<template>
  <form @submit.prevent="submit">
    <div class="mb-4 grid grid-cols-3 gap-4">
      <SelectField
        name="rentalPartner"
        :label="t('rentalPartner')"
        :options="rentalPartnerOptions"
        empty-value-to="null"
      />
      <InputField :label="t('reservationNumber')" name="internalComment" />
    </div>
    <div>
      <BookingRangePicker
        v-model:start-date="formValues.startDate"
        v-model:end-date="formValues.endDate"
        class="col-span-2"
        :car-id="props.car.id"
        :other-price-changing-module-is-active="false"
        :start-date-title="t('start')"
        :end-date-title="t('end')"
      />

      <Divider />

      <VehicleImmutable :car="car" />

      <Divider />

      <div class="flex justify-end"></div>

      <div class="flex items-center justify-between">
        <CVButton
          v-if="!bookingNew"
          outline
          variant="error"
          :disabled="isSaving"
          :is-loading="isDeleting"
          @click.prevent="$emit('onDelete')"
        >
          {{ t('deleteBooking') }}
        </CVButton>

        <CVButton
          variant="primary"
          type="submit"
          class="justify-self-end"
          :disabled="isDeleting"
          :is-loading="isSaving"
        >
          {{ t('save') }}
        </CVButton>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import BookingRangePicker from '@/views/handover/components/overview/BookingRangePicker.vue';
import InputField from '@/components/InputField.vue';
import { useI18n } from 'vue-i18n';
import type { Car } from '@/entities/cars/car.entity';
import { computed, reactive } from 'vue';
import Divider from '@/components/Divider.vue';
import { useForm } from 'vee-validate';
import type { UpdateInternalBookingDto } from '@/entities/bookings/internal-booking.entity';
import { DateTime } from 'luxon';
import VehicleImmutable from '@/components/booking-forms/VehicleImmutable.vue';
import type { Booking } from '@/entities/bookings/booking.entity';
import SelectField from '@/components/SelectField.vue';
import { RentalPartner } from '@/entities/bookings/rental-partner.enum';
import { partnerRentSchema } from '@/validation/partner-rent.schema';

const props = defineProps<{
  car: Car;
  booking?: Booking;
  bookingNew: boolean;
  isSaving: boolean;
  isDeleting: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSubmit', value: UpdateInternalBookingDto): void;
  (e: 'onDelete'): void;
}>();

const { t } = useI18n();

interface FormValues {
  startDate: string;
  endDate: string;
}

const rentalPartnerOptions = computed(
  () =>
    Object.values(RentalPartner).map((rentalPartner) => ({
      label: t(`rentalPartners.${rentalPartner}`),
      value: rentalPartner,
    })) ?? [],
);

const formValues: FormValues = reactive({
  startDate:
    props.booking?.startDate ??
    DateTime.now().set({ hour: 11, minute: 0, second: 0, millisecond: 0 }).toISO(),
  endDate:
    props.booking?.endDate ??
    DateTime.now().set({ hour: 11, minute: 0, second: 0, millisecond: 0 }).plus({ day: 1 }).toISO(),
});

const { handleSubmit } = useForm({
  initialValues: {
    rentalPartner: props.booking?.rentalPartner ?? RentalPartner.PORSCHE_DRIVE,
    internalComment: props.booking?.internalComment,
  },
  validationSchema: partnerRentSchema,
});

const submit = handleSubmit((values) => {
  emit('onSubmit', {
    ...formValues,
    ...values,
  });
});
</script>

<i18n lang="json">
{
  "en": {
    "reservationNumber": "Reservation Number",
    "save": "save",
    "start": "Start",
    "end": "End",
    "deleteBooking": "Delete Booking",
    "rentalPartner": "Rental Partner"
  },
  "de": {
    "reservationNumber": "Reservierungsnummer",
    "save": "speichern",
    "start": "Beginn",
    "end": "Ende",
    "deleteBooking": "Buchung löschen",
    "rentalPartner": "Mietpartner"
  }
}
</i18n>
