<template>
  <div>
    <div v-if="!booking.customer">
      <div class="grid grid-cols-3 gap-4">
        <InputField name="email" :label="t('email')" :tooltip="t('emailInformation')" />
      </div>
      <Divider />
    </div>

    <CustomerDataForm hide-header />

    <Divider />

    <CustomerDocumentsForm hide-header />

    <div v-if="hasChanged" class="mt-4 flex justify-end gap-4">
      <CVButton variant="success" :is-loading="isSaving" @click.prevent="onSubmit">{{
        t('save')
      }}</CVButton>
      <CVButton variant="warning" :disabled="isSaving" @click.prevent="onCancel">{{
        t('cancel')
      }}</CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import { useForm } from 'vee-validate';
import { cloneDeep } from 'lodash';
import { useFormHasChanged } from '@/hooks/use-form-has-changed';
import { customerHandoverSchema } from '@/validation/customer.schema';
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import CustomerDataForm from '@/components/booking-forms/customer/CustomerDataForm.vue';
import CustomerDocumentsForm from '@/components/booking-forms/customer/CustomerDocumentsForm.vue';
import { toTypedSchema } from '@vee-validate/yup';
import Divider from '@/components/Divider.vue';
import {
  useCreateUser,
  useUserWithEmailIfExists,
  useCreateUserOrReturnId,
} from '@/queries/use-users';
import InputField from '@/components/InputField.vue';
import { Alert } from '@/utils/alert';
import { Local } from '@/entities/auth/local.enum';
import type { CustomerDataDto } from '@/entities/bookings/customer-data.dto';
import type { User } from '@/entities/auth/user.entity';
import * as yup from 'yup';

const props = defineProps<{
  booking: Booking;
  isSaving: boolean;
}>();

const emit = defineEmits<{
  (e: 'hasChanged', value: boolean): void;
  (
    e: 'updateBooking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished?: () => void,
  ): void;
}>();

const { t } = useI18n();

const { mutateAsync: createUser, isPending: isCreatingUser } = useCreateUser();
const { mutateAsync: createUserOrReturnId, isPending: isCreatingOrReturningId } =
  useCreateUserOrReturnId();

const isSaving = computed(
  () =>
    props.isSaving || isCreatingUser.value || isCreatingOrReturningId.value || isFetchingUser.value,
);

const initializeFormValues = () => {
  return {
    ...props.booking.customerData,
    dateOfBirth: props.booking.customerData?.dateOfBirth
      ? new Date(props.booking.customerData?.dateOfBirth)
      : undefined,
    idCardDate: props.booking.customerData?.idCardDate
      ? new Date(props.booking.customerData?.idCardDate)
      : undefined,
    licenseDate: props.booking.customerData?.licenseDate
      ? new Date(props.booking.customerData?.licenseDate)
      : undefined,
    email: props.booking.customer?.email,
  };
};

const onCancel = () => {
  setValues(initializeFormValues());
};

watch(
  () => props.booking.customerData,
  () => onCancel(),
);

const validationSchema = toTypedSchema(
  customerHandoverSchema(
    computed(() => props.booking.car.vehicleType.minAge),
    computed(() => props.booking.startDate),
    computed(() => !props.booking.customer),
  ),
);

const {
  handleSubmit,
  values: formValues,
  setValues,
  validate,
} = useForm({
  initialValues: initializeFormValues(),
  validationSchema,
});

const { refetch: fetchUser, isFetching: isFetchingUser } = useUserWithEmailIfExists(
  computed(() => formValues.email ?? ''),
  false,
);

const unchangedValues = ref(cloneDeep(formValues));

const onSubmit = handleSubmit(async (values) => {
  const { email, ...customerData } = values;
  const emailExistsResponse = !props.booking.customer ? await checkIfEmailExists() : null;
  const existingUser = props.booking.customer ?? emailExistsResponse?.user;
  if (existingUser) {
    let userToChangeTo: { id: string } | undefined = undefined;
    if (!isCustomerUnchanged(customerData, existingUser)) {
      const changeEmail = await showChangeEmailAlert(existingUser);
      if (changeEmail.isConfirmed) {
        const newEmail = await showNewEmailAlert();
        if (!newEmail.isConfirmed) return;
        userToChangeTo = await createUserOrReturnId({ email: newEmail.value, ...customerData });
      }
    }
    updateBooking({ customerData, customerId: userToChangeTo?.id ?? existingUser.id });
  } else if (email) {
    if (emailExistsResponse?.emailExists) return;
    const { id } = await createUser({
      ...customerData,
      preferredLocal: customerData.preferredLocal ?? Local.EN_US,
      email,
    });
    updateBooking({ customerData, customerId: id });
  }
});

const checkIfEmailExists = async () => {
  const user = await fetchUser();
  if (!user.data?.id) {
    return {
      emailExists: false,
      user: null,
    };
  }
  const { isConfirmed } = await Alert.fire({
    titleText: t('emailExistsTitle'),
    html: t('emailExistsText', {
      name: user.data.firstName + ' ' + user.data.lastName,
      customerNumber: user.data.customerNumber,
      email: user.data.email,
    }),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('useAccount'),
    cancelButtonText: t('dontUse'),
  });

  return {
    emailExists: true,
    user: isConfirmed ? user.data : null,
  };
};

const showChangeEmailAlert = (customer: User) => {
  return Alert.fire({
    titleText: t('customerDataDiffersTitle'),
    html: t('customerDataDiffersText', {
      email: customer.email,
      name: customer.firstName + ' ' + customer.lastName,
      address:
        customer.street + ', ' + customer.zip + '<br/>' + customer.city + ', ' + customer.country,
    }),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('updateEmail'),
    cancelButtonText: t('continueWithEmail'),
  });
};

const showNewEmailAlert = () => {
  return Alert.fire({
    titleText: t('newEmailTitle'),
    text: t('newEmailText'),
    input: 'text',
    inputValidator: (value: string) => {
      try {
        yup.string().email().validateSync(value);
        return undefined;
      } catch (e) {
        return t('invalidEmail');
      }
    },
    showCancelButton: true,
    confirmButtonText: t('save'),
    cancelButtonText: t('cancel'),
  });
};

const isCustomerUnchanged = (customerData: CustomerDataDto, customer: User) => {
  const fieldsToCompare: (keyof CustomerDataDto)[] = [
    'firstName',
    'lastName',
    'street',
    'zip',
    'city',
    'country',
  ];
  return fieldsToCompare.every(
    (field) =>
      customerData[field]?.toString().toLowerCase().trim() ===
      customer[field]?.toString().toLowerCase().trim(),
  );
};

const updateBooking = (booking: UpdateBookingDto) => {
  emit(
    'updateBooking',
    {
      id: props.booking.id,
      booking,
    },
    () => {
      unchangedValues.value = cloneDeep(formValues);
    },
  );
};

const hasChanged = useFormHasChanged(unchangedValues, formValues);
watch(hasChanged, () => emit('hasChanged', hasChanged.value));

defineExpose({
  validate,
});
</script>

<i18n lang="json">
{
  "en": {
    "email": "Email",
    "emailInformation": "The email will be used to create a new customer account. Contract and Invoices will be sent to this address. Please double check if the email is correct.",
    "emailExistsTitle": "Email already exists",
    "emailExistsText": "<div class='font-medium mb-3'>{email}<br/>{name} (Cnr. {customerNumber})</div>Do you want to use this existing account for the booking?",
    "useAccount": "Use Account",
    "dontUse": "Don't use",
    "customerDataDiffersTitle": "Change email?",
    "customerDataDiffersText": "<div class='text-left'>The entered data differs from the used emails data:<br/><br/><div class='font-medium mb-3 text-left'>{email}<br/>{name}<br/>{address}</div>Contract and Invoices will be sent to this address.<br/>Do you want to use a different email address?</div>",
    "updateEmail": "Change email",
    "continueWithEmail": "The email is totally fine",
    "invalidEmail": "Invalid email address",
    "newEmailTitle": "New email address",
    "newEmailText": "Please enter a new email address",
    "cancel": "Cancel"
  },
  "de": {
    "email": "Email",
    "emailInformation": "Die Email wird verwendet um einen neuen Kundenaccount zu erstellen. Verträge und Rechnungen werden an diese Adresse gesendet. Bitte stelle sicher, dass die Email korrekt ist.",
    "emailExistsTitle": "Email existiert bereits",
    "emailExistsText": "<div class='font-medium mb-3'>{email}<br/>{name} (Knr. {customerNumber})</div>Möchtest du dieses bestehende Konto für die Buchung verwenden?",
    "useAccount": "Konto verwenden",
    "dontUse": "Nicht verwenden",
    "customerDataDiffersTitle": "E-Mail Adresse wechseln?",
    "customerDataDiffersText": "<div class='text-left'>Die eingegebenen Daten unterscheiden sich von den Daten der benutzten E-Mailadresse:<br/><br/><div class='font-medium mb-3 text-left'>{email}<br/>{name}<br/>{address}</div>Verträge und Rechnungen werden an diese Adresse gesendet.<br/>Möchtest du eine andere E-Mailadresse verwenden?</div>",
    "updateEmail": "E-Mail Adresse ändern",
    "continueWithEmail": "Passt schon so",
    "invalidEmail": "Ungültige E-Mail Adresse",
    "newEmailTitle": "Neue E-Mail Adresse",
    "newEmailText": "Bitte gib eine neue E-Mail Adresse ein",
    "cancel": "Abbrechen"
  }
}
</i18n>
