import { defineStore } from 'pinia';

export interface UiStoreState {
  headerTitle: string | null;
  subTitleLine1: string | null;
  subTitleLine2: string | null;
}

export const useUiStore = defineStore({
  id: 'uiStore',
  state: () =>
    ({
      headerTitle: null,
      subTitleLine1: null,
      subTitleLine2: null,
    }) as UiStoreState,
  getters: {},
  actions: {
    setHeaderTitle(
      title: string | null,
      subTitleLine1: string | null = null,
      subTitleLine2: string | null = null,
    ) {
      this.headerTitle = title;
      this.subTitleLine1 = subTitleLine1;
      this.subTitleLine2 = subTitleLine2;
    },
  },
});
