<template>
  <div class="w-full">
    <h2>{{ t('handoverDone') }}</h2>
    <p class="mt-4">
      {{ t('vehicleCanBeHandedOver') }}
    </p>
    <BigSuccess />

    <Divider />
    <div class="flex justify-end">
      <CVButton :to="{ name: 'upcomingHandovers' }">
        {{ t('next') }}
      </CVButton>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import BigSuccess from '@/components/BigSuccess.vue';
import Divider from '@/components/Divider.vue';

const { t } = useI18n();
</script>

<i18n lang="json">
{
  "en": {
    "handoverDone": "Handover Done",
    "next": "Back to Dashboard",
    "vehicleCanBeHandedOver": "The vehicle can now be handed over."
  },
  "de": {
    "handoverDone": "Übergabe abgeschlossen",
    "next": "Zurück zur Übersicht",
    "vehicleCanBeHandedOver": "Das Fahrzeug kann jetzt übergeben werden."
  }
}
</i18n>
