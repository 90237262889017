<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <label class="form-control">
    <InputLabel :label="label" :tooltip="tooltip" :is-error="!!errorMessage" :warning="warning" />

    <div
      :class="{
        'cursor-not-allowed opacity-50': $attrs.disabled,
      }"
    >
      <Multiselect
        v-model="inputValue"
        v-bind="$attrs"
        :placeholder="placeholder"
        :options="options"
        :disabled="disabled"
        mode="tags"
        :classes="{
          container:
            'flex justify-start rounded-none border border-primary/40 hover:border-primary transition duration-300 bg-accent p-0 placeholder-primary placeholder-opacity-60 first:rounded-none relative',
          containerOpen: 'border-primary bg-white',
          caret:
            'bg-multiselect-caret bg-center bg-no-repeat w-2 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5 opacity-20',
          caretOpen: 'rotate-180 pointer-events-auto',
          tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-0 pl-0.5 rtl:pl-0 rtl:pr-0.5',
          clear: 'hidden',
          clearIcon:
            'bg-multiselect-remove bg-center bg-no-repeat w-2 h-4 py-px box-content inline-block',
        }"
      >
        <template #option="{ option }">
          <p>{{ option.label }}</p>
        </template>

        <template #tag="{ option, handleTagRemove, disabled }">
          <div>
            <div
              class="m-0.5 flex items-center space-x-1 rounded-full bg-primary px-2 py-[1px] text-sm text-white"
              :class="{
                disabled: disabled,
                'cursor-not-allowed': disabled,
              }"
            >
              <p>{{ option.label }}</p>

              <svg
                v-if="!disabled"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                class="inline-block h-4 w-4 stroke-current"
                @click="handleTagRemove(option, $event)"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
          </div>
        </template>
      </Multiselect>
    </div>
    <InputHint :hint="hint" :is-error="!!errorMessage" />
  </label>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import InputLabel from './InputLabel.vue';
import { computed } from 'vue';
import InputHint from './InputHint.vue';

export interface MultiSelectOptionData {
  label: string;
  value: string;
}

const props = defineProps<{
  name: string;
  label?: string;
  placeholder?: string;
  hint?: string;
  options: MultiSelectOptionData[];
  tooltip?: string;
  warning?: string;
  disabled?: boolean;
}>();

const hint = computed(() => errorMessage.value || props.hint);

const { value: inputValue, errorMessage } = useField(props.name, undefined);
</script>
