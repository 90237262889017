<template>
  <div>
    <p class="mb-4 text-xl font-medium">
      {{ t('agency') }}
    </p>

    <div class="grid grid-cols-3 gap-4">
      <LabeledText :label="t('company')">{{ agency.company ?? '-' }}</LabeledText>
      <LabeledText :label="t('name')"> {{ agency.firstName }} {{ agency.lastName }} </LabeledText>
      <LabeledText :label="t('email')">{{ agency.email }}</LabeledText>
    </div>
  </div>
</template>

<script lang="ts" setup>
import LabeledText from '@/components/LabeledText.vue';
import type { User } from '@/entities/auth/user.entity';
import { useI18n } from 'vue-i18n';

defineProps<{
  agency: User;
}>();

const { t } = useI18n();
</script>

<i18n lang="json">
{
  "en": {
    "agency": "Partner/Agency",
    "company": "Company",
    "name": "Name",
    "email": "Email"
  },
  "de": {
    "agency": "Partner/Vermittler",
    "company": "Firma",
    "name": "Name",
    "email": "Email"
  }
}
</i18n>
