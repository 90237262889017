import type { DeleteResponse } from '@/entities/delete-reponse';
import type { PaginatedResponse } from '@/entities/pagination/paginated-response';
import { httpClient } from './http-client';

export class Service<
  ReturnDto = any,
  CreateDto = ReturnDto,
  UpdateDto = CreateDto,
  UpdateQueryDto = any,
> {
  httpClient = httpClient;
  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  async getAll() {
    const res = await this.httpClient.get<ReturnDto[]>(`${this.endpoint}`);
    return res.data;
  }
  async getAllPaginated(params: { page: number; limit?: number }) {
    const res = await this.httpClient.get<PaginatedResponse<ReturnDto>>(`${this.endpoint}`, {
      params,
    });
    return res.data;
  }
  async getOne(id: string) {
    const res = await this.httpClient.get<ReturnDto>(`${this.endpoint}/${id}`);
    return res.data;
  }
  async update(id: string, data: UpdateDto, query?: UpdateQueryDto) {
    const res = await this.httpClient.patch<ReturnDto>(`${this.endpoint}/${id}`, data, {
      params: query,
    });
    return res.data;
  }
  async create(data: CreateDto) {
    const res = await this.httpClient.post<ReturnDto>(`${this.endpoint}`, data);
    return res.data;
  }
  async remove(id: string) {
    const res = await this.httpClient.delete<DeleteResponse>(`${this.endpoint}/${id}`);
    return res.data;
  }
  async updateBatch(data: UpdateDto[]) {
    const res = await this.httpClient.patch<ReturnDto[]>(`${this.endpoint}`, data);
    return res.data;
  }
}
