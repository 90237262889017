<template>
  <div>
    <BookingsList :bookings="bookings" />

    <EntityListExceptions
      :status="status"
      :error="error"
      :items-count="bookings.length"
      :no-items-text="t('noBookingFound')"
    />

    <div v-if="hasNextPage" class="flex justify-center pt-8">
      <CVButton outline :is-loading="isFetchingNextPage" @click="() => fetchNextPage()">
        {{ t('loadMore') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BookingsList from '../../bookings/bookings-list/BookingsList.vue';
import EntityListExceptions from '@/components/EntityListExceptions.vue';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { useBookingsWithQuery } from '@/queries/use-bookings';
import { useI18n } from 'vue-i18n';
import { FindAllBookingsSort } from '@/entities/bookings/find-all-bookings-sort.enum';
import { Order } from '@/entities/pagination/order.enum';
import { BookingType } from '@/entities/bookings/booking-type.enum';

const props = defineProps<{
  userId: string;
}>();

const { t } = useI18n();

const { data, status, error, isFetchingNextPage, hasNextPage, fetchNextPage } =
  useBookingsWithQuery({
    filter: [
      {
        types: [BookingType.CUSTOMER_BOOKING],
        customerIds: [props.userId],
      },
      {
        types: [BookingType.CUSTOMER_BOOKING],
        agentIds: [props.userId],
      },
    ],
    sort: FindAllBookingsSort.CREATED_DATE,
    order: Order.DESC,
  });
const bookings = useFlattenPaginatedData(data);
</script>

<i18n lang="json">
{
  "en": {
    "noBookingFound": "No Bookings found"
  },
  "de": {
    "noBookingFound": "Es wurden keine Buchungen gefunden"
  }
}
</i18n>
