import type { Car } from '@/entities/cars/car.entity';
import type { CarFormValues } from '../EditCarForm.vue';

export const getInitialValues = (car: Car): CarFormValues => {
  if (!car.isGhostCar) {
    return {
      vehicleTypeId: car.vehicleType.id,
      licencePlate: car.licencePlate,
      fin: car.fin,
      tyres: car.tyres,
      selectedImageFile: undefined,
      availableFrom: car.availableFrom,
      availableUntil: car.availableUntil,
      hiddenFromPublic: car.hiddenFromPublic,
      notAtTheHub: car.notAtTheHub,
      stations:
        car?.stations
          .map(({ station, fromDate }) => ({
            id: station.id,
            fromDate,
          }))
          .sort((a, b) => {
            const aDate = a.fromDate ? new Date(a.fromDate).getTime() : 0;
            const bDate = b.fromDate ? new Date(b.fromDate).getTime() : 0;
            return aDate > bDate ? 1 : -1;
          }) ?? [],
      acquisitionType: car.acquisitionType,
      purchasePrice: car.purchasePrice,
      leasingRate: car.leasingRate,
      listPrice: car.listPrice,
      color: car.color,
      insuranceCompany: car.insuranceCompany,
      firstRegistration: car.firstRegistration,
      fuelType: car.fuelType,
      transmission: car.transmission,
      drive: car.drive,
      batteryCapacityInKwh: car.batteryCapacityInKwh,
      tankVolumeInLiter: car.tankVolumeInLiter,
      rangeInKm: car.rangeInKm,
      seats: car.seats,
      power: car.power,
      accelaration: car.accelaration,
      hasNavigation: car.hasNavigation,
      hasDistanceControl: car.hasDistanceControl,
      hasRearViewCamera: car.hasRearViewCamera,
    };
  } else {
    return {
      vehicleTypeId: car.vehicleType.id,
      licencePlate: car.licencePlate,
      selectedImageFile: undefined,
      hiddenFromPublic: car.hiddenFromPublic,
      notAtTheHub: car.notAtTheHub,
      stations:
        car?.stations
          .map(({ station, fromDate }) => ({
            id: station.id,
            fromDate,
          }))
          .sort((a, b) => {
            const aDate = a.fromDate ? new Date(a.fromDate).getTime() : 0;
            const bDate = b.fromDate ? new Date(b.fromDate).getTime() : 0;
            return aDate > bDate ? 1 : -1;
          }) ?? [],
      possibleUpgradeCars: [...car.possibleUpgradesTo]
        .sort((a, b) => a.order - b.order)
        .map((upgrade) => upgrade.to),
      isMysteryCar: car.isMysteryCar,
      color: car.color,
      fuelType: car.fuelType,
      transmission: car.transmission,
      drive: car.drive,
      batteryCapacityInKwh: car.batteryCapacityInKwh,
      tankVolumeInLiter: car.tankVolumeInLiter,
      rangeInKm: car.rangeInKm,
      seats: car.seats,
      power: car.power,
      accelaration: car.accelaration,
      hasNavigation: car.hasNavigation,
      hasDistanceControl: car.hasDistanceControl,
      hasRearViewCamera: car.hasRearViewCamera,
    };
  }
};
