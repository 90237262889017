import type { BookedExtra } from '@/entities/bookings/booked-extra';

export const convertToBookedExtrasMap = (bookedExtras: BookedExtra[]) => {
  return (
    bookedExtras?.reduce(
      (acc, bookedExtra) => {
        acc[bookedExtra.vehicleExtra.type] =
          `${bookedExtra.vehicleExtra.id};${bookedExtra.vehicleExtraOption.id}`;
        return acc;
      },
      {} as Record<string, string>,
    ) ?? {}
  );
};
