import { vehicleExtrasService } from '@/api/vehicle-extras.service';
import type {
  CreateVehicleExtraDto,
  UpdateVehicleExtraDto,
  VehicleExtra,
} from '@/entities/vehicle-extra.entity';
import { useMutation, useQuery } from '@tanstack/vue-query';
import type { UseQueryHookOptions } from '@/entities/use-query-hook-options';

const QUERY_KEY = 'vehicle-extras';

export const useVehicleExtra = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: () => vehicleExtrasService.getOne(id),
  });
};

export const useVehicleExtras = <TData>(options?: UseQueryHookOptions<VehicleExtra[], TData>) => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => vehicleExtrasService.getAll(),
    ...options,
  });
};

export const useUpdateVehicleExtra = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ id, extra }: { id: string; extra: UpdateVehicleExtraDto }) =>
      vehicleExtrasService.update(id, extra),
  });
};

export const useCreateVehicleExtra = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (extra: CreateVehicleExtraDto) => vehicleExtrasService.create(extra),
  });
};
