<template>
  <router-link :to="{ name: 'insuranceCase', params: { id: insuranceCase.id } }">
    <div class="relative w-full border border-primary transition-shadow hover:shadow-md">
      <div class="flex items-center gap-2 py-2 pl-3 pr-2 text-sm">
        <div class="basis-1/4">
          <p class="mb-1 font-medium text-primary">{{ t('customer') }}</p>
          <p>
            {{ insuranceCase.customerData?.firstName ?? 'n/a' }}
            {{ insuranceCase.customerData?.lastName ?? 'n/a' }}
          </p>
        </div>

        <div class="basis-1/4">
          <p class="mb-1 font-medium text-primary">{{ t('insuranceProvider') }}</p>
          <p>{{ insuranceCase.insuranceProvider }}</p>
        </div>

        <div class="basis-1/4">
          <p class="mb-1 font-medium text-primary">{{ t('referenceNumber') }}</p>
          <p>{{ insuranceCase.insuranceData?.referenceNumber ?? 'n/a' }}</p>
        </div>

        <div class="basis-1/4">
          <div class="mb-1">
            <p class="mr-2 inline font-medium text-primary">
              {{ t('start') }}
            </p>
            <span>{{ startDate }}</span>
          </div>

          <div>
            <p class="mr-2 inline font-medium text-primary">
              {{ t('end') }}
            </p>
            <span>{{ endDate }}</span>
          </div>
        </div>

        <div class="basis-1/4">
          <p class="mb-1 font-medium text-primary">{{ t('createdAt') }}</p>
          <p>{{ createdDate }}</p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { useFormattedDate } from '@/hooks/use-formatted-date';
import { useI18n } from 'vue-i18n';
import type { InsuranceCase } from '@carvia/ros-client-types';
import { useDateFormat } from '@/hooks/use-date-format';

const props = defineProps<{
  insuranceCase: InsuranceCase;
}>();
const { t } = useI18n();

const startDate = useFormattedDate(props.insuranceCase.startDate, useDateFormat());
const endDate = useFormattedDate(props.insuranceCase.endDate, useDateFormat());
const createdDate = useFormattedDate(props.insuranceCase.createdDate, useDateFormat());
</script>

<i18n lang="json">
{
  "en": {
    "customer": "Customer",
    "start": "Start:",
    "end": "End:",
    "createdAt": "Created at",
    "insuranceProvider": "Insurance provider",
    "referenceNumber": "Reference Nr."
  },
  "de": {
    "customer": "Kunde",
    "start": "Start:",
    "end": "Ende:",
    "createdAt": "Erstellt am",
    "insuranceProvider": "Versicherer",
    "referenceNumber": "Aktenzeichen"
  }
}
</i18n>
