<template>
  <div v-if="!hideHead" class="mb-4 flex justify-between">
    <h2>{{ title }}</h2>
    <CVButton @click.prevent="emit('addValuePair')">
      {{ buttonText }}
    </CVButton>
  </div>

  <div class="border border-primary pb-1 pl-4 pr-4 pt-1">
    <div
      v-for="(valuePair, i) in valuePairs"
      :key="i"
      class="relative border-primary pb-3 pr-8 pt-3"
      :class="{ 'border-b': i < valuePairs.length - 1 }"
    >
      <div class="grid grid-cols-2 gap-4">
        <InputField
          :name="`${name}[${i}].${valueNames[0]}`"
          :initial-value="valuePair[0]"
          :label="labels[0].label"
          :adornment="labels[0].adornment"
          type="number"
          :save-number-as-string="!!saveNumbersAsString?.[0]"
          :disabled="i === 0 && isInclusiveKm"
        />
        <InputField
          :name="`${name}[${i}].${valueNames[1]}`"
          :initial-value="valuePair[1]"
          :label="labels[1].label"
          :adornment="labels[1].adornment"
          type="number"
          :save-number-as-string="!!saveNumbersAsString?.[1]"
        />
      </div>
      <div
        v-if="i !== 0"
        class="absolute bottom-3 right-0 cursor-pointer p-2 text-lg leading-3 text-red-600"
        @click="emit('removeValuePair', i)"
      >
        ×
      </div>
    </div>
    <div v-if="!valuePairs.length" class="flex justify-center p-4">
      {{ t('noEntries') }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import InputField from '@/components/InputField.vue';
import { useI18n } from 'vue-i18n';

export type Label = { label: string; adornment?: string };

export type Labels = [Label, Label];

export type ValuePairs = [number | string | null, number | string | null][];

defineProps<{
  isInclusiveKm?: boolean;
  title?: string;
  buttonText?: string;
  name: string;
  valueNames: [string, string];
  valuePairs: ValuePairs;
  labels: Labels;
  saveNumbersAsString?: [boolean, boolean];
  hideHead?: boolean;
}>();

const emit = defineEmits<{
  (e: 'addValuePair'): void;
  (e: 'removeValuePair', value: number): void;
}>();

const { t } = useI18n();
</script>

<i18n lang="json">
{
  "en": {
    "noEntries": "No Entries"
  },
  "de": {
    "noEntries": "Keine Einträge"
  }
}
</i18n>
