export enum CancellationFee {
  NO_FEE = 'NO_FEE',
  WITHIN_SEVEN_DAYS = 'WITHIN_SEVEN_DAYS',
  AFTER_BOOKING_START_DATE = 'AFTER_BOOKING_START_DATE',
}

export const CANCELLATION_FEES: Record<string, number> = {
  [CancellationFee.NO_FEE]: 0,
  [CancellationFee.WITHIN_SEVEN_DAYS]: 0.3,
  [CancellationFee.AFTER_BOOKING_START_DATE]: 1.0,
};
