import {
  addItemToQueryData,
  removeItemFromQueryData,
  updateItemOnQueryData,
} from '@/utils/update-query-data';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { type MaybeRefOrGetter, toRef, toValue } from '@vueuse/core';
import type { MutationDefaultsContext } from './query-client';
import { paymentTerminalsService } from '@/api/payment-terminals.service';
import type {
  CreatePaymentTerminalDto,
  FindAllPaymentTerminalsQueryDto,
  PaymentTerminal,
  UpdatePaymentTerminalDto,
} from '@/entities/payment-terminals/payment-terminal.entity';
import type { ChargeCreditCardDto } from '@/entities/payment-terminals/charge-credit-card.dto';
import type { ChargeRemainingFromCreditCardDto } from '@/entities/payment-terminals/charge-remaining-from-credit-card.dto';

const QUERY_KEY = 'payment-terminal';

export const usePaymentTerminal = (
  id: MaybeRefOrGetter<string>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(id)],
    queryFn: () => paymentTerminalsService.getOne(toValue(id)),
    enabled: toValue(enabled),
  });
};

export const usePaymentTerminalsWithQuery = (
  query: MaybeRefOrGetter<FindAllPaymentTerminalsQueryDto>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY, toRef(query)],
    queryFn: ({ pageParam = 1 }) =>
      paymentTerminalsService.getAllWithQuery({
        ...toValue(query),
        page: pageParam,
      }),
    enabled: toValue(enabled),
    getNextPageParam: (lastPage) => lastPage.meta.nextPage,
    initialPageParam: 1,
  });
};

export const useUpdatePaymentTerminal = (
  query?: MaybeRefOrGetter<FindAllPaymentTerminalsQueryDto>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({
      id,
      paymentTerminal,
    }: {
      id: string;
      paymentTerminal: UpdatePaymentTerminalDto;
    }) => paymentTerminalsService.update(id, paymentTerminal),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'Payment Terminal updated',
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      updateItemOnQueryData<PaymentTerminal>([QUERY_KEY, toRef(query)], result, queryClient);
    },
  });
};

export const useCreatePaymentTerminal = (
  query?: MaybeRefOrGetter<FindAllPaymentTerminalsQueryDto>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (paymentTerminal: CreatePaymentTerminalDto) =>
      paymentTerminalsService.create(paymentTerminal),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'Payment Terminal saved',
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      addItemToQueryData<PaymentTerminal>([QUERY_KEY, toRef(query)], result, queryClient);
    },
  });
};

export const useDeletePaymentTerminal = (
  query?: MaybeRefOrGetter<FindAllPaymentTerminalsQueryDto>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (id: string) => paymentTerminalsService.remove(id),
    onMutate: (id) => ({ id, successMessage: 'Payment Terminal deleted' }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      removeItemFromQueryData([QUERY_KEY, toRef(query)], context?.id, queryClient);
    },
  });
};

export const useChargeCreditCard = () => {
  return useMutation({
    mutationKey: ['charge-credit-card'],
    mutationFn: (data: ChargeCreditCardDto) => paymentTerminalsService.chargeCreditCard(data),
    onMutate: () => ({ showSuccessToast: false }),
  });
};

export const useChargeRemainingBookingAmountFromCreditCard = () => {
  return useMutation({
    mutationKey: ['charge-remaining-amount-from-credit-card'],
    mutationFn: (data: ChargeRemainingFromCreditCardDto) =>
      paymentTerminalsService.chargeRemainingBookingAmountFromCreditCard(data),
    onMutate: () => ({ showSuccessToast: false }),
  });
};
