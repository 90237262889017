import { computed } from 'vue';
import router from '@/router';

export const useModalVisibility = <T>(searchParamName: string) =>
  computed<T | false>({
    get: () => {
      return router.currentRoute.value.query[searchParamName] as T;
    },
    set: (value) => {
      if (value !== false) {
        router.push({
          path: router.currentRoute.value.path,
          query: {
            ...router.currentRoute.value.query,
            [searchParamName]: value as string,
          },
        });
      } else {
        if (!(searchParamName in router.currentRoute.value.query)) {
          return; // avoid endless loops
        }
        const previousUrl = router.options.history.state.back;
        if (
          typeof previousUrl === 'string' &&
          previousUrl.startsWith(router.currentRoute.value.path)
        ) {
          router.back();
        } else {
          const currentSearchParams = { ...router.currentRoute.value.query };
          delete currentSearchParams[searchParamName];
          router.push({
            path: router.currentRoute.value.path,
            query: currentSearchParams,
            replace: true,
          });
        }
      }
    },
  });
