import { paymentsService } from '@/api/payments.service';
import type {
  CreatePaymentDto,
  FindAllPaymentsQueryDto,
  Payment,
  UpdatePaymentDto,
} from '@/entities/payments/payment.entity';
import {
  addItemToQueriesData,
  removeItemFromQueriesData,
  updateItemOnQueriesData,
} from '@/utils/update-query-data';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { type MaybeRefOrGetter, toRef, toValue } from '@vueuse/core';
import type { MutationDefaultsContext } from './query-client';

const QUERY_KEY = 'payments';

export const usePayment = (id: MaybeRefOrGetter<string>) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(id)],
    queryFn: () => paymentsService.getOne(toValue(id)),
  });
};

export const usePayments = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => paymentsService.getAll(),
  });
};

export const usePaymentsWithQuery = (
  query: MaybeRefOrGetter<FindAllPaymentsQueryDto>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY, toRef(query)],
    queryFn: ({ pageParam = 1 }) =>
      paymentsService.getAllWithQuery({
        ...toValue(query),
        page: pageParam,
      }),
    enabled: toValue(enabled),
    getNextPageParam: (lastPage) => lastPage.meta.nextPage,
    initialPageParam: 1,
  });
};

export const useUpdatePayment = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ id, payment }: { id: string; payment: UpdatePaymentDto }) =>
      paymentsService.update(id, payment),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'Payment updated',
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      updateItemOnQueriesData<Payment>([QUERY_KEY], result, queryClient);
    },
  });
};

export const useCreatePayment = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (payment: CreatePaymentDto) => paymentsService.create(payment),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'Payment saved',
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      addItemToQueriesData<Payment>([QUERY_KEY], result, queryClient);
    },
  });
};

export const useDeletePayment = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (id: string) => paymentsService.remove(id),
    onMutate: (id) => {
      return { id };
    },
    onSuccess: (result, variables, context) => {
      removeItemFromQueriesData([QUERY_KEY], context?.id, queryClient);
    },
  });
};
