<template>
  <div
    v-if="status === 'success' && itemsCount === 0"
    class="flex"
    :class="{ 'my-4 justify-start': small, 'justify-center py-28': !small }"
  >
    <p>{{ noItemsText ?? t('noItemsFound') }}</p>
  </div>

  <div
    v-if="status === 'pending'"
    class="flex justify-center"
    :class="{ 'my-4': small, 'py-5': !small }"
  >
    <Spinner />
  </div>

  <div
    v-if="status === 'error'"
    class="flex"
    :class="{ 'my-4 justify-start': small, 'justify-center py-28': !small }"
  >
    <p>{{ error }}</p>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import Spinner from './icons/Spinner.vue';

const { t } = useI18n();

defineProps<{
  status: 'success' | 'pending' | 'error';
  error: unknown;
  itemsCount: number;
  noItemsText?: string;
  small?: boolean;
}>();
</script>

<i18n lang="json">
{
  "en": {
    "noItemsFound": "No items found"
  },
  "de": {
    "noItemsFound": "Keine Einträge gefunden"
  }
}
</i18n>
