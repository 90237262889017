<template>
  <div
    class="h-26 relative overflow-hidden break-words border-b border-primary/30 pb-4 last:border-b-0 last:pb-0"
  >
    <div class="mb-1 flex items-start justify-between">
      <h3 class="space-x-1 text-lg font-medium lg:text-xl">
        <router-link :to="{ name: 'showNews', params: { id: news.id } }" class="hover:underline">{{
          news.title
        }}</router-link>
        <span class="ml-[2px] text-xs font-normal lg:text-sm">{{ '- ' + formattedDate }}</span>
      </h3>
      <p v-if="authStore.user?.role === UserRole.ADMIN" class="flex gap-1 text-xs">
        <span class="font-medium text-primary">
          {{ t(`newsStatus.${news.status}`) }}
        </span>
        <span class="text-primary">•</span>
        <router-link :to="{ name: 'editNews', params: { id: news.id } }" class="text-link">{{
          t('edit')
        }}</router-link>
        <span class="text-primary">•</span>
        <a href="#" class="text-link text-error" @click.prevent="deleteNews">{{ t('delete') }}</a>
      </p>
    </div>
    <p class="text-sm lg:text-base">
      {{ shortenedText }}
      <span v-if="showReadMore">
        ...
        <router-link :to="{ name: 'showNews', params: { id: news.id } }" class="text-link">{{
          t('readMore')
        }}</router-link>
      </span>
    </p>
    <div
      v-if="isDeletingNews"
      class="absolute inset-0 flex items-center justify-center bg-black/20"
    >
      <spinner />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Spinner from '@/components/icons/Spinner.vue';
import { UserRole } from '@/entities/auth/user-role.enum';
import type { News } from '@/entities/news/news.entity';
import { useFormattedDate } from '@/hooks/use-formatted-date';
import { useI18n } from 'vue-i18n';
import { useDeleteNews } from '@/queries/use-news';
import { useAuthStore } from '@/stores/auth.store';
import { Alert } from '@/utils/alert';
import { computed } from 'vue';

const props = defineProps<{
  news: News;
}>();

const { t } = useI18n();
const authStore = useAuthStore();

const { mutateAsync: deleteNewsAsync, isPending: isDeletingNews } = useDeleteNews();

const shortenedText = computed(() => props.news.content.slice(0, 180));
const showReadMore = computed(() => props.news.content.length > shortenedText.value.length);

const formattedDate = useFormattedDate(
  computed(() => props.news?.publishedDate ?? props.news?.createdDate),
);

const deleteNews = async () => {
  if (!props.news) return;
  const { isConfirmed } = await Alert.fire({
    text: t('reallyDeleteNewsText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('delete'),
    denyButtonText: t('cancel'),
  });
  if (!isConfirmed) return;
  await deleteNewsAsync(props.news.id);
};
</script>

<i18n lang="json">
{
  "en": {
    "readMore": "read more",
    "edit": "Edit",
    "delete": "Delete",
    "reallyDeleteNewsText": "Do you really want to delete this news?"
  },
  "de": {
    "readMore": "weiterlesen",
    "edit": "Bearbeiten",
    "delete": "Löschen",
    "reallyDeleteNewsText": "Möchtest du diese Neuigkeit wirklich löschen?"
  }
}
</i18n>
