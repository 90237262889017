<template>
  <div>
    <LabeledText v-if="booking" :label="t('relatedBooking')" class="mb-4">
      <p>{{ `${t('bookingNumber')} ${booking?.bookingNumber}` }}</p>
      <p>
        {{ booking?.customerData?.firstName ?? 'n/a' }}
        {{ booking?.customerData?.lastName ?? 'n/a' }}
      </p>
      <p>
        {{ booking?.car.vehicleType.make.name }}
        {{ booking?.car.vehicleType.model }}
      </p>
    </LabeledText>
    <CVButton v-if="!showInput" outline @click="showInput = true">{{
      t('switchBooking')
    }}</CVButton>
    <div v-else class="flex items-center justify-start gap-4">
      <SearchBookingInput
        class="w-full"
        :label="inputLabel"
        :error="errorMessage"
        @on-update-booking="onUpdateBooking"
      />
      <CVButton v-if="booking" variant="warning" @click="showInput = false">{{
        t('cancel')
      }}</CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import LabeledText from '@/components/LabeledText.vue';
import SearchBookingInput from '@/components/booking-forms/SearchBookingInput.vue';
import type { ManyBookingsBooking } from '@/entities/bookings/many-bookings-booking.entity';
import { useI18n } from 'vue-i18n';
import { useField } from 'vee-validate';
import { computed, ref } from 'vue';

const { t } = useI18n();

const booking = ref<ManyBookingsBooking>();
const showInput = ref(true);

const inputLabel = computed(() => (!booking.value ? t('linkBooking') : undefined));

const { value: bookingId, errorMessage } = useField<string | undefined>('bookingId');

const onUpdateBooking = (selectedBooking: ManyBookingsBooking | undefined) => {
  booking.value = selectedBooking ?? booking.value;
  bookingId.value = selectedBooking?.id ?? bookingId.value;
  if (selectedBooking) {
    showInput.value = false;
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "relatedBooking": "Related booking",
    "bookingNumber": "Bnr.",
    "switchBooking": "Switch booking",
    "linkBooking": "Link Booking"
  },
  "de": {
    "relatedBooking": "Verknüpfte Buchung",
    "bookingNumber": "Bnr.",
    "switchBooking": "Buchung wechseln",
    "linkBooking": "Buchung verknüpfen"
  }
}
</i18n>
