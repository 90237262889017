import { fetchEventSource } from '@microsoft/fetch-event-source';
import { Observable } from 'rxjs';
import { TOKEN_STORAGE_KEY } from './constants';
import { API_URL } from '@/constants/api-url.constant';

export class ServerSentEventService<T> {
  endpoint: string;
  private abortController = new AbortController();
  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  close() {
    this.abortController.abort();
  }

  open(): Observable<T> {
    return new Observable((observer) => {
      const token = localStorage.getItem(TOKEN_STORAGE_KEY);

      if (!token) {
        observer.error('No token found');
        return;
      }

      fetchEventSource(API_URL + this.endpoint, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        signal: this.abortController.signal,
        onmessage(ev) {
          if (ev.data) {
            try {
              const parsedData = JSON.parse(ev.data);
              observer.next(parsedData);
            } catch (err) {
              observer.error(err);
            }
          }
        },
        onerror(err) {
          observer.error(err);
        },
        onclose() {
          observer.complete();
        },
      });
    });
  }
}
