import { BookingType } from '@/entities/bookings/booking-type.enum';
import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { computed, type ComputedRef } from 'vue';
import { Tab } from './tab.enum';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import { FindAllBookingsSort } from '@/entities/bookings/find-all-bookings-sort.enum';
import { Order } from '@/entities/pagination/order.enum';
import { DateTime } from 'luxon';
import type {
  FindAllBookingsFilterDto,
  FindAllBookingsQueryDto,
} from '@/entities/bookings/booking.entity';

export const useGetBookingsQuery = (
  activeTab: MaybeRefOrGetter<Tab>,
  searchQuery: MaybeRefOrGetter<string>,
  currentStationId: MaybeRefOrGetter<string | undefined>,
  filterDate: MaybeRefOrGetter<Date>,
  bookingNumberQuery: MaybeRefOrGetter<number | undefined>,
) => {
  const getGeneralQueryParams = (
    customQueryParams?: FindAllBookingsFilterDto,
  ): FindAllBookingsFilterDto[] => {
    const searchQueryValue = toValue(searchQuery);
    const currentStationIdValue = toValue(currentStationId);
    const fixParams = {
      types: [BookingType.CUSTOMER_BOOKING],
      query: searchQueryValue,
      ...customQueryParams,
    };
    return currentStationIdValue
      ? [
          {
            ...fixParams,
            pickupStationIds: [currentStationIdValue],
          },
          {
            ...fixParams,
            dropoffStationIds: [currentStationIdValue],
          },
          {
            ...fixParams,
            carStationIds: [currentStationIdValue],
          },
        ]
      : [fixParams];
  };

  const getBookingsQuery = computed<FindAllBookingsQueryDto>(() => {
    const activeTabValue = toValue(activeTab);
    const filterDateValue = toValue(filterDate);
    const bookingNumberQueryValue = toValue(bookingNumberQuery);
    switch (activeTabValue) {
      case Tab.FLOATING:
        return {
          filter: getGeneralQueryParams({
            status: [BookingStatus.FLOATING],
          }),
          sort: FindAllBookingsSort.START_DATE,
          order: Order.ASC,
        };
      case Tab.RESERVED:
        return {
          filter: getGeneralQueryParams({
            status: [BookingStatus.OPEN],
          }),
          sort: FindAllBookingsSort.START_DATE,
          order: Order.ASC,
        };
      case Tab.ACTIVE:
        return {
          filter: getGeneralQueryParams({
            status: [
              BookingStatus.OPEN,
              BookingStatus.CONFIRMED,
              BookingStatus.HANDED_OVER,
              BookingStatus.CAR_RETURNED,
            ],
            startDateBefore: DateTime.fromJSDate(filterDateValue).endOf('day').toISO() ?? undefined,
            endDateAfter: DateTime.fromJSDate(filterDateValue).startOf('day').toISO() ?? undefined,
          }),
          sort: FindAllBookingsSort.START_DATE,
          order: Order.ASC,
        };
      case Tab.WITH_TRANSFER:
        return {
          filter: getGeneralQueryParams({
            hasTransfers: true,
            status: [
              BookingStatus.FLOATING,
              BookingStatus.OPEN,
              BookingStatus.CONFIRMED,
              BookingStatus.HANDED_OVER,
              BookingStatus.CAR_RETURNED,
            ],
            startDateAfter: DateTime.now().startOf('day').toISO(),
          }),
          sort: FindAllBookingsSort.START_DATE,
          order: Order.ASC,
        };
      case Tab.NOT_PAID:
        return {
          filter: getGeneralQueryParams({
            status: [BookingStatus.DRAFT],
          }),
          sort: FindAllBookingsSort.CREATED_DATE,
          order: Order.ASC,
        };
      default:
        return {
          filter: getGeneralQueryParams({
            bookingNumbers: bookingNumberQueryValue ? [bookingNumberQueryValue] : undefined,
            status: [
              BookingStatus.FLOATING,
              BookingStatus.OPEN,
              BookingStatus.CONFIRMED,
              BookingStatus.HANDED_OVER,
              BookingStatus.CAR_RETURNED,
              BookingStatus.CANCELED,
            ],
          }),
          sort: FindAllBookingsSort.START_DATE,
          order: Order.DESC,
        };
    }
  });

  return getBookingsQuery as ComputedRef<FindAllBookingsQueryDto>;
};
