import { Service } from './http-service';
import type { PaginatedResponse } from '@/entities/pagination/paginated-response';
import type {
  News,
  CreateNewsDto,
  UpdateNewsDto,
  FindAllNewsQueryDto,
} from '@/entities/news/news.entity';

class NewsService extends Service<News, CreateNewsDto, UpdateNewsDto> {
  async getAllWithQuery(params: FindAllNewsQueryDto) {
    const res = await this.httpClient.get<PaginatedResponse<News>>(this.endpoint, {
      params,
    });
    return res.data;
  }
}

export const newsService = new NewsService('/news');
