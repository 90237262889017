<template>
  <div class="grid grid-cols-3 gap-4">
    <InputField :label="t('company')" name="receiverData.company" />
    <InputField :label="t('firstName')" name="receiverData.firstName" />
    <InputField :label="t('lastName')" name="receiverData.lastName" />
    <InputField :label="t('street')" name="receiverData.street" />
    <div class="flex gap-x-2">
      <InputField :label="t('zip')" name="receiverData.zip" class="grow-0 basis-1/2" />
      <InputField :label="t('city')" name="receiverData.city" class="basis-1/2" />
    </div>
    <InputField :label="t('country')" name="receiverData.country" />
    <InputField :label="t('email')" name="receiverData.email" />
    <SelectField
      name="receiverData.preferredLocal"
      :label="t('preferredLocal')"
      :options="preferredLocalOptions"
    />
  </div>
</template>

<script lang="ts" setup>
import InputField from '@/components/InputField.vue';
import SelectField from '@/components/SelectField.vue';
import { Local } from '@/entities/auth/local.enum';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const { t } = useI18n();

const preferredLocalOptions = computed(() =>
  Object.values(Local).map((value) => ({
    label: t(`locales.${value}`),
    value,
  })),
);
</script>

<i18n lang="json">
{
  "en": {
    "firstName": "First Name",
    "lastName": "Last Name",
    "company": "Company",
    "email": "Email",
    "city": "City",
    "zip": "Zip",
    "street": "Street",
    "country": "Country",
    "preferredLocal": "Preferred Language"
  },
  "de": {
    "firstName": "Vorname",
    "lastName": "Nachname",
    "company": "Firma",
    "email": "Email",
    "city": "Stadt",
    "zip": "PLZ",
    "street": "Straße",
    "country": "Land",
    "preferredLocal": "Bevorzugte Sprache"
  }
}
</i18n>
