<template>
  <div class="mt-5 flex justify-center">
    <div
      class="flex h-[120px] w-[120px] items-center justify-center rounded-full border-[8px] border-booking-open-content"
    >
      <div class="relative mt-3 flex h-[120px] w-[120px] items-center justify-center">
        <div
          class="absolute h-2 w-[40px] -translate-x-[19px] translate-y-[11.8px] rotate-[40deg] bg-booking-open-content"
        ></div>
        <div
          class="absolute h-2 w-[70px] translate-x-[15px] -rotate-[55deg] rounded-l-lg bg-booking-open-content"
        ></div>
      </div>
    </div>
  </div>
</template>
