<template>
  <div class="grid grid-cols-3 gap-4">
    <SelectField
      name="fuelType"
      :label="t('fuelType')"
      :options="fuelTypes"
      empty-value-to="null"
      :hint="vehicleTypeValue('fuelType')"
    />

    <SelectField
      name="transmission"
      :label="t('transmission')"
      :options="transmissionTypes"
      empty-value-to="null"
      :hint="vehicleTypeValue('transmission')"
    />

    <SelectField
      name="drive"
      :label="t('drive')"
      :options="driveTypes"
      empty-value-to="null"
      :hint="vehicleTypeValue('drive')"
    />

    <InputField
      v-if="
        (!fuelType && vehicleType?.fuelType === FuelType.ELECTRIC) || fuelType === FuelType.ELECTRIC
      "
      name="batteryCapacityInKwh"
      :label="t('batteryCapacity')"
      adornment="kWh"
      type="number"
      empty-value-to="null"
      :hint="vehicleTypeValue('batteryCapacityInKwh')"
    />

    <InputField
      v-else
      name="tankVolumeInLiter"
      :label="t('tankVolume')"
      :placeholder="t('fromVehicleType')"
      adornment="l"
      type="number"
      empty-value-to="null"
      :hint="vehicleTypeValue('tankVolumeInLiter')"
    />

    <InputField
      v-if="
        (!fuelType && vehicleType?.fuelType === FuelType.ELECTRIC) || fuelType === FuelType.ELECTRIC
      "
      name="rangeInKm"
      :label="t('range')"
      :placeholder="t('fromVehicleType')"
      adornment="km"
      type="number"
      empty-value-to="null"
      :hint="vehicleTypeValue('rangeInKm')"
    />

    <InputField
      name="seats"
      :label="t('seats')"
      :placeholder="t('fromVehicleType')"
      :hint="vehicleTypeValue('seats')"
    />

    <InputField
      name="power"
      :label="t('power')"
      :placeholder="t('fromVehicleType')"
      adornment="PS"
      type="number"
      empty-value-to="null"
      :hint="vehicleTypeValue('power')"
    />

    <InputField
      name="accelaration"
      :label="t('accelaration')"
      :placeholder="t('fromVehicleType')"
      adornment="s"
      :max-fraction-digits="2"
      type="number"
      empty-value-to="null"
      :hint="vehicleTypeValue('accelaration')"
    />
  </div>
</template>

<script lang="ts" setup>
import InputField from '@/components/InputField.vue';
import SelectField from '@/components/SelectField.vue';
import { FuelType } from '@/entities/cars/fuel-type.enum';
import { useI18n } from 'vue-i18n';
import { TransmissionType } from '@/entities/cars/transmission-type.enum';
import { DriveType } from '@/entities/cars/drive-type.enum';
import type { VehicleType } from '@/entities/vehicle-type.entity';

const props = defineProps<{
  vehicleType?: VehicleType;
  fuelType: FuelType | undefined | null;
}>();

const { t } = useI18n();

const fuelTypes = Object.values({ EMPTY: null, ...FuelType }).map((value) => ({
  label: value ? t(`fuelTypes.${value}`) : t('fromVehicleType'),
  value,
}));

const transmissionTypes = Object.values({
  EMPTY: null,
  ...TransmissionType,
}).map((value) => ({
  label: value ? t(`transmissionTypes.${value}`) : t('fromVehicleType'),
  value,
}));

const driveTypes = Object.values({ EMPTY: null, ...DriveType }).map((value) => ({
  label: value ? t(`driveTypes.${value}`) : t('fromVehicleType'),
  value,
}));

const vehicleTypeValue = (key: string) => {
  if (!props.vehicleType) return null;
  const prefix = t('originalValue') + ': ';
  switch (key) {
    case 'fuelType':
      return prefix + t(`fuelTypes.${props.vehicleType.fuelType}`);
    case 'transmission':
      return prefix + t(`transmissionTypes.${props.vehicleType.transmission}`);
    case 'drive':
      return prefix + t(`driveTypes.${props.vehicleType.drive}`);
    case 'batteryCapacityInKwh':
      return prefix + props.vehicleType.batteryCapacityInKwh + ' kWh';
    case 'tankVolumeInLiter':
      return prefix + props.vehicleType.tankVolumeInLiter + ' l';
    case 'rangeInKm':
      return prefix + (props.vehicleType.rangeInKm ? props.vehicleType.rangeInKm + ' km' : '-');
    case 'seats':
      return prefix + props.vehicleType.seats;
    case 'power':
      return prefix + props.vehicleType.power + ' ' + t('hp');
    case 'accelaration':
      return prefix + props.vehicleType.accelaration + ' s';
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "fuelType": "Fuel Type",
    "transmission": "Transmission",
    "seats": "Seats",
    "power": "Power",
    "accelaration": "Acceleration (0 - 100)",
    "drive": "Drive",
    "tankVolume": "Tank Volume",
    "batteryCapacity": "Battery Capacity",
    "range": "Range",
    "fromVehicleType": "From Vehicle Type",
    "originalValue": "Original Value",
    "hp": "HP"
  },
  "de": {
    "fuelType": "Treibstofftyp",
    "transmission": "Getriebe",
    "seats": "Sitze",
    "power": "Leistung",
    "accelaration": "Beschleunigung (0 - 100)",
    "drive": "Antrieb",
    "tankVolume": "Tankgröße",
    "batteryCapacity": "Batteriekapazität",
    "range": "Reichweite",
    "fromVehicleType": "Von Vehicle Type",
    "originalValue": "Originalwert",
    "hp": "PS"
  }
}
</i18n>
