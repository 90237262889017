<template>
  <div
    ref="markerContainerRef"
    class="relative select-none overflow-hidden border border-primary/50"
    data-testid="marker-container"
    @click="addDamage"
  >
    <img ref="imageRef" src="@/assets/damage-map.jpg" class="w-full" crossorigin="anonymous" />
    <div v-if="damages">
      <span
        v-for="(dmg, index) in damages"
        :id="dmg.id"
        :key="dmg.id"
        class="absolute flex h-7 w-7 items-center justify-center rounded-full bg-red-500 text-lg font-medium text-white"
        :style="{
          top: `${dmg.positionY - 3}%`,
          left: `${dmg.positionX - 1}%`,
        }"
      >
        {{ !hidePositionNumbers ? index + 1 : '' }}
      </span>
    </div>
    <div v-else-if="newDamagePosition && editable">
      <span
        v-if="newDamagePosition.positionX && newDamagePosition.positionY"
        class="flex h-7 w-7 items-center justify-center rounded-full bg-red-500 text-lg font-medium text-white"
        :style="{
          top: `${newDamagePosition.positionY - 3}%`,
          left: `${newDamagePosition.positionX - 1}%`,
          position: 'absolute',
        }"
      ></span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Damage, Position } from '@/entities/damage.entity';
import { computed, ref } from 'vue';

const props = defineProps<{
  damages?: Damage[];
  damage?: Damage;
  newDamagePosition?: Position;
  editable?: boolean;
  hidePositionNumbers?: boolean;
}>();

const emits = defineEmits<{
  (e: 'update:newDamagePosition', newDamagePosition: Position): void;
}>();

const newDamagePosition = ref(props.newDamagePosition);

const markerContainerRef = ref<HTMLElement>();

const damages = computed(() => (props.damage ? [props.damage] : props.damages));

const addDamage = (e: MouseEvent) => {
  const width = markerContainerRef.value?.offsetWidth;
  const height = markerContainerRef.value?.offsetHeight;

  if (!width || !height) return;

  const positionX = Math.round((e.offsetX / width) * 10000) / 100;
  const positionY = Math.round((e.offsetY / height) * 10000) / 100;
  newDamagePosition.value = {
    positionX,
    positionY,
  };
  emits('update:newDamagePosition', newDamagePosition.value);
};
</script>
