<template>
  <h2 class="mb-4">
    {{ t('meterReadings') }}
  </h2>
  <div class="mb-6 grid grid-cols-6 space-x-6">
    <VehicleStatus
      v-if="booking"
      v-model:milage="formValues.milage"
      v-model:fuel-level="formValues.fuelLevel"
      class="col-span-4"
      :disabled="isSaving"
      :fuel-type="fueltype"
      :show-milage-warning="!!formValues.milage && formValues.milage < booking.car.milage"
      @on-fuel-level-touched="fuelLevelChanged = true"
    />

    <LabeledText v-if="booking.milageStart" class="col-span-2" :label="t('milageStart')">
      <p class="flex h-8 items-center">
        {{ `${booking.milageStart} km` }}
      </p>
    </LabeledText>
  </div>

  <CVButton
    v-if="!isSuccess || milageChanged || fuelLevelChanged"
    :is-loading="isSaving"
    :disabled="!formValues.milage"
    @click="updateCarStatus"
    >{{ t('save') }}</CVButton
  >

  <div v-if="booking.fuelLevelEnd != null && !!booking.milageEnd">
    <Divider />

    <Summary :booking="booking" />

    <Divider />

    <h2>{{ t('newDamages') }}</h2>
    <p v-for="(damage, i) in damages" :key="damage.id" class="mt-1">
      {{ `${i + 1}. ${damage.description}` }}
    </p>
    <div class="flex items-center justify-end space-x-1">
      <Information>
        <template #text>
          <div class="text-base font-medium lg:text-lg">
            {{ t('damagesInformationTitle') }}
          </div>
        </template>
        <template #modal>
          <div class="min-w-min">
            {{ t('damagesInformationText') }}
          </div>
        </template>
      </Information>
    </div>
    <Divider />
    <div class="flex justify-end">
      <ButtonNext
        :is-loading="isSaving"
        :disabled="isSaving || !booking.fuelLevelEnd || !booking.milageEnd"
        @click.prevent="debitBail"
        >{{ t('next') }}</ButtonNext
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import VehicleStatus from '@/components/booking-forms/VehicleStatus.vue';
import ButtonNext from '@/components/buttons/ButtonNext.vue';
import Divider from '@/components/Divider.vue';
import Information from '@/components/Information.vue';
import LabeledText from '@/components/LabeledText.vue';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import type { Booking } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';
import { useUpdateBooking } from '@/queries/use-bookings';
import { useUpdateCar } from '@/queries/use-cars';
import { useDamages } from '@/queries/use-damages';
import { computed, reactive, ref, toRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Summary from './components/Summary.vue';
import { cloneDeep } from 'lodash';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form';
import { formHasChanged } from '@/hooks/use-form-has-changed';

const { t } = useI18n();
const props = defineProps<{
  booking: Booking;
}>();
const route = useRoute();
const router = useRouter();

const booking = toRef(props, 'booking');
const fueltype = computed(
  () => props.booking.car.fuelType ?? props.booking.car.vehicleType.fuelType,
);

watch(booking, () => {
  if (getInitialMilageValues() !== unchangedValues.value.milage) {
    formValues.milage = getInitialMilageValues();
    unchangedValues.value.milage = formValues.milage;
  }
  if (getInitialFuelLevelValues() !== unchangedValues.value.fuelLevel) {
    formValues.fuelLevel = getInitialFuelLevelValues();
    unchangedValues.value.fuelLevel = formValues.fuelLevel;
  }
});

const getInitialMilageValues = () => {
  return booking.value.milageEnd;
};

const getInitialFuelLevelValues = () => {
  return (
    booking.value.fuelLevelEnd ?? booking.value.fuelLevelStart ?? booking.value.car?.fuelLevel ?? 0
  );
};

const formValues = reactive({
  milage: getInitialMilageValues(),
  fuelLevel: getInitialFuelLevelValues(),
});

const { data: damages } = useDamages({ bookingIds: booking.value.id });

const {
  isPending: isSavingBooking,
  mutateAsync: updateBooking,
  isSuccess: isBookingUpdateSuccess,
} = useUpdateBooking();
const {
  mutateAsync: mutateCar,
  isSuccess: isCarUpdateSuccess,
  isPending: isSavingCar,
} = useUpdateCar();
const isSuccess = computed(() => isBookingUpdateSuccess.value || isCarUpdateSuccess.value);

const isSaving = computed(() => isSavingBooking.value || isSavingCar.value);

const milageChanged = computed(() => {
  return getInitialMilageValues() !== formValues.milage;
});
const fuelLevelChanged = ref(false);

const unchangedValues = ref(cloneDeep(formValues));

const updateCarStatus = async () => {
  if (!booking.value?.car.id || !formValues.milage) return;
  fuelLevelChanged.value = false;

  unchangedValues.value = cloneDeep(formValues);

  await updateBooking({
    id: booking.value.id,
    booking: {
      milageEnd: formValues.milage,
      fuelLevelEnd: formValues.fuelLevel,
    },
  });
  await mutateCar({
    id: booking.value?.car.id,
    car: {
      milage: formValues.milage,
      fuelLevel: formValues.fuelLevel,
    },
  });
};

usePreventLeavingUnsavedForm(() => formHasChanged(unchangedValues.value, formValues));

const debitBail = async () => {
  if (!booking.value.fuelLevelEnd || !booking.value.milageEnd) return;
  await updateBooking({
    id: booking.value.id,
    booking: {
      status: BookingStatus.CAR_RETURNED,
    },
  });
  router.push({
    name: 'returnDone',
    params: { id: route.params.id },
  });
};
</script>

<i18n lang="json">
{
  "en": {
    "back": "back to damages",
    "milesDriven": "Miles Driven",
    "enterMilage": "Please enter the meter milage",
    "summary": "Summary",
    "newDamages": "New Damages",
    "next": "Finish return and release deposit",
    "milageStart": "Milage at rental start",
    "meterReadings": "Meter Readings",
    "damagesInformationTitle": "Damages are calculated separately",
    "damagesInformationText": "Do not make any statement about the repair costs. The claims department will take care of it. Normally, a cost estimate or expert opinion is provided within 2 weeks. Deposit is retained."
  },
  "de": {
    "back": "Zurück zu den Schäden",
    "milesDriven": "Gefahrene Kilometer",
    "enterMilage": "Bitte geben Sie den Kilometerstand an",
    "summary": "Zusammenfassung",
    "milesIncluded": "Inklusive Kilometer",
    "newDamages": "Neue Schäden",
    "next": "Rückgabe beenden und Kaution freigeben",
    "milageStart": "Kilometerstand bei Mietbeginn",
    "meterReadings": "Zählerstände",
    "damagesInformationTitle": "Schäden werden gesondert gerechnet",
    "damagesInformationText": "Keine Aussage zur Reparaturkosten machen. Schadensabteilung kümmert sich. Im Normalfall innerhalb von 2 Wochen Kostenvoranschlag oder Gutachten. Kaution wird einbehalten."
  }
}
</i18n>
