import type {
  CreateVehicleExtraDto,
  UpdateVehicleExtraDto,
  VehicleExtra,
} from '@/entities/vehicle-extra.entity';
import { Service } from './http-service';

export const vehicleExtrasService = new Service<
  VehicleExtra,
  CreateVehicleExtraDto,
  UpdateVehicleExtraDto
>('/vehicle-extras');
