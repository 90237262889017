<template>
  <div class="col-span-2 grid grid-cols-2 gap-x-4 gap-y-2">
    <Select
      v-model="pickupLocation.locationType"
      :options="locationTypeOptions"
      :label="t('startLocation')"
    />

    <Select
      v-model="dropoffLocation.locationType"
      :options="locationTypeOptions"
      :label="t('endLocation')"
    />

    <Select
      v-if="pickupLocation.locationType === BookingLocationType.STATION"
      v-model="pickupStationId"
      :options="stationOptions"
    />

    <SearchLocation v-else v-model:location="pickupLocation.location" />

    <Select
      v-if="dropoffLocation.locationType === BookingLocationType.STATION"
      v-model="dropoffStationId"
      :options="stationOptions"
    />

    <SearchLocation v-else v-model:location="dropoffLocation.location" />
  </div>
</template>

<script lang="ts" setup>
import Select from '@/components/Select.vue';
import SearchLocation from '@/components/booking-forms/SearchLocation.vue';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import type { BookingLocation } from '@/entities/bookings/booking.entity';
import type { Station } from '@/entities/station/station.entity';
import { useI18n } from 'vue-i18n';
import { computed, ref, watch, watchEffect } from 'vue';
import { cloneDeep } from 'lodash';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

const props = defineProps<{
  stations: Station[];
  pickupLocation: BookingLocation | null;
  dropoffLocation: BookingLocation | null;
}>();

const emit = defineEmits<{
  (
    event: 'change',
    value: {
      pickupLocation: BookingLocation;
      dropoffLocation: BookingLocation;
    },
  ): void;
}>();

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();

const pickupLocation = ref(cloneDeep(props.pickupLocation) ?? getEmptyBookingLocation());
const dropoffLocation = ref(cloneDeep(props.dropoffLocation) ?? getEmptyBookingLocation());
const pickupStationId = ref<string | null>(null);
const dropoffStationId = ref<string | null>(null);
watchEffect(
  () => (pickupLocation.value = cloneDeep(props.pickupLocation) ?? getEmptyBookingLocation()),
);
watchEffect(
  () => (dropoffLocation.value = cloneDeep(props.dropoffLocation) ?? getEmptyBookingLocation()),
);
watchEffect(() => (pickupStationId.value = props.pickupLocation?.station?.id ?? null));
watchEffect(() => (dropoffStationId.value = props.dropoffLocation?.station?.id ?? null));

const locationTypeOptions = computed(() =>
  Object.entries(BookingLocationType).map(([key, value]) => ({
    label: t(`locationTypes.${key}`),
    value,
  })),
);

const stationOptions = computed(() => [
  {
    label: `- ${t('chooseStation')} -`,
    value: null,
  },
  ...props.stations.map((station) => ({
    label: getLocalizedField(station.info),
    value: station.id,
  })),
]);

function getEmptyBookingLocation() {
  return {
    locationType: BookingLocationType.STATION,
    station: null,
    location: null,
  };
}

watch(pickupStationId, () => {
  pickupLocation.value.station =
    props.stations.find((station) => station.id === pickupStationId.value) ?? null;
});
watch(dropoffStationId, () => {
  dropoffLocation.value.station =
    props.stations.find((station) => station.id === dropoffStationId.value) ?? null;
});

watch(
  [pickupLocation, dropoffLocation],
  () => {
    emit('change', {
      pickupLocation: cloneDeep(pickupLocation.value),
      dropoffLocation: cloneDeep(dropoffLocation.value),
    });
  },
  { deep: true },
);
</script>

<i18n lang="json">
{
  "en": {
    "startLocation": "Start Location",
    "endLocation": "End Location",
    "chooseStation": "Choose Station"
  },
  "de": {
    "startLocation": "Startort",
    "endLocation": "Endort",
    "chooseStation": "Station auswählen"
  }
}
</i18n>
