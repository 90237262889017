import * as yup from 'yup';
import { Local } from '@/entities/auth/local.enum';
import { ageVerification } from './validation-types/age-verification';
import { type MaybeRefOrGetter, toRef } from '@vueuse/core';
import { Gender } from '@/entities/auth/gender.enum';

export const customerSchema = (
  minAge: MaybeRefOrGetter<number>,
  bookingStart: MaybeRefOrGetter<string | Date>,
) => {
  return {
    company: yup.string().optional(),
    gender: yup.string().oneOf(Object.values(Gender)).required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    phone: yup.string().required(),
    street: yup.string().required(),
    zip: yup.string().required(),
    city: yup.string().required(),
    country: yup.string().required(),
    dateOfBirth: ageVerification(minAge, bookingStart),
    preferredLocal: yup.string().oneOf(Object.values(Local)).required(),
  };
};

export const customerOptionalSchema = (
  minAge: MaybeRefOrGetter<number>,
  bookingStart: MaybeRefOrGetter<string | Date>,
) => {
  return {
    company: yup.string().optional(),
    gender: yup.string().oneOf(Object.values(Gender)).required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    phone: yup.string().optional(),
    street: yup.string().optional(),
    zip: yup.string().optional(),
    city: yup.string().optional(),
    country: yup.string().optional(),
    dateOfBirth: ageVerification(minAge, bookingStart, true),
    preferredLocal: yup.string().oneOf(Object.values(Local)).required(),
  };
};

export const customerEmailSchema = (isRequired: MaybeRefOrGetter<boolean> = true) => ({
  email: yup
    .string()
    .email()
    .test('requireEmailIfRequired', 'email is a required field', (val) => {
      return toRef(isRequired).value ? !!val : true;
    }),
});

export const customerDocumentsSchema = {
  licenseNumber: yup.string().required(),
  licenseDate: yup.date().required(),
  licenseAuthority: yup.string().required(),
  idCardNumber: yup.string().required(),
  idCardDate: yup.date().required(),
  idCardAuthority: yup.string().required(),
};

export const customerEditSchema = (
  minAge: MaybeRefOrGetter<number>,
  bookingStart: MaybeRefOrGetter<string | Date>,
) => {
  return yup.object({
    ...customerOptionalSchema(minAge, bookingStart),
    ...customerEmailSchema(false),
  });
};

export const customerHandoverSchema = (
  minAge: MaybeRefOrGetter<number>,
  bookingStart: MaybeRefOrGetter<string | Date>,
  emailIsRequired: MaybeRefOrGetter<boolean>,
) => {
  return yup.object({
    ...customerSchema(minAge, bookingStart),
    ...customerEmailSchema(emailIsRequired),
    ...customerDocumentsSchema,
  });
};
