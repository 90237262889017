<template>
  <div class="m-auto w-full max-w-xl p-4 pt-16">
    <template v-if="!review.isError.value">
      <h1 v-if="review.data.value" class="text mb-8 text-pretty text-center text-2xl">
        {{ t('rateHandover', { name: review.data.value?.employee?.firstName }) }}
      </h1>
      <div v-else class="mx-auto mb-8 h-8 w-96 max-w-full animate-pulse rounded-lg bg-silver-100" />
      <div class="-mb-12 flex h-32 justify-center">
        <Transition
          enter-from-class="opacity-0 translate-y-4"
          enter-active-class="transition-all duration-1000"
          appear
        >
          <img
            v-if="review.data.value?.car?.imageUrl"
            class="h-32 w-auto"
            :src="review.data.value?.car.imageUrl"
          />
        </Transition>
      </div>
      <div
        ref="outerElement"
        class="pt-18 content- box-content rounded-lg bg-silver-100 p-4 pt-24 transition-all"
      >
        <div ref="innerElement">
          <div
            v-if="completeReview.isSuccess.value || review.data.value?.status === 'COMPLETED'"
            class="pb-4"
          >
            <h2 class="mb-8 text-center text-black">{{ t('thanks') }}</h2>
            <div
              v-if="rating /* rating can be undefined when already submitted */ && rating >= 4"
              class="text-pretty text-center text-black"
            >
              {{ t('veryGood', { name: review.data.value?.employee?.firstName }) }}
            </div>
            <div
              v-else-if="rating /* rating can be undefined when already submitted */ && rating <= 2"
              class="text-pretty text-center text-black"
            >
              {{ t('sorry') }}
            </div>
            <div v-else class="text-pretty text-center text-black">
              {{ t('getInTouch') }}
            </div>
          </div>
          <template v-else>
            <div class="mb-6 text-center font-medium">{{ t('questions') }}</div>
            <div class="mb-8 flex justify-center gap-1">
              <button
                v-for="i in 5"
                :key="i"
                class="border-none bg-transparent"
                :title="t(`rating.${i}`)"
                @click="rating = i"
              >
                <StarIcon
                  class="h-12 w-12"
                  :class="{
                    'fill-gold-500': i <= rating,
                    'fill-carvia-silver': i > rating,
                  }"
                />
              </button>
            </div>
            <textarea
              v-model="comment"
              :placeholder="t('feedback')"
              class="mb-4 w-full rounded-lg bg-silver-50 p-4 focus:outline focus:outline-2 focus:outline-offset-0 focus:outline-gold-500"
            />

            <button
              ref="button"
              class="color-primary text-md flex w-full flex-row items-center justify-center gap-2 rounded-full bg-black px-4 py-2 font-bold uppercase text-gold-500 transition-all enabled:active:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-70"
              :disabled="rating === 0 || review.isLoading.value"
              @click="handleCompleteReview"
            >
              <Transition
                enter-active-class="transition-all"
                enter-from-class="w-0"
                enter-to-class="w-4"
                exit-active-class="transition-all"
                exit-from-class="w-4"
                exit-to-class="w-0"
              >
                <div v-if="completeReview.isPending.value" class="overflow-hidden">
                  <Spinner class="h-4 w-4" />
                </div>
              </Transition>
              {{ t('submit') }}
            </button>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mb-8 flex flex-col gap-4 text-center">
        <h2>{{ t('error') }}</h2>
        <p>{{ t('errorDescription') }}</p>
      </div>
    </template>
    <div class="mt-4 text-center text-xs">
      <a :href="t('imprintLink')" class="text-gray-700">{{ t('imprint') }}</a>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import StarIcon from '@/assets/icons/star.svg?component';
import { useResizeObserver } from '@vueuse/core';
import confetti from 'canvas-confetti';
import { useCompleteReview, useReview } from '@/queries/use-reviews';
import { useRoute } from 'vue-router';
import { createToast } from 'mosha-vue-toastify';

const { t } = useI18n();
const rating = ref(0);
const innerElement = ref<HTMLDivElement>();
const outerElement = ref<HTMLDivElement>();
const button = ref<HTMLButtonElement>();
const route = useRoute();
const review = useReview(route.params.id as string, route.query.key as string);
const completeReview = useCompleteReview(
  (vars) => {
    if (vars.review.rating! >= 4) {
      confetti({
        origin: {
          x: (button.value!.offsetLeft + button.value!.offsetWidth / 2) / window.innerWidth,
          y: (button.value!.offsetTop + button.value!.offsetHeight / 2) / window.innerHeight,
        },
        colors: ['#CDAF74'],
      });
    }
  },
  () => {
    createToast(t('errorDescription'), {
      position: 'bottom-center',
      type: 'danger',
    });
  },
);
const comment = ref('');

useResizeObserver(innerElement, (entries) => {
  const entry = entries[0];
  const { height } = entry.contentRect;
  outerElement.value!.style.height = `${height}px`;
});

function handleCompleteReview() {
  completeReview.mutate({
    id: route.params.id as string,
    key: route.query.key as string,
    review: {
      rating: rating.value,
      comment: comment.value,
    },
  });
}
</script>
<i18n lang="json">
{
  "en": {
    "submit": "Submit Review",
    "imprint": "Imprint",
    "rateHandover": "Rate Handover by {name}",
    "questions": "How would you rate the handover?",
    "thanks": "Thanks for your feedback!",
    "veryGood": "{name} is happy to hear that you enjoyed the handover.",
    "sorry": "We're sorry to hear that you had a bad experience. We'll get in touch to see how we can improve the experience.",
    "getInTouch": "If you have any comments or ideas, please get in touch with our customer care team.",
    "feedback": "Your feedback",
    "imprintLink": "https://www.carvia.com/imprint/",
    "pleaseWait": "Please wait...",
    "error": "Error",
    "errorDescription": "Unfortunately an error occurred. Please try again later.",
    "rating": {
      "1": "Very Bad",
      "2": "Bad",
      "3": "Average",
      "4": "Good",
      "5": "Very Good"
    }
  },
  "de": {
    "submit": "Bewertung absenden",
    "imprint": "Impressum",
    "rateHandover": "Übergabe von {name} bewerten",
    "questions": "Wie zufrieden Sind sie mit dem Service bei der Übergabe?",
    "thanks": "Danke für Ihr Feedback!",
    "veryGood": "{name} freut sich über Ihre Bewertung.",
    "sorry": "Es tut uns leid, dass Sie eine schlechte Erfahrung hatten. Wir werden Sie in Kürze kontaktieren, um unseren Prozess zu verbessern.",
    "getInTouch": "Wenn Sie weitere Kommentare oder Vorschläge haben, kontaktieren Sie bitte unser Customer-Care-Team.",
    "feedback": "Ihr Feedback",
    "imprintLink": "https://www.carvia.de/impressum/",
    "pleaseWait": "Bitte warten...",
    "error": "Fehler",
    "errorDescription": "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    "rating": {
      "1": "Sehr schlecht",
      "2": "Schlecht",
      "3": "Durchschnittlich",
      "4": "Gut",
      "5": "Sehr gut"
    }
  }
}
</i18n>
