<template>
  <router-link
    class="vehicle-card"
    :to="{
      name: 'vehicleTypeCarsList',
      params: { id: vehicleType.id },
    }"
  >
    <figure class="basis-[14%]">
      <img :src="vehicleType.imageUrl" />
    </figure>

    <div class="ml-3 flex basis-[25%] flex-col space-y-[2px]">
      <p class="text-primary">{{ t('vehicleTypes') }}</p>
      <p>{{ vehicleType.make.name }} {{ vehicleType.model }}</p>
    </div>

    <div class="flex basis-[21%] flex-col space-y-[2px]">
      <p class="text-primary">{{ t('category') }}</p>
      <p>{{ t(`categories.${vehicleType.mainCategory}`) ?? 'n.a.' }}</p>
    </div>

    <div class="flex basis-[11%] flex-col space-y-[2px]">
      <p class="text-primary">{{ t('number') }}</p>
      <p>{{ vehicleType.cars.length }}</p>
    </div>

    <div
      class="flex w-56 basis-[29%] items-center justify-between space-x-2 self-stretch border border-primary p-1 px-3 text-primary"
    >
      <p>{{ t('goToCars') }}</p>
      <p class="text-xl">→</p>
    </div>
    <div v-if="!hideDropdownDotted">
      <DropdownDotted
        :buttons="[
          {
            title: t('editVehicleType'),
            onClick: () =>
              $router.push({
                name: 'editVehicleType',
                params: { id: vehicleType.id },
              }),
          },
        ]"
        class="m-auto"
      />
    </div>
  </router-link>
</template>

<script lang="ts" setup>
import DropdownDotted from '@/components/DropdownDotted.vue';
import type { ManyVehicleTypesVehicleType } from '@/entities/many-vehicle-types-vehicle-type.entity';
import { useI18n } from 'vue-i18n';

defineProps<{
  vehicleType: ManyVehicleTypesVehicleType;
  hideDropdownDotted?: boolean;
}>();

const { t } = useI18n();
</script>

<style scoped>
.vehicle-card {
  @apply relative flex w-full cursor-pointer items-center gap-2 overflow-visible
    border border-primary py-1.5 pl-2 pr-2 transition-shadow hover:shadow-md lg:text-sm;
}
</style>

<i18n lang="json">
{
  "en": {
    "numberOfCars": "Number of cars",
    "vehicleTypes": "Vehicle Types",
    "category": "Category",
    "number": "Number",
    "editVehicleType": "Edit Vehicle Type",
    "goToCars": "Go to the individual cars"
  },
  "de": {
    "numberOfCars": "Anzahl Autos",
    "vehicleTypes": "Vehicle Types",
    "category": "Kategorie",
    "number": "Anzahl",
    "editVehicleType": "Vehicle Type bearbeiten",
    "goToCars": "Weiter zu den einzelnen Fahrzeugen"
  }
}
</i18n>
