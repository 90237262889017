<template>
  <div class="mb-4 mt-6 border border-primary p-4">
    <div class="mb-4 grid grid-cols-3 items-start gap-4 border-b border-primary pb-4">
      <InputField name="name" :label="t('name')" />
      <SelectField name="type" :label="t('documentType')" :options="documentTypes" />
      <FileInputField name="file" :label="t('upload')" />
    </div>
    <div class="flex justify-end gap-2">
      <CVButton :disabled="isUploadingCarDocument" @click.prevent="$emit('complete')">
        {{ t('cancel') }}
      </CVButton>
      <CVButton :is-loading="isUploadingCarDocument" @click.prevent="saveDocument">
        {{ t('saveDocument') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import FileInputField from '@/components/FileInputField.vue';
import InputField from '@/components/InputField.vue';
import SelectField from '@/components/SelectField.vue';
import { CarDocumentType } from '@/entities/cars/car-document-type.enum';
import type { CarDocument } from '@/entities/cars/car-document.entity';
import type { Car } from '@/entities/cars/car.entity';
import { useUploadAndCreateCarDocument } from '@/hooks/use-upload-and-create-car-document';
import { useI18n } from 'vue-i18n';
import { Alert } from '@/utils/alert';
import { toTypedSchema } from '@vee-validate/yup';
import { useForm } from 'vee-validate';
import { computed } from 'vue';
import * as yup from 'yup';

const props = defineProps<{
  car: Car;
  carDocuments: CarDocument[];
}>();

const emit = defineEmits<{
  (e: 'complete'): void;
}>();

const { t } = useI18n();

const { uploadAndCreateCarDocument, isPending: isUploadingCarDocument } =
  useUploadAndCreateCarDocument();

const { handleSubmit } = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      name: yup.string().required(),
      type: yup.string().oneOf(Object.values(CarDocumentType)).required(),
      file: yup.mixed().required(),
    }),
  ),
});

const saveDocument = handleSubmit(async (values) => {
  await uploadAndCreateCarDocument(props.car.id, values.file as File, values.type, values.name);
  if (values.type === CarDocumentType.ORDER_CONFIRMATION) {
    Alert.fire({
      icon: 'warning',
      html: t('checkIfOrderConfirmationIsCorrect'),
      showConfirmButton: true,
      confirmButtonText: t('understood'),
    });
  }
  emit('complete');
});

const documentTypes = computed(() =>
  Object.entries(CarDocumentType)
    .filter(([key]) =>
      key === CarDocumentType.OTHER
        ? true
        : !props.carDocuments.some((document) => {
            return document.type === key;
          }),
    )
    .map(([key, value]) => ({
      label: t(`carDocumentTypes.${key}`),
      value,
    })),
);
</script>

<i18n lang="json">
{
  "en": {
    "name": "Name",
    "documentType": "Document Type",
    "upload": "Upload",
    "saveDocument": "Save Document",
    "checkIfOrderConfirmationIsCorrect": "<span class='font-medium'>PLEASE CHECK NOW</span> if purchase price and equipment match the purchase contract. If there are any discrepancies, <span class='font-medium'>PLEASE RAISE ALARM!</span>",
    "understood": "Understood"
  },
  "de": {
    "name": "Name",
    "documentType": "Dokumententyp",
    "upload": "Hochladen",
    "saveDocument": "Dokument Speichern",
    "checkIfOrderConfirmationIsCorrect": "<span class='font-medium'>BITTE ÜBERPRÜFE JETZT</span>, ob Einkaufspreis und Ausstattung mit dem Kaufvertrag übereinstimmen. Bei Abweichungen <span class='font-medium'>BITTE ALARM SCHLAGEN!</span>",
    "understood": "Verstanden"
  }
}
</i18n>
