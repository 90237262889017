<template>
  <div class="max-w-full grow">
    <div v-if="booking && isGlobalSettingsSuccess && !isSurveysConductedLoading">
      <h2 class="mb-4">
        {{ t('handoverOverview') }}
      </h2>
      <HandoverOverviewForm :booking="booking" @on-submit="handleSubmit" />
    </div>
    <div v-else class="flex justify-center">
      <Spinner />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useBookingOfType } from '@/queries/use-bookings';
import { useRoute, useRouter } from 'vue-router';
import HandoverOverviewForm from './HandoverOverviewForm.vue';
import { useGlobalSettings } from '@/queries/use-global-settings';
import { useSurveysConductedWithQuery } from '@/queries/use-surveys-conducted';
import { computed } from 'vue';
import Spinner from '@/components/icons/Spinner.vue';
import { BookingType } from '@/entities/bookings/booking-type.enum';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const { data: booking } = useBookingOfType(
  route.params.id as string,
  true,
  BookingType.CUSTOMER_BOOKING,
  'upcomingHandovers',
);
const { data: globalSettings, isSuccess: isGlobalSettingsSuccess } = useGlobalSettings();
const { data: surveysConducted, isLoading: isSurveysConductedLoading } =
  useSurveysConductedWithQuery(
    computed(() => ({
      filter: [
        {
          bookingIds: [route.params.id as string],
          ...(globalSettings.value?.showHandoverSurvey
            ? { surveyIds: [globalSettings.value.showHandoverSurvey] }
            : {}),
        },
        ...(booking.value?.customer
          ? [
              {
                customerIds: [booking.value?.customer?.id],
                ...(globalSettings.value?.showHandoverSurvey
                  ? { surveyIds: [globalSettings.value.showHandoverSurvey] }
                  : {}),
              },
            ]
          : []),
      ],
    })),
    computed(() => !!(booking.value?.id && globalSettings.value)),
  );

const handleSubmit = async () => {
  router.push(
    !globalSettings.value?.showHandoverSurvey || surveysConducted.value?.items.length
      ? {
          name: 'handoverClientData',
          params: { id: route.params.id },
        }
      : {
          name: 'handoverSurvey',
          params: {
            id: route.params.id,
            surveyId: globalSettings.value?.showHandoverSurvey,
          },
        },
  );
};
</script>

<i18n lang="json">
{
  "en": {
    "handoverOverview": "Handover overview"
  },
  "de": {
    "handoverOverview": "Übergabeübersicht"
  }
}
</i18n>
