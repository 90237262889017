<template>
  <h2>{{ t('vehicle') }}</h2>
  <div class="mt-4 grid grid-cols-2 gap-4">
    <figure class="">
      <img :src="imageUrl" :height="300" :width="600" />
    </figure>
    <div>
      <p class="text-base font-medium">
        {{ car?.vehicleType?.make.name }}
        {{ car?.vehicleType?.model }}
      </p>

      <p class="font-normal">{{ car?.licencePlate }}</p>
      <p class="font-normal">{{ t('color') }}: {{ t(`carColors.${car?.color}`) }}</p>

      <div v-if="bookingId">
        <CVButton class="mt-2" outline :to="{ name: 'bookingEdit', params: { id: bookingId } }">
          {{ t('editBooking') }}
        </CVButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import type { Car } from '@/entities/cars/car.entity';
import type { CarWithPricing } from '@/views/handover/components/overview/HandoverOverviewForm.vue';
import { computed } from 'vue';

const props = defineProps<{
  car: Car | CarWithPricing;
  bookingId?: string;
}>();

const imageUrl = computed(() => props.car.imageUrl ?? props.car.vehicleType.imageUrl);

const { t } = useI18n();
</script>

<i18n lang="json">
{
  "en": {
    "editBooking": "Edit Booking",
    "vehicle": "Vehicle",
    "color": "Color"
  },
  "de": {
    "editBooking": "Buchung bearbeiten",
    "vehicle": "Fahrzeug",
    "color": "Farbe"
  }
}
</i18n>
