<template>
  <a
    href="#"
    class="vehicle-card"
    :class="{ 'opacity-50': car.status === CarStatus.FLOAT_OUT }"
    @click.prevent="
      () =>
        onClickFunction
          ? onClickFunction()
          : $router.push({
              name: cannotEdit ? 'viewCar' : 'editCar',
              params: { id: props.car.id },
            })
    "
  >
    <figure class="basis-[14%]">
      <img :src="imageUrl" />
    </figure>

    <div class="ml-6 flex basis-[23%] flex-col space-y-[2px]">
      <p class="title">Name</p>
      <p>{{ vehicleType?.make.name }} {{ vehicleType?.model }}</p>
    </div>

    <div class="flex basis-[12%] flex-col space-y-[2px]">
      <p class="title">{{ t('color') }}</p>
      <p>{{ car.color ? t(`carColors.${car.color}`) : '-' }}</p>
    </div>

    <div class="flex basis-[32%] space-x-2">
      <div class="flex flex-col space-y-[2px]">
        <p class="title">{{ t('lp') + '.' }}</p>
        <p class="title">FIN</p>
      </div>

      <div class="flex flex-col space-y-[2px]">
        <p>
          {{ car.licencePlate }}
        </p>
        <p>{{ car.fin }}</p>
      </div>
    </div>

    <div class="flex basis-[19%] flex-col space-y-[2px]">
      <p class="title">{{ t('station') }}</p>
      <p>{{ currentStation.city }}</p>
    </div>

    <div>
      <DropdownDotted :buttons="dropDownItems" @click.prevent="" />
    </div>
  </a>
</template>

<script lang="ts" setup>
import DropdownDotted, { type DropdownItem } from '@/components/DropdownDotted.vue';
import type { Car } from '@/entities/cars/car.entity';
import type { VehicleType } from '@/entities/vehicle-type.entity';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { computed, toRef } from 'vue';
import { useCurrentStationOfCar } from '@/hooks/use-current-station-of-car';
import { CarStatus } from '@/entities/cars/car-status.enum';

const props = defineProps<{
  car: Car;
  vehicleType?: VehicleType;
  dropDownItems?: DropdownItem[];
  cannotEdit?: boolean;
  onClickFunction?: () => void;
}>();
const { t } = useI18n();

const router = useRouter();

const imageUrl = computed(() => props.car.imageUrl ?? props.vehicleType?.imageUrl);

const currentStation = useCurrentStationOfCar(toRef(props, 'car'));

const dropDownItems = computed<DropdownItem[]>(
  () =>
    props.dropDownItems ?? [
      {
        title: t('addMaintenanceBlock'),
        onClick: () =>
          router.push({
            name: 'maintenanceEdit',
            params: { carId: props.car.id, blockId: 'new' },
          }),
      },
      {
        title: t('addPartnerRent'),
        onClick: () =>
          router.push({
            name: 'partnerRentEdit',
            params: { carId: props.car.id, bookingId: 'new' },
          }),
      },
    ],
);
</script>

<style scoped>
.vehicle-card {
  @apply relative flex w-full cursor-pointer items-center gap-2 overflow-visible
    border border-primary py-1 pl-2 pr-2 transition-shadow hover:shadow-md lg:text-sm;
}
.title {
  @apply font-medium text-primary;
}
</style>

<i18n lang="json">
{
  "en": {
    "numberOfCars": "Number of cars",
    "vehicleTypes": "Vehicle Types",
    "color": "Color",
    "lp": "Lp",
    "addMaintenanceBlock": "+ Add Maintenance Block",
    "addPartnerRent": "+ Add Partner Rent"
  },
  "de": {
    "numberOfCars": "Anzahl Autos",
    "vehicleTypes": "Vehicle Types",
    "color": "Farbe",
    "lp": "Knz",
    "addMaintenanceBlock": "+ Wartungsblock hinzufügen",
    "addPartnerRent": "+ Partnermiete hinzufügen"
  }
}
</i18n>
