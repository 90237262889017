<template>
  <div class="space-y-4">
    <h2>
      {{
        t('youGetToInaugurate', {
          name: authStore.user?.firstName ?? '',
          car: car.vehicleType.make.name + ' ' + car.vehicleType.model,
        })
      }}
    </h2>

    <p class="text-lg font-medium">1. {{ t('headerCarDelivered') }}</p>

    <div class="grid grid-cols-3 gap-4">
      <LabeledText :label="t('vehicleModel')">{{
        car.vehicleType.make.name + ' ' + car.vehicleType.model
      }}</LabeledText>

      <LabeledText :label="t('color')">{{
        car.color ? t(`carColors.${car.color}`) : '-'
      }}</LabeledText>
    </div>

    <Checkbox v-model="carHasBeenDelivered">{{ t('carIsActuallyInFrontOfMe') }}</Checkbox>

    <Divider />

    <p class="text-lg font-medium">2. {{ t('headerRegistrationDocument') }}</p>

    <Checkbox v-model="registrationDocumentIsInCar">{{
      t('registrationDocumentIsInCar')
    }}</Checkbox>

    <Divider />

    <p class="text-lg font-medium">3. {{ t('headerEnvironmentalBadge') }}</p>

    <Checkbox v-model="environmentalBadgeIsThere">{{ t('environmentalBadgeIsAffixed') }}</Checkbox>

    <Divider />

    <p class="text-lg font-medium">4. {{ t('headerAccidentEquipment') }}</p>

    <Checkbox v-model="accidentEquipmentIsThere">{{ t('accidentEquipmentIsThere') }}</Checkbox>

    <Divider />

    <p class="text-lg font-medium">5. {{ t('headerKeys') }}</p>

    <Checkbox v-model="keysAreThere">{{ t('keysAreThere') }}</Checkbox>

    <Divider />

    <p class="text-lg font-medium">6. {{ t('headerPhotos') }}</p>

    <div class="grid grid-cols-3 gap-4">
      <FileInputField name="frontImage" :label="t('fromTheFront')" />
      <FileInputField name="backImage" :label="t('fromTheBack')" />
    </div>

    <Divider />

    <p class="text-lg font-medium">7. {{ t('headerTakeYourTimeWithTheCar') }}</p>

    <Checkbox v-model="iKnowTheCar">{{ t('iKnowTheCar') }}</Checkbox>

    <div class="grid grid-cols-3 gap-4">
      <InputField
        name="initialMilage"
        type="number"
        :label="t('howManyKmDoesTheCarHave')"
        adornment="km"
      />
    </div>

    <Checkbox v-model="carIsConnectedWithTheApp">
      <Information>
        <template #text>
          {{ t('carIsConnectedWithTheApp') }}
        </template>
        <template #modal>
          {{ t('carIsConnectedWithTheAppTooltip') }}
        </template>
      </Information>
    </Checkbox>

    <Checkbox v-model="GpsConsultationHasBeenHeld">
      <Information>
        <template #text>
          {{ t('gpsConsultationHasBeenHeld') }}
        </template>
        <template #modal>
          {{ t('gpsConsultationHasBeenHeldTooltip') }}
        </template>
      </Information>
    </Checkbox>

    <Divider />

    <p class="text-lg font-medium">8. {{ t('headerReadyForFirstRental') }}</p>
    <div>
      <p>{{ t('afterFleetInIsDone') }}</p>
      <ol class="list-decimal pl-5">
        <li>{{ t('refuelCar') }}</li>
        <li>{{ t('lookForDamages') }}</li>
      </ol>
    </div>

    <Divider />

    <div class="flex justify-end">
      <CVButton :disabled="!allCheckboxesChecked" :is-loading="isSaving" @click.prevent="submit">
        {{ t('completeFleetIn') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Checkbox from '@/components/Checkbox.vue';
import Divider from '@/components/Divider.vue';
import FileInputField from '@/components/FileInputField.vue';
import Information from '@/components/Information.vue';
import InputField from '@/components/InputField.vue';
import LabeledText from '@/components/LabeledText.vue';
import type { Car } from '@/entities/cars/car.entity';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth.store';
import { toTypedSchema } from '@vee-validate/yup';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import * as yup from 'yup';

export interface CompleteFleetInFormValues {
  frontImage: File;
  backImage: File;
  initialMilage: number;
}

defineProps<{
  car: Car;
  isSaving: boolean;
}>();

const emit = defineEmits<{
  (e: 'submit', values: CompleteFleetInFormValues): void;
}>();

const { t } = useI18n();
const authStore = useAuthStore();

const carHasBeenDelivered = ref(false);
const registrationDocumentIsInCar = ref(false);
const environmentalBadgeIsThere = ref(false);
const accidentEquipmentIsThere = ref(false);
const keysAreThere = ref(false);
const iKnowTheCar = ref(false);
const carIsConnectedWithTheApp = ref(false);
const GpsConsultationHasBeenHeld = ref(false);

const allCheckboxesChecked = computed(
  () =>
    carHasBeenDelivered.value &&
    registrationDocumentIsInCar.value &&
    environmentalBadgeIsThere.value &&
    accidentEquipmentIsThere.value &&
    keysAreThere.value &&
    iKnowTheCar.value &&
    carIsConnectedWithTheApp.value &&
    GpsConsultationHasBeenHeld.value,
);

const { handleSubmit } = useForm({
  initialValues: {
    frontImage: undefined,
    backImage: undefined,
    initialMilage: undefined,
  },
  validationSchema: toTypedSchema(
    yup.object({
      frontImage: yup.mixed().required(),
      backImage: yup.mixed().required(),
      initialMilage: yup.number().required(),
    }),
  ),
});

const submit = handleSubmit((values) => {
  emit('submit', {
    ...values,
    frontImage: values.frontImage as File,
    backImage: values.backImage as File,
  });
});
</script>

<i18n lang="json">
{
  "en": {
    "youGetToInaugurate": "{name}! You get to inaugurate the new {car}!",
    "vehicleModel": "Vehicle model",
    "color": "Color",
    "headerCarDelivered": "Please make sure that the correct car has been delivered",
    "headerRegistrationDocument": "Check if the copy of the registration document is in the vehicle (If not, please do it now)",
    "headerEnvironmentalBadge": "Environmental badge also there?",
    "headerAccidentEquipment": "Check if warning vest, first aid kit & warning triangle are available (If you don't know where the stuff is, you will get to know the car now)",
    "headerKeys": "Are both keys available & provided with key fobs?",
    "headerPhotos": "Say cheeese! (Take real photos of the vehicle and please make an effort!)",
    "headerTakeYourTimeWithTheCar": "And now the most important thing: Take your time with the vehicle and make sure that our theft protection works",
    "headerReadyForFirstRental": "Great, the car is ready for the first rental!",
    "carIsActuallyInFrontOfMe": "The car is actually in front of me exactly as indicated!",
    "registrationDocumentIsInCar": "The copy of the registration document is in the car!",
    "environmentalBadgeIsAffixed": "The environmental badge is affixed!",
    "accidentEquipmentIsThere": "Warning vest, first aid kit & warning triangle are in the car!",
    "keysAreThere": "Yep, keys are there and key fobs are properly attached",
    "fromTheFront": "From the front",
    "fromTheBack": "From the back",
    "iKnowTheCar": "I know where to look for KM, how to open the trunk, adjust the seat & change the language setting of the car",
    "howManyKmDoesTheCarHave": "How many KM does the car have?",
    "carIsConnectedWithTheApp": "The car has been connected to the manufacturer's app",
    "carIsConnectedWithTheAppTooltip": "You probably don't know how to do that. Therefore, please call the fleet management team to do the process together.",
    "gpsConsultationHasBeenHeld": "Consultation regarding installation of the GPS tracker has been held with fleet management",
    "gpsConsultationHasBeenHeldTooltip": "Maybe an additional GPS tracker is not necessary, but fleet management will be able to decide and coordinate the installation with you.",
    "afterFleetInIsDone": "After you have completed the fleet in, you will be referred to the vehicle status page:",
    "refuelCar": "If the tank/charge level is not yet full, please take care of it so that the first rental can start with full power",
    "lookForDamages": "In any case, take a closer look for damages, which would be the fault of the car dealer",
    "completeFleetIn": "Complete fleet in"
  },
  "de": {
    "youGetToInaugurate": "{name}! Du darfst den neuen {car} einweihen!",
    "vehicleModel": "Fahrzeugmodell",
    "color": "Farbe",
    "headerCarDelivered": "Bitte stelle sicher, dass der richtige Wagen geliefert wurde",
    "headerRegistrationDocument": "Schau nach, ob die Fahrzeugscheinkopie im Fahrzeug ist (Wenn nicht, bitte jetzt erledigen)",
    "headerEnvironmentalBadge": "Umweltplakette auch vorhanden?",
    "headerAccidentEquipment": "Kontrolliere, ob Warnweste, Verbandskasten & Warndreieck vorhanden sind (Wenn du nicht weißt, wo die Sachen liegen, dann lernst du jetzt das Auto kennen)",
    "headerKeys": "Sind beide Schlüssel vorhanden & mit Schlüsselanhängern versehen?",
    "headerPhotos": "Sag Cheeese! (Mach echte Fotos vom Fahrzeug und bitte gib dir Mühe!)",
    "headerTakeYourTimeWithTheCar": "Und jetzt das wichtigste: Nimm dir Zeit mit dem Fahrzeug und stelle sicher, dass unsere Diebstahlsicherung funktioniert",
    "headerReadyForFirstRental": "Super, das Auto ist ready für die erste Vermietung!",
    "carIsActuallyInFrontOfMe": "Das Auto steht tatsächlich genauso wie angegeben vor mir!",
    "registrationDocumentIsInCar": "Die Fahrzeugscheinkopie ist im Auto!",
    "environmentalBadgeIsAffixed": "Die Umweltplakette klebt!",
    "accidentEquipmentIsThere": "Warnweste, Verbandskasten & Warndreieck liegen im Auto!",
    "keysAreThere": "Jap, Schlüssel sind da und Schlüsselanhänger sind ordnungsgemäß angebracht",
    "fromTheFront": "Von Vorne",
    "fromTheBack": "Von Hinten",
    "iKnowTheCar": "Ich weiß, wo man KM nachschaut, wie man den Kofferraum öffnet, den Sitz einstellt & und die Spracheinstellung vom Auto ändert",
    "howManyKmDoesTheCarHave": "Wieviel KM hat das Auto denn?",
    "carIsConnectedWithTheApp": "Das Auto wurde mit der Herstellerapp verbunden",
    "carIsConnectedWithTheAppTooltip": "Wahrscheinlich weißt du nicht, wie das geht. Daher ruf bitte das Team vom Fuhrpark an um den Prozess gemeinsam zu machen.",
    "gpsConsultationHasBeenHeld": "Rücksprache bzgl. Einbau des GPS Trackers wurde mit Fuhrparkmanagement gehalten",
    "gpsConsultationHasBeenHeldTooltip": "Evtl. ist auch kein zusätzlicher GPS Tracker nötig, aber das wird Fuhrpark entscheiden können und mit dir den Einbau koordinieren.",
    "afterFleetInIsDone": "Nachdem du die Einflottung abgeschlossen hast, kommst du auf die Fahrzeugstatusseite:",
    "refuelCar": "Wenn Tank/Ladestand noch nicht voll sein sollte, kümmer dich bitte darum, damit die erste Miete mit voller Power durchstarten kann",
    "lookForDamages": "Schau auf jeden Fall noch mal genau nach Schäden, die wären dann ja die Schuld des Fahrzeughändlers",
    "completeFleetIn": "Einflottung abschließen"
  }
}
</i18n>
