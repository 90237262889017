<template>
  <HeaderBar />

  <section class="carvia-container">
    <div class="mb-4 flex items-end justify-between">
      <Tabs v-model="activeTab" :tabs="tabs" />
      <CVButton :to="{ name: 'insuranceCase', params: { id: 'new' } }">{{
        t('createInsuranceCase')
      }}</CVButton>
    </div>

    <div class="flex flex-col gap-2">
      <InsuranceCaseListItem
        v-for="insuranceCase in insuranceCases"
        :key="insuranceCase.id"
        :insurance-case="insuranceCase"
      />
    </div>

    <EntityListExceptions
      :status="status"
      :error="error"
      :items-count="insuranceCases.length"
      :no-items-text="t('noInsuranceCasesFound')"
    />

    <div v-if="hasNextPage" class="flex justify-center pt-8">
      <CVButton outline :is-loading="isFetchingNextPage" @click="() => fetchNextPage()">
        {{ t('loadMore') }}
      </CVButton>
    </div>
  </section>
</template>

<script setup lang="ts">
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import EntityListExceptions from '@/components/EntityListExceptions.vue';
import InsuranceCaseListItem from './InsuranceCaseListItem.vue';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { useInsuranceCases } from '@/queries/use-insurance-cases';
import { InsuranceCaseStatus } from '@carvia/ros-client-types';
import { computed, ref, watchEffect } from 'vue';
import Tabs, { type TabItem } from '@/components/Tabs.vue';
import { useI18n } from 'vue-i18n';
import { useUiStore } from '@/stores/ui.store';

enum Tab {
  INITIAL = 'INITIAL',
  ACTIVE = 'ACTIVE',
  TO_CLOSE = 'TO_CLOSE',
  ALL = 'ALL',
}

const { t } = useI18n();
const uiStore = useUiStore();
const activeTab = ref<Tab>(Tab.INITIAL);

watchEffect(() => {
  uiStore.setHeaderTitle(t('insuranceCases'));
});

const filters: Record<Tab, InsuranceCaseStatus[]> = {
  [Tab.INITIAL]: [InsuranceCaseStatus.INITIAL],
  [Tab.ACTIVE]: [InsuranceCaseStatus.HANDED_OVER],
  [Tab.TO_CLOSE]: [InsuranceCaseStatus.RETURNED],
  [Tab.ALL]: [],
};

const query = computed(() => ({
  status: filters[activeTab.value],
}));

const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status, error } =
  useInsuranceCases(query);

const insuranceCases = useFlattenPaginatedData(data);

const tabs = computed<TabItem[]>(() => [
  {
    title: t('initial'),
    id: Tab.INITIAL,
  },
  {
    title: t('active'),
    id: Tab.ACTIVE,
  },
  {
    title: t('toClose'),
    id: Tab.TO_CLOSE,
  },
  {
    title: t('all'),
    id: Tab.ALL,
  },
]);
</script>

<i18n lang="json">
{
  "en": {
    "insuranceCases": "Insurance cases",
    "noInsuranceCasesFound": "No insurance cases found",
    "loadMore": "Load more",
    "initial": "Initial",
    "active": "Active",
    "toClose": "To close",
    "all": "All",
    "createInsuranceCase": "+ Create Insurance Case"
  },
  "de": {
    "insuranceCases": "Versicherungsfälle",
    "noInsuranceCasesFound": "Keine Versicherungsfälle gefunden",
    "loadMore": "Mehr laden",
    "initial": "Initial",
    "active": "Aktiv",
    "toClose": "Abzuschließen",
    "all": "Alle",
    "createInsuranceCase": "+ Versicherungsfall erstellen"
  }
}
</i18n>
