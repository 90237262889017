<template>
  <div class="mt-4 grid grid-cols-3 grid-rows-1 gap-4">
    <LabeledText :label="t('renter')"
      >{{ booking.customerData?.firstName ?? 'n/a' }}
      {{ booking.customerData?.lastName ?? 'n/a' }}</LabeledText
    >

    <LabeledText :label="t('bookingNumber')">{{ booking.bookingNumber }}</LabeledText>

    <div class="flex flex-col space-y-2">
      <LabeledText :label="t('customerComment')">
        {{ booking.customerComment || t('noComment') }}
      </LabeledText>
      <LabeledText :label="t('internalComment')">
        {{ booking.internalComment || t('noComment') }}
      </LabeledText>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Booking } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';
import LabeledText from '@/components/LabeledText.vue';

const { t } = useI18n();
defineProps<{
  booking: Booking;
}>();
</script>

<i18n lang="json">
{
  "en": {
    "customerComment": "Customer Comment",
    "internalComment": "Internal Comment",
    "returnOverview": "Return overview",
    "renter": "Renter",
    "bookingNumber": "Booking Nr.",
    "vehicle": "Vehicle",
    "startDate": "Start of rental",
    "endDate": "End of rental",
    "noComment": "No Comment"
  },
  "de": {
    "customerComment": "Kundenkommentar",
    "internalComment": "Interner Kommentar",
    "returnOverview": "Buchungsübersicht",
    "renter": "Mieter",
    "bookingNumber": "Buchungs Nr.",
    "vehicle": "Fahrzeug",
    "startDate": "Mietbeginn",
    "endDate": "Mietende",
    "noComment": "Kein Kommentar"
  }
}
</i18n>
