<template>
  <HeaderBar />

  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
  </ButtonBackContainer>

  <section class="carvia-container flex flex-col justify-center">
    <h2>{{ t('createInvoice') }}</h2>
    <div class="mt-4 grid grid-cols-2 gap-4">
      <SelectField class="w-full" name="type" :label="t('invoiceType')" :options="typeOptions" />
    </div>

    <div v-if="isBookingRelated">
      <Divider />
      <div class="mt-4 grid grid-cols-2 gap-4">
        <div class="col-start-1">
          <SelectBooking />
        </div>
      </div>
    </div>

    <div v-if="isGeneric || isCarSale">
      <Divider />
      <div class="mt-4 grid grid-cols-2 gap-4">
        <Select
          v-model="receiverType"
          :options="receiverTypeOptions"
          :label="t('setInvoiceReceiver')"
        />
      </div>
      <div v-if="receiverType === ReceiverType.MANUALLY" class="mt-4">
        <InvoiceReceiverForm />
      </div>
      <div v-else class="mt-4 grid grid-cols-2 gap-4">
        <SelectCustomer />
      </div>
    </div>

    <div v-if="isReversal">
      <Divider />
      <div class="mt-4 grid grid-cols-2 gap-4">
        <div class="col-start-1">
          <SelectOriginalInvoice />
        </div>
      </div>
    </div>

    <div>
      <div>
        <Divider />
        <div class="mt-4 grid grid-cols-2 gap-4">
          <InputField name="title" :label="t('invoiceTitle')" class="col-start-1" />
          <DatepickerField name="invoiceDate" :label="t('invoiceDate')" />
        </div>
      </div>
    </div>
    <InvoicePositionForm :invoice-type="formValues.type" />
    <Checkbox
      v-if="canSendInvoiceOnSave"
      v-model="sendInvoiceOnSave"
      :label="t('sendInvoiceOnSave')"
      class="mt-6"
    />
    <Divider />
    <div class="flex justify-end">
      <CVButton :is-loading="isLoading" @click.prevent="onSubmit">{{ t('saveInvoice') }}</CVButton>
    </div>
  </section>
</template>

<script lang="ts" setup>
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import DatepickerField from '@/components/DatepickerField.vue';
import InputField from '@/components/InputField.vue';
import SelectField from '@/components/SelectField.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { InvoiceType } from '@/entities/invoices/invoice-type.enum';
import type { CreateInvoiceDto, ReceiverData } from '@/entities/invoices/invoice.entity';
import { useInvoicePdfs } from '@/hooks/use-invoice-pdf';
import { useI18n } from 'vue-i18n';
import { useCreateInvoice } from '@/queries/use-invoices';
import { invoiceCreateSchema } from '@/validation/invoice.schema';
import { useForm } from 'vee-validate';
import { computed, ref, watch, watchEffect } from 'vue';
import InvoicePositionForm, { type PositionFormValues } from './InvoicePositionForm.vue';
import { useUiStore } from '@/stores/ui.store';
import Checkbox from '@/components/Checkbox.vue';
import Divider from '@/components/Divider.vue';
import SelectBooking from './SelectBooking.vue';
import SelectCustomer from './SelectCustomer.vue';
import Select from '@/components/Select.vue';
import InvoiceReceiverForm from './InvoiceReceiverForm.vue';
import SelectOriginalInvoice from './SelectOriginalInvoice.vue';
import { InvoiceStatus } from '@/entities/invoices/invoice-status.enum';
import router from '@/router';
import { Alert } from '@/utils/alert';

interface InvoiceFormValues {
  title: string;
  customerId?: string;
  bookingId?: string;
  receiverData?: ReceiverData;
  invoiceDate: string;
  positions: PositionFormValues[];
  type: InvoiceType;
  originalInvoiceId?: string;
}

enum ReceiverType {
  MANUALLY = 'MANUALLY',
  LINK_CUSTOMER = 'LINK_CUSTOMER',
}

const { t } = useI18n();
const uiStore = useUiStore();

const sendInvoiceOnSave = ref(true);
const receiverType = ref(ReceiverType.MANUALLY);

const canSendInvoiceOnSave = computed(() => formValues.type !== InvoiceType.GENERIC);

const receiverTypeOptions = computed(() =>
  Object.values(ReceiverType).map((value) => ({
    label: t(value),
    value,
  })),
);

watch(receiverType, () => {
  formValues.receiverData = undefined;
});

const typeOptions = computed(() =>
  Object.values([
    InvoiceType.GENERIC,
    InvoiceType.CAR_SALE,
    InvoiceType.DAMAGE,
    InvoiceType.ADMINISTRATIVE_OFFENCE,
    InvoiceType.OTHER,
    InvoiceType.REVERSAL,
  ]).map((type) => ({
    label: `${t(`invoiceTypes.${type}`)}${bookingRelated(type) ? ` (${t('bookingRelated')})` : ''}`,
    value: type,
  })),
);

const bookingRelated = (type: InvoiceType) => {
  if (
    type === InvoiceType.DAMAGE ||
    type === InvoiceType.ADMINISTRATIVE_OFFENCE ||
    type === InvoiceType.OTHER
  ) {
    return true;
  }
  return false;
};

const isBookingRelated = computed(() => bookingRelated(formValues.type));
const isGeneric = computed(() => formValues.type === InvoiceType.GENERIC);
const isCarSale = computed(() => formValues.type === InvoiceType.CAR_SALE);
const isReversal = computed(() => formValues.type === InvoiceType.REVERSAL);

const { mutateAsync: createInvoice, isPending: isCreating } = useCreateInvoice();
const { sendInvoiceToCustomer, isSending } = useInvoicePdfs();

const isLoading = computed(() => isCreating.value || isSending.value);

const { handleSubmit, values: formValues } = useForm<InvoiceFormValues>({
  initialValues: getEmptyFormValues(),
  validationSchema: invoiceCreateSchema,
});

const onSubmit = handleSubmit(async (values) => {
  const alertResult = await Alert.fire({
    titleText: t('reallyCreateInvoiceTitle'),
    text: t('reallyCreateInvoiceText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('confirmCreateInvoice'),
    denyButtonText: t('cancel'),
  });

  if (alertResult.isConfirmed) {
    const newInvoice: CreateInvoiceDto = {
      ...values,
      positions: values.positions.map((position) => ({
        title: position.title,
        amount: position.amount ?? 0,
        unitPrice: position.unitPrice ?? 0,
        tax: (position.tax ?? 0) / 100,
      })),
      status: InvoiceStatus.OPEN,
    };
    saveInvoice(newInvoice, sendInvoiceOnSave.value);
  }
});

const saveInvoice = async (newInvoice: CreateInvoiceDto, sendInvoiceOnSave: boolean) => {
  const { id } = await createInvoice(newInvoice);
  if (sendInvoiceOnSave) {
    await sendInvoiceToCustomer(id);
  }
  router.push({ name: 'invoice', params: { id } });
};

function getEmptyFormValues(): InvoiceFormValues {
  return {
    title: '',
    customerId: undefined,
    bookingId: undefined,
    receiverData: undefined,
    invoiceDate: new Date().toISOString(),
    positions: [],
    type: InvoiceType.GENERIC,
    originalInvoiceId: undefined,
  };
}

watchEffect(() => {
  uiStore.setHeaderTitle(t('createInvoice'));
});
</script>

<i18n lang="json">
{
  "en": {
    "createInvoice": "Create Invoice",
    "invoiceType": "Invoice Type",
    "invoiceDate": "Invoice Date",
    "originalInvoice": "Original Invoice",
    "selectOriginalInvoice": "- Select Original Invoice -",
    "title": "Title",
    "saveInvoice": "Save Invoice",
    "selectPayment": "- Select Payment -",
    "payment": "Payment",
    "repayment": "Repayment",
    "addPayment": "+ Add Payment",
    "sendInvoiceOnSave": "Automatically send Invoice to Customer after Saving",
    "compensationInvoice": "This is a damage compensation Invoice",
    "invoiceTitle": "Invoice Title",
    "paymentsMade": "Payments made",
    "administrativeOffencePositionTitle": "Processing fee administrative offence booking file number ",
    "bookingRelated": "booking related",
    "MANUALLY": "Type in invoice receiver",
    "LINK_CUSTOMER": "Link a customer",
    "setInvoiceReceiver": "Set invoice receiver",
    "reallyCreateInvoiceTitle": "Really create invoice?",
    "reallyCreateInvoiceText": "This will generate a sequential invoice number. The invoice can not be deleted.",
    "confirmCreateInvoice": "Create Invoice"
  },
  "de": {
    "createInvoice": "Rechnung erstellen",
    "invoiceType": "Rechnungstyp",
    "invoiceDate": "Rechnungsdatum",
    "originalInvoice": "Originalrechnung",
    "selectOriginalInvoice": "- Originalrechnung auswählen -",
    "title": "Titel",
    "saveInvoice": "Rechnung speichern",
    "selectPayment": "- Zahlung auswählen -",
    "payment": "Zahlung",
    "repayment": "Rückzahlung",
    "addPayment": "+ Zahlung hinzufügen",
    "sendInvoiceOnSave": "Rechnung beim Speichern automatisch an Kunden senden",
    "compensationInvoice": "Dies ist eine Schadensersatzrechnung",
    "invoiceTitle": "Rechnungstitel",
    "paymentsMade": "Getätigte Zahlungen",
    "administrativeOffencePositionTitle": "Bearbeitungsgebühr Ordnungswidrigkeit Buchung Aktenzeichen ",
    "bookingRelated": "Buchungsbezogen",
    "MANUALLY": "Tippe einen Rechnungsempfänger ein",
    "LINK_CUSTOMER": "Verknüpfe einen Kunden",
    "setInvoiceReceiver": "Rechnungsempfänger angeben",
    "reallyCreateInvoiceTitle": "Rechnung wirklich erstellen?",
    "reallyCreateInvoiceText": "Dadurch wird eine fortlaufende Rechnungsnummer erzeugt. Die Rechnung kann nicht gelöscht werden.",
    "confirmCreateInvoice": "Rechnung erstellen"
  }
}
</i18n>
