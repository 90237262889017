<template>
  <Modal
    :model-value="showModal"
    :close-on-click-outside="false"
    @update:model-value="$emit('update:showModal', $event)"
  >
    <div class="mb-4 flex justify-end">
      <a
        href="#"
        class="text-red-600 underline hover:no-underline"
        @click.prevent="$emit('update:showModal', false)"
        >{{ t('close') }} [x]</a
      >
    </div>
    <div v-if="showModal && car">
      <OverviewAndExtras
        v-if="activePage === Page.OVERVIEW_AND_EXTRAS"
        :car="car"
        :create-booking-props="createBookingProps"
        :booked-extras-map="bookedExtrasMap"
        :is-agency-booking="isAgencyBooking"
        :is-loading="isCreatingPricing"
        @update-booked-extras-map="handleUpdateBookedExtrasMap"
        @update-is-agency-booking="isAgencyBooking = $event"
        @continue="continueFromOverviewAndExtras"
      />
      <AddAgency
        v-if="activePage === Page.ADD_AGENCY"
        v-model:agency="agency"
        :total-price="pricing!.totalPrice"
        @continue="activePage = Page.ADD_CUSTOMER"
        @back="activePage = Page.OVERVIEW_AND_EXTRAS"
      />
      <AddCustomer
        v-if="activePage === Page.ADD_CUSTOMER"
        v-model:customer="customer"
        v-model:customer-id="customerId"
        v-model:customer-email="customerEmail"
        :total-price="pricing!.totalPrice"
        @continue="activePage = Page.CUSTOMER_DATA"
        @back="backFromAddCustomer"
      />
      <CustomerData
        v-if="activePage === Page.CUSTOMER_DATA"
        v-model:customer-data="customerData"
        :customer-email="customerEmail"
        :agency="agency"
        :create-booking-props="createBookingProps"
        :car="car"
        :booked-extras-map="bookedExtrasMap"
        :is-loading="isLoading"
        @back="activePage = Page.ADD_CUSTOMER"
        @continue="createCustomerAndBooking"
      />
    </div>
    <div v-else class="flex justify-center py-12">
      <Spinner />
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from '@/components/Modal.vue';
import { computed, onMounted, ref, watch } from 'vue';
import OverviewAndExtras from './modal-forms/overview-and-extras/OverviewAndExtras.vue';
import { useCar } from '@/queries/use-cars';
import { AdditionalDriver, type CreateBookingDto } from '@/entities/bookings/booking.entity';
import AddAgency from './modal-forms/add-agency/AddAgency.vue';
import AddCustomer from './modal-forms/add-customer/AddCustomer.vue';
import type { CreateCustomerUserDto, User } from '@/entities/auth/user.entity';
import type { CustomerDataDto } from '@/entities/bookings/customer-data.dto';
import CustomerData from './modal-forms/customer-data/CustomerData.vue';
import { Gender } from '@/entities/auth/gender.enum';
import { useCreateCustomerUser } from '@/queries/use-users';
import { useCreateBooking, useCreateBookingAsAgent } from '@/queries/use-bookings';
import { BookingType } from '@/entities/bookings/booking-type.enum';
import { VehicleExtraType } from '@/entities/vehicle-extra-type.enum';
import { useI18n } from 'vue-i18n';
import { Local } from '@/entities/auth/local.enum';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import { useAuthStore } from '@/stores/auth.store';
import { UserRole } from '@/entities/auth/user-role.enum';
import type { CreateBookingProps } from '@/views/bookings/booking-create/components/use-create-booking-props';
import { useCreatePricingForBookingProcess } from '@/views/bookings/booking-create/components/use-create-pricing-for-booking-process';
import type { Pricing } from '@/entities/pricing.entity';
import { Alert } from '@/utils/alert';
import router from '@/router';

enum Page {
  OVERVIEW_AND_EXTRAS = 'OVERVIEW_AND_EXTRAS',
  ADD_AGENCY = 'ADD_AGENCY',
  ADD_CUSTOMER = 'ADD_CUSTOMER',
  CUSTOMER_DATA = 'CUSTOMER_DATA',
}

const props = defineProps<{
  showModal: boolean;
  createBookingProps: CreateBookingProps;
}>();

const {
  selectedOffer,
  startDate,
  endDate,
  pickupLocation,
  dropoffLocation,
  pricing,
  insuranceCase,
} = props.createBookingProps;

const emit = defineEmits<{
  (e: 'update:showModal', value: boolean): void;
}>();

const { t } = useI18n();
const authStore = useAuthStore();

const activePage = ref(Page.OVERVIEW_AND_EXTRAS);
const bookedExtrasMap = ref<Record<string, string>>({});
const isAgencyBooking = ref(false);
const agency = ref<User | null>(null);
const customer = ref<User | null>(null);
const customerId = ref<string | null>(null);
const customerEmail = ref<string | null>(null);
const customerData = ref<CustomerDataDto>({ preferredLocal: Local.EN_US });

const { data: car } = useCar(computed(() => selectedOffer.value?.car.id));
const { create: createPricing, isCreating: isCreatingPricing } = useCreatePricingForBookingProcess(
  props.createBookingProps,
);

const { mutateAsync: createUser, isPending: isCreatingUser } = useCreateCustomerUser();
const { mutateAsync: createBooking, isPending: isCreatingBooking } = useCreateBooking();
const { mutateAsync: createBookingAsAgent, isPending: isCreatingBookingAsAgent } =
  useCreateBookingAsAgent();

const isLoading = computed(
  () => isCreatingUser.value || isCreatingBooking.value || isCreatingBookingAsAgent.value,
);

watch(
  () => props.showModal,
  (showModal) => {
    if (showModal) {
      activePage.value = Page.OVERVIEW_AND_EXTRAS;
      bookedExtrasMap.value = {};
      isAgencyBooking.value = false;
      pricing.value = selectedOffer.value!.pricing as unknown as Pricing;
      agency.value = null;
      customer.value = null;
      customerId.value = null;
      customerEmail.value = null;
      customerData.value = { preferredLocal: Local.EN_US };
    }
  },
);

const handleUpdateBookedExtrasMap = async (map: Record<string, string>) => {
  bookedExtrasMap.value = map;
  pricing.value = (await createPricing(map)) ?? pricing.value;
};

watch(customer, () => {
  if (insuranceCase.value) return;
  presetCustomerData(customer.value);
});

onMounted(() => {
  if (!insuranceCase.value) return;
  presetCustomerData(insuranceCase.value.customerData);
  customerId.value = insuranceCase.value.customer?.id ?? null;
  customerEmail.value = insuranceCase.value.customer?.email ?? null;
});

const presetCustomerData = (initialCustomerData: CustomerDataDto | User | null) => {
  customerData.value = initialCustomerData
    ? {
        firstName: initialCustomerData.firstName,
        lastName: initialCustomerData.lastName,
        company: initialCustomerData.company ?? undefined,
        gender: initialCustomerData.gender,
        dateOfBirth: initialCustomerData.dateOfBirth ?? undefined,
        phone: initialCustomerData.phone ?? undefined,
        street: initialCustomerData.street ?? undefined,
        city: initialCustomerData.city ?? undefined,
        zip: initialCustomerData.zip ?? undefined,
        country: initialCustomerData.country ?? undefined,
        preferredLocal: initialCustomerData.preferredLocal,
      }
    : { preferredLocal: Local.EN_US };
};

const continueFromOverviewAndExtras = async () => {
  if (!pricing.value?.id) {
    pricing.value = (await createPricing(bookedExtrasMap.value)) ?? null;
  }
  if (isAgencyBooking.value) {
    activePage.value = Page.ADD_AGENCY;
  } else {
    activePage.value = Page.ADD_CUSTOMER;
    agency.value = null;
  }
};

const backFromAddCustomer = () => {
  if (isAgencyBooking.value) {
    activePage.value = Page.ADD_AGENCY;
  } else {
    activePage.value = Page.OVERVIEW_AND_EXTRAS;
  }
};

const createCustomerAndBooking = async () => {
  if (!pricing.value || !pickupLocation.value || !dropoffLocation.value || !selectedOffer.value)
    return;

  const isAgency = authStore.user?.role === UserRole.AGENT;

  let newCustomer: User | null = null;
  if (customerEmail.value && !customerId.value) {
    const createUserDto: CreateCustomerUserDto = {
      ...customerData.value,
      email: customerEmail.value,
      firstName: customerData.value.firstName ?? '',
      lastName: customerData.value.lastName ?? '',
      gender: customerData.value.gender ?? Gender.MALE,
      preferredLocal: customerData.value.preferredLocal ?? Local.EN_US,
    };
    newCustomer = await createUser(createUserDto);
    customerId.value = newCustomer.id;
  }

  const additionalDriversExtra = pricing.value?.availableExtras?.find(
    (extra) => extra.type === VehicleExtraType.ADDITIONAL_DRIVER,
  );
  const optionId = additionalDriversExtra ? bookedExtrasMap.value[additionalDriversExtra.id] : null;
  const additionalDriversCount = Number(
    additionalDriversExtra?.options.find((option) => option.id === optionId)?.value ?? 0,
  );
  const additionalDrivers = Array.from({ length: additionalDriversCount }, (_, i) => i + 1).map(
    () => new AdditionalDriver(),
  );

  const createBookingDto: CreateBookingDto = {
    type: BookingType.CUSTOMER_BOOKING,
    startDate: startDate.value,
    endDate: endDate.value,
    carId: selectedOffer.value.car.id,
    pickupLocationType: pickupLocation.value.locationType,
    pickupStationId: pickupLocation.value.station?.id,
    pickupLocation: pickupLocation.value.location?.coordinates,
    pickupLocationGeocodedAddress: pickupLocation.value.location?.address,
    dropoffLocationType: dropoffLocation.value.locationType,
    dropoffStationId: dropoffLocation.value.station?.id,
    dropoffLocation: dropoffLocation.value.location?.coordinates,
    dropoffLocationGeocodedAddress: dropoffLocation.value.location?.address,
    bookedExtras: convertToBookedExtras(bookedExtrasMap.value),
    priceCalculationId: pricing.value?.id,
    agentId: agency.value?.id,
    customerId: customerId.value,
    customerData: customerData.value,
    additionalDrivers: additionalDrivers,
    insuranceCaseId: insuranceCase.value?.id,
    status: insuranceCase.value ? BookingStatus.CONFIRMED : BookingStatus.OPEN,
  };

  const { id, bookingNumber } = await (!isAgency
    ? createBooking(createBookingDto)
    : createBookingAsAgent(createBookingDto));

  emit('update:showModal', false);

  const { isConfirmed } = await Alert.fire({
    titleText: t('bookingCreated'),
    text: t('bookingCreatedText', { bookingNumber }),
    icon: 'success',
    showCloseButton: true,
    confirmButtonText: !isAgency ? t('viewBooking') : t('close'),
  });

  if (isConfirmed && !isAgency) {
    router.push({ name: 'bookingEdit', params: { id } });
  }
};

const convertToBookedExtras = (bookedExtrasMap: Record<string, string>) => {
  return Object.entries(bookedExtrasMap).map(([vehicleExtraId, vehicleExtraOptionId]) => ({
    vehicleExtraId,
    vehicleExtraOptionId,
  }));
};
</script>

<i18n lang="json">
{
  "en": {
    "bookingCreated": "Booking created",
    "bookingCreatedText": "The booking number is {bookingNumber}.",
    "viewBooking": "View Booking",
    "close": "Close"
  },
  "de": {
    "bookingCreated": "Buchung erstellt",
    "bookingCreatedText": "Die Buchungsnummer lautet {bookingNumber}.",
    "viewBooking": "Buchung ansehen",
    "close": "Schließen"
  }
}
</i18n>
