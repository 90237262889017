import { invoicesService } from '@/api/invoices.service';
import type {
  CreateInvoiceDto,
  ExportInvoicesCsvDto,
  FindAllInvoicesQueryDto,
  Invoice,
  UpdateInvoiceDto,
} from '@/entities/invoices/invoice.entity';
import {
  addItemToQueryData,
  removeItemFromQueryData,
  updateItemOnQueryData,
} from '@/utils/update-query-data';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { type MaybeRefOrGetter, toRef, toValue } from '@vueuse/core';
import type { MutationDefaultsContext } from './query-client';

const QUERY_KEY = 'invoices';

export const useInvoice = (id: MaybeRefOrGetter<string>) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(id)],
    queryFn: () => invoicesService.getOne(toValue(id)),
  });
};

export const useInvoices = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => invoicesService.getAll(),
  });
};

export const useInvoicesWithQuery = (
  query: MaybeRefOrGetter<FindAllInvoicesQueryDto>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY, toRef(query)],
    queryFn: ({ pageParam = 1 }) =>
      invoicesService.getAllWithQuery({
        ...toValue(query),
        page: pageParam,
      }),
    enabled: toValue(enabled),
    getNextPageParam: (lastPage) => lastPage.meta.nextPage,
    initialPageParam: 1,
  });
};

export const useUpdateInvoice = (query?: MaybeRefOrGetter<FindAllInvoicesQueryDto>) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ id, invoice }: { id: string; invoice: UpdateInvoiceDto }) =>
      invoicesService.update(id, invoice),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'Invoice updated',
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      updateItemOnQueryData<Invoice>([QUERY_KEY, toRef(query)], result, queryClient);
      if (result.booking?.id) {
        queryClient.invalidateQueries({ queryKey: ['bookings', result.booking.id] });
      }
    },
  });
};

export const useCreateInvoice = (query?: MaybeRefOrGetter<FindAllInvoicesQueryDto>) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (invoice: CreateInvoiceDto) => invoicesService.create(invoice),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'Invoice saved',
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      addItemToQueryData<Invoice>([QUERY_KEY, toRef(query)], result, queryClient);
    },
  });
};

export const useDeleteInvoice = (query?: MaybeRefOrGetter<FindAllInvoicesQueryDto>) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (id: string) => invoicesService.remove(id),
    onMutate: (id) => {
      return { id };
    },
    onSuccess: (result, variables, context) => {
      removeItemFromQueryData([QUERY_KEY, toRef(query)], context?.id, queryClient);
    },
  });
};

export const useSendInvoiceToCustomer = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (invoiceId: string) => invoicesService.sendInvoiceToCustomer(invoiceId),
    onMutate: () => ({
      successMessage: 'Invoice sent to customer successfully',
    }),
  });
};

export const useExportInvoicesCsv = () => {
  return useMutation({
    mutationKey: ['invoices-export'],
    mutationFn: (query: ExportInvoicesCsvDto) => invoicesService.exportInvoicesCsv(query),
  });
};

export const useSendInvoiceToAgent = () => {
  return useMutation({
    mutationKey: [QUERY_KEY, 'agent'],
    mutationFn: (bookingId: string) => invoicesService.sendInvoiceToAgent(bookingId),
    onMutate: () => ({ successMessage: 'Invoice sent to agent' }),
  });
};
