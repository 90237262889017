<template>
  <div class="grow">
    <Damages v-if="booking" :car-id="booking.car.id" :booking-id="booking.id" />
    <div class="mt-10 flex justify-end">
      <ButtonNext :is-loading="isSaving" type="submit" @click.prevent="handleSubmit">
        {{ t('saveAndNext') }}
      </ButtonNext>
    </div>
  </div>
</template>

<script setup lang="ts">
import Damages from '@/components/booking-forms/damages/Damages.vue';
import { useRoute, useRouter } from 'vue-router';
import ButtonNext from '@/components/buttons/ButtonNext.vue';
import { useBookingOfType, useUpdateBooking } from '@/queries/use-bookings';
import { useI18n } from 'vue-i18n';
import { BookingType } from '@/entities/bookings/booking-type.enum';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const { data: booking } = useBookingOfType(
  route.params.id as string,
  true,
  BookingType.CUSTOMER_BOOKING,
  'upcomingHandovers',
);
const { isPending: isSaving } = useUpdateBooking();

const handleSubmit = () => {
  router.push({
    name: 'returnCarStatus',
    params: { id: route.params.id },
  });
};
</script>
<i18n lang="json">
{
  "en": {
    "back": "back to customer bookings overview"
  },
  "de": {
    "back": "Zurück zur Buchungsübersicht"
  }
}
</i18n>
