import type { LocalizedField } from '@/entities/localized-field';
import { useI18n } from 'vue-i18n';

export const useLocalizedFields = () => {
  const { locale } = useI18n();
  const getLocalizedField = (field: LocalizedField | null | undefined) => {
    if (!field) return '';
    return locale.value === 'de' ? field.de : field.en;
  };
  return { getLocalizedField };
};
