<template>
  <div class="space-y-4">
    <p class="text-2xl font-medium">
      {{ t('carIncoming') }}
    </p>

    <Checkbox v-model="registrationIsDone">{{ t('registrationIsDone') }}</Checkbox>

    <p class="font-medium">
      {{ t('deliveryDateLastUpdate') }}:
      {{ formattedAvailableFromDate }}
    </p>

    <div class="grid grid-cols-3 gap-4">
      <DatepickerField name="availableFrom" :label="t('enterNewDeliveryDate')" />
      <DatepickerField name="lastTuvDate" :label="t('carWasRegisteredOn')" />

      <DatepickerField
        v-if="purchasedUsed"
        name="firstRegistration"
        :label="t('firstRegistration')"
      />

      <InputField class="col-start-1" name="insuranceCompany" :label="t('insurance')" />

      <InputField
        name="licencePlate"
        :label="t('licencePlate')"
        :placeholder="t('licencePlatePlaceholder')"
      />

      <InputField name="fin" :label="t('fin')" />

      <FileInputField name="registrationFile" :label="t('uploadRegistrationCertificate')" />
    </div>

    <Checkbox v-model="transferHasBeenOrganized">{{ t('transferHasBeenOrganized') }}</Checkbox>

    <div class="h-12"></div>

    <Divider />

    <div class="flex justify-end">
      <CVButton
        :disabled="!registrationIsDone || !transferHasBeenOrganized"
        :is-loading="isSaving"
        @click.prevent="submit"
      >
        {{ t('save') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Checkbox from '@/components/Checkbox.vue';
import type { Car } from '@/entities/cars/car.entity';
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import Divider from '@/components/Divider.vue';
import { useForm } from 'vee-validate';
import { useUpdateCar } from '@/queries/use-cars';
import * as yup from 'yup';
import { toTypedSchema } from '@vee-validate/yup';
import { dateOrString, dateOrStringRequired } from '@/validation/car.schema';
import DatepickerField from '@/components/DatepickerField.vue';
import InputField from '@/components/InputField.vue';
import { CarAcquisitionType } from '@/entities/cars/car-acquisition-type.enum';
import { useFormattedDate } from '@/hooks/use-formatted-date';
import { getDateFormat } from '@/hooks/use-date-format';
import FileInputField from '@/components/FileInputField.vue';
import { useUploadAndCreateCarDocument } from '@/hooks/use-upload-and-create-car-document';
import { CarDocumentType } from '@/entities/cars/car-document-type.enum';

const props = defineProps<{
  car: Car;
}>();

const emit = defineEmits<{
  (e: 'complete'): void;
}>();

const { t } = useI18n();

const registrationIsDone = ref(false);
const transferHasBeenOrganized = ref(false);
const purchasedUsed = computed(
  () => props.car.acquisitionType === CarAcquisitionType.PURCHASED_USED,
);

const formattedAvailableFromDate = useFormattedDate(
  computed(() => props.car.availableFrom),
  getDateFormat(),
);

const { uploadAndCreateCarDocument, isPending: isUploadingCarDocument } =
  useUploadAndCreateCarDocument();
const { mutateAsync: updateCar, isPending: isUpdatingCar } = useUpdateCar();

const isSaving = computed(() => isUpdatingCar.value || isUploadingCarDocument.value);

const { handleSubmit } = useForm({
  initialValues: {},
  validationSchema: toTypedSchema(
    yup.object({
      availableFrom: dateOrStringRequired(),
      lastTuvDate: dateOrStringRequired('registrationDate is a required field'),
      firstRegistration: purchasedUsed.value ? dateOrStringRequired() : dateOrString,
      insuranceCompany: yup.string().required(),
      licencePlate: yup.string().required(),
      fin: yup.string().required(),
      registrationFile: yup.mixed().required(),
    }),
  ),
});

const submit = handleSubmit(async (values) => {
  await uploadAndCreateCarDocument(
    props.car.id,
    values.registrationFile as File,
    CarDocumentType.REGISTRATION_CERTIFICATE,
    'Fahrzeugschein',
  );
  await updateCar({
    id: props.car.id,
    car: {
      ...values,
      ...(purchasedUsed.value ? {} : { firstRegistration: values.lastTuvDate }),
      registrationAndOrganizationDoneDate: new Date(),
    },
  });
  emit('complete');
});
</script>

<i18n lang="json">
{
  "en": {
    "licencePlatePlaceholder": "i.e. M-CV 1234",
    "carIncoming": "Car incoming",
    "registrationIsDone": "Registration is done",
    "carWasRegisteredOn": "Car was registered on",
    "firstRegistration": "First registration on",
    "insurance": "Insurance",
    "fin": "FIN",
    "transferHasBeenOrganized": "Delivery to the rental hub has been organized",
    "deliveryDateLastUpdate": "Delivery date as per last update",
    "enterNewDeliveryDate": "Enter new delivery date",
    "uploadRegistrationCertificate": "Upload registration certificate"
  },
  "de": {
    "licencePlatePlaceholder": "zB.: M-CV 1234",
    "carIncoming": "Auto im Anflug",
    "registrationIsDone": "Zulassung ist erledigt",
    "carWasRegisteredOn": "Auto wurde zugelassen am",
    "firstRegistration": "Erstzulassung am",
    "insurance": "Versicherung",
    "fin": "FIN",
    "transferHasBeenOrganized": "Anlieferung zum Rental Hub wurde organisiert",
    "deliveryDateLastUpdate": "Lieferdatum laut letzter Aktualisierung",
    "enterNewDeliveryDate": "Liefertermin neu eingeben",
    "uploadRegistrationCertificate": "Fahrzeugschein hochladen"
  }
}
</i18n>
