import type { User } from '@/entities/auth/user.entity';
import { Alert } from '@/utils/alert';
import type { UpdateBookingDto } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';

export const useGenerateSwapCustomerDto = () => {
  const { t } = useI18n();
  return async (customer: User): Promise<UpdateBookingDto> => {
    const { isConfirmed } = await Alert.fire({
      text: t('swapCustomer.swapCustomerText'),
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: t('swapCustomer.adjustDriversData'),
      denyButtonText: t('swapCustomer.onlyChangeCustomer'),
    });

    return {
      customerId: customer.id,
      customerData: isConfirmed
        ? {
            firstName: customer.firstName,
            lastName: customer.lastName,
            phone: customer.phone ?? undefined,
            dateOfBirth: customer.dateOfBirth ?? undefined,
            city: customer.city ?? undefined,
            company: customer.company ?? undefined,
            country: customer.country ?? undefined,
            gender: customer.gender ?? undefined,
            preferredLocal: customer.preferredLocal ?? undefined,
            street: customer.street ?? undefined,
            zip: customer.zip ?? undefined,
          }
        : undefined,
    };
  };
};
