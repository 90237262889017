import * as yup from 'yup';

export const resetPasswordSchema = yup.object({
  password: yup
    .string()
    .required()
    .min(6, 'Password must be more than 5 characters')
    .max(32, 'Password must be less than 32 characters'),
  passwordConfirm: yup
    .string()
    .required('Please confirm your password')
    .oneOf([yup.ref('password')], 'passwords must match'),
});
