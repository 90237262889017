import { Service } from './http-service';
import type { GlobalSettings, UpdateGlobalSettingsDto } from '@/entities/global-settings.entity';

class GlobalSettingsService extends Service<GlobalSettings, undefined, UpdateGlobalSettingsDto> {
  async getGlobalSettings() {
    const res = await this.httpClient.get<GlobalSettings>(this.endpoint);
    return res.data;
  }

  async updateGlobalSettings(dto: UpdateGlobalSettingsDto) {
    const res = await this.httpClient.patch<GlobalSettings>(this.endpoint, dto);
    return res.data;
  }
}

export const globalSettingsService = new GlobalSettingsService('/global-settings');
