<template>
  <div>
    <h2>
      {{
        t('thanksForReturn', {
          firstName: user?.firstName,
        })
      }}
    </h2>
    <p class="mt-4">
      {{ t('returnSuccess') }}
    </p>
    <BigSuccess />

    <Divider />
    <div class="flex justify-end">
      <CVButton :to="{ name: 'upcomingHandovers' }">
        {{ t('next') }}
      </CVButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import BigSuccess from '@/components/BigSuccess.vue';
import Divider from '@/components/Divider.vue';
import { useMe } from '@/queries/use-users';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { data: user } = useMe();
</script>

<i18n lang="json">
{
  "en": {
    "next": "Back to Dashboard",
    "thanksForReturn": "Thanks {firstName} for your good work!",
    "returnSuccess": "The booking is successfully completed and the vehicle can now be cleaned again."
  },
  "de": {
    "next": "Zurück zur Übersicht",
    "thanksForReturn": "Danke {firstName} für deine Gute Arbeit!",
    "returnSuccess": "Die Buchung ist erfolgreich abgeschlossen und das Fahrzeug kann jetzt wieder aufbereitet werden."
  }
}
</i18n>
