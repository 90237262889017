import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import type { Booking } from '@/entities/bookings/booking.entity';
import { type MaybeRefOrGetter, toRef } from '@vueuse/core';
import { computed } from 'vue';

export const useAvailableCreditCards = (booking: MaybeRefOrGetter<Booking | null | undefined>) => {
  const bookingRef = toRef(booking);

  const showOnlinePaymentCard = computed(
    () =>
      !!(
        bookingRef.value &&
        (bookingRef.value.status === BookingStatus.DRAFT ||
          bookingRef.value.status === BookingStatus.OPEN ||
          bookingRef.value.status === BookingStatus.CONFIRMED ||
          bookingRef.value.status === BookingStatus.FLOATING) &&
        bookingRef.value.hasOnlineCreditCardPayment
      ),
  );

  const showBlockedCard = computed(
    () =>
      !!(
        bookingRef.value &&
        (bookingRef.value.status === BookingStatus.HANDED_OVER ||
          bookingRef.value.status === BookingStatus.CAR_RETURNED) &&
        bookingRef.value.hasBlockedCreditCard
      ),
  );

  return {
    showOnlinePaymentCard,
    showBlockedCard,
  };
};
