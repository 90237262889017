import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { surveysConductedService } from '@/api/surveys-conducted.service';
import type {
  CreateSurveyConductedDto,
  FindAllSurveysConductedQueryDto,
  SurveyConducted,
} from '@/entities/surveys/survey-conducted.entity';
import { type MaybeRefOrGetter, toRef, toValue } from '@vueuse/core';
import type { MutationDefaultsContext } from './query-client';
import { addItemToQueriesData } from '@/utils/update-query-data';

const QUERY_KEY = 'surveys-conducted';

export const useSurveysConductedWithQuery = (
  query: MaybeRefOrGetter<FindAllSurveysConductedQueryDto>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, query],
    queryFn: () => surveysConductedService.getAllWithQuery(toValue(query)),
    enabled: toRef(enabled),
  });
};

export const useCreateSurveyConducted = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (surveyConducted: CreateSurveyConductedDto) =>
      surveysConductedService.create(surveyConducted),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'Survey saved',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(null, null, context);
      addItemToQueriesData<SurveyConducted>([QUERY_KEY], result, queryClient);
    },
  });
};
