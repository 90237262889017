import { pricingService } from '@/api/pricing.service';
import type { CreateAdditionalCostsDto, CreatePricingDto } from '@/entities/pricing.entity';

import { useMutation, useQuery } from '@tanstack/vue-query';
import { type MaybeRefOrGetter, toRef } from '@vueuse/core';
import type { MutationDefaultsContext } from './query-client';

const QUERY_KEY = 'pricing';

export const useCreatePricing = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (params: CreatePricingDto) => pricingService.createPricing(params),
    onMutate: (): MutationDefaultsContext => ({ showSuccessToast: false }),
  });
};

export const useRequestAdditionalCosts = (
  params: CreateAdditionalCostsDto,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useQuery({
    queryKey: ['additional-costs', params.bookingId],
    queryFn: () => pricingService.requestAdditionalCosts(params),
    enabled: toRef(enabled),
  });
};
