<template>
  <div>
    <Divider class="col-span-3" />

    <h2>{{ t('specialCaseOpenFee') }}</h2>

    <label class="label cursor-pointer justify-start">
      <input v-model="confirmManualReservation" type="checkbox" class="checkbox mr-2 bg-white" />
      <span>{{ t('confirmManualPayment') }}</span>
    </label>

    <div class="mt-5 flex justify-end">
      <ButtonNext
        :is-loading="isSaving"
        :disabled="!confirmManualReservation"
        type="submit"
        @click.prevent="$emit('continueWithoutTransaction')"
      >
        {{ t('continueWithManualPayment') }}
      </ButtonNext>
    </div>
  </div>
</template>

<script setup lang="ts">
import ButtonNext from '@/components/buttons/ButtonNext.vue';
import type { Booking } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';

defineProps<{
  booking: Booking;
  isSaving: boolean;
}>();

defineEmits<{
  (e: 'continueWithoutTransaction'): void;
}>();

const { t } = useI18n();

const confirmManualReservation = ref(false);
</script>

<i18n lang="json">
{
  "en": {
    "specialCaseOpenFee": "Special case open fee",
    "confirmManualPayment": "Confirm reservation of deposit via credit card in the amount of reservation amount",
    "continueWithManualPayment": "Continue with manual payment"
  },
  "de": {
    "specialCaseOpenFee": "Sonderfall offener Mietbetrag",
    "confirmManualPayment": "Rücksprache mit Supervisor wurde gehalten.",
    "continueWithManualPayment": "Weiter mit manueller Zahlung"
  }
}
</i18n>
