<template>
  <form class="flex w-full flex-col space-y-4">
    <div class="flex justify-between">
      <div class="flex flex-col">
        <div class="flex items-end space-x-2">
          <Input
            :model-value="milage"
            :label="t('milage')"
            :warning="milageWarning"
            :disabled="disabled"
            type="number"
            adornment="km"
            adornment-transparent
            @update:model-value="(value) => emit('update:milage', parseInt(value as string))"
          />
        </div>
      </div>
    </div>
    <div class="w-full">
      <InputLabel :label="fuelLevelLabel" :warning="fuelLevelWarning" />
      <div class="flex items-center space-x-2">
        <input
          :value="props.fuelLevel"
          type="range"
          min="0"
          :disabled="disabled"
          max="1"
          class="slider"
          :step="fuelType === FuelType.ELECTRIC ? 0.01 : 0.125"
          @input="
            (evt: Event) =>
              emit('update:fuelLevel', parseFloat((evt.target as HTMLInputElement).value))
          "
        />
        <div class="flex items-center space-x-2">
          <Input
            :model-value="fuelLevelDisplayValue"
            type="number"
            :disabled="disabled"
            :class="fuelType === FuelType.ELECTRIC ? 'w-24' : 'w-16'"
            :max="fuelType === FuelType.ELECTRIC ? 100 : 8"
            :adornment="fuelLevelAdornment"
            data-testid="fuel-level"
            adornment-transparent
            @input="() => emit('onFuelLevelTouched')"
            @update:model-value="(value) => onFuelLevelDisplayValueChange(Number(value))"
          />
        </div>
      </div>
    </div>
  </form>
</template>
<script lang="ts" setup>
import Input from '@/components/Input.vue';
import { FuelType } from '@/entities/cars/fuel-type.enum';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import InputLabel from '../InputLabel.vue';

const { t } = useI18n();
const props = defineProps<{
  fuelType: FuelType;
  milage: number | null;
  fuelLevel: number | null;
  showMilageWarning?: boolean;
  showFuelLevelNotUpdated?: boolean;
  showMilageNotUpdated?: boolean;
  disabled?: boolean;
}>();

const milageWarning = computed(() => {
  if (props.showMilageNotUpdated) return t('notUpdated');
  if (props.showMilageWarning) return t('milageWarning');
  return '';
});
const fuelLevelLabel = computed(() =>
  props.fuelType === FuelType.ELECTRIC ? t('battery') : t('fuelLevel'),
);
const fuelLevelWarning = computed(() => (props.showFuelLevelNotUpdated ? t('notUpdated') : ''));
const fuelLevelAdornment = computed(() => (props.fuelType === FuelType.ELECTRIC ? '%' : `/8`));

const fuelLevelDisplayValue = computed(() => {
  return props.fuelType === FuelType.ELECTRIC
    ? Math.round((props.fuelLevel ?? 0) * 100)
    : (props.fuelLevel ?? 0) * 8;
});
const onFuelLevelDisplayValueChange = (value: number) => {
  emit(
    'update:fuelLevel',
    Math.min(props.fuelType === FuelType.ELECTRIC ? value / 100 : value / 8, 1),
  );
};

const emit = defineEmits<{
  (e: 'update:milage', milage: number | null): void;
  (e: 'update:fuelLevel', fuelLevel: number): void;
  (e: 'onFuelLevelTouched'): void;
}>();
</script>
<style scoped>
.slider {
  -webkit-appearance: none;
  @apply h-7 w-full appearance-none rounded-full border border-primary/40 bg-transparent p-2 transition-all focus:border-primary;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  @apply h-4 w-4 cursor-pointer rounded-full border border-primary bg-primary transition-all;
}
.slider::-moz-range-thumb {
  @apply h-4 w-4 cursor-pointer rounded-full border border-primary bg-primary transition-all;
}
</style>
<i18n lang="json">
{
  "en": {
    "milage": "Milage",
    "fuelLevel": "Fuel Level",
    "battery": "Battery",
    "notUpdated": "not updated",
    "milageWarning": "check input"
  },
  "de": {
    "fuelLevel": "Tankfüllstand",
    "battery": "Ladestand",
    "milage": "Kilometerstand",
    "notUpdated": "nicht aktualisiert",
    "milageWarning": "Eingabe überprüfen"
  }
}
</i18n>
