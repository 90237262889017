import * as yup from 'yup';

export const transferSchema = {
  internalComment: yup.string().notRequired(),
  startDate: yup.date(),
  endDate: yup.date(),
  userId: yup.string().optional().nullable(),
};

export const transferEditSchema = yup.object(transferSchema);
