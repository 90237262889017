<template>
  <HeaderBar />

  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
  </ButtonBackContainer>

  <section class="carvia-container flex flex-col justify-center">
    <PaymentTerminalForm
      v-if="(paymentTerminal || paymentTerminalNew) && stations"
      :payment-terminal="paymentTerminal"
      :stations="stations"
      :is-loading="isLoading"
      :is-fetching="isFetching"
      @on-submit="onSubmit"
      @on-delete="onDelete"
      @refetch="refetch"
    />
    <div v-else class="flex justify-center"><Spinner /></div>
  </section>
</template>

<script lang="ts" setup>
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import { useUiStore } from '@/stores/ui.store';
import { computed, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import PaymentTerminalForm from './PaymentTerminalForm.vue';
import {
  useCreatePaymentTerminal,
  useDeletePaymentTerminal,
  usePaymentTerminal,
  useUpdatePaymentTerminal,
} from '@/queries/use-payment-terminals';
import { useStations } from '@/queries/use-stations';
import type {
  CreatePaymentTerminalDto,
  UpdatePaymentTerminalDto,
} from '@/entities/payment-terminals/payment-terminal.entity';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';

const { t } = useI18n();
const uiStore = useUiStore();
const route = useRoute();
const paymentTerminalId = route.params.id as string;
const paymentTerminalNew = ref(paymentTerminalId === 'new');
const paymentTerminalFetchingEnabled = computed(() => !paymentTerminalNew.value);

const {
  data: paymentTerminal,
  refetch,
  isFetching,
} = usePaymentTerminal(paymentTerminalId, paymentTerminalFetchingEnabled);
const { mutateAsync: createPaymentTerminal, isPending: isSaving } = useCreatePaymentTerminal();
const { mutateAsync: updatePaymentTerminal, isPending: isUpdating } = useUpdatePaymentTerminal();
const { mutateAsync: deletePaymentTerminal, isPending: isDeleting } = useDeletePaymentTerminal();

const { data: stationsData, isFetching: isLoadingStations } = useStations(500);
const stations = useFlattenPaginatedData(stationsData);

const isLoading = computed(
  () => isSaving.value || isUpdating.value || isDeleting.value || isLoadingStations.value,
);

watchEffect(() => {
  if (paymentTerminal.value) {
    const { description, terminalId } = paymentTerminal.value;
    uiStore.setHeaderTitle(t('paymentTerminal'), description, String(terminalId));
  } else {
    uiStore.setHeaderTitle(t('paymentTerminal'));
  }
});

const onSubmit = async (formValues: UpdatePaymentTerminalDto) => {
  if (!paymentTerminalNew.value) {
    updatePaymentTerminal({
      id: paymentTerminalId,
      paymentTerminal: formValues,
    });
  } else {
    await createPaymentTerminal(formValues as CreatePaymentTerminalDto);
  }
};

const onDelete = async () => {
  await deletePaymentTerminal(paymentTerminalId);
  router.push({ name: 'paymentTerminalList' });
};
</script>

<i18n lang="json">
{
  "en": {
    "paymentTerminal": "Payment Terminal"
  },
  "de": {
    "paymentTerminal": "Zahlungsterminal"
  }
}
</i18n>
