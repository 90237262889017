<template>
  <div class="mt-4 grid grid-cols-12 gap-8">
    <div class="col-span-5">
      <h4 class="text-base font-medium lg:text-lg">
        {{ t('transferStart') }}
      </h4>

      <TransferAddress :transfer-location="pickupTransferLocation" />
    </div>

    <HorizontalArrow class="col-span-2" right />

    <div class="col-span-5">
      <h4 class="text-base font-medium lg:text-lg">
        {{ t('transferDestination') }}
      </h4>

      <TransferAddress :transfer-location="dropoffTransferLocation" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import TransferAddress from '@/components/booking-forms/TransferAddress.vue';
import HorizontalArrow from '@/components/HorizontalArrow.vue';
import type { BookingLocation } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';

defineProps<{
  dropoffTransferLocation?: BookingLocation | null;
  pickupTransferLocation?: BookingLocation | null;
}>();

const { t } = useI18n();
</script>

<i18n lang="json">
{
  "en": {
    "street": "Street",
    "station": "Station",
    "transferStart": "Transfer Start",
    "transferDestination": "Transfer Destination"
  },
  "de": {
    "street": "Straße",
    "station": "Station",
    "transferStart": "Transferstartort",
    "transferDestination": "Transferziel"
  }
}
</i18n>
