import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { type MaybeRefOrGetter, toRef, toValue } from '@vueuse/core';
import type { MutationDefaultsContext } from './query-client';
import { newsService } from '@/api/news.service';
import type {
  CreateNewsDto,
  FindAllNewsQueryDto,
  News,
  UpdateNewsDto,
} from '@/entities/news/news.entity';
import {
  addItemToQueriesData,
  removeItemFromQueriesData,
  updateItemOnQueriesData,
} from '@/utils/update-query-data';

const QUERY_KEY = 'payment-terminal';

export const useNews = (
  id: MaybeRefOrGetter<string>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(id)],
    queryFn: () => newsService.getOne(toValue(id)),
    enabled: toValue(enabled),
  });
};

export const useAllNewsWithQuery = (
  query: MaybeRefOrGetter<FindAllNewsQueryDto>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY, toRef(query)],
    queryFn: ({ pageParam = 1 }) =>
      newsService.getAllWithQuery({
        ...toValue(query),
        page: pageParam,
      }),
    enabled: toValue(enabled),
    getNextPageParam: (lastPage) => lastPage.meta.nextPage,
    initialPageParam: 1,
  });
};

export const useCreateNews = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (news: CreateNewsDto) => newsService.create(news),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'News saved',
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      addItemToQueriesData<News>([QUERY_KEY], result, queryClient);
    },
  });
};

export const useUpdateNews = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ id, news }: { id: string; news: UpdateNewsDto }) => newsService.update(id, news),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'News updated',
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      updateItemOnQueriesData<News>([QUERY_KEY], result, queryClient);
    },
  });
};

export const useDeleteNews = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (id: string) => newsService.remove(id),
    onMutate: (id) => ({ id, successMessage: 'News deleted' }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      removeItemFromQueriesData([QUERY_KEY], context?.id, queryClient);
    },
  });
};
