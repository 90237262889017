import type { BookedExtra, BookedExtraDto, CustomBookedExtra } from './bookings/booked-extra';
import type { Car } from './cars/car.entity';
import type { LocationDto, Station } from './station/station.entity';
import type { VehicleExtra } from './vehicle-extra.entity';

export interface Pricing {
  id: string;
  basePrice: number;
  totalBasePrice: number;
  customTotalBasePrice: number | null;
  calcTotalBasePrice: number;
  totalPrice: number;
  averagePricePerDay: number;
  days: number;
  car: Car;
  pickupStation: Station;
  dropoffStation: Station;
  bookedExtras: BookedExtra[];
  customBookedExtras: CustomBookedExtra[] | null;
  createdDate: Date;
  updatedDate: Date;
  availableExtras?: VehicleExtra[];
  customDeposit: number | null;
  customPricePerExtraKm: number | null;
  customInclusiveKm: number | null;
  defaultDeposit: number;
  defaultPricePerExtraKm: number;
  defaultInclusiveKm: number;
  pricePerExtraKm: number;
  deposit: number;
  inclusiveKm: number;
}

export interface AdditionalCosts {
  costsOfExtraKm: number;
  costsOfExtraDuration: number;
  extraKm: number;
  includedKms: number;
  additionalBookedKmExtraValue: number;
  extraDuration: number;
  costsOfRecharging: number | null;
  costsOfRefueling: number | null;
  missingBattery: number | null;
  missingFuel: number | null;
  costPerLiter: number;
  costPerKwh: number;
  totalAdditionalCosts: number;
  finalTotalBookingPrice: number;
}

export interface CreatePricingDto {
  startDate: Date | string;
  endDate: Date | string;
  carId: string;
  oldPricingId?: string;
  bookedExtras: BookedExtraDto[];
  customBookedExtras?: CustomBookedExtra[] | null;
  customTotalBasePrice?: number | null;
  transferDeliveryLocation?: LocationDto | null;
  transferReturnLocation?: LocationDto | null;
  pickupStationId?: string | null;
  returnStationId?: string | null;
  insuranceCaseId?: string | null;
  priceRecalculationMode?: PriceRecalculationMode;
  customDeposit?: number | null;
  customPricePerExtraKm?: number | null;
  customInclusiveKm?: number | null;
}

export enum PriceRecalculationMode {
  USE_EXISTING = 'USE_EXISTING',
  SCALE = 'SCALE',
  RECALCULATE = 'RECALCULATE',
}

export interface CreatePricingWithExtendedTimePeriodDto {
  oldPricingId: string;
  startDate: string;
  endDate: string;
}

export interface CreateAdditionalCostsDto {
  bookingId: string;
}

export type UpdatePricingnDto = Partial<CreatePricingDto>;
