<template>
  <div>
    <LabeledText v-if="invoice" :label="t('originalInvoice')" class="mb-4">
      <p>{{ `${t('invoiceNumber')} ${invoice.completeInvoiceNumber}` }}</p>
      <p>{{ invoice.title }}</p>
      <p>{{ invoice.receiverData.firstName }} {{ invoice.receiverData.lastName }}</p>
      <p>{{ `${t('date')}: ${invoiceDate}` }}</p>
    </LabeledText>
    <CVButton v-if="!showInput" outline @click="showInput = true">{{
      t('switchInvoice')
    }}</CVButton>
    <div v-else class="flex items-center justify-start gap-4">
      <SearchInvoiceInput
        class="w-full"
        :label="inputLabel"
        :error="errorMessage"
        @on-update-invoice="onUpdateInvoice"
      />
      <CVButton v-if="invoice" variant="warning" @click="showInput = false">{{
        t('cancel')
      }}</CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import LabeledText from '@/components/LabeledText.vue';
import SearchInvoiceInput from '@/components/SearchInvoiceInput.vue';
import type { Invoice } from '@/entities/invoices/invoice.entity';
import { getDateFormat } from '@/hooks/use-date-format';
import { formatDate } from '@/hooks/use-formatted-date';
import { useI18n } from 'vue-i18n';
import { useField } from 'vee-validate';
import { computed, ref } from 'vue';

const { t } = useI18n();

const invoice = ref<Invoice>();
const showInput = ref(true);

const invoiceDate = computed(() => formatDate(invoice.value?.invoiceDate, getDateFormat()));

const inputLabel = computed(() => (!invoice.value ? t('chooseOriginalInvoice') : undefined));

const { value: invoiceId, errorMessage } = useField<string | undefined>('originalInvoiceId');

const onUpdateInvoice = (selectedInvoice: Invoice | undefined) => {
  invoice.value = selectedInvoice ?? invoice.value;
  invoiceId.value = selectedInvoice?.id ?? invoiceId.value;
  if (selectedInvoice) {
    showInput.value = false;
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "originalInvoice": "Original invoice",
    "invoiceNumber": "Inr.",
    "date": "Date",
    "switchInvoice": "Switch invoice",
    "chooseOriginalInvoice": "Choose original invoice"
  },
  "de": {
    "originalInvoice": "Originalrechnung",
    "invoiceNumber": "Rnr.",
    "date": "Datum",
    "switchInvoice": "Rechnung wechseln",
    "chooseOriginalInvoice": "Originalrechnung auswählen"
  }
}
</i18n>
