<template>
  <div class="flex flex-col">
    <LabeledText :label="t('rentalDuration')"
      ><p class="py-1">
        {{ booking.totalRentalDays }} {{ t('days') }}
        <span v-if="booking.totalRentalDays !== pricing.days">{{
          t('priceCalculatedForXDays', { days: pricing.days })
        }}</span>
      </p></LabeledText
    >
    <div v-if="showOverwriteInclusiveKm && !immutable" class="mb-2 mt-2">
      <div class="flex items-center">
        <div class="w-32">
          <InputField name="customInclusiveKm" type="number" :min="0" adornment="km" />
        </div>
        <div class="ml-2">+ {{ currentInclusiveAndExtraKm.extraKm }} {{ t('extraKm') }} =</div>
      </div>
    </div>
    <Information
      ><template #text>{{ currentInclusiveAndExtraKm.sum + ' ' + t('inclusiveKm') }}</template
      ><template #modal>{{ t('information') }}</template></Information
    >
    <p v-if="!immutable && !hasChanged">
      <a class="text-link" href="#" @click.prevent="toggleOverwriteInclusiveKm">{{
        showOverwriteInclusiveKm ? t('useInclusiveKmOfCar') : t('overwriteInclusiveKm')
      }}</a>
    </p>
    <div v-if="hasChanged" class="mt-2 flex justify-end gap-2">
      <CVButton variant="success" :is-loading="isSaving" @click.prevent="onSubmit">
        {{ t('save') }}
      </CVButton>
      <CVButton variant="warning" :disabled="isSaving" @click.prevent="onCancel">
        {{ t('cancel') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { VehicleExtraType } from '@/entities/vehicle-extra-type.enum';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { computed, ref, watch } from 'vue';
import Information from '../Information.vue';
import InputField from '../InputField.vue';
import LabeledText from '../LabeledText.vue';
import { PriceRecalculationMode, type Pricing } from '@/entities/pricing.entity';
import * as yup from 'yup';
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import { cloneDeep } from 'lodash';
import { useFormHasChanged } from '@/hooks/use-form-has-changed';
import type { RecalculatePriceParams } from '@/hooks/use-recalculate-price';

const props = defineProps<{
  pricing: Pricing;
  immutable?: boolean;
  booking: Booking;
  isSaving?: boolean;
}>();

const emit = defineEmits<{
  (e: 'recalculate-price', value: RecalculatePriceParams): void;
  (
    e: 'updateBooking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished?: () => void,
  ): void;
  (e: 'hasChanged', value: boolean): void;
}>();

const { t } = useI18n();

const toggleOverwriteInclusiveKm = () => {
  showOverwriteInclusiveKm.value = !showOverwriteInclusiveKm.value;
  if (showOverwriteInclusiveKm.value) {
    setFieldValue('customInclusiveKm', props.pricing.inclusiveKm);
  } else {
    setFieldValue('customInclusiveKm', null);
  }
};

const currentInclusiveKm = computed(
  () => formValues.customInclusiveKm ?? props.pricing.defaultInclusiveKm,
);
const currentInclusiveAndExtraKm = computed(() => {
  let extraKm =
    props.pricing.customBookedExtras?.find(
      (customBookedExtra) => customBookedExtra.customVehicleExtraType === VehicleExtraType.EXTRA_KM,
    )?.value ?? null;

  if (extraKm === null) {
    const extra = props.pricing.availableExtras?.find(
      (extra) => extra.type === VehicleExtraType.EXTRA_KM,
    );
    let option = props.pricing.bookedExtras.find(
      (bookedExtra) => bookedExtra.vehicleExtra.type === extra?.type,
    )?.vehicleExtraOption;
    extraKm = Number(option?.value ?? 0);
  }
  return {
    extraKm,
    sum: extraKm + (currentInclusiveKm.value ?? 0),
  };
});

const onCancel = () => {
  setFieldValue('customInclusiveKm', props.pricing.customInclusiveKm);
  showOverwriteInclusiveKm.value = !!props.pricing.customInclusiveKm;
};

const {
  handleSubmit,
  values: formValues,
  setFieldValue,
} = useForm({
  initialValues: {
    customInclusiveKm: props.pricing.customInclusiveKm,
  },
  validationSchema: yup.object({
    customInclusiveKm: yup.number().required().nullable(),
  }),
  keepValuesOnUnmount: true,
});

const showOverwriteInclusiveKm = ref(!!formValues.customInclusiveKm);
const unchangedValues = ref(cloneDeep(formValues));

const onSubmit = handleSubmit((values) => {
  emit('recalculate-price', {
    ...values,
    priceRecalculationMode: PriceRecalculationMode.USE_EXISTING,
    showAlert: false,
    nullExtrasOnConfirm: false,
    isConfirmedFunction: (pricing) => {
      console.log(pricing);
      emit(
        'updateBooking',
        {
          id: props.booking.id,
          booking: {
            priceCalculationId: pricing.id,
          },
        },
        () => (unchangedValues.value = cloneDeep(formValues)),
      );
    },
  });
});

const hasChanged = useFormHasChanged(unchangedValues, formValues);
watch(hasChanged, () => emit('hasChanged', hasChanged.value));
</script>

<i18n lang="json">
{
  "en": {
    "rentalDuration": "Rental Duration",
    "days": "Days",
    "priceCalculatedForXDays": "(Price calculated for {days} days)",
    "information": "Extra kilometers already included!",
    "overwriteInclusiveKm": "Overwrite inclusive KM",
    "useInclusiveKmOfCar": "Use inclusive KM of Car",
    "extraKm": "Extra KM"
  },
  "de": {
    "rentalDuration": "Mietdauer",
    "days": "Tage",
    "priceCalculatedForXDays": "(Preis berechnet für {days} Tage)",
    "information": "Extra Kilometer mit inbegriffen!",
    "overwriteInclusiveKm": "Inklusiv KM überschreiben",
    "useInclusiveKmOfCar": "Inklusiv KM des Autos nutzen",
    "extraKm": "Extra KM"
  }
}
</i18n>
