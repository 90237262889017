<template>
  <router-link
    :to="{
      name: linkName,
      params: { id: booking.id },
    }"
  >
    <div
      class="relative w-full border transition-shadow hover:shadow-md"
      :class="{
        'bg-booking-open':
          isHandover &&
          !isLateHandover &&
          booking.car.readyToHandover &&
          booking.car.currentLocationType === CarLocationType.STATION,
        'bg-booking-warning':
          isHandover &&
          !isLateHandover &&
          !booking.car.readyToHandover &&
          booking.car.currentLocationType === CarLocationType.STATION,
        'bg-booking-danger':
          isLateHandover ||
          (isHandover &&
            (booking.car.currentLocationType !== CarLocationType.STATION ||
              booking.car.status === CarStatus.ORDERED)),
        'bg-booking-return': isReturn,
        'bg-booking-transfer': isTransfer,
        'bg-booking-partner': isPartnerRent,

        'border-booking-open-content/30':
          isHandover &&
          !isLateHandover &&
          booking.car.readyToHandover &&
          booking.car.currentLocationType === CarLocationType.STATION,
        'border-booking-warning-content/30':
          isHandover &&
          !isLateHandover &&
          !booking.car.readyToHandover &&
          booking.car.currentLocationType === CarLocationType.STATION,
        'border-booking-danger-content/30':
          isLateHandover ||
          (isHandover &&
            (booking.car.currentLocationType !== CarLocationType.STATION ||
              booking.car.status === CarStatus.ORDERED)),
        'border-booking-return-content/30': isReturn,
        'border-booking-transfer-content/30': isTransfer,
        'border-booking-partner-content/30': isPartnerRent,
      }"
    >
      <div
        v-if="hasTransfer"
        class="absolute bottom-[-1px] left-[-1px] top-[-1px] w-2 border border-booking-transfer-content/30 bg-booking-transfer"
      ></div>
      <div class="flex items-center gap-2 py-1 pl-3 pr-2 text-sm">
        <div class="basis-[11%]">
          <BookingListItemTitle :booking="booking"> {{ title }}</BookingListItemTitle>
          <p>{{ isReturn ? endDate : startDate }}</p>
        </div>

        <div class="basis-[25%]">
          <BookingListItemTitle :booking="booking">{{ t('vehicle') }}</BookingListItemTitle>
          <p>{{ car?.vehicleType?.make.name }} {{ car?.vehicleType?.model }}</p>
        </div>

        <div v-if="!isPartnerRent" class="basis-[17%]">
          <BookingListItemTitle :booking="booking">{{
            !isTransfer ? t('customer') : t('driver')
          }}</BookingListItemTitle>
          <p>
            {{
              booking.customerData?.firstName && booking.customerData?.lastName
                ? `${booking.customerData.firstName} ${booking.customerData.lastName}`
                : 'n/a'
            }}
          </p>
        </div>

        <div v-else class="basis-[17%]">
          <BookingListItemTitle :booking="booking">{{ t('rentalPartner') }}</BookingListItemTitle>
          <p>
            {{ t(`rentalPartners.${booking.rentalPartner}`) }}
          </p>
        </div>

        <div v-if="!isPartnerRent" class="mt-1 flex basis-[19%]">
          <div class="mr-2 flex flex-col">
            <BookingListItemTitle :booking="booking">{{ t('bookingNumber') }}</BookingListItemTitle>
            <BookingListItemTitle :booking="booking">{{ t('licencePlate') }}</BookingListItemTitle>
          </div>

          <div class="flex flex-col space-y-1">
            <span>{{ bookingNumber }}</span>
            <span>{{ car?.licencePlate }}</span>
          </div>
        </div>

        <div v-else class="mt-1 flex basis-[19%]">
          <div class="mr-2 flex flex-col">
            <BookingListItemTitle :booking="booking">{{ t('ref') }}</BookingListItemTitle>
            <BookingListItemTitle :booking="booking">{{ t('licencePlate') }}</BookingListItemTitle>
          </div>

          <div class="flex flex-col space-y-1">
            <span>{{ booking.internalComment }}</span>
            <span>{{ car?.licencePlate }}</span>
          </div>
        </div>

        <div
          class="relative flex h-11 basis-[25%] overflow-hidden px-2 xl:h-[48px] xl:w-1/5"
          :class="{
            'bg-booking-open-content/20':
              isHandover &&
              !isLateHandover &&
              booking.car.readyToHandover &&
              booking.car.currentLocationType === CarLocationType.STATION,
            'bg-booking-warning-content/20':
              isHandover &&
              !isLateHandover &&
              !booking.car.readyToHandover &&
              booking.car.currentLocationType === CarLocationType.STATION,
            'bg-booking-danger-content/30':
              isLateHandover ||
              (isHandover &&
                (booking.car.currentLocationType !== CarLocationType.STATION ||
                  booking.car.status === CarStatus.ORDERED)),
            'bg-booking-return-content/20': isReturn,
            'bg-booking-transfer-content/20': isTransfer,
            'bg-booking-partner-content/20': isPartnerRent,
          }"
        >
          <p class="clamp m-auto overflow-clip">
            {{ !isPartnerRent ? comment : t('partnerRent') }}
          </p>
        </div>

        <DropdownDotted :buttons="dropDownItems" />
      </div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { CarLocationType } from '@/entities/cars/car-location-type.enum';
import { useBookingCharacteristics } from '@/hooks/use-booking-characteristics';
import { useFormattedDate } from '@/hooks/use-formatted-date';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';
import { computed, toRefs } from 'vue';
import type { DropdownItem } from '@/components/DropdownDotted.vue';
import DropdownDotted from '@/components/DropdownDotted.vue';
import BookingListItemTitle from './BookingListItemTitle.vue';
import { useRouter } from 'vue-router';
import type { ManyBookingsBooking } from '@/entities/bookings/many-bookings-booking.entity';
import { CarStatus } from '@/entities/cars/car-status.enum';
import { BookingType } from '@/entities/bookings/booking-type.enum';

const props = defineProps<{
  booking: ManyBookingsBooking;
}>();

const { t } = useI18n();
const router = useRouter();
const { booking } = toRefs(props);
const startDate = useFormattedDate(booking.value.startDate, DateTime.TIME_24_SIMPLE);
const endDate = useFormattedDate(booking.value.endDate, DateTime.TIME_24_SIMPLE);
const {
  isReturn,
  isHandover,
  isLateHandover,
  isTransfer,
  isTransferDelivery,
  isTransferReturn,
  isPartnerRent,
  isPartnerRentHandover,
  isPartnerRentReturn,
  hasTransfer,
} = useBookingCharacteristics(booking);

const bookingNumber = computed(() =>
  !isTransfer.value
    ? booking.value.bookingNumber
    : (booking.value.relatedBookings.find(
        (relatedBooking) => relatedBooking.type === BookingType.CUSTOMER_BOOKING,
      )?.bookingNumber ?? '-'),
);

const linkName = computed(() => {
  if (isReturn.value) {
    return 'returnOverview';
  } else if (isHandover.value) {
    return 'handoverDetail';
  } else if (isTransfer.value) {
    return 'transferDetail';
  } else if (isPartnerRent.value) {
    return 'partnerRentHandover';
  } else return undefined;
});

const title = computed(() => {
  if (isReturn.value || isPartnerRentReturn.value) {
    return t('return');
  } else if (isHandover.value || isPartnerRentHandover.value) {
    return t('handover');
  } else if (isTransferDelivery.value) {
    return t('transferDelivery');
  } else if (isTransferReturn.value) {
    return t('transferReturn');
  } else return undefined;
});

const comment = computed(() => {
  return booking.value.internalComment ? booking.value.internalComment : 'Kein Kommentar';
});

const car = computed(() => booking.value.car);

const dropDownItems = computed<DropdownItem[]>(() => {
  const items = [];
  if (isTransfer.value) {
    items.push({
      onClick: () =>
        router.push({
          name: 'transferEdit',
          params: {
            transferType: props.booking?.type,
            bookingId: props.booking?.relatedBookings?.[0].id,
            transferId: props.booking?.id,
          },
        }),
      title: t('editTransfer'),
    });
  }
  if (isPartnerRent.value) {
    items.push({
      onClick: () =>
        router.push({
          name: 'partnerRentEdit',
          params: {
            carId: props.booking?.car.id,
            bookingId: props.booking?.id,
          },
        }),
      title: t('editPartnerRent'),
    });
  }
  if (isHandover.value || isReturn.value) {
    items.push({
      onClick: () => router.push({ name: 'bookingEdit', params: { id: props.booking.id } }),
      title: t('editBooking'),
    });
    items.push({
      onClick: () =>
        router.push({
          name: 'editCarStatus',
          params: { id: props.booking.car.id },
        }),
      title: t('editCarStatus'),
    });
  }
  return items;
});
</script>

<style scoped>
.clamp {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>

<i18n lang="json">
{
  "en": {
    "handover": "Handover",
    "return": "Return",
    "transferDelivery": "Delivery",
    "transferReturn": "Return",
    "vehicle": "Vehicle",
    "customer": "Customer",
    "driver": "Driver",
    "licencePlate": "Lp.",
    "bookingNumber": "Bnr.",
    "markAsHandoverReady": "Mark as handover ready",
    "markAsOpen": "Mark as open",
    "editBooking": "edit booking",
    "editTransfer": "edit transfer",
    "editPartnerRent": "edit partner rent",
    "editCarStatus": "edit car status",
    "addTransfer": "add transfer",
    "rentalPartner": "Rental Partner",
    "partnerRent": "Partner Rent",
    "ref": "Ref."
  },
  "de": {
    "handover": "Übergabe",
    "return": "Rückgabe",
    "transferDelivery": "Anlieferung",
    "transferReturn": "Rückführung",
    "vehicle": "Fahrzeug",
    "customer": "Kunde",
    "driver": "Fahrer",
    "licencePlate": "Knz.",
    "bookingNumber": "Bnr.",
    "markAsHandoverReady": "Als Übergabebereit markieren",
    "markAsOpen": "Als Offen markieren",
    "editBooking": "Buchung bearbeiten",
    "editTransfer": "Transfer bearbeiten",
    "editPartnerRent": "Partnermiete bearbeiten",
    "editCarStatus": "Fahrzeugstatus bearbeiten",
    "addTransfer": "Transfer hinzufügen",
    "rentalPartner": "Mietpartner",
    "partnerRent": "Partnermiete",
    "ref": "Ref."
  }
}
</i18n>
