<template>
  <div v-if="stations" class="space-y-4">
    <StationAndTimesForm
      :stations="stations"
      :station="locationsAndTimes.station"
      :start-date="locationsAndTimes.startDate"
      :end-date="locationsAndTimes.endDate"
      @change="onStationAndTimesChange"
    />

    <Locations
      v-if="locationsAndTimes.station === CUSTOM_LOCATIONS"
      :stations="stations"
      :pickup-location="pickupLocation"
      :dropoff-location="dropoffLocation"
      @change="onLocationsChange"
    />

    <div class="grid grid-cols-3 items-end gap-4">
      <FilterDropdowns
        :category-filter="categoryFilter"
        :make-filter="makeFilter"
        class="col-span-2"
        @change="onFiltersChange"
      />

      <CVButton
        v-if="hasChanged"
        :disabled="!customLocationsComplete"
        @click.prevent="updateProps"
        >{{ t('findCars') }}</CVButton
      >
    </div>
  </div>
  <div v-else class="flex items-center justify-center py-4"><Spinner /></div>
</template>

<script setup lang="ts">
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import StationAndTimesForm, { type StationAndTimes } from './StationAndTimesForm.vue';
import type { BookingLocation } from '@/entities/bookings/booking.entity';
import type { Station } from '@/entities/station/station.entity';
import Locations from './Locations.vue';
import { CUSTOM_LOCATIONS, type CustomLocations } from '@/constants/custom-locations.constant';
import { useStations } from '@/queries/use-stations';
import { useI18n } from 'vue-i18n';
import { computed, ref, watch, watchEffect } from 'vue';
import { useFormHasChanged } from '@/hooks/use-form-has-changed';
import { cloneDeep } from 'lodash';
import FilterDropdowns from './FilterDropdowns.vue';
import type { VehicleCategory } from '@/entities/vehicle-category.enum';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';

export interface LocationsAndTimesProps {
  station: Station | CustomLocations | null;
  pickupLocation: BookingLocation | null;
  dropoffLocation: BookingLocation | null;
  startDate: Date;
  endDate: Date;
  categoryFilter: VehicleCategory | null;
  makeFilter: string | null;
}

const props = defineProps<LocationsAndTimesProps>();

const emit = defineEmits<{
  (event: 'change', value: LocationsAndTimesProps): void;
  (event: 'changing-filter', value: boolean): void;
}>();

const { t } = useI18n();

const { data: stationsData } = useStations(500);
const stations = useFlattenPaginatedData(stationsData);

const locationsAndTimes = ref(cloneDeep(props));
const initialLocationsAndTimes = ref(cloneDeep(props));
watchEffect(() => {
  locationsAndTimes.value = cloneDeep(props);
});

const customLocationsComplete = computed(
  () =>
    ((locationsAndTimes.value.pickupLocation?.locationType === BookingLocationType.STATION &&
      locationsAndTimes.value.pickupLocation?.station) ||
      (locationsAndTimes.value.pickupLocation?.locationType === BookingLocationType.STREET &&
        locationsAndTimes.value.pickupLocation?.location)) &&
    ((locationsAndTimes.value.dropoffLocation?.locationType === BookingLocationType.STATION &&
      locationsAndTimes.value.dropoffLocation?.station) ||
      (locationsAndTimes.value.dropoffLocation?.locationType === BookingLocationType.STREET &&
        locationsAndTimes.value.dropoffLocation?.location)),
);

const onStationAndTimesChange = (stationAndTimes: StationAndTimes) => {
  const { station, startDate, endDate } = stationAndTimes;
  const pickupLocation =
    station !== CUSTOM_LOCATIONS
      ? {
          locationType: BookingLocationType.STATION,
          station,
          location: null,
        }
      : locationsAndTimes.value.pickupLocation;
  const dropoffLocation =
    station !== CUSTOM_LOCATIONS
      ? {
          locationType: BookingLocationType.STATION,
          station,
          location: null,
        }
      : locationsAndTimes.value.dropoffLocation;
  locationsAndTimes.value = {
    ...locationsAndTimes.value,
    station,
    pickupLocation,
    dropoffLocation,
    startDate,
    endDate,
  };
  // if (station !== CUSTOM_LOCATIONS) {
  //   updateProps();
  // }
};

const onLocationsChange = ({
  pickupLocation,
  dropoffLocation,
}: {
  pickupLocation: BookingLocation;
  dropoffLocation: BookingLocation;
}) => {
  locationsAndTimes.value = {
    ...locationsAndTimes.value,
    pickupLocation,
    dropoffLocation,
  };
};

const onFiltersChange = (categoryFilter: VehicleCategory | null, makeFilter: string | null) => {
  locationsAndTimes.value = {
    ...locationsAndTimes.value,
    categoryFilter,
    makeFilter,
  };
};

const updateProps = () => {
  emit('change', cloneDeep(locationsAndTimes.value));
  initialLocationsAndTimes.value = cloneDeep(locationsAndTimes.value);
};

const hasChanged = useFormHasChanged(initialLocationsAndTimes, locationsAndTimes);

watch(hasChanged, () => {
  emit('changing-filter', hasChanged.value);
});
</script>

<i18n lang="json">
{
  "en": {
    "findCars": "Find Cars"
  },
  "de": {
    "findCars": "Autos finden"
  }
}
</i18n>
