export enum VehicleCategory {
  CONVERTIBLE = 'CONVERTIBLE',
  CITY_RACER = 'CITY_RACER',
  ELECTRIC_CAR = 'ELECTRIC_CAR',
  LIMOUSINE = 'LIMOUSINE',
  SPORTS_CAR = 'SPORTS_CAR',
  SUPER_CAR = 'SUPER_CAR',
  SUV = 'SUV',
  STATION_WAGON = 'STATION_WAGON',
  VAN = 'VAN',
}
