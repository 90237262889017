import type { VehicleExtraOption } from '@/entities/vehicle-extra-option.entity';
import type { VehicleExtra } from '@/entities/vehicle-extra.entity';
import { VehicleExtraOptionValueType } from '@/entities/vehicle-extra-option-value-type.enum';
import { VehicleExtraType } from '@/entities/vehicle-extra-type.enum';

export const isBiggerExtraOption = (
  option: VehicleExtraOption,
  comparedOption: VehicleExtraOption,
  extra: VehicleExtra,
) => {
  if (option.id === comparedOption.id) return false;
  if ((option.calculatedPrice ?? 0) <= (comparedOption.calculatedPrice ?? 0)) {
    return false;
  }
  if (
    comparedOption.valueType === VehicleExtraOptionValueType.BOOLEAN &&
    comparedOption.value === option.value
  ) {
    return false;
  }
  if (
    extra.type === VehicleExtraType.DEDUCTIBLE &&
    Number(option.value) >= Number(comparedOption.value)
  ) {
    return false;
  }
  if (
    [VehicleExtraType.EXTRA_KM, VehicleExtraType.ADDITIONAL_DRIVER].includes(extra.type) &&
    Number(option.value) <= Number(comparedOption.value)
  ) {
    return false;
  }
  return true;
};
