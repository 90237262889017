<template>
  <div>
    <div class="flex justify-end">
      <p class="col-start-3 mr-2 grow-0 text-right">
        <span class="text-lg font-medium">
          {{ isBlockMode ? t('reservationAmount') : t('amountToCharge') }}:
        </span>
        <span
          v-if="!showOverwriteTotalAmount"
          v-currency="amount"
          class="block text-2xl font-medium"
        />
        <Input
          v-else
          v-model.number="customTotalAmount"
          type="number"
          adornment="€"
          :max-fraction-digits="2"
        />
        <span v-if="isBlockMode" class="block text-base"
          ><a href="#" class="text-link" @click.prevent="toggleOverwriteTotalAmount">{{
            !showOverwriteTotalAmount ? t('changeAmount') : t('resetAmount')
          }}</a></span
        >
      </p>
    </div>

    <div class="mt-6 flex justify-between">
      <div class="flex items-end">
        <div>
          <span v-if="manualReservation" class="mr-1.5 font-medium text-error">×</span>
          <a
            href="#"
            class="text-link"
            :disabled="isSaving"
            @click.prevent="manualReservation = !manualReservation"
          >
            {{
              !manualReservation
                ? `${isBlockMode ? t('blockManually') : t('chargeManually')} →`
                : isBlockMode
                  ? t('cancelBlockManually')
                  : t('cancelChargeManually')
            }}
          </a>
        </div>
      </div>
      <div class="relative">
        <div class="grid grid-rows-1 gap-2">
          <Select v-model="paymentTerminalId" :options="paymentTerminalOptions" />
          <CVButton
            :is-loading="isSaving"
            :disabled="!paymentTerminalId || manualReservation"
            @click.prevent="chargeCreditCardViaTerminal"
          >
            {{ isBlockMode ? t('blockViaTerminal') : t('chargeViaTerminal') }} →
          </CVButton>
          <!-- Temporarily deactivated. Will be activated again in the future. -->
          <!-- <CVButton
            v-if="booking.hasOnlineCreditCardPayment"
            :is-loading="isSaving"
            :disabled="manualReservation"
            outline
            @click.prevent="chargeFromSavedCreditCard"
          >
            {{
              isBlockMode
                ? t('blockOnSavedCreditCard')
                : t('chargeFromSavedCreditCard')
            }}
            →
          </CVButton> -->
          <CVButton
            :is-loading="isSaving"
            :disabled="manualReservation"
            outline
            @click.prevent="sendPaymentLink"
          >
            {{ t('sendPaymentLink') }} →
          </CVButton>
        </div>
        <div
          v-if="isLoadingPaymentTerminals || isLoadingUser"
          class="absolute -inset-2 flex items-center justify-center bg-black/20"
        >
          <Spinner />
        </div>
      </div>
    </div>

    <div v-if="manualReservation">
      <Divider />

      <SpecialCaseDeposit
        v-if="isBlockMode"
        :booking="props.booking"
        :amount="realTotalAmount"
        :is-saving="props.isSaving"
        @continue-without-transaction="$emit('continueWithoutTransaction', realTotalAmount)"
      />
      <SpecialCaseOpenFee
        v-else
        :booking="props.booking"
        :is-saving="props.isSaving"
        @continue-without-transaction="$emit('continueWithoutTransaction', 0)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Input from '@/components/Input.vue';
import Select from '@/components/Select.vue';
import Spinner from '@/components/icons/Spinner.vue';
import type { Booking } from '@/entities/bookings/booking.entity';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { useI18n } from 'vue-i18n';
import { usePaymentTerminalsWithQuery } from '@/queries/use-payment-terminals';
import { useMe } from '@/queries/use-users';
import { computed, ref, watch } from 'vue';
import SpecialCaseDeposit from './SpecialCaseDeposit.vue';
import SpecialCaseOpenFee from './SpecialCaseOpenFee.vue';
import Divider from '@/components/Divider.vue';
import { Alert } from '@/utils/alert';

const props = defineProps<{
  booking: Booking;
  amount: number;
  isSaving?: boolean;
  mode?: 'block' | 'charge';
}>();

const emit = defineEmits<{
  (e: 'chargeCreditCardViaTerminal', paymentTerminalId: string, amount: number): void;
  (e: 'chargeFromSavedCreditCard', amount: number): void;
  (e: 'sendPaymentLink', amount: number): void;
  (e: 'continueWithoutTransaction', amount: number): void;
}>();

const { t } = useI18n();

const showOverwriteTotalAmount = ref(false);
const customTotalAmount = ref<number>();
const manualReservation = ref(false);
const paymentTerminalId = ref<string>();

const isBlockMode = computed(() => props.mode === 'block');

const { data: user, isLoading: isLoadingUser } = useMe();

const { data: paymentTerminalsData, isLoading: isLoadingPaymentTerminals } =
  usePaymentTerminalsWithQuery({
    stationIds: [props.booking?.pickupStation?.id ?? props.booking.car.stations[0].station.id],
    limit: 500,
  });

const paymentTerminals = useFlattenPaginatedData(paymentTerminalsData);

watch(
  [user, paymentTerminals],
  () => {
    if (paymentTerminalId.value) return;
    if (!(user.value && paymentTerminals.value.length)) return;
    if (
      !user.value.preferredPaymentTerminal ||
      !paymentTerminals.value.some(
        (paymentTerminal) => paymentTerminal.id === user.value.preferredPaymentTerminal?.id,
      )
    ) {
      return;
    }
    paymentTerminalId.value = user.value.preferredPaymentTerminal?.id;
  },
  {
    immediate: true,
  },
);

const paymentTerminalOptions = computed(() =>
  paymentTerminals.value.map((paymentTerminal) => ({
    label: `${paymentTerminal.description} (${paymentTerminal.terminalId})`,
    value: paymentTerminal.id,
  })),
);

const toggleOverwriteTotalAmount = () => {
  showOverwriteTotalAmount.value = !showOverwriteTotalAmount.value;
  if (showOverwriteTotalAmount.value) {
    customTotalAmount.value = props.amount;
  } else {
    customTotalAmount.value = undefined;
  }
};

const realTotalAmount = computed(() => customTotalAmount.value ?? props.amount);

const chargeCreditCardViaTerminal = () => {
  if (!paymentTerminalId.value) return;
  emit('chargeCreditCardViaTerminal', paymentTerminalId.value, realTotalAmount.value);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const chargeFromSavedCreditCard = async () => {
  const { isConfirmed } = await Alert.fire({
    text: t(
      isBlockMode.value
        ? 'reallyBlockOnSavedCreditCardText'
        : 'reallyChargeFromSavedCreditCardText',
      {
        maskedNumber: props.booking.onlineCreditCardDetails?.number,
      },
    ),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: isBlockMode.value ? t('block') : t('charge'),
    cancelButtonText: t('cancel'),
  });
  if (!isConfirmed) return;
  emit('chargeFromSavedCreditCard', realTotalAmount.value);
};

const sendPaymentLink = async () => {
  const { isConfirmed } = await Alert.fire({
    text: t('reallySendPaymentLinkText'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('send'),
    cancelButtonText: t('cancel'),
  });
  if (!isConfirmed) return;
  emit('sendPaymentLink', realTotalAmount.value);
};
</script>

<i18n lang="json">
{
  "en": {
    "reservationAmount": "Reservation Amount",
    "amountToCharge": "Amount to charge",
    "blockViaTerminal": "Block amount via terminal",
    "chargeViaTerminal": "Charge amount via terminal",
    "blockOnSavedCreditCard": "Block on saved credit card",
    "chargeFromSavedCreditCard": "Charge from saved credit card",
    "sendPaymentLink": "Send payment link",
    "blockManually": "Special case deposit",
    "cancelBlockManually": "Cancel special case deposit",
    "chargeManually": "Special case open fee",
    "cancelChargeManually": "Cancel special case open fee",
    "changeAmount": "Change Amount",
    "resetAmount": "Reset Amount",
    "reallyBlockOnSavedCreditCardText": "Do you really want to block the deposit on the credit card with the ending {maskedNumber} with which the rental amount was also paid?",
    "reallyChargeFromSavedCreditCardText": "Do you really want to charge the amount on the credit card with the ending {maskedNumber} with which the rental amount was also paid?",
    "block": "Yes, block",
    "charge": "Yes, charge",
    "reallySendPaymentLinkText": "Please only use the payment link if it did not work via the terminal or the car is delivered. The link will be automatically sent to the customer's stored email.",
    "send": "Send"
  },
  "de": {
    "reservationAmount": "Reservierungsbetrag",
    "amountToCharge": "Noch zu zahlender Betrag",
    "blockViaTerminal": "Betrag über Terminal blockieren",
    "chargeViaTerminal": "Betrag über Terminal einziehen",
    "blockOnSavedCreditCard": "Betrag auf hinterlegter Kreditkarte blockieren",
    "chargeFromSavedCreditCard": "Betrag von hinterlegter Kreditkarte einziehen",
    "sendPaymentLink": "Zahlungslink senden",
    "blockManually": "Sonderfall Kaution",
    "cancelBlockManually": "Sonderfall Kaution abbrechen",
    "chargeManually": "Sonderfall offener Mietbetrag",
    "cancelChargeManually": "Sonderfall offener Mietbetrag abbrechen",
    "changeAmount": "Betrag ändern",
    "resetAmount": "Betrag zurücksetzen",
    "reallyBlockOnSavedCreditCardText": "Möchtest du die Kaution wirklich auf der Kreditkarte mit der Endung {maskedNumber} blocken, mit der auch der Mietbetrag bezahlt wurde?",
    "reallyChargeFromSavedCreditCardText": "Möchtest du den Betrag wirklich auf der Kreditkarte mit der Endung {maskedNumber} einziehen, mit der auch der Mietbetrag bezahlt wurde?",
    "block": "Ja, blocken",
    "charge": "Ja, einziehen",
    "reallySendPaymentLinkText": "Bitte Zahlungslink nur verwenden, wenn es über das Terminal nicht geklappt hat, oder das Auto angeliefert wird. Der Link wird automatisch an die hinterlegte E-mail des Kunden geschickt.",
    "send": "Senden"
  }
}
</i18n>
