import { VehiclePoolTab as Tab } from './vehicle-pool-tab.enum';
import type { Car } from '@/entities/cars/car.entity';
import { ref } from 'vue';
import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { getCurrentCarTyresSeason } from '@/utils/get-current-car-tyres-season';
import { Alert } from '@/utils/alert';
import { CarTyres } from '@/entities/cars/car-tyres.enum';
import { useUpdateCar } from '@/queries/use-cars';
import { CarStatus } from '@/entities/cars/car-status.enum';
import type { TranslationFunction } from '@/i18n';

export enum TabsWithDropdown {
  MERCHANT_CONSULTATION = Tab.MERCHANT_CONSULTATION,
  CAR_INCOMING = Tab.CAR_INCOMING,
  INSPECTION = Tab.INSPECTION,
  TUV = Tab.TUV,
  TYRES = Tab.TYRES,
  SELL = Tab.SELL,
}

export const useTodoFunctions = (activeTab: MaybeRefOrGetter<Tab>, t: TranslationFunction) => {
  const showTodosModal = ref(false);
  const currentTodoCar = ref<Car | undefined>(undefined);

  const { mutateAsync: updateCar, isPending: isUpdatingCar } = useUpdateCar();

  const openTodosModal = (car: Car) => {
    showTodosModal.value = true;
    currentTodoCar.value = car;
  };

  const getTodoFunction = (car: Car) => {
    switch (toValue(activeTab)) {
      case Tab.MERCHANT_CONSULTATION:
        return () => openTodosModal(car);
      case Tab.CAR_INCOMING:
        return () => openTodosModal(car);
      case Tab.INSPECTION:
        return () => openTodosModal(car);
      case Tab.TUV:
        return () => openTodosModal(car);
      case Tab.TYRES:
        return async () => {
          const currentTyresSeason = getCurrentCarTyresSeason();
          const { isConfirmed } = await Alert.fire({
            text:
              currentTyresSeason === CarTyres.SUMMER
                ? t('reallyChangedTyresToSummer')
                : t('reallyChangedTyresToWinter'),
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: t('cancel'),
            confirmButtonText:
              currentTyresSeason === CarTyres.SUMMER
                ? t('yesSummerTyresAreApplied')
                : t('yesWinterTyresAreApplied'),
          });
          if (!isConfirmed) return;
          await updateCar({
            id: car.id,
            car: {
              tyres: currentTyresSeason,
            },
          });
        };
      case Tab.SELL:
        return async () => {
          const { isConfirmed } = await Alert.fire({
            text: t('reallyFleetOutCar'),
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: t('cancel'),
            confirmButtonText: t('yesFleetOutCar'),
          });
          if (!isConfirmed) return;
          await updateCar({
            id: car.id,
            car: {
              status: CarStatus.FLOAT_OUT,
            },
          });
        };
    }
    return undefined;
  };

  return {
    getTodoFunction,
    showTodosModal,
    currentTodoCar,
    isUpdatingCar,
  };
};
