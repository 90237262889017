import type {
  Booking,
  CreateBookingAsAgentDto,
  CreateBookingDto,
  FindAllBookingsQueryDto,
  UpdateBookingDto,
} from '@/entities/bookings/booking.entity';
import type { InvoicePositionDto } from '@/entities/invoices/invoice-position';
import type { PaginatedResponse } from '@/entities/pagination/paginated-response';
import { Service } from './http-service';
import type { CreateBookingContractPdfDto } from '@/entities/bookings/create-booking-contract-pdf.dto';
import type { GetBookingContractTemplateDto } from '@/entities/bookings/get-booking-contract-template.dto';
import type { ManyBookingsBooking } from '@/entities/bookings/many-bookings-booking.entity';
import type { autoAdjustTransfersDto } from '@/entities/bookings/auto-adjust-transfers.dto';
import type { PrivateFileKey } from '@/entities/private-file-key.interface';
import type { CountBookingsOfUserDto } from '@carvia/ros-backend/src/bookings/dto/count-bookings-of-user.dto';

class BookingsService extends Service<Booking, CreateBookingDto, UpdateBookingDto> {
  constructor() {
    super('/bookings');
  }

  async createAsAgent(data: CreateBookingAsAgentDto) {
    const res = await this.httpClient.post<Booking>(`${this.endpoint}/as-agent`, data);
    return res.data;
  }

  async getAllWithQuery(params: FindAllBookingsQueryDto) {
    const res = await this.httpClient.get<PaginatedResponse<ManyBookingsBooking>>(
      `${this.endpoint}`,
      {
        params,
      },
    );
    return res.data;
  }

  async countBookingsOfUser(userId: string) {
    const res = await this.httpClient.get<CountBookingsOfUserDto>(
      `${this.endpoint}/count-bookings-of-user/${userId}`,
    );
    return res.data;
  }

  async getAllInvoicePositions(bookingId: string) {
    const res = await this.httpClient.get<InvoicePositionDto[]>(
      `${this.endpoint}/${bookingId}/invoice-positions`,
    );
    return res.data;
  }

  async getBookingContractTemplate(query: GetBookingContractTemplateDto) {
    const res = await this.httpClient.get<string>(
      `${this.endpoint}/${query.bookingId}/contract-template`,
      {
        responseType: 'text',
        params: {
          lang: query.language,
        },
      },
    );
    return res.data;
  }

  async createContract(bookingId: string, data: CreateBookingContractPdfDto) {
    const res = await this.httpClient.post<Booking>(`${this.endpoint}/${bookingId}/contract`, data);
    return res.data;
  }

  async resendContract(bookingId: string) {
    const res = await this.httpClient.post<string>(
      `${this.endpoint}/${bookingId}/mails/handed-over`,
    );
    return res.data;
  }

  async resendBookingConfirmation(bookingId: string) {
    const res = await this.httpClient.post<string>(
      `${this.endpoint}/${bookingId}/mails/booking-confirmation`,
    );
    return res.data;
  }

  async refreshBookingConfirmation(bookingId: string) {
    const res = await this.httpClient.post<PrivateFileKey>(
      `${this.endpoint}/${bookingId}/refresh-booking-confirmation`,
    );
    return res.data;
  }

  async autoAdjustTransfers(bookingId: string, data: autoAdjustTransfersDto) {
    const res = await this.httpClient.post<Booking>(
      `${this.endpoint}/${bookingId}/auto-adjust-transfers`,
      data,
    );
    return res.data;
  }
}

export const bookingsService = new BookingsService();
