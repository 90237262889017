<template>
  <div>
    <div class="flex flex-col space-y-2">
      <div v-for="invoice in invoices" :key="invoice.id">
        <InvoiceListItem :invoice="invoice" />
      </div>
    </div>

    <EntityListExceptions
      :status="invoicesStatus"
      :error="invoicesError"
      :items-count="invoices.length"
      :no-items-text="t('noInvoicesFound')"
    />

    <div v-if="hasNextPage" class="flex justify-center pt-6">
      <CVButton outline :is-loading="isFetchingNextPage" @click="() => fetchNextPage()">
        {{ t('loadMore') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import InvoiceListItem from './InvoiceListItem.vue';
import type { FindAllInvoicesQueryDto } from '@/entities/invoices/invoice.entity';
import { useInvoicesWithQuery } from '@/queries/use-invoices';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import EntityListExceptions from '@/components/EntityListExceptions.vue';
import { useI18n } from 'vue-i18n';
import { toRef } from 'vue';

const props = defineProps<{
  queryParams: FindAllInvoicesQueryDto;
}>();

const { t } = useI18n();

const {
  data: invoicesData,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  status: invoicesStatus,
  error: invoicesError,
} = useInvoicesWithQuery(toRef(props, 'queryParams'));

const invoices = useFlattenPaginatedData(invoicesData);
</script>

<i18n lang="json">
{
  "en": {
    "noInvoicesFound": "No Invoices found"
  },
  "de": {
    "noInvoicesFound": "Keine Rechnungen gefunden"
  }
}
</i18n>
