<template>
  <form @submit.prevent="onSubmit">
    <h2>{{ t('user') }}</h2>

    <div class="mt-4 grid grid-cols-3 gap-4">
      <SelectField
        name="role"
        :label="t('role')"
        :options="roleOptions"
        :disabled="disabled || !authUserIsAdmin"
      />
      <SelectField
        v-if="formValues.role === UserRole.AGENT"
        name="agentType"
        :label="t('agentType')"
        :options="agentTypeOptions"
        :disabled="disabled"
      />
      <InputField name="company" :label="t('company')" :disabled="disabled" />
      <SelectField
        name="gender"
        :label="t('gender')"
        :options="genderOptions"
        :disabled="disabled"
        class="col-start-1"
      />
      <InputField name="firstName" :label="t('firstName')" :disabled="disabled" />
      <InputField name="lastName" :label="t('lastName')" :disabled="disabled" />
      <InputField name="email" :label="t('email')" :disabled="disabled" />
      <InputField name="phone" :label="t('phone')" :disabled="disabled" />
      <DatepickerField name="dateOfBirth" :label="t('dateOfBirth')" :disabled="disabled" />
      <div class="flex gap-x-2">
        <InputField
          class="grow-0 basis-1/2"
          name="country"
          :label="t('country')"
          :disabled="disabled"
        />
        <SelectField
          class="basis-1/2"
          name="preferredLocal"
          :label="t('preferredLocal')"
          :options="preferredLocalOptions"
          :disabled="disabled"
        />
      </div>
      <InputField class="w-full grow" name="street" :label="t('street')" :disabled="disabled" />
      <div class="flex gap-2">
        <InputField class="basis-2/5" name="zip" :label="t('zip')" :disabled="disabled" />
        <InputField name="city" :label="t('city')" :disabled="disabled" />
      </div>
    </div>

    <Divider />

    <div v-if="!isPublicUser">
      <div class="grid grid-cols-3 gap-4">
        <MultiSelectField
          v-if="stations"
          name="stationIds"
          :label="t('stations')"
          :options="
            stations.map((station) => {
              return {
                label: getLocalizedField(station.info),
                value: station.id,
              };
            })
          "
          :disabled="disabled"
        />
      </div>

      <Divider />
    </div>

    <TextareaField name="comment" :label="t('comment')" :disabled="disabled" />

    <Divider />

    <div class="mt-3 flex justify-end">
      <CVButton :is-loading="isLoading" :disabled="disabled" @click="onSubmit">{{
        t('save')
      }}</CVButton>
    </div>
  </form>
</template>

<script lang="ts" setup>
import type { UpdateUserDto, User } from '@/entities/auth/user.entity';
import type { Station } from '@/entities/station/station.entity';
import { useI18n } from 'vue-i18n';
import { userEditSchema } from '@/validation/user.schema';
import { useForm } from 'vee-validate';
import { computed, ref, watch } from 'vue';
import { cloneDeep } from 'lodash';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form';
import InputField from '@/components/InputField.vue';
import SelectField from '@/components/SelectField.vue';
import { Gender } from '@/entities/auth/gender.enum';
import { Local } from '@/entities/auth/local.enum';
import DatepickerField from '@/components/DatepickerField.vue';
import MultiSelectField from '@/components/MultiSelectField.vue';
import Divider from '@/components/Divider.vue';
import { UserRole } from '@/entities/auth/user-role.enum';
import { formHasChanged } from '@/hooks/use-form-has-changed';
import { AgentType } from '@/entities/auth/agent-type.enum';
import TextareaField from '@/components/TextareaField.vue';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

const props = defineProps<{
  user?: User;
  me: User;
  stations: Station[];
  isLoading: boolean;
  isFetching: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSubmit', value: UpdateUserDto): void;
  (e: 'refetch'): void;
}>();

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();

const initialValues: UpdateUserDto = {
  company: props.user?.company,
  gender: props.user?.gender,
  firstName: props.user?.firstName,
  lastName: props.user?.lastName,
  email: props.user?.email,
  phone: props.user?.phone,
  street: props.user?.street,
  zip: props.user?.zip,
  city: props.user?.city,
  country: props.user?.country,
  dateOfBirth: props.user?.dateOfBirth,
  role: props.user?.role ?? UserRole.OPERATOR,
  agentType: props.user?.agentType,
  stationIds: props.user?.stations.map((station) => station.id),
  preferredLocal: props.user?.preferredLocal ?? Local.DE_DE,
  preferredPaymentTerminalId: props.user?.preferredPaymentTerminal?.id,
  comment: props.user?.comment,
};

const disabled = computed(
  () =>
    !!(
      props.user?.role !== UserRole.CUSTOMER &&
      props.user?.role !== UserRole.AGENT &&
      props.me.role !== UserRole.ADMIN
    ),
);

const authUserIsAdmin = computed(() => props.me.role === UserRole.ADMIN);

const isPublicUser = computed(
  () => props.user?.role === UserRole.CUSTOMER || props.user?.role === UserRole.AGENT,
);

const genderOptions = computed(() =>
  Object.values(Gender).map((value) => ({
    label: t(`gender.${value}`),
    value,
  })),
);

const roleOptions = computed(() => {
  return Object.values(UserRole).map((role) => ({
    label: t(`userRoles.${role}`),
    value: role,
  }));
});

const agentTypeOptions = computed(() => {
  return Object.values(AgentType).map((agentType) => ({
    label: t(`agentTypes.${agentType}`),
    value: agentType,
  }));
});

const preferredLocalOptions = computed(() =>
  Object.values(Local).map((value) => ({
    label: t(`locales.${value}`),
    value,
  })),
);

const {
  handleSubmit,
  values: formValues,
  setFieldValue,
} = useForm({
  initialValues,
  validationSchema: userEditSchema,
});

const unchangedValues = ref(cloneDeep(formValues));

const onSubmit = handleSubmit((values) => {
  unchangedValues.value = cloneDeep(formValues);
  emit('onSubmit', values);
});

watch(
  () => formValues.role,
  (role) => {
    if (role === UserRole.AGENT) {
      setFieldValue('agentType', props.user?.agentType);
    }
  },
);

usePreventLeavingUnsavedForm(() => formHasChanged(unchangedValues.value, formValues));
</script>

<i18n lang="json">
{
  "en": {
    "user": "User",
    "role": "Role",
    "agentType": "Partner Type",
    "customer": "Customer",
    "gender": "Gender",
    "firstName": "First Name",
    "lastName": "Last Name",
    "company": "Company",
    "email": "Email",
    "city": "City",
    "zip": "Zip",
    "street": "Street",
    "phone": "Phone",
    "country": "Country",
    "dateOfBirth": "Date Of Birth",
    "preferredLocal": "Pref. Language",
    "stations": "Stations",
    "comment": "Comment",
    "licenseNumber": "License Number",
    "licenseAuthority": "License Authority",
    "licenseDate": "License Date",
    "idCardNumber": "ID Card Number",
    "idCardAuthority": "ID Card Authority",
    "idCardDate": "ID Card Date"
  },
  "de": {
    "user": "Benutzer",
    "role": "Rolle",
    "agentType": "Partnertyp",
    "customer": "Kunde",
    "gender": "Geschlecht",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "company": "Firma",
    "email": "Email",
    "city": "Stadt",
    "zip": "PLZ",
    "street": "Straße",
    "phone": "Telefon",
    "country": "Land",
    "dateOfBirth": "Geburtsdatum",
    "preferredLocal": "Bev. Sprache",
    "stations": "Stationen",
    "comment": "Kommentar",
    "licenseNumber": "Führerschein Nr.",
    "licenseAuthority": "Behörde",
    "licenseDate": "Ausstelldatum",
    "idCardNumber": "Ausweis Nr.",
    "idCardAuthority": "Behörde",
    "idCardDate": "Ausstelldatum"
  }
}
</i18n>
