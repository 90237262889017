import { useLocalStorage, type RemovableRef } from '@vueuse/core';
import { defineStore } from 'pinia';

export interface StationState {
  currentStationId?: RemovableRef<string> | null;
}

const STATION_STORAGE_KEY = 'STATION_STORAGE_KEY';

export const useStationStore = defineStore({
  id: 'stationStore',
  state: () =>
    ({
      currentStationId: useLocalStorage<string>(STATION_STORAGE_KEY, null),
    }) as StationState,
  getters: {},
  actions: {
    setCurrentStation(stationId: string | null | undefined) {
      this.currentStationId = stationId ? stationId : null;
    },
  },
});
