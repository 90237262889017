import { authService } from '@/api/auth.service';
import type { JwtTokenResponse } from '@/entities/auth/jwt-token-response.entity';
import type { LoginDto } from '@/entities/auth/login-dto.entity';
import { useMutation, useQuery } from '@tanstack/vue-query';
import type { MutationDefaultsContext } from '../query-client';

const QUERY_KEY = 'authUser';

export const useTokenPayload = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => authService.getTokenPayload(),
    enabled: false,
    retry: 1,
  });
};

export const useLogin = () =>
  useMutation<JwtTokenResponse, unknown, LoginDto, MutationDefaultsContext>({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ email, password }) => authService.login(email, password),
    onMutate: () => ({
      successMessage: 'Login successful',
      invalidateQueryKeys: [QUERY_KEY],
    }),
  });

export const useOAuthCallback = () =>
  useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (queryString: string) => authService.oauthCallback(queryString),
    onMutate: () => ({
      successMessage: 'Login successful',
      invalidateQueryKeys: [QUERY_KEY],
    }),
  });
