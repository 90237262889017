import { vehicleTypesService } from '@/api/vehicle-types.service';
import type {
  CreateVehicleTypeDto,
  FindAllVehicleTypesQuery,
  GetOneVehicleTypeWithQuery,
  UpdateVehicleTypeDto,
  VehicleType,
} from '@/entities/vehicle-type.entity';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { type MaybeRefOrGetter, toRef, toValue } from '@vueuse/core';
import { updateItemOnQueriesData } from '@/utils/update-query-data';

const QUERY_KEY = 'vehicle-types';

export const useVehicleType = (
  id: MaybeRefOrGetter<string>,
  enabled?: MaybeRefOrGetter<boolean>,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(id)],
    queryFn: () => vehicleTypesService.getOne(toValue(id)),
    enabled: toRef(enabled),
  });
};

export const useVehicleTypeWithQuery = (
  id: MaybeRefOrGetter<string>,
  query: MaybeRefOrGetter<GetOneVehicleTypeWithQuery>,
  enabled?: MaybeRefOrGetter<boolean>,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(id)],
    queryFn: () => vehicleTypesService.getOneWithQuery(toValue(id), toValue(query)),
    enabled: toRef(enabled),
  });
};

export const useVehicleTypesWithQuery = (
  query: MaybeRefOrGetter<FindAllVehicleTypesQuery>,
  enabled?: MaybeRefOrGetter<boolean>,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(query)],
    queryFn: () => vehicleTypesService.getAllWithQuery(toValue(query)),
    enabled: toRef(enabled),
  });
};

export const useVehicleTypes = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => vehicleTypesService.getAllWithQuery({}),
  });
};

export const useUpdateVehicleType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ id, vehicleType }: { id: string; vehicleType: UpdateVehicleTypeDto }) =>
      vehicleTypesService.update(id, vehicleType),
    onMutate: () => ({
      successMessage: 'Vehicle Type updated',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(null, null, context);
      updateItemOnQueriesData<VehicleType>([QUERY_KEY], result, queryClient);
    },
  });
};

export const useCreateVehicleType = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (vehicleType: CreateVehicleTypeDto) => vehicleTypesService.create(vehicleType),
  });
};
