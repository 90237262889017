<template>
  <vue-final-modal
    v-model="showModal"
    class="flex items-start justify-center pt-4"
    content-class="p-4 bg-white w-11/12 max-w-5xl"
    content-transition="vfm-fade"
    overlay-transition="vfm-fade"
  >
    <div v-if="showModal" class="space-y-4">
      <h2>{{ t('addUpgradeCar') }}</h2>
      <SearchCarInput :station-id="stationId" @update-car="updateCar" />
    </div>
  </vue-final-modal>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import SearchCarInput from './SearchCarInput.vue';
import type { Car } from '@/entities/cars/car.entity';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  modelValue: boolean;
  stationId: string | undefined;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'carSelected', value: Car): void;
}>();

const { t } = useI18n();

const showModal = ref(props.modelValue);

const updateCar = (car: Car) => {
  if (!car) return;
  emit('carSelected', car);
  showModal.value = false;
};

watch(
  () => props.modelValue,
  (value) => (showModal.value = value),
);

watch(
  () => showModal.value,
  (value) => emit('update:modelValue', value),
);
</script>

<i18n lang="json">
{
  "en": {
    "addUpgradeCar": "Add upgrade car"
  },
  "de": {
    "addUpgradeCar": "Upgrade Auto hinzufügen"
  }
}
</i18n>
