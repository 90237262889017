<template>
  <ValuePairsList
    :value-pairs="valuePairs"
    :title="t('inclusiveKmDay')"
    :button-text="t('addInclusivKmStep')"
    name="inclusiveKm"
    :value-names="['fromDay', 'kmInclusivePerDay']"
    :labels="labels"
    :is-inclusive-km="true"
    @add-value-pair="push({ fromDay: null, kmInclusivePerDay: null })"
    @remove-value-pair="remove"
  />
</template>

<script lang="ts" setup>
import ValuePairsList, { type ValuePairs, type Labels } from '../ValuePairsListField.vue';
import { useI18n } from 'vue-i18n';
import { computed, watchEffect } from 'vue';
import { useFieldArray } from 'vee-validate';

const { t } = useI18n();

const labels = computed<Labels>(() => [
  {
    label: t('fromDay'),
  },
  {
    label: t('inclusiveKmDay'),
    adornment: 'km',
  },
]);

const { remove, push, fields } = useFieldArray<{
  fromDay: number | null;
  kmInclusivePerDay: number | null;
}>('inclusiveKm');

const valuePairs = computed<ValuePairs>(() =>
  fields.value.map((field) => [field.value.fromDay, field.value.kmInclusivePerDay]),
);
watchEffect(() => {
  if (fields.value.length === 0) {
    push({
      fromDay: 1,
      kmInclusivePerDay: null,
    });
  }
});
</script>

<i18n lang="json">
{
  "en": {
    "inclusiveKmDay": "Inclusive Kilometers / day",
    "addInclusivKmStep": "+ Add Inclusive Kilometers Step",
    "fromDay": "From Day"
  },
  "de": {
    "inclusiveKmDay": "Inklusivkilometer / Tag",
    "addInclusivKmStep": "+ Inklusivkilometerstaffelung hinzufügen",
    "fromDay": "Ab Tag"
  }
}
</i18n>
