import type { JwtTokenResponse } from '@/entities/auth/jwt-token-response.entity';
import type { JWTUser } from '@/entities/auth/jwt-user.entity';
import type { CreateUserDto, User } from '@/entities/auth/user.entity';
import { ROLES_ALLOWED_TO_USE_APP } from '@/utils/roles-allowed-to-use-app';
import axios from 'axios';
import { REFRESH_TOKEN_STORAGE_KEY, TOKEN_STORAGE_KEY } from './constants';
import { Service } from './http-service';
import { API_URL } from '@/constants/api-url.constant';
import type { RawRule } from '@casl/ability';
import { useAuthStore } from '@/stores/auth.store';

class AuthService extends Service {
  constructor() {
    super('/auth');
  }

  async login(username: string, password: string) {
    const res = await this.httpClient.post<JwtTokenResponse>(
      `${this.endpoint}/login`,
      {
        username,
        password,
      },
      {
        headers: {
          Authorization: null,
        },
      },
    );
    this.verifyUserAccess(res.data.user);
    this.storeTokenInfo(res.data);
    return res.data;
  }

  async signUp(data: CreateUserDto, login = true) {
    const res = await this.httpClient.post<JwtTokenResponse>(`${this.endpoint}/signup`, data);
    if (login) {
      this.storeTokenInfo(res.data);
    }
    return res.data;
  }

  async logout() {
    await this.httpClient.post(`${this.endpoint}/logout`);
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
  }

  async refreshAccessToken() {
    const response = await axios.get<JwtTokenResponse>(`${API_URL}${this.endpoint}/refresh`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY)}`,
      },
    });
    this.storeTokenInfo(response.data);
    return response.data;
  }

  async getMe(): Promise<User> {
    const res = await this.httpClient.get<User>(`${this.endpoint}/me`);
    return res.data;
  }

  async getAbilities(): Promise<RawRule[]> {
    const res = await this.httpClient.get<RawRule[]>(`${this.endpoint}/abilities`);
    return res.data;
  }

  async getTokenPayload() {
    const res = await this.httpClient.get<JWTUser>(`${this.endpoint}/token-payload`);
    return res.data;
  }

  protected storeTokenInfo(data: JwtTokenResponse) {
    localStorage.setItem(TOKEN_STORAGE_KEY, data.access_token);
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, data.refreshToken);
    useAuthStore().setAuthUser(data.user);
  }

  protected verifyUserAccess(user: JWTUser) {
    if (!(user.role && ROLES_ALLOWED_TO_USE_APP.includes(user.role))) {
      throw new Error('Access denied');
    }
  }

  async sendPasswordResetEmail(email: string) {
    const res = await this.httpClient.post(`${this.endpoint}/send-password-reset-mail`, { email });
    return res.data;
  }

  async resetPassword(token: string, password: string): Promise<JwtTokenResponse> {
    const res = await this.httpClient.post(`${this.endpoint}/reset-password`, {
      token,
      password,
    });
    this.storeTokenInfo(res.data);
    return res.data;
  }

  async startGoogleLogin() {
    window.location.href = `${API_URL}${this.endpoint}/google/login`;
  }

  async oauthCallback(queryString: string) {
    const res = await this.httpClient.get<JwtTokenResponse>(
      `${this.endpoint}/google/verify${queryString}`,
    );
    this.verifyUserAccess(res.data.user);
    this.storeTokenInfo(res.data);
    return res.data;
  }
}

export const authService = new AuthService();
