<template>
  <router-link v-if="to" :to="to" class="btn gap-2" :class="classes">
    <slot></slot>
    <span v-if="isLoading" class="loading loading-spinner"></span>
  </router-link>
  <button v-else class="btn gap-2" :class="classes">
    <slot></slot>
    <span v-if="isLoading" class="loading loading-spinner"></span>
  </button>
</template>

<script lang="ts" setup>
import { computed, toRef } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';

const props = withDefaults(
  defineProps<{
    variant?: 'primary' | 'secondary' | 'success' | 'warning' | 'error';
    outline?: boolean;
    to?: RouteLocationNamedRaw;
    noHover?: boolean;
    isLoading?: boolean;
  }>(),
  {
    variant: 'primary',
    outline: false,
    to: undefined,
  },
);

const variant = toRef(props, 'variant');
const outline = toRef(props, 'outline');
const noHover = toRef(props, 'noHover');

const classes = computed(() => ({
  'btn-primary': variant.value === 'primary',
  'btn-secondary': variant.value === 'secondary',
  'btn-success': variant.value === 'success',
  'btn-warning': variant.value === 'warning',
  'btn-error': variant.value === 'error',
  'btn-outline': outline.value,
  'no-hover': noHover.value,
}));
</script>

<style scoped>
[data-theme='carvia'] .btn {
  @apply rounded-none text-sm lg:text-base;
  animation: button-pop var(--animation-btn, 0.25s) ease-out;
}
[data-theme='carvia'] .btn:not(.btn-sm, .btn-xs) {
  @apply h-9 min-h-min;
}

[data-theme='carvia'] .btn:not(.btn-outline .no-hover):hover {
  @apply bg-transparent;
}

[data-theme='carvia'] .btn.btn-primary:not(.btn-outline, [disabled]) {
  @apply text-white;
}
[data-theme='carvia'] .btn.btn-primary:not(.btn-outline .no-hover):hover {
  @apply border-primary text-primary;
}
[data-theme='carvia'] .btn.btn-primary.btn-outline:not(.no-hover):hover {
  @apply border-primary bg-primary text-white;
}

[data-theme='carvia'] .btn.btn-secondary:not(.btn-outline) {
  @apply text-white;
}
[data-theme='carvia'] .btn.btn-secondary:not(.btn-outline .no-hover):hover {
  @apply text-secondary;
}
[data-theme='carvia'] .btn.btn-secondary.btn-outline:not(.no-hover):hover {
  @apply border-secondary bg-secondary text-white;
}

[data-theme='carvia'] .btn.btn-success:not(.btn-outline) {
  @apply text-white;
}
[data-theme='carvia'] .btn.btn-success:not(.btn-outline .no-hover):hover {
  @apply text-success;
}
[data-theme='carvia'] .btn.btn-success.btn-outline:not(.no-hover):hover {
  @apply border-success bg-success text-white;
}

[data-theme='carvia'] .btn.btn-warning:not(.btn-outline) {
  @apply text-white;
}
[data-theme='carvia'] .btn.btn-warning:not(.btn-outline .no-hover):hover {
  @apply text-warning;
}
[data-theme='carvia'] .btn.btn-warning.btn-outline:not(.no-hover):hover {
  @apply border-warning bg-warning text-white;
}

[data-theme='carvia'] .btn.btn-error:not(.btn-outline) {
  @apply text-white;
}
[data-theme='carvia'] .btn.btn-error:not(.btn-outline .no-hover):hover {
  @apply text-error;
}
[data-theme='carvia'] .btn.btn-error.btn-outline:not(.no-hover):hover {
  @apply border-error bg-error text-white;
}
</style>
