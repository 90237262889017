<template>
  <div>
    <CVButton v-if="!showDownloadOptions" outline @click.prevent="showDownloadOptions = true">{{
      t('downloadCsv')
    }}</CVButton>
    <div v-else class="flex items-center justify-start gap-4">
      <Select v-model="monthOrYear" :options="monthOrYearOptions" :disabled="isPending" />
      <Datepicker
        v-if="monthOrYear === MonthOrYear.MONTH"
        v-model="month"
        v-bind="$attrs"
        locale="de"
        :format-locale="de"
        format="MM/yyyy"
        auto-apply
        close-on-auto-apply
        month-picker
        :disabled="isPending"
      />
      <Datepicker
        v-else
        v-model="year"
        v-bind="$attrs"
        locale="de"
        :format-locale="de"
        format="yyyy"
        auto-apply
        close-on-auto-apply
        year-picker
        :disabled="isPending"
      />
      <CVButton :is-loading="isPending" @click.prevent="downloadCsv">{{ t('download') }}</CVButton>
      <CVButton outline :disabled="isPending" @click.prevent="showDownloadOptions = false">{{
        t('cancel')
      }}</CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Select from '@/components/Select.vue';
import { useI18n } from 'vue-i18n';
import { de } from 'date-fns/locale';
import { DateTime } from 'luxon';
import { ref } from 'vue';
import { useExportInvoicesCsv } from '@/queries/use-invoices';
import fileDownload from 'js-file-download';
import { addLeadingZeros } from '@/utils/format-numbers';

const enum MonthOrYear {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

const { t } = useI18n();
const { mutateAsync: exportInvoicesCsv, isPending } = useExportInvoicesCsv();

const showDownloadOptions = ref(false);
const month = ref({
  month: DateTime.now().minus({ month: 1 }).month - 1,
  year: DateTime.now().minus({ month: 1 }).year,
});
const year = ref(DateTime.now().minus({ year: 1 }).year);
const monthOrYear = ref(MonthOrYear.MONTH);

const monthOrYearOptions = [
  {
    label: t('month'),
    value: MonthOrYear.MONTH,
  },
  {
    label: t('year'),
    value: MonthOrYear.YEAR,
  },
];

const downloadCsv = async () => {
  const invoiceDateAfter =
    monthOrYear.value === MonthOrYear.MONTH
      ? DateTime.fromObject({
          year: month.value.year,
          month: month.value.month + 1,
        }).startOf('month')
      : DateTime.fromObject({ year: year.value }).startOf('year');
  const invoiceDateBefore =
    monthOrYear.value === MonthOrYear.MONTH
      ? invoiceDateAfter.endOf('month')
      : invoiceDateAfter.endOf('year');

  const res = await exportInvoicesCsv({
    invoiceDateAfter: invoiceDateAfter.toJSDate(),
    invoiceDateBefore: invoiceDateBefore.toJSDate(),
  });
  const fileName = `${t('invoicesExport')}-${
    monthOrYear.value === MonthOrYear.MONTH
      ? month.value.year + '-' + addLeadingZeros(month.value.month + 1, 2)
      : year.value
  }.csv`;
  fileDownload(res, fileName);
  showDownloadOptions.value = false;
};
</script>

<i18n lang="json">
{
  "en": {
    "downloadCsv": "Download CSV ...",
    "download": "Download",
    "month": "Month",
    "year": "Year",
    "invoicesExport": "invoices-export"
  },
  "de": {
    "downloadCsv": "CSV runterladen ...",
    "download": "Runterladen",
    "month": "Monat",
    "year": "Jahr",
    "invoicesExport": "rechnungen-export"
  }
}
</i18n>
