<template>
  <div>
    <div v-if="!showWeeks" class="flex flex-col gap-2">
      <BookingListItem v-for="booking in bookings" :key="booking.id" :booking="booking" />
    </div>
    <div v-else class="flex flex-col gap-3">
      <div v-for="week in bookingsPerWeek" :key="week.week" class="flex flex-col gap-2">
        <div class="text-sm text-primary">
          <span class="font-medium">{{ t('cw') }} {{ formatDate(week.week, 'W') }}</span>
          —
          {{ formatDate(week.week, getDateFormat()) }}
        </div>
        <div class="flex flex-col gap-2">
          <div v-for="booking in week.bookings" :key="booking.id">
            <BookingListItem :booking="booking" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ManyBookingsBooking } from '@/entities/bookings/many-bookings-booking.entity';
import { getDateFormat } from '@/hooks/use-date-format';
import { formatDate } from '@/hooks/use-formatted-date';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import BookingListItem from './BookingListItem.vue';

const props = defineProps<{
  bookings: ManyBookingsBooking[];
  showWeeks?: boolean;
}>();

const { t } = useI18n();

const bookingsPerWeek = computed(() =>
  props.bookings
    .reduce(
      (weeks, booking) => {
        const bookingDate = booking.startDate;
        const weekDate = DateTime.fromISO(bookingDate).startOf('week').toISO();
        const week = weeks.find(({ week }) => week === weekDate);
        if (!week) {
          weeks.push({
            week: weekDate!,
            bookings: [booking],
          });
        } else {
          week.bookings.push(booking);
        }
        return weeks;
      },
      [] as { week: string; bookings: ManyBookingsBooking[] }[],
    )
    .sort((a, b) => (DateTime.fromISO(a.week) > DateTime.fromISO(b.week) ? 1 : -1)),
);
</script>

<i18n lang="json">
{
  "en": {
    "cw": "CW"
  },
  "de": {
    "cw": "KW"
  }
}
</i18n>
