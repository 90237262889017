<template>
  <div v-if="booking.status !== BookingStatus.CANCELED" class="space-y-4">
    <Checkbox v-model="sendCancelledMail" :label="t('sendCancelledMail')" />
    <CVButton outline variant="error" :is-loading="isSaving" @click.prevent="cancelBooking">
      {{ t('cancelBooking') }}
    </CVButton>
  </div>

  <div v-else-if="!hideRevertCancelling">
    <CVButton outline variant="success" :is-loading="isSaving" @click.prevent="revertCancelling">
      {{ t('revertCancelling') }}
    </CVButton>
  </div>
</template>

<script lang="ts" setup>
import Checkbox from '@/components/Checkbox.vue';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import type { UpdateBookingQueryDto } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';
import { Alert } from '@/utils/alert';
import { DateTime } from 'luxon';
import { ref } from 'vue';
import {
  CANCELLATION_FEES,
  CancellationFee,
} from '@carvia/ros-backend/src/bookings/constants/cancellation-fees';
import { CancelledBy } from '@carvia/ros-backend/src/bookings/dto/cancelled-by.enum';

const props = defineProps<{
  booking: Booking;
  isSaving: boolean;
  hideRevertCancelling?: boolean;
}>();

const emit = defineEmits<{
  (
    e: 'updateBooking',
    query: { id: string; booking: UpdateBookingDto; query?: UpdateBookingQueryDto },
    onFinished?: () => void,
  ): void;
  (e: 'bookingCancelled'): void;
}>();

const { t } = useI18n();

const sendCancelledMail = ref(true);

const cancelBooking = async () => {
  const {
    reason,
    cancellationFee,
    autoCancellationFee,
    isConfirmed: isFeeConfirmed,
  } = await showFeeAlert();
  if (!isFeeConfirmed) return;
  const { cancelledBy, isConfirmed: isCauserConfirmed } = await showCancelledByAlert(reason);
  if (!isCauserConfirmed) return;
  const { comment, isConfirmed: isCommentConfirmed } = await showFeeCommentAlert(reason);
  if (!isCommentConfirmed) return;

  if (autoCancellationFee === CancellationFee.NO_FEE) {
    const { isConfirmed } = await Alert.fire({
      text: t('reallyCancelBookingText'),
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: t('cancelBooking'),
      denyButtonText: t('dontCancel'),
      focusDeny: true,
    });
    if (!isConfirmed) return;
  }

  emit(
    'updateBooking',
    {
      id: props.booking.id,
      booking: {
        status: BookingStatus.CANCELED,
      },
      query: {
        overrideCancellationFee: cancellationFee,
        overrideCancellationFeeComment: reason
          ? t('reason.' + reason) + (comment ? ' | ' + comment : '')
          : undefined,
        cancelledBy,
        sendCancelledMail: sendCancelledMail.value,
      },
    },
    async () => {
      await Alert.fire({
        titleText: t('bookingCancelledTitle'),
        text: t('bookingCancelledText'),
        icon: 'success',
        confirmButtonText: t('ok'),
      });
      emit('bookingCancelled');
    },
  );
};

const showFeeAlert = async () => {
  const startDate = DateTime.fromISO(props.booking.startDate);
  const now = DateTime.now();
  const startDateDiff = startDate.diff(now, 'days').days;
  const autoCancellationFee =
    startDateDiff < 0
      ? CancellationFee.AFTER_BOOKING_START_DATE
      : startDateDiff < 7
        ? CancellationFee.WITHIN_SEVEN_DAYS
        : CancellationFee.NO_FEE;
  const autoCancellationFeeInPercent = Math.round(CANCELLATION_FEES[autoCancellationFee] * 100);
  if (
    !(
      props.booking.status === BookingStatus.OPEN ||
      props.booking.status === BookingStatus.CONFIRMED ||
      props.booking.status === BookingStatus.FLOATING ||
      props.booking.status === BookingStatus.HANDED_OVER
    ) ||
    autoCancellationFee === CancellationFee.NO_FEE
  ) {
    return {
      reason: Reason.NO_REASON,
      cancellationFee: CancellationFee.NO_FEE,
      autoCancellationFee,
      isConfirmed: true,
    };
  }
  const { value: reason, isConfirmed } = await Alert.fire<Reason>({
    text: t('cancellationFeeText', { feeInPercent: autoCancellationFeeInPercent }),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('ok'),
    denyButtonText: t('dontCancel'),
    input: 'select',
    inputOptions: {
      [Reason.NO_REASON]: t('noReason'),
      [Reason.MISAPPROPRIATION]: getSelectOptionText(
        Reason.MISAPPROPRIATION,
        CancellationFee.NO_FEE,
      ),
      [Reason.NOT_AVAILABLE]: getSelectOptionText(Reason.NOT_AVAILABLE, CancellationFee.NO_FEE),
      [Reason.GOODWILL]: getSelectOptionText(
        Reason.GOODWILL,
        autoCancellationFee === CancellationFee.AFTER_BOOKING_START_DATE
          ? CancellationFee.WITHIN_SEVEN_DAYS
          : CancellationFee.NO_FEE,
      ),
    },
  });
  const cancellationFee = determineCancellationFee(startDateDiff, reason);
  return { reason, cancellationFee, autoCancellationFee, isConfirmed };
};

const getSelectOptionText = (reason: Reason, cancellationFee: CancellationFee) => {
  return t('optionText', {
    reason: t('reason.' + reason),
    fee: Math.round(CANCELLATION_FEES[cancellationFee] * 100),
  });
};

enum Reason {
  NO_REASON = '',
  MISAPPROPRIATION = 'misappropriation',
  NOT_AVAILABLE = 'notAvailable',
  GOODWILL = 'goodwill',
}

const determineCancellationFee = (startDateDiff: number, reason: Reason | undefined) => {
  if (reason === Reason.MISAPPROPRIATION || reason === Reason.NOT_AVAILABLE)
    return CancellationFee.NO_FEE;
  if (!reason && startDateDiff < 0) return CancellationFee.AFTER_BOOKING_START_DATE;
  if (reason && startDateDiff < 0) return CancellationFee.WITHIN_SEVEN_DAYS;
  if (!reason && startDateDiff < 7) return CancellationFee.WITHIN_SEVEN_DAYS;
  return CancellationFee.NO_FEE;
};

const showCancelledByAlert = async (reason: Reason | undefined) => {
  if (reason && reason !== Reason.GOODWILL) return { cancelledBy: undefined, isConfirmed: true };
  const { value: cancelledBy, isConfirmed } = await Alert.fire<CancelledBy>({
    text: t('cancellationCauserText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('ok'),
    denyButtonText: t('dontCancel'),
    input: 'select',
    inputOptions: {
      [CancelledBy.US]: t('cancelledBy.' + CancelledBy.US),
      [CancelledBy.CUSTOMER]: t('cancelledBy.' + CancelledBy.CUSTOMER),
    },
  });
  return { cancelledBy, isConfirmed };
};

const showFeeCommentAlert = async (reason: Reason | undefined) => {
  if (!reason) return { comment: undefined, isConfirmed: true };
  const { value: comment, isConfirmed } = await Alert.fire<string>({
    text: t('feeComment', { reason: t('reason.' + reason) }),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('ok'),
    denyButtonText: t('dontCancel'),
    input: 'textarea',
    inputPlaceholder: t('feeCommentPlaceholder'),
    inputValidator: (value: string) => value.length < 10 && t('commentTooShort'),
  });
  return { comment, isConfirmed };
};

const revertCancelling = async () => {
  const { isConfirmed } = await Alert.fire({
    text: t('reallyRevertCancellationText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('revertCancellation'),
    denyButtonText: t('dontRevert'),
    focusDeny: true,
  });
  if (!isConfirmed) return;

  emit(
    'updateBooking',
    {
      id: props.booking.id,
      booking: {
        status: BookingStatus.DRAFT,
      },
    },
    async () => {
      await Alert.fire({
        titleText: t('cancellationRevertedTitle'),
        text: t('cancellationRevertedText'),
        icon: 'success',
        confirmButtonText: t('ok'),
      });
    },
  );
};
</script>

<i18n lang="json">
{
  "en": {
    "cancelBooking": "Cancel Booking",
    "reallyCancelBookingText": "Do you really want to cancel the Booking?",
    "bookingCancelledTitle": "Booking cancelled",
    "bookingCancelledText": "The Booking was successfully cancelled",
    "dontCancel": "Don't cancel",
    "cancellationFeeText": "After our cancellation rules, cancellation fees of {feeInPercent}% of the total price are due. If we should charge less or no cancellation fee, please choose the reason here",
    "noReason": "No reason - charge cancellation fee",
    "reason": {
      "misappropriation": "Customer could misappropriate car",
      "notAvailable": "Booked car is not available",
      "goodwill": "Goodwill"
    },
    "optionText": "{reason} - to {fee}% fee",
    "cancellationCauserText": "Cancelled by ...",
    "cancelledBy": {
      "US": "Cancelled by us",
      "CUSTOMER": "Cancelled by Customer"
    },
    "feeComment": "More infos about selection: \"{reason}\"",
    "feeCommentPlaceholder": "More reasons and details on the reduction of the fee",
    "commentTooShort": "Comment too short",
    "ok": "OK",
    "sendCancelledMail": "Send cancellation mail to customer",
    "revertCancelling": "Revert cancelling",
    "reallyRevertCancellationText": "Do you really want to revert the cancellation?",
    "revertCancellation": "Revert cancellation",
    "dontRevert": "Don't revert",
    "cancellationRevertedTitle": "Cancellation reverted",
    "cancellationRevertedText": "The cancellation was successfully reverted"
  },
  "de": {
    "cancelBooking": "Buchung stornieren",
    "reallyCancelBookingText": "Möchtest du die Buchung wirklich stornieren?",
    "bookingCancelledTitle": "Buchung storniert",
    "bookingCancelledText": "Die Buchung wurde erfolgreich storniert",
    "dontCancel": "Abbrechen",
    "cancellationFeeText": "Nach unseren Stornierungsregeln werden Gebühren von {feeInPercent}% des Gesamtpreises für die Stornierung fällig. Falls wir weniger/keine Gebühren in Rechnung stellen sollen, gib hier bitte den Grund an",
    "noReason": "Kein Grund - Gebühr berechnen",
    "reason": {
      "misappropriation": "Kunde könnte Auto unterschlagen",
      "notAvailable": "Gebuchtes Auto ist nicht verfügbar",
      "goodwill": "Kulanz"
    },
    "optionText": "{reason} - auf {fee}% Gebühr",
    "cancellationCauserText": "Storniert durch ...",
    "cancelledBy": {
      "US": "Storniert durch uns",
      "CUSTOMER": "Storniert durch Kunde"
    },
    "feeComment": "Mehr Infos zur Auswahl: \"{reason}\"",
    "feeCommentPlaceholder": "Weitere Gründe und Details zur Herabsetzung der Gebühr",
    "commentTooShort": "Kommentar zu kurz",
    "ok": "OK",
    "sendCancelledMail": "Stornierungsmail an Kunden senden",
    "revertCancelling": "Stornierung rückgängig machen",
    "reallyRevertCancellationText": "Möchtest du die Stornierung wirklich rückgängig machen?",
    "revertCancellation": "Stornierung rückgängig machen",
    "dontRevert": "Nicht rückgängig machen",
    "cancellationRevertedTitle": "Stornierung rückgängig gemacht",
    "cancellationRevertedText": "Die Stornierung wurde erfolgreich rückgängig gemacht"
  }
}
</i18n>
