<template>
  <div>
    <h2>{{ t('vehicle') }}</h2>
    <div class="mt-4 grid grid-cols-2 gap-4">
      <figure class="">
        <img :src="imageUrl" :height="300" :width="600" />
      </figure>
      <div>
        <p class="text-base font-medium">
          {{ car?.vehicleType?.make.name }}
          {{ car?.vehicleType?.model }}
        </p>

        <p class="font-normal">{{ car?.licencePlate }}</p>
        <p class="font-normal">FIN: {{ car?.fin }}</p>
        <p class="font-normal">{{ t('from') }}: {{ getLocalizedField(currentStation.info) }}</p>

        <div>
          <CVButton :disabled="disabled" class="mt-2" @click.prevent="showCarModal = true">
            {{ t('changeVehicle') }}
          </CVButton>
        </div>
      </div>

      <div v-if="hasChanged" class="col-span-3 flex">
        <CVButton
          :disabled="props.isSaving || recalculatingPrice"
          :is-loading="recalculatingPrice"
          class="mr-2"
          @click.prevent="$emit('recalculate-price', {})"
        >
          {{ t('recalculatePrice') }}
        </CVButton>
        <CVButton
          :disabled="props.isSaving || recalculatingPrice"
          variant="success"
          :is-loading="props.isSaving"
          class="mr-2"
          @click.prevent="saveCarChange"
        >
          {{ t('save') }}
        </CVButton>
        <CVButton
          :disabled="props.isSaving || recalculatingPrice"
          class="btn btn-warning mr-2"
          @click.prevent="cancelCarChange"
        >
          {{ t('cancel') }}
        </CVButton>
      </div>
    </div>

    <SelectCarModal
      v-if="booking"
      v-model="showCarModal"
      :selected-car="car"
      :booking="booking"
      :pickup-station-id="booking.pickupStation?.id"
      :pickup-location-type="booking.pickupLocationType"
      @on-select="handleNewCarSelection"
    />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import type { Car } from '@/entities/cars/car.entity';
import { computed, ref, watch } from 'vue';
import SelectCarModal from '@/views/handover/components/overview/SelectCarModal.vue';
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import type { Pricing } from '@/entities/pricing.entity';
import type { RecalculatePriceParams } from '@/hooks/use-recalculate-price';
import { useStationOfCarByDate } from '@/hooks/use-current-station-of-car';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

const props = defineProps<{
  car: Car;
  booking: Booking;
  priceCalculation: Pricing;
  recalculatingPrice: boolean;
  isSaving: boolean;
  disabled?: boolean;
}>();
const emit = defineEmits<{
  (e: 'update:car', value: Car): void;
  (e: 'update:priceCalculation', value: Pricing): void;
  (e: 'hasChanged', value: boolean): void;
  (e: 'recalculate-price', value: RecalculatePriceParams): void;
  (
    e: 'update-booking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished: () => void,
  ): void;
}>();

const car = ref(props.car);
const hasChanged = ref(false);
const showCarModal = ref(false);

const currentStation = useStationOfCarByDate(
  computed(() => props.car),
  computed(() => props.booking.startDate),
);

const { getLocalizedField } = useLocalizedFields();

watch(
  () => props.car,
  () => (car.value = props.car),
);

const imageUrl = computed(() => car.value.imageUrl ?? car.value.vehicleType.imageUrl);

const { t } = useI18n();

const handleNewCarSelection = (newCar: Car) => {
  showCarModal.value = false;
  car.value = newCar;
  hasChanged.value = car.value.id !== props.booking.car.id;
};

const cancelCarChange = () => {
  car.value = props.booking.car;
  hasChanged.value = false;
  emit('update:priceCalculation', props.booking.priceCalculation);
};

const saveCarChange = async () => {
  const updateBookingValues: UpdateBookingDto = {
    carId: car.value.id,
    priceCalculationId: props.priceCalculation.id,
  };
  emit(
    'update-booking',
    {
      id: props.booking.id,
      booking: updateBookingValues,
    },
    () => {
      hasChanged.value = false;
    },
  );
};

watch(car, (newValue) => emit('update:car', newValue));
watch(hasChanged, (newValue) => emit('hasChanged', newValue));
</script>

<i18n lang="json">
{
  "en": {
    "changeVehicle": "Change Vehicle",
    "vehicle": "Vehicle",
    "recalculatePrice": "Recalculate Price",
    "save": "Save",
    "cancel": "Cancel",
    "from": "From"
  },
  "de": {
    "changeVehicle": "Fahrzeug ändern",
    "vehicle": "Fahrzeug",
    "recalculatePrice": "Preis neu berechnen",
    "save": "Speichern",
    "cancel": "Abbrechen",
    "from": "Von"
  }
}
</i18n>
