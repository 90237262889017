import * as yup from 'yup';
import { Gender } from '@/entities/auth/gender.enum';
import { Local } from '@/entities/auth/local.enum';

export const createInsuranceCaseSchema = yup.object({
  customerData: yup
    .object({
      company: yup.string().notRequired(),
      gender: yup.string().oneOf(Object.values(Gender)).required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      phone: yup.string().notRequired(),
      street: yup.string().required(),
      zip: yup.string().required(),
      city: yup.string().required(),
      country: yup.string().required(),
      dateOfBirth: yup.string().notRequired(),
      preferredLocal: yup.string().oneOf(Object.values(Local)).required(),
    })
    .notRequired(),
  location: yup.string().notRequired().nullable(),
  email: yup.string().email().notRequired(),
  startDate: yup.string().required(),
  endDate: yup.string().required(),
  insuranceProvider: yup.string().required(),
  insuranceData: yup
    .object()
    .when('insuranceProvider', ([insuranceProvider], schema) => {
      return insuranceProvider === 'axa'
        ? schema.shape({
            referenceNumber: yup.string().required(),
            licencePlate: yup.string().notRequired(),
            initialDays: yup.number().required(),
            porscheCenter: yup.object({}).notRequired().nullable(),
            axaCategory: yup.string().notRequired(),
            maxDayPrice: yup.number().notRequired(),
          })
        : schema.shape({});
    })
    .required(),
});

export const insuranceCaseHandoverSchema = yup.object({
  insuranceProvider: yup.string().required(),
  insuranceData: yup
    .object()
    .when('insuranceProvider', ([insuranceProvider], schema) => {
      return insuranceProvider === 'axa'
        ? schema.shape({
            referenceNumber: yup.string().required(),
            licencePlate: yup.string().required(),
            porscheCenter: yup.object({}).required(),
          })
        : schema.shape({});
    })
    .required(),
});
