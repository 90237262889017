<template>
  <HeaderBar />

  <ButtonBackContainer v-if="route.name !== 'partnerRentHandoverDone'">
    <ButtonBack>{{ t('back') }}</ButtonBack>
  </ButtonBackContainer>

  <section class="carvia-container flex flex-col justify-center">
    <div class="flex justify-center space-y-3">
      <router-view />
    </div>
  </section>
</template>

<script lang="ts" setup>
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const { t } = useI18n();
const route = useRoute();
</script>

<i18n lang="json">
{
  "en": {
    "back": "Return to upcoming handovers"
  },
  "de": {
    "back": "Zurück zur Übergabeliste"
  }
}
</i18n>
