<template>
  <h2>
    {{ 'Vehicle Type | ' + vehicleType!.make.name + ' ' + vehicleType!.model }}
  </h2>
  <div class="mt-4 grid">
    <div>
      <div class="col-span-1 col-start-1 flex grid-flow-col-dense flex-col space-y-3">
        <labeled-text :label="t('brand')">
          {{ vehicleType?.make.name }}
        </labeled-text>

        <labeled-text :label="t('model')">
          {{ vehicleType?.model }}
        </labeled-text>

        <labeled-text :label="t('category')">
          {{ t(`categories.${vehicleType?.mainCategory}`) ?? 'n.a.' }}
        </labeled-text>
      </div>
      <CVButton
        v-if="!hideEditButton"
        type="submit"
        class="mt-8"
        @click="
          $router.push({
            name: 'editVehicleType',
            params: {
              id: vehicleType?.id,
            },
          })
        "
      >
        {{ t(`editVehicleType`) }}
      </CVButton>
    </div>
    <figure class="col-span-2 col-start-2 w-[350px]">
      <img :src="vehicleType?.imageUrl" />
    </figure>
  </div>
</template>

<script setup lang="ts">
import LabeledText from '@/components/LabeledText.vue';
import type { VehicleType } from '@/entities/vehicle-type.entity';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  vehicleType: VehicleType | undefined;
  hideEditButton?: boolean;
}>();

const { t } = useI18n();
const vehicleType = ref(props.vehicleType);
</script>

<i18n lang="json">
{
  "en": {
    "licensePlate": "License Plate",
    "red": "red",
    "black": "black",
    "white": "white",
    "editVehicleType": "look at / edit vehicle type"
  },
  "de": {
    "licensePlate": "Kennzeichen",
    "red": "rot",
    "black": "schwarz",
    "white": "weiß",
    "editVehicleType": "Vehicle Type ansehen / bearbeiten"
  }
}
</i18n>
