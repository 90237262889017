import router from '@/router';
import { defineStore } from 'pinia';
import type { LocationQueryRaw } from 'vue-router';

interface RouteQueryState {
  query: LocationQueryRaw;
}

export const useRouteQueryStore = defineStore({
  id: 'routeQueryStore',
  state: (): RouteQueryState => ({
    query: {},
  }),
  getters: {},
  actions: {
    push(query: LocationQueryRaw) {
      Object.entries(query).forEach(([key, value]) => {
        if (value === undefined) {
          delete this.query[key];
        } else {
          this.query[key] = value;
        }
      });
      router.replace({ query: this.query });
    },
    replace(query: LocationQueryRaw) {
      this.query = { ...query };
      router.replace({ query: this.query });
    },
  },
});
