import posthog from 'posthog-js';
import { ref, type Ref } from 'vue';

export const useFeatureFlag = (flag: string): Ref<boolean | undefined> => {
  const value = ref(posthog.isFeatureEnabled(flag));
  posthog.onFeatureFlags((flags) => {
    value.value = flags.includes(flag);
  });
  return value;
};
