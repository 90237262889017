import { globalSettingsService } from '@/api/global-settings.service';
import type { UpdateGlobalSettingsDto } from '@/entities/global-settings.entity';
import { useMutation, useQuery } from '@tanstack/vue-query';
import type { MutationDefaultsContext } from './query-client';

const QUERY_KEY = 'global-settings';

export const useGlobalSettings = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => globalSettingsService.getGlobalSettings(),
  });
};

export const useUpdateGlobalSettings = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (globalSettings: UpdateGlobalSettingsDto) =>
      globalSettingsService.updateGlobalSettings(globalSettings),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'Global settings updated',
    }),
  });
};
