import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';
import qs from 'qs';

import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { authService } from './auth.service';
import { TOKEN_STORAGE_KEY } from './constants';
import { API_URL } from '@/constants/api-url.constant';

const isLogEnabled = false;

export const httpClient = axios.create({
  baseURL: API_URL,
  timeout: 30000,

  headers: {
    // 'x-api-key':  import.meta.env.API_KEY,
    'Content-Type': 'application/json',
  },
  maxContentLength: 30 * 1000 * 1000,
});

httpClient.defaults.paramsSerializer = (params) =>
  qs.stringify(params, {
    strictNullHandling: true,
    encodeValuesOnly: true,
  });

const refreshAuthLogic = async (failedRequest: AxiosError) => {
  try {
    const tokenRefreshResponse = await authService.refreshAccessToken();

    if (failedRequest.response?.config.headers) {
      failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.access_token}`;
    }

    return Promise.resolve();
  } catch (err) {
    console.error(err);
  }
};

createAuthRefreshInterceptor(httpClient, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: true,
});

function getAccessToken() {
  return localStorage.getItem(TOKEN_STORAGE_KEY);
}

// Add a request interceptor
httpClient.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();

    if (token && config.headers && config.headers.Authorization === undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (config.headers && config.headers.Authorization === null) {
      config.headers.Authorization = undefined;
    }

    if (isLogEnabled) {
      console.log('Network Request:', `${config.baseURL}${config.url}`, config.method);
    }
    return config;
  },
  async (error) => {
    if (isLogEnabled) console.error('Network Request:', error);
    throw error;
  },
);

// Add a response interceptor
httpClient.interceptors.response.use(
  (response: AxiosResponse) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async (error: AxiosError) => {
    if (isLogEnabled) console.error('Network Response:', error);

    throw error;
  },
);
