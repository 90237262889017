<template>
  <div>
    <BookingsList :bookings="bookings" />

    <EntityListExceptions
      :status="bookingsStatus"
      :error="bookingsError"
      :items-count="bookings.length"
      :no-items-text="t('noBookingsFound')"
    />

    <div v-if="hasNextPage" class="flex justify-center pt-6">
      <CVButton outline :is-loading="isFetchingNextPage" @click="() => fetchNextPage()">
        {{ t('loadMore') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import EntityListExceptions from '@/components/EntityListExceptions.vue';
import { useI18n } from 'vue-i18n';
import { useBookingsWithQuery } from '@/queries/use-bookings';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import { FindAllBookingsSort } from '@/entities/bookings/find-all-bookings-sort.enum';
import { Order } from '@/entities/pagination/order.enum';
import BookingsList from '@/views/bookings/bookings-list/BookingsList.vue';

const { t } = useI18n();

const {
  data: bookingsData,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  status: bookingsStatus,
  error: bookingsError,
} = useBookingsWithQuery({
  filter: [
    {
      status: [BookingStatus.CAR_RETURNED],
      hasUnhandledDamages: true,
    },
  ],
  sort: FindAllBookingsSort.END_DATE,
  order: Order.ASC,
});

const bookings = useFlattenPaginatedData(bookingsData);
</script>

<i18n lang="json">
{
  "en": {
    "noBookingsFound": "No Bookings found"
  },
  "de": {
    "noBookingsFound": "Keine Buchungen gefunden"
  }
}
</i18n>
