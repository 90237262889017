<template>
  <div>
    <p class="mb-4 text-2xl font-medium">
      {{ t('customerData') }}
    </p>

    <LabeledText v-if="customerEmail" :label="t('email')" class="mb-4">{{
      customerEmail
    }}</LabeledText>

    <CustomerDataForm hide-email hide-header />

    <Divider />

    <div v-if="agency">
      <Agency :agency="agency" />
      <Divider />
    </div>

    <p class="mb-4 text-xl font-medium">
      {{ t('bookingSummary') }}
    </p>

    <div class="grid grid-cols-2 items-start gap-4">
      <VehicleInfo :car="car" :start-date="startDate" />

      <LocationsAndTimesInfo :create-booking-props="props.createBookingProps" />
    </div>

    <Divider />

    <div v-if="hasTransfers">
      <Transfers :car="car" :create-booking-props="props.createBookingProps" />

      <Divider />
    </div>

    <div v-if="showExtras">
      <Extras
        :booked-extras-map="bookedExtrasMap"
        :car="car"
        :price-calculation="pricing!"
        immutable
        @number-of-chosen-extras="showExtras = $event > 0"
      />

      <Divider />
    </div>

    <div class="flex items-end justify-between">
      <CVButton outline :disabled="isLoading" @click="back">{{ t('back') }}</CVButton>
      <div class="flex items-end gap-5">
        <TotalPrice :total-price="pricing!.totalPrice" />
        <CVButton class="mt-4" :is-loading="isLoading" @click="submitForm">{{
          t('createBooking')
        }}</CVButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Divider from '@/components/Divider.vue';
import CustomerDataForm from '@/components/booking-forms/customer/CustomerDataForm.vue';
import { Gender } from '@/entities/auth/gender.enum';
import type { CustomerDataDto } from '@/entities/bookings/customer-data.dto';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { object, string } from 'yup';
import TotalPrice from '../../TotalPrice.vue';
import { cloneDeep } from 'lodash';
import { Local } from '@/entities/auth/local.enum';
import LabeledText from '@/components/LabeledText.vue';
import type { User } from '@/entities/auth/user.entity';
import Agency from './Agency.vue';
import LocationsAndTimesInfo from '../../LocationsAndTimesInfo.vue';
import { computed, ref } from 'vue';
import type { Car } from '@/entities/cars/car.entity';
import Transfers from '../overview-and-extras/Transfers.vue';
import Extras from '../overview-and-extras/Extras.vue';
import VehicleInfo from './VehicleInfo.vue';
import type { CreateBookingProps } from '@/views/bookings/booking-create/components/use-create-booking-props';

const props = defineProps<{
  customerEmail: string | null;
  customerData: CustomerDataDto;
  agency: User | null;
  createBookingProps: CreateBookingProps;
  car: Car;
  bookedExtrasMap: Record<string, string>;
  isLoading: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:customerData', value: CustomerDataDto): void;
  (e: 'continue'): void;
  (e: 'back'): void;
}>();

const { startDate, pricing, transferDeliveryPrice, transferReturnPrice } = props.createBookingProps;

const { t } = useI18n();

const showExtras = ref(true);

const hasTransfers = computed(() => transferDeliveryPrice.value || transferReturnPrice.value);

const { handleSubmit, values: formValues } = useForm({
  initialValues: props.customerData,
  validationSchema: props.customerEmail
    ? object({
        firstName: string().required(),
        lastName: string().required(),
        gender: string().oneOf(Object.values(Gender)).required(),
        preferredLocal: string().oneOf(Object.values(Local)).required(),
      })
    : undefined,
});

const submitForm = handleSubmit((values) => {
  emit('update:customerData', values);
  emit('continue');
});

const back = () => {
  emit('update:customerData', cloneDeep(formValues));
  emit('back');
};
</script>

<i18n lang="json">
{
  "en": {
    "customerData": "Customer Data",
    "createBooking": "Create Booking",
    "email": "Email",
    "bookingSummary": "Booking Summary"
  },
  "de": {
    "customerData": "Kundendaten",
    "createBooking": "Buchung erstellen",
    "email": "Email",
    "bookingSummary": "Buchungsübersicht"
  }
}
</i18n>
