import type { Invoice } from '@/entities/invoices/invoice.entity';
import { Alert } from '@/utils/alert';
import { useSendInvoiceToCustomer } from '@/queries/use-invoices';
import { useI18n } from 'vue-i18n';

export const useInvoicePdfs = () => {
  const { t } = useI18n({
    useScope: 'global',
    legacy: false,
    fallbackLocale: 'en',
    globalInjection: true,
    fallbackWarn: false,
    missingWarn: false,
    messages: localization,
  });

  const { mutateAsync: sendInvoiceToCustomer, isPending: isSending } = useSendInvoiceToCustomer();

  const downloadInvoice = (invoice: Invoice) => {
    if (!invoice.fileUrl) return;
    window.location.href = invoice.fileUrl;
  };

  const sendInvoiceToCustomerWithAlert = async (invoiceId: string) => {
    const alertResult = await Alert.fire({
      titleText: t('reallySendInvoiceTitle'),
      text: t('reallySendInvoiceText'),
      icon: 'question',
      showDenyButton: true,
      confirmButtonText: t('sendInvoice'),
      denyButtonText: t('cancel'),
    });
    if (alertResult.isConfirmed) {
      sendInvoiceToCustomer(invoiceId);
    }
  };

  return {
    downloadInvoice,
    sendInvoiceToCustomer,
    sendInvoiceToCustomerWithAlert,
    isSending,
  };
};

const localization = {
  en: {
    reallySendInvoiceTitle: 'Really send Invoice?',
    reallySendInvoiceText: 'Are you sure, you want to send this Invoice to the Customer?',
    sendInvoice: 'Send Invoice',
  },
  de: {
    reallySendInvoiceTitle: 'Rechnung wirklich versenden?',
    reallySendInvoiceText:
      'Bist du sicher, dass du diese Rechnung an den Kunden versenden möchtest?',
    sendInvoice: 'Rechnung verschicken',
  },
};
