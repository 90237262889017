<template>
  <div v-if="data" class="flex flex-col gap-3">
    <div v-for="(day, i) in bookingsPerDay" :key="i" class="flex flex-col gap-2">
      <div v-if="showDays" class="text-sm text-primary">
        <span class="font-medium">{{ formatDate(day.day, 'cccc') }}</span>
        —
        {{ formatDate(day.day, getDateFormat()) }}
      </div>
      <div class="flex flex-col gap-2">
        <div v-for="booking in day.bookings" :key="booking.id">
          <BookingListItem :booking="booking" />
        </div>
      </div>
    </div>
  </div>

  <EntityListExceptions
    :status="status"
    :error="error"
    :items-count="bookings.length"
    :no-items-text="t('noBookingFound')"
  />

  <div v-if="hasNextPage" class="flex justify-center pt-8">
    <CVButton outline :is-loading="isFetchingNextPage" @click="() => fetchNextPage()">
      {{ t('loadMore') }}
    </CVButton>
  </div>
</template>

<script lang="ts" setup>
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import type {
  FindAllBookingsFilterDto,
  FindAllBookingsQueryDto,
} from '@/entities/bookings/booking.entity';
import { FindAllBookingsSort } from '@/entities/bookings/find-all-bookings-sort.enum';
import { useI18n } from 'vue-i18n';
import { useBookingsWithQuery } from '@/queries/use-bookings';
import { useCurrentStations } from '@/hooks/use-current-stations';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import BookingListItem from './BookingListItem.vue';
import { BookingType } from '@/entities/bookings/booking-type.enum';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { formatDate } from '@/hooks/use-formatted-date';
import { getDateFormat } from '@/hooks/use-date-format';
import type { ManyBookingsBooking } from '@/entities/bookings/many-bookings-booking.entity';
import EntityListExceptions from '@/components/EntityListExceptions.vue';

const props = defineProps<{
  filterBookingStatus: BookingStatus[];
  filterDate: Date;
}>();

const { t } = useI18n();

const currentStations = useCurrentStations();

const basicStartDateQueryParams = computed(() => ({
  // startDateAfter: DateTime.fromJSDate(props.filterDate).startOf('day').toISO(),
  startDateBefore:
    DateTime.fromJSDate(props.filterDate).startOf('day').plus({ day: 1 }).toISO() ?? undefined,
}));

const getBookingsQuery = computed<FindAllBookingsQueryDto>(() => ({
  filter: props.filterBookingStatus.reduce((acc, status) => {
    if (status === BookingStatus.CONFIRMED) {
      // Handovers
      acc.push({
        types: [BookingType.CUSTOMER_BOOKING, BookingType.PARTNER_BOOKING],
        ...basicStartDateQueryParams.value,
        status: [status],
        pickupStationIds: currentStations.value,
      });
      acc.push({
        types: [BookingType.CUSTOMER_BOOKING, BookingType.PARTNER_BOOKING],
        ...basicStartDateQueryParams.value,
        status: [status],
        pickupStationIds: null,
        carStationIds: currentStations.value,
      });
      // Delivery Transfers
      acc.push({
        types: [BookingType.TRANSFER_DELIVERY_BOOKING],
        ...basicStartDateQueryParams.value,
        status: [status],
        pickupStationIds: currentStations.value,
      });
      acc.push({
        types: [BookingType.TRANSFER_DELIVERY_BOOKING],
        ...basicStartDateQueryParams.value,
        status: [status],
        pickupStationIds: null,
        carStationIds: currentStations.value,
      });
    } else {
      // Returns
      acc.push({
        types: [BookingType.CUSTOMER_BOOKING, BookingType.PARTNER_BOOKING],
        status: [status],
        endDateBefore:
          DateTime.fromJSDate(props.filterDate).startOf('day').plus({ day: 1 }).toISO() ??
          undefined,
        dropoffStationIds: currentStations.value,
      });
      acc.push({
        types: [BookingType.CUSTOMER_BOOKING, BookingType.PARTNER_BOOKING],
        status: [status],
        endDateBefore:
          DateTime.fromJSDate(props.filterDate).startOf('day').plus({ day: 1 }).toISO() ??
          undefined,
        dropoffStationIds: null,
        carStationIds: currentStations.value,
      });
      // Return Transfers
      acc.push({
        types: [BookingType.TRANSFER_RETURN_BOOKING],
        ...basicStartDateQueryParams.value,
        status: [BookingStatus.CONFIRMED, BookingStatus.HANDED_OVER],
        dropoffStationIds: currentStations.value,
      });
      acc.push({
        types: [BookingType.TRANSFER_RETURN_BOOKING],
        ...basicStartDateQueryParams.value,
        status: [BookingStatus.CONFIRMED, BookingStatus.HANDED_OVER],
        dropoffStationIds: null,
        carStationIds: currentStations.value,
      });
    }
    return acc;
  }, [] as FindAllBookingsFilterDto[]),
  sort: FindAllBookingsSort.RELEVANT_DATE,
}));

const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status, error } =
  useBookingsWithQuery(
    getBookingsQuery,
    computed(() => !!currentStations.value),
  );

const bookings = useFlattenPaginatedData(data);

const bookingsPerDay = computed(() =>
  bookings.value
    .reduce(
      (days, booking) => {
        const bookingDate =
          booking.type === BookingType.CUSTOMER_BOOKING &&
          booking.status === BookingStatus.HANDED_OVER
            ? booking.endDate
            : booking.startDate;
        const dayDate = DateTime.fromISO(bookingDate).startOf('day').toISO();
        const day = days.find((day) => day.day === dayDate);
        if (!day) {
          days.push({
            day: dayDate!,
            bookings: [booking],
          });
        } else {
          day.bookings.push(booking);
        }
        return days;
      },
      [] as { day: string; bookings: ManyBookingsBooking[] }[],
    )
    .sort((a, b) => (DateTime.fromISO(a.day) > DateTime.fromISO(b.day) ? 1 : -1)),
);

const showDays = computed(
  () =>
    bookingsPerDay.value.length > 1 ||
    (bookingsPerDay.value.length === 1 &&
      bookingsPerDay.value[0].day !== DateTime.fromJSDate(props.filterDate).startOf('day').toISO()),
);
</script>

<i18n lang="json">
{
  "en": {
    "noBookingFound": "No Bookings found"
  },

  "de": {
    "noBookingFound": "Es wurden keine Buchungen gefunden"
  }
}
</i18n>
