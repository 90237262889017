import type {
  CreateVehicleTypeDto,
  FindAllVehicleTypesQuery,
  GetOneVehicleTypeDto,
  GetOneVehicleTypeWithQuery,
  UpdateVehicleTypeDto,
  VehicleType,
} from '@/entities/vehicle-type.entity';
import { Service } from './http-service';
import type { ManyVehicleTypesVehicleType } from '@/entities/many-vehicle-types-vehicle-type.entity';

class VehicleTypesService extends Service<VehicleType, CreateVehicleTypeDto, UpdateVehicleTypeDto> {
  constructor() {
    super('/vehicle-types');
  }

  async getOneWithQuery(id: string, query: GetOneVehicleTypeWithQuery) {
    const res = await this.httpClient.get<GetOneVehicleTypeDto>(`${this.endpoint}/${id}`, {
      params: query,
    });
    return res.data;
  }

  async getAllWithQuery(query: FindAllVehicleTypesQuery) {
    const res = await this.httpClient.get<ManyVehicleTypesVehicleType[]>(`${this.endpoint}`, {
      params: query,
    });
    return res.data;
  }
}

export const vehicleTypesService = new VehicleTypesService();
