import { Service } from './http-service';

class EmailConfirmationService extends Service {
  constructor() {
    super('/email-confirmation');
  }

  async confirmEmail(token: string) {
    const res = await this.httpClient.post(this.endpoint + '/confirm', {
      token,
    });
    return res.data;
  }

  async resendConfirmationLink() {
    const res = await this.httpClient.post(this.endpoint + '/resend-confirmation-link');
    return res.data;
  }
}

export const emailConfirmationService = new EmailConfirmationService();
