<template>
  <div
    class="group relative flex h-10 w-10 items-center justify-center"
    :class="{ 'text-gray-400': noButtons }"
  >
    <button
      class="flex h-7 w-7 items-center justify-between rounded-full p-1 text-2xl font-bold transition ease-in-out"
      :class="{ 'group-hover:bg-black/20': !noButtons }"
      @click.prevent=""
      @mouseover="deactivateListHover = false"
    >
      <Icon v-if="!isLoading" name="ellipsis-horizontal" />
      <Spinner v-else />
    </button>

    <ul :class="{ 'group-hover:visible': !noButtons && !deactivateListHover }">
      <li v-for="(button, i) in buttons" :key="i">
        <button @click.prevent.stop="onClick(button)">
          {{ button.title }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

export interface DropdownItem {
  title: string;
  onClick?: () => void;
}

const props = defineProps<{
  buttons: DropdownItem[];
  isLoading?: boolean;
}>();

const deactivateListHover = ref(false);
const noButtons = computed(() => props.buttons.length === 0);

const onClick = (button: DropdownItem) => {
  deactivateListHover.value = true;
  button.onClick?.();
};
</script>

<style scoped>
ul {
  @apply invisible absolute right-2 top-9 z-50 w-52
      min-w-max space-y-1 rounded-box bg-base-100 p-2
      opacity-0 shadow transition-all ease-in
      group-hover:flex group-hover:flex-col group-hover:opacity-100;
}
li > button {
  @apply w-full p-2 text-left transition-colors duration-75 hover:bg-slate-200;
}
</style>
