export const formatCurrency = (value: number) => {
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  return formatter.format(value);
};

export const formatFloat = (value: number | undefined | null, decimals = 2) => {
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'decimal',
    maximumFractionDigits: decimals,
  });

  return value ? formatter.format(value) : '0';
};

export const addLeadingZeros = (value: number, minimumDigits: number) => {
  const formatter = new Intl.NumberFormat('de-DE', {
    minimumIntegerDigits: minimumDigits,
  });

  return formatter.format(value);
};
