<template>
  <div v-if="!isLoading" class="space-y-4">
    <DashboardNews v-for="article in news" :key="article.id" :news="article" />
  </div>
  <div v-else class="flex h-[200px] items-center justify-center">
    <Spinner />
  </div>
</template>

<script lang="ts" setup>
import Spinner from '@/components/icons/Spinner.vue';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { useAllNewsWithQuery } from '@/queries/use-news';
import DashboardNews from './DashboardNews.vue';
import { NewsStatus } from '@/entities/news/news-status.enum';
import { Order } from '@/entities/pagination/order.enum';
import { FindAllNewsSort } from '@/entities/news/find-all-news-sort.enum';

const { data: newsData, isLoading } = useAllNewsWithQuery({
  status: NewsStatus.PUBLISHED,
  sort: FindAllNewsSort.PUBLISHED_DATE,
  order: Order.DESC,
  limit: 2,
});

const news = useFlattenPaginatedData(newsData);
</script>
