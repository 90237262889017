<template>
  <HeaderBar />

  <section class="carvia-container">
    <div class="flex justify-between">
      <Input v-model="search" :placeholder="t('search')" />
      <div class="flex gap-4">
        <CVButton
          v-if="$can('payouts', 'AgentProvision')"
          @click.prevent="downloadAgentProvisions"
          >{{ t('downloadAgentProvisions') }}</CVButton
        >
        <CVButton
          v-if="me?.role === 'ADMIN'"
          @click.prevent="router.push({ name: 'user', params: { id: 'new' } })"
          >{{ t('createNewUser') }}</CVButton
        >
        <div v-if="!me"><Spinner /></div>
      </div>
    </div>

    <Tabs v-model="activeTab" :tabs="tabs" class="mb-4 mt-6" />

    <div>
      <div v-if="users" class="flex flex-col space-y-2">
        <div v-for="user in users" :key="user.id">
          <UserListItem :user="user" />
        </div>
      </div>

      <EntityListExceptions
        :status="usersStatus"
        :error="usersError"
        :items-count="users.length"
        :no-items-text="t('noUsersFound')"
      />

      <div v-if="hasNextPage" class="flex justify-center pt-8">
        <CVButton outline :is-loading="isFetchingNextPage" @click="() => fetchNextPage()">
          {{ t('loadMore') }}
        </CVButton>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import { computed, ref, watch, watchEffect } from 'vue';
import { debounce } from 'lodash';
import { useMe, useUsersWithQuery } from '@/queries/use-users';
import Tabs from '@/components/Tabs.vue';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import type { FindAllUsersQueryDto } from '@/entities/auth/user.entity';
import { UserRole } from '@/entities/auth/user-role.enum';
import { FindAllUsersSort } from '@/entities/auth/find-all-users-sort.enum';
import { Order } from '@/entities/pagination/order.enum';
import Input from '@/components/Input.vue';
import UserListItem from './UserListItem.vue';
import { useUiStore } from '@/stores/ui.store';
import EntityListExceptions from '@/components/EntityListExceptions.vue';
import { agentProvisionService } from '@/api/agent-provision.service';

enum Tab {
  ALL = 'ALL',
  STAFF = 'STAFF',
  AGENTS = 'AGENTS',
  CUSTOMERS = 'CUSTOMERS',
  FRANCHISE_BROS = 'FRANCHISE_BROS',
}

const { t } = useI18n();
const uiStore = useUiStore();

const activeTab = ref(Tab.STAFF);
const search = ref('');
const searchQuery = ref('');

const query = computed<FindAllUsersQueryDto>(() => {
  let activeQuery: FindAllUsersQueryDto = {};
  switch (activeTab.value) {
    case Tab.STAFF:
      activeQuery = {
        role: [UserRole.ADMIN, UserRole.HUB_MANAGER, UserRole.HUB_AGENT, UserRole.OPERATOR],
      };
      break;
    case Tab.AGENTS:
      activeQuery = {
        role: [UserRole.AGENT],
      };
      break;
    case Tab.CUSTOMERS:
      activeQuery = {
        role: [UserRole.CUSTOMER],
      };
      break;
    case Tab.FRANCHISE_BROS:
      activeQuery = {
        role: [UserRole.FRANCHISE_AGENT],
      };
      break;
  }
  return {
    ...activeQuery,
    query: searchQuery.value,
    limit: 10,
    sort: FindAllUsersSort.FIRST_NAME,
    order: Order.ASC,
  };
});

const { data: me } = useMe();
const {
  data: usersData,
  status: usersStatus,
  error: usersError,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
} = useUsersWithQuery(query);
const users = useFlattenPaginatedData(usersData);

const refreshSearchQuery = debounce(() => {
  searchQuery.value = search.value;
}, 500);
watch(search, () => {
  refreshSearchQuery();
});

const tabs = computed(() => [
  {
    title: t('staff'),
    id: Tab.STAFF,
  },
  {
    title: t('franchiseBros'),
    id: Tab.FRANCHISE_BROS,
  },
  {
    title: t('agents'),
    id: Tab.AGENTS,
  },
  {
    title: t('customers'),
    id: Tab.CUSTOMERS,
  },
  {
    title: t('all'),
    id: Tab.ALL,
  },
]);

const downloadAgentProvisions = async () => {
  const res = await agentProvisionService.getPayouts();
  const blob = new Blob([res], { type: 'text/csv;charset=utf-8' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'agent-provisions.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

watchEffect(() => {
  uiStore.setHeaderTitle(t('users'));
});
</script>

<i18n lang="json">
{
  "en": {
    "search": "Search",
    "createNewUser": "+ Create new user",
    "staff": "Staff",
    "franchiseBros": "Franchise Bros",
    "agents": "Partners/Agents",
    "customers": "Customers",
    "all": "All",
    "users": "Users",
    "noUsersFound": "No users found",
    "downloadAgentProvisions": "Download Agent Provisions"
  },
  "de": {
    "search": "Suche",
    "createNewUser": "+ Neuen User erstellen",
    "staff": "Mitarbeiter",
    "franchiseBros": "Franchise Bros",
    "agents": "Partner/Vermittler",
    "customers": "Kunden",
    "all": "Alle",
    "users": "Benutzer",
    "noUsersFound": "Keine Benutzer gefunden",
    "downloadAgentProvisions": "Agent Provisionen herunterladen"
  }
}
</i18n>
