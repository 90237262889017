<template>
  <form @submit.prevent="onSubmit">
    <h2>{{ t('station') }}</h2>

    <div class="mt-4 grid grid-cols-3 gap-4">
      <InputField name="info.de" :label="t('nameDe')" class="col-start-1" />
      <InputField name="info.en" :label="t('nameEn')" />
      <InputField name="notice.de" :label="t('noticeDe')" class="col-start-1" />
      <InputField name="notice.en" :label="t('noticeEn')" />
      <SearchLocation v-model:location="location" hide-address class="col-start-1" />
      <InputField name="street" :label="t('street')" class="col-start-1" />
      <div class="flex gap-2">
        <InputField class="basis-2/5" :name="`zip`" :label="t('zip')" />
        <InputField :name="`city`" :label="t('city')" />
      </div>
      <SelectField name="state" :label="t('state')" :options="stateOptions" />
      <InputField name="slug" :label="t('slug')" />
      <InputField name="invoiceSuffix" :label="t('invoiceSuffix')" />
    </div>

    <Divider />

    <h2>{{ t('openingHours') }}</h2>

    <div class="mt-4 grid grid-cols-7 gap-4">
      <div v-for="index in 7" :key="index" class="flex flex-col gap-4">
        <LabeledText :label="t(`weekdays.${weekdays[index - 1]}`)" />
        <Select v-model="times[index - 1].opened" :options="openedOptions" />
        <div
          v-if="times[index - 1].opened === 'true'"
          class="flex flex-col items-center justify-center"
        >
          <Input v-model="times[index - 1].openingTime" type="time" class="w-full" />
          <div>-</div>
          <Input v-model="times[index - 1].closingTime" type="time" class="w-full" />
        </div>
      </div>
    </div>

    <Divider />

    <CheckboxField name="active" :label="t('stationIsActive')" class="col-span-3" />

    <Divider />

    <div class="mt-3 flex justify-end">
      <CVButton :is-loading="isLoading" @click="onSubmit">{{ t('save') }}</CVButton>
    </div>
  </form>
</template>

<script lang="ts" setup>
import Divider from '@/components/Divider.vue';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { cloneDeep, kebabCase } from 'lodash';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form';
import InputField from '@/components/InputField.vue';
import SelectField from '@/components/SelectField.vue';
import type { Station, UpdateStationDto } from '@/entities/station/station.entity';
import { stationSchema } from '@/validation/station.schema';
import { formHasChanged } from '@/hooks/use-form-has-changed';
import { State } from '@/entities/station/state.enum';
import { type ExtendedGeocodedLocation } from '@/components/GooglePlacesInput.vue';
import SearchLocation from '@/components/booking-forms/SearchLocation.vue';
import { watch } from 'vue';
import Input from '@/components/Input.vue';
import Select from '@/components/Select.vue';
import LabeledText from '@/components/LabeledText.vue';
import { openingHoursTimeToString } from '@/utils/opening-hours-time-to-string';
import CheckboxField from '@/components/CheckboxField.vue';

const props = defineProps<{
  station?: Station;
  isLoading: boolean;
  isFetching: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSubmit', value: UpdateStationDto): void;
  (e: 'onDelete'): void;
  (e: 'refetch'): void;
}>();

const { t } = useI18n();

const times = ref<{ openingTime?: string; closingTime?: string; opened: 'true' | 'false' }[]>(
  props.station
    ? [...props.station.openingHours]
        .sort((a, b) => a.weekday - b.weekday)
        .map((time) =>
          time.openingTime && time.closingTime
            ? {
                openingTime: openingHoursTimeToString(time.openingTime),
                closingTime: openingHoursTimeToString(time.closingTime),
                opened: 'true',
              }
            : { opened: 'false' },
        )
    : Array.from({ length: 7 }, () => ({ opened: 'true' })),
);

watch(
  times,
  () => {
    setFieldValue(
      'openingHours',
      times.value.map((time, index) => {
        const openingTime =
          time.opened === 'true' && time.openingTime ? time.openingTime?.split(':') : null;
        const closingTime =
          time.opened === 'true' && time.closingTime ? time.closingTime?.split(':') : null;
        return {
          weekday: index + 1,
          openingTime: openingTime ? { hour: +openingTime[0], minute: +openingTime[1] } : null,
          closingTime: closingTime ? { hour: +closingTime[0], minute: +closingTime[1] } : null,
        };
      }),
    );
  },
  { deep: true },
);

const location = ref<ExtendedGeocodedLocation | undefined>(
  props.station?.location
    ? {
        coordinates: {
          latitude: props.station.location.coordinates[1],
          longitude: props.station.location.coordinates[0],
        },
      }
    : undefined,
);

const initialValues: UpdateStationDto = {
  info: props.station?.info,
  slug: props.station?.slug,
  location: props.station?.location
    ? {
        longitude: props.station.location.coordinates[0],
        latitude: props.station.location.coordinates[1],
      }
    : undefined,
  street: props.station?.street,
  zip: props.station?.zip,
  city: props.station?.city,
  state: props.station?.state,
  invoiceSuffix: props.station?.invoiceSuffix,
  notice: props.station?.notice,
  openingHours: props.station?.openingHours,
  active: props.station?.active ?? false,
};

// Set address fields, when location changes
watch(location, () => {
  if (location.value?.coordinates) {
    setFieldValue('location', location.value.coordinates);
    location.value.address_components?.forEach((component) => {
      if (component.types.includes('postal_code')) {
        setFieldValue('zip', component.long_name);
      } else if (component.types.includes('locality')) {
        setFieldValue('city', component.long_name);
      } else if (component.types.includes('route')) {
        const number = location.value?.address_components?.find((comp) =>
          comp.types.includes('street_number'),
        )?.long_name;
        setFieldValue('street', component.long_name + (number ? ` ${number}` : ''));
      } else if (component.types.includes('administrative_area_level_1')) {
        setFieldValue('state', component.short_name);
      }
    });
  }
});

const stateOptions = computed(() => {
  return Object.values(State).map((state) => ({
    label: t(`states.${state}`),
    value: state,
  }));
});

const openedOptions = computed(() => [
  { label: t('opened'), value: 'true' },
  { label: t('closed'), value: 'false' },
]);

const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const {
  handleSubmit,
  values: formValues,
  setFieldValue,
} = useForm({
  initialValues,
  validationSchema: stationSchema,
});

const unchangedValues = ref(cloneDeep(formValues));

const onSubmit = handleSubmit((values) => {
  unchangedValues.value = cloneDeep(formValues);
  emit('onSubmit', values);
});

usePreventLeavingUnsavedForm(() => formHasChanged(unchangedValues.value, formValues));

watch(
  () => formValues.info?.de,
  (value) => {
    if (props.station) return;
    setFieldValue('slug', kebabCase(value));
    setFieldValue('invoiceSuffix', value?.match(/([a-zA-Z]){1,2}/)?.[0].toUpperCase());
  },
);
</script>

<i18n lang="json">
{
  "en": {
    "station": "Station",
    "stationIsActive": "Station is active (will be displayed on website, etc.)",
    "nameDe": "Name DE",
    "nameEn": "Name EN",
    "noticeDe": "Notice DE",
    "noticeEn": "Notice EN",
    "street": "Street",
    "zip": "Zip",
    "city": "City",
    "state": "State",
    "slug": "Slug",
    "invoiceSuffix": "Invoice Suffix",
    "openingHours": "Opening Hours",
    "opened": "Opened",
    "closed": "Closed"
  },
  "de": {
    "station": "Station",
    "stationIsActive": "Station ist aktiv (wird auf Webseite angezeigt, etc.)",
    "nameDe": "Name DE",
    "nameEn": "Name EN",
    "noticeDe": "Zusatzinfo DE",
    "noticeEn": "Zusatzinfo EN",
    "street": "Straße",
    "zip": "PLZ",
    "city": "Ort",
    "state": "Bundesstaat",
    "slug": "Slug",
    "invoiceSuffix": "Rechnungssuffix",
    "openingHours": "Öffnungszeiten",
    "opened": "Geöffnet",
    "closed": "Geschlossen"
  }
}
</i18n>
