import { carsService } from '@/api/cars.service';
import type {
  Car,
  CreateCarDto,
  FindAllCarsQueryDto,
  UpdateCarDto,
} from '@/entities/cars/car.entity';
import { addItemToQueriesData, updateItemOnQueriesData } from '@/utils/update-query-data';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { type MaybeRefOrGetter, toRef, toValue } from '@vueuse/core';
import type { MutationDefaultsContext } from './query-client';
import { computed } from 'vue';

const QUERY_KEY = 'cars';

export const useCar = (
  id: MaybeRefOrGetter<string | undefined>,
  enabled?: MaybeRefOrGetter<boolean>,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(id)],
    queryFn: () => carsService.getOne(toValue(id)!),
    enabled: computed(() => toValue(enabled) && !!toValue(id)),
  });
};

export const useCarsWithQuery = (
  query: MaybeRefOrGetter<FindAllCarsQueryDto>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY, toRef(query)],
    queryFn: ({ pageParam = 1 }) =>
      carsService.getAllWithQuery({ ...toValue(query), page: pageParam }),
    enabled: toRef(enabled),
    getNextPageParam: (lastPage) => lastPage.meta.nextPage,
    initialPageParam: 1,
  });
};

export const useUpdateCar = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ id, car }: { id: string; car: UpdateCarDto }) => carsService.update(id, car),
    onMutate: () => ({
      successMessage: 'Car updated',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(null, null, context);
      updateItemOnQueriesData<Car>([QUERY_KEY], result, queryClient);
    },
  });
};

export const useCreateCar = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (car: CreateCarDto) => carsService.create(car),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'Car created',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(null, null, context);
      addItemToQueriesData<Car>([QUERY_KEY], result, queryClient);
    },
  });
};
