<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <label class="form-control">
    <InputLabel :label="label" :tooltip="tooltip" :is-error="isError" :warning="warning" />
    <select
      class="select"
      :value="value"
      :disabled="disabled"
      @input="onInput(($event.target as HTMLSelectElement)?.value ?? '')"
    >
      <option v-if="placeholder" disabled selected>{{ placeholder }}</option>
      <option v-for="option in options" :key="option.value ?? 'key'" :value="option.value ?? ''">
        {{ option.label }}
      </option>
    </select>

    <InputHint :hint="hint" :is-error="isError" />
  </label>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import InputLabel from './InputLabel.vue';
import InputHint from './InputHint.vue';

export interface SelectOption {
  label: string | null;
  value: string | null;
}

const props = defineProps<{
  label?: string;
  placeholder?: string;
  hint?: string;
  modelValue?: string | null;
  options?: SelectOption[];
  value?: string;
  isError?: boolean;
  tooltip?: string;
  warning?: string;
  disabled?: boolean;
  emptyValueTo?: 'null' | 'undefined';
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null | undefined): void;
}>();

const value = computed(() => props.modelValue || props.value);

const onInput = (newValue: string) => {
  const emptyValue = props.emptyValueTo === 'null' ? null : undefined;
  emit('update:modelValue', newValue === '' && props.emptyValueTo ? emptyValue : newValue);
};
</script>

<style scoped>
.select {
  @apply relative h-8 min-h-min w-full rounded-none border border-primary/40
  bg-accent pl-1 transition duration-300
  hover:border-primary focus:outline-none disabled:opacity-50 lg:text-base;
  background-image: linear-gradient(45deg, transparent 50%, #cdaf746b 50%),
    linear-gradient(135deg, #cdaf746b 50%, transparent 50%);
}
.select:focus {
  background-image: linear-gradient(45deg, transparent 50%, #cdaf74 50%),
    linear-gradient(135deg, #cdaf74 50%, transparent 50%);
}
option[active] {
  @apply appearance-none;
}
</style>
