import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import { BookingType } from '@/entities/bookings/booking-type.enum';
import type { Booking, BookingLocation } from '@/entities/bookings/booking.entity';
import type { Car } from '@/entities/cars/car.entity';
import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { computed, type ComputedRef } from 'vue';
import { pointToLocationDto } from './use-location-to-coordinates';
import { getStationOfCarByDate } from './use-current-station-of-car';

export const useTransferLocations = (
  transferType: MaybeRefOrGetter<BookingType | undefined>,
  transfer: MaybeRefOrGetter<Booking | null | undefined>,
  car: MaybeRefOrGetter<Car | null | undefined>,
  customerBooking: MaybeRefOrGetter<Booking | null | undefined>,
): {
  pickupTransferLocation: ComputedRef<BookingLocation | null>;
  dropoffTransferLocation: ComputedRef<BookingLocation | null>;
} => {
  const pickupTransferLocation = computed<BookingLocation | null>(() => {
    const unrefTransfer = toValue(transfer);

    const unrefCar = toValue(car);
    const unrefBooking = toValue(customerBooking);
    if (toValue(transferType) === BookingType.TRANSFER_DELIVERY_BOOKING) {
      const startDate = (unrefBooking ?? unrefTransfer)?.startDate;
      return {
        locationType: unrefTransfer?.pickupLocationType ?? BookingLocationType.STATION,
        station:
          unrefTransfer?.pickupStation ??
          (unrefCar && startDate ? getStationOfCarByDate(unrefCar, startDate) : null) ??
          null,
        location: {
          coordinates: pointToLocationDto(unrefTransfer?.pickupLocation),
          address: unrefTransfer?.pickupLocationGeocodedAddress,
        },
      };
    }

    if (toValue(transferType) === BookingType.TRANSFER_RETURN_BOOKING) {
      return {
        locationType:
          unrefTransfer?.pickupLocationType ??
          unrefBooking?.dropoffLocationType ??
          BookingLocationType.STREET,
        station: unrefTransfer?.pickupStation ?? unrefBooking?.dropoffStation ?? null,
        location: {
          coordinates:
            pointToLocationDto(unrefTransfer?.pickupLocation) ??
            pointToLocationDto(unrefBooking?.dropoffLocation),
          address:
            unrefTransfer?.pickupLocationGeocodedAddress ??
            unrefBooking?.dropoffLocationGeocodedAddress,
        },
      };
    }

    return null;
  });
  const dropoffTransferLocation = computed<BookingLocation | null>(() => {
    const unrefTransfer = toValue(transfer);

    const unrefCar = toValue(car);
    const unrefBooking = toValue(customerBooking);

    if (toValue(transferType) === BookingType.TRANSFER_DELIVERY_BOOKING) {
      return {
        locationType:
          unrefTransfer?.dropoffLocationType ??
          unrefBooking?.pickupLocationType ??
          BookingLocationType.STREET,
        station: unrefTransfer?.dropoffStation ?? unrefBooking?.pickupStation ?? null,
        location: {
          coordinates:
            pointToLocationDto(unrefTransfer?.dropoffLocation) ??
            pointToLocationDto(unrefBooking?.pickupLocation),
          address:
            unrefTransfer?.dropoffLocationGeocodedAddress ??
            unrefBooking?.pickupLocationGeocodedAddress,
        },
      };
    }
    if (toValue(transferType) === BookingType.TRANSFER_RETURN_BOOKING) {
      const startDate = (unrefBooking ?? unrefTransfer)?.startDate;
      return {
        locationType: unrefTransfer?.dropoffLocationType ?? BookingLocationType.STATION,
        station:
          unrefTransfer?.dropoffStation ??
          (unrefCar && startDate ? getStationOfCarByDate(unrefCar, startDate) : null) ??
          null,
        location: {
          coordinates: pointToLocationDto(unrefTransfer?.dropoffLocation),
          address: unrefTransfer?.dropoffLocationGeocodedAddress,
        },
      };
    }
    return null;
  });

  return {
    pickupTransferLocation,
    dropoffTransferLocation,
  };
};
