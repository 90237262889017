import { computed } from 'vue';
import { flatMap } from 'lodash';
import { get, type MaybeRef } from '@vueuse/core';

export const useFlattenPaginatedData = <T>(
  data: MaybeRef<{ pages: { items: T[] }[] } | undefined>,
) => {
  return computed(() => flattenPaginatedData(data));
};

export const flattenPaginatedData = <T>(
  data: MaybeRef<{ pages: { items: T[] }[] } | undefined>,
) => {
  return flatMap(get(data)?.pages, (page) => page.items);
};
