import type { Car } from '@/entities/cars/car.entity';
import { computed } from 'vue';
import { type MaybeRefOrGetter, toRef } from '@vueuse/core';

export const useTrueCarAttributes = (carMaybeRef: MaybeRefOrGetter<Car>) => {
  const car = toRef(carMaybeRef);
  return {
    imageUrl: computed(() => car.value.imageUrl ?? car.value.vehicleType.imageUrl),
    fuelType: computed(() => car.value.fuelType ?? car.value.vehicleType.fuelType),
    transmission: computed(() => car.value.transmission ?? car.value.vehicleType.transmission),
    drive: computed(() => car.value.drive ?? car.value.vehicleType.drive),
    batteryCapacityInKwh: computed(
      () => car.value.batteryCapacityInKwh ?? car.value.vehicleType.batteryCapacityInKwh ?? 0,
    ),
    tankVolumeInLiter: computed(
      () => car.value.tankVolumeInLiter ?? car.value.vehicleType.tankVolumeInLiter ?? 0,
    ),
    rangeInKm: computed(() => car.value.rangeInKm ?? car.value.vehicleType.rangeInKm),
    seats: computed(() => car.value.seats ?? car.value.vehicleType.seats),
    power: computed(() => car.value.power ?? car.value.vehicleType.power),
    accelaration: computed(() => car.value.accelaration ?? car.value.vehicleType.accelaration),
    hasNavigation: computed(() => car.value.hasNavigation ?? car.value.vehicleType.hasNavigation),
    hasDistanceControl: computed(
      () => car.value.hasDistanceControl ?? car.value.vehicleType.hasDistanceControl,
    ),
    hasRearViewCamera: computed(
      () => car.value.hasRearViewCamera ?? car.value.vehicleType.hasRearViewCamera,
    ),
  };
};
