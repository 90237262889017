<template>
  <router-link
    :to="{
      name: 'editCarStatus',
      params: { id: car.id },
    }"
  >
    <div
      class="relative w-full border border-booking-warning-content/30 bg-booking-warning transition-shadow hover:shadow-md"
    >
      <div class="flex items-center gap-2 py-1 pl-3 pr-2 text-sm">
        <div class="basis-[15%]">
          <p class="mb-1 font-medium text-booking-warning-content">
            {{ t('detail') }}
          </p>
          <p>--:--</p>
        </div>

        <figure class="basis-[15%]">
          <img class="w-24" :src="imageUrl" />
        </figure>

        <div class="basis-[25%]">
          <p class="mb-1 font-medium text-booking-warning-content">
            {{ t('vehicle') }}
          </p>
          <p>{{ car.vehicleType.make.name }} {{ car.vehicleType.model }}</p>
        </div>

        <div class="basis-[15%]">
          <p class="mb-1 font-medium text-booking-warning-content">
            {{ t('licencePlate') }}
          </p>
          <p>{{ car.licencePlate }}</p>
        </div>

        <div
          class="relative flex h-11 basis-[30%] overflow-hidden bg-booking-warning-content/20 px-2 xl:h-[48px] xl:w-1/5"
        >
          <p class="clamp m-auto overflow-clip">
            {{ t('updateCarStatus') }}
          </p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import type { Car } from '@/entities/cars/car.entity';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const props = defineProps<{
  car: Car;
}>();

const { t } = useI18n();

const imageUrl = computed(() => props.car.imageUrl ?? props.car.vehicleType.imageUrl);
</script>

<style scoped>
.clamp {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>

<i18n lang="json">
{
  "en": {
    "detail": "Detail",
    "vehicle": "Vehicle",
    "licencePlate": "Lp.",
    "color": "Color",
    "updateCarStatus": "Update Car Status"
  },
  "de": {
    "detail": "Aufbereiten",
    "vehicle": "Fahrzeug",
    "licencePlate": "Knz.",
    "color": "Farbe",
    "updateCarStatus": "Fahrzeugstatus aktualisieren"
  }
}
</i18n>
