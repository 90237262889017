import { BookingType } from '@/entities/bookings/booking-type.enum';
import type { Booking } from '@/entities/bookings/booking.entity';
import { toRef, type MaybeRefOrGetter } from '@vueuse/core';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

export const useFilterForBookingTypes = (
  booking: MaybeRefOrGetter<Booking | undefined>,
  requiredTypes: BookingType | BookingType[],
  referToName?: string,
) => {
  const router = useRouter();
  const bookingRef = toRef(booking);
  const bookingReturn = ref<Booking>();
  const requiredTypesArray = Array.isArray(requiredTypes) ? requiredTypes : [requiredTypes];
  watch(
    bookingRef,
    () => {
      if (bookingRef.value && !requiredTypesArray.includes(bookingRef.value.type)) {
        bookingReturn.value = undefined;
        referToName && router.push({ name: referToName });
      } else if (bookingRef.value) {
        bookingReturn.value = bookingRef.value;
      }
    },
    { immediate: true },
  );
  return bookingReturn;
};
