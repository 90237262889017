<template>
  <HeaderBar />

  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
    <AuditLogButton entity-type="Booking" :entity-id="route.params.id as string" />
  </ButtonBackContainer>

  <section class="carvia-container">
    <div v-if="booking">
      <BookingEditForm :booking="booking" />
    </div>
    <div v-else class="flex justify-center"><Spinner /></div>
  </section>
</template>

<script lang="ts" setup>
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useI18n } from 'vue-i18n';
import { useBooking } from '@/queries/use-bookings';
import { useUiStore } from '@/stores/ui.store';
import { watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import BookingEditForm from './BookingEditForm.vue';
import AuditLogButton from '@/components/audit-log/AuditLogButton.vue';

const { t } = useI18n();
const route = useRoute();

const uiStore = useUiStore();

const { data: booking } = useBooking(route.params.id as string);

watchEffect(() => {
  if (booking.value) {
    const { customerData, car } = booking.value;
    uiStore.setHeaderTitle(
      t('booking'),
      `${customerData?.firstName ?? 'n/a'} ${customerData?.lastName ?? 'n/a'}`,
      `${car.vehicleType?.make.name} ${car.vehicleType?.model}`,
    );
  } else {
    uiStore.setHeaderTitle(t('booking'));
  }
});
</script>

<i18n lang="json">
{
  "en": {
    "booking": "Booking"
  },
  "de": {
    "booking": "Buchung"
  }
}
</i18n>
