<template>
  <HeaderBar />
  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
    <AuditLogButton entity-type="Car" :entity-id="carId" />
  </ButtonBackContainer>

  <section class="carvia-container">
    <EditCarForm
      v-if="car && vehicleTypes && selectedVehicleType && stations"
      :is-saving="isUpdating || isUploadingImage"
      :initial-car="car"
      :vehicle-types="vehicleTypes"
      :selected-vehicle-type="selectedVehicleType"
      :stations="stations"
      @on-submit="handleSubmit"
      @vehicle-type-change="selectedVehicleTypeId = $event"
    />
    <div v-else class="flex justify-center">
      <Spinner />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useCar, useUpdateCar } from '@/queries/use-cars';
import { useRoute } from 'vue-router';
import { useUiStore } from '@/stores/ui.store';
import EditCarForm, { type CarFormValues } from './EditCarForm.vue';
import { computed, ref, watchEffect } from 'vue';
import { useVehicleType, useVehicleTypes } from '@/queries/use-vehicle-types';
import { useStations } from '@/queries/use-stations';
import { useUploadCarImage } from '@/queries/use-upload';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import type { Car } from '@/entities/cars/car.entity';
import AuditLogButton from '@/components/audit-log/AuditLogButton.vue';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';

const uiStore = useUiStore();
const { t } = useI18n();
const route = useRoute();
const carId = ref(route.params.id as string);
const { data: car } = useCar(carId);
const { data: vehicleTypes } = useVehicleTypes();
const selectedVehicleTypeId = ref<string | null>(null);
const { data: vehicleType } = useVehicleType(
  computed(() => selectedVehicleTypeId.value ?? ''),
  computed(() => !!selectedVehicleTypeId.value),
);
const selectedVehicleType = computed(() => vehicleType.value ?? car.value?.vehicleType ?? null);
const { data: stationsData } = useStations(500);
const stations = useFlattenPaginatedData(stationsData);

const { mutateAsync: uploadImage, isPending: isUploadingImage } = useUploadCarImage();

watchEffect(() => {
  if (car.value?.vehicleType) {
    const { make, model } = car.value.vehicleType;
    uiStore.setHeaderTitle(t('vehicle'), `${make.name} ${model}`, `${car.value.licencePlate}`);
  } else {
    uiStore.setHeaderTitle(t('vehicle'));
  }
});

const { mutate: updateCar, isPending: isUpdating } = useUpdateCar();

const handleSubmit = async ({
  selectedImageFile,
  possibleUpgradeCars,
  ...formValues
}: CarFormValues) => {
  const { fileKey } = selectedImageFile
    ? await uploadImage({
        file: selectedImageFile,
        params: {
          carId: carId.value,
          formerFileName: selectedImageFile.name,
        },
      })
    : { fileKey: selectedImageFile };
  updateCar({
    id: carId.value,
    car: {
      ...formValues,
      imageFileKey: fileKey,
      possibleUpgrades: possibleUpgradeCars
        ? possibleUpgradeCars.map((car, i) => {
            return {
              toId: (car as unknown as Car).id,
              order: i,
            };
          })
        : undefined,
    },
  });
};
</script>

<i18n lang="json">
{
  "en": {
    "vehicle": "Vehicle"
  },
  "de": {
    "vehicle": "Fahrzeug"
  }
}
</i18n>
