<template>
  <router-link
    :to="{
      name: 'station',
      params: { id: station.id },
    }"
  >
    <div class="relative w-full border border-primary transition-shadow hover:shadow-md">
      <div class="flex items-center gap-2 py-1 pl-3 pr-2 text-sm">
        <div class="basis-[40%]">
          <p class="mb-1 font-medium">{{ t('name') }}</p>
          <p>{{ getLocalizedField(station.info) }}</p>
        </div>

        <div class="basis-[60%]">
          <p class="mb-1 font-medium">{{ t('address') }}</p>
          <p>{{ `${station.street}, ${station.zip} ${station.city}` }}</p>
        </div>

        <DropdownDotted :buttons="dropDownItems" />
      </div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { DropdownItem } from '@/components/DropdownDotted.vue';
import DropdownDotted from '@/components/DropdownDotted.vue';
import router from '@/router';
import { useLocalizedFields } from '@/hooks/use-localized-fields';
import type { Station } from '@/entities/station/station.entity';

const props = defineProps<{
  station: Station;
}>();

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();

const dropDownItems: DropdownItem[] = [
  {
    title: t('editStation'),
    onClick: () =>
      router.push({
        name: 'station',
        params: { id: props.station.id },
      }),
  },
];
</script>

<i18n lang="json">
{
  "en": {
    "name": "Name",
    "address": "Address",
    "editStation": "Edit station"
  },
  "de": {
    "name": "Name",
    "address": "Adresse",
    "editStation": "Station bearbeiten"
  }
}
</i18n>
