import * as yup from 'yup';
import { CarColor } from '@/entities/cars/car-color.enum';
import { FuelType } from '@/entities/cars/fuel-type.enum';
import { TransmissionType } from '@/entities/cars/transmission-type.enum';
import { DriveType } from '@/entities/cars/drive-type.enum';
import { DateTime } from 'luxon';
import { CarAcquisitionType } from '@/entities/cars/car-acquisition-type.enum';
import { CarTyres } from '@/entities/cars/car-tyres.enum';
import { CarStatus } from '@/entities/cars/car-status.enum';

export const dateOrString = yup.lazy((value) => {
  if (typeof value === 'string') {
    return yup.string().notRequired();
  }
  return yup.date().notRequired();
});

export const dateOrStringRequired = (message?: string) =>
  yup.lazy((value) => {
    if (typeof value === 'string') {
      return yup.string().required(message);
    }
    return yup.date().required(message);
  });

export const carFleetInSchema = yup.object({
  vehicleTypeId: yup.string().required(),
  selectedImageFile: yup.mixed().required(),
  contractName: yup.string().required(),
  contractFile: yup.mixed().required(),
  contractComment: yup.string().notRequired(),
  acquisitionType: yup.string().oneOf(Object.values(CarAcquisitionType)).required(),
  purchasePrice: yup.number().when('acquisitionType', {
    is: (val: CarAcquisitionType) => val !== CarAcquisitionType.LEASED,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  leasingRate: yup.number().when('acquisitionType', {
    is: (val: CarAcquisitionType) => val === CarAcquisitionType.LEASED,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  listPrice: yup.number().required(),
  availableFrom: dateOrStringRequired(),
  color: yup.string().oneOf(Object.values(CarColor)).required(),
  tyres: yup.string().oneOf(Object.values(CarTyres)).required(),
  stationId: yup.string().required(),
  fuelType: yup.string().oneOf(Object.values(FuelType)).notRequired(),
  transmission: yup.string().oneOf(Object.values(TransmissionType)).notRequired(),
  drive: yup.string().oneOf(Object.values(DriveType)).notRequired(),
  tankVolumeInLiter: yup.number().notRequired(),
  batteryCapacityInKwh: yup.number().notRequired(),
  seats: yup.string().notRequired(),
  rangeInKm: yup.number().notRequired(),
  power: yup.number().notRequired(),
  accelaration: yup.number().notRequired(),
  hasNavigation: yup.boolean().notRequired(),
  hasDistanceControl: yup.boolean().notRequired(),
  hasRearViewCamera: yup.boolean().notRequired(),
});

export const ghostCarFleetInSchema = yup.object({
  vehicleTypeId: yup.string().required(),
  selectedImageFile: yup.mixed().required(),
  color: yup.string().oneOf(Object.values(CarColor)).required(),
  stationId: yup.string().required(),
  possibleUpgradeCars: yup.array().of(yup.mixed().required()).min(1).required(),
  isMysteryCar: yup.boolean().notRequired(),
  fuelType: yup.string().oneOf(Object.values(FuelType)).notRequired(),
  transmission: yup.string().oneOf(Object.values(TransmissionType)).notRequired(),
  drive: yup.string().oneOf(Object.values(DriveType)).notRequired(),
  tankVolumeInLiter: yup.number().notRequired(),
  batteryCapacityInKwh: yup.number().notRequired(),
  seats: yup.string().notRequired(),
  rangeInKm: yup.number().notRequired(),
  power: yup.number().notRequired(),
  accelaration: yup.number().notRequired(),
  hasNavigation: yup.boolean().notRequired(),
  hasDistanceControl: yup.boolean().notRequired(),
  hasRearViewCamera: yup.boolean().notRequired(),
});

export const editCarSchema = (status: CarStatus) => {
  const isFloatIn = status !== CarStatus.ORDERED;
  return yup.object({
    vehicleTypeId: yup.string(),
    licencePlate: isFloatIn ? yup.string().required() : yup.string().notRequired(),
    fin: isFloatIn ? yup.string().required() : yup.string().notRequired(),
    fuelLevel: yup.number(),
    milage: yup.number(),
    acquisitionType: yup.string().oneOf(Object.values(CarAcquisitionType)).required(),
    purchasePrice: yup.number().when('acquisitionType', {
      is: (val: CarAcquisitionType) => val !== CarAcquisitionType.LEASED,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    leasingRate: yup.number().when('acquisitionType', {
      is: (val: CarAcquisitionType) => val === CarAcquisitionType.LEASED,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    listPrice: yup.number().required(),
    stations: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          fromDate: yup.date().required().nullable(),
        }),
      )
      .min(1)
      .required()
      .test('dates-order', 'From-dates must be set and in ascendant order', (stations) => {
        let lastDate: Date | null = null;
        let firstOne = true;
        for (const station of stations) {
          if (firstOne) {
            if (station.fromDate !== null) return false;
            firstOne = false;
            continue;
          }
          if (
            !station.fromDate ||
            (lastDate &&
              DateTime.fromJSDate(station.fromDate).startOf('day') <
                DateTime.fromJSDate(lastDate).plus({ days: 1 }).startOf('day'))
          ) {
            return false;
          }
          lastDate = station.fromDate;
        }
        return true;
      }),
    insuranceCompany: isFloatIn ? yup.string().required() : yup.string().notRequired(),
    color: yup.string().oneOf(Object.values(CarColor)).notRequired(),
    tyres: yup.string().oneOf(Object.values(CarTyres)).required(),
    firstRegistration: isFloatIn ? dateOrStringRequired() : dateOrString,
    availableFrom: isFloatIn ? dateOrStringRequired() : dateOrString,
    availableUntil: dateOrString,
    fuelType: yup.string().oneOf(Object.values(FuelType)).notRequired(),
    transmission: yup.string().oneOf(Object.values(TransmissionType)).notRequired(),
    drive: yup.string().oneOf(Object.values(DriveType)).notRequired(),
    tankVolumeInLiter: yup.number().notRequired(),
    batteryCapacityInKwh: yup.number().notRequired(),
    seats: yup.string().notRequired(),
    rangeInKm: yup.number().notRequired(),
    power: yup.number().notRequired(),
    accelaration: yup.number().notRequired(),
    hasNavigation: yup.boolean().notRequired(),
    hasDistanceControl: yup.boolean().notRequired(),
    hasRearViewCamera: yup.boolean().notRequired(),
  });
};

export const editGhostCarSchema = yup.object({
  vehicleTypeId: yup.string(),
  licencePlate: yup.string().required(),
  stations: yup
    .array()
    .of(
      yup.object({
        id: yup.string().required(),
        fromDate: yup.date().required().nullable(),
      }),
    )
    .min(1)
    .required()
    .test('dates-order', 'From-dates must be set and in ascendant order', (stations) => {
      let lastDate: Date | null = null;
      let firstOne = true;
      for (const station of stations) {
        if (firstOne) {
          if (station.fromDate !== null) return false;
          firstOne = false;
          continue;
        }
        if (
          !station.fromDate ||
          (lastDate &&
            DateTime.fromJSDate(station.fromDate).startOf('day') <
              DateTime.fromJSDate(lastDate).plus({ days: 1 }).startOf('day'))
        ) {
          return false;
        }
        lastDate = station.fromDate;
      }
      return true;
    }),
  possibleUpgradeCars: yup.array().of(yup.mixed().required()).min(1).required(),
  color: yup.string().oneOf(Object.values(CarColor)).notRequired(),
  isMysteryCar: yup.boolean().notRequired(),
  fuelType: yup.string().oneOf(Object.values(FuelType)).notRequired(),
  transmission: yup.string().oneOf(Object.values(TransmissionType)).notRequired(),
  drive: yup.string().oneOf(Object.values(DriveType)).notRequired(),
  tankVolumeInLiter: yup.number().notRequired(),
  batteryCapacityInKwh: yup.number().notRequired(),
  seats: yup.string().notRequired(),
  rangeInKm: yup.number().notRequired(),
  power: yup.number().notRequired(),
  accelaration: yup.number().notRequired(),
  hasNavigation: yup.boolean().notRequired(),
  hasDistanceControl: yup.boolean().notRequired(),
  hasRearViewCamera: yup.boolean().notRequired(),
});
