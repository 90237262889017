import { useMutation, useQuery } from '@tanstack/vue-query';
import { toValue } from '@vueuse/core';
import { surveysService } from '@/api/surveys.service';

const QUERY_KEY = 'surveys';

export const useSurvey = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: () => surveysService.getOne(toValue(id)),
  });
};

export const useSurveys = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => surveysService.getAll(),
  });
};

export const useExportSurveyCsv = () => {
  return useMutation({
    mutationKey: ['survey-export'],
    mutationFn: (query: { surveyId: string }) => surveysService.exportSurveyCsv(query),
  });
};
