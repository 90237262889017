<template>
  <router-link
    :to="{
      name: 'paymentTerminal',
      params: { id: paymentTerminal.id },
    }"
  >
    <div class="relative w-full border border-primary transition-shadow hover:shadow-md">
      <div class="flex items-center gap-2 py-1 pl-3 pr-2 text-sm">
        <div class="basis-[34%]">
          <p class="mb-1 font-medium">{{ t('description') }}</p>
          <p>{{ paymentTerminal.description }}</p>
        </div>

        <div class="basis-[33%]">
          <p class="mb-1 font-medium">{{ t('terminalId') }}</p>
          <p>{{ paymentTerminal.terminalId }}</p>
        </div>

        <div class="basis-[33%]">
          <p class="mb-1 font-medium">{{ t('station') }}</p>
          <p>{{ getLocalizedField(paymentTerminal.station.info) }}</p>
        </div>

        <DropdownDotted :buttons="dropDownItems" />
      </div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { DropdownItem } from '@/components/DropdownDotted.vue';
import DropdownDotted from '@/components/DropdownDotted.vue';
import { Alert } from '@/utils/alert';
import type { PaymentTerminal } from '@/entities/payment-terminals/payment-terminal.entity';
import { useDeletePaymentTerminal } from '@/queries/use-payment-terminals';
import router from '@/router';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

const props = defineProps<{
  paymentTerminal: PaymentTerminal;
}>();

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();

const { mutateAsync: removePaymentTerminal } = useDeletePaymentTerminal();

const deletePaymentTerminal = async (paymentTerminalId: string) => {
  const alertResult = await Alert.fire({
    titleText: t('reallyDeletePaymentTerminalTitle'),
    text: t('reallyCancelPaymentTerminalText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('deletePaymentTerminal'),
    denyButtonText: t('dontDelete'),
  });
  if (alertResult.isConfirmed) {
    removePaymentTerminal(paymentTerminalId);
  }
};

const dropDownItems: DropdownItem[] = [
  {
    title: t('editPaymentTerminal'),
    onClick: () =>
      router.push({
        name: 'paymentTerminal',
        params: { id: props.paymentTerminal.id },
      }),
  },
  {
    title: t('deletePaymentTerminal'),
    onClick: () => deletePaymentTerminal(props.paymentTerminal.id),
  },
];
</script>

<i18n lang="json">
{
  "en": {
    "description": "Description",
    "terminalId": "Terminal ID",
    "station": "Station",
    "editPaymentTerminal": "Edit Payment Terminal",
    "deletePaymentTerminal": "Delete Payment Terminal",
    "reallyDeletePaymentTerminalTitle": "Really delete Payment Terminal?",
    "reallyCancelPaymentTerminalText": "The Payment Terminal will be permanently deleted.",
    "dontDelete": "Don't delete"
  },
  "de": {
    "description": "Beschreibung",
    "terminalId": "Terminal ID",
    "station": "Station",
    "editPaymentTerminal": "Zahlungsterminal bearbeiten",
    "deletePaymentTerminal": "Zahlungsterminal löschen",
    "reallyDeletePaymentTerminalTitle": "Zahlungsterminal wirklich löschen?",
    "reallyCancelPaymentTerminalText": "Das Zahlungsterminal wird unwiderruflich gelöscht.",
    "dontDelete": "Nicht löschen"
  }
}
</i18n>
