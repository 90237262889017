<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router';
import { useAuthStore } from './stores/auth.store';
import router from './router';
import { UserRole } from './entities/auth/user-role.enum';
import { authService } from './api/auth.service';
import { onMounted, onUnmounted, watch } from 'vue';
import { useRouteQueryStore } from './stores/route-query.store';
import { useAbility } from '@casl/vue';
import { useI18n } from 'vue-i18n';
import * as Sentry from '@sentry/vue';
import { posthogClient } from '@/utils/posthog';

const authStore = useAuthStore();
const route = useRoute();
const routeQueryStore = useRouteQueryStore();
const ability = useAbility();
let interval: any;

const { locale } = useI18n({
  useScope: 'global',
});

onMounted(async () => {
  try {
    const jwtUser = await authService.getTokenPayload();
    authStore.setAuthUser(jwtUser);
    if (jwtUser?.role === UserRole.AGENT) {
      locale.value = 'en';
    }
  } catch (err) {
    authStore.setAuthUser(null);
  }

  if (route.name !== 'bookingCreate' && authStore.user?.role === UserRole.AGENT) {
    router.push({ name: 'bookingCreate' });
  }

  interval = setInterval(
    async () => {
      if (authStore.authUser) {
        const [me, abilities] = await Promise.all([
          authService.getMe(),
          authService.getAbilities(),
        ]);
        authStore.setUser(me);
        ability.update([]);
        ability.update(abilities as any);
      }
    },
    10 * 60 * 1000,
  );
});

onUnmounted(() => {
  clearInterval(interval);
});

router.beforeEach((to, from, next) => {
  if (authStore.user?.role === UserRole.AGENT && to.name !== 'bookingCreate') {
    next({ name: 'bookingCreate' });
  } else {
    next();
  }
});

watch(
  () => authStore.authUser,
  async () => {
    try {
      if (authStore.authUser?.id) {
        posthogClient.identify(authStore.authUser?.id, {
          email: authStore.authUser?.email,
          name: `${authStore.authUser?.firstName} ${authStore.authUser?.lastName}`,
        });
      } else {
        posthogClient.reset();
      }
      if (authStore.authUser === null) {
        authStore.setUser(null);
        ability.update([]);
        authStore.setIsLoading(false);
      } else if (authStore.authUser) {
        authStore.setIsLoading(true);
        const [me, abilities] = await Promise.all([
          authService.getMe(),
          authService.getAbilities(),
        ]);
        authStore.setUser(me);
        ability.update([]);
        ability.update(abilities as any);
        authStore.setIsLoading(false);
      }
    } catch (err) {
      authStore.setAuthUser(null);
      authStore.setUser(null);
      ability.update([]);
      authStore.setIsLoading(false);
    }
  },
);

watch(
  () => authStore.user,
  () => {
    Sentry.setUser({
      id: authStore.user?.id,
      email: authStore.user?.email,
      role: authStore.user?.role,
    });
  },
);

watch(
  () => route.query,
  () => {
    routeQueryStore.replace(route.query);
  },
);
</script>

<template>
  <RouterView />
</template>

<style>
@import '@/../node_modules/@vueform/multiselect/themes/tailwind.css';
</style>
