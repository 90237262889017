<template>
  <h2>{{ t('profile') }}</h2>
  <div class="mt-4">
    <p class="mb-4">{{ t('name') }}: {{ user.firstName }} {{ user.lastName }}</p>
    <p class="mb-4">{{ t('email') }}: {{ user.email }}</p>
    <p class="mb-4">{{ t('role') }}: {{ user.role }}</p>

    <form class="space-y-4" @submit.prevent="onSubmit">
      <MultiSelectField
        v-if="$can('view', 'User', 'stations')"
        :disabled="!$can('update', 'User', 'stations')"
        name="stationIds"
        :label="t('stations')"
        :options="
          stations.map((station) => {
            return {
              label: getLocalizedField(station.info),
              value: station.id,
            };
          })
        "
      />

      <div class="flex justify-end">
        <CVButton :is-loading="isUpdating" type="submit">{{ t('save') }}</CVButton>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import MultiSelectField from '@/components/MultiSelectField.vue';
import type { UpdateUserDto, User } from '@/entities/auth/user.entity';
import type { Station } from '@/entities/station/station.entity';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

const props = defineProps<{
  user: User;
  stations: Station[];
  isUpdating: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSubmit', value: UpdateUserDto): void;
}>();

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();

const { handleSubmit } = useForm({
  initialValues: {
    stationIds: props.user.stations?.map((station) => station.id) ?? [],
  },
});

const onSubmit = handleSubmit((values) => {
  emit('onSubmit', values);
});
</script>

<i18n lang="json">
{
  "en": {
    "noStationsFound": "No Stations found",
    "profile": "Profile",
    "name": "Name",
    "email": "Email",
    "role": "Role",
    "stations": "Stations"
  },
  "de": {
    "noStationsFound": "Keine Stationen gefunden",
    "profile": "Profil",
    "name": "Name",
    "email": "E-Mail",
    "role": "Rolle",
    "stations": "Stationen"
  }
}
</i18n>
