<template>
  <div class="grow">
    <ReturnCarStatusForm v-if="booking" :booking="booking" />
  </div>
</template>

<script lang="ts" setup>
import { useBookingOfType } from '@/queries/use-bookings';
import { useRoute } from 'vue-router';
import ReturnCarStatusForm from './ReturnCarStatusForm.vue';
import { BookingType } from '@/entities/bookings/booking-type.enum';

const route = useRoute();
const { data: booking } = useBookingOfType(
  route.params.id as string,
  true,
  BookingType.CUSTOMER_BOOKING,
  'upcomingHandovers',
);
</script>
