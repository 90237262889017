<template>
  <HeaderBar />
  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
  </ButtonBackContainer>
  <section class="carvia-container flex flex-col justify-center">
    <div class="flex justify-center space-y-3">
      <div class="grow">
        <div v-if="!isLoading && car">
          <h2 class="mb-4">
            {{ t('carStatus') }}
          </h2>
          <EditCarStatusForm
            :is-saving="isUpdating"
            :car="car"
            :auto-enter-milage="autoEnterMilage"
            @on-submit="handleSubmit"
          />
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="flex justify-center"><Spinner /></div>
  </section>
</template>

<script lang="ts" setup>
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import type { UpdateCarDto } from '@/entities/cars/car.entity';
import { useI18n } from 'vue-i18n';
import { useCar, useUpdateCar } from '@/queries/use-cars';
import { useUiStore } from '@/stores/ui.store';
import EditCarStatusForm from './EditCarStatusForm.vue';
import { useRoute } from 'vue-router';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import { watchEffect } from 'vue';

const uiStore = useUiStore();
const { t } = useI18n();
const route = useRoute();
const { data: car, isLoading } = useCar(route.params.id as string, route.params.id !== 'new');
const autoEnterMilage = route.params.autoEnterMilage === 'auto-enter-milage';

watchEffect(() => {
  uiStore.setHeaderTitle(t('carStatus'));
});

const { mutate: updateCar, isPending: isUpdating } = useUpdateCar();
const handleSubmit = (car: Partial<UpdateCarDto>) => {
  updateCar({
    id: route.params.id as string,
    car: { ...car, readyToHandover: true },
  });
};
</script>

<i18n lang="json">
{
  "en": {
    "carStatus": "Car Status"
  },
  "de": {
    "carStatus": "Fahrzeugstatus"
  }
}
</i18n>
