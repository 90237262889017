<template>
  <div>
    <LabeledText v-if="!isFetching" :label="t('previousBookings')">
      <div>{{ t('completedBookings') }}: {{ countBookingsOfUser?.completedBookingsCount }}</div>
      <div v-if="!hideCancelled">
        {{ t('cancelledBookings') }}: {{ countBookingsOfUser?.cancelledBookingsCount }}
      </div>
    </LabeledText>
    <Spinner v-else />
  </div>
</template>

<script lang="ts" setup>
import { useCountBookingsOfUser } from '@/queries/use-bookings';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import LabeledText from '@/components/LabeledText.vue';

const props = defineProps<{
  userId: string;
  hideCancelled?: boolean;
}>();

const { t } = useI18n();

const { data: countBookingsOfUser, isFetching } = useCountBookingsOfUser(
  computed(() => props.userId),
);
</script>

<i18n lang="json">
{
  "en": {
    "previousBookings": "Previous Bookings",
    "completedBookings": "Completed",
    "cancelledBookings": "Cancelled"
  },
  "de": {
    "previousBookings": "Bisherige Buchungen",
    "completedBookings": "Abgeschlossen",
    "cancelledBookings": "Storniert"
  }
}
</i18n>
