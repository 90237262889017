import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { DateTime } from 'luxon';
import { computed } from 'vue';

export const useTuvDueDate = (dateOfIssue: MaybeRefOrGetter<Date> = new Date()) => {
  return computed(() => getTuvDueDate(toValue(dateOfIssue)));
};

export const getTuvDueDate = (dateOfIssue: Date = new Date()) => {
  return DateTime.fromJSDate(dateOfIssue).minus({ years: 1 }).endOf('month').toJSDate();
};
