import type { BookingLocation } from '@/entities/bookings/booking.entity';
import type { LocationDto } from '@/entities/station/station.entity';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import { pointToLocationDto } from '@/hooks/use-location-to-coordinates';

export const bookingLocationToLocationDto = (
  location: BookingLocation | null,
): LocationDto | undefined | null => {
  if (!location) {
    return undefined;
  }
  if (location.locationType === BookingLocationType.STATION) {
    return pointToLocationDto(location.station?.location);
  }
  return location.location?.coordinates;
};
