<template>
  <div v-if="!isLoading && booking" class="space-y-4">
    <h2>{{ t('close') }}</h2>
    <LabeledText :label="t('booking')">
      <RouterLink :to="{ name: 'bookingPlanEdit', params: { id: booking?.id } }">
        {{ booking?.bookingNumber }}
      </RouterLink>
    </LabeledText>
    <Invoices :booking="booking" default-expanded />
    <div class="flex justify-end">
      <CVButton
        :disabled="!canBeClosed"
        class="btn btn-primary"
        :loading="isUpdating"
        @click="handleClose"
      >
        {{ t('closeNow') }}
      </CVButton>
    </div>
  </div>
  <div v-else>
    <Spinner class="h-8 w-8" />
  </div>
</template>
<script setup lang="ts">
import { useBooking } from '@/queries/use-bookings';
import { useI18n } from 'vue-i18n';
import LabeledText from '@/components/LabeledText.vue';
import Invoices from '@/components/booking-forms/Invoices.vue';
import { computed } from 'vue';
import { InvoiceStatus } from '@/entities/invoices/invoice-status.enum';
import { useUpdateInsuranceCase } from '@/queries/use-insurance-cases';
import { type InsuranceCase, InsuranceCaseStatus } from '@carvia/ros-client-types';

const props = defineProps<{
  insuranceCase: InsuranceCase;
}>();

const { t } = useI18n();

const { data: booking, isLoading } = useBooking(props.insuranceCase.booking!.id);

const canBeClosed = computed(
  () => !booking.value?.invoices.some((invoice) => invoice.status === InvoiceStatus.DRAFT),
);

const { mutateAsync: update, isPending: isUpdating } = useUpdateInsuranceCase();

const handleClose = async () => {
  await update({
    id: props.insuranceCase.id,
    insuranceCase: { status: InsuranceCaseStatus.CLOSED },
  });
};
</script>
<i18n lang="json">
{
  "en": {
    "close": "Close Insurance Case",
    "closeNow": "Close Insurance Case Now",
    "booking": "Booking"
  },
  "de": {
    "close": "Versicherungsfall schließen",
    "closeNow": "Versicherungsfall jetzt schließen",
    "booking": "Buchung"
  }
}
</i18n>
