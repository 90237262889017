import { computed, ref } from 'vue';
import type { Station } from '@/entities/station/station.entity';
import type { CustomLocations } from '@/constants/custom-locations.constant';
import type { BookingLocation } from '@/entities/bookings/booking.entity';
import type { VehicleCategory } from '@/entities/vehicle-category.enum';
import { type InsuranceCase, type Offer } from '@carvia/ros-client-types';
import { DateTime } from 'luxon';
import { VehicleExtraType } from '@/entities/vehicle-extra-type.enum';
import type { Pricing } from '@/entities/pricing.entity';

export type CreateBookingProps = ReturnType<typeof useCreateBookingProps>;

export const useCreateBookingProps = (initialValues?: {
  startDate?: Date;
  endDate?: Date;
  station?: Station | CustomLocations;
  pickupLocation?: BookingLocation;
  dropoffLocation?: BookingLocation;
  categoryFilter?: VehicleCategory;
  makeFilter?: string;
  selectedOffer?: Offer;
  insuranceCase?: InsuranceCase;
}) => {
  const initialStartDate = DateTime.now()
    .plus({ days: 1 })
    .set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
  const startDate = ref(initialValues?.startDate ?? initialStartDate.toJSDate());
  const endDate = ref(initialValues?.endDate ?? initialStartDate.plus({ days: 2 }).toJSDate());
  const selectedOffer = ref<Offer | null>(initialValues?.selectedOffer ?? null);
  const pricing = ref<Pricing | null>(
    (selectedOffer.value?.pricing as unknown as Pricing | undefined) ?? null,
  );
  pricing.value;
  return {
    startDate,
    endDate,
    station: ref<Station | CustomLocations | null>(initialValues?.station ?? null),
    pickupLocation: ref<BookingLocation | null>(initialValues?.pickupLocation ?? null),
    dropoffLocation: ref<BookingLocation | null>(initialValues?.dropoffLocation ?? null),
    categoryFilter: ref<VehicleCategory | null>(initialValues?.categoryFilter ?? null),
    makeFilter: ref<string | null>(initialValues?.makeFilter ?? null),
    insuranceCase: ref(initialValues?.insuranceCase),
    selectedOffer,
    pricing,
    transferDeliveryPrice: computed(
      () =>
        pricing.value?.customBookedExtras?.find(
          (extra) => extra.customVehicleExtraType === VehicleExtraType.TRANSFER_PICKUP,
        )?.price,
    ),
    transferReturnPrice: computed(
      () =>
        pricing.value?.customBookedExtras?.find(
          (extra) => extra.customVehicleExtraType === VehicleExtraType.TRANSFER_RETURN,
        )?.price,
    ),
  };
};
