<template>
  <SearchApiEntityItemInput
    :search-result-options="searchResultOptions"
    :is-fetching-results="isFetchingResults"
    :placeholder="searchInputPlaceholder"
    @fetch="fetchResults"
    @on-update-selected-item="updateSelectedItem"
  />
</template>

<script lang="ts" setup>
import SearchApiEntityItemInput from '../SearchApiEntityItemInput.vue';
import { computed, ref } from 'vue';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { useI18n } from 'vue-i18n';
import type { FindAllBookingsQueryDto } from '@/entities/bookings/booking.entity';
import { BookingType } from '@/entities/bookings/booking-type.enum';
import { useBookingsWithQuery } from '@/queries/use-bookings';
import { getDateTimeFormat } from '@/hooks/use-date-format';
import { formatDate } from '@/hooks/use-formatted-date';
import type { ManyBookingsBooking } from '@/entities/bookings/many-bookings-booking.entity';

const emit = defineEmits<{
  (e: 'on-update-booking', value: ManyBookingsBooking | undefined): void;
}>();

const { t } = useI18n();

const searchString = ref('');
const searchInputPlaceholder = computed(() => t('searchForBooking'));

const query = computed<FindAllBookingsQueryDto>(() => ({
  filter: [
    {
      types: [BookingType.CUSTOMER_BOOKING],
      query: searchString.value,
    },
  ],
  limit: 20,
}));

const { data: searchResultsData, isFetching: isFetchingResults } = useBookingsWithQuery(query);

const fetchResults = (search: string) => {
  searchString.value = search;
};

const searchResults = useFlattenPaginatedData(searchResultsData);

const searchResultOptions = computed(() => {
  return searchResults.value.map((booking) => {
    const content = [
      `${t('bookingNumber')} ${booking.bookingNumber}`,
      (booking.customerData?.firstName ?? 'n/a') + ' ' + (booking.customerData?.lastName ?? 'n/a'),
      booking.car.vehicleType.make.name + ' ' + booking.car.vehicleType.model,
      `${formatDate(
        booking.startDate,
        getDateTimeFormat(),
      )} - ${formatDate(booking.endDate, getDateTimeFormat())}`,
    ];
    return {
      item: booking,
      content,
    };
  });
});

const updateSelectedItem = (item: any) => {
  emit('on-update-booking', item as ManyBookingsBooking);
};
</script>

<i18n lang="json">
{
  "en": {
    "bookingNumber": "Bnr.",
    "searchForBooking": "Search for booking"
  },
  "de": {
    "bookingNumber": "Bnr.",
    "searchForBooking": "Buchung suchen"
  }
}
</i18n>
