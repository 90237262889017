import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { computed } from 'vue';

export const useShortenText = (
  text: MaybeRefOrGetter<string>,
  maxLength: MaybeRefOrGetter<number>,
  addDots: MaybeRefOrGetter<boolean> = true,
) => {
  return computed(() => shortenText(toValue(text), toValue(maxLength), toValue(addDots)));
};

export const shortenText = (text: string, maxLength: number, addDots = true) => {
  const shortened = text.slice(0, maxLength);
  if (!addDots) return shortened;
  if (text.length <= maxLength) return shortened;
  return shortened + '...';
};
