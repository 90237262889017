import { FindAllInvoicesSort } from '@/entities/invoices/find-all-invoices-sort.enum';
import { Order } from '@/entities/pagination/order.enum';
import { useInvoicesWithQuery } from '@/queries/use-invoices';
import { type MaybeRefOrGetter, toRef } from '@vueuse/core';
import { computed } from 'vue';
import { useFlattenPaginatedData } from './use-flatten-paginated-data';

export const useInvoicesOfBooking = (bookingId: MaybeRefOrGetter<string | undefined>) => {
  const bookingIdRef = toRef(bookingId);
  const { data: invoicesData } = useInvoicesWithQuery(
    computed(() => ({
      filter: [
        {
          bookingIds: bookingIdRef.value ? [bookingIdRef.value] : undefined,
        },
      ],
      sort: FindAllInvoicesSort.INVOICE_DATE,
      order: Order.ASC,
    })),
    computed(() => !!bookingIdRef.value),
  );
  const invoices = useFlattenPaginatedData(invoicesData);
  return invoices;
};
