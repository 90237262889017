<template>
  <CVButton
    class="back-button group"
    outline
    @click.prevent="to ? $router.push(to) : $router.go(-1)"
  >
    ←
    <span v-if="!$slots.default">{{ t('back') }}</span>
    <slot></slot>
  </CVButton>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { RouteLocationNamedRaw } from 'vue-router';

defineProps<{
  to?: RouteLocationNamedRaw;
}>();

const { t } = useI18n();
</script>

<style scoped>
[data-theme='carvia'] .back-button.btn.btn-primary.btn-outline {
  @apply border border-transparent bg-accent text-primary;
}
[data-theme='carvia'] .back-button.btn.btn-primary.btn-outline:hover {
  @apply border-primary bg-transparent text-primary;
}
.arrow-long-left {
  @apply relative  block h-[6px] w-[14px] scale-90 border-b-[2px]
      border-t-[2px] border-b-transparent
      border-t-transparent text-primary
      shadow-[inset_0_0_0_2px]
      before:absolute before:-bottom-[2px] before:left-0 before:block before:h-[6px]
      before:w-[6px] before:rotate-[45deg] before:border-b-[2px]
      before:border-l-[2px] before:border-primary before:content-[''];
}
</style>
