<template>
  <section class="grid min-h-screen place-items-center py-8">
    <div>
      <h1 class="text-5xl">{{ t('checkMail') }}</h1>
      <CVButton :is-loading="isPending" @click="onResendClicked">
        {{ t('resendConfirmationMail') }}
      </CVButton>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useResendConfirmEmail } from '@/queries/auth/use-confirm-email';

const { t } = useI18n();

const { mutateAsync: resendEmail, isPending } = useResendConfirmEmail();

const onResendClicked = () => {
  resendEmail();
};
</script>

<i18n lang="json">
{
  "en": {
    "checkMail": "Check your Mailbox",
    "resendConfirmationMail": "Resend confirmation email"
  },
  "de": {
    "checkMail": "Überprüfen Sie Ihren Posteingang",
    "resendConfirmationMail": "Bestätigungsmail erneut senden"
  }
}
</i18n>
