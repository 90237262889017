<template>
  <form @submit="onPasswordReset">
    <InputField name="email" :label="t('email')" autocomplete="email" type="email" />
    <div class="mt-6 flex items-center justify-between">
      <button class="btn-ghost btn-sm" type="button" @click="emit('onSwitchToLogin')">
        {{ t('back') }}
      </button>
      <CVButton type="submit" :is-loading="isLoading">
        {{ t('sendPasswordResetMail') }}
      </CVButton>
    </div>
  </form>
</template>

<script setup lang="ts">
import InputField from '@/components/InputField.vue';
import type { SendPasswordResetMailDto } from '@/entities/auth/send-password-reset-mail.dto';
import { useI18n } from 'vue-i18n';
import { sendPasswordResetSchema } from '@/validation/send-password-reset.schema';
import { useForm } from 'vee-validate';

const { t } = useI18n();

defineProps<{
  isLoading: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSendPasswordSubmit', value: SendPasswordResetMailDto): void;
  (e: 'onSwitchToLogin'): void;
}>();

const { handleSubmit: handlePasswordResetSubmit, resetForm: resetPasswordResetForm } =
  useForm<SendPasswordResetMailDto>({
    validationSchema: sendPasswordResetSchema,
  });

const onPasswordReset = handlePasswordResetSubmit(async (values) => {
  emit('onSendPasswordSubmit', values);
});

defineExpose({
  resetPasswordResetForm,
});
</script>

<i18n lang="json">
{
  "en": {
    "email": "Email",
    "sendPasswordResetMail": "Send Password Reset Mail",
    "login": "Login"
  },
  "de": {
    "email": "Email",
    "sendPasswordResetMail": "Passwort zurücksetzen",
    "login": "Anmelden"
  }
}
</i18n>
