<template>
  <h2>{{ t('resultingDamages') }}</h2>
  <div v-if="damages" class="relative mt-4">
    <div v-for="(damage, i) in damages" :key="i" class="relative border-b border-primary/40">
      <div class="grid grid-cols-8 items-center gap-4 pb-2 pt-2">
        <div>
          {{ formatDate(damage.createdDate, dateFormat) }}
        </div>
        <div class="col-span-5">
          {{ damage.description }}
        </div>
        <div class="col-span-2 flex justify-end">
          <CVButton class="btn-sm" outline @click.prevent="viewDamage(damage)">{{
            t('viewDamage')
          }}</CVButton>
        </div>
      </div>
    </div>
  </div>

  <EntityListExceptions
    :status="status"
    :error="error"
    :items-count="damages?.length ?? 0"
    :no-items-text="t('noDamagesFound')"
    small
  />

  <div class="mt-5 flex justify-end gap-4">
    <CVButton
      v-if="booking.hasUnhandledDamages"
      outline
      :is-loading="isSaving"
      @click.prevent="allDamagesHandled"
      >{{ t('allDamagesHandled') }}</CVButton
    >
    <CVButton
      :disabled="isSaving"
      @click.prevent="
        $router.push({
          name: 'bookingEditDamages',
          params: { id: props.booking.id },
          query: { tab: 'NEW' },
        })
      "
      >{{ t('editDamages') }}</CVButton
    >
  </div>

  <vue-final-modal
    v-model="showModal"
    class="flex items-center justify-center"
    content-class="modal-box p-0 w-11/12 max-w-5xl h-max"
    content-transition="vfm-fade"
    overlay-transition="vfm-fade"
  >
    <DamageViewer v-if="viewedDamage" :damage="viewedDamage" />
  </vue-final-modal>
</template>

<script lang="ts" setup>
import type { Damage } from '@/entities/damage.entity';
import { formatDate } from '@/hooks/use-formatted-date';
import { useI18n } from 'vue-i18n';
import { useDamages } from '@/queries/use-damages';
import { ref } from 'vue';
import DamageViewer from './DamageViewer.vue';
import EntityListExceptions from '@/components/EntityListExceptions.vue';
import { Alert } from '@/utils/alert';
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';

const props = defineProps<{
  booking: Booking;
  isSaving: boolean;
}>();

const emit = defineEmits<{
  (
    e: 'update-booking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished?: () => void,
  ): void;
}>();

const { t } = useI18n();

const dateFormat = 'dd.MM.yy';
const showModal = ref(false);
const viewedDamage = ref<Damage>();

const { data: damages, status, error } = useDamages({ bookingIds: props.booking.id });

const viewDamage = (damage: Damage) => {
  viewedDamage.value = damage;
  showModal.value = true;
};

const allDamagesHandled = async () => {
  const { isConfirmed } = await Alert.fire({
    title: t('allDamagesHandledTitle'),
    text: t('allDamagesHandledText'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('yes'),
    cancelButtonText: t('no'),
  });

  if (!isConfirmed) return;
  emit('update-booking', {
    id: props.booking.id,
    booking: { hasUnhandledDamages: false },
  });
};
</script>

<i18n lang="json">
{
  "en": {
    "resultingDamages": "Resulting Damages",
    "viewDamage": "View Damages",
    "noDamagesFound": "This booking has no damages",
    "editDamages": "Edit Damages",
    "allDamagesHandled": "All damages handled",
    "allDamagesHandledTitle": "Are all damages handled?",
    "allDamagesHandledText": "Please make sure, that all damages are handled (invoiced, paid, etc.) before you continue. Do you want to continue?"
  },
  "de": {
    "resultingDamages": "Entstandene Schäden",
    "viewDamage": "Schaden ansehen",
    "noDamagesFound": "Dieser Buchung sind keine Schäden zugeordnet",
    "editDamages": "Schäden bearbeiten",
    "allDamagesHandled": "Alle Schäden bearbeitet",
    "allDamagesHandledTitle": "Sind alle Schäden bearbeitet?",
    "allDamagesHandledText": "Bitte stelle sicher, dass alle Schäden bearbeitet sind (abgerechnet, bezahlt, etc.) bevor du fortfährst. Möchtest du fortfahren?"
  }
}
</i18n>
