import type { VehicleExtraType } from '@/entities/vehicle-extra-type.enum';
import {
  type CreatePricingDto,
  PriceRecalculationMode,
  type Pricing,
} from '@/entities/pricing.entity';
import { Alert } from '@/utils/alert';
import { createI18n } from 'vue-i18n';
import { i18n } from '@/i18n';
import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import type { CustomBookedExtra } from '@/entities/bookings/booked-extra';
import { useCreatePricing } from '@/queries/use-pricing';
import type { Car } from '@/entities/cars/car.entity';
import type { InsuranceCase } from '@/entities/bookings/booking.entity';
import type { LocationDto } from '@/entities/station/station.entity';

export type ChangedBookedExtraTypes = Partial<
  Record<
    VehicleExtraType,
    {
      vehicleExtraId: string;
      vehicleExtraOptionId: string;
    }
  >
>;

export interface RecalculatePriceParams {
  changedBookedExtraTypes?: ChangedBookedExtraTypes;
  customBookedExtras?: CustomBookedExtra[];
  transferDeliveryLocation?: LocationDto | null;
  transferReturnLocation?: LocationDto | null;
  customDeposit?: number | null;
  customPricePerExtraKm?: number | null;
  customInclusiveKm?: number | null;
  showAlert?: boolean;
  nullExtrasOnConfirm?: boolean;
  priceRecalculationMode?: PriceRecalculationMode;
  isConfirmedFunction?: (pricing: Pricing) => void;
  isDeniedFunction?: () => void;
}

/**
 * Returns a function that can be used to calculate a new pricing
 * @param formValues form values object that includes all values, that are relevant for the price calculation
 * @param oldPriceCalculation The price calculation that is currently saved in bookings
 * @param requestPricing API request function, that returns the new pricing
 * @param recalculatingPrice Will be set to true for the duration of the price API request
 * @param totalRentalDays total duration of days, the price calculation will be based on
 */
export const useRecalculatePrice = (
  formValues: {
    startDate: string;
    endDate: string;
    car: Car;
    priceCalculation: Pricing;
  },
  oldPriceCalculation: MaybeRefOrGetter<Pricing>,
  insuranceCase: MaybeRefOrGetter<InsuranceCase | null | undefined>,
) => {
  const { t } = createI18n({
    legacy: false,
    locale: toValue(i18n.global.locale),
    fallbackLocale: 'en',
    globalInjection: true,
    fallbackWarn: false,
    missingWarn: false,
    messages: localization,
  }).global;

  const { mutateAsync: requestPricing, isPending: recalculatingPrice } = useCreatePricing();

  /**
   * Requests a price calculation for a specific booking and its booked extras
   * @param changedBookedExtraTypes List of extra types to be calculated and therfore filtered from bookedExtrasMap
   * @param showAlert If true, an alert will be shown, that asks for confirmation to use the calculated price
   * @param nullExtrasOnConfirm If true, all booked extras will be removed from the booking
   * @param isConfirmedFunction Will be exectuted, if new price gets confirmed
   * @param isDeniedFunction Will be executed, if new price gets denied
   */
  const recalculatePrice = async ({
    changedBookedExtraTypes,
    showAlert = true,
    nullExtrasOnConfirm = true,
    isConfirmedFunction,
    isDeniedFunction,
    customBookedExtras,
    transferDeliveryLocation,
    transferReturnLocation,
    customDeposit,
    customPricePerExtraKm,
    customInclusiveKm,
    priceRecalculationMode,
  }: RecalculatePriceParams) => {
    const oldPriceCalculationUnref = toValue(oldPriceCalculation);
    const requestPricingValues: CreatePricingDto = {
      carId: formValues.car.id,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
      oldPricingId: !nullExtrasOnConfirm ? oldPriceCalculationUnref.id : undefined,
      bookedExtras: nullExtrasOnConfirm ? [] : Object.values(changedBookedExtraTypes ?? {}),
      customBookedExtras:
        nullExtrasOnConfirm && !customBookedExtras
          ? toValue(oldPriceCalculation).customBookedExtras
          : customBookedExtras,
      insuranceCaseId: toValue(insuranceCase)?.id,
      transferReturnLocation,
      transferDeliveryLocation,
      customDeposit,
      customPricePerExtraKm,
      customInclusiveKm,
      priceRecalculationMode,
    };
    const pricing = await requestPricing(requestPricingValues);

    if (!showAlert) {
      formValues.priceCalculation = pricing;
      return isConfirmedFunction?.(pricing);
    }

    const newInclusiveKm = pricing.inclusiveKm;
    const oldInclusiveKm = formValues.priceCalculation.inclusiveKm;
    const { isConfirmed } = await Alert.fire({
      titleText: t('newPriceTitle'),
      html: `
        <p>
          ${t('newTotalPrice')}:
          ${oldPriceCalculationUnref.totalPrice} €
          -> <strong>${pricing.totalPrice} €</strong>
        </p>
        <p>
          ${t('newInclusiveKm')}:
          ${oldInclusiveKm} km
          -> <strong>${newInclusiveKm} km</strong>
        </p>
        ${nullExtrasOnConfirm ? t('extrasReset') : ''}`,
      icon: 'info',
      showDenyButton: true,
      confirmButtonText: t('acceptNewPrice'),
      denyButtonText: t('keepOldPrice'),
    });

    if (!isConfirmed) {
      return isDeniedFunction?.();
    }

    formValues.priceCalculation = pricing;
    isConfirmedFunction?.(pricing);
  };

  return {
    recalculatePrice,
    recalculatingPrice,
  };
};

const localization = {
  en: {
    acceptNewPrice: 'Accept new price',
    keepOldPrice: 'Continue with old price',
    newPriceTitle: 'Price calculated',
    newPriceDescription: 'New total price',
    newInclusiveKm: 'New Incl.-Kilometers',
    extrasReset: '<p>With this price/km-update all extras will be switched to default.</p>',
  },
  de: {
    acceptNewPrice: 'Neuen Preis akzeptieren',
    keepOldPrice: 'Weiter mit altem Preis',
    newPriceTitle: 'Preis berechnet',
    newTotalPrice: 'Neuer Gesamtpreis',
    newInclusiveKm: 'Neue Inkl.-Kilometer',
    extrasReset: '<p>Mit dem Preis-/Kilometer-Update werden alle Extras zurückgesetzt.</p>',
  },
};
