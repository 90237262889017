<template>
  <div class="space-y-4">
    <p class="text-2xl font-medium">
      {{ t('enterTuvAppointmentDate') }}
    </p>

    <div class="grid grid-cols-3 gap-4">
      <LabeledText :label="t('lastTuvDate')">
        {{ formattedLastTuvDate }}
      </LabeledText>
      <DatepickerField
        class="col-start-1"
        name="lastTuvDate"
        :label="t('setTuvAppointmentDate')"
        enable-time-picker
      />
    </div>

    <div class="h-52"></div>

    <Divider />

    <div class="flex justify-end">
      <CVButton :is-loading="isUpdatingCar" @click.prevent="submit">
        {{ t('enterAppointmentDate') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Car } from '@/entities/cars/car.entity';
import { getDateFormat } from '@/hooks/use-date-format';
import { useFormattedDate } from '@/hooks/use-formatted-date';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import Divider from '@/components/Divider.vue';
import { useForm } from 'vee-validate';
import { useUpdateCar } from '@/queries/use-cars';
import * as yup from 'yup';
import { toTypedSchema } from '@vee-validate/yup';
import { dateOrStringRequired } from '@/validation/car.schema';
import DatepickerField from '@/components/DatepickerField.vue';
import LabeledText from '@/components/LabeledText.vue';

const props = defineProps<{
  car: Car;
}>();

const emit = defineEmits<{
  (e: 'complete'): void;
}>();

const { t } = useI18n();

const formattedLastTuvDate = useFormattedDate(
  computed(() => props.car.lastTuvDate),
  getDateFormat(),
  '-',
);

const { mutateAsync: updateCar, isPending: isUpdatingCar } = useUpdateCar();

const { handleSubmit } = useForm({
  initialValues: {
    lastTuvDate: undefined,
  },
  validationSchema: toTypedSchema(
    yup.object({
      lastTuvDate: dateOrStringRequired(),
    }),
  ),
});

const submit = handleSubmit(async (values) => {
  await updateCar({
    id: props.car.id,
    car: values,
  });
  emit('complete');
});
</script>

<i18n lang="json">
{
  "en": {
    "enterTuvAppointmentDate": "Enter TÜV appointment date",
    "lastTuvDate": "Last TÜV date",
    "setTuvAppointmentDate": "Set TÜV appointment date",
    "enterAppointmentDate": "Enter appointment date"
  },
  "de": {
    "enterTuvAppointmentDate": "TÜV-Termin eintragen",
    "lastTuvDate": "Letzter TÜV-Termin",
    "setTuvAppointmentDate": "TÜV-Termin auswählen",
    "enterAppointmentDate": "Termin eintragen"
  }
}
</i18n>
