<template>
  <div>
    <div class="flex justify-between">
      <h2>{{ t('customContractCheckboxes') }}</h2>
      <CVButton @click.prevent="add">
        {{ t('addCheckbox') }}
      </CVButton>
    </div>

    <div class="mb-8 mt-4 border border-primary pb-1 pl-4 pr-4 pt-1">
      <div
        v-for="(checkbox, i) in fields"
        :key="i"
        class="relative flex border-primary py-3"
        :class="{ 'border-b': i < fields.length - 1 }"
      >
        <div class="grid flex-1 grid-cols-1 gap-4">
          <TextareaField
            :name="`${name}[${i}].label.de`"
            :initial-value="checkbox.value.label.de"
            :label="t('labelGerman')"
            rows="1"
          />
          <TextareaField
            :name="`${name}[${i}].label.en`"
            :initial-value="checkbox.value.label.en"
            :label="t('labelEnglish')"
            rows="1"
          />
        </div>
        <div class="cursor-pointer p-2 text-lg leading-3 text-red-600" @click="remove(i)">×</div>
      </div>
      <div v-if="!fields.length" class="flex justify-center p-4">
        {{ t('noEntries') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useFieldArray } from 'vee-validate';
import TextareaField from '../TextareaField.vue';
import type { LocalizedField } from '@/entities/localized-field';

const props = defineProps<{
  name: string;
}>();

const { t } = useI18n();

const { remove, push, fields } = useFieldArray<{
  label: LocalizedField;
}>(props.name);

const add = () => {
  push({ label: { de: '', en: '' } });
};
</script>

<i18n lang="json">
{
  "en": {
    "customContractCheckboxes": "Custom Contract Checkboxes",
    "addCheckbox": "+ Add Checkbox",
    "labelGerman": "Label (German)",
    "labelEnglish": "Label (English)",
    "noEntries": "No entries"
  },
  "de": {
    "customContractCheckboxes": "Benutzerdefinierte Vertrags-Checkboxen",
    "addCheckbox": "+ Checkbox hinzufügen",
    "labelGerman": "Label (Deutsch)",
    "labelEnglish": "Label (Englisch)",
    "noEntries": "Keine Einträge"
  }
}
</i18n>
