import { carsService } from '@/api/cars.service';
import { CarStatus } from '@/entities/cars/car-status.enum';
import type { MiddlewareContext } from '../middleware-context.interface';

export default async function carStatusRedirectToCompleteFleetIn({ to, next }: MiddlewareContext) {
  const carId = to.params.id as string;
  const car = await carsService.getOne(carId);
  if (car.status === CarStatus.ORDERED) {
    return next({
      name: 'completeCarFleetIn',
      params: { id: carId },
    });
  }
  return next();
}
