<template>
  <FileInput v-model="value" :hint="error" :is-error="!!error" />
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { toRef } from 'vue';
import FileInput from './FileInput.vue';

const props = defineProps<{
  name: string;
  initialValue?: File | null;
  isError?: boolean;
}>();

const name = toRef(props, 'name');

const { errorMessage: error, value } = useField(name, undefined, {
  initialValue: props.initialValue,
});
</script>
