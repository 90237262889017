<template>
  <form class="flex flex-col space-y-2" @submit="onLogin">
    <div class="flex flex-col space-y-4">
      <InputField
        name="email"
        :label="t('email')"
        autocomplete="email"
        type="email"
        height="h-10"
      />

      <InputField
        name="password"
        :label="t('password')"
        type="password"
        autocomplete="current-password"
        height="h-10"
      />
    </div>

    <div class="mb-2 text-right">
      <button type="button" class="btn btn-link btn-xs" @click="emit('onSwitchToPasswordForgot')">
        {{ t('forgotPassword') }}
      </button>
    </div>

    <CVButton type="submit" class="w-full" :is-loading="isLoading">
      {{ t('login') }}
    </CVButton>
    <CVButton type="button" class="w-full" @click="onLoginWithGoogle">
      <GoogleIcon class="h-4 w-4" />
      {{ t('loginWithGoogle') }}
    </CVButton>
  </form>
</template>

<script setup lang="ts">
import InputField from '@/components/InputField.vue';
import type { LoginDto } from '@/entities/auth/login-dto.entity';
import { useI18n } from 'vue-i18n';
import { loginSchema } from '@/validation/login.schema';
import { useForm } from 'vee-validate';
import { authService } from '@/api/auth.service';
import GoogleIcon from '@/assets/icons/google.svg?component';

defineProps<{
  isLoading: boolean;
}>();

const emit = defineEmits<{
  (e: 'onLogin', value: LoginDto): void;
  (e: 'onSwitchToPasswordForgot'): void;
}>();
const { t } = useI18n();

const { handleSubmit: handleLoginSubmit, resetForm: resetLoginForm } = useForm<LoginDto>({
  validationSchema: loginSchema,
});

const onLogin = handleLoginSubmit(async (values) => {
  emit('onLogin', values);
});

const onLoginWithGoogle = () => {
  authService.startGoogleLogin();
};

defineExpose({
  resetLoginForm,
});
</script>

<i18n lang="json">
{
  "en": {
    "email": "Email",
    "password": "Password",
    "needAccount": "Need an account?",
    "signupHere": "Sign up here",
    "forgotPassword": "Forgot Password?",
    "login": "Login",
    "loginWithGoogle": "Login with Google"
  },
  "de": {
    "email": "E-Mail",
    "password": "Passwort",
    "needAccount": "Benötigen Sie ein Konto?",
    "signupHere": "Hier registrieren",
    "forgotPassword": "Passwort vergessen?",
    "login": "Anmelden",
    "loginWithGoogle": "Mit Google anmelden"
  }
}
</i18n>
