<template>
  <HeaderBar />
  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
  </ButtonBackContainer>

  <section class="carvia-container">
    <CompleteFleetInForm
      v-if="car && car.status === CarStatus.ORDERED"
      :car="car"
      :is-saving="isSaving"
      @submit="submit"
    />

    <div v-else class="flex justify-center">
      <Spinner />
    </div>
  </section>
</template>

<script lang="ts" setup>
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useCar, useUpdateCar } from '@/queries/use-cars';
import type { CompleteFleetInFormValues } from './CompleteFleetInForm.vue';
import CompleteFleetInForm from './CompleteFleetInForm.vue';
import { useUiStore } from '@/stores/ui.store';
import { CarStatus } from '@/entities/cars/car-status.enum';
import { CarDocumentType } from '@/entities/cars/car-document-type.enum';
import { computed } from 'vue';
import { useUploadAndCreateCarDocument } from '@/hooks/use-upload-and-create-car-document';

const { t } = useI18n();
const route = useRoute();
const carId = route.params.id as string;
const uiStore = useUiStore();
const router = useRouter();

uiStore.setHeaderTitle(t('completeFleetIn'));

const { data: car } = useCar(carId);
const { uploadAndCreateCarDocument, isPending: isUploadingImage } = useUploadAndCreateCarDocument();
const { mutateAsync: updateCar, isPending: isUpdatingCar } = useUpdateCar();

const isSaving = computed(() => isUploadingImage.value || isUpdatingCar.value);

const submit = async (values: CompleteFleetInFormValues) => {
  const { initialMilage, frontImage, backImage } = values;

  await updateCar({
    id: carId,
    car: {
      initialMilage,
      milage: initialMilage,
      status: CarStatus.FLOAT_IN,
    },
  });

  await uploadAndCreateCarDocument(carId, frontImage, CarDocumentType.CAR_IMAGE, 'Frontansicht');
  await uploadAndCreateCarDocument(carId, backImage, CarDocumentType.CAR_IMAGE, 'Heckansicht');

  router.replace({
    name: 'editCarStatus',
    params: { id: carId, autoEnterMilage: 'auto-enter-milage' },
  });
};
</script>

<i18n lang="json">
{
  "en": {
    "completeFleetIn": "Complete fleet in"
  },
  "de": {
    "completeFleetIn": "Einflottung abschließen"
  }
}
</i18n>
