<template>
  <div class="form-control w-full">
    <Datepicker
      v-bind="$attrs"
      v-model="inputValue"
      :format="dateFormat"
      :enable-time-picker="!!enableTimePicker"
      :label="label"
      :tooltip="tooltip"
      :is-error="!!error"
      :hint="error"
    />
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { toRef, watch } from 'vue';
import Datepicker from './Datepicker.vue';
import { useDateFormat, useDateTimeFormat } from '@/hooks/use-date-format';

const props = defineProps<{
  label?: string;
  hint?: string;
  name: string;
  initialValue?: string | Date | null;
  modelValue?: string | Date | null;
  enableTimePicker?: boolean;
  tooltip?: string;
}>();

const emit = defineEmits(['update:modelValue']);

const name = toRef(props, 'name');
const modelValue = toRef(props, 'modelValue');

const dateFormat = props.enableTimePicker ? useDateTimeFormat() : useDateFormat();

const { value: inputValue, errorMessage: error } = useField(name, undefined, {
  initialValue: props.initialValue,
});

if (modelValue.value !== undefined) {
  inputValue.value = modelValue.value ? modelValue.value : inputValue.value;
}
watch(modelValue, () => (inputValue.value = modelValue.value ?? null));
watch(inputValue, () => emit('update:modelValue', inputValue.value));
</script>
