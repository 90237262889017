import { useInfiniteQuery } from '@tanstack/vue-query';
import { type MaybeRefOrGetter, toRef, toValue } from '@vueuse/core';
import type { OffersControllerGetOffersData } from '@carvia/ros-client-types';
import { offersService } from '@/api/offers.service';

const QUERY_KEY = 'offers';

export const useOffers = (
  query: MaybeRefOrGetter<OffersControllerGetOffersData['query']>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY, toRef(query)],
    queryFn: ({ pageParam = 1 }) => offersService.getOffers({ ...toValue(query), page: pageParam }),
    enabled: toRef(enabled),
    getNextPageParam: (lastPage) => lastPage.meta.nextPage,
    initialPageParam: 1,
  });
};
