<template>
  <div class="flex items-end justify-between">
    <div class="flex items-end gap-4">
      <InputField
        :name="`extraDeductible.options.${idx}.value`"
        :label="t('deductibleAmount')"
        type="number"
        adornment="€"
      />
      <CVButton v-if="idx" variant="error" outline @click.prevent="$emit('removeOption')">{{
        t('removeOption')
      }}</CVButton>
    </div>
    <CVButton @click.prevent="push({ fromDay: null, pricePerDay: null })">{{
      t('addPriceStep')
    }}</CVButton>
  </div>
  <ValuePairsListField
    hide-head
    :value-pairs="valuePairs"
    :name="`extraDeductible.options.${idx}.pricePerDay`"
    :value-names="['fromDay', 'pricePerDay']"
    :labels="labels"
    :save-numbers-as-string="[false, false]"
    @remove-value-pair="remove"
  />
</template>

<script lang="ts" setup>
import type { Labels, ValuePairs } from '../ValuePairsListField.vue';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useFieldArray } from 'vee-validate';
import type {
  PricePerDayFormValues,
  VehicleExtraOptionFormValues,
} from '@/entities/vehicle-extra.entity';
import InputField from '@/components/InputField.vue';
import ValuePairsListField from '../ValuePairsListField.vue';
import CVButton from '../buttons/CVButton.vue';

const props = defineProps<{
  idx: number;
  option: VehicleExtraOptionFormValues;
}>();

defineEmits<{
  (e: 'removeOption'): void;
}>();

const { t } = useI18n();

const labels = computed<Labels>(() => [
  {
    label: t('fromDay'),
  },
  {
    label: t('pricePerDay'),
    adornment: '€',
  },
]);

const { remove, push, fields } = useFieldArray<PricePerDayFormValues>(
  `extraDeductible.options[${props.idx}].pricePerDay`,
);

if (fields.value.length === 0) {
  push({
    fromDay: 1,
    pricePerDay: null,
  });
}

const valuePairs = computed<ValuePairs>(
  () => fields.value.map((field) => [field.value.fromDay ?? 0, field.value.pricePerDay ?? 0]) ?? [],
);
</script>

<i18n lang="json">
{
  "en": {
    "addInclusiveExtraKmStep": "+ Add Extra Kilometers Step",
    "fromDay": "From Day",
    "pricePerDay": "Price / Day",
    "deductibleAmount": "Deductible Amount",
    "addPriceStep": "+ Add Price Step",
    "removeOption": "Remove Option"
  },
  "de": {
    "addInclusiveExtraKmStep": "+ Extrakilometerstaffelung hinzufügen",
    "fromDay": "Ab Tag",
    "pricePerDay": "Preis / Tag",
    "deductibleAmount": "SB Höhe",
    "addPriceStep": "+ Preisstaffelung hinzufügen",
    "removeOption": "Option löschen"
  }
}
</i18n>
