<template>
  <form @submit="onSubmit">
    <h2>{{ t('basicInfo') }}</h2>
    <div class="mt-4 grid grid-cols-3 gap-4">
      <div class="grid grid-cols-1 gap-2">
        <SelectField name="makeId" :label="t('make')" :options="makeOptions" />

        <InputField name="model" :label="t('model')" />

        <MultiSelectField
          name="categories"
          value-prop="value"
          mode="tags"
          :label="t('categories')"
          :options="categories"
        />

        <SelectField name="mainCategory" :label="t('mainCategory')" :options="chosenCategories" />
      </div>

      <div class="col-span-2 grid grid-cols-6 gap-4">
        <img
          v-if="imageUrl"
          class="col-span-4 mb-3 self-center"
          :src="imageUrl"
          :width="600"
          :height="300"
        />
        <div
          v-else
          class="col-span-4 flex h-52 w-full items-center justify-center self-center border border-primary/40 text-primary"
        >
          {{ t('noImage') }}
        </div>
        <div class="col-span-2 space-y-2 self-center">
          <CVButton outline class="w-full" @click.prevent="showUploadImageModal = true">
            {{ t('changeImage') }}
          </CVButton>
          <CVButton
            v-if="formValues.imageFile"
            outline
            variant="error"
            class="w-full"
            @click.prevent="cancelImageChange"
          >
            {{ t('resetImage') }}
          </CVButton>
        </div>
      </div>
    </div>

    <Divider />

    <h2>{{ t('dataForPriceCalculation') }}</h2>

    <div class="mt-4 grid grid-cols-3 gap-4">
      <InputField
        v-if="user?.role === UserRole.ADMIN"
        :min="0"
        name="resellPrice"
        :label="t('purchasePrice')"
        adornment="€"
        :max-fraction-digits="2"
        type="number"
        :tooltip="t('purchasePriceInfo')"
      />

      <InputField
        v-if="user?.role === UserRole.ADMIN"
        :min="0"
        name="basePrice"
        :label="t('basePrice')"
        adornment="€"
        :max-fraction-digits="2"
        type="number"
        :hint="t('basePriceHint')"
      />

      <InputField :min="0" name="deposit" :label="t('deposit')" adornment="€" type="number" />
    </div>

    <Discount :initial-vehicle-type="initialVehicleType" class="mt-4" />

    <Divider />

    <h2>{{ t('technicalData') }}</h2>

    <div class="mb-4 mt-4 grid grid-cols-3 gap-4">
      <SelectField name="fuelType" :label="t('fuelType')" :options="fuelTypes" />

      <SelectField name="transmission" :label="t('transmission')" :options="transmissionTypes" />

      <SelectField name="drive" :label="t('drive')" :options="driveTypes" />

      <InputField
        v-if="formValues.fuelType === FuelType.ELECTRIC"
        name="batteryCapacityInKwh"
        :label="t('batteryCapacity')"
        adornment="kWh"
        type="number"
        :min="0"
        empty-value-to="null"
      />

      <InputField
        v-else
        :min="0"
        name="tankVolumeInLiter"
        :label="t('tankVolume')"
        adornment="l"
        type="number"
        empty-value-to="null"
      />

      <InputField
        v-if="formValues.fuelType === FuelType.ELECTRIC"
        :min="0"
        name="rangeInKm"
        :label="t('range')"
        adornment="km"
        type="number"
      />

      <InputField type="text" name="seats" :label="t('seats')" save-number-as-string />

      <InputField :min="0" name="power" :label="t('power')" adornment="PS" type="number" />

      <InputField
        :min="0"
        name="accelaration"
        :label="t('accelaration')"
        adornment="s"
        :max-fraction-digits="2"
        type="number"
      />
    </div>

    <CheckboxField
      name="isAlwaysOnWinterTyres"
      :initial-value="initialValues.isAlwaysOnWinterTyres"
      :label="t('alwaysOnWinterTyres')"
    />

    <Divider />

    <h2>{{ t('features') }}</h2>

    <div class="mt-4 grid grid-cols-3 gap-4">
      <CheckboxField
        name="hasNavigation"
        :initial-value="initialValues.hasNavigation"
        :label="t('gpsNavigation')"
      />

      <CheckboxField
        name="hasDistanceControl"
        :initial-value="initialValues.hasDistanceControl"
        :label="t('distanceControl')"
      />

      <CheckboxField
        name="hasRearViewCamera"
        :initial-value="initialValues.hasRearViewCamera"
        :label="t('rearViewCamera')"
      />
    </div>

    <Divider />

    <h2>{{ t('more') }}</h2>

    <div class="mt-4 grid grid-cols-3 gap-4">
      <InputField
        :min="0"
        name="inspectionIntervalInKm"
        :label="t('inspectionInterval')"
        adornment="km"
        type="number"
      />

      <InputField :min="0" name="minAge" :label="t('minAge')" type="number" />

      <InputField
        :min="0"
        name="pricePerExtraKm"
        :label="t('pricePerExtraKm')"
        adornment="€"
        :max-fraction-digits="2"
        type="number"
      />
    </div>

    <Divider />

    <h2>{{ t('vehicleTypesForInsurances') }}</h2>

    <div class="mt-4 grid grid-cols-3 gap-4">
      <SelectField name="axaCategory" :label="t('axa')" :options="axaTypes" empty-value-to="null" />
    </div>

    <Divider />

    <CustomContractCheckboxes name="customContractCheckboxes" />

    <Divider />

    <InclusiveKmPerDay />

    <Divider />

    <ExtraKm />

    <Divider />

    <ExtraDeductible />

    <Divider />

    <div class="flex justify-end">
      <CVButton :is-loading="isSaving" type="submit" :disabled="isSaving">
        {{ t('save') }}
      </CVButton>
    </div>

    <SelectImageForUploadModal
      v-model:show-modal="showUploadImageModal"
      :image-file="formValues.imageFile"
      @select="onSelectImage"
    ></SelectImageForUploadModal>
  </form>
</template>

<script lang="ts" setup>
import CheckboxField from '@/components/CheckboxField.vue';
import Divider from '@/components/Divider.vue';
import InputField from '@/components/InputField.vue';
import MultiSelectField from '@/components/MultiSelectField.vue';
import ExtraKm from '@/components/vehicle-forms/ExtraKm.vue';
import InclusiveKmPerDay from '@/components/vehicle-forms/InclusiveKmPerDay.vue';
import { DriveType } from '@/entities/cars/drive-type.enum';
import { FuelType } from '@/entities/cars/fuel-type.enum';
import { TransmissionType } from '@/entities/cars/transmission-type.enum';
import { VehicleCategory } from '@/entities/vehicle-category.enum';
import { VehicleExtraType } from '@/entities/vehicle-extra-type.enum';
import type {
  VehicleExtraFormValues,
  VehicleExtraOptionFormValues,
} from '@/entities/vehicle-extra.entity';
import type { UpdateVehicleTypeDto, VehicleType } from '@/entities/vehicle-type.entity';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form';
import { useI18n } from 'vue-i18n';
import { useMakes } from '@/queries/use-makes';
import { vehicleTypeValidator } from '@/validation/vehicle-type.schema';
import { useField, useForm } from 'vee-validate';
import { computed, reactive, ref, watch } from 'vue';
import SelectField from '@/components/SelectField.vue';
import { useAuthStore } from '@/stores/auth.store';
import { UserRole } from '@/entities/auth/user-role.enum';
import { formHasChanged } from '@/hooks/use-form-has-changed';
import SelectImageForUploadModal from '@/components/SelectImageForUploadModal.vue';
import Discount from './Discount.vue';
import CustomContractCheckboxes from '@/components/vehicle-forms/CustomContractCheckboxes.vue';
import ExtraDeductible from '@/components/vehicle-forms/ExtraDeductible.vue';
import { AxaVehicleCategory } from '@carvia/ros-client-types';

export type VehicleTypeFormValues = Omit<UpdateVehicleTypeDto, 'inclusiveKm'> & {
  inclusiveKm: { fromDay: number | null; kmInclusivePerDay: number | null }[];
  extraKm: VehicleExtraFormValues;
  extraDeductible: VehicleExtraFormValues;
  overrideDeductibleExtra: boolean;
  imageFile: File | undefined;
};

const props = defineProps<{
  initialVehicleType?: VehicleType;
  isSaving: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSubmit', value: VehicleTypeFormValues): void;
}>();

const { t } = useI18n();
const { user } = useAuthStore();

const showUploadImageModal = ref(false);
const imageUrl = ref(props.initialVehicleType?.imageUrl);

const initialValues = reactive<VehicleTypeFormValues>({
  ...props.initialVehicleType,
  extraKm: getIntitialExtra(VehicleExtraType.EXTRA_KM),
  extraDeductible: getIntitialExtra(VehicleExtraType.DEDUCTIBLE),
  overrideDeductibleExtra: !!getIntitialExtra(VehicleExtraType.DEDUCTIBLE).id,
  availableExtraIds: props.initialVehicleType?.availableExtras.map((extra) => extra.id) ?? [],
  makeId: props.initialVehicleType?.make.id,
  imageFile: undefined,
  hasDistanceControl: props.initialVehicleType?.hasDistanceControl ?? false,
  hasNavigation: props.initialVehicleType?.hasNavigation ?? false,
  hasRearViewCamera: props.initialVehicleType?.hasRearViewCamera ?? false,
  inclusiveKm: props.initialVehicleType?.inclusiveKm ?? [{ fromDay: 1, kmInclusivePerDay: null }],
  discountFrom: props.initialVehicleType?.discountFrom ?? null,
  discountUntil: props.initialVehicleType?.discountUntil ?? null,
  discountFactor: props.initialVehicleType?.discountFactor ?? null,
  isAlwaysOnWinterTyres: props.initialVehicleType?.isAlwaysOnWinterTyres ?? false,
});

function getIntitialExtra(type: VehicleExtraType) {
  const extraName = type === VehicleExtraType.DEDUCTIBLE ? 'Deductible' : `Extra KM`;
  const extra = props.initialVehicleType?.availableExtras.find(
    (extra) => extra.type === type && !extra.autoGenerated,
  );
  const initialExtra = extra && {
    id: extra.id,
    type: extra.type,
    options: extra.options.map((option) => ({
      ...option,
      value: Number(option.value),
    })) as VehicleExtraOptionFormValues[],
  };
  return (
    initialExtra ?? {
      id: undefined,
      description: `${extraName} - ${props.initialVehicleType?.make.name} ${props.initialVehicleType?.model}`,
      type: type,
      options: [],
    }
  );
}

const fuelTypes = computed(() =>
  Object.values(FuelType).map((value) => ({
    label: t(`fuelTypes.${value}`),
    value,
  })),
);

const transmissionTypes = computed(() =>
  Object.values(TransmissionType).map((value) => ({
    label: t(`transmissionTypes.${value}`),
    value,
  })),
);

const driveTypes = computed(() =>
  Object.values(DriveType).map((value) => ({
    label: t(`driveTypes.${value}`),
    value,
  })),
);

const categories = computed(() =>
  Object.values(VehicleCategory).map((value) => ({
    label: t(`categories.${value}`),
    value,
  })),
);

const axaTypes = computed(() => [
  { label: t('noCategory'), value: null },
  ...Object.values(AxaVehicleCategory).map((value) => ({
    label: value,
    value,
  })),
]);

const chosenCategories = computed(
  () =>
    formValues.categories?.map((value) => ({
      label: t(`categories.${value}`),
      value,
      tooltip: t(`categories.${value}`),
    })) ?? [],
);

const { data: makes } = useMakes();

const makeOptions = computed(() => makes.value?.map((m) => ({ label: m.name, value: m.id })) ?? []);

const {
  handleSubmit,
  values: formValues,
  setFieldValue,
} = useForm<VehicleTypeFormValues>({
  initialValues,
  validationSchema: vehicleTypeValidator,
});

const unchangedValues = ref({ ...formValues });

const { value: resellPrice } = useField<number>('resellPrice');
watch(resellPrice, (newPrice) => {
  setFieldValue('basePrice', Math.round((newPrice / 1.19) * 0.0025 * 100) / 100);
});

const onSelectImage = (newImageFile: File, newImageUrl: string) => {
  setFieldValue('imageFile', newImageFile);
  imageUrl.value = newImageUrl;
};

const cancelImageChange = () => {
  setFieldValue('imageFile', undefined);
  imageUrl.value = props.initialVehicleType?.imageUrl;
};

const onSubmit = handleSubmit(
  (values) => {
    values.extraKm.description = `Extra KM - ${
      makeOptions.value.find((make) => make.value === values.makeId)?.label
    } ${values.model}`;
    unchangedValues.value = { ...formValues };
    emit('onSubmit', values);
    console.log(values);
  },
  ({ errors }) => {
    console.log(errors);
  },
);

usePreventLeavingUnsavedForm(() => formHasChanged(unchangedValues.value, formValues));
</script>

<i18n lang="json">
{
  "en": {
    "basicInfo": "Basic Info",
    "make": "Make",
    "model": "Model",
    "categories": "Categories",
    "mainCategory": "Main Category",
    "changeImage": "Change Image",
    "dataForPriceCalculation": "Data for Price Calculation",
    "purchasePrice": "Purchase Price",
    "purchasePriceInfo": "The purchase price is the gross purchase price incl. discount",
    "basePrice": "Base Price",
    "deposit": "Deposit",
    "technicalData": "Technical Data",
    "fuelType": "Fuel Type",
    "drive": "Drive",
    "tankVolume": "Tank Volume",
    "batteryCapacity": "Battery Capacity",
    "range": "Range",
    "transmission": "Transmission",
    "seats": "Seats",
    "power": "Power",
    "accelaration": "Acceleration (0 - 100)",
    "features": "Features",
    "gpsNavigation": "GPS/Navigation",
    "distanceControl": "Distance Control ...",
    "rearViewCamera": "... incl. Rear View Camera",
    "more": "More",
    "minAge": "Minimal Age",
    "pricePerExtraKm": "Price per Extra KM",
    "backToOverview": "Back to Vehicle Types Overview",
    "noImage": "No Image",
    "basePriceHint": "Default: 0.25% of the net resell price",
    "resetImage": "Reset image",
    "inspectionInterval": "Inspection Interval",
    "alwaysOnWinterTyres": "Always on winter tyres (should never be changed to summer tyres)",
    "vehicleTypesForInsurances": "Vehicle types for partner insurances",
    "axa": "AXA",
    "noCategory": "- No category -"
  },
  "de": {
    "basicInfo": "Grundlegende Daten",
    "make": "Marke",
    "model": "Modell",
    "categories": "Kategorien",
    "mainCategory": "Hauptkategorie",
    "changeImage": "Bild ändern",
    "dataForPriceCalculation": "Daten zur Preisberechnung",
    "purchasePrice": "Einkaufspreis",
    "purchasePriceInfo": "Der Einkaufspreis ist der Brutto EK inkl. Nachlass",
    "basePrice": "Basispreis",
    "deposit": "Kaution",
    "technicalData": "Technische Daten",
    "fuelType": "Treibstofftyp",
    "drive": "Antrieb",
    "tankVolume": "Tankgröße",
    "batteryCapacity": "Batteriekapazität",
    "range": "Reichweite",
    "transmission": "Getriebe",
    "seats": "Sitze",
    "power": "Leistung",
    "accelaration": "Beschleunigung (0 - 100)",
    "features": "Ausstattung",
    "gpsNavigation": "GPS/Navigation",
    "distanceControl": "Parkabstandswarner ...",
    "rearViewCamera": "... inkl. Rückfahrkamera",
    "more": "Weiteres",
    "minAge": "Mindestalter",
    "pricePerExtraKm": "Zusatzkilometerpreis",
    "backToOverview": "Zurück zur Vehicle Types Übersicht",
    "noImage": "Kein Bild",
    "basePriceHint": "Standard: 0.25% vom Netto Einkaufspreis",
    "resetImage": "Bild zurücksetzen",
    "inspectionInterval": "Inspektionsintervall",
    "alwaysOnWinterTyres": "Immer auf Winterreifen (sollen nie auf Sommerreifen gewechselt werden)",
    "vehicleTypesForInsurances": "Fahrzeugtypen für Partnerversicherungen",
    "axa": "AXA",
    "noCategory": "- Keine Kategorie -"
  }
}
</i18n>
