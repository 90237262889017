<template>
  <div class="grid grid-cols-3">
    <VehicleStatus
      v-model:milage="formValues.milage"
      v-model:fuel-level="formValues.fuelLevel"
      :show-milage-not-updated="!booking.car.readyToHandover && !isMilageChanged"
      :show-fuel-level-not-updated="!booking.car.readyToHandover && !isFuelLevelChanged"
      :show-milage-warning="formValues.milage < booking.car.milage"
      class="col-span-2"
      :fuel-type="fueltype"
      @on-fuel-level-touched="isFuelLevelChanged = true"
    />
  </div>
  <Divider />

  <Damages :car-id="booking.car.id" />

  <div class="mt-5 flex justify-end">
    <ButtonNext :is-loading="isSaving" @click="submit">
      {{ hasChanged ? t('saveAndNext') : t('next') }}
    </ButtonNext>
  </div>
</template>

<script lang="ts" setup>
import Damages from '@/components/booking-forms/damages/Damages.vue';
import VehicleStatus from '@/components/booking-forms/VehicleStatus.vue';
import ButtonNext from '@/components/buttons/ButtonNext.vue';
import Divider from '@/components/Divider.vue';
import type { Booking } from '@/entities/bookings/booking.entity';
import type { CarStatusFormValues } from '@/entities/cars/car.entity';
import { formHasChanged } from '@/hooks/use-form-has-changed';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form';
import { useI18n } from 'vue-i18n';
import { cloneDeep } from 'lodash';
import { computed, reactive, ref, toRef, watch } from 'vue';

const { t } = useI18n();
const props = defineProps<{
  booking: Booking;
  isSaving?: boolean;
}>();

const booking = toRef(props, 'booking');
const fueltype = computed(
  () => props.booking.car.fuelType ?? props.booking.car.vehicleType.fuelType,
);

const emit = defineEmits<{
  (e: 'onSubmit', value: CarStatusFormValues): void;
}>();

const getInitialMilageValues = () => {
  return booking.value.car?.milage ?? 0;
};

const formValues: CarStatusFormValues = reactive({
  milage: getInitialMilageValues(),
  fuelLevel: booking.value.car?.fuelLevel ?? 1,
});

const isMilageChanged = computed(() => formValues.milage !== getInitialMilageValues());
const isFuelLevelChanged = ref(false);

const hasChanged = ref(false);
watch(formValues, async () => {
  hasChanged.value = true;
});

const unchangedValues = ref(cloneDeep(formValues));

const submit = async () => {
  unchangedValues.value = cloneDeep(formValues);
  emit('onSubmit', formValues);
};

usePreventLeavingUnsavedForm(() => formHasChanged(unchangedValues.value, formValues));
</script>

<i18n lang="json">
{
  "en": {
    "back": "back to customer overview"
  },
  "de": {
    "back": "Zurück zur Kundenübersicht"
  }
}
</i18n>
