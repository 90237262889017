<template>
  <div>
    <a :href="url" target="_blank" class="text-link">{{ t('carviaWorld') }}</a>
  </div>
</template>

<script lang="ts" setup>
import { UserRole } from '@/entities/auth/user-role.enum';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth.store';
import { computed } from 'vue';

const { t } = useI18n();

const authStore = useAuthStore();

const url = computed(() =>
  authStore.user?.role === UserRole.ADMIN || authStore.user?.role === UserRole.HUB_MANAGER
    ? 'https://www.notion.so/CarVia-World-874ad9b8c0414ec7b61236a84b7b95c1'
    : 'https://continuous-cricket-e4d.notion.site/CarVia-Wir-machen-das-eigene-Auto-berfl-ssig-874ad9b8c0414ec7b61236a84b7b95c1',
);
</script>

<i18n lang="json">
{
  "en": {
    "carviaWorld": "Carvia World"
  },
  "de": {
    "carviaWorld": "Carvia Welt"
  }
}
</i18n>
