import { useResendBookingConfirmation, useResendBookingContract } from '@/queries/use-bookings';
import { Alert } from '@/utils/alert';
import { useI18n } from 'vue-i18n';

export const useResendBookingMails = (bookingId: string) => {
  const { t } = useI18n({
    useScope: 'global',
    legacy: false,
    fallbackLocale: 'en',
    globalInjection: true,
    fallbackWarn: false,
    missingWarn: false,
    messages: localization,
  });

  const { mutateAsync: resendBookingConfirmation } = useResendBookingConfirmation();
  const { mutateAsync: resendBookingContract } = useResendBookingContract();

  const resendConfirmationEmail = async () => {
    const alertResult = await Alert.fire({
      titleText: t('reallySendConfirmationTitle'),
      text: t('reallySendConfirmationText'),
      icon: 'question',
      showDenyButton: true,
      confirmButtonText: t('sendConfirmation'),
      denyButtonText: t('cancel'),
    });
    if (alertResult.isConfirmed) resendBookingConfirmation(bookingId);
  };

  const resendContractEmail = async () => {
    const alertResult = await Alert.fire({
      titleText: t('reallySendContractTitle'),
      text: t('reallySendContractText'),
      icon: 'question',
      showDenyButton: true,
      confirmButtonText: t('sendContract'),
      denyButtonText: t('cancel'),
    });
    if (alertResult.isConfirmed) resendBookingContract(bookingId);
  };

  return {
    resendConfirmationEmail,
    resendContractEmail,
  };
};

const localization = {
  en: {
    reallySendContractTitle: 'Really send Contract?',
    reallySendContractText: 'Are you sure, you want to send this Confirmation to the Customer?',
    sendContract: 'Send Contract',
    reallySendConfirmationTitle: 'Really send Confirmation?',
    reallySendConfirmationText: 'Are you sure, you want to send this Confirmation to the Customer?',
    sendConfirmation: 'Send Confirmation',
  },
  de: {
    reallySendContractTitle: 'Vertrag wirklich versenden?',
    reallySendContractText:
      'Bist du sicher, dass du diesen Vertrag an den Kunden versenden möchtest?',
    sendContract: 'Vertrag verschicken',
    reallySendConfirmationTitle: 'Bestätigung wirklich versenden?',
    reallySendConfirmationText:
      'Bist du sicher, dass du diese Bestätigung an den Kunden versenden möchtest?',
    sendConfirmation: 'Bestätigung verschicken',
  },
};
