<template>
  <header class="relative px-8">
    <nav class="mx-auto flex h-full min-h-16 w-full max-w-5xl justify-between pb-2 pt-6">
      <div v-if="uiStore.headerTitle" class="flex items-center border-b border-primary py-1">
        <h1>
          {{ uiStore.headerTitle }}
        </h1>

        <div
          v-if="uiStore.subTitleLine1"
          class="ml-4 flex flex-col border-l-2 border-secondary pl-4"
        >
          <p class="font-medium leading-4">{{ uiStore.subTitleLine1 }}</p>
          <p class="font-normal">{{ uiStore.subTitleLine2 }}</p>
        </div>
      </div>
      <div v-else />

      <div class="ml-5 flex items-center">
        <div v-if="isStaging" class="mr-10 rounded-full bg-error px-3 py-1 text-white">
          {{ t('testEnvironment') }}
        </div>

        <div class="mt-1 hidden flex-grow justify-end md:flex">
          <img src="@/assets/carvia_logo_hazel.png" class="max-w-[130px] lg:max-w-[140px]" />
        </div>

        <LangSwitcher class="ml-3" />

        <label class="dropdown dropdown-end ml-5 outline-none" tabindex="0">
          <HeaderBarHamburger v-model:isActive="isActive" />
          <HeaderbarDropdown :class="{ hidden: !isActive }" />
        </label>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import { useUiStore } from '@/stores/ui.store';
import { ref } from 'vue';
import HeaderbarDropdown from './components/HeaderbarDropdown.vue';
import HeaderBarHamburger from './components/HeaderBarHamburger.vue';
import LangSwitcher from './components/LangSwitcher.vue';
import { useI18n } from 'vue-i18n';

const uiStore = useUiStore();

const { t } = useI18n();

const isStaging = import.meta.env.VITE_ENVIRONMENT === 'staging';

const isActive = ref(false);
</script>

<i18n lang="json">
{
  "en": {
    "testEnvironment": "Test environment"
  },
  "de": {
    "testEnvironment": "Testumgebung"
  }
}
</i18n>
