<template>
  <section class="grid min-h-screen place-items-center bg-accent">
    <div>
      <div
        class="card mx-auto w-[27rem] max-w-full space-y-6 overflow-hidden bg-white p-8 shadow-lg"
      >
        <div>
          <img
            src="@/assets/carvia_logo_hazel.png"
            alt="Logo"
            class="mx-auto mb-8 w-64 max-w-full"
          />
          <h1 class="text-center font-medium">
            {{ t('welcomeBack') }}
          </h1>
        </div>
        <div>
          <div v-if="isOAuthLoading">
            <Spinner class="mx-auto mb-4" />
            <div class="text-center text-gray-500">{{ t('loggingIn') }}</div>
          </div>
          <PasswordForgotForm
            v-else-if="forgotPasswordMode"
            ref="passwordForgotForm"
            :is-loading="sendingPasswordReset"
            @on-send-password-submit="onPasswordReset"
            @on-switch-to-login="forgotPasswordMode = false"
          />

          <LoginForm
            v-else
            ref="loginForm"
            :is-loading="isPending"
            @on-login="onLogin"
            @on-switch-to-password-forgot="forgotPasswordMode = true"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { LoginDto } from '@/entities/auth/login-dto.entity';
import type { SendPasswordResetMailDto } from '@/entities/auth/send-password-reset-mail.dto';
import { useI18n } from 'vue-i18n';
import { useLogin, useOAuthCallback } from '@/queries/auth/use-login';
import { useSendPasswordResetMail } from '@/queries/auth/use-password-reset';
import router from '@/router';
import { useAuthStore } from '@/stores/auth.store';
import { ref } from 'vue';
import LoginForm from './components/LoginForm.vue';
import PasswordForgotForm from './components/PasswordForgotForm.vue';
import { authService } from '@/api/auth.service';
import type { JwtTokenResponse } from '@/entities/auth/jwt-token-response.entity';
import { UserRole } from '@/entities/auth/user-role.enum';

const { t } = useI18n();
const { locale } = useI18n({ useScope: 'global' });
const authStore = useAuthStore();
const forgotPasswordMode = ref(false);
const loginForm = ref();
const passwordForgotForm = ref();
const { isPending, mutateAsync: login } = useLogin();
const { mutateAsync: sendPasswordReset, isPending: sendingPasswordReset } =
  useSendPasswordResetMail();

async function finalizeLogin(res: JwtTokenResponse) {
  const me = await authService.getMe();

  loginForm.value?.resetLoginForm();
  authStore.setAuthUser(res.user);
  authStore.setUser(me);
  res.user.isEmailConfirmed
    ? router.push({ name: 'home' })
    : router.push({ name: 'checkConfirmEmail' });
  if (res.user.role === UserRole.AGENT) {
    locale.value = 'en';
  }
}

const oauthCode = router.currentRoute.value.query?.code;
const isOauthCallback = typeof oauthCode === 'string';
const { mutateAsync: oauthCallback, isPending: isOAuthLoading } = useOAuthCallback();
if (isOauthCallback) {
  oauthCallback(window.location.search)
    .then((res) => finalizeLogin(res))
    .catch(() => {
      router.replace({ name: 'login' });
    });
}

const onLogin = async (values: LoginDto) => {
  const res = await login({
    email: values.email,
    password: values.password,
  });
  finalizeLogin(res);
};

const onPasswordReset = async (values: SendPasswordResetMailDto) => {
  await sendPasswordReset(values);
  passwordForgotForm.value.resetPasswordResetForm();
};
</script>

<i18n lang="json">
{
  "en": {
    "welcomeBack": "Welcome back",
    "loggingIn": "Driver's license please..."
  },
  "de": {
    "welcomeBack": "Willkommen zurück",
    "loggingIn": "Einmal den Führerschein bitte..."
  }
}
</i18n>
