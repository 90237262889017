export enum VehicleExtraType {
  EXTRA_KM = 'EXTRA_KM',
  ADDITIONAL_DRIVER = 'ADDITIONAL_DRIVER',
  CROSSBORDER = 'CROSSBORDER',
  DEDUCTIBLE = 'DEDUCTIBLE',
  YOUNG_DRIVER = 'YOUNG_DRIVER',
  WINDOW_AND_MOBILITY_INSURANCE = 'WINDOW_AND_MOBILITY_INSURANCE',
  TRANSFER_PICKUP = 'TRANSFER_PICKUP',
  TRANSFER_RETURN = 'TRANSFER_RETURN',
}
