<template>
  <h2>{{ t('otherConditions') }}</h2>
  <div class="mt-4 grid grid-cols-3 gap-4">
    <OverrideDefaultValueField
      name="customDeposit"
      :default-value="pricing.defaultDeposit"
      :formatted-default-value="formatCurrency(pricing.defaultDeposit)"
      type="number"
      :label="t('deposit')"
      :max-fraction-digits="2"
      :min="0"
      adornment="€"
      :disabled="!$can('create', subject('Pricing', pricing), 'customDeposit')"
    />
    <OverrideDefaultValueField
      name="customPricePerExtraKm"
      :default-value="pricing.defaultPricePerExtraKm"
      :formatted-default-value="formatCurrency(pricing.defaultPricePerExtraKm)"
      type="number"
      :label="t('pricePerExtraKm')"
      :max-fraction-digits="2"
      :min="0"
      adornment="€"
      :disabled="!$can('create', subject('Pricing', pricing), 'customPricePerExtraKm')"
    />
  </div>
  <div v-if="hasChanged" class="mt-4 flex justify-end gap-4">
    <CVButton variant="success" :is-loading="isSaving" @click.prevent="onSubmit">{{
      t('save')
    }}</CVButton>
    <CVButton variant="warning" :disabled="isSaving" @click.prevent="onCancel">{{
      t('cancel')
    }}</CVButton>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import OverrideDefaultValueField from '@/components/OverrideDefaultValueField.vue';
import type { UpdateBookingDto } from '@/entities/bookings/booking.entity';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import { ref, watch } from 'vue';
import { cloneDeep } from 'lodash';
import { useFormHasChanged } from '@/hooks/use-form-has-changed';
import { subject } from '@casl/ability';
import { PriceRecalculationMode, type Pricing } from '@/entities/pricing.entity';
import { formatCurrency } from '@/utils/format-numbers';
import type { RecalculatePriceParams } from '@/hooks/use-recalculate-price';

const { t } = useI18n();

const props = defineProps<{
  pricing: Pricing;
  bookingId: string;
  isSaving: boolean;
}>();

const emit = defineEmits<{
  (e: 'recalculate-price', value: RecalculatePriceParams): void;
  (
    e: 'updateBooking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished?: () => void,
  ): void;
  (e: 'hasChanged', value: boolean): void;
}>();

const onCancel = () => {
  setValues({
    customDeposit: props.pricing.customDeposit,
    customPricePerExtraKm: props.pricing.customPricePerExtraKm,
  });
};

const {
  handleSubmit,
  values: formValues,
  setValues,
} = useForm({
  initialValues: {
    customDeposit: props.pricing.customDeposit,
    customPricePerExtraKm: props.pricing.customPricePerExtraKm,
  },
  validationSchema: yup.object({
    customDeposit: yup.number().required().nullable(),
    customPricePerExtraKm: yup.number().required().nullable(),
  }),
});

const unchangedValues = ref(cloneDeep(formValues));

const onSubmit = handleSubmit((values) => {
  emit('recalculate-price', {
    ...values,
    priceRecalculationMode: PriceRecalculationMode.USE_EXISTING,
    showAlert: false,
    nullExtrasOnConfirm: false,
    isConfirmedFunction: (pricing) => {
      emit(
        'updateBooking',
        {
          id: props.bookingId,
          booking: {
            priceCalculationId: pricing.id,
          },
        },
        () => (unchangedValues.value = cloneDeep(formValues)),
      );
    },
  });
});

const hasChanged = useFormHasChanged(unchangedValues, formValues);
watch(hasChanged, () => emit('hasChanged', hasChanged.value));
</script>

<i18n lang="json">
{
  "en": {
    "otherConditions": "Other Conditions",
    "deposit": "Deposit",
    "pricePerExtraKm": "Price per extra km"
  },
  "de": {
    "otherConditions": "Sonstige Konditionen",
    "deposit": "Kaution",
    "pricePerExtraKm": "Preis pro Extra Km"
  }
}
</i18n>
