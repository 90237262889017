<template>
  <div v-if="data">
    <ul class="flex flex-col gap-2">
      <li v-for="car in cars" :key="car.id">
        <DetailingListItem :car="car" />
      </li>
    </ul>
  </div>

  <div v-if="status === 'success' && data?.pages.length === 0" class="flex justify-center py-28">
    <p>{{ t('noCarsFound') }}</p>
  </div>

  <div v-if="status === 'pending'" class="flex justify-center py-28">
    <Spinner />
  </div>

  <div v-if="status === 'error'" class="flex justify-center py-28">
    <p>{{ error }}</p>
  </div>

  <div v-if="hasNextPage" class="flex justify-center pt-8">
    <CVButton outline :is-loading="isFetchingNextPage" @click="() => fetchNextPage()">
      {{ t('loadMore') }}
    </CVButton>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { reactive, watch } from 'vue';
import { useCarsWithQuery } from '@/queries/use-cars';
import DetailingListItem from './DetailingListItem.vue';
import { useCurrentStations } from '@/hooks/use-current-stations';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';

const { t } = useI18n();

const currentStations = useCurrentStations();

const getCarsQuery = reactive({
  readyToHandover: false,
  stationIds: currentStations.value,
});

watch(currentStations, () => {
  getCarsQuery.stationIds = currentStations.value;
});

const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status, error } =
  useCarsWithQuery(getCarsQuery);

const cars = useFlattenPaginatedData(data);
</script>

<i18n lang="json">
{
  "en": {
    "handovers": "Handovers",
    "upcomingHandovers": "Upcoming handovers",
    "all": "All",
    "pickups": "Pickups",
    "returns": "Returns",
    "detailing": "Detailing",
    "noCarsFound": "No cars found that need to be detailed",
    "tomorrow": "Tomorrow",
    "today": "Today"
  },

  "de": {
    "handovers": "Übergaben / Rückgaben",
    "upcomingHandovers": "Übergaben & Rückgaben der nächsten 24h",
    "all": "Alles",
    "pickups": "Übergaben",
    "returns": "Rückgaben",
    "detailing": "Aufbereiten",
    "noCarsFound": "Keine Fahrzeuge gefunden, die aufbereitet werden müssen",
    "tomorrow": "Morgen",
    "today": "Heute"
  }
}
</i18n>
