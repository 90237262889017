<template>
  <div>
    <div class="grid grid-cols-6 gap-4">
      <img
        v-if="selectedImageUrl"
        class="col-span-4 mb-3 self-center"
        :src="selectedImageUrl"
        :width="600"
        :height="300"
      />
      <div
        v-else
        class="h-50 col-span-4 flex w-full items-center justify-center border border-primary/40 text-primary"
      >
        {{ t('noImage') }}
      </div>
      <div class="col-span-2 space-y-2 self-center">
        <CVButton outline class="w-full" @click.prevent="showUploadImageModal = true">
          {{ t('changeImage') }}
        </CVButton>
        <CVButton
          v-if="selectedImageFile"
          outline
          variant="error"
          class="w-full"
          @click.prevent="cancelImageChange"
        >
          {{ t('resetImage') }}
        </CVButton>
      </div>
    </div>

    <p v-if="error" class="mt-1 text-xs text-error">{{ error }}</p>

    <SelectImageForUploadModal
      v-model:show-modal="showUploadImageModal"
      :image-file="selectedImageFile"
      @select="onSelectImage"
    ></SelectImageForUploadModal>
  </div>
</template>

<script lang="ts" setup>
import SelectImageForUploadModal from '@/components/SelectImageForUploadModal.vue';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';

defineProps<{
  selectedImageFile: File | undefined | null;
  selectedImageUrl: string | undefined;
  error?: string;
}>();

const emit = defineEmits<{
  (e: 'select', imageFile: File | undefined, imageUrl: string | undefined): void;
}>();

const { t } = useI18n();

const showUploadImageModal = ref(false);

const onSelectImage = (newImageFile: File, newImageUrl: string) => {
  emit('select', newImageFile, newImageUrl);
};

const cancelImageChange = () => {
  emit('select', undefined, undefined);
};
</script>

<i18n lang="json">
{
  "en": {
    "changeImage": "Change Image",
    "removeImage": "× Remove Image",
    "noImage": "No Image",
    "resetImage": "Reset image"
  },
  "de": {
    "changeImage": "Bild ändern",
    "removeImage": "× Bild löschen",
    "noImage": "Kein Bild",
    "resetImage": "Bild zurücksetzen"
  }
}
</i18n>
