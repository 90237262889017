<template>
  <div>
    <div v-if="makes" class="grid grid-cols-2 gap-4">
      <Select
        :model-value="categoryFilter"
        :options="categoryOptions"
        @update:model-value="$emit('change', $event as VehicleCategory | null, makeFilter)"
      />
      <Select
        :model-value="makeFilter"
        :options="makeOptions"
        @update:model-value="$emit('change', categoryFilter, $event ?? null)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Select from '@/components/Select.vue';
import { VehicleCategory } from '@/entities/vehicle-category.enum';
import { useI18n } from 'vue-i18n';
import { useMakes } from '@/queries/use-makes';
import { computed } from 'vue';

defineProps<{
  categoryFilter: VehicleCategory | null;
  makeFilter: string | null;
}>();

defineEmits<{
  (event: 'change', categoryFilter: VehicleCategory | null, makeFilter: string | null): void;
}>();

const { t } = useI18n();

const { data: makes } = useMakes();

const categoryOptions = computed(() => [
  { label: t('vehicleCategory'), value: null },
  ...Object.values(VehicleCategory).map((value) => ({
    label: t(`categories.${value}`),
    value,
  })),
]);

const makeOptions = computed(() => [
  { label: t('premiumMake'), value: null },
  ...(makes.value?.map((make) => ({ label: make.name, value: make.id })) ?? []),
]);
</script>

<i18n lang="json">
{
  "en": {
    "vehicleCategory": "Vehicle category",
    "premiumMake": "Premium make"
  },
  "de": {
    "vehicleCategory": "Fahrzeugkategorie",
    "premiumMake": "Premium Marke"
  }
}
</i18n>
