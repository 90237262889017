<template>
  <Modal :show-cancel="true">
    <div class="mb-4 flex justify-between">
      <h2>{{ t('selectCarTitle') }}</h2>
      <StationSwitcher v-model="currentStationId" />
    </div>
    <div v-if="vehicleTypes">
      <VehicleTypeItem
        v-for="vehicleType in vehicleTypes"
        :key="vehicleType.id"
        :vehicle-type="vehicleType"
        :selected-car="selectedCar"
        @on-car-select="emit('onSelect', $event)"
      />
    </div>
    <div v-else class="flex items-center justify-center"><Spinner /></div>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from '@/components/Modal.vue';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import type { Booking } from '@/entities/bookings/booking.entity';
import type { Car } from '@/entities/cars/car.entity';
import { useI18n } from 'vue-i18n';
import { useVehicleTypesWithQuery } from '@/queries/use-vehicle-types';
import { toRef, computed, ref } from 'vue';
import VehicleTypeItem from './VehicleTypeItem.vue';
import StationSwitcher from '@/views/settings/components/StationSwitcher.vue';
import { useStationOfCarByDate } from '@/hooks/use-current-station-of-car';

const props = defineProps<{
  booking: Booking;
  selectedCar: Car;
  pickupStationId?: string | null;
  pickupLocationType: BookingLocationType;
}>();

const emit = defineEmits<{
  (e: 'onSelect', car: Car): void;
}>();

const { t } = useI18n();

const booking = toRef(props, 'booking');

const selectedCarStation = useStationOfCarByDate(
  toRef(props, 'selectedCar'),
  computed(() => booking.value.startDate),
);

const currentStationId = ref(
  props.pickupLocationType === BookingLocationType.STATION && props.pickupStationId
    ? props.pickupStationId
    : selectedCarStation.value.id,
);

const { data: vehicleTypes } = useVehicleTypesWithQuery(
  computed(() => ({
    startDate: booking.value.startDate,
    endDate: booking.value.endDate,
    stationIds: [currentStationId.value],
    isGhostCar: false,
    ignoreOpeningHoursAndHolidays: true,
  })),
);
</script>

<i18n lang="json">
{
  "en": {
    "selectCarTitle": "Select car",
    "select": "select",
    "changeCar": "Change car?"
  },
  "de": {
    "selectCarTitle": "Auto wählen",
    "select": "auswählen",
    "changeCar": "Auto ändern?"
  }
}
</i18n>
