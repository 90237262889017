<template>
  <label>
    <InputLabel :label="label" :tooltip="tooltip" :is-error="isError" :warning="warning" />
    <input
      ref="fileUploadField"
      class="file-input file-input-bordered file-input-primary file-input-sm w-full max-w-xs"
      type="file"
      @change="onChange"
    />
    <InputHint :hint="hint" :is-error="isError" />
  </label>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import InputHint from './InputHint.vue';
import InputLabel from './InputLabel.vue';

const props = defineProps<{
  label?: string;
  modelValue?: File | null;
  hint?: string;
  isError?: boolean;
  tooltip?: string;
  warning?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value?: File | null): void;
}>();

const fileUploadField = ref<HTMLInputElement>();

watchEffect(() => {
  if (!props.modelValue && fileUploadField.value) fileUploadField.value.value = '';
});

const onChange = () => {
  emit('update:modelValue', fileUploadField.value?.files?.item(0));
};
</script>
