<template>
  <div class="border-t border-primary/40 pt-4">
    <div class="flex items-center gap-4">
      <p>{{ t('upgradedFrom') }}</p>

      <div class="w-[100px]">
        <img :src="car.imageUrl ?? undefined" class="w-full" />
      </div>

      <p class="font-medium">
        {{ car?.vehicleType?.make.name }}
        {{ car?.vehicleType?.model }}
      </p>

      <p>({{ t(`carColors.${car.color}`) }})</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Car } from '@/entities/cars/car.entity';
import { useI18n } from 'vue-i18n';

defineProps<{
  car: Car;
}>();

const { t } = useI18n();
</script>

<i18n lang="json">
{
  "en": {
    "upgradedFrom": "Upgraded from the original booking"
  },
  "de": {
    "upgradedFrom": "Upgegradet von der ursprünglichen Buchung"
  }
}
</i18n>
