<template>
  <ValuePairsList
    :value-pairs="valuePairs"
    :title="t('extraKilometers')"
    :button-text="t('addInclusiveExtraKmStep')"
    name="extraKm.options"
    :value-names="['value', 'price']"
    :labels="labels"
    :save-numbers-as-string="[false, false]"
    @add-value-pair="
      push({
        name: '',
        value: null,
        valueType: VehicleExtraOptionValueType.NUMBER,
        price: null,
        pricingType: VehicleExtraOptionPricingType.FIXED,
      })
    "
    @remove-value-pair="remove"
  />
</template>

<script lang="ts" setup>
import ValuePairsList, { type Labels, type ValuePairs } from '../ValuePairsListField.vue';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useFieldArray } from 'vee-validate';
import type { VehicleExtraOptionFormValues } from '@/entities/vehicle-extra.entity';
import { VehicleExtraOptionValueType } from '@/entities/vehicle-extra-option-value-type.enum';
import { VehicleExtraOptionPricingType } from '@/entities/vehicle-extra-option.entity';

const { t } = useI18n();

const labels = computed<Labels>(() => [
  {
    label: t('kilometers'),
    adornment: 'km',
  },
  {
    label: t('price'),
    adornment: '€',
  },
]);

const { remove, push, fields } = useFieldArray<VehicleExtraOptionFormValues>('extraKm.options');

if (fields.value.length === 0) {
  push({
    name: '',
    value: null,
    valueType: VehicleExtraOptionValueType.NUMBER,
    price: null,
    pricingType: VehicleExtraOptionPricingType.FIXED,
  });
}

const valuePairs = computed<ValuePairs>(
  () => fields.value.map((field) => [field.value.value ?? 0, field.value.price ?? 0]) ?? [],
);
</script>

<i18n lang="json">
{
  "en": {
    "extraKilometers": "Extra Kilometers",
    "addInclusiveExtraKmStep": "+ Add Extra Kilometers Step",
    "kilometers": "Kilometers",
    "price": "Price"
  },
  "de": {
    "extraKilometers": "Extrakilometer",
    "addInclusiveExtraKmStep": "+ Extrakilometerstaffelung hinzufügen",
    "kilometers": "Kilometer",
    "price": "Preis"
  }
}
</i18n>
