import type { ApiErrorResponse } from '@/entities/api-error';
import { QueryClient, type VueQueryPluginOptions } from '@tanstack/vue-query';
import { createToast } from 'mosha-vue-toastify';

export const queryClient = new QueryClient();

export const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClient,
};

export interface MutationDefaultsContext {
  successMessage?: string;
  invalidateQueryKeys?: string[] | null;
  showSuccessToast?: boolean;
}

queryClient.setMutationDefaults([], {
  onSuccess: (
    data,
    variables,
    { successMessage, invalidateQueryKeys, showSuccessToast = true }: MutationDefaultsContext = {},
  ) => {
    invalidateQueryKeys && queryClient.invalidateQueries({ queryKey: invalidateQueryKeys });
    showSuccessToast &&
      createToast(successMessage ?? 'Success', {
        position: 'bottom-right',
        type: 'success',
      });
  },

  onError: (error) => {
    console.log(error.message);
    const errorMessage = (error as ApiErrorResponse).response?.data.message ?? error.message;
    createToast(
      {
        title: 'Error',
        description: Array.isArray(errorMessage) ? errorMessage.join(', ') : errorMessage,
      },
      {
        type: 'danger',
        position: 'bottom-right',
      },
    );
  },
});
