<template>
  <HeaderBar />
  <ButtonBackContainer>
    <ButtonBack>{{ t('backToFleetManagement') }}</ButtonBack>
  </ButtonBackContainer>

  <section class="carvia-container">
    <AddNewCarForm
      v-if="vehicleTypes && (!routeVehicleTypeId || vehicleType) && stations"
      :vehicle-type="vehicleType"
      :vehicle-types="vehicleTypes"
      :stations="stations"
      :is-saving="isSaving"
      :is-ghost-car="isGhostCar"
      @vehicle-type-change="vehicleTypeId = $event"
      @on-submit="handleSubmit"
    />

    <div v-else class="flex justify-center">
      <Spinner />
    </div>
  </section>
</template>

<script lang="ts" setup>
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useI18n } from 'vue-i18n';
import AddNewCarForm, { type AddNewCarFormValues } from './AddNewCarForm.vue';
import { useVehicleType, useVehicleTypes } from '@/queries/use-vehicle-types';
import { useUploadCarImage } from '@/queries/use-upload';
import { useUiStore } from '@/stores/ui.store';
import { useCreateCar, useUpdateCar } from '@/queries/use-cars';
import router from '@/router';
import type { Car, CreateCarDto } from '@/entities/cars/car.entity';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStations } from '@/queries/use-stations';
import { CarDocumentType } from '@/entities/cars/car-document-type.enum';
import { useUploadAndCreateCarDocument } from '@/hooks/use-upload-and-create-car-document';
import { CarTyres } from '@/entities/cars/car-tyres.enum';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';

const { t } = useI18n();
const uiStore = useUiStore();

uiStore.setHeaderTitle(t('fleetInCar'));
const route = useRoute();
const isGhostCar = route.query.isGhostCar === 'true';

const routeVehicleTypeId = route.params.vehicleTypeId as string;
const vehicleTypeId = ref(routeVehicleTypeId);

const { data: stationsData } = useStations(500);
const stations = useFlattenPaginatedData(stationsData);
const { data: vehicleType } = useVehicleType(
  vehicleTypeId,
  computed(() => !!vehicleTypeId.value),
);
const { data: vehicleTypes } = useVehicleTypes();

const { uploadAndCreateCarDocument, isPending: isUploadingCarDocument } =
  useUploadAndCreateCarDocument();
const { mutateAsync: uploadImage, isPending: isUploadingImage } = useUploadCarImage();
const { mutateAsync: updateCar, isPending: isUpdating } = useUpdateCar();
const { mutateAsync: createCar, isPending: isCreating } = useCreateCar();

const isSaving = computed(
  () =>
    isUploadingCarDocument.value || isUploadingImage.value || isUpdating.value || isCreating.value,
);

const handleSubmit = async ({
  selectedImageFile,
  contractName,
  contractFile,
  contractComment,
  stationId,
  possibleUpgradeCars,
  ...formValues
}: AddNewCarFormValues) => {
  if (!stationId) return;
  const { id } = await createCar({
    ...formValues,
    ...(isGhostCar
      ? {
          isGhostCar: true,
          availableFrom: new Date(),
          listPrice: 0,
          purchasePrice: 0,
          tyres: CarTyres.ALL_SEASON,
        }
      : {}),
    possibleUpgrades: possibleUpgradeCars
      ? possibleUpgradeCars.map((car, i) => ({
          toId: (car as unknown as Car).id,
          order: i,
        }))
      : undefined,
    stations: [{ id: stationId, fromDate: null }],
  } as CreateCarDto);
  if (contractFile && contractName) {
    await uploadAndCreateCarDocument(
      id,
      contractFile,
      CarDocumentType.PURCHASE_CONTRACT,
      contractName,
      contractComment,
    );
  }
  if (selectedImageFile) {
    const { fileKey } = await uploadImage({
      file: selectedImageFile,
      params: { carId: id, formerFileName: selectedImageFile.name },
    });
    await updateCar({ id, car: { imageFileKey: fileKey } });
  }
  router.replace({
    name: 'editCar',
    params: { id },
  });
};
</script>

<i18n lang="json">
{
  "en": {
    "fleetInCar": "Fleet in car",
    "backToFleetManagement": "Back to fleet management"
  },
  "de": {
    "fleetInCar": "Fahrzeug einflotten",
    "backToFleetManagement": "Zurück zum Flottenmanagement"
  }
}
</i18n>
