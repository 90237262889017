<template>
  <h2 v-if="!hideHeader" class="mb-4">{{ t('documents') }}</h2>
  <div class="grid grid-cols-3 gap-4">
    <InputField :name="`${namePrefix}licenseNumber`" :label="t('licenseNumber')" />
    <InputField :name="`${namePrefix}licenseAuthority`" :label="t('licenseAuthority')" />
    <DatepickerField :name="`${namePrefix}licenseDate`" :label="t('licenseDate')" />
    <InputField :name="`${namePrefix}idCardNumber`" :label="t('idCardNumber')" />
    <InputField :name="`${namePrefix}idCardAuthority`" :label="t('idCardAuthority')" />
    <DatepickerField :name="`${namePrefix}idCardDate`" :label="t('idCardDate')" />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import InputField from '@/components/InputField.vue';
import DatepickerField from '@/components/DatepickerField.vue';
import { computed } from 'vue';

const props = defineProps<{
  name?: string;
  hideHeader?: boolean;
}>();

const { t } = useI18n();

const namePrefix = computed(() => (props.name ? `${props.name}.` : ''));
</script>

<i18n lang="json">
{
  "en": {
    "documents": "Documents",
    "licenseNumber": "License Number",
    "licenseAuthority": "License Authority",
    "licenseDate": "License Date",
    "idCardNumber": "ID Card Number",
    "idCardAuthority": "ID Card Authority",
    "idCardDate": "ID Card Date"
  },
  "de": {
    "documents": "Dokumente",
    "licenseNumber": "Führerschein Nr.",
    "licenseAuthority": "Behörde",
    "licenseDate": "Ausstelldatum",
    "idCardNumber": "Ausweis Nr.",
    "idCardAuthority": "Behörde",
    "idCardDate": "Ausstelldatum"
  }
}
</i18n>
