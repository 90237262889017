<template>
  <form @submit.prevent="onSubmit">
    <h2>{{ t('paymentTerminal') }}</h2>

    <div class="mt-4 grid grid-cols-3 gap-4">
      <InputField name="terminalId" :label="t('terminalId')" type="number" :use-grouping="false" />
      <InputField name="description" :label="t('description')" />
      <SelectField name="stationId" :label="t('station')" :options="stationOptions" />
    </div>

    <Divider />

    <div class="mt-3 flex justify-between">
      <CVButton
        outline
        variant="error"
        :disabled="props.isLoading"
        @click.prevent="deletePaymentTerminal"
      >
        {{ t('deletePaymentTerminal') }}
      </CVButton>
      <CVButton :is-loading="isLoading" @click="onSubmit">{{ t('save') }}</CVButton>
    </div>
  </form>
</template>

<script lang="ts" setup>
import Divider from '@/components/Divider.vue';
import type {
  PaymentTerminal,
  UpdatePaymentTerminalDto,
} from '@/entities/payment-terminals/payment-terminal.entity';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { Alert } from '@/utils/alert';
import { computed, ref } from 'vue';
import { cloneDeep } from 'lodash';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form';
import InputField from '@/components/InputField.vue';
import SelectField from '@/components/SelectField.vue';
import type { Station } from '@/entities/station/station.entity';
import { paymentTerminalSchema } from '@/validation/payment-terminal.schema';
import { formHasChanged } from '@/hooks/use-form-has-changed';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

const props = defineProps<{
  paymentTerminal?: PaymentTerminal;
  stations: Station[];
  isLoading: boolean;
  isFetching: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSubmit', value: UpdatePaymentTerminalDto): void;
  (e: 'onDelete'): void;
  (e: 'refetch'): void;
}>();

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();

const initialValues: UpdatePaymentTerminalDto = {
  description: props.paymentTerminal?.description,
  terminalId: props.paymentTerminal?.terminalId,
  stationId: props.paymentTerminal?.station.id,
};

const stationOptions = computed(() => {
  return props.stations.map((station) => ({
    label: getLocalizedField(station.info),
    value: station.id,
  }));
});

const { handleSubmit, values: formValues } = useForm({
  initialValues,
  validationSchema: paymentTerminalSchema,
});

const unchangedValues = ref(cloneDeep(formValues));

const onSubmit = handleSubmit((values) => {
  unchangedValues.value = cloneDeep(formValues);
  emit('onSubmit', values);
});

usePreventLeavingUnsavedForm(() => formHasChanged(unchangedValues.value, formValues));

const deletePaymentTerminal = async () => {
  const alertResult = await Alert.fire({
    titleText: t('reallyDeletePaymentTerminalTitle'),
    text: t('reallyCancelPaymentTerminalText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('deletePaymentTerminal'),
    denyButtonText: t('dontDelete'),
  });
  if (alertResult.isConfirmed) {
    emit('onDelete');
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "paymentTerminal": "Payment Terminal",
    "terminalId": "Terminal ID",
    "description": "Description",
    "deletePaymentTerminal": "Delete Payment Terminal",
    "reallyDeletePaymentTerminalTitle": "Really delete Payment Terminal?",
    "reallyCancelPaymentTerminalText": "The Payment Terminal will be permanently deleted.",
    "dontDelete": "Don't delete"
  },
  "de": {
    "paymentTerminal": "Zahlungsterminal",
    "terminalId": "Terminal ID",
    "description": "Beschreibung",
    "deletePaymentTerminal": "Zahlungsterminal löschen",
    "reallyDeletePaymentTerminalTitle": "Zahlungsterminal wirklich löschen?",
    "reallyCancelPaymentTerminalText": "Das Zahlungsterminal wird unwiderruflich gelöscht.",
    "dontDelete": "Nicht löschen"
  }
}
</i18n>
