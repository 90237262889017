<template>
  <div class="flex flex-col gap-4">
    <div class="flex justify-between gap-4">
      <h2>{{ title }}</h2>
      <CVButton :disabled="!stationId" @click.prevent="addField">
        {{ t('addPossibleUpgradeCar') }}
      </CVButton>
    </div>

    <div>
      <VueDraggableNext :list="fields" class="space-y-2" handle=".handle" @change="changeOrder">
        <div
          v-for="(possibleUpgradeCar, i) of fields"
          :key="i"
          class="flex items-center justify-between gap-4 border-primary/50"
          :class="{ 'border-t pt-2': i > 0 }"
        >
          <div class="flex gap-4">
            <div class="handle cursor-grab">
              <Icon name="bars-3" class="text-black/20" />
            </div>
            <p>{{ i + 1 }}.</p>
            <p v-if="possibleUpgradeCar.value">
              {{ possibleUpgradeCar.value.vehicleType.make.name }}
              {{ possibleUpgradeCar.value.vehicleType.model }}
              ({{ possibleUpgradeCar.value.licencePlate }})
            </p>
            <p v-else>-</p>
          </div>

          <div class="flex gap-4">
            <CVButton outline @click.prevent="editField(i)">{{ t('change') }}</CVButton>
            <CVButton variant="error" outline @click.prevent="removeField(i)">{{
              t('remove')
            }}</CVButton>
          </div>
        </div>
      </VueDraggableNext>
    </div>

    <div v-if="errors?.length">
      <p v-for="error of errors" :key="error" class="text-xs text-error">
        {{ error }}
      </p>
    </div>

    <AddPossibleUpgradeCarModal
      v-model="showModal"
      :station-id="stationId"
      @car-selected="carSelected"
    />
  </div>
</template>

<script lang="ts" setup>
import type { Car } from '@/entities/cars/car.entity';
import { useI18n } from 'vue-i18n';
import { useFieldArray } from 'vee-validate';
import AddPossibleUpgradeCarModal from './AddPossibleUpgradeCarModal.vue';
import { computed, ref, watch } from 'vue';
import { Alert } from '@/utils/alert';
import { VueDraggableNext } from 'vue-draggable-next';

const props = defineProps<{
  stationId: string | undefined;
  title: string;
  errors: string | string[] | undefined;
}>();

const { t } = useI18n();

const showModal = ref(false);
const addingCarToIndex = ref<number>();

const errors = computed(() => {
  if (!props.errors) return undefined;
  if (Array.isArray(props.errors)) return props.errors;
  return [props.errors];
});

const { push, replace, fields } = useFieldArray<Car | undefined>('possibleUpgradeCars');

const addField = () => {
  push(undefined);
  addingCarToIndex.value = fields.value.length - 1;
  showModal.value = true;
};

const editField = (index: number) => {
  addingCarToIndex.value = index;
  showModal.value = true;
};

const carSelected = (car: Car) => {
  replace(
    fields.value.map((field, i) => {
      return i === addingCarToIndex.value ? car : field.value;
    }),
  );
};

const removeField = async (index: number) => {
  const { isConfirmed } = await Alert.fire({
    text: t('reallyRemoveUpgradeCar'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('remove'),
    cancelButtonText: t('cancel'),
  });
  if (!isConfirmed) return;
  replace(fields.value.filter((_, i) => i !== index).map((field) => field.value));
};

const changeOrder = ({ moved }: { moved: { newIndex: number; oldIndex: number } }) => {
  const { newIndex, oldIndex } = moved;
  replace(
    fields.value.map((field, index) => {
      if (index === newIndex) return fields.value[oldIndex].value;
      if (index === oldIndex) return fields.value[newIndex].value;
      return field.value;
    }),
  );
};

watch(
  () => props.stationId,
  () => replace([]),
);
</script>

<i18n lang="json">
{
  "en": {
    "possibleUpgradeCars": "Possible upgrade cars",
    "addPossibleUpgradeCar": "+ Add possible upgrade car",
    "reallyRemoveUpgradeCar": "Do you really want to remove this upgrade car?",
    "change": "Change",
    "remove": "Remove"
  },
  "de": {
    "possibleUpgradeCars": "Mögliche Upgrade Autos",
    "addPossibleUpgradeCar": "+ Mögliches Upgrade Auto hinzufügen",
    "reallyRemoveUpgradeCar": "Möchtest du dieses Upgrade-Auto wirklich entfernen?",
    "change": "Ändern",
    "remove": "Löschen"
  }
}
</i18n>
