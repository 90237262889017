<template>
  <HeaderBar />
  <ButtonBackContainer>
    <ButtonBack>{{ t('backToOverview') }}</ButtonBack>
    <AuditLogButton entity-type="VehicleType" :entity-id="route.params.id as string" />
  </ButtonBackContainer>

  <section class="carvia-container">
    <div class="flex justify-center space-y-3">
      <div class="grow">
        <div v-if="vehicleType || vehicleTypeNew">
          <EditVehicleTypeForm
            :is-saving="
              isUpdating || isCreating || isUpdatingExtra || isCreatingExtra || isUploadingImage
            "
            :initial-vehicle-type="vehicleType"
            @on-submit="handleSubmit"
          />
        </div>
        <div v-else class="flex justify-center"><Spinner /></div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { CreateVehicleTypeDto, UpdateVehicleTypeDto } from '@/entities/vehicle-type.entity';
import { useI18n } from 'vue-i18n';
import {
  useCreateVehicleType,
  useUpdateVehicleType,
  useVehicleType,
} from '@/queries/use-vehicle-types';
import { useRoute } from 'vue-router';
import { useUiStore } from '@/stores/ui.store';
import EditVehicleTypeForm, { type VehicleTypeFormValues } from './EditVehicleTypeForm.vue';
import type {
  CreateVehicleExtraDto,
  VehicleExtraFormValues,
} from '@/entities/vehicle-extra.entity';
import { ref, watchEffect } from 'vue';
import { useCreateVehicleExtra, useUpdateVehicleExtra } from '@/queries/use-vehicle-extras';
import router from '@/router';
import { useUploadVehicleTypeImage } from '@/queries/use-upload';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import AuditLogButton from '@/components/audit-log/AuditLogButton.vue';

const uiStore = useUiStore();
const { t } = useI18n();
const route = useRoute();
const vehicleTypeId = ref(route.params.id as string);
const vehicleTypeNew = ref(vehicleTypeId.value === 'new');
const { data: vehicleType } = useVehicleType(vehicleTypeId, !vehicleTypeNew.value);

watchEffect(() => {
  if (vehicleType.value) {
    const { make, model } = vehicleType.value;
    uiStore.setHeaderTitle(t('vehicleType'), `${make.name}`, `${model}`);
  } else {
    uiStore.setHeaderTitle(t('vehicleType'));
  }
});

const { mutateAsync: updateVehicleType, isPending: isUpdating } = useUpdateVehicleType();
const { mutateAsync: createVehicleType, isPending: isCreating } = useCreateVehicleType();
const { mutateAsync: updateVehicleExtra, isPending: isUpdatingExtra } = useUpdateVehicleExtra();
const { mutateAsync: createVehicleExtra, isPending: isCreatingExtra } = useCreateVehicleExtra();
const { mutateAsync: uploadImage, isPending: isUploadingImage } = useUploadVehicleTypeImage();

const handleSubmit = async ({ imageFile, ...formValues }: VehicleTypeFormValues) => {
  const extraKmDto = extraFormValuesToDto(formValues.extraKm);
  const extraDeductibleDto = extraFormValuesToDto(formValues.extraDeductible);

  if (vehicleTypeNew.value) {
    // Save new vehicle type & extra
    const extraKm = await createVehicleExtra(extraKmDto);
    const extraDeductible =
      formValues.overrideDeductibleExtra && (await createVehicleExtra(extraDeductibleDto));
    const { id } = await createVehicleType({
      ...(formValues as CreateVehicleTypeDto),
      availableExtraIds: [extraKm.id, ...(extraDeductible ? [extraDeductible.id] : [])],
    });
    if (imageFile) {
      const imageResponse = await uploadImage({
        file: imageFile,
        params: {
          vehicleTypeId: id,
          formerFileName: imageFile.name,
        },
      });
      await updateVehicleType({
        id,
        vehicleType: {
          imageFileKey: imageResponse.fileKey,
        },
      });
    }
    router.push({
      name: 'vehicleTypeCarsList',
      params: { id },
    });
  } else {
    const { fileKey } = imageFile
      ? await uploadImage({
          file: imageFile,
          params: {
            vehicleTypeId: vehicleTypeId.value,
            formerFileName: imageFile.name,
          },
        })
      : { fileKey: undefined };

    // Update vehicle type & extra
    // If extra does not exist yet, create it, else update it
    const extraKm = await createOrUpdateExtra(extraKmDto, formValues.extraKm?.id);
    const extraDeductible =
      formValues.overrideDeductibleExtra &&
      (await createOrUpdateExtra(extraDeductibleDto, formValues.extraDeductible?.id));
    await updateVehicleType({
      id: vehicleTypeId.value,
      vehicleType: {
        ...(formValues as UpdateVehicleTypeDto),
        availableExtraIds: [extraKm.id, ...(extraDeductible ? [extraDeductible.id] : [])],
        imageFileKey: fileKey,
      },
    });
  }
};

const extraFormValuesToDto = (formValues: VehicleExtraFormValues): CreateVehicleExtraDto => {
  return {
    ...formValues,
    options:
      formValues.options?.map((option) => ({
        ...option,
        value: String(option.value),
        price: option.price ?? undefined,
        pricePerDay: option.pricePerDay?.map((pricePerDay) => ({
          fromDay: pricePerDay.fromDay ?? 0,
          pricePerDay: pricePerDay.pricePerDay ?? 0,
        })),
      })) ?? [],
  };
};

const createOrUpdateExtra = async (extraDto: CreateVehicleExtraDto, extraId?: string) => {
  return !extraId
    ? await createVehicleExtra(extraDto)
    : await updateVehicleExtra({
        id: extraId,
        extra: extraDto,
      });
};
</script>

<i18n lang="json">
{
  "en": {
    "vehicleType": "Vehicle Type",
    "backToOverview": "Back to Vehicle Type Overview"
  },
  "de": {
    "vehicleType": "Vehicle Type",
    "backToOverview": "Zurück zur Vehicle Types Übersicht"
  }
}
</i18n>
