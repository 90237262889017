import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import type { BookingLocation } from '@/entities/bookings/booking.entity';
import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { computed } from 'vue';
import { useLocalizedFields } from './use-localized-fields';

export const useLocationInfo = (
  bookingLocation: MaybeRefOrGetter<BookingLocation | undefined | null>,
  stationWithoutAddress = true,
) => {
  const { getLocalizedField } = useLocalizedFields();
  return computed(() => {
    const bookingLocationValue = toValue(bookingLocation);
    if (!bookingLocationValue) return '';
    const { location, station, locationType } = bookingLocationValue;
    const stationInfo = getLocalizedField(station?.info);
    return locationType === BookingLocationType.STATION
      ? stationWithoutAddress
        ? stationInfo
        : `${stationInfo},\n${station?.street},\n${station?.zip} ${station?.city}`
      : location?.address?.displayName;
  });
};
