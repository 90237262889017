<template>
  <div v-if="hasBookingIdOrInvoiceId" class="mb-4 mt-6 border border-primary p-4">
    <div class="mb-4 grid grid-cols-2 gap-4 border-b border-primary pb-4">
      <InputField
        name="amount"
        :label="t('amount')"
        type="number"
        :max-fraction-digits="2"
        :min="0"
        adornment="€"
      />
      <SelectField name="direction" :label="t('direction')" :options="directions" />
      <SelectField name="paymentType" :label="t('paymentType')" :options="paymentTypes" />
      <DatepickerField name="date" :label="t('paymentDate')" />
      <InputField name="referenceId" :label="t('referenceId')" />
      <SelectField
        v-if="!invoiceId"
        name="invoiceId"
        :label="t('invoice')"
        :options="invoicesOptions"
        empty-value-to="null"
      />
    </div>
    <div class="flex justify-end gap-2">
      <CVButton variant="success" @click.prevent="savePayment">
        {{ t('savePayment') }}
      </CVButton>
      <CVButton variant="warning" @click.prevent="$emit('onCancel')">
        {{ t('cancel') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Invoice } from '@/entities/invoices/invoice.entity';
import { PaymentStatus } from '@/entities/payments/payment-status.enum';
import { PaymentType } from '@/entities/payments/payment-type.enum';
import type { CreatePaymentDto } from '@/entities/payments/payment.entity';
import { useI18n } from 'vue-i18n';
import { paymentCreateSchema } from '@/validation/payment.schema';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import DatepickerField from '../DatepickerField.vue';
import InputField from '../InputField.vue';
import SelectField from '../SelectField.vue';

const props = defineProps<{
  bookingId?: string | null;
  invoiceId?: string;
  invoices: Invoice[];
}>();

const emit = defineEmits<{
  (e: 'onSubmit', value: CreatePaymentDto): void;
  (e: 'onCancel'): void;
}>();

const { t } = useI18n();

const hasBookingIdOrInvoiceId = computed(() => !!(props.bookingId || props.invoiceId));

const { handleSubmit } = useForm({
  initialValues: getNewPayment(),
  validationSchema: paymentCreateSchema,
});

const savePayment = handleSubmit(async (values) => {
  if (!hasBookingIdOrInvoiceId.value) return;
  const newPayment: CreatePaymentDto = {
    status: PaymentStatus.PAID,
    bookingId: props.bookingId ?? undefined,
    invoiceId: props.invoiceId,
    amount: Math.abs(Number(values.amount)) * Number(values.direction),
    paymentType: values.paymentType ?? PaymentType.VISA,
    referenceId: values.referenceId ?? undefined,
    date: values.date,
  };
  if (values.invoiceId) newPayment.invoiceId = values.invoiceId;
  emit('onSubmit', newPayment);
});

const paymentTypes = ref(
  Object.entries(PaymentType).map(([key, value]) => ({
    label: t(`paymentTypes.${key}`),
    value,
  })),
);

const invoicesOptions = computed(() => [
  { label: t('noInvoice'), value: null },
  ...props.invoices.map((invoice) => ({
    label: `${invoice.completeInvoiceNumber ?? t('iNrNA')} - ${invoice.title ?? ''}`,
    value: invoice.id,
  })),
]);

function getNewPayment() {
  return {
    invoiceId: null,
    direction: '1',
    amount: null,
    paymentType: PaymentType.VISA,
    referenceId: null,
    date: new Date().toISOString(),
  };
}

const directions = ref([
  {
    label: t('payment'),
    value: '1',
  },
  {
    label: t('repayment'),
    value: '-1',
  },
]);
</script>

<i18n lang="json">
{
  "en": {
    "invoice": "Invoice",
    "amount": "Amount",
    "paymentType": "Payment Type",
    "direction": "Direction",
    "savePayment": "Save Payment",
    "paymentDate": "Payment Date",
    "payment": "Payment",
    "repayment": "Repayment",
    "noInvoice": "- No Invoice selected -",
    "referenceId": "Referenz ID"
  },
  "de": {
    "invoice": "Rechnung",
    "amount": "Betrag",
    "paymentType": "Zahlungsart",
    "direction": "Richtung",
    "savePayment": "Zahlung speichern",
    "paymentDate": "Zahlungsdatum",
    "payment": "Zahlung",
    "repayment": "Rückzahlung",
    "noInvoice": "- Keine Rechnung ausgewählt -",
    "referenceId": "Referenz ID"
  }
}
</i18n>
