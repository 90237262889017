import { httpClient } from '@/api/http-client';
import type { AgentProvisionSummary } from '@/entities/agent-provision';

class AgentProvisionService {
  httpClient = httpClient;
  endpoint = '/agent-provision';

  async getSummary() {
    const res = await this.httpClient.get<AgentProvisionSummary>(
      `${this.endpoint}/monthly-summary`,
      {},
    );
    return res.data;
  }

  async getYearlySummary(userId: string) {
    const res = await this.httpClient.get<AgentProvisionSummary>(
      `${this.endpoint}/yearly-summary/${userId}`,
      {},
    );
    return res.data;
  }

  async getPayouts() {
    const res = await this.httpClient.get<string>(`${this.endpoint}/payouts`, {});
    return res.data;
  }
}

export const agentProvisionService = new AgentProvisionService();
