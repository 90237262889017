<template>
  <div class="grow">
    <h2>
      {{
        t('title', {
          firstName: user?.firstName,
        })
      }}
    </h2>
    <p class="mt-4">
      {{ isHandover ? t('handoverSuccess') : t('returnSuccess') }}
    </p>
    <BigSuccess />

    <Divider />
    <div class="flex justify-end">
      <CVButton :to="{ name: 'upcomingHandovers' }">
        {{ t('next') }}
      </CVButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import BigSuccess from '@/components/BigSuccess.vue';
import Divider from '@/components/Divider.vue';
import { useMe } from '@/queries/use-users';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const { t } = useI18n();
const { data: user } = useMe();
const route = useRoute();

const isHandover = route.params.handedOverOrReturned === 'handed-over';
</script>

<i18n lang="json">
{
  "en": {
    "next": "Back to Dashboard",
    "title": "Thanks {firstName} for your good work!",
    "handoverSuccess": "You just made another customer happy.",
    "returnSuccess": "The booking is successfully completed and the vehicle can now be cleaned again."
  },
  "de": {
    "next": "Zurück zur Übersicht",
    "title": "Danke {firstName} für deine Gute Arbeit!",
    "handoverSuccess": "Du hast gerade einen weiteren Kunden glücklich gemacht.",
    "returnSuccess": "Die Buchung ist erfolgreich abgeschlossen und das Fahrzeug kann jetzt wieder aufbereitet werden."
  }
}
</i18n>
