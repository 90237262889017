<template>
  <HeaderBar />

  <section class="carvia-container flex flex-col justify-center">
    <div class="mb-4 flex justify-between gap-4">
      <Input v-model="search" :placeholder="t('search')" class="w-full max-w-sm" />
      <CVButton :to="{ name: 'station', params: { id: 'new' } }">{{ t('newStation') }}</CVButton>
    </div>

    <div>
      <div v-if="stations" class="flex flex-col space-y-2">
        <div v-for="(station, i) in stations" :key="i">
          <StationListItem :station="station" />
        </div>
      </div>

      <EntityListExceptions
        :status="stationsStatus"
        :error="stationsError"
        :items-count="stations?.length ?? 0"
        :no-items-text="t('noStationsFound')"
      />

      <div v-if="hasNextPage" class="flex justify-center pt-8">
        <CVButton outline :is-loading="isFetchingNextPage" @click="() => fetchNextPage()">
          {{ t('loadMore') }}
        </CVButton>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useUiStore } from '@/stores/ui.store';
import { ref, watch, watchEffect } from 'vue';
import { debounce } from 'lodash';
import { useStationsWithQuery } from '@/queries/use-stations';
import StationListItem from './StationListItem.vue';
import EntityListExceptions from '@/components/EntityListExceptions.vue';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import Input from '@/components/Input.vue';
import { computed } from 'vue';
import { FindAllStationsSort } from '@/entities/station/find-all-stations-sort.enum';
import { Order } from '@/entities/pagination/order.enum';

const { t } = useI18n();
const uiStore = useUiStore();
watchEffect(() => {
  uiStore.setHeaderTitle(t('stations'));
});

const search = ref('');
const searchQuery = ref('');

const refreshSearchQuery = debounce(() => {
  searchQuery.value = search.value;
}, 500);
watch(search, () => {
  refreshSearchQuery();
});

const queryParams = computed(() => ({
  query: searchQuery.value,
  sort: FindAllStationsSort.CREATED_DATE,
  order: Order.ASC,
}));

const {
  data: stationsData,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  status: stationsStatus,
  error: stationsError,
} = useStationsWithQuery(queryParams);

const stations = useFlattenPaginatedData(stationsData);
</script>

<i18n lang="json">
{
  "en": {
    "stations": "Stations",
    "newStation": "+ New Station",
    "search": "Search",
    "noStationsFound": "No Stations found"
  },
  "de": {
    "stations": "Stationen",
    "newStation": "+ Neue Station",
    "search": "Suche",
    "noStationsFound": "Keine Stationen gefunden"
  }
}
</i18n>
