<template>
  <label class="form-control">
    <InputLabel :label="label" :tooltip="tooltip" :is-error="isError" :warning="warning" />
    <textarea
      ref="textarea"
      :rows="rows ?? 2"
      :disabled="disabled"
      :value="inputText"
      class="rounded-none border border-primary/40 bg-accent p-1 transition duration-300 hover:border-primary focus:border-primary focus:bg-white focus:outline-none"
      :placeholder="placeholder"
      @input="$emit('update:inputText', ($event.target as HTMLTextAreaElement).value)"
    ></textarea>
    <InputHint :hint="hint" :is-error="isError" />
  </label>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import InputLabel from './InputLabel.vue';
import InputHint from './InputHint.vue';

const props = defineProps<{
  name?: string;
  label?: string;
  placeholder?: string;
  hint?: string;
  inputText?: string | number | null;
  disabled?: boolean;
  isError?: boolean;
  rows?: number | string;
  tooltip?: string;
  warning?: string;
}>();

defineEmits<{
  (e: 'update:inputText', inputText: string): void;
}>();

const textarea = ref<HTMLTextAreaElement>();

watch([textarea, () => props.inputText], () => {
  if (textarea.value) {
    textarea.value.style.height = 'auto';
    textarea.value.style.height = `${textarea.value.scrollHeight + 2}px`;
  }
});

const inputText = computed(() => (props.inputText !== null ? props.inputText : undefined));
</script>
