<template>
  <CVButton
    v-if="$can('read', 'AuditLog')"
    variant="secondary"
    :outline="true"
    type="button"
    @click="showForm"
    >{{ t('label') }}</CVButton
  >
  <vue-final-modal
    v-model="modalVisibility"
    class="flex items-center justify-center"
    content-class="modal-box p-0 w-11/12 max-w-5xl h-max"
    content-transition="vfm-fade"
    overlay-transition="vfm-fade"
  >
    <div class="p-4">
      <AuditLogTable :entity-id="entityId" :entity-type="entityType" />
    </div>
  </vue-final-modal>
</template>

<script lang="ts" setup>
import { useModalVisibility } from '@/utils/use-modal-visibility';
import CVButton from '@/components/buttons/CVButton.vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import AuditLogTable from '@/components/audit-log/AuditLogTable.vue';
import type { AuditLogEntityType } from '@/entities/audit-log/audit-log.entity';

defineProps<{
  entityId: string;
  entityType: AuditLogEntityType;
}>();

const { t } = useI18n();

const modalVisibility = useModalVisibility<boolean>('audit-log');
const showForm = () => (modalVisibility.value = true);
</script>
<i18n lang="json">
{
  "en": {
    "label": "Show Edit History"
  },
  "de": {
    "label": "Bearbeitungsverlauf anzeigen"
  }
}
</i18n>
