<template>
  <slot v-for="entry in entries" name="entry" :entry="entry" />
  <div v-if="data.hasNextPage" class="flex justify-center pt-8">
    <CVButton
      v-if="hasNextPage"
      outline
      :is-loading="isFetchingNextPage"
      @click="data.fetchNextPage"
    >
      {{ t('loadMore') }}
    </CVButton>
  </div>
</template>

<script lang="ts" setup generic="T">
import { type QueryKey, useInfiniteQuery } from '@tanstack/vue-query';
import type { PaginatedResponse } from '@/entities/pagination/paginated-response';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  load: (page: number) => Promise<PaginatedResponse<T>>;
  queryKey: QueryKey;
}>();

defineSlots<{
  entry: (props: { entry: T }) => any;
}>();

const data = useInfiniteQuery({
  queryKey: props.queryKey,
  queryFn: async ({ pageParam = 1 }) => props.load(pageParam),
  getNextPageParam: (lastPage) => lastPage.meta.nextPage,
  initialPageParam: 1,
});

const entries = useFlattenPaginatedData(data.data);

const isFetchingNextPage = data.isFetchingNextPage;
const hasNextPage = data.hasNextPage;
</script>
<i18n lang="json">
{
  "en": {
    "loadMore": "Load more"
  },
  "de": {
    "loadMore": "Mehr laden"
  }
}
</i18n>
