import type { Car } from '@/entities/cars/car.entity';
import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { computed } from 'vue';

export const useCurrentInspectionCycleMileage = (car: MaybeRefOrGetter<Car>) => {
  return computed(() => getCurrentInspectionCycleMileage(toValue(car)));
};

export const getCurrentInspectionCycleMileage = (car: Car) => {
  return (
    Math.floor((car.milage + 5000) / car.vehicleType.inspectionIntervalInKm) *
    car.vehicleType.inspectionIntervalInKm
  );
};
