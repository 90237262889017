import type {
  CreateSurveyConductedDto,
  FindAllSurveysConductedQueryDto,
  SurveyConducted,
} from '@/entities/surveys/survey-conducted.entity';
import { Service } from './http-service';
import type { PaginatedResponse } from '@/entities/pagination/paginated-response';

class SurveysConductedService extends Service<
  SurveyConducted,
  CreateSurveyConductedDto,
  undefined
> {
  async getAllWithQuery(params: FindAllSurveysConductedQueryDto) {
    const res = await this.httpClient.get<PaginatedResponse<SurveyConducted>>(`${this.endpoint}`, {
      params,
    });
    return res.data;
  }
}

export const surveysConductedService = new SurveysConductedService('/surveys-conducted');
