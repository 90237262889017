<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="!isLoading && booking" class="flex grow flex-col items-center justify-center">
    <div class="w-full bg-white pl-8 pr-8 pt-8">
      <div v-html="contractHtml"></div>
    </div>
    <div class="w-full bg-white pb-8 pl-8 pr-8">
      <div class="mb-4">
        <Checkbox v-model="gtcConfirmed" :label="t('confirmGtc')" />
        <Checkbox v-model="iTakePicturesConfirmed" :label="t('confirmITakePictures')" />
        <Checkbox
          v-if="isSportsCar"
          v-model="noRacetracksConfirmed"
          :label="t('confirmNoRacetracks')"
        />
        <Checkbox
          v-for="(customCheckbox, index) in customCheckboxes"
          :key="customCheckbox"
          :label="customCheckbox"
          @change="customCheckboxesConfirmed[index] = $event"
        />
      </div>

      <div class="mb-10 flex items-end justify-between">
        <div class="">
          <div
            v-date="{
              value: DateTime.now().toISO(),
              format: DateTime.DATE_FULL,
            }"
            class="border-b border-black pb-2"
          ></div>
          <p class="mt-1">{{ t('date') }}</p>
        </div>
        <div>
          <div class="relative flex h-[140px] w-[450px] items-stretch border-b border-black">
            <div
              v-if="signingState === SigningState.NOTREADY"
              class="flex h-full w-full items-center bg-gray-200 p-2 font-medium text-gray-500"
            >
              <span class="text-center">
                {{ t('pleaseConfirm') }}
              </span>
            </div>
            <button
              v-if="signingState === SigningState.READY"
              class="h-full w-full bg-gray-200 p-2 font-medium text-gray-500"
              href="#"
              @click.prevent="signingState = SigningState.ACTIVE"
            >
              {{ t('tapToSign') }}
            </button>
            <canvas
              v-show="signingState === SigningState.ACTIVE || signingState === SigningState.FILLED"
              ref="signatureCanvasRef"
              class="bg-red absolute left-[-70px] top-[-80px] h-[340px] w-[560px]"
              width="560"
              height="340"
            ></canvas>
            <div
              v-if="signingState === SigningState.DONE"
              class="absolute left-[-70px] top-[-80px] h-[340px] w-[560px]"
            >
              <img class="h-full w-full" :src="signatureImage" />
            </div>
          </div>
          <p class="mt-1 text-right">{{ t('signature') }}</p>
        </div>
      </div>
      <p class="text-center text-xs">
        {{ t('imprint') }}
      </p>
    </div>

    <div class="card-actions mt-8 flex w-full justify-end">
      <CVButton
        v-show="
          !isSaving &&
          signingState !== SigningState.NOTREADY &&
          signingState !== SigningState.READY &&
          signingState !== SigningState.ACTIVE
        "
        outline
        @click="clearSignature"
      >
        {{ t('resetSignature') }}
      </CVButton>
      <CVButton
        v-show="
          !isSaving &&
          signingState !== SigningState.NOTREADY &&
          signingState !== SigningState.READY &&
          signingState !== SigningState.ACTIVE
        "
        :disabled="signingState === SigningState.DONE"
        @click="confirmSignature"
      >
        {{ t('confirmSignature') }}
      </CVButton>
      <CVButton
        :disabled="signingState !== SigningState.DONE"
        :class="{
          loading: isSaving,
        }"
        @click="saveSignature"
      >
        {{ t('completeHandover') }}
      </CVButton>
    </div>
  </div>
  <Spinner v-else />
</template>

<script lang="ts" setup>
import { VehicleCategory } from '@/entities/vehicle-category.enum';
import { useI18n } from 'vue-i18n';
import {
  useBookingContractTemplate,
  useBookingOfType,
  useCreateBookingContract,
  useUpdateBooking,
} from '@/queries/use-bookings';
import { useUploadContractSignature } from '@/queries/use-upload';
import { DateTime } from 'luxon';
import { create as createDrawingBoard } from 'simple-drawing-board';
import { computed, ref, watch, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import { QueryLocale } from '@/entities/auth/local.enum';
import Checkbox from '@/components/Checkbox.vue';
import { BookingType } from '@/entities/bookings/booking-type.enum';

enum SigningState {
  NOTREADY = 'NOTREADY',
  READY = 'READY',
  ACTIVE = 'ACTIVE',
  FILLED = 'FILLED',
  DONE = 'DONE',
}

const { t, locale } = useI18n();
const route = useRoute();
const signingState = ref(SigningState.NOTREADY);
const signatureImage = ref('');
const signatureCanvasRef = ref<HTMLCanvasElement>();
const drawingBoard = ref();
const gtcConfirmed = ref(false);
const iTakePicturesConfirmed = ref(false);
const noRacetracksConfirmed = ref(false);
const customCheckboxesConfirmed = ref<boolean[]>([]);
const bookingId = route.params.id as string;
const contractTemplateQuery = computed(() => {
  return {
    language: Object.keys(QueryLocale).includes(locale.value.toLocaleUpperCase())
      ? (locale.value as QueryLocale)
      : QueryLocale.DE,
    bookingId: bookingId,
  };
});

const { isLoading, data: contractHtml } = useBookingContractTemplate(contractTemplateQuery);

const { mutateAsync: createBookingContract, isPending: isCreatingBookingContract } =
  useCreateBookingContract();
const { mutateAsync: uploadSignature, isPending: isUploadingSignature } =
  useUploadContractSignature();
const { mutateAsync: updateBooking, isPending: isUpdatingBooking } = useUpdateBooking();

const isSaving = computed(
  () => isCreatingBookingContract.value || isUploadingSignature.value || isUpdatingBooking.value,
);

watchEffect(() => {
  if (
    gtcConfirmed.value &&
    iTakePicturesConfirmed.value &&
    (noRacetracksConfirmed.value || !isSportsCar.value) &&
    customCheckboxesConfirmed.value.filter((confirmed) => confirmed).length ===
      customCheckboxes.value.length
  ) {
    signingState.value = SigningState.READY;
  } else {
    signingState.value = SigningState.NOTREADY;
  }
});

watch(signingState, (newVal) => {
  if (!signatureCanvasRef.value) return;
  switch (newVal) {
    case SigningState.NOTREADY:
    case SigningState.READY:
      drawingBoard.value?.clear();
      break;
    case SigningState.ACTIVE:
      drawingBoard.value = createDrawingBoard(signatureCanvasRef.value);
      drawingBoard.value.observer.on('drawEnd', () => {
        signingState.value = SigningState.FILLED;
      });
      break;
    case SigningState.DONE:
      drawingBoard.value.destroy();
      signatureImage.value = signatureCanvasRef.value.toDataURL();
  }
});

const { data: booking } = useBookingOfType(
  bookingId,
  true,
  BookingType.CUSTOMER_BOOKING,
  'upcomingHandovers',
);

const isSportsCar = computed(() => {
  const matchTypes = [VehicleCategory.SPORTS_CAR, VehicleCategory.SUPER_CAR];
  return matchTypes.some((i) => booking.value?.car.vehicleType?.categories.includes(i));
});

const customCheckboxes = computed(() => {
  return booking.value
    ? booking.value.car.vehicleType.customContractCheckboxes.map((checkbox) =>
        locale.value === 'de' ? checkbox.label.de : checkbox.label.en,
      )
    : [];
});

const confirmSignature = () => {
  signingState.value = SigningState.DONE;
};

const clearSignature = () => {
  if (!drawingBoard.value) return;

  drawingBoard.value.clear();
  signingState.value = SigningState.READY;
};

const saveSignature = () => {
  if (!signatureCanvasRef.value) return;

  signatureCanvasRef.value.toBlob(async (blob) => {
    const { bookingNumber, customer } = booking.value ?? {};
    if (!blob || !bookingNumber || !customer || !booking.value) return;
    const res = await uploadSignature({
      file: blob,
      params: {
        bookingId: booking.value.id,
      },
    });

    await createBookingContract({
      bookingId,
      data: {
        signatureFileKey: res.fileKey,
      },
    });

    await updateBooking({
      id: booking.value.id,
      booking: {
        status: BookingStatus.HANDED_OVER,
      },
    });
    router.push({ name: 'handoverDone' });
  });
};
</script>

<style scoped>
h3 {
  @apply text-sm font-medium xl:text-base;
}
h4 {
  @apply text-xs font-medium xl:text-sm;
}
</style>

<i18n lang="json">
{
  "en": {
    "pleaseConfirm": "Please confirm the required declarations first",
    "confirmGtc": "I have read and accept the terms and conditions and privacy agreements.",
    "confirmITakePictures": "I take my own pictures of the vehicle and in particular the rims, the front bumper and the rear bumper to provide as evidence in the event of damage.",
    "confirmNoRacetracks": "I do not drive the rental car on race tracks.",
    "confirmNoRacetracksSuperCar": "I do not drive the rental car on race tracks. In case of breach, I acknowledge a contract penalty of 15,000€.",
    "tapToSign": "Tap here to sign",
    "rentalContract": "Rental Contract",
    "bookingNumber": "Booking Number",
    "renter": "Renter",
    "nameBirthdayDriversLicence": "Name, Date of Birth / Drivers Licence / Licence Authority",
    "issued": "issued on",
    "streetZipCityCountry": "Street, ZIP Code & Town, Country",
    "mobileEmail": "Mobile, Email",
    "additionalDrivers": "Additional Driver, Date of Birth / Drivers Licence / Licence Authority",
    "rentalData": "Rental Data",
    "rentalStart": "Rental start",
    "rentalEnd": "Rental end",
    "pickUp": "Pick-up",
    "return": "Return",
    "rentalObject": "Rental object",
    "description": "Description",
    "licencePlate": "Licence Plate",
    "previousDamages": "Previous Damages",
    "sDamageList": "s. damage list",
    "mileage": "Mileage",
    "fuelLevel": "Fuel level",
    "deductible": "Deductible comprehensive insurance",
    "racingTracksParagraph": "The vehicle may only be driven on public, paved roads. Use on race tracks, off-road tracks or similar terrain is not permitted and obligates the renter to pay a contractual penalty. The traffic laws must be followed at all times. For vehicles with winter tires, the permitted maximum speed must be observed. The (partial) deactivation of assistance systems (also indirectly via driving modes such as \"Sport Plus\") is not permitted and may lead to a loss of insurance coverage.",
    "fuelLevelParagraph": "The vehicle is provided with a full tank. A flat rate of 15.00 EUR plus the fuel price according to the receipt will be charged for any refilling with fuel upon return (in each case including VAT).",
    "damageList": "Damage list",
    "sampleModel": "Sample model, does not reflect the actual vehicle",
    "chargingAndPayment": "Charging & Payment",
    "quantity": "Quantity",
    "serviceDescription": "Description",
    "priceUnit": "Price/Unit",
    "total": "Total",
    "totalSum": "Total sum",
    "inclVat": "incl. VAT",
    "deposit": "Deposit",
    "blockedOnCreditCard": "blocked on credit card in the name of the renter",
    "freeKilometersParagraph": "The rental includes {freeKilometers} free kilometers. Each additional kilometer is charged with {kilometerPrice} incl. VAT.",
    "countryPermissions": "The renter is permitted to enter the following countries:",
    "germany": "Germany",
    "countries": "Germany, Austria, Switzerland, Liechtenstein, Italy, France, Holland, Belgium, Denmark, Norway, Sweden & Luxembourg",
    "accidentParagraph": "In the event of an accident, theft, or any kind of damage the renter or driver is obliged to inform the police as well as us without any undue delay. <span class='font-medium'>Service number:</span> 0049 89 954573640",
    "generalTermsAndConditions": "The general terms and conditions have been read and accepted. The rental contract has been thouroughly explained and understood.",
    "safeRide": "The CarVia team wishes a safe ride and great experiences on the way.",
    "date": "Date",
    "resetSignature": "Reset Signature",
    "confirmSignature": "Confirm Signature",
    "completeHandover": "Complete handover and send contract",
    "signature": "Signature of renter/CarVia",
    "imprint": "Place of business: Munich, Germany • Commercial register: District court Munich: HRB 232676 • Managing directors: Joschka Reik, Julian Reik VAT-ID: DE312142017"
  },
  "de": {
    "pleaseConfirm": "Bitte bestätigen Sie zuerst die erforderlichen Erklärungen",
    "confirmGtc": "Ich habe die AGB und Datenschutzverbeinbarungen gelesen und akzeptiere diese.",
    "confirmITakePictures": "Ich mache eigene Bilder vom Fahrzeug und insbesondere den Felgen, der Frontsstoßstange und der Heckstoßstange, um sie im Schadensfall zum Beweis bereitzustellen.",
    "confirmNoRacetracks": "Ich fahre mit dem Mietwagen nicht auf Rennstrecken.",
    "confirmNoRacetracksSuperCar": "Ich fahre mit dem Wagen nicht auf Rennstrecken. Bei Missachtung erkenne ich eine Vertragsstrafe von 15.000€ an.",
    "tapToSign": "Hier tippen um zu unterschreiben",
    "rentalContract": "Mietvertrag",
    "bookingNumber": "Buchungsnummer",
    "renter": "Mieter",
    "nameBirthdayDriversLicence": "Name, Geburtsdatum / Führerschein / Behörde",
    "issued": "ausgestellt am",
    "streetZipCityCountry": "Straße, PLZ & Ort, Land",
    "mobileEmail": "Mobil, E-Mail",
    "additionalDrivers": "Zusatzfahrer, Geburtsdatum / Führerschein / Behörde",
    "rentalData": "Mietdaten",
    "rentalStart": "Mietbeginn",
    "rentalEnd": "Mietende",
    "pickUp": "Abholung",
    "return": "Rückgabe",
    "rentalObject": "Mietobjekt",
    "description": "Bezeichnung",
    "licencePlate": "Amt. Kennzeichen",
    "previousDamages": "Vorschäden",
    "sDamageList": "s. Skizze",
    "mileage": "KM-Stand",
    "fuelLevel": "Tankstand",
    "deductible": "SB Vollkaskovers.",
    "racingTracksParagraph": "Das Fahrzeug darf nur auf öffentlichen, befestigten Straßen bewegt werden. Der Einsatz auf Rennstrecken, Off- Road-Strecken oder ähnlichem Gelände ist nicht gestattet und verpflichtet den Mieter zur Zahlung einer Vertragsstrafe. Die Straßenverkehrsordnung ist zu befolgen. Bei Fahrzeugen mit Winterreifen ist die zulässige Maximalgeschwindigkeit zu beachten. Das (teilweise) Ausschalten von Assistenzsystemen (auch indirekt über Fahrmodi wie \"Sport Plus\") ist nicht gestattet und kann zu einem Verlust des Versicherungsschutzes führen.",
    "fuelLevelParagraph": "Das Fahrzeug wird vollgetankt zur Verfügung gestellt. Für eine etwaige Wiederauffüllung mit Kraftstoff bei Rückgabe wird eine Pauschale von 15,00 EUR sowie der Kraftstoffpreis gemäß Beleg berechnet (jeweils inkl. USt.).",
    "damageList": "Schadensskizze",
    "sampleModel": "Mustermodell, kein Anspruch auf Übereinstimmung mit dem gemieteten Fahrzeugmodell",
    "chargingAndPayment": "Berechnung und Bezahlung",
    "quantity": "Anzahl",
    "serviceDescription": "Leistung",
    "priceUnit": "Preis/Einheit",
    "total": "Gesamtpreis",
    "totalSum": "Summe",
    "inclVat": "inkl. MwSt.",
    "deposit": "Garantiebetrag",
    "blockedOnCreditCard": "durch auf den Mieter lautende Kreditkarte",
    "freeKilometers1": "Die Miete beinhaltet {freeKilometers} Freikilometer. Jeder zusätzliche Kilometer wird mit {kilometerPrice} inkl. MwSt. berechnet.",
    "countryPermissions": "Einreise in folgende Länder gestattet:",
    "germany": "Deutschland",
    "countries": "Deutschland, Österreich, Schweiz, Liechtenstein, Italien, Frankreich, Holland, Belgien, Dänemark, Norwegen, Schweden & Luxemburg",
    "accidentParagraph": "Nach einem Unfall, Diebstahl, Brand-, Wild- oder sonstigen Schäden hat der Mieter oder Fahrer unverzüglich die Polizei sowie den Vermieter zu verständigen. <span class='font-medium'>Servicenummer:</span> 089 954573640",
    "generalTermsAndConditions": "Die Allgemeinen Vermietbedingungen wurden gelesen und akzeptiert. Der Mietvertrag wurde ausführlich erläutert und verstanden.",
    "safeRide": "Das Team von CarVia wünscht Ihnen eine sichere Fahrt und tolle Erlebnisse.",
    "date": "Datum",
    "resetSignature": "Unterschrift zurücksetzen",
    "confirmSignature": "Unterschrift bestätigen",
    "completeHandover": "Übergabe abschließen und Vertrag senden",
    "signature": "Unterschrift Mieter/CarVia",
    "imprint": "Sitz der Gesellschaft: München • Handelsregister München: HRB 232676 • Geschäftsführer: Joschka Reik, Julian Reik • Ust.-ID: DE312142017"
  }
}
</i18n>
