import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import { BookingType } from '@/entities/bookings/booking-type.enum';
import type { Booking } from '@/entities/bookings/booking.entity';
import type { ManyBookingsBooking } from '@/entities/bookings/many-bookings-booking.entity';
import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { DateTime } from 'luxon';
import { computed } from 'vue';

export const useBookingCharacteristics = (
  bookingRef: MaybeRefOrGetter<Booking | ManyBookingsBooking | undefined>,
) => {
  const isHandover = computed(() => {
    const booking = toValue(bookingRef);
    if (!booking) return undefined;
    return (
      booking?.type === BookingType.CUSTOMER_BOOKING && booking?.status === BookingStatus.CONFIRMED
    );
  });

  const isReturn = computed(() => {
    const booking = toValue(bookingRef);
    if (!booking) return undefined;
    return (
      booking?.type === BookingType.CUSTOMER_BOOKING &&
      booking?.status === BookingStatus.HANDED_OVER
    );
  });

  const isLateHandover = computed(() => {
    const booking = toValue(bookingRef);
    if (!booking) return undefined;
    const startDate = DateTime.fromISO(booking.startDate);
    return startDate.diffNow('hours').hours < -2;
  });

  const hasTransfer = computed(() => {
    const booking = toValue(bookingRef);
    if (
      isHandover.value &&
      booking?.relatedBookings.some(
        (relatedBooking) => relatedBooking.type === BookingType.TRANSFER_DELIVERY_BOOKING,
      )
    ) {
      return true;
    }
    if (
      isReturn.value &&
      booking?.relatedBookings.some(
        (relatedBooking) => relatedBooking.type === BookingType.TRANSFER_RETURN_BOOKING,
      )
    ) {
      return true;
    }
    return false;
  });

  const isTransferDelivery = computed(() => {
    const booking = toValue(bookingRef);
    if (!booking) return undefined;
    return booking?.type === BookingType.TRANSFER_DELIVERY_BOOKING;
  });

  const isTransferReturn = computed(() => {
    const booking = toValue(bookingRef);
    if (!booking) return undefined;
    return booking?.type === BookingType.TRANSFER_RETURN_BOOKING;
  });

  const isPartnerRent = computed(() => {
    const booking = toValue(bookingRef);
    return booking?.type === BookingType.PARTNER_BOOKING;
  });

  const isPartnerRentHandover = computed(() => {
    const booking = toValue(bookingRef);
    return isPartnerRent.value && booking?.status === BookingStatus.CONFIRMED;
  });

  const isPartnerRentReturn = computed(() => {
    const booking = toValue(bookingRef);
    return isPartnerRent.value && booking?.status === BookingStatus.HANDED_OVER;
  });

  return {
    isReturn,
    isHandover,
    isLateHandover,
    isPartnerRent,
    isPartnerRentHandover,
    isPartnerRentReturn,
    isTransferDelivery,
    isTransferReturn,
    isTransfer: computed(() => isTransferDelivery.value || isTransferReturn.value),
    hasTransfer,
  };
};
