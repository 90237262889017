<template>
  <div class="mt-4 border border-primary/40 px-4 pb-3 pt-2">
    <div class="grid grid-cols-2 gap-12">
      <div>
        <p class="mb-2 border-b border-b-primary/40 text-lg font-medium">
          {{ t('start') }}
        </p>
        <VehicleStatus
          v-if="showStart"
          v-model:milage="milageStart"
          v-model:fuel-level="fuelLevelStart"
          :fuel-type="fuelType"
        />
        <p v-else class="py-2">{{ t('notHandedOver') }}</p>
      </div>
      <div>
        <p class="mb-2 border-b border-b-primary/40 text-lg font-medium">
          {{ t('end') }}
        </p>
        <VehicleStatus
          v-if="showEnd"
          v-model:milage="milageEnd"
          v-model:fuel-level="fuelLevelEnd"
          :fuel-type="fuelType"
        />
        <p v-else class="py-2">{{ t('notReturned') }}</p>
      </div>
    </div>
    <div class="mt-6 flex justify-end gap-4">
      <CVButton :is-loading="isSaving" variant="success" @click.prevent="onSave">{{
        t('save')
      }}</CVButton>
      <CVButton variant="warning" @click.prevent="onCancel">{{ t('cancel') }}</CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useForm } from 'vee-validate';
import VehicleStatus from './VehicleStatus.vue';
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import { computed, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';

const props = defineProps<{
  booking: Booking;
  isSaving: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSave', value: UpdateBookingDto): void;
  (e: 'onCancel'): void;
}>();

const { t } = useI18n();

const showStart = computed(
  () =>
    props.booking.status === BookingStatus.HANDED_OVER ||
    props.booking.status === BookingStatus.CAR_RETURNED,
);

const showEnd = computed(() => props.booking.status === BookingStatus.CAR_RETURNED);

const fuelType = computed(
  () => props.booking.car.fuelType ?? props.booking.car.vehicleType.fuelType,
);

const { handleSubmit, setValues } = useForm({
  initialValues: {
    milageStart: props.booking.milageStart,
    milageEnd: props.booking.milageEnd,
    fuelLevelStart: props.booking.fuelLevelStart,
    fuelLevelEnd: props.booking.fuelLevelEnd,
  },
});

const milageStart = ref(props.booking.milageStart);
const milageEnd = ref(props.booking.milageEnd);
const fuelLevelStart = ref(props.booking.fuelLevelStart);
const fuelLevelEnd = ref(props.booking.fuelLevelEnd);

watchEffect(() => {
  setValues({
    milageStart: milageStart.value,
    milageEnd: milageEnd.value,
    fuelLevelStart: fuelLevelStart.value,
    fuelLevelEnd: fuelLevelEnd.value,
  });
});

const onSave = handleSubmit((values) => {
  emit('onSave', values);
});

const onCancel = () => {
  emit('onCancel');
};
</script>

<i18n lang="json">
{
  "en": {
    "start": "Start",
    "end": "End",
    "notHandedOver": "can only be edited once the car has been handed over",
    "notReturned": "can only be edited once the car has been returned"
  },
  "de": {
    "start": "Start",
    "end": "Ende",
    "notHandedOver": "Kann erst bearbeitet werden, sobald das Auto übergeben wurde",
    "notReturned": "Kann erst bearbeitet werden, sobald das Auto zurückgegeben wurde"
  }
}
</i18n>
