<template>
  <form @submit.prevent="onSubmit">
    <div class="space-y-4">
      <SelectField class="w-56" name="status" :label="t('status')" :options="statusOptions" />
      <InputField name="title" :label="t('title')" />
      <TextareaField name="content" :rows="10" :label="t('content')" />
    </div>

    <div class="mt-4 flex" :class="{ 'justify-end': !news, 'justify-between': news }">
      <CVButton
        v-if="news"
        variant="error"
        outline
        :is-loading="isLoading"
        @click.prevent="deleteNews"
        >{{ t('delete') }}</CVButton
      >
      <CVButton :is-loading="isLoading">{{ t('save') }}</CVButton>
    </div>
  </form>
</template>

<script lang="ts" setup>
import InputField from '@/components/InputField.vue';
import SelectField from '@/components/SelectField.vue';
import TextareaField from '@/components/TextareaField.vue';
import { NewsStatus } from '@/entities/news/news-status.enum';
import type { News, UpdateNewsDto } from '@/entities/news/news.entity';
import { formHasChanged } from '@/hooks/use-form-has-changed';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form';
import { useI18n } from 'vue-i18n';
import { Alert } from '@/utils/alert';
import { newsEditSchema } from '@/validation/news.schema';
import { cloneDeep } from 'lodash';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';

const props = defineProps<{
  news?: News;
  isLoading: boolean;
  isFetching: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSubmit', value: UpdateNewsDto): void;
  (e: 'onDelete', value: string): void;
}>();

const { t } = useI18n();

const initialValues: UpdateNewsDto = {
  title: props.news?.title,
  content: props.news?.content,
  status: props.news?.status ?? NewsStatus.DRAFT,
};

const statusOptions = computed(() =>
  Object.values(NewsStatus).map((value) => ({
    label: t(`newsStatus.${value}`),
    value,
  })),
);

const { handleSubmit, values: formValues } = useForm({
  initialValues,
  validationSchema: newsEditSchema,
});

const unchangedValues = ref(cloneDeep(formValues));

const onSubmit = handleSubmit((values) => {
  unchangedValues.value = cloneDeep(formValues);
  emit('onSubmit', values);
});

usePreventLeavingUnsavedForm(() => formHasChanged(unchangedValues.value, formValues));

const deleteNews = async () => {
  if (!props.news) return;
  const { isConfirmed } = await Alert.fire({
    text: t('reallyDeleteNewsText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('delete'),
    denyButtonText: t('cancel'),
  });
  if (!isConfirmed) return;
  emit('onDelete', props.news.id);
};
</script>

<i18n lang="json">
{
  "en": {
    "status": "Status",
    "title": "Title",
    "content": "Content",
    "delete": "Delete",
    "reallyDeleteNewsText": "Do you really want to delete this news?"
  },
  "de": {
    "status": "Status",
    "title": "Titel",
    "content": "Inhalt",
    "delete": "Löschen",
    "reallyDeleteNewsText": "Möchtest du diese Neuigkeit wirklich löschen?"
  }
}
</i18n>
