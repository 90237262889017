import { calculateAge } from '@/utils/calculate-age';
import { type MaybeRefOrGetter, toRef } from '@vueuse/core';
import * as yup from 'yup';

export const ageVerification = (
  minAge: MaybeRefOrGetter<number>,
  bookingStart: MaybeRefOrGetter<string | Date>,
  optional = false,
) => {
  return !optional
    ? yup.date().test('is-old-enough', 'Driver is not old enough for Car', (val) => {
        return val ? calculateAge(val, toRef(bookingStart).value) >= toRef(minAge).value : false;
      })
    : yup.date().optional();
};
