<template>
  <HeaderBar />
  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
  </ButtonBackContainer>
  <section class="carvia-container">
    <PartnerRentForm
      v-if="car && (booking || bookingNew)"
      :car="car"
      :booking="booking"
      :booking-new="bookingNew"
      :is-saving="isCreating || isUpdating"
      :is-deleting="isDeleting"
      @on-submit="handleSubmit"
      @on-delete="onDelete"
    />
    <div v-else class="flex justify-center">
      <Spinner />
    </div>
  </section>
</template>

<script setup lang="ts">
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import { useCar } from '@/queries/use-cars';
import { useUiStore } from '@/stores/ui.store';
import { ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  useCreateInternalBooking,
  useUpdateInternalBooking,
} from '@/queries/use-internal-bookings';
import type {
  CreateInternalBookingDto,
  UpdateInternalBookingDto,
} from '@/entities/bookings/internal-booking.entity';
import { useI18n } from 'vue-i18n';
import { useBooking, useDeleteBooking } from '@/queries/use-bookings';
import { BookingType } from '@/entities/bookings/booking-type.enum';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import { Alert } from '@/utils/alert';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import PartnerRentForm from './PartnerRentForm.vue';

const { t } = useI18n();
const uiStore = useUiStore();
const route = useRoute();
const router = useRouter();
const carId = route.params.carId as string;
const bookingId = route.params.bookingId as string;
const bookingNew = ref(bookingId === 'new');
const { data: car } = useCar(carId);
const { data: booking } = useBooking(bookingId, !bookingNew.value);

watchEffect(() => {
  uiStore.setHeaderTitle(
    t('partnerRent'),
    car.value?.vehicleType.make.name,
    car.value?.vehicleType.model,
  );
});

const { isPending: isCreating, mutateAsync: createPartnerRent } = useCreateInternalBooking();
const { isPending: isUpdating, mutateAsync: updatePartnerRent } = useUpdateInternalBooking();
const { isPending: isDeleting, mutateAsync: deletePartnerRent } = useDeleteBooking();

const handleSubmit = async (internalBooking: UpdateInternalBookingDto) => {
  if (bookingNew.value) {
    const createPartnerRentValues: CreateInternalBookingDto = {
      ...internalBooking,
      startDate: internalBooking.startDate ?? new Date().toISOString(),
      endDate: internalBooking.endDate ?? new Date().toISOString(),
      carId: carId,
      type: BookingType.PARTNER_BOOKING,
      pickupLocationType: BookingLocationType.STATION,
      dropoffLocationType: BookingLocationType.STATION,
    };
    await createPartnerRent(createPartnerRentValues);
    router.push({
      name: 'cars',
      params: { vehicleTypeId: car.value?.vehicleType.id },
    });
  } else {
    updatePartnerRent({ id: bookingId, internalBooking });
  }
};

const onDelete = async () => {
  if (!booking.value?.id) return;
  const alertResult = await Alert.fire({
    titleText: t('reallyDeleteBookingTitle'),
    text: t('reallyDeleteBookingText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('delete'),
    denyButtonText: t('keep'),
  });
  if (alertResult.isConfirmed) {
    await deletePartnerRent(booking.value.id);
    router.push({ name: 'bookingPlan' });
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "partnerRent": "Partner Rent",
    "reallyDeleteBookingTitle": "Really remove Booking?",
    "reallyDeleteBookingText": "The Booking will be permanently deleted.",
    "delete": "Delete",
    "keep": "Keep"
  },
  "de": {
    "partnerRent": "Partnermiete",
    "reallyDeleteBookingTitle": "Buchung wirklich entfernen?",
    "reallyDeleteBookingText": "Die Buchung wird unwiderruflich gelöscht.",
    "delete": "Löschen",
    "keep": "Behalten"
  }
}
</i18n>
