import type { PaginatedResponse } from '@/entities/pagination/paginated-response';
import { Service } from './http-service';
import { type Offer, type OffersControllerGetOffersData } from '@carvia/ros-client-types';

class OffersService extends Service<Offer, OffersControllerGetOffersData['query'], null> {
  constructor() {
    super('/offers');
  }

  async getOffers(params: OffersControllerGetOffersData['query']) {
    const res = await this.httpClient.get<PaginatedResponse<Offer>>(`${this.endpoint}`, {
      params,
    });
    return res.data;
  }
}

export const offersService = new OffersService();
