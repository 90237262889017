<template>
  <div>
    <Divider class="col-span-3" />

    <h2>{{ t('blockManually') }}</h2>

    <p class="mb-3 mt-4">
      <span class="bg-primary/20 p-2">
        {{ t('typeInBookingNumber') }}:
        <span class="font-medium">{{ booking.bookingNumber }}</span>
      </span>
    </p>

    <label class="label cursor-pointer justify-start">
      <input v-model="confirmManualReservation" type="checkbox" class="checkbox mr-2 bg-white" />
      <span
        >{{ t('confirmManualReservation1') }}
        <span class="font-medium">(<span v-currency="amount" />)</span>
        {{ t('confirmManualReservation2') }}</span
      >
    </label>

    <div class="mt-5 flex justify-end">
      <ButtonNext
        :is-loading="isSaving"
        :disabled="!confirmManualReservation"
        type="submit"
        @click.prevent="$emit('continueWithoutTransaction')"
      >
        {{ t('continueWithManualPayment') }}
      </ButtonNext>
    </div>
  </div>
</template>

<script setup lang="ts">
import ButtonNext from '@/components/buttons/ButtonNext.vue';
import type { Booking } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';

defineProps<{
  booking: Booking;
  amount: number;
  isSaving: boolean;
}>();

defineEmits<{
  (e: 'continueWithoutTransaction'): void;
}>();

const { t } = useI18n();

const confirmManualReservation = ref(false);
</script>

<i18n lang="json">
{
  "en": {
    "blockManually": "Special case deposit",
    "confirmManualReservation1": "Confirm reservation of deposit via credit card in the amount of reservation amount",
    "confirmManualReservation2": ".",
    "continueWithManualPayment": "Continue with manual payment",
    "typeInBookingNumber": "Please enter booking number into card terminal"
  },
  "de": {
    "blockManually": "Sonderfall Kaution",
    "confirmManualReservation1": "Reservierung der Kaution über Kreditkarte i.H.v. Reservierungsbetrag",
    "confirmManualReservation2": "bestätigen.",
    "continueWithManualPayment": "Weiter mit manueller Zahlung",
    "typeInBookingNumber": "Buchungsnummer bitte ins Kartenterminal eingeben"
  }
}
</i18n>
