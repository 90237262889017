import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { computed } from 'vue';
import { includes, isArray, isEqualWith, isObject, omitBy, pickBy } from 'lodash';

export const useFormHasChanged = (
  initialValues: MaybeRefOrGetter<unknown>,
  values: MaybeRefOrGetter<unknown>,
) => {
  return computed(() => formHasChanged(toValue(initialValues), toValue(values)));
};

const deepEqualIgnoringUndefinedComparisonFunc = (a: any, b: any) => {
  if (isArray(a) || isArray(b)) return;
  if (!isObject(a) || !isObject(b)) return;

  if (!includes(a as any, undefined) && !includes(b as any, undefined)) return;

  return isEqualWith(
    omitBy(a, (value) => value === undefined),
    omitBy(b, (value) => value === undefined),
    deepEqualIgnoringUndefinedComparisonFunc,
  );
};

const deepEqualIgnoreUndefined = (a: any, b: any) =>
  isEqualWith(a, b, deepEqualIgnoringUndefinedComparisonFunc);

export const formHasChanged = (initialValues: unknown, values: unknown) => {
  return !deepEqualIgnoreUndefined(initialValues, values);
};

const removeUndefined = <T>(object: T): T => {
  if (typeof object !== 'object') return object;
  if (Array.isArray(object)) {
    return object.map((item) => removeUndefined(item)) as T;
  }
  return pickBy(object, (value) => value !== undefined) as T;
};
