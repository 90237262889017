<template>
  <div class="flex">
    <div class="group relative flex cursor-pointer items-center">
      <slot name="text"></slot>
      <div class="information group">
        i
        <div class="carvia-modal"><slot name="modal"></slot></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.information {
  @apply ml-2 flex h-5 w-5 cursor-pointer select-none
    items-center justify-center rounded-full border border-primary
    font-bold text-primary;
}

.carvia-modal {
  @apply invisible absolute bottom-8 left-0
      z-50 w-[300px] rounded-box bg-white p-3 text-left text-base font-normal
      text-black opacity-0 shadow transition-all ease-in
      group-hover:visible group-hover:flex group-hover:flex-col group-hover:space-y-1 group-hover:opacity-100;
}
</style>
