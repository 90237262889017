<template>
  <div class="relative -mt-0.5 flex flex-row gap-2 align-top">
    <div class="absolute bottom-2 left-[0.65rem] top-2 z-0 w-1 rounded-md bg-gray-100" />
    <div class="relative inline-block h-6 w-6 rounded-md p-1" :class="colors[changedField.type]">
      <component :is="icons[changedField.type]" class="h-4 w-4" />
    </div>
    <div class="my-0.5">
      <span class="font-semibold">
        {{ changedField.identifier }}
      </span>

      <div>
        <ChangedFieldsList
          v-if="changedField.type === 'related'"
          :changed-fields="changedField.changedFields"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type {
  ChangedCreated,
  ChangeDeleted,
  ChangedRelated,
} from '@/entities/audit-log/audit-log.entity';
import { PencilIcon, PlusCircleIcon, TrashIcon } from '@heroicons/vue/24/outline';
import ChangedFieldsList from '@/components/audit-log/ChangedFieldsList.vue';

defineProps<{
  changedField: ChangedRelated | ChangedCreated | ChangeDeleted;
}>();

const icons = {
  created: PlusCircleIcon,
  related: PencilIcon,
  deleted: TrashIcon,
};

const colors = {
  created: 'text-green-500 bg-green-100',
  related: 'text-blue-500 bg-blue-100',
  deleted: 'text-red-500 bg-red-100',
};
</script>
