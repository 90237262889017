<template>
  <div v-if="showPicker" ref="pickerContainer">
    <Datepicker
      v-model="dates"
      range
      inline
      week-numbers
      multi-calendars
      :close-on-auto-apply="false"
      :partial-range="false"
      :month-change-on-scroll="false"
      :clearable="false"
      enable-time-picker
      :flow="['calendar', 'time']"
    />
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';

const props = defineProps<{
  startDate: Date;
  endDate: Date;
  showPicker: boolean;
}>();

const emit = defineEmits<{
  (e: 'datesChange', value: { startDate: Date; endDate: Date }): void;
  (e: 'update:showPicker', value: boolean): void;
}>();

const dates = ref([props.startDate, props.endDate]);

const pickerContainer = ref(null);
onClickOutside(pickerContainer, () => {
  if (!props.showPicker) return;
  emit('update:showPicker', false);

  if (props.startDate === dates.value[0] && props.endDate === dates.value[1]) {
    return;
  }

  emit('datesChange', {
    startDate: dates.value[0],
    endDate: dates.value[1],
  });
});
</script>
