<template>
  <div class="flex flex-col gap-1">
    <Input
      v-if="showInputField"
      v-model="value"
      :hint="error"
      :is-error="!!error"
      :type="type"
      :max-fraction-digits="maxFractionDigits"
      :min="min"
      :disabled="disabled"
      :label="label"
      empty-value-to="undefined"
      v-bind="$attrs"
    />
    <LabeledText v-else :label="label">
      {{ formattedDefaultValue ?? defaultValue }}
    </LabeledText>
    <button
      v-if="!disabled"
      class="self-start text-left text-sm text-primary underline hover:no-underline"
      @click.prevent="toggleShowInputField"
    >
      {{ !showInputField ? t('override') : t('restoreDefault') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { ref, watch } from 'vue';
import Input from './Input.vue';
import { useI18n } from 'vue-i18n';
import LabeledText from './LabeledText.vue';

const { t } = useI18n();

const props = defineProps<{
  name: string;
  defaultValue: string | number | null;
  formattedDefaultValue?: string | number | null;
  initialValue?: string | number | null;
  type?: string;
  saveNumberAsString?: boolean;
  emptyValueTo?: 'null' | 'undefined';
  maxFractionDigits?: number;
  min?: number;
  isError?: boolean;
  disabled?: boolean;
  label?: string;
}>();

const {
  errorMessage: error,
  value,
  setValue,
} = useField<string | number | null | undefined>(props.name, undefined, {
  initialValue: props.initialValue,
});

const showInputField = ref(value.value !== undefined && value.value !== null);

const toggleShowInputField = () => {
  showInputField.value = !showInputField.value;
  if (showInputField.value) {
    setValue(props.defaultValue);
  } else {
    setValue(null);
  }
};

if (props.emptyValueTo) {
  watch(value, (newValue) => {
    const emptyValue = props.emptyValueTo === 'null' ? null : undefined;
    value.value = newValue === '' || newValue === undefined ? emptyValue : newValue;
  });
}
if (props.saveNumberAsString) {
  watch(value, (newValue) => {
    value.value = props.saveNumberAsString ? `${newValue}` : newValue;
  });
}
watch(value, (newValue) => {
  showInputField.value = newValue !== null;
});
</script>

<i18n lang="json">
{
  "en": {
    "override": "Override",
    "restoreDefault": "Restore default"
  },
  "de": {
    "override": "Überschreiben",
    "restoreDefault": "Standard wiederherstellen"
  }
}
</i18n>
