import { useQuery } from '@tanstack/vue-query';
import { agentProvisionService } from '@/api/agent-provision.service';
import type { MaybeRefOrGetter } from '@vueuse/core';
import { toValue } from '@vueuse/core';

export const useAgentProvisionSummary = () =>
  useQuery({
    queryKey: ['agent-provision'],
    queryFn: () => agentProvisionService.getSummary(),
  });

export const useYearlyAgentProvisionSummary = (
  userId: MaybeRefOrGetter<string>,
  enabled?: MaybeRefOrGetter<boolean>,
) => {
  return useQuery({
    queryKey: ['agent-provision-yearly', userId],
    queryFn: () => agentProvisionService.getYearlySummary(toValue(userId)),
    enabled: enabled,
  });
};
