<template>
  <div class="mt-2 grid grid-cols-2 gap-4">
    <div class="border border-primary/40 bg-primary/10 p-2 pb-1 pt-1.5">
      <div v-if="booking.pickupLocationType === BookingLocationType.STATION">
        <div class="mb-1 font-medium">{{ t('station') }}</div>
        <div>
          {{ getLocalizedField(booking.pickupStation?.info) }}<br />
          <span v-if="!hideStationAddresses">
            {{ booking.pickupStation?.street }}<br />{{
              `${booking.pickupStation?.zip} ${booking.pickupStation?.city}`
            }}
          </span>
        </div>
      </div>
      <div v-else>
        <div v-if="!isFetchingPickupLocation">
          <div class="mb-1 font-medium">{{ t('street') }}</div>
          <div>
            {{
              booking.pickupLocationGeocodedAddress?.displayName ??
              pickupLocationData?.address?.displayName
            }}
          </div>
        </div>
        <div v-else class="flex justify-center"><Spinner /></div>
      </div>
    </div>

    <div class="border border-primary/40 bg-primary/10 p-2 pb-1 pt-1.5">
      <div v-if="booking.dropoffLocationType === BookingLocationType.STATION">
        <div class="mb-1 font-medium">{{ t('station') }}</div>
        <div>
          {{ getLocalizedField(booking.dropoffStation?.info) }}<br />
          <span v-if="!hideStationAddresses">
            {{ booking.dropoffStation?.street }}<br />{{
              `${booking.dropoffStation?.zip} ${booking.dropoffStation?.city}`
            }}
          </span>
        </div>
      </div>
      <div v-else>
        <div v-if="!isFetchingDropoffLocation">
          <div class="mb-1 font-medium">{{ t('street') }}</div>
          <div>
            {{
              booking.dropoffLocationGeocodedAddress?.displayName ??
              dropoffLocationData?.address?.displayName
            }}
          </div>
        </div>
        <div v-else class="flex justify-center"><Spinner /></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import type { Booking } from '@/entities/bookings/booking.entity';
import { useLocalizedFields } from '@/hooks/use-localized-fields';
import { useLocationsData } from '@/hooks/use-location-data';
import { pointToLocationDto } from '@/hooks/use-location-to-coordinates';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  booking: Booking;
  hideStationAddresses?: boolean;
}>();

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();

const {
  pickupLocationData,
  isFetchingPickupLocation,
  dropoffLocationData,
  isFetchingDropoffLocation,
} = useLocationsData(
  computed(() => ({
    pickupLocationType: props.booking.pickupLocationType,
    dropoffLocationType: props.booking.dropoffLocationType,
    pickupLocation: pointToLocationDto(props.booking.pickupLocation),
    dropoffLocation: pointToLocationDto(props.booking.dropoffLocation),
    pickupLocationGeocodedAddress: props.booking.pickupLocationGeocodedAddress,
    dropoffLocationGeocodedAddress: props.booking.dropoffLocationGeocodedAddress,
  })),
);
</script>

<i18n lang="json">
{
  "en": {
    "street": "Street"
  },
  "de": {
    "street": "Straße"
  }
}
</i18n>
