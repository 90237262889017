<template>
  <div>
    <LabeledText v-if="customer" :label="t('relatedCustomer')" class="mb-4">
      <p>{{ `${t('customerNumber')} ${customer.customerNumber}` }}</p>
      <p v-if="customer.company">{{ customer.company }}</p>
      <p>{{ customer.firstName }} {{ customer.lastName }}</p>
      <p>{{ customer.street }}</p>
      <p>{{ customer.zip }} {{ customer.city }}</p>
      <p>{{ customer.country }}</p>
    </LabeledText>
    <CVButton v-if="!showInput" outline @click="showInput = true">{{
      t('switchCustomer')
    }}</CVButton>
    <div v-else class="flex items-center justify-start gap-4">
      <SearchCustomerInput
        class="w-full"
        :error="errorMessage"
        @on-update-user="onUpdateCustomer"
      />
      <CVButton v-if="customer" variant="warning" @click="showInput = false">{{
        t('cancel')
      }}</CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import LabeledText from '@/components/LabeledText.vue';
import SearchCustomerInput from '@/components/booking-forms/customer/SearchCustomerInput.vue';
import type { User } from '@/entities/auth/user.entity';
import { useI18n } from 'vue-i18n';
import { useField } from 'vee-validate';
import { ref } from 'vue';

const { t } = useI18n();

const customer = ref<User>();
const showInput = ref(true);

const { value: customerId, errorMessage } = useField<string | undefined>('customerId');

const onUpdateCustomer = (selectedCustomer: User | undefined) => {
  customer.value = selectedCustomer ?? customer.value;
  customerId.value = selectedCustomer?.id ?? customerId.value;
  if (selectedCustomer) {
    showInput.value = false;
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "relatedCustomer": "Related customer",
    "switchCustomer": "Switch customer",
    "customerNumber": "Cnr."
  },
  "de": {
    "relatedCustomer": "Verknüpfte Kunde",
    "switchCustomer": "Kunde wechseln",
    "customerNumber": "Knr."
  }
}
</i18n>
