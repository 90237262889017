<template>
  <label>
    <InputLabel :label="label" :tooltip="tooltip" :is-error="isError" :warning="warning" />
    <VueDatepicker
      :format="dateFormat"
      :locale="locale"
      :format-locale="formatLocale"
      auto-apply
      close-on-auto-apply
      :enable-time-picker="false"
      v-bind="$attrs"
      input-class-name="datepicker-input"
    />
    <InputHint :hint="hint" :is-error="isError" />
  </label>
</template>

<script lang="ts" setup>
import { de, enUS } from 'date-fns/locale';
import { i18n } from '@/i18n';
import { toValue } from '@vueuse/core';
import { computed } from 'vue';
import VueDatepicker from '@vuepic/vue-datepicker';
import { useDateFormat } from '@/hooks/use-date-format';
import InputLabel from './InputLabel.vue';
import InputHint from './InputHint.vue';

defineProps<{
  label?: string;
  hint?: string;
  error?: string;
  isError?: boolean;
  warning?: string;
  tooltip?: string;
}>();

const locale = i18n.global.locale;
const formatLocale = computed(() => (toValue(locale) === 'de' ? de : enUS));

const dateFormat = useDateFormat();
</script>

<style>
.datepicker-input:disabled {
  background-color: transparent !important;
  border-color: #cdaf746b !important;
  opacity: 0.5;
}
</style>
