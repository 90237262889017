<template>
  <div class="flex items-center gap-4">
    <div class="w-28"><img :src="imageUrl" /></div>
    <div>
      <p>{{ car.vehicleType.make.name }} {{ car.vehicleType.model }}</p>
      <p>{{ car.licencePlate }}</p>
      <p>{{ getLocalizedField(carStation.info) }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Car } from '@/entities/cars/car.entity';
import { useStationOfCarByDate } from '@/hooks/use-current-station-of-car';
import { useTrueCarAttributes } from '@/hooks/use-true-car-attributes';
import { toRef } from 'vue';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

const props = defineProps<{
  car: Car;
  startDate: Date;
}>();

const { getLocalizedField } = useLocalizedFields();

const carStation = useStationOfCarByDate(toRef(props, 'car'), toRef(props, 'startDate'));

const { imageUrl } = useTrueCarAttributes(props.car);
</script>

<i18n lang="json">
{
  "en": {},
  "de": {}
}
</i18n>
