import { makesService } from '@/api/makes.service';
import type { CreateMakeDto, UpdateMakeDto } from '@/entities/make.entity';
import { useMutation, useQuery } from '@tanstack/vue-query';

const QUERY_KEY = 'makes';

export const useMake = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: () => makesService.getOne(id),
  });
};

export const useMakes = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => makesService.getAll(),
  });
};

export const useUpdateMake = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ id, make }: { id: string; make: UpdateMakeDto }) =>
      makesService.update(id, make),
  });
};

export const useCreateMake = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (make: CreateMakeDto) => makesService.create(make),
  });
};
