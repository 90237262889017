<template>
  <HeaderBar />

  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
    <AuditLogButton entity-type="User" :entity-id="userId" />
  </ButtonBackContainer>

  <section class="carvia-container">
    <UserForm
      v-if="(user || userNew) && stations && me"
      :user="user"
      :me="me"
      :stations="stations"
      :is-loading="isLoading"
      :is-fetching="isFetching"
      @on-submit="onSubmit"
      @refetch="refetch"
    />
    <div v-if="userHasProvision && userProvision.data.value">
      <Divider />
      <h2 class="my-4">{{ t('provisions') }}</h2>
      {{
        t('provisionsTime', {
          start: formatDate(userProvision.data.value.startDate, getDateFormat()),
          end: formatDate(userProvision.data.value.endDate, getDateFormat()),
        })
      }}
      <br />
      {{
        t('provisionsContent', {
          handovers: userProvision.data.value.handovers,
          returns: userProvision.data.value.returns,
          detailings: userProvision.data.value.detailings,
          extras: formatCurrency(userProvision.data.value.extrasProvisions),
          ratings: formatCurrency(userProvision.data.value.ratingProvisions),
        })
      }}
    </div>
  </section>
  <section class="carvia-container flex flex-col gap-4">
    <h2>{{ t('bookings') }}</h2>
    <UserBookings :user-id="userId" />
  </section>
</template>

<script lang="ts" setup>
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import type { CreateUserDto, UpdateUserDto } from '@/entities/auth/user.entity';
import { useI18n } from 'vue-i18n';
import { useStations } from '@/queries/use-stations';
import { useCreateUser, useMe, useUpdateUser, useUser } from '@/queries/use-users';
import { useUiStore } from '@/stores/ui.store';
import { computed, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import UserForm from './UserForm.vue';
import router from '@/router';
import { UserRole } from '@/entities/auth/user-role.enum';
import AuditLogButton from '@/components/audit-log/AuditLogButton.vue';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { useYearlyAgentProvisionSummary } from '@/queries/use-agent-provision-summary';
import { formatCurrency } from '../../../utils/format-numbers';
import { formatDate } from '../../../hooks/use-formatted-date';
import { getDateFormat } from '../../../hooks/use-date-format';
import Divider from '@/components/Divider.vue';
import UserBookings from './UserBookings.vue';

const { t } = useI18n();
const uiStore = useUiStore();
const route = useRoute();
const userId = route.params.id as string;
const userNew = ref(userId === 'new');
const userFetchingEnabled = computed(() => !userNew.value);

const { data: me, isLoading: isLoadingMe } = useMe();
const { data: user, refetch, isFetching } = useUser(userId, userFetchingEnabled);
const { mutateAsync: createUser, isPending: isSaving } = useCreateUser();
const { mutateAsync: updateUser, isPending: isUpdating } = useUpdateUser();

const { data: stationsData, isFetching: isLoadingStations } = useStations(500);
const stations = useFlattenPaginatedData(stationsData);

const isLoading = computed(
  () => isSaving.value || isUpdating.value || isLoadingStations.value || isLoadingMe.value,
);

const userHasProvision = computed(
  () => !!user.value?.role && [UserRole.HUB_AGENT, UserRole.HUB_MANAGER].includes(user.value?.role),
);
const userProvision = useYearlyAgentProvisionSummary(userId, userHasProvision);

watchEffect(() => {
  if (user.value) {
    const { firstName, lastName, email } = user.value;
    uiStore.setHeaderTitle(t('editUser'), firstName + ' ' + lastName, email);
  } else {
    uiStore.setHeaderTitle(t('createUser'));
  }
});

const onSubmit = async (formValues: UpdateUserDto) => {
  const { role: _role, ...formValuesWithoutRole } = formValues;
  const authUserIsAdmin = me.value?.role === UserRole.ADMIN;
  if (!userNew.value) {
    await updateUser({
      id: userId,
      user: authUserIsAdmin ? formValues : formValuesWithoutRole,
    });
  } else {
    await createUser(formValues as CreateUserDto);
    router.push({ name: 'userList' });
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "editUser": "Edit User",
    "createUser": "Create User",
    "provisions": "Provisions",
    "provisionsTime": "Provisions in the period from {start} to {end}:",
    "provisionsContent": "{handovers} handovers, {returns} returns, {detailings} detailings, {extras} extras, {ratings} ratings",
    "bookings": "Bookings"
  },
  "de": {
    "editUser": "Benutzer bearbeiten",
    "createUser": "Benutzer erstellen",
    "provisions": "Provisionen",
    "provisionsTime": "Provisionen im Zeitraum von {start} bis {end}:",
    "provisionsContent": "{handovers} Übergaben, {returns} Rückgaben, {detailings} Aufbereitungen, {extras} Extras, {ratings} 5★-Provisionen",
    "bookings": "Buchungen"
  }
}
</i18n>
