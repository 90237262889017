<template>
  <HeaderBar />

  <ButtonBackContainer>
    <ButtonBack>{{ t('backToBooking') }}</ButtonBack>
  </ButtonBackContainer>

  <section class="carvia-container">
    <div v-if="(transfer || transferNew) && booking && bookingReady && car">
      <TransferEditForm
        :transfer="transfer"
        :booking="booking"
        :pickup-transfer-location="pickupTransferLocation"
        :dropoff-transfer-location="dropoffTransferLocation"
        :transfer-new="transferNew"
        :transfer-type="transferType"
        :is-saving="isSaving"
        @on-submit="handleSubmit"
      />
    </div>
    <div v-else class="flex justify-center">
      <Spinner />
    </div>
  </section>
</template>

<script lang="ts" setup>
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import type {
  CreateInternalBookingDto,
  UpdateInternalBookingDto,
} from '@/entities/bookings/internal-booking.entity';
import { useTransferLocations } from '@/hooks/use-transfer-locations';
import { useI18n } from 'vue-i18n';
import { useBooking, useUpdateBooking } from '@/queries/use-bookings';
import {
  useCreateInternalBooking,
  useUpdateInternalBooking,
} from '@/queries/use-internal-bookings';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import { BookingType } from '@/entities/bookings/booking-type.enum';
import { useCar } from '@/queries/use-cars';
import router from '@/router';
import { useUiStore } from '@/stores/ui.store';
import { computed, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import TransferEditForm from './TransferEditForm.vue';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';

const uiStore = useUiStore();
const { t } = useI18n();
const route = useRoute();
const bookingId = ref(route.params.bookingId as string);
const transferId = ref(route.params.transferId as string);
const transferNew = ref(transferId.value === 'new');
const transferType = ref<BookingType>(route.params.transferType as BookingType);
const bookingFetchingEnabled = computed(() => !transferNew.value);
const { data: booking } = useBooking(bookingId.value);
const { data: transfer } = useBooking(transferId, bookingFetchingEnabled);
const carId = computed(() => booking.value?.car.id ?? '');
const carFetchingEnabled = computed(() => !!booking.value?.id);
const { data: car } = useCar(carId, carFetchingEnabled);
const bookingReady = ref(false);

watchEffect(() => {
  if (!booking.value) return;
  const existingTransferId = booking.value?.relatedBookings.find(
    (booking) => booking.type === transferType.value,
  )?.id;

  if (transferNew.value && existingTransferId) {
    transferNew.value = false;
    transferId.value = existingTransferId;
    router.replace({
      name: 'transferEdit',
      params: {
        transferType: transferType.value,
        bookingId: bookingId.value,
        transferId: existingTransferId,
      },
    });
  }
  bookingReady.value = true;
});

const { pickupTransferLocation, dropoffTransferLocation } = useTransferLocations(
  transferType,
  transfer,
  car,
  booking,
);

watchEffect(() => {
  const title = t(
    transferType.value === BookingType.TRANSFER_DELIVERY_BOOKING ? 'delivery' : 'return',
  );
  if (booking.value) {
    const { customerData, car } = booking.value;
    uiStore.setHeaderTitle(
      title,
      `${customerData?.firstName ?? 'n/a'} ${customerData?.lastName ?? 'n/a'}`,
      `${car.vehicleType?.make.name} ${car.vehicleType?.model}`,
    );
  } else {
    uiStore.setHeaderTitle(title);
  }
});

const { isPending: isSavingTransfer, mutateAsync: updateTransfer } = useUpdateInternalBooking();
const { isPending: isCreatingTransfer, mutateAsync: createTransfer } = useCreateInternalBooking();
const { isPending: isSavingBooking, mutateAsync: updateBooking } = useUpdateBooking();

const isSaving = computed(
  () => isSavingTransfer.value || isCreatingTransfer.value || isSavingBooking.value,
);

const handleSubmit = async (formValues: UpdateInternalBookingDto) => {
  if (!pickupTransferLocation.value || !dropoffTransferLocation.value) return;
  const locations: UpdateInternalBookingDto = {
    pickupStationId: pickupTransferLocation.value.station?.id ?? null,
    dropoffStationId: dropoffTransferLocation.value.station?.id ?? null,
    pickupLocationType: pickupTransferLocation.value.locationType,
    dropoffLocationType: dropoffTransferLocation.value.locationType,
    pickupLocation: pickupTransferLocation.value.location?.coordinates,
    dropoffLocation: dropoffTransferLocation.value.location?.coordinates,
    pickupLocationGeocodedAddress: pickupTransferLocation.value.location?.address,
    dropoffLocationGeocodedAddress: dropoffTransferLocation.value.location?.address,
  };

  if (!transferNew.value) {
    const updateTransferValues: UpdateInternalBookingDto = {
      ...formValues,
      ...locations,
    };

    updateTransfer({
      id: transferId.value,
      internalBooking: updateTransferValues,
    });
  } else {
    if (!booking.value) return;
    const createTransferValues: CreateInternalBookingDto = {
      ...(formValues as CreateInternalBookingDto),
      ...locations,
      carId: booking.value.car.id,
      relatedBookingIds: [booking.value.id],
      type: transferType.value,
    };

    const { id: transferId } = await createTransfer(createTransferValues);
    updateBooking({
      id: booking.value.id,
      booking: {
        relatedBookingIds: [
          ...booking.value.relatedBookings.map((booking) => booking.id),
          transferId,
        ],
      },
    });
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "booking": "Booking",
    "delivery": "Delivery",
    "return": "Return",
    "backToBooking": "Back to Booking"
  },
  "de": {
    "booking": "Buchung",
    "delivery": "Anlieferung",
    "return": "Rückführung",
    "backToBooking": "Zurück zur Buchung"
  }
}
</i18n>
