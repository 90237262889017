import { Service } from './http-service';
import type { Survey } from '@/entities/surveys/survey.entity';

class SurveysService extends Service<Survey, undefined, undefined> {
  async exportSurveyCsv({ surveyId }: { surveyId: string }) {
    const res = await this.httpClient.post<Blob>(`${this.endpoint}/${surveyId}/export`);
    return res.data;
  }
}

export const surveysService = new SurveysService('/surveys');
