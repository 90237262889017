<template>
  <div>
    <div v-if="!hideAddress && location?.coordinates" class="mb-2">
      <div class="relative gap-1 border border-primary/40 bg-primary/10 px-2 pb-1 pt-1.5">
        <div class="font-medium">{{ t('address') }}</div>
        <div class="text-sm">
          {{ location?.address?.displayName }}
        </div>
      </div>
    </div>
    <LabeledText v-else :label="t('address')" class="mb-1">
      <div v-if="location?.coordinates" class="text-success">{{ t('addressIsSet') }} ✓</div>
      <div v-else>
        {{ t('pleaseChooseLocation') }}
      </div>
    </LabeledText>

    <GooglePlacesInput
      v-if="!location?.coordinates || changeAddress"
      @select-address="setLocation"
    />

    <div v-if="!disabled" class="mt-3 flex gap-4">
      <CVButton
        v-if="location?.coordinates && !changeAddress"
        class="flex-grow"
        @click.prevent="changeAddress = true"
      >
        {{ t('changeAddress') }}
      </CVButton>
      <CVButton v-if="loggedInLocation" variant="success" class="flex-grow" @click.prevent="onSave">
        {{ t('confirm') }}
      </CVButton>
      <CVButton v-if="changeAddress" variant="warning" class="flex-grow" @click.prevent="onCancel">
        {{ t('cancel') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { GeocodedLocation } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import GooglePlacesInput, {
  type ExtendedGeocodedLocation,
} from '@/components/GooglePlacesInput.vue';
import LabeledText from '../LabeledText.vue';

defineProps<{
  location: GeocodedLocation | null | undefined;
  hideAddress?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:location', value: ExtendedGeocodedLocation | null): void;
}>();

const { t } = useI18n();

const loggedInLocation = ref<ExtendedGeocodedLocation | null>(null);

const changeAddress = ref(false);

const setLocation = (location: ExtendedGeocodedLocation | null) => {
  loggedInLocation.value = location
    ? {
        ...location,
        coordinates: {
          latitude: parseFloat(location.coordinates?.latitude.toFixed(7) ?? ''),
          longitude: parseFloat(location.coordinates?.longitude.toFixed(7) ?? ''),
        },
      }
    : location;
  changeAddress.value = true;
};

const onSave = () => {
  changeAddress.value = false;
  emit('update:location', loggedInLocation.value);
  loggedInLocation.value = null;
};

const onCancel = () => {
  changeAddress.value = false;
  loggedInLocation.value = null;
};
</script>

<i18n lang="json">
{
  "en": {
    "typeLocation": "Type in Location ...",
    "address": "Address",
    "changeAddress": "Change Address",
    "addressIsSet": "Address is set",
    "pleaseChooseLocation": "Please choose a location"
  },
  "de": {
    "typeLocation": "Gib einen Ort ein ...",
    "address": "Adresse",
    "changeAddress": "Adresse ändern",
    "addressIsSet": "Adresse ist gesetzt",
    "pleaseChooseLocation": "Bitte wähle einen Ort"
  }
}
</i18n>
