<template>
  <h2 class="mb-4">{{ t('customer') }}</h2>

  <div v-if="!editCustomerData && booking.customerData">
    <div class="mb-4 border-b border-primary/40 pb-4">
      <CustomerOverview :customer-data="booking.customerData" />
      <CVButton
        v-if="$can('update', subject('Booking', booking), 'customerData')"
        class="mt-4"
        :disabled="isLoading"
        @click.prevent="editCustomerData = true"
        >{{ t('editCustomerData') }}</CVButton
      >
    </div>

    <div v-if="!isSwappingCustomer" class="flex items-center justify-between">
      <div class="flex items-center gap-2.5">
        <p>{{ t('customerAccount') }}:</p>
        <p class="rounded-lg bg-primary/20 px-3 py-1">
          {{ booking.customer?.email ?? t('noCustomerAccount') }}
        </p>
      </div>
      <div
        v-if="booking.customer?.id && $can('update', subject('Booking', booking), 'customer')"
        class="flex gap-4"
      >
        <CVButton
          v-if="customerCanBeRemoved"
          outline
          variant="error"
          :is-loading="isLoading"
          @click.prevent="removeAccount"
          >{{ t('removeAccount') }}</CVButton
        >
        <CVButton outline :disabled="isLoading" @click.prevent="isSwappingCustomer = true">{{
          t('swapAccount')
        }}</CVButton>
        <CVButton
          v-if="$can('manage', 'User')"
          outline
          :disabled="isLoading"
          @click.prevent="viewAccount"
          >{{ t('viewAccount') }}</CVButton
        >
      </div>
    </div>
  </div>

  <div v-else-if="editCustomerData">
    <div v-if="!booking.customer">
      <div class="grid grid-cols-3 gap-4">
        <InputField name="email" :label="t('email')" :tooltip="t('emailInformation')" />
      </div>
      <Divider class="opacity-40" />
    </div>

    <CustomerDataForm hide-header />

    <Divider class="opacity-40" />

    <div class="flex justify-end gap-4">
      <CVButton variant="success" :is-loading="isLoading" @click.prevent="onUpdateCustomerData">{{
        t('save')
      }}</CVButton>
      <CVButton variant="warning" :disabled="isLoading" @click.prevent="editCustomerData = false">{{
        t('cancel')
      }}</CVButton>
    </div>
  </div>

  <SwapCustomer
    v-if="isSwappingCustomer"
    :schema="validationSchema"
    :is-saving="isLoading"
    @swap-customer="swapCustomer"
    @cancel="isSwappingCustomer = false"
  />
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import CustomerDataForm from './CustomerDataForm.vue';
import { computed, ref, watchEffect } from 'vue';
import Divider from '@/components/Divider.vue';
import CustomerOverview from './CustomerOverview.vue';
import { useForm } from 'vee-validate';
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import SwapCustomer from './SwapCustomer.vue';
import { useRouter } from 'vue-router';
import InputField from '@/components/InputField.vue';
import { customerEditSchema } from '@/validation/customer.schema';
import { useCreateUser } from '@/queries/use-users';
import { Alert } from '@/utils/alert';
import { Local } from '@/entities/auth/local.enum';
import { toTypedSchema } from '@vee-validate/yup';
import { usersService } from '@/api/users.service';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import { subject } from '@casl/ability';
import type { User } from '@/entities/auth/user.entity';
import { useGenerateSwapCustomerDto } from '@/components/booking-forms/customer/use-generate-swap-customer-dto';

const props = defineProps<{
  booking: Booking;
  startDate: string;
  isSaving: boolean;
}>();

const emit = defineEmits<{
  (
    e: 'updateBooking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished?: () => void,
  ): void;
}>();

const { t } = useI18n();
const router = useRouter();

const editCustomerData = ref(false);
const isSwappingCustomer = ref(false);

const { mutateAsync: createUser, isPending: isCreatingUser } = useCreateUser();

const isLoading = computed(() => props.isSaving || isCreatingUser.value);

const customerCanBeRemoved = computed(
  () =>
    props.booking.status === BookingStatus.DRAFT ||
    props.booking.status === BookingStatus.OPEN ||
    props.booking.status === BookingStatus.CONFIRMED ||
    props.booking.status === BookingStatus.FLOATING,
);

const initializeFormValues = () => {
  return {
    ...props.booking.customerData,
    dateOfBirth: props.booking.customerData?.dateOfBirth
      ? new Date(props.booking.customerData.dateOfBirth)
      : undefined,
    email: props.booking.customer?.email,
  };
};

const validationSchema = customerEditSchema(
  computed(() => props.booking.car.vehicleType.minAge),
  computed(() => props.booking.startDate),
);

const { handleSubmit, setValues } = useForm({
  initialValues: initializeFormValues(),
  validationSchema: toTypedSchema(validationSchema),
});

watchEffect(() => setValues(initializeFormValues()));

const onUpdateCustomerData = handleSubmit(async (values) => {
  const { email, ...customerData } = values;
  const { emailExists, existingUser } = await checkIfEmailExists(email);
  let customerId = props.booking.customer?.id ?? existingUser?.id;
  if (!customerId && email) {
    if (emailExists) return;
    const { id } = existingUser
      ? existingUser
      : await createUser({
          ...customerData,
          preferredLocal: customerData.preferredLocal ?? Local.EN_US,
          email,
        });
    customerId = id;
  }
  emit(
    'updateBooking',
    {
      id: props.booking.id,
      booking: { customerData, customerId },
    },
    () => (editCustomerData.value = false),
  );
});

const checkIfEmailExists = async (email?: string) => {
  if (props.booking.customer || !email)
    return {
      emailExists: false,
      existingUser: null,
    };
  const user = await usersService.getOneByEmailIfExists(email);
  if (!user?.id) {
    return {
      emailExists: false,
      existingUser: null,
    };
  }
  const { isConfirmed } = await Alert.fire({
    titleText: t('emailExistsTitle'),
    html: t('emailExistsText', {
      name: user.firstName + ' ' + user.lastName,
      customerNumber: user.customerNumber,
      email: user.email,
    }),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('useAccount'),
    cancelButtonText: t('dontUse'),
  });

  return {
    emailExists: true,
    existingUser: isConfirmed ? user : null,
  };
};

const generateSwapCustomerDto = useGenerateSwapCustomerDto();
const swapCustomer = async (customer: User) => {
  emit(
    'updateBooking',
    {
      id: props.booking.id,
      booking: await generateSwapCustomerDto(customer),
    },
    () => {
      isSwappingCustomer.value = false;
    },
  );
};

const viewAccount = () => {
  if (!props.booking.customer?.id) return;
  router.push({
    name: 'user',
    params: { id: props.booking.customer?.id },
  });
};

const removeAccount = async () => {
  const { isConfirmed } = await Alert.fire({
    text: t('removeAccountText'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('removeAccount'),
    cancelButtonText: t('cancel'),
  });

  if (isConfirmed) {
    emit('updateBooking', {
      id: props.booking.id,
      booking: { customerId: null },
    });
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "customer": "Customer",
    "editCustomerData": "Edit customer data",
    "swapAccount": "Swap Account",
    "viewAccount": "View Account",
    "swap": "Swap",
    "createNewCustomer": "+ Create new customer",
    "customerAccount": "Customer account",
    "noCustomerAccount": "No customer account linked",
    "emailInformation": "The email will be used to create a new customer account. Contract and Invoices will be sent to this address. Please double check if the email is correct.",
    "emailExistsTitle": "Email already exists",
    "emailExistsText": "<div class='font-medium mb-3'>{email}<br/>{name} (Cnr. {customerNumber})</div>Do you want to use this existing account for the booking?",
    "useAccount": "Use Account",
    "dontUse": "Don't use",
    "email": "Email",
    "removeAccount": "Remove Account",
    "removeAccountText": "Do you really want to remove the linked customer account?"
  },
  "de": {
    "customer": "Kunde",
    "editCustomerData": "Kundendaten bearbeiten",
    "swapAccount": "Konto austauschen",
    "viewAccount": "Konto ansehen",
    "swap": "Austauschen",
    "createNewCustomer": "+ Neuen Kunden anlegen",
    "customerAccount": "Kundenkonto",
    "noCustomerAccount": "Kein verknüpftes Kundenkonto",
    "emailInformation": "Die Email wird verwendet um einen neuen Kundenaccount zu erstellen. Verträge und Rechnungen werden an diese Adresse gesendet. Bitte stelle sicher, dass die Email korrekt ist.",
    "emailExistsTitle": "Email existiert bereits",
    "emailExistsText": "<div class='font-medium mb-3'>{email}<br/>{name} (Knr. {customerNumber})</div>Möchtest du dieses bestehende Konto für die Buchung verwenden?",
    "useAccount": "Konto verwenden",
    "dontUse": "Nicht verwenden",
    "email": "Email",
    "removeAccount": "Konto entfernen",
    "removeAccountText": "Möchtest du das verknüpfte Kundenkonto wirklich entfernen?"
  }
}
</i18n>
