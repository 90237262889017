import Datepicker from '@/components/Datepicker.vue';
import '@vuepic/vue-datepicker/dist/main.css';
import 'mosha-vue-toastify/dist/style.css';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import './datepicker.scss';
import 'vue-final-modal/style.css';

import Multiselect from '@vueform/multiselect';
import App from './App.vue';
import { i18n } from './i18n';
import { vueQueryPluginOptions } from './queries/query-client';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import router from './router';
import enums from 'vue-enums';
import { createVfm, VueFinalModal } from 'vue-final-modal';
import { formatCurrency } from './utils/format-numbers';
import { formatDate } from './hooks/use-formatted-date';
import CVButton from './components/buttons/CVButton.vue';
import Icon from './components/icons/Icon.vue';
import Spinner from './components/icons/Spinner.vue';
import PrimeVue from 'primevue/config';
import * as Sentry from '@sentry/vue';
import { abilitiesPlugin } from '@casl/vue';
import { aliasResolver } from './entities/auth/abilities';
import { createMongoAbility } from '@casl/ability';

const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  tracesSampleRate: 0.05,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/api\.carvia\.de/,
    /^https:\/\/ros-api-stage\.herokuapp\.com/,
  ],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    'partial-range', // thrown by vue datepicker, but meaningless
  ],
  beforeSend(event, hint) {
    if (
      event.exception?.values?.[0]?.type === 'AxiosError' &&
      (event.exception.values[0].value === 'Network Error' ||
        event.exception.values[0].value === 'Request failed with status code 400' ||
        event.exception.values[0].value === 'Request failed with status code 401' ||
        event.exception.values[0].value?.startsWith('timeout of'))
    ) {
      return null;
    }
    if (
      event.exception?.values?.[0]?.type === 'AbortError' &&
      event.exception.values[0].value === 'Fetch is aborted'
    ) {
      return null;
    }
    if (event.exception?.values?.[0].value === 'Load failed') {
      return null;
    }
    return event;
  },
});

app.component('Datepicker', Datepicker);
app.component('VueFinalModal', VueFinalModal);
app.component('Multiselect', Multiselect);
app.component('PulseLoader', PulseLoader);
app.component('CVButton', CVButton);
app.component('Icon', Icon);
app.component('Spinner', Spinner);

app.use(i18n);
app.use(createPinia());
app.use(router);

app.use(VueQueryPlugin, vueQueryPluginOptions);
const vfm = createVfm();
app.use(vfm);
app.use(PrimeVue);

app.directive('currency', (el, binding) => {
  el.textContent = formatCurrency(binding.value);
});

app.directive('date', (el, binding) => {
  el.textContent = formatDate(binding.value.value, binding.value.format);
});

export const ability = createMongoAbility([]);

app.use(
  abilitiesPlugin,
  ability, // init with empty ability
  {
    useGlobalProperties: true,
    resolveAction: aliasResolver,
  },
);

app.use(enums);

app.mount('#app');
