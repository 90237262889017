import { Alert } from '@/utils/alert';
import { i18n } from '@/i18n';
import type { Booking } from '@/entities/bookings/booking.entity';
import { PaymentLinkReceiver } from '@carvia/ros-client-types';

export const selectPaymentLinkReceiver = async (booking: Booking) => {
  const t = i18n.global.t;
  const inputOptions: Record<string, string> = {};
  if (booking.customer) inputOptions[PaymentLinkReceiver.CUSTOMER] = t('paymentLink.customer');
  if (booking.agent) inputOptions[PaymentLinkReceiver.AGENCY] = t('paymentLink.agency');
  inputOptions[PaymentLinkReceiver.NONE] = t('paymentLink.copyLink');
  const { value, isConfirmed } = await Alert.fire({
    text: t('paymentLink.selectReceiverText'),
    icon: 'question',
    showDenyButton: true,
    confirmButtonText: t('paymentLink.createPaymentLink'),
    denyButtonText: t('cancel'),
    input: 'select',
    inputOptions,
  });
  if (!isConfirmed) return;
  return value as PaymentLinkReceiver;
};

export const showPaymentLinkToCopy = (paymentLink: string) => {
  const t = i18n.global.t;
  navigator.clipboard.writeText(paymentLink);
  Alert.fire({
    html: t('paymentLink.copyThisLinkText'),
    icon: 'success',
    confirmButtonText: t('close'),
    input: 'text',
    inputValue: paymentLink,
    inputAttributes: { readonly: 'readonly' },
  });
};
