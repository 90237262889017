<template>
  <div class="w-22 group relative z-[999] select-none">
    <div
      class="selected-option"
      tabindex="0"
      @click="toggleDropdown"
      @focusout="isDropdownOpen = false"
    >
      <img :src="selectedOption?.image" class="h-6 w-6" />
      <span>{{ selectedOption?.text }}</span>
    </div>
    <transition name="fade">
      <div v-if="isDropdownOpen" class="dropdown-options">
        <div
          v-for="option in options"
          :key="option.language"
          class="option"
          @click="selectOption(option)"
        >
          <img :src="option.image" class="h-6 w-6" />
          <span class="ml-2">{{ option.text }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { getLanguage, Local } from '@/entities/auth/local.enum';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import germany from '@/assets/country-flags/germany.png';
import unitedStates from '@/assets/country-flags/united-states.png';

interface LanguageOption {
  language: Local;
  text: string;
  image: string;
}

const { locale } = useI18n({
  useScope: 'global',
});

const options: LanguageOption[] = [
  {
    language: Local.EN_US,
    text: 'EN',
    image: unitedStates,
  },
  {
    language: Local.DE_DE,
    text: 'DE',
    image: germany,
  },
];

const isDropdownOpen = ref(false);
const selectedOption = ref(
  options.find((option) => {
    return getLanguage(option.language) === locale.value;
  }),
);

function toggleDropdown() {
  isDropdownOpen.value = !isDropdownOpen.value;
}

function selectOption(option: LanguageOption) {
  selectedOption.value = option;
  isDropdownOpen.value = false;
  locale.value = getLanguage(option.language) as 'en' | 'de';
}
</script>

<style scoped>
.selected-option {
  @apply flex h-min min-w-max cursor-pointer items-center space-x-2 border
  border-primary/40 bg-accent px-4 py-1 transition focus:border-primary;
}

.dropdown-options {
  @apply absolute left-0 top-full flex w-full min-w-max flex-col border
  border-gray-300 bg-white py-[2px] selection:bg-blue-400;
}

.option {
  @apply flex cursor-pointer items-center justify-center px-2 py-1 transition hover:bg-cyan-500;
}

.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-200;
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
</style>
