<template>
  <div class="absolute inset-0">
    <div ref="itemChartCanvasContainer" class="absolute h-full w-full">
      <canvas ref="itemChartCanvas" class="absolute h-full w-full" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { DateTime } from 'luxon';
import { computed, onMounted, ref } from 'vue';

import type { BlockInternal, CategoryInternal, Config, LineInternal } from './interfaces';
import { type Day, getDay } from './utils';
import { useMousePosition } from '@/hooks/use-mouse-position';

const props = defineProps<{
  configObj: Config;
  categories?: CategoryInternal[];
  lines?: LineInternal[];
  todayDate: DateTime;
  currentScrollPosition: { x: number; y: number };
}>();

const emit = defineEmits<{
  (e: 'onShowHover', content: string[], yPos: number): void;
  (e: 'onHideHover'): void;
}>();

const res = 2;
const dateBarHeight = 100 * res;

const itemChartLineColor = '#eee';
const categoryBGColor = '#eee';
const blockStandardColor = '#888';
const blocksFont = `${12 * res}px arial`;
const itemChartTodayColor = '#e6ffdd';
const itemChartWeekendBGColor = '#f7f7f7';
const itemChartFontColor = '#fff';
const dateBarBGColor = '#fff';
const dateBarBorderColor = '#ddd';
const dateBarLineColor = '#ddd';
const dateBarTodayBGColor = '#e6ffdd';
const dateBarWeekendBGColor = '#eee';
const dateBarFontColor = '#000';

const itemChartCanvas = ref();
const itemChartCanvasContainer = ref();

let visibleBlocks: BlockInternal[] = [];
let visibleLines: LineInternal[] = [];
let ctx: CanvasRenderingContext2D | null;
let categoriesWidth: number;
let scaleX: number;
let scaleY: number;
let visibleDayCount: number;
let canvasScrollPosition: { x: number; y: number };
let currentScrollPositionDays: number;
let firstVisibleDay: DateTime;
let lastFirstVisibleDay: DateTime;
let calculatedDays: { day: Day; todayDiff: number }[];
let hoveredBlock: BlockInternal | null = null;

const mousePosition = useMousePosition();

const mousePositionRelative = computed(() => {
  const rect = itemChartCanvas.value.getBoundingClientRect();
  return {
    x: (mousePosition.value.x - rect.left) * res,
    y: (mousePosition.value.y - rect.top) * res,
  };
});

onMounted(() => {
  ctx = itemChartCanvas.value.getContext('2d');
  if (!ctx) return;
});

const loop = () => {
  if (!ctx) return;
  calculateRelevantValues(ctx);
  calculateDays();
  calculateBlockPositions(ctx);
  checkBlocksMouseOver();
  drawLines(ctx);
  drawBlocks(ctx);
  drawDateBar(ctx);
};

const calculateRelevantValues = (ctx: CanvasRenderingContext2D) => {
  ctx.canvas.width = itemChartCanvasContainer.value.offsetWidth * res;
  ctx.canvas.height = itemChartCanvasContainer.value.offsetHeight * res;
  categoriesWidth = 270 * res;
  scaleX = props.configObj.scaleX * res;
  scaleY = props.configObj.scaleY * res;
  visibleDayCount = Math.ceil(itemChartCanvas.value.width / scaleX) + 4;
  canvasScrollPosition = { ...props.currentScrollPosition };
  canvasScrollPosition.x = canvasScrollPosition.x - itemChartCanvasContainer.value.offsetWidth / 2;
  currentScrollPositionDays = Math.floor(canvasScrollPosition.x / props.configObj.scaleX - 3);
  firstVisibleDay = props.todayDate
    .minus({
      days: -currentScrollPositionDays,
    })
    .startOf('day');
};

const calculateDays = () => {
  if (lastFirstVisibleDay && lastFirstVisibleDay.diff(firstVisibleDay).as('days') === 0) {
    return;
  }
  lastFirstVisibleDay = firstVisibleDay;
  const newCalculatedDays = [];
  let currentDay = firstVisibleDay;
  for (let i = 0; i < visibleDayCount; i++) {
    let newCalculatedDay = calculatedDays?.find(
      (calculatedDay) => calculatedDay.day.dateTime.diff(currentDay).as('days') === 0,
    ) ?? {
      day: getDay(currentDay),
      todayDiff: currentDay.diff(props.todayDate).as('days'),
    };
    newCalculatedDays.push(newCalculatedDay);
    currentDay = currentDay.plus({ day: 1 });
  }
  calculatedDays = newCalculatedDays;
};

const calculateBlockPositions = (ctx: CanvasRenderingContext2D) => {
  const lastVisibleBlocks = visibleBlocks.map(({ id, xPos, width, shortenedTitle }) => ({
    id,
    xPos,
    width,
    shortenedTitle,
  }));
  visibleBlocks = [];
  visibleLines = [];
  if (!props.categories) return;
  let index = 0;
  for (const category of props.categories) {
    index++;
    if (!category.lines || category.hidden) continue;
    for (const line of category.lines) {
      if (line.hiddenByLineType || line.hiddenByTags || !line.blocks) continue;
      const yPos = index * scaleY - canvasScrollPosition.y * res + dateBarHeight;
      index++;

      // Don't process lines outside the canvas
      if (!(yPos >= -scaleY && yPos <= itemChartCanvas.value.height)) {
        continue;
      }
      visibleLines.push({ ...line, yPos });

      for (const block of line.blocks) {
        const xStartPos =
          block.xStartPosInDays !== null
            ? block.xStartPosInDays * scaleX - canvasScrollPosition.x * res
            : null;
        const xEndPos =
          block.xEndPosIndDays !== null
            ? block.xEndPosIndDays * scaleX - canvasScrollPosition.x * res
            : null;

        block.xPos = xStartPos ? xStartPos : -100;
        block.width = xEndPos ? xEndPos - block.xPos : ctx.canvas.width + 100 - block.xPos;
        block.yPos = yPos;

        if (!isVisibleElement(block.xPos, block.yPos, block.width)) continue;

        // Shorten title
        const lastBlock = lastVisibleBlocks.find((lastBlock) => lastBlock.id === block.id);
        const lastRemainingWidth = lastBlock ? getRemainingBlockWidth(lastBlock) : null;
        const remainingWidth = getRemainingBlockWidth(block);
        if (lastBlock && lastRemainingWidth === remainingWidth) {
          block.shortenedTitle = lastBlock.shortenedTitle;
        } else {
          ctx.font = blocksFont;
          block.shortenedTitle = shortenTitle(block.title, remainingWidth - 20, ctx);
        }

        visibleBlocks.push(block);
      }
    }
  }
  visibleBlocks.sort((a, b) => (a.tagObj?.zIndex ?? 0) - (b.tagObj?.zIndex ?? 0));
};

const getRemainingBlockWidth = (block: { xPos: number; width: number }) => {
  const cropped = categoriesWidth - block.xPos;
  return Math.min(block.width, block.width - cropped);
};

const isVisibleElement = (xPos: number, yPos: number, width: number) => {
  return (
    xPos >= -width &&
    xPos <= itemChartCanvas.value.width &&
    yPos >= scaleY &&
    yPos <= itemChartCanvas.value.height
  );
};

const checkBlocksMouseOver = () => {
  hoveredBlock = null;
  if (mousePositionRelative.value.x > categoriesWidth) {
    for (const block of [...visibleBlocks].slice().reverse()) {
      if (block.onClick || block.url || block.hover) {
        hoveredBlock =
          mousePositionRelative.value.x > block.xPos &&
          mousePositionRelative.value.x < block.xPos + block.width &&
          mousePositionRelative.value.y > block.yPos &&
          mousePositionRelative.value.y < block.yPos + scaleY
            ? block
            : null;
        if (hoveredBlock) break;
      }
    }
  }

  if (hoveredBlock && (hoveredBlock.onClick || hoveredBlock.url || hoveredBlock.hover)) {
    itemChartCanvasContainer.value.style.cursor = 'pointer';
  } else {
    itemChartCanvasContainer.value.style.cursor = null;
  }

  if (!hoveredBlock?.hover) {
    emit('onHideHover');
    return;
  }
  emit('onShowHover', hoveredBlock.hover, hoveredBlock.yPos / res);
};

const drawLines = (ctx: CanvasRenderingContext2D) => {
  if (!props.categories) return;

  ctx.lineWidth = 1 * res;

  // Draw Vertical Lines
  for (const calculatedDay of calculatedDays) {
    const { isWeekend, isToday } = calculatedDay.day;
    const xPos = calculatedDay.todayDiff * scaleX - canvasScrollPosition.x * res;

    if (isToday) {
      ctx.fillStyle = itemChartTodayColor;
      ctx.fillRect(xPos, 0, scaleX, itemChartCanvas.value.height);
    } else if (isWeekend) {
      ctx.fillStyle = itemChartWeekendBGColor;
      ctx.fillRect(xPos, 0, scaleX, itemChartCanvas.value.height);
    }

    ctx.strokeStyle = itemChartLineColor;
    ctx.beginPath();
    ctx.moveTo(xPos, 0);
    ctx.lineTo(xPos, itemChartCanvas.value.height);
    ctx.stroke();
  }

  // Draw Horizontal Lines
  let index = 0;
  for (const category of props.categories) {
    drawHorizontalLine(ctx, index, true);
    index++;
    if (!category.lines || category.hidden) continue;
    for (const line of category.lines) {
      drawHorizontalLine(ctx, index, !!line.color, line.color, 0.5);
      index++;
    }
  }
};

const drawHorizontalLine = (
  ctx: CanvasRenderingContext2D,
  index: number,
  drawBackground = false,
  backgroundColor = categoryBGColor,
  backgroundAlpha = 1,
) => {
  const yPos = index * scaleY - canvasScrollPosition.y * res + dateBarHeight;
  if (drawBackground) {
    ctx.globalAlpha = backgroundAlpha;
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, yPos, itemChartCanvas.value.width, scaleY);
    ctx.globalAlpha = 1;
  }

  ctx.strokeStyle = itemChartLineColor;
  ctx.beginPath();
  ctx.moveTo(0, yPos);
  ctx.lineTo(itemChartCanvas.value.width, yPos);
  ctx.stroke();
};

const drawBlocks = (ctx: CanvasRenderingContext2D) => {
  ctx.font = blocksFont;
  for (const block of visibleBlocks) {
    ctx.fillStyle = block.tagObj?.color ?? blockStandardColor;

    ctx.globalAlpha = block.tagObj?.alpha ?? 1;

    ctx.beginPath();
    ctx.rect(block.xPos, block.yPos + 2 * res, block.width, scaleY - 4 * res);
    ctx.fill();

    ctx.globalAlpha = 1;

    const textPos = Math.max(block.xPos, categoriesWidth);
    ctx.fillStyle = itemChartFontColor;
    ctx.fillText(block.shortenedTitle, textPos + 7 * res, block.yPos + scaleY - 8 * res);
  }
};

const shortenTitle = (title: string, maxLength: number, ctx: CanvasRenderingContext2D) => {
  while (ctx.measureText(title).width > maxLength && title.length > 0) {
    title = title.slice(0, title.length - 1);
  }
  let dottedTitle = title;
  let dotted = false;
  while (ctx.measureText(`${dottedTitle} ...`).width > maxLength && dottedTitle.length > 1) {
    dottedTitle = dottedTitle.slice(0, dottedTitle.length - 1);
    dotted = true;
  }
  if (dotted) {
    return dottedTitle + ' ...';
  }
  return title;
};

const drawDateBar = (ctx: CanvasRenderingContext2D) => {
  ctx.lineWidth = 1 * res;

  ctx.fillStyle = dateBarBGColor;
  ctx.fillRect(0, 0, ctx.canvas.width, dateBarHeight);

  for (const calculatedDay of calculatedDays) {
    const day = calculatedDay.day;
    const xPos = calculatedDay.todayDiff * scaleX - canvasScrollPosition.x * res;

    ctx.font = `${12 * res}px arial`;

    if (day.isToday) {
      ctx.fillStyle = dateBarTodayBGColor;
      ctx.fillRect(xPos, dateBarHeight - 44 * res, scaleX, 44 * res);
    } else if (day.isWeekend) {
      ctx.fillStyle = dateBarWeekendBGColor;
      ctx.fillRect(xPos, dateBarHeight - 44 * res, scaleX, 44 * res);
    }

    ctx.strokeStyle = dateBarLineColor;
    ctx.beginPath();
    ctx.moveTo(xPos, dateBarHeight - day.strokeHeight * res);
    ctx.lineTo(xPos, dateBarHeight);
    ctx.stroke();

    ctx.fillStyle = dateBarFontColor;
    ctx.fillText(day.monthday, xPos + 6 * res, dateBarHeight - 11 * res);
    ctx.fillText(day.weekday, xPos + 6 * res, dateBarHeight - 29 * res);
    if (day.isNewWeek) {
      ctx.fillText(day.weekString, xPos + 6 * res, dateBarHeight - 58 * res);
    }
    if (day.isNewMonth) {
      ctx.fillText(day.monthString, xPos + 6 * res, dateBarHeight - 82 * res);
    }
  }

  ctx.strokeStyle = dateBarBorderColor;
  ctx.beginPath();
  ctx.moveTo(0, dateBarHeight - 1 * res);
  ctx.lineTo(ctx.canvas.width, dateBarHeight - 1 * res);
  ctx.stroke();
};

/**
 * Apply mouse click on a block element
 * @param block The block that has been clicked on
 */
const onBlockClick = () => {
  if (!hoveredBlock) return;
  if (!hoveredBlock?.onClick && !hoveredBlock?.url) return;

  if (hoveredBlock.onClick) {
    hoveredBlock.onClick();
  }
  if (hoveredBlock.url) {
    window.location.href = hoveredBlock.url;
  }
};

const onCalendarClick = (callback: (line: LineInternal | undefined, Date: DateTime) => void) => {
  if (hoveredBlock) return;
  if (
    mousePositionRelative.value.x > categoriesWidth &&
    mousePositionRelative.value.y > dateBarHeight
  ) {
    const line = visibleLines.find(
      (line) => line.yPos && line.yPos + scaleY >= mousePositionRelative.value.y,
    );
    const date = props.todayDate.plus({
      days: (canvasScrollPosition.x + mousePositionRelative.value.x / res) / props.configObj.scaleX,
    });
    callback(line, date);
  }
};

const getCanvasHeight = () => ((ctx?.canvas?.height ?? 0) - dateBarHeight) / res;

defineExpose({
  loop,
  onBlockClick,
  onCalendarClick,
  getCanvasHeight,
});
</script>
