import { State } from '@/entities/station/state.enum';
import * as yup from 'yup';
import { localizedField } from './validation-types/localized-field';

export const stationSchema = yup.object({
  info: localizedField.required(),
  notice: localizedField.required(),
  slug: yup.string().required(),
  location: yup.object().required(),
  street: yup.string().required(),
  zip: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().oneOf(Object.values(State)).required(),
  invoiceSuffix: yup.string().required(),
  openingHours: yup
    .array(
      yup.object({
        weekday: yup.number().required(),
        closingTime: yup
          .object({
            hour: yup.number().required(),
            minute: yup.number().required(),
          })
          .nullable(),
        openingTime: yup
          .object({
            hour: yup.number().required(),
            minute: yup.number().required(),
          })
          .nullable(),
      }),
    )
    .min(7)
    .required(),
  active: yup.boolean().required(),
});
