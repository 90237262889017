<template>
  <div v-if="transferLocation" class="border border-primary/40 bg-primary/10 p-2 pb-1 pt-1.5">
    <div v-if="transferLocation.locationType === BookingLocationType.STATION">
      <div class="mb-1 font-medium">{{ t('station') }}</div>
      <div>
        {{ getLocalizedField(transferLocation.station?.info) }}<br />{{
          transferLocation.station?.street
        }}<br />{{ `${transferLocation.station?.zip} ${transferLocation.station?.city}` }}
      </div>
    </div>
    <div v-else>
      <div class="mb-1 font-medium">{{ t('street') }}</div>
      <div>{{ transferLocation?.location?.address?.displayName }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import type { BookingLocation } from '@/entities/bookings/booking.entity';
import { useLocalizedFields } from '@/hooks/use-localized-fields';
import { useI18n } from 'vue-i18n';

defineProps<{
  transferLocation?: BookingLocation | null;
}>();

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();
</script>

<i18n lang="json">
{
  "en": {
    "street": "Street",
    "station": "Station",
    "transferStart": "Transfer Start",
    "transferDestination": "Transfer Destination"
  },
  "de": {
    "street": "Straße",
    "station": "Station",
    "transferStart": "Transferstartort",
    "transferDestination": "Transferziel"
  }
}
</i18n>
