<template>
  <HeaderBar />

  <ButtonBackContainer v-if="route.name !== 'returnDone'">
    <ButtonBack>{{ backButtonTitle }}</ButtonBack>

    <CVButton outline variant="error" @click="onCancelClicked">
      {{ t('cancelReturn') }}
      <XMarkIcon class="h-4 w-4" />
    </CVButton>
  </ButtonBackContainer>

  <section class="carvia-container flex flex-col justify-center">
    <router-view />
  </section>
</template>

<script setup lang="ts">
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useI18n } from 'vue-i18n';
import { useBooking } from '@/queries/use-bookings';
import { useUiStore } from '@/stores/ui.store';
import { computed, watchEffect } from 'vue';
import { RouterView, useRoute, useRouter } from 'vue-router';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import { Alert } from '@/utils/alert';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';

const uiStore = useUiStore();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const { data: booking } = useBooking(route.params.id as string);

watchEffect(() => {
  if (booking.value) {
    const { customerData, car } = booking.value;
    uiStore.setHeaderTitle(
      t('return'),
      `${customerData?.firstName ?? 'n/a'} ${customerData?.lastName ?? 'n/a'}`,
      `${car.vehicleType?.make.name} ${car.vehicleType?.model}`,
    );
  } else {
    uiStore.setHeaderTitle(t('return'));
  }
});

const backButtonTitle = computed(() => {
  switch (route.name) {
    case 'returnOverview':
      return t('backToUpcomingHandovers');
    case 'returnDamages':
      return t('backToReturnOverview');
    case 'returnCarStatus':
      return t('backToDamages');
    default:
      return t('back');
  }
});

const onCancelClicked = async () => {
  const result = await Alert.fire({
    title: t('cancelReturn'),
    text: t('cancelReturnConfirmation'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('yes'),
    cancelButtonText: t('no'),
  });
  if (result.isConfirmed) {
    router.push({ name: 'upcomingHandovers' });
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "return": "Return",
    "backToUpcomingHandovers": "Back to Upcoming Handovers",
    "backToReturnOverview": "Back to Booking Overview",
    "backToDamages": "Back to Damages",
    "cancelReturn": "Cancel return",
    "cancelReturnConfirmation": "Do you really want to cancel the return?"
  },
  "de": {
    "return": "Rückgabe",
    "backToUpcomingHandovers": "Zurück zur Übergabeliste",
    "backToReturnOverview": "Zurück zur Buchungsübersicht",
    "backToDamages": "Zurück zu den Schäden",
    "cancelReturn": "Rückgabe abbrechen",
    "cancelReturnConfirmation": "Möchtest du die Rückgabe wirklich abbrechen?"
  }
}
</i18n>
