<template>
  <h2>{{ t('transfers') }}</h2>
  <div class="mt-4 grid grid-cols-3 gap-4">
    <div>
      <LabeledText :label="t('delivery')" class="mb-2">
        {{ !deliveryId ? t('noDelivery') : t('deliveryCreated') }}
      </LabeledText>

      <CVButton
        v-if="!deliveryId && !disabled"
        outline
        variant="primary"
        class="w-full"
        @click.prevent="editDelivery()"
      >
        {{ t('add') }}
      </CVButton>

      <div v-else-if="!disabled" class="grid grid-cols-2 gap-3">
        <CVButton @click.prevent="editDelivery()">
          {{ t('edit') }}
        </CVButton>
        <CVButton outline variant="primary" @click.prevent="removeDelivery">
          {{ t('remove') }}
        </CVButton>
      </div>
    </div>

    <div>
      <LabeledText :label="t('return')" class="mb-2">
        {{ !returnId ? t('noReturn') : t('returnCreated') }}
      </LabeledText>

      <CVButton
        v-if="!returnId && !disabled"
        outline
        variant="primary"
        class="w-full"
        @click.prevent="editReturn()"
      >
        {{ t('add') }}
      </CVButton>

      <div v-else-if="!disabled" class="grid grid-cols-2 gap-3">
        <CVButton data-testid="edit-return-transfer" @click.prevent="editReturn()">
          {{ t('edit') }}
        </CVButton>
        <CVButton outline variant="primary" @click.prevent="removeReturn">
          {{ t('remove') }}
        </CVButton>
      </div>
    </div>

    <div class="flex items-end justify-end">
      <div v-if="!disabled" class="flex items-center gap-2">
        <Information
          ><template #modal>{{ t('autoAdjustInfo') }}</template></Information
        >
        <CVButton :is-loading="adjustingTransfers" @click.prevent="showAutoAdjustModal = true">{{
          t('autoAdjustTransfers')
        }}</CVButton>
      </div>
    </div>
  </div>

  <AdjustTransfersModal
    v-model:show-modal="showAutoAdjustModal"
    :booking="booking"
    :pickup-location-type="booking.pickupLocationType"
    :dropoff-location-type="booking.dropoffLocationType"
    :pickup-station-id="booking.pickupStation?.id"
    :dropoff-station-id="booking.dropoffStation?.id"
    :is-saving="adjustingTransfers"
    just-auto-adjust
    @save="onAdjustTransfers"
  />
</template>

<script lang="ts" setup>
import { BookingType } from '@/entities/bookings/booking-type.enum';
import type { Booking } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';
import { useAutoAdjustTransfers, useDeleteBooking, useUpdateBooking } from '@/queries/use-bookings';
import router from '@/router';
import { Alert } from '@/utils/alert';
import { computed, ref } from 'vue';
import LabeledText from '../LabeledText.vue';
import AdjustTransfersModal from './AdjustTransfersModal.vue';
import type { SaveBookingLocationsEvent } from './AdjustTransfers.vue';
import Information from '../Information.vue';

const props = defineProps<{
  booking: Booking;
  disabled?: boolean;
}>();

const { t } = useI18n();

const { mutateAsync: deleteTransfer } = useDeleteBooking();
const { mutateAsync: updateBooking } = useUpdateBooking();
const { mutateAsync: autoAdjustTransfers, isPending: adjustingTransfers } =
  useAutoAdjustTransfers();

const showAutoAdjustModal = ref(false);

const deliveryId = computed(
  () =>
    props.booking.relatedBookings.find(
      (booking) => booking.type === BookingType.TRANSFER_DELIVERY_BOOKING,
    )?.id,
);

const returnId = computed(
  () =>
    props.booking.relatedBookings.find(
      (booking) => booking.type === BookingType.TRANSFER_RETURN_BOOKING,
    )?.id,
);

const editDelivery = () => editTransfer(BookingType.TRANSFER_DELIVERY_BOOKING);
const editReturn = () => editTransfer(BookingType.TRANSFER_RETURN_BOOKING);
const editTransfer = (type: BookingType) => {
  router.push({
    name: 'transferEdit',
    params: {
      transferType: type,
      bookingId: props.booking.id,
      transferId:
        (type === BookingType.TRANSFER_DELIVERY_BOOKING ? deliveryId.value : returnId.value) ??
        'new',
    },
  });
};

const removeDelivery = () => removeTransfer(BookingType.TRANSFER_DELIVERY_BOOKING);
const removeReturn = () => removeTransfer(BookingType.TRANSFER_RETURN_BOOKING);
const removeTransfer = async (type: BookingType) => {
  const transferId =
    type === BookingType.TRANSFER_DELIVERY_BOOKING ? deliveryId.value : returnId.value;
  if (!transferId) return;
  const alertResult = await Alert.fire({
    titleText: t('reallyDeleteTransferTitle'),
    text: t('reallyDeleteTransferText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('delete'),
    denyButtonText: t('keep'),
  });
  if (alertResult.isConfirmed) {
    await updateBooking({
      id: props.booking.id,
      booking: {
        relatedBookingIds: props.booking.relatedBookings
          .filter((booking) => booking.id !== transferId)
          .map((booking) => booking.id),
      },
    });
    await deleteTransfer(transferId);
  }
};

const onAdjustTransfers = async (adjustTransfers: SaveBookingLocationsEvent) => {
  await autoAdjustTransfers({
    bookingId: props.booking.id,
    data: {
      deliveryDriverId: adjustTransfers.deliveryDriverId!,
      returnDriverId: adjustTransfers.returnDriverId!,
    },
  });
  showAutoAdjustModal.value = false;
};
</script>

<i18n lang="json">
{
  "en": {
    "transfers": "Transfers",
    "delivery": "Delivery",
    "noDelivery": "No Delivery",
    "deliveryCreated": "Delivery Created",
    "return": "Return",
    "noReturn": "No Return",
    "returnCreated": "Return Created",
    "edit": "Edit",
    "remove": "remove",
    "reallyDeleteTransferTitle": "Really remove Transfer?",
    "reallyDeleteTransferText": "The Transfer will be permanently deleted.",
    "delete": "Delete",
    "keep": "Don't delete",
    "autoAdjustTransfers": "Auto adjust transfers",
    "autoAdjustInfo": "Automatically create/adjust/delete transfers based on the start and end location."
  },
  "de": {
    "transfers": "Transfers",
    "delivery": "Anlieferung",
    "noDelivery": "Keine Anlieferung",
    "deliveryCreated": "Anlieferung angelegt",
    "return": "Rückführung",
    "noReturn": "Keine Rückführung",
    "returnCreated": "Rückführung angelegt",
    "edit": "Bearbeiten",
    "remove": "Löschen",
    "reallyDeleteTransferTitle": "Transfer wirklich entfernen?",
    "reallyDeleteTransferText": "Der Transfer wird unwiderruflich gelöscht.",
    "delete": "Löschen",
    "keep": "Nicht löschen",
    "autoAdjustTransfers": "Automatisch anpassen",
    "autoAdjustInfo": "Transfers anhand des Start- und Endorts automatisch erstellen/anpassen/löschen"
  }
}
</i18n>
