import { UserRole } from '@/entities/auth/user-role.enum';

export const ROLES_ALLOWED_TO_USE_APP = [
  UserRole.ADMIN,
  UserRole.HUB_MANAGER,
  UserRole.OPERATOR,
  UserRole.AGENT,
  UserRole.FRANCHISE_AGENT,
  UserRole.HUB_AGENT,
];
