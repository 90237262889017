import { stationsService } from '@/api/stations.service';
import type {
  CreateStationDto,
  FindAllStationsQueryDto,
  UpdateStationDto,
} from '@/entities/station/station.entity';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/vue-query';
import { type MaybeRefOrGetter, toRef, toValue } from '@vueuse/core';

const QUERY_KEY = 'stations';

export const useStation = (id: MaybeRefOrGetter<string>, enabled: MaybeRefOrGetter<boolean>) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(id)],
    queryFn: () => stationsService.getOne(toValue(id)),
    enabled: toRef(enabled),
  });
};

export const useStations = (limit = 10) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY],
    queryFn: ({ pageParam = 1 }) => stationsService.getAllPaginated({ page: pageParam, limit }),
    getNextPageParam: (lastPage) => lastPage.meta.nextPage,
    initialPageParam: 1,
  });
};

export const useStationsWithQuery = (
  query: MaybeRefOrGetter<FindAllStationsQueryDto>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY, toRef(query)],
    queryFn: ({ pageParam = 1 }) =>
      stationsService.getAllPaginated({ ...toValue(query), page: pageParam }),
    enabled: toRef(enabled),
    getNextPageParam: (lastPage) => lastPage.meta.nextPage,
    initialPageParam: 1,
  });
};

export const useUpdateStation = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ id, station }: { id: string; station: UpdateStationDto }) =>
      stationsService.update(id, station),
  });
};

export const useCreateStation = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (station: CreateStationDto) => stationsService.create(station),
  });
};
