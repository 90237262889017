import type {
  CreateDamageDto,
  Damage,
  FindAllDamagesQueryDto,
  UpdateDamageDto,
} from '@/entities/damage.entity';
import { Service } from './http-service';

class DamagesService extends Service<Damage, CreateDamageDto, UpdateDamageDto> {
  constructor() {
    super('/damages');
  }

  async getAllWithQuery(params: FindAllDamagesQueryDto) {
    const res = await this.httpClient.get<Damage[]>(`${this.endpoint}`, {
      params,
    });
    return res.data;
  }

  async getAllByCarId(carId: string) {
    const params: FindAllDamagesQueryDto = {
      carIds: [carId].toString(),
    };
    const res = await this.httpClient.get<Damage[]>(`${this.endpoint}`, {
      params,
    });
    return res.data;
  }

  async getAllByBookingId(bookingId: string) {
    const params: FindAllDamagesQueryDto = {
      bookingIds: [bookingId].toString(),
    };
    const res = await this.httpClient.get<Damage[]>(`${this.endpoint}`, {
      params,
    });
    return res.data;
  }
}

export const damagesService = new DamagesService();
