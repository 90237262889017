<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <label :class="props.class">
    <InputLabel :label="label" :tooltip="tooltip" :is-error="isError" :warning="warning" />
    <div class="flex w-full items-stretch justify-start">
      <input
        v-if="type !== 'number'"
        ref="input"
        :value="modelValue"
        :type="type"
        :placeholder="placeholder"
        :readonly="readonly"
        class="w-full basis-full rounded-none border border-primary/40 bg-accent p-2 placeholder-primary placeholder-opacity-60 transition duration-300 first:rounded-none hover:border-primary focus:border-primary focus:bg-white focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
        :class="[isError ? 'input-error' : '', height ? height : 'h-8']"
        v-bind="$attrs"
        @input="
          (event) => {
            $emit('update:modelValue', input?.value);
            $emit('input', event as InputEvent);
          }
        "
      />
      <InputNumber
        v-else
        v-model="numberValue"
        :locale="locale as string"
        class="w-full"
        :class="{
          'h-8': !props.height,
          [height ?? '']: props.height !== undefined,
        }"
        :min="min"
        :max="max"
        :max-fraction-digits="maxFractionDigits"
        :placeholder="placeholder"
        v-bind="$attrs"
        @input="
          (event) => {
            getInputNumberValue(event.value);
            $emit('input', event);
          }
        "
        @blur="
          // Fix for older Android versions
          nextTick(() => {
            getInputNumberValue(numberValue);
          })
        "
      />
      <div
        v-if="adornment"
        class="flex items-center"
        :class="{
          'bg-transparent px-2 text-primary': adornmentTransparent,
          'bg-primary px-3 text-white': !adornmentTransparent,
        }"
      >
        {{ adornment }}
      </div>
    </div>
    <InputHint :hint="hint" :is-error="isError" />
  </label>
</template>

<script setup lang="ts">
import { nextTick, ref, watch } from 'vue';
import InputHint from './InputHint.vue';
import InputLabel from './InputLabel.vue';
import { useI18n } from 'vue-i18n';
import InputNumber, { type InputNumberInputEvent } from 'primevue/inputnumber';

const { locale } = useI18n();

const props = defineProps<{
  label?: string;
  placeholder?: string;
  hint?: string;
  modelValue?: string | number | null;
  adornment?: string;
  adornmentTransparent?: boolean;
  isError?: boolean;
  readonly?: boolean;
  height?: string;
  warning?: string;
  tooltip?: string;
  class?: string;
  maxFractionDigits?: number;
  type?: string;
  min?: number;
  max?: number;
  emptyValueTo?: 'null' | 'undefined';
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number | null | undefined): void;
  (e: 'input', event: InputEvent | InputNumberInputEvent): void;
}>();

const input = ref<HTMLInputElement>();
const blur = () => {
  input.value?.blur();
};

defineExpose({
  blur,
  input,
});

const numberValue = ref(modelValueToNumberOrNull(props.modelValue));

const numberFieldOutputValue = ref<string | null>(
  typeof numberValue.value === 'number' ? numberValue.value?.toString() : null,
);

const maxFractionDigits = props.maxFractionDigits ? props.maxFractionDigits : 0;

const getInputNumberValue = (value: string | number | null | undefined) => {
  numberFieldOutputValue.value = value === undefined || value === null ? null : value.toString();
  emit('update:modelValue', stringOutputToNumberOrNull(numberFieldOutputValue.value));
};

watch(
  () => props.modelValue,
  (newValue) => {
    if (props.type !== 'number') return;
    if (
      !isNaN(Number(numberFieldOutputValue.value ?? 0)) ||
      newValue !== stringOutputToNumberOrNull(numberFieldOutputValue.value)
    ) {
      numberValue.value = modelValueToNumberOrNull(newValue);
    }
  },
);

function stringOutputToNumberOrNull(value: string | null) {
  const emptyValue = props.emptyValueTo === 'undefined' ? undefined : null;
  return value !== null && !isNaN(Number(value)) ? Number(value) : emptyValue;
}

function modelValueToNumberOrNull(value: string | number | null | undefined) {
  const emptyValue = props.emptyValueTo === 'undefined' ? undefined : null;
  return value !== undefined && value !== null
    ? !isNaN(Number(value))
      ? Number(value)
      : 0
    : emptyValue;
}
</script>

<style scoped>
:deep(.p-inputnumber input) {
  @apply h-full w-full basis-full rounded-none border border-primary/40 bg-accent p-2 placeholder-primary placeholder-opacity-60 transition duration-300 first:rounded-none hover:border-primary focus:border-primary focus:bg-white focus:outline-none disabled:cursor-not-allowed disabled:opacity-50;
}
</style>
