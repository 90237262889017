export enum VehiclePoolTab {
  OVERVIEW = 'OVERVIEW',
  ORDERED = 'ORDERED',
  FLOAT_IN = 'FLOAT_IN',
  FLOAT_OUT = 'FLOAT_OUT',
  GHOST_CARS = 'GHOST_CARS',
  MERCHANT_CONSULTATION = 'MERCHANT_CONSULTATION',
  CAR_INCOMING = 'CAR_INCOMING',
  INSPECTION = 'INSPECTION',
  TUV = 'TUV',
  TYRES = 'TYRES',
  SELL = 'SELL',
}
