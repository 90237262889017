<template>
  <h2>{{ t('customExtras') }}</h2>
  <div class="mt-4">
    <div v-if="customBookedExtras" class="border border-primary/40 px-3">
      <div
        v-for="(customBookedExtra, i) in customBookedExtras"
        :key="i"
        class="relative border-b border-primary/40 last:border-b-0"
      >
        <div class="grid grid-cols-4 items-center gap-4 pb-2 pt-2">
          <div class="col-span-3">
            {{ customBookedExtra.description }}
          </div>
          <div class="col-span-1 text-right">
            <span v-if="!hidePrice" v-currency="customBookedExtra.price"></span>
            <span v-else>{{ hidePriceText }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="pb-3 pt-1">
      {{ t('noCustomExtras') }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CustomBookedExtra } from '@/entities/bookings/booked-extra';
import { useI18n } from 'vue-i18n';

defineProps<{
  customBookedExtras: CustomBookedExtra[] | null;
  hidePrice?: boolean;
  hidePriceText?: string;
}>();

const { t } = useI18n();
</script>

<i18n lang="json">
{
  "en": {
    "customExtras": "Custom Extras",
    "noCustomExtras": "No Custom Extras"
  },
  "de": {
    "customExtras": "Individuelle Extras",
    "noCustomExtras": "Keine individuellen Extras"
  }
}
</i18n>
