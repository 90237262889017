<template>
  <div class="space-y-4">
    <div v-if="porscheCentersData" class="grid grid-cols-3 gap-4">
      <InputField
        :readonly="handoverView"
        name="insuranceData.referenceNumber"
        :label="t('referenceNumber')"
      />
      <InputField name="insuranceData.licencePlate" :label="t('licencePlate')" />
      <InputField
        v-if="!handoverView"
        name="insuranceData.initialDays"
        :label="t('initialDays')"
        type="number"
      />
      <Select
        :model-value="porscheCenter?.userId"
        :label="t('porscheCenter')"
        :options="porscheCenterOptions"
        :is-error="!!errorMessage"
        :hint="errorMessage"
        empty-value-to="null"
        @update:model-value="handleUpdatePorscheCenter"
      />
      <SelectField
        v-if="!handoverView"
        name="insuranceData.axaCategory"
        :options="axaCategoryOptions"
        :label="t('axaCategory')"
        empty-value-to="undefined"
      />
      <InputField
        v-if="!handoverView"
        name="insuranceData.maxDayPrice"
        :label="t('maxDayPrice')"
        type="number"
        adornment="€"
        :max-fraction-digits="2"
        empty-value-to="undefined"
      />
    </div>
    <div v-else class="flex justify-center">
      <Spinner />
    </div>
  </div>
</template>

<script setup lang="ts">
import InputField from '@/components/InputField.vue';
import SelectField from '@/components/SelectField.vue';
import { useI18n } from 'vue-i18n';
import { useUsersWithQuery } from '@/queries/use-users';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { computed } from 'vue';
import { useField } from 'vee-validate';
import Select from '@/components/Select.vue';
import { AxaVehicleCategory, type PorscheCenter } from '@carvia/ros-client-types';

defineProps<{
  handoverView?: boolean;
}>();

const { t } = useI18n();

const {
  value: porscheCenter,
  setValue: setPorscheCenter,
  errorMessage,
} = useField<PorscheCenter | undefined>('insuranceData.porscheCenter');

const handleUpdatePorscheCenter = (userId: string | null | undefined) => {
  if (!userId) setPorscheCenter(undefined);
  const porscheCenter = porscheCenters.value?.find((porscheCenter) => porscheCenter.id === userId);
  setPorscheCenter({
    userId: porscheCenter!.id,
    displayName: porscheCenter!.firstName + ' ' + porscheCenter!.lastName,
  });
};

const { data: porscheCentersData } = useUsersWithQuery({
  query: 'Porsche Zentrum',
  limit: 500,
});
const porscheCenters = useFlattenPaginatedData(porscheCentersData);
const porscheCenterOptions = computed(() => [
  { label: t('noPorscheCenter'), value: null },
  ...(porscheCenters.value?.map((porscheCenter) => ({
    label: porscheCenter.firstName + ' ' + porscheCenter.lastName,
    value: porscheCenter.id,
  })) ?? []),
]);

const axaCategoryOptions = computed(() => {
  return [
    { label: t('noCategory'), value: null },
    ...Object.values(AxaVehicleCategory).map((category) => ({
      label: category,
      value: category,
    })),
  ];
});
</script>

<i18n lang="json">
{
  "en": {
    "referenceNumber": "Reference number",
    "licencePlate": "Licence plate",
    "porscheCenter": "Porsche Center",
    "initialDays": "Initial days",
    "axaCategory": "AXA category",
    "maxDayPrice": "Max day price",
    "axaDetails": "AXA Details",
    "noCategory": "- No category -",
    "noPorscheCenter": "- No Porsche Center -"
  },
  "de": {
    "referenceNumber": "Aktenzeichen",
    "licencePlate": "Kennzeichen",
    "porscheCenter": "Porschezentrum",
    "initialDays": "Initiale Tagesanzahl",
    "axaCategory": "AXA Kategorie",
    "maxDayPrice": "Max Tagespreis",
    "axaDetails": "AXA Details",
    "noCategory": "- Keine Kategorie -",
    "noPorscheCenter": "- Kein Porschezentrum -"
  }
}
</i18n>
