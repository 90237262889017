import type { JWTUser } from '@/entities/auth/jwt-user.entity';
import { defineStore } from 'pinia';
import type { LatestUserData } from '@/entities/auth/latest-user-data.entity';
import type { User } from '@/entities/auth/user.entity';

interface AuthUserStorage {
  authUser: JWTUser | null | undefined; // null means not logged in, undefined means not yet loaded
  user: LatestUserData | null;
  isLoading: boolean;
}

export const useAuthStore = defineStore({
  id: 'authStore',
  state: () =>
    ({
      authUser: undefined,
      user: null,
      isLoading: true,
    }) as AuthUserStorage,
  actions: {
    setIsLoading(isLoading: boolean) {
      this.isLoading = isLoading;
    },
    setAuthUser(authUser: JWTUser | null) {
      this.authUser = authUser;
    },
    setUser(user: User | null) {
      this.user = user
        ? {
            id: user.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            isEmailConfirmed: user.isEmailConfirmed,
          }
        : null;
    },
  },
});
