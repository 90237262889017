import * as yup from 'yup';
import { Local } from '@/entities/auth/local.enum';
import { UserRole } from '@/entities/auth/user-role.enum';
import { Gender } from '@/entities/auth/gender.enum';
import { AgentType } from '@/entities/auth/agent-type.enum';

type Schema = yup.StringSchema<string | undefined, yup.AnyObject, undefined, ''>;

const isNotCustomerThanOptional = {
  is: (role: UserRole) => role === UserRole.CUSTOMER || role === UserRole.AGENT,
  then: (schema: Schema) => schema.required(),
  otherwise: (schema: Schema) => schema.optional().nullable(),
};

export const userEditSchema = yup.object({
  company: yup.string().optional().nullable(),
  gender: yup.string().oneOf(Object.values(Gender)).required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required().email(),
  password: yup.string().min(8).optional(),
  role: yup.string().oneOf(Object.values(UserRole)).optional(),
  agentType: yup
    .string()
    .oneOf(Object.values(AgentType))
    .when('role', {
      is: (role: UserRole) => role === UserRole.AGENT,
      then: (schema: Schema) => schema.required(),
      otherwise: (schema: Schema) => schema.optional().nullable(),
    }),
  phone: yup.string().optional().nullable(),
  street: yup.string().when('role', isNotCustomerThanOptional),
  zip: yup.string().when('role', isNotCustomerThanOptional),
  city: yup.string().when('role', isNotCustomerThanOptional),
  country: yup.string().when('role', isNotCustomerThanOptional),
  dateOfBirth: yup.date().optional().nullable(),
  stationIds: yup.array(yup.string()),
  preferredLocal: yup.string().oneOf(Object.values(Local)).required(),
  preferredPaymentTerminalId: yup.string().optional().nullable(),
  licenseNumber: yup.string().optional().nullable(),
  licenseDate: yup.string().optional().nullable(),
  licenseAuthority: yup.string().optional().nullable(),
  idCardNumber: yup.string().optional().nullable(),
  idCardDate: yup.date().optional().nullable(),
  idCardAuthority: yup.string().optional().nullable(),
});
