<template>
  <HeaderBar />

  <section class="carvia-container">
    <div>
      <CreateBookingFromInsuranceCase v-if="insuranceCase" :insurance-case="insuranceCase" />
      <div v-else class="flex justify-center">
        <Spinner />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import CreateBookingFromInsuranceCase from './CreateBookingFromInsuranceCase.vue';
import { useRoute } from 'vue-router';
import { useInsuranceCase } from '@/queries/use-insurance-cases';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { watchEffect } from 'vue';
import { useUiStore } from '@/stores/ui.store';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const { t } = useI18n();
const uiStore = useUiStore();
const insuranceCaseId = route.params.id as string;

const { data: insuranceCase } = useInsuranceCase(insuranceCaseId);

watchEffect(() => {
  if (insuranceCase.value) {
    const { insuranceProvider, insuranceData, customerData } = insuranceCase.value;
    uiStore.setHeaderTitle(
      t('createBooking'),
      `${insuranceProvider} ${insuranceData?.referenceNumber}`,
      `${customerData.firstName} ${customerData.lastName}`,
    );
  } else {
    uiStore.setHeaderTitle(t('createBooking'));
  }
});
</script>

<i18n lang="json">
{
  "en": {
    "createBooking": "Create Booking"
  },
  "de": {
    "createBooking": "Buchung erstellen"
  }
}
</i18n>
