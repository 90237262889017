<template>
  <Select v-model="inputValue" :hint="errorMessage" :is-error="!!errorMessage" />
</template>

<script setup lang="ts">
import type { FuelType } from '@/entities/cars/fuel-type.enum';
import { useField } from 'vee-validate';
import { toRef, watch } from 'vue';
import Select from './Select.vue';

const props = defineProps<{
  name: string;
  inputValue?: FuelType | null;
  initialValue?: string | null | undefined;
  emptyValueTo?: 'null' | 'undefined';
}>();

const emits = defineEmits(['update:inputValue']);

const name = toRef(props, 'name');

const { value: inputValue, errorMessage } = useField<string | null | undefined>(name, undefined, {
  initialValue: props.initialValue,
});

watch(inputValue, (inputUpdate) => {
  emits('update:inputValue', inputUpdate);
});

if (props.emptyValueTo) {
  const emptyValue = props.emptyValueTo === 'null' ? null : undefined;
  watch(inputValue, (newValue) => (inputValue.value = newValue === '' ? emptyValue : newValue));
}
</script>
