import { useMe } from '@/queries/use-users';
import { useStationStore } from '@/stores/station.store';
import { computed } from 'vue';

export const useCurrentStations = () => {
  const stationStore = useStationStore();
  const { data: user } = useMe();
  return computed(() =>
    stationStore.currentStationId
      ? [stationStore.currentStationId]
      : user.value?.stations.map((station) => station.id),
  );
};
