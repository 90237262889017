<template>
  <router-link :to="{ name: 'user', params: { id: user.id } }">
    <div
      class="relative w-full border border-primary/30 bg-accent transition-shadow hover:shadow-md"
    >
      <div class="flex items-center gap-2 px-3 py-1 text-sm">
        <div class="basis-[30%]">
          <UserListItemTitle>
            {{ t('name') }}
          </UserListItemTitle>
          <p>{{ user.firstName }} {{ user.lastName }}</p>
        </div>
        <div class="basis-[36%]">
          <UserListItemTitle>
            {{ t('email') }}
          </UserListItemTitle>
          <p>{{ user.email }}</p>
        </div>
        <div class="basis-[20%]">
          <UserListItemTitle>
            {{ t('role') }}
          </UserListItemTitle>
          <p>{{ userRoleString }}</p>
        </div>
        <div class="basis-[14%] text-right">
          <UserListItemTitle>
            {{ t('createdAt') }}
          </UserListItemTitle>
          <p>{{ formattedCreatedDate }}</p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import UserListItemTitle from './UserListItemTitle.vue';
import type { User } from '@/entities/auth/user.entity';
import { useFormattedDate } from '@/hooks/use-formatted-date';
import { getDateFormat } from '@/hooks/use-date-format';
import { computed } from 'vue';
import { UserRole } from '@/entities/auth/user-role.enum';

const props = defineProps<{
  user: User;
}>();

const { t } = useI18n();

const userRoleString = computed(() => {
  return props.user.role !== UserRole.AGENT
    ? t(`userRoles.${props.user.role}`)
    : t(`agentTypes.${props.user.agentType}`);
});

const formattedCreatedDate = useFormattedDate(props.user.createdDate, getDateFormat());
</script>

<i18n lang="json">
{
  "en": {
    "name": "Name",
    "email": "Email",
    "role": "Role",
    "createdAt": "Created at"
  },
  "de": {
    "name": "Name",
    "email": "Email",
    "role": "Rolle",
    "createdAt": "Erstellt am"
  }
}
</i18n>
