import type {
  ManyBookingsCar,
  ManyBookingsStation,
} from '@/entities/bookings/many-bookings-booking.entity';
import type { Car, CarStations } from '@/entities/cars/car.entity';
import type {
  ManyVehicleTypesCar,
  ManyVehicleTypesStation,
} from '@/entities/many-vehicle-types-vehicle-type.entity';
import type { Station } from '@/entities/station/station.entity';
import { type MaybeRefOrGetter, toRef } from '@vueuse/core';
import { computed, type ComputedRef } from 'vue';

type ReturnType<T extends Car | ManyBookingsCar | ManyVehicleTypesCar> = T extends Car
  ? Station
  : T extends ManyBookingsCar
    ? ManyBookingsStation
    : ManyVehicleTypesStation;

export const useCurrentStationOfCar = <T extends Car | ManyBookingsCar | ManyVehicleTypesCar>(
  car: MaybeRefOrGetter<T>,
): ComputedRef<ReturnType<T>> => {
  const carRef = toRef(car);
  return computed(() => getCurrentStationOfCar(carRef.value) as ReturnType<T>);
};

export const useStationOfCarByDate = <T extends Car | ManyBookingsCar | ManyVehicleTypesCar>(
  car: MaybeRefOrGetter<T>,
  date: MaybeRefOrGetter<Date | string>,
): ComputedRef<ReturnType<T>> => {
  const carRef = toRef(car);
  const dateRef = toRef(date);
  return computed(() => getStationOfCarByDate(carRef.value, dateRef.value) as ReturnType<T>);
};

export const getCurrentStationOfCar = <T extends Car | ManyBookingsCar | ManyVehicleTypesCar>(
  car: T,
): ReturnType<T> => {
  return getStationOfCarByDate(car, new Date()) as ReturnType<T>;
};

export const getStationOfCarByDate = <T extends Car | ManyBookingsCar | ManyVehicleTypesCar>(
  car: T,
  date: Date | string,
): ReturnType<T> => {
  const stationDate = new Date(date);
  return ((car.stations as unknown as CarStations[]).find(
    (station) =>
      (station.fromDate === null || new Date(station.fromDate) <= stationDate) &&
      (station.untilDate === null || new Date(station.untilDate) >= stationDate),
  )?.station ??
    (car.stations as unknown as CarStations[]).sort((a, b) => {
      const aDate = a.fromDate ? new Date(a.fromDate).getTime() : 0;
      const bDate = b.fromDate ? new Date(b.fromDate).getTime() : 0;
      return new Date(aDate) > new Date(bDate) ? 1 : -1;
    })[0].station) as ReturnType<T>;
};
