import { Local } from '@/entities/auth/local.enum';
import { InvoiceType } from '@/entities/invoices/invoice-type.enum';
import type { ReceiverData } from '@/entities/invoices/invoice.entity';
import * as yup from 'yup';

export const invoiceCreateSchema = yup.object().shape(
  {
    title: yup.string().required(),
    invoiceDate: yup.date().required(),
    positions: yup.array(
      yup.object({
        title: yup.string().required(),
        amount: yup.number().required(),
        unitPrice: yup
          .number()
          .required()
          .test('notNegative', 'Price must be negative', function (value) {
            return this.from?.[1].value.type === InvoiceType.REVERSAL
              ? yup.number().max(0).isValidSync(value)
              : true;
          }),
        tax: yup.number().required(),
      }),
    ),
    comment: yup.string().optional(),
    type: yup
      .string()
      .oneOf(
        Object.values([
          InvoiceType.DAMAGE,
          InvoiceType.ADMINISTRATIVE_OFFENCE,
          InvoiceType.REVERSAL,
          InvoiceType.OTHER,
          InvoiceType.GENERIC,
          InvoiceType.CAR_SALE,
        ]),
      )
      .required(),
    originalInvoiceId: yup.string().when('type', {
      is: (type: InvoiceType) => type === InvoiceType.REVERSAL,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    receiverData: yup
      .object({
        city: yup.string().required(),
        company: yup.string().optional(),
        country: yup.string().required(),
        email: yup.string().required().email(),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        preferredLocal: yup.string().oneOf(Object.values(Local)).required(),
        street: yup.string().required(),
        zip: yup.string().required(),
      })
      .default(undefined)
      .when(['type', 'customerId'], {
        is: (type: InvoiceType, customerId: string | undefined) => {
          return type === InvoiceType.GENERIC && !customerId;
        },
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
    customerId: yup.string().when(['type', 'receiverData'], {
      is: (type: InvoiceType, receiverData: ReceiverData | undefined) =>
        type === InvoiceType.GENERIC && !receiverData,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    bookingId: yup.string().when('type', {
      is: (type: InvoiceType) =>
        type === InvoiceType.DAMAGE ||
        type === InvoiceType.ADMINISTRATIVE_OFFENCE ||
        type === InvoiceType.OTHER,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  },
  [['receiverData', 'customerId']],
);

export const invoiceUpdateSchema = yup.object({
  comment: yup.string().optional(),
});
