import { authService } from '@/api/auth.service';
import { usersService } from '@/api/users.service';
import type {
  CreateCustomerUserDto,
  CreateUserDto,
  FindAllUsersQueryDto,
  UpdateUserDto,
} from '@/entities/auth/user.entity';

import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/vue-query';
import { type MaybeRefOrGetter, toRef, toValue } from '@vueuse/core';

const QUERY_KEY = 'users';

export const useMe = () => {
  return useQuery({ queryKey: ['me'], queryFn: () => authService.getMe() });
};

export const useUser = (
  id: MaybeRefOrGetter<string>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(id)],
    queryFn: () => usersService.getOne(toValue(id)),
    enabled: toValue(enabled),
  });
};

export const useUserWithEmailIfExists = (
  email: MaybeRefOrGetter<string>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(email)],
    queryFn: () => usersService.getOneByEmailIfExists(toValue(email)),
    enabled: toValue(enabled),
  });
};

export const useUsers = (limit = 10) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY],
    queryFn: ({ pageParam = 1 }) => usersService.getAllPaginated({ page: pageParam, limit }),
    getNextPageParam: (lastPage) => lastPage.meta.nextPage,
    initialPageParam: 1,
  });
};

export const useUsersWithQuery = (
  query: MaybeRefOrGetter<FindAllUsersQueryDto>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY, toRef(query)],
    queryFn: ({ pageParam = 1 }) =>
      usersService.getAllWithQuery({ ...toValue(query), page: pageParam }),
    enabled: toValue(enabled),
    getNextPageParam: (lastPage) => lastPage.meta.nextPage,
    initialPageParam: 1,
  });
};

export const useUpdateUser = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ id, user }: { id: string; user: UpdateUserDto }) =>
      usersService.update(id, user),
    onMutate: () => ({ successMessage: 'User updated' }),
  });
};

export const useCreateUser = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (user: CreateUserDto) => usersService.create(user),
    onMutate: () => ({ successMessage: 'User created' }),
  });
};

export const useCreateCustomerUser = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (user: CreateCustomerUserDto) => usersService.createCustomerUser(user),
    onMutate: () => ({ successMessage: 'User created' }),
  });
};

export const useCreateUserOrReturnId = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (user: CreateCustomerUserDto) => usersService.createOrReturnId(user),
    onMutate: () => ({ successMessage: 'User received' }),
  });
};
