<template>
  <HeaderBar />

  <ButtonBackContainer>
    <ButtonBack :to="{ name: 'upcomingHandovers' }">{{ t('backToOverview') }}</ButtonBack>
    <CVButton
      v-if="transfer && booking"
      outline
      :to="{
        name: 'transferEdit',
        params: {
          transferType: transferType,
          bookingId: booking?.id,
          transferId: transfer?.id,
        },
      }"
      >{{ t('editTransfer') }}
    </CVButton>
  </ButtonBackContainer>
  <section class="carvia-container flex flex-col justify-center">
    <div class="flex w-full justify-center space-y-3">
      <div v-if="transfer && booking">
        <h2>
          {{ t('transferOverview') }}
        </h2>

        <BasicTransferOverview :transfer="transfer" :booking-number="booking.bookingNumber" />

        <Divider />

        <div class="mt-4 grid grid-cols-3 gap-x-10">
          <div>
            <LabeledText :label="t('transferStartDate')">{{ formattedStartDate }}</LabeledText>
            <Divider />
            <TransferAddress :transfer-location="pickupTransferLocation" />
          </div>

          <div>
            <LabeledText :label="t('transferEndDate')">{{ formattedEndDate }}</LabeledText>
            <Divider />
            <TransferAddress :transfer-location="dropoffTransferLocation" />
          </div>

          <div>
            <LabeledText :label="t('estimatedDrivingTime')">
              {{ estimatedDrivingTime }}
            </LabeledText>

            <LabeledText :label="t('estimatedOnSiteTime')">{{ estimatedOnSiteTime }}</LabeledText>
          </div>
        </div>

        <Divider />

        <div class="mt-4 grid grid-cols-3 gap-x-10">
          <LabeledText :label="t('renter')"
            >{{ booking.customerData?.firstName ?? 'n/a' }}
            {{ booking.customerData?.lastName ?? 'n/a' }}</LabeledText
          >
          <LabeledText :label="t('phoneCustomer')">{{
            booking.customerData?.phone ?? 'n/a'
          }}</LabeledText>

          <LabeledText :label="t('emailCustomer')">{{
            booking.customer?.email ?? 'n/a'
          }}</LabeledText>
        </div>

        <Divider />

        <VehicleAndTimes :booking="booking" />
      </div>
      <div v-else>
        <Spinner />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import TransferAddress from '@/components/booking-forms/TransferAddress.vue';
import VehicleAndTimes from '@/components/booking-forms/VehicleAndTimes.vue';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import Divider from '@/components/Divider.vue';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import LabeledText from '@/components/LabeledText.vue';
import { BookingType } from '@/entities/bookings/booking-type.enum';
import { useBookingCharacteristics } from '@/hooks/use-booking-characteristics';
import { useFormattedDate } from '@/hooks/use-formatted-date';
import { useTransferLocations } from '@/hooks/use-transfer-locations';
import { useI18n } from 'vue-i18n';
import { useBooking, useBookingOfType } from '@/queries/use-bookings';
import { useUiStore } from '@/stores/ui.store';
import BasicTransferOverview from '@/views/handover/components/transfer/components/BasicTransferOverview.vue';
import { DateTime } from 'luxon';
import { computed, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

const uiStore = useUiStore();
const { t } = useI18n();
const route = useRoute();
const transferId = ref(route.params.id as string);
const { data: transfer } = useBookingOfType(
  transferId,
  true,
  [BookingType.TRANSFER_DELIVERY_BOOKING, BookingType.TRANSFER_RETURN_BOOKING],
  'upcomingHandovers',
);

const customerBookingId = computed(
  () =>
    transfer.value?.relatedBookings.find((booking) => booking.type === BookingType.CUSTOMER_BOOKING)
      ?.id ?? '',
);
const bookingFetchingEnabled = computed(() => !!customerBookingId.value);
const transferType = computed(() => transfer.value?.type);
const { data: booking } = useBooking(customerBookingId, bookingFetchingEnabled);
const car = computed(() => booking.value?.car);

const { isTransferDelivery } = useBookingCharacteristics(transfer);
const { pickupTransferLocation, dropoffTransferLocation } = useTransferLocations(
  transferType,
  transfer,
  car,
  booking,
);

const startDate = computed(() => transfer.value?.startDate);
const endDate = computed(() => transfer.value?.endDate);
const formattedStartDate = useFormattedDate(startDate);
const formattedEndDate = useFormattedDate(endDate);

watchEffect(() => {
  if (isTransferDelivery.value === undefined) return;

  const title = t(isTransferDelivery.value ? 'delivery' : 'return');
  if (booking.value) {
    const { customerData, car } = booking.value;
    uiStore.setHeaderTitle(
      title,
      `${customerData?.firstName ?? 'n/a'} ${customerData?.lastName ?? 'n/a'}`,
      `${car.vehicleType?.make.name} ${car.vehicleType?.model}`,
    );
  } else {
    uiStore.setHeaderTitle(title);
  }
});

const estimatedDrivingTime = computed(() => {
  if (!transfer.value) return undefined;
  const start = DateTime.fromISO(transfer.value?.startDate);
  const end = DateTime.fromISO(transfer.value?.endDate);
  return end.diff(start, 'hours').toHuman();
});
const estimatedOnSiteTime = computed(() => {
  if (!transfer.value || !booking.value?.startDate) return undefined;
  const start = DateTime.fromISO(booking.value?.startDate);
  const arrival = DateTime.fromISO(transfer.value?.endDate);
  return start.diff(arrival, 'hours').toHuman();
});
</script>

<i18n lang="json">
{
  "en": {
    "booking": "Booking",
    "delivery": "Delivery",
    "return": "Return",
    "transferOverview": "Transfer overview",
    "transferStartDate": "Transfer start date",
    "transferEndDate": "Transfer end date",
    "estimatedDrivingTime": "Estimated driving time",
    "estimatedOnSiteTime": "Estimated on-site time",
    "renter": "Renter",
    "phoneCustomer": "Phone customer",
    "emailCustomer": "Email customer",
    "backToOverview": "Back to handover overview",
    "editTransfer": "Edit transfer"
  },
  "de": {
    "booking": "Buchung",
    "delivery": "Anlieferung",
    "return": "Rückführung",
    "transferOverview": "Transferübersicht",
    "transferStartDate": "Transferbeginn",
    "transferEndDate": "Transferende",
    "estimatedDrivingTime": "Geschätzte Fahrzeit",
    "estimatedOnSiteTime": "Geschätzte Zeit vor Ort",
    "renter": "Mieter",
    "phoneCustomer": "Telefon Kunde",
    "emailCustomer": "Email Kunde",
    "backToOverview": "Zurück zur Übergabeliste",
    "editTransfer": "Transfer bearbeiten"
  }
}
</i18n>
