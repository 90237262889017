<template>
  <div>
    <h2>
      {{ t('bookingSummary') }}
    </h2>
    <div class="mt-4 grid grid-cols-2 gap-4">
      <div class="grid grid-cols-2 gap-4 border border-primary/40 bg-primary/10 px-4 py-2">
        <LabeledText :label="t('milageStart')">
          {{ booking.milageStart ? formatFloat(booking.milageStart) + ' km' : '-' }}
        </LabeledText>

        <LabeledText
          :label="`${fuelType === FuelType.ELECTRIC ? t('batteryStart') : t('fuelLevelStart')}`"
        >
          {{ fuelLevelStart }}
        </LabeledText>
      </div>

      <div class="grid grid-cols-2 gap-4 border border-primary/40 bg-primary/10 px-4 py-2">
        <LabeledText :label="t('milageEnd')">
          {{ booking.milageEnd ? formatFloat(booking.milageEnd) + ' km' : '-' }}
        </LabeledText>

        <LabeledText
          :label="`${fuelType === FuelType.ELECTRIC ? t('batteryEnd') : t('fuelLevelEnd')}`"
        >
          {{ fuelLevelEnd }}
        </LabeledText>
      </div>
    </div>

    <BookingSummaryEdit
      v-if="isAdmin && showEdit"
      :booking="booking"
      :is-saving="isSaving"
      @on-save="onSave"
      @on-cancel="showEdit = false"
    />

    <div class="mt-4 flex items-end justify-between">
      <div class="mt-2 flex justify-end">
        <CVButton v-if="isAdmin && !showEdit" outline @click.prevent="showEdit = true">{{
          t('edit')
        }}</CVButton>
      </div>
      <div
        v-if="additionalCosts"
        class="costs-grid grid grid-cols-2 items-end justify-end gap-x-3 gap-y-1 text-right font-medium"
        :class="{ invisible: isFetchingAdditionalCosts }"
      >
        <p>{{ t('additionalCosts') }}:</p>
        <p v-currency="additionalCosts.totalAdditionalCosts" class="block text-xl"></p>
        <p class="text-lg">{{ t('finalTotalPrice') }}:</p>
        <p v-currency="additionalCosts.finalTotalBookingPrice" class="block text-2xl"></p>
      </div>
      <div v-if="isFetchingAdditionalCosts" class="mt-2 flex justify-center">
        <Spinner />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import LabeledText from '../LabeledText.vue';
import { computed, ref } from 'vue';
import { FuelType } from '@/entities/cars/fuel-type.enum';
import { useI18n } from 'vue-i18n';
import { formatFloat } from '@/utils/format-numbers';
import { useRequestAdditionalCosts } from '@/queries/use-pricing';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import BookingSummaryEdit from './BookingSummaryEdit.vue';
import { useAuthStore } from '@/stores/auth.store';
import { UserRole } from '@/entities/auth/user-role.enum';
import { useTrueCarAttributes } from '@/hooks/use-true-car-attributes';

const props = defineProps<{
  booking: Booking;
  isSaving: boolean;
}>();

const emit = defineEmits<{
  (
    e: 'updateBooking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished: () => void,
  ): void;
}>();

const { t } = useI18n();
const authStore = useAuthStore();

const showEdit = ref(false);

const isAdmin = computed(() => authStore.user?.role === UserRole.ADMIN);

const {
  data: additionalCosts,
  refetch: refetchAdditionalCosts,
  isFetching: isFetchingAdditionalCosts,
} = useRequestAdditionalCosts(
  {
    bookingId: props.booking.id,
  },
  computed(() => props.booking.status === BookingStatus.CAR_RETURNED),
);

const { fuelType, batteryCapacityInKwh } = useTrueCarAttributes(computed(() => props.booking.car));

const fuelLevelStart = computed(() => {
  if (!props.booking.fuelLevelStart) return '-';
  const fuelStart = props.booking.fuelLevelStart;
  return fuelType.value === FuelType.ELECTRIC
    ? `${Math.round(fuelStart * 100)}% (${Math.round(batteryCapacityInKwh.value * fuelStart)} Kwh)`
    : `${fuelStart * 8} / 8`;
});

const fuelLevelEnd = computed(() => {
  if (!props.booking.fuelLevelEnd) return '-';
  const fuelEnd = props.booking.fuelLevelEnd;
  return fuelType.value === FuelType.ELECTRIC
    ? `${Math.round(fuelEnd * 100)}% (${Math.round(batteryCapacityInKwh.value * fuelEnd)} Kwh)`
    : `${fuelEnd * 8} / 8`;
});

const onSave = (formValues: UpdateBookingDto) => {
  emit(
    'updateBooking',
    {
      id: props.booking.id,
      booking: {
        milageStart: formValues.milageStart ?? undefined,
        milageEnd: formValues.milageEnd ?? undefined,
        fuelLevelStart: formValues.fuelLevelStart ?? undefined,
        fuelLevelEnd: formValues.fuelLevelEnd ?? undefined,
      },
    },
    () => {
      showEdit.value = false;
      if (props.booking.status !== BookingStatus.CAR_RETURNED) return;
      refetchAdditionalCosts();
    },
  );
};
</script>

<i18n lang="json">
{
  "en": {
    "bookingSummary": "Booking Summary",
    "fuelLevelStart": "Fuel Level Start",
    "fuelLevelEnd": "Fuel Level End",
    "batteryStart": "Battery Start",
    "batteryEnd": "Battery End",
    "milageStart": "Milage Start",
    "milageEnd": "Milage End",
    "additionalCosts": "Additional Costs",
    "finalTotalPrice": "Final Total Price",
    "edit": "Edit"
  },
  "de": {
    "bookingSummary": "Buchungszusammenfassung",
    "fuelLevelStart": "Tankfüllung Start",
    "fuelLevelEnd": "Tankfüllung Ende",
    "batteryStart": "Ladestand Start",
    "batteryEnd": "Ladestand Ende",
    "milageStart": "Km Start",
    "milageEnd": "Km Ende",
    "additionalCosts": "Zusatzkosten",
    "finalTotalPrice": "Finaler Gesamtpreis",
    "edit": "Bearbeiten"
  }
}
</i18n>

<style scoped>
.costs-grid {
  grid-template-columns: [first] auto [line2] auto [end];
}
</style>
