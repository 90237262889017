<template>
  <HeaderBar />

  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
  </ButtonBackContainer>

  <section class="carvia-container">
    <EditNewsForm
      v-if="news || newsNew"
      :news="news"
      :is-loading="isLoading"
      :is-fetching="isFetching"
      @on-submit="onSubmit"
      @on-delete="onDelete"
    />
  </section>
</template>

<script lang="ts" setup>
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import EditNewsForm from './EditNewsForm.vue';
import { computed, ref, watchEffect } from 'vue';
import { useCreateNews, useDeleteNews, useNews, useUpdateNews } from '@/queries/use-news';
import { useUiStore } from '@/stores/ui.store';
import type { CreateNewsDto, UpdateNewsDto } from '@/entities/news/news.entity';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const uiStore = useUiStore();
const newsId = route.params.id as string;
const newsNew = ref(newsId === 'new');
const newsFetchingEnabled = computed(() => !newsNew.value);

const { data: news, isFetching } = useNews(newsId, newsFetchingEnabled);
const { mutateAsync: createNews, isPending: isCreating } = useCreateNews();
const { mutateAsync: updateNews, isPending: isUpdating } = useUpdateNews();
const { mutateAsync: deleteNews, isPending: isDeleting } = useDeleteNews();

const isLoading = computed(() => isCreating.value || isUpdating.value || isDeleting.value);

watchEffect(() => {
  if (news.value) {
    uiStore.setHeaderTitle(t('editNews'));
  } else {
    uiStore.setHeaderTitle(t('createNews'));
  }
});

const onSubmit = async (formValues: UpdateNewsDto) => {
  if (!newsNew.value) {
    await updateNews({
      id: newsId,
      news: formValues,
    });
  } else {
    await createNews(formValues as CreateNewsDto);
    router.push({ name: 'newsList' });
  }
};

const onDelete = async () => {
  if (!news.value) return;
  await deleteNews(newsId);
  router.push({ name: 'newsList' });
};
</script>

<i18n lang="json">
{
  "en": {
    "editNews": "Edit News",
    "createNews": "Create News"
  },
  "de": {
    "editNews": "Neuigkeit bearbeiten",
    "createNews": "Neuigkeit erstellen"
  }
}
</i18n>
