import { paymentApiService } from '@/api/payment-api.service';
import type { BlockAmountDto } from '@/entities/payment-terminals/block-amount.dto';
import type { ChargeAmountResponseDto } from '@/entities/payment-terminals/charge-amount-response.dto';
import type { ChargeAmountDto } from '@/entities/payment-terminals/charge-amount.dto';
import type { ChargeRemainingBookingAmountDto } from '@/entities/payment-terminals/charge-remaining-booking-amount.dto';
import { useMutation } from '@tanstack/vue-query';
import { createToast } from 'mosha-vue-toastify';

export const useChargeAmount = () => {
  return useMutation({
    mutationKey: ['charge-amount'],
    mutationFn: (data: ChargeAmountDto) => paymentApiService.chargeAmount(data),
    onSuccess: (result) => {
      showChargingStatusToast(result);
    },
  });
};

export const useBlockAmount = () => {
  return useMutation({
    mutationKey: ['block-amount'],
    mutationFn: (data: BlockAmountDto) => paymentApiService.blockAmount(data),
    onSuccess: (result) => {
      showChargingStatusToast(result);
    },
  });
};

export const useChargeRemainingBookingAmount = () => {
  return useMutation({
    mutationKey: ['charge-remaining-booking-amount'],
    mutationFn: (data: ChargeRemainingBookingAmountDto) =>
      paymentApiService.chargeRemainingBookingAmount(data),
    onSuccess: (result) => {
      showChargingStatusToast(result);
    },
  });
};

const showChargingStatusToast = (result: ChargeAmountResponseDto) => {
  if (result.dueAmount === 0) {
    createToast('Charged Amount', {
      position: 'bottom-right',
      type: 'success',
    });
  } else if (result.paymentLink) {
    createToast('Sent Payment Link', {
      position: 'bottom-right',
      type: 'success',
    });
  } else {
    createToast('Could not charge the full amount. Still due: ' + result.dueAmount + '€', {
      position: 'bottom-right',
      type: 'warning',
    });
  }
};
