import type { MaybeRefOrGetter } from '@vueuse/core';
import * as yup from 'yup';
import { ageVerification } from './validation-types/age-verification';

export const additionalDriversSchema = yup.object({
  additionalDrivers: yup
    .array(
      yup.object({
        firstName: yup.string(),
        lastName: yup.string(),
        dateOfBirth: yup.date().nullable(),
        licenseNumber: yup.string(),
        licenseAuthority: yup.string(),
        licenseDate: yup.date().nullable(),
      }),
    )
    .optional(),
});

export const additionalDriversMandatorySchema = (
  minAge: MaybeRefOrGetter<number>,
  bookingStart: MaybeRefOrGetter<string | Date>,
) =>
  yup.object({
    additionalDrivers: yup
      .array(
        yup.object({
          firstName: yup.string().required(),
          lastName: yup.string().required(),
          dateOfBirth: ageVerification(minAge, bookingStart),
          licenseNumber: yup.string().required(),
          licenseAuthority: yup.string().required(),
          licenseDate: yup.date(),
        }),
      )
      .optional(),
  });
