import type {
  CreateCustomerUserDto,
  FindAllUsersQueryDto,
  User,
} from '@/entities/auth/user.entity';
import type { PaginatedResponse } from '@/entities/pagination/paginated-response';
import { Service } from './http-service';

class UsersService extends Service {
  async createCustomerUser(data: CreateCustomerUserDto) {
    const res = await this.httpClient.post<User>(`${this.endpoint}/customer`, data);
    return res.data;
  }

  async createOrReturnId(data: CreateCustomerUserDto) {
    const res = await this.httpClient.post<{ id: string }>(
      `${this.endpoint}/create-or-return-id`,
      data,
    );
    return res.data;
  }

  async getOneByEmailIfExists(email: string) {
    const res = await this.httpClient.get<User | null>(`${this.endpoint}/email-if-exists/${email}`);
    return res.data;
  }

  async getOneIdByEmailIfExists(email: string) {
    const res = await this.httpClient.get<{ id: string } | null>(
      `${this.endpoint}/id-by-email-if-exists/${email}`,
    );
    return res.data;
  }

  async getAllWithQuery(params: FindAllUsersQueryDto) {
    const res = await this.httpClient.get<PaginatedResponse<User>>(`${this.endpoint}`, {
      params,
    });
    return res.data;
  }
}

export const usersService = new UsersService('/users');
