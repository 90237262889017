import { DateTime } from 'luxon';

export interface Day {
  dateTime: DateTime;
  isToday: boolean;
  weekday: string;
  monthday: string;
  isNewWeek: boolean;
  isNewMonth: boolean;
  isWeekend: boolean;
  monthString: string;
  weekString: string;
  isHoliday: boolean;
  strokeHeight: number;
}

export const getDay = (currentDay: DateTime) => {
  const isToday = currentDay.startOf('day').diff(DateTime.now().startOf('day')).as('days') === 0;
  const day = currentDay.weekday;
  const date = currentDay.day;
  const isWeekend = day === 6 || day === 7;
  const isNewWeek = day === 1;
  const isNewMonth = date === 1;
  let strokeHeight = 44;
  strokeHeight = isNewWeek ? 71 : strokeHeight;
  strokeHeight = isNewMonth ? 100 : strokeHeight;
  return {
    dateTime: currentDay,
    isToday: isToday,
    weekday: currentDay.toFormat('ccc'),
    monthday: currentDay.toFormat('dd'),
    isNewWeek: isNewWeek,
    isNewMonth: isNewMonth,
    isWeekend: isWeekend,
    monthString: currentDay.toFormat('MMMM yyyy'),
    weekString: isNewWeek ? `KW ${currentDay.weekNumber}` : '',
    isHoliday: false, // to be implemented
    strokeHeight: strokeHeight,
  };
};
