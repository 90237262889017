import type { MiddlewareContext } from '../middleware-context.interface';
import type { CanParameters } from '@casl/ability';
import type { RosAbilities } from '@/entities/auth/abilities';
import { ability } from '@/main';

// const ability = useAbility();

export default function requireAuth(
  restrictions?: [CanParameters<RosAbilities>[0], CanParameters<RosAbilities>[1]][],
) {
  return async ({ to, next, authStore }: MiddlewareContext) => {
    if (to.name === 'login') return next();
    const checkRight = async () => {
      if (authStore.isLoading) return;
      const jwtUser = authStore.authUser;
      const user = authStore.user;
      if (
        !jwtUser ||
        !user ||
        !user.isEmailConfirmed ||
        restrictions?.some((restriction) => !ability.can(...restriction))
      ) {
        if (ability.can('ros', 'Dashboard')) {
          return next({ name: 'home' });
        } else {
          return next({
            name: 'login',
          });
        }
      }
      return next();
    };
    // when authStore is loading, check right after authStore is loaded
    if (!authStore.isLoading) {
      return checkRight();
    } else {
      return new Promise<void>((resolve) => {
        const unsubscribe = authStore.$subscribe((mutation, state) => {
          if (!state.isLoading) {
            unsubscribe();
            checkRight().then(() => {
              resolve();
            });
          }
        });
      });
    }
  };
}
