<template>
  <div class="grow">
    <div v-if="booking">
      <HandoverClientDataForm v-if="booking" :booking="booking" @on-submit="handleSubmit" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useBookingOfType } from '@/queries/use-bookings';
import { useRoute, useRouter } from 'vue-router';
import HandoverClientDataForm from './HandoverClientDataForm.vue';
import { BookingType } from '@/entities/bookings/booking-type.enum';

const route = useRoute();
const router = useRouter();

const { data: booking } = useBookingOfType(
  route.params.id as string,
  true,
  BookingType.CUSTOMER_BOOKING,
  'upcomingHandovers',
);

const handleSubmit = () => {
  router.push({
    name: 'handoverPayment',
    params: { id: route.params.id },
  });
};
</script>
