import { httpClient } from '@/api/http-client';
import type { AuditLog, FindAllAuditLogsForEntityDto } from '@/entities/audit-log/audit-log.entity';
import type { PaginatedResponse } from '@/entities/pagination/paginated-response';

class AuditLogService {
  httpClient = httpClient;
  endpoint = '/audit-log/';

  async getForEntity({ entityType, entityId, page }: FindAllAuditLogsForEntityDto) {
    const res = await this.httpClient.get<PaginatedResponse<AuditLog>>(
      `${this.endpoint}entity/${entityType}/${entityId}`,
      { params: { page } },
    );
    return res.data;
  }
}

export const auditLogService = new AuditLogService();
