import { uploadService } from '@/api/upload.service';
import type { GetContractSignatureUploadUrlDto } from '@/entities/bookings/booking.entity';
import type {
  GetCarDocumentUploadUrlDto,
  GetCarImageUploadUrlDto,
} from '@/entities/cars/car.entity';
import type { GetDamageMediaUploadUrlDto } from '@/entities/damage.entity';
import type { GetVehicleTypeImageUploadUrlDto } from '@/entities/vehicle-type.entity';

import { useMutation } from '@tanstack/vue-query';

const QUERY_KEY = 'upload';

export const useUploadDamageMedia = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ file, params }: { file: string | Blob; params: GetDamageMediaUploadUrlDto }) =>
      uploadService.uploadDamageMedia(params, file),
    onMutate: () => ({ showSuccessToast: false }),
  });
};

export const useUploadCarImage = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ file, params }: { file: string | Blob; params: GetCarImageUploadUrlDto }) =>
      uploadService.uploadCarImage(params, file),
    onMutate: () => ({ showSuccessToast: false }),
  });
};

export const useUploadVehicleTypeImage = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({
      file,
      params,
    }: {
      file: string | Blob;
      params: GetVehicleTypeImageUploadUrlDto;
    }) => uploadService.uploadVehicleTypeImage(params, file),
    onMutate: () => ({ showSuccessToast: false }),
  });
};

export const useUploadCarDocument = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ file, params }: { file: string | Blob; params: GetCarDocumentUploadUrlDto }) =>
      uploadService.uploadCarDocument(params, file),
    onMutate: () => ({ showSuccessToast: false }),
  });
};

export const useUploadContractSignature = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({
      file,
      params,
    }: {
      file: string | Blob;
      params: GetContractSignatureUploadUrlDto;
    }) => uploadService.uploadContractSignature(params, file),
    onMutate: () => ({ showSuccessToast: false }),
  });
};
