<template>
  <vue-final-modal
    v-model="showModal"
    class="flex items-center justify-center"
    content-class="modal-box w-11/12 max-w-5xl"
    content-transition="vfm-fade"
    overlay-transition="vfm-fade"
  >
    <div v-if="showModal && car">
      <ConfirmMerchantConsultationForm
        v-if="activeTab === Tab.MERCHANT_CONSULTATION"
        :car="car"
        :stations="stations"
        @complete="showModal = false"
      />
      <CarIncomingForm
        v-if="activeTab === Tab.CAR_INCOMING"
        :car="car"
        @complete="showModal = false"
      />
      <EnterInspectionAppointmentDateForm
        v-if="activeTab === Tab.INSPECTION"
        :car="car"
        @complete="showModal = false"
      />
      <EnterTuvAppointmentDateForm
        v-if="activeTab === Tab.TUV"
        :car="car"
        @complete="showModal = false"
      />
    </div>
  </vue-final-modal>
</template>

<script lang="ts" setup>
import { VehiclePoolTab as Tab } from '../vehicle-pool-tab.enum';
import type { Car } from '@/entities/cars/car.entity';
import ConfirmMerchantConsultationForm from './ConfirmMerchantConsultationForm.vue';
import type { Station } from '@/entities/station/station.entity';
import { ref, watch } from 'vue';
import CarIncomingForm from './CarIncomingForm.vue';
import EnterInspectionAppointmentDateForm from './EnterInspectionAppointmentDateForm.vue';
import EnterTuvAppointmentDateForm from './EnterTuvAppointmentDateForm.vue';

const props = defineProps<{
  modelValue: boolean;
  car: Car | undefined;
  stations: Station[] | undefined;
  activeTab: Tab;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const showModal = ref(props.modelValue);
watch(
  () => props.modelValue,
  (value) => (showModal.value = value),
);
watch(
  () => showModal.value,
  (value) => emit('update:modelValue', value),
);
</script>
