export enum Local {
  DE_DE = 'de-DE',
  EN_US = 'en-US',
}

export enum QueryLocale {
  DE = 'de',
  EN = 'en',
}

export const getLanguage = (local: Local) => new Intl.Locale(local).language;
