<template>
  <HeaderBar />

  <section class="carvia-container">
    <LocationsAndTimes
      :station="station"
      :pickup-location="pickupLocation"
      :dropoff-location="dropoffLocation"
      :start-date="startDate"
      :end-date="endDate"
      :category-filter="categoryFilter"
      :make-filter="makeFilter"
      @change="onLocationsAndTimesChange"
      @changing-filter="changingFilter = $event"
    />

    <VehiclesList
      v-if="station"
      :create-booking-props="createBookingProps"
      :changing-filter="changingFilter"
      class="mt-8"
      @select-offer="handleSelectOffer"
    />

    <CreateBookingModal
      v-if="pickupLocation && dropoffLocation && selectedOffer"
      v-model:show-modal="showModal"
      :create-booking-props="createBookingProps"
    />
  </section>
</template>

<script lang="ts" setup>
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useI18n } from 'vue-i18n';
import { useUiStore } from '@/stores/ui.store';
import LocationsAndTimes, { type LocationsAndTimesProps } from './components/LocationsAndTimes.vue';
import VehiclesList from './components/VehiclesList.vue';
import { ref, watchEffect } from 'vue';
import CreateBookingModal from './components/CreateBookingModal.vue';
import type { Offer } from '@carvia/ros-client-types';
import { useCreateBookingProps } from '@/views/bookings/booking-create/components/use-create-booking-props';
import type { Pricing } from '@/entities/pricing.entity';

const { t } = useI18n();
const uiStore = useUiStore();

const changingFilter = ref(true);

watchEffect(() => {
  uiStore.setHeaderTitle(t('createBooking'));
});

const createBookingProps = useCreateBookingProps();
const {
  startDate,
  endDate,
  station,
  pickupLocation,
  dropoffLocation,
  categoryFilter,
  makeFilter,
  selectedOffer,
  pricing,
} = createBookingProps;

const showModal = ref(false);

const onLocationsAndTimesChange = (result: LocationsAndTimesProps) => {
  station.value = result.station;
  pickupLocation.value = result.pickupLocation;
  dropoffLocation.value = result.dropoffLocation;
  startDate.value = result.startDate;
  endDate.value = result.endDate;
  categoryFilter.value = result.categoryFilter;
  makeFilter.value = result.makeFilter;
};

const handleSelectOffer = (offer: Offer) => {
  selectedOffer.value = offer;
  pricing.value = offer.pricing as unknown as Pricing;
  showModal.value = true;
};
</script>

<i18n lang="json">
{
  "en": {
    "createBooking": "Create Booking"
  },
  "de": {
    "createBooking": "Buchung erstellen"
  }
}
</i18n>
