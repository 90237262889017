import type {
  CreatePaymentTerminalDto,
  PaymentTerminal,
  UpdatePaymentTerminalDto,
  FindAllPaymentTerminalsQueryDto,
} from '@/entities/payment-terminals/payment-terminal.entity';
import { Service } from './http-service';
import type { PaginatedResponse } from '@/entities/pagination/paginated-response';
import type { ChargeCreditCardResponseDto } from '@/entities/payment-terminals/charge-credit-card-response.dto';
import type { ChargeCreditCardDto } from '@/entities/payment-terminals/charge-credit-card.dto';
import type { ChargeRemainingFromCreditCardDto } from '@/entities/payment-terminals/charge-remaining-from-credit-card.dto';

class PaymentTerminalsService extends Service<
  PaymentTerminal,
  CreatePaymentTerminalDto,
  UpdatePaymentTerminalDto
> {
  async getAllWithQuery(params: FindAllPaymentTerminalsQueryDto) {
    const res = await this.httpClient.get<PaginatedResponse<PaymentTerminal>>(this.endpoint, {
      params,
    });
    return res.data;
  }

  async chargeCreditCard(data: ChargeCreditCardDto) {
    const res = await this.httpClient.post<ChargeCreditCardResponseDto>(
      `${this.endpoint}/charge-credit-card`,
      data,
    );
    return res.data;
  }

  async chargeRemainingBookingAmountFromCreditCard(data: ChargeRemainingFromCreditCardDto) {
    const res = await this.httpClient.post<ChargeCreditCardResponseDto>(
      `${this.endpoint}/charge-remaining-from-credit-card`,
      data,
    );
    return res.data;
  }
}

export const paymentTerminalsService = new PaymentTerminalsService('/payment-terminals');
