<template>
  <div class="space-y-4 text-lg">
    <h2 class="mb-4">{{ t('autoAdjustTransfersTitle') }}</h2>
    <p>{{ t('autoAdjustTransfersText') }}</p>
    <div v-if="!isLoadingDrivers" class="grid grid-cols-2 gap-4">
      <div class="space-y-4" :class="{ 'opacity-30': !hasDelivery }">
        <LabeledText :label="t('delivery')">
          {{ hasDelivery ? t('changeDeliveryDriver') : t('noDelivery') }}
        </LabeledText>
        <Select v-if="hasDelivery" v-model="deliveryDriverId" :options="driverOptions" />
      </div>
      <div class="space-y-4" :class="{ 'opacity-30': !hasReturn }">
        <LabeledText :label="t('return')">
          {{ hasReturn ? t('changeReturnDriver') : t('noReturn') }}
        </LabeledText>
        <Select v-if="hasReturn" v-model="returnDriverId" :options="driverOptions" />
      </div>
    </div>
    <div v-else class="flex items-center justify-center">
      <Spinner />
    </div>

    <div class="flex justify-end gap-4 pt-2">
      <CVButton
        v-if="!justAutoAdjust"
        variant="success"
        outline
        :is-loading="isSaving"
        @click.prevent="saveWithoutAutoAdjusting"
      >
        {{ t('saveWithoutAdjustingTransfers') }}
      </CVButton>
      <CVButton v-else variant="warning" :disabled="isSaving" @click.prevent="$emit('cancel')">
        {{ t('cancel') }}
      </CVButton>
      <CVButton
        :is-loading="isSaving"
        variant="success"
        :disabled="!readyToSave"
        @click.prevent="saveAndAutoAdjust"
      >
        {{ justAutoAdjust ? t('autoAdjustTransfers') : t('saveAndAutoAdjustTransfers') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Booking } from '@/entities/bookings/booking.entity';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import LabeledText from '../LabeledText.vue';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import { useUsersWithQuery } from '@/queries/use-users';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import Select from '../Select.vue';
import { BookingType } from '@/entities/bookings/booking-type.enum';
import Spinner from '../icons/Spinner.vue';
import { UserRole } from '@/entities/auth/user-role.enum';

export interface SaveBookingLocationsEvent {
  autoAdjust: boolean;
  deliveryDriverId?: string;
  returnDriverId?: string;
}

const props = defineProps<{
  booking: Booking;
  showModal: boolean;
  pickupLocationType: BookingLocationType;
  dropoffLocationType: BookingLocationType;
  pickupStationId: string | null | undefined;
  dropoffStationId: string | null | undefined;
  justAutoAdjust?: boolean;
  isSaving: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:showModal', value: boolean): void;
  (e: 'save', value: SaveBookingLocationsEvent): void;
  (e: 'cancel'): void;
}>();

const { t } = useI18n();

const deliveryDriverId = ref<string | null>(null);
const returnDriverId = ref<string | null>(null);

const readyToSave = computed(
  () =>
    !((hasDelivery.value && !deliveryDriverId.value) || (hasReturn.value && !returnDriverId.value)),
);

const { data: driversData, isLoading: isLoadingDrivers } = useUsersWithQuery({
  role: [
    UserRole.ADMIN,
    UserRole.HUB_MANAGER,
    UserRole.HUB_AGENT,
    UserRole.FRANCHISE_AGENT,
    UserRole.OPERATOR,
  ],
  stationIds: [props.booking.car.stations[0].station.id],
  limit: 500,
});
const drivers = useFlattenPaginatedData(driversData);

const driverOptions = computed(() => [
  { label: t('selectDriver'), value: null },
  ...drivers.value.map((driver) => ({
    label: driver.firstName + ' ' + driver.lastName,
    value: driver.id,
  })),
]);

const hasDelivery = ref(
  props.pickupLocationType === BookingLocationType.STREET ||
    props.pickupStationId !== props.booking.car.stations[0].station.id,
);
const hasReturn = ref(
  props.dropoffLocationType === BookingLocationType.STREET ||
    props.dropoffStationId !== props.booking.car.stations[0].station.id,
);

deliveryDriverId.value =
  props.booking.relatedBookings.find(
    (relatedBooking) => relatedBooking.type === BookingType.TRANSFER_DELIVERY_BOOKING,
  )?.customer?.id ?? null;

returnDriverId.value =
  props.booking.relatedBookings.find(
    (relatedBooking) => relatedBooking.type === BookingType.TRANSFER_RETURN_BOOKING,
  )?.customer?.id ?? null;

watch(
  drivers,
  () => {
    if (drivers.value.length) {
      const driverIds = drivers.value.map((driver) => driver.id);
      deliveryDriverId.value = driverIds.includes(deliveryDriverId.value ?? '')
        ? deliveryDriverId.value
        : null;
      returnDriverId.value = driverIds.includes(returnDriverId.value ?? '')
        ? returnDriverId.value
        : null;
    }
  },
  { immediate: true },
);

const saveAndAutoAdjust = () => {
  emit('save', {
    autoAdjust: true,
    deliveryDriverId: deliveryDriverId.value ?? undefined,
    returnDriverId: returnDriverId.value ?? undefined,
  });
};

const saveWithoutAutoAdjusting = () => {
  emit('save', {
    autoAdjust: false,
  });
};
</script>

<i18n lang="json">
{
  "en": {
    "autoAdjustTransfersTitle": "Create/customize transfers automatically?",
    "autoAdjustTransfersText": "Do you want to create/adjust the transfers automatically? This could create new transfers, as well as delete existing transfers that are no longer needed.",
    "delivery": "Delivery",
    "return": "Return",
    "changeDeliveryDriver": "Change delivery driver",
    "changeReturnDriver": "Change return driver",
    "selectDriver": "- Select driver -",
    "noDelivery": "No delivery necessary. No need to choose a driver.",
    "noReturn": "No return necessary. No need to choose a driver.",
    "saveAndAutoAdjustTransfers": "Save and create/adjust transfers automatically",
    "saveWithoutAdjustingTransfers": "Save without adjustments",
    "autoAdjustTransfers": "Yes, Create/adjust/delete transfers automatically"
  },
  "de": {
    "autoAdjustTransfersTitle": "Transfers automatisch erstellen/anpassen?",
    "autoAdjustTransfersText": "Möchtest du die Transfers automatisch erstellen/anpassen lassen? Dabei könnten neue Transfers erstellt, als auch existierende und nicht mehr benötigte gelöscht werden.",
    "delivery": "Anlieferung",
    "return": "Rückführung",
    "changeDeliveryDriver": "Fahrer für Anlieferung ändern",
    "changeReturnDriver": "Fahrer für Rückführung ändern",
    "selectDriver": "- Fahrer auswählen -",
    "noDelivery": "Keine Anlieferung nötig. Es muss kein Fahrer ausgewählt werden.",
    "noReturn": "Keine Rückführung nötig. Es muss kein Fahrer ausgewählt werden.",
    "saveAndAutoAdjustTransfers": "Speichern und Transfers automatisch erstellen/anpassen",
    "saveWithoutAdjustingTransfers": "Speichern ohne Anpassungen",
    "autoAdjustTransfers": "Ja, Transfers automatisch erstellen/anpassen/löschen"
  }
}
</i18n>
