<template>
  <div
    class="relative w-full cursor-pointer rounded-2xl bg-primary/20 shadow-sm"
    @click="$emit('onView')"
  >
    <div
      class="mb-1 h-40 w-full rounded-t-2xl bg-cover bg-center"
      :style="{ backgroundImage: `url(${damage.imageFileUrl})` }"
    />
    <div class="mb-8 flex space-x-2 p-2">
      <figure
        class="flex h-7 w-7 items-center justify-center rounded-full bg-red-500 text-xl font-medium text-white"
      >
        {{ index }}
      </figure>
      <p class="-mt-[2px] w-4/5 break-words">
        {{ damage.description }}
      </p>
      <div
        v-if="showRepairedStatus && damage.status === DamageStatus.REPAIRED"
        class="absolute right-2 top-4 rounded-full bg-success px-2 py-0.5 text-xs text-white"
      >
        {{ t('repaired') }}
      </div>
    </div>
    <p class="absolute bottom-0 right-1 m-2 break-words text-right text-sm text-gray-500/70">
      {{ createdDate }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { DamageStatus } from '@/entities/damage-status.enum';
import type { Damage } from '@/entities/damage.entity';
import { useFormattedDate } from '@/hooks/use-formatted-date';
import { useI18n } from 'vue-i18n';
import { computed, toRefs } from 'vue';

const props = defineProps<{
  damage: Damage;
  index?: number;
  showRepairedStatus?: boolean;
}>();
defineEmits<{
  (e: 'onView'): void;
}>();

const { t } = useI18n();

const createdDate = useFormattedDate(computed(() => props.damage.createdDate));

const { damage } = toRefs(props);
</script>

<i18n lang="json">
{
  "en": {
    "repaired": "Repaired"
  },
  "de": {
    "repaired": "Repariert"
  }
}
</i18n>
