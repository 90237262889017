<template>
  <div class="flex justify-between">
    <h2>{{ t('customExtras') }}</h2>
    <CVButton :disabled="disabled" @click.prevent="push(getEmptyCustomExtra())">
      {{ t('addCustomExtra') }}
    </CVButton>
  </div>

  <div v-if="fields.length" class="mb-8 mt-4 border border-primary pb-1 pl-4 pr-4 pt-1">
    <div
      v-for="(customBookedExtra, i) in fields"
      :key="i"
      class="relative border-primary pb-3 pr-8 pt-3"
      :class="{
        'border-b': i < fields.length - 1,
      }"
    >
      <div class="grid grid-cols-6 gap-4">
        <InputField
          :disabled="disabled"
          :class="{
            'col-span-3': !hasValue(customBookedExtra.value.customVehicleExtraType),
            'col-span-2': hasValue(customBookedExtra.value.customVehicleExtraType),
          }"
          :name="`customBookedExtras[${i}].description`"
          :label="t('description')"
        />
        <InputField
          v-if="hasValue(customBookedExtra.value.customVehicleExtraType)"
          :disabled="disabled"
          class="col-span-1"
          :name="`customBookedExtras[${i}].value`"
          :label="getValueLabel(customBookedExtra.value.customVehicleExtraType)"
          type="number"
        />
        <InputField
          :disabled="disabled"
          :name="`customBookedExtras[${i}].price`"
          :label="t('price')"
          adornment="€"
          type="number"
          :max-fraction-digits="2"
        />
        <SelectField
          class="col-span-2"
          :name="`customBookedExtras[${i}].customVehicleExtraType`"
          :label="t('customVehicleExtraType')"
          :options="customVehicleExtraTypeOptions"
          empty-value-to="null"
        />
      </div>
      <button
        :disabled="disabled"
        class="absolute bottom-3 right-0 cursor-pointer p-2 text-lg leading-3 text-red-600"
        @click.prevent="removeField(i)"
      >
        ×
      </button>
    </div>
  </div>
  <div v-if="hasChanged" class="col-span-3 mt-2 flex">
    <CVButton
      variant="success"
      :is-loading="isSaving || recalculatingPrice"
      class="mr-2"
      @click.prevent="saveCustomExtras"
    >
      {{ t('save') }}
    </CVButton>
    <CVButton
      variant="warning"
      :disabled="isSaving || recalculatingPrice"
      class="mr-2"
      @click.prevent="cancelCustomExtras"
    >
      {{ t('cancel') }}
    </CVButton>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import type { Pricing } from '@/entities/pricing.entity';
import { useFieldArray, useForm } from 'vee-validate';
import InputField from '@/components/InputField.vue';
import { VehicleExtraType } from '@/entities/vehicle-extra-type.enum';
import { customExtrasSchema } from '@/validation/price-calculation.schema';
import SelectField from '../SelectField.vue';
import type { RecalculatePriceParams } from '@/hooks/use-recalculate-price';
import { useFormHasChanged } from '@/hooks/use-form-has-changed';
import { cloneDeep } from 'lodash';

const props = defineProps<{
  bookingId: string;
  booking: Booking;
  priceCalculation: Pricing;
  recalculatingPrice: boolean;
  isSaving: boolean;
  disabled?: boolean;
}>();
const emit = defineEmits<{
  (e: 'update:priceCalculation', value: Pricing): void;
  (e: 'hasChanged', value: boolean): void;
  (e: 'recalculate-price', value: RecalculatePriceParams): void;
  (
    e: 'update-booking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished: () => void,
  ): void;
}>();

const { t } = useI18n();

let bookingNeedsUpdate = false;

const customVehicleExtraTypeOptions = computed(() => {
  return (
    [null, ...Object.values(VehicleExtraType)].map((type) => ({
      label: type
        ? isReplacementCustomExtra(type)
          ? `${t('replace', { extraType: t(`vehicleExtras.${type}`) })}`
          : t(`vehicleExtras.${type}`)
        : t('custom'),
      value: type,
    })) ?? []
  );
});

const isReplacementCustomExtra = (extraType: VehicleExtraType | null) => {
  return (
    extraType !== VehicleExtraType.TRANSFER_RETURN && extraType !== VehicleExtraType.TRANSFER_PICKUP
  );
};

const hasValue = (extraType: VehicleExtraType | null) => {
  return (
    extraType === VehicleExtraType.ADDITIONAL_DRIVER ||
    extraType === VehicleExtraType.DEDUCTIBLE ||
    extraType === VehicleExtraType.EXTRA_KM ||
    extraType === VehicleExtraType.TRANSFER_PICKUP ||
    extraType === VehicleExtraType.TRANSFER_RETURN
  );
};

const {
  handleSubmit,
  values: formValues,
  setValues,
} = useForm({
  initialValues: {
    customBookedExtras: props.booking.priceCalculation.customBookedExtras,
  },
  validationSchema: customExtrasSchema,
});

const { push, replace, fields } = useFieldArray<{
  description: string;
  price: number | null;
  customVehicleExtraType: VehicleExtraType | null;
  value: number | null;
}>('customBookedExtras');

const removeField = (index: number) => {
  replace(fields.value.filter((_, i) => i !== index).map((field) => field.value));
};

watch(
  () => props.booking.priceCalculation.customBookedExtras,
  () => {
    replace(cloneDeep(props.booking.priceCalculation.customBookedExtras) ?? []);
    unchangedValues.value = cloneDeep(formValues);
  },
);

const unchangedValues = ref(cloneDeep(formValues));

const saveCustomExtras = handleSubmit((values) => {
  emit('recalculate-price', {
    changedBookedExtraTypes: {},
    showAlert: false,
    nullExtrasOnConfirm: false,
    customBookedExtras: values.customBookedExtras ?? undefined,
    isConfirmedFunction: () => {
      bookingNeedsUpdate = true;
    },
  });
});

watch(
  () => props.priceCalculation,
  () => {
    if (bookingNeedsUpdate) {
      const updateBookingValues: UpdateBookingDto = {
        priceCalculationId: props.priceCalculation.id,
      };
      emit('update-booking', { id: props.bookingId, booking: updateBookingValues }, () => {
        bookingNeedsUpdate = false;
        unchangedValues.value = cloneDeep(formValues);
      });
    }
  },
);

const cancelCustomExtras = () => {
  replace(props.booking.priceCalculation.customBookedExtras ?? []);
};

watch(
  () => formValues,
  () => {
    if (!formValues.customBookedExtras) {
      setValues({ customBookedExtras: [] });
    }
  },
  { deep: true },
);

const hasChanged = useFormHasChanged(unchangedValues, formValues);
watch(hasChanged, () => emit('hasChanged', hasChanged.value));

const getValueLabel = (extraType: VehicleExtraType | null) => {
  switch (extraType) {
    case VehicleExtraType.ADDITIONAL_DRIVER:
      return t('additionalDrivers');
    case VehicleExtraType.DEDUCTIBLE:
      return t('deductible');
    case VehicleExtraType.EXTRA_KM:
      return t('kilometers');
    case VehicleExtraType.TRANSFER_PICKUP:
      return t('kilometers');
    case VehicleExtraType.TRANSFER_RETURN:
      return t('kilometers');
  }
};

const getEmptyCustomExtra = () => {
  return {
    description: '',
    price: null,
    customVehicleExtraType: null,
    value: null,
  };
};
</script>

<i18n lang="json">
{
  "en": {
    "customExtras": "Custom Extras",
    "addCustomExtra": "+ Add Custom Extra",
    "description": "Description",
    "price": "Price",
    "custom": "Custom Extra",
    "customVehicleExtraType": "Extra Type",
    "replace": "Replace {extraType}",
    "additionalDrivers": "Add. drivers",
    "deductible": "Deductible",
    "kilometers": "Kilometers"
  },
  "de": {
    "customExtras": "Individuelle Extras",
    "addCustomExtra": "+ Extra hinzufügen",
    "description": "Beschreibung",
    "price": "Preis",
    "custom": "Individuelles Extra",
    "customVehicleExtraType": "Extratyp",
    "replace": "{extraType} ersetzen",
    "additionalDrivers": "Zusatzfahrer",
    "deductible": "SB.",
    "kilometers": "Kilometer"
  }
}
</i18n>
