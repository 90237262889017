<template>
  <div>
    <p>
      <span class="font-medium">{{ t('rentStart') }}:</span>
      {{ formattedStartDate }} | {{ pickupLocationInfo }}
    </p>
    <p>
      <span class="font-medium">{{ t('rentEnd') }}:</span>
      {{ formattedEndDate }} | {{ dropoffLocationInfo }}
    </p>
    <p>
      <span class="font-medium">{{ t('rentDuration') }}:</span>
      {{ pricing?.days }} {{ t('days') }}
    </p>
    <div v-if="pricing?.totalBasePrice">
      <p class="font-medium">
        {{ t('price') }}:
        {{ formatCurrency(pricing.totalBasePrice) }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getDateTimeFormat } from '@/hooks/use-date-format';
import { useFormattedDate } from '@/hooks/use-formatted-date';
import { useLocationInfo } from '@/hooks/use-location-info';
import { useI18n } from 'vue-i18n';
import { formatCurrency } from '@/utils/format-numbers';
import type { CreateBookingProps } from '@/views/bookings/booking-create/components/use-create-booking-props';

const props = defineProps<{
  createBookingProps: CreateBookingProps;
}>();

const { t } = useI18n();

const { startDate, endDate, pickupLocation, dropoffLocation, pricing } = props.createBookingProps;

const formattedStartDate = useFormattedDate(startDate, getDateTimeFormat());
const formattedEndDate = useFormattedDate(endDate, getDateTimeFormat());
const pickupLocationInfo = useLocationInfo(pickupLocation);
const dropoffLocationInfo = useLocationInfo(dropoffLocation);
</script>

<i18n lang="json">
{
  "en": {
    "rentStart": "Rent Start",
    "rentEnd": "Rent End",
    "rentDuration": "Rent Duration",
    "days": "days",
    "price": "Price"
  },
  "de": {
    "rentStart": "Mietbeginn",
    "rentEnd": "Mietende",
    "rentDuration": "Mietdauer",
    "days": "Tage",
    "price": "Preis"
  }
}
</i18n>
