<template>
  <div role="tablist" class="tabs-cv max-w-min flex-nowrap pr-10">
    <a
      v-for="(tab, i) in tabs"
      :key="i"
      :class="{ 'tab-active font-semibold': activeTab === tab.id }"
      class="tab min-w-max flex-nowrap"
      @click="activeTab = tab.id"
      >{{ tab.title }}</a
    >
  </div>
</template>

<script lang="ts" setup>
import { useRouteQueryStore } from '@/stores/route-query.store';
import type { MaybeRefOrGetter } from '@vueuse/core';
import { ref, toRef, watch, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

export interface TabItem {
  title: MaybeRefOrGetter<string>;
  id: string;
}

const route = useRoute();
const routeQueryStore = useRouteQueryStore();

const props = defineProps<{
  tabs: TabItem[];
  modelValue: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const isValidTabQueryParam = props.tabs.some((tab) => tab.id === (route.query.tab as string));
const presetTab = isValidTabQueryParam ? (route.query.tab as string) : props.modelValue;
const activeTab = ref<string>(presetTab);

watch(toRef(props, 'modelValue'), (newModelValue) => {
  activeTab.value = newModelValue;
});

watchEffect(() => {
  emit('update:modelValue', activeTab.value);
  routeQueryStore.push({ tab: activeTab.value });
});
</script>
