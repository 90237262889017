export const extractFileNamePath = (path: string) => {
  if (!path) return { fullFileName: '', fileName: '', suffix: '' };
  let fullFileName = path.split(/(\\|\/)/g).pop() ?? '';
  if (fullFileName.indexOf('?') !== -1) {
    fullFileName = fullFileName.substring(0, fullFileName.lastIndexOf('?'));
  }
  const fileName = fullFileName.substring(0, fullFileName.lastIndexOf('.'));
  const suffix = fullFileName.substring(fullFileName.lastIndexOf('.') + 1);
  return {
    fullFileName,
    fileName,
    suffix,
  };
};

export const extractFileNameFromFile = (file: File) => {
  const { fullFileName, fileName, suffix } = extractFileNamePath(file.name);
  return {
    fullFileName,
    fileName,
    suffix,
  };
};
