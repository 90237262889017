<template>
  <div>
    <p class="mb-4 text-2xl font-medium">
      {{ t('customerEmail') }}
    </p>
    <div>
      <Input
        v-model="email"
        :label="t('customerEmail')"
        name="email"
        :hint="errorMessage"
        :is-error="!!errorMessage"
      />
    </div>
    <Divider />
    <div class="flex items-end justify-between">
      <CVButton outline @click="$emit('back')">{{ t('back') }}</CVButton>
      <div class="flex items-end gap-5">
        <TotalPrice :total-price="totalPrice" />
        <CVButton :disabled="isLoading" outline @click="continueWithoutEmail">{{
          t('continueWithoutEmail')
        }}</CVButton>
        <CVButton :disabled="!email" :is-loading="isLoading" @click="linkCustomer">{{
          t('next')
        }}</CVButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Divider from '@/components/Divider.vue';
import { useI18n } from 'vue-i18n';
import TotalPrice from '../../TotalPrice.vue';
import type { User } from '@/entities/auth/user.entity';
import Input from '@/components/Input.vue';
import { useField } from 'vee-validate';
import { string } from 'yup';
import { usersService } from '@/api/users.service';
import { ref } from 'vue';
import { useAuthStore } from '@/stores/auth.store';
import { UserRole } from '@/entities/auth/user-role.enum';

const props = defineProps<{
  customer: User | null;
  customerId: string | null;
  customerEmail: string | null;
  totalPrice: number;
}>();

const emit = defineEmits<{
  (e: 'update:customer', value: User | null): void;
  (e: 'update:customerId', value: string | null): void;
  (e: 'update:customerEmail', value: string | null): void;
  (e: 'continue'): void;
  (e: 'back'): void;
}>();

const { t } = useI18n();
const authStore = useAuthStore();

const isLoading = ref(false);

const {
  value: email,
  errorMessage,
  validate,
} = useField('email', string().email().required(), {
  initialValue: props.customerEmail,
});

const linkCustomer = async () => {
  if (!email.value || !(await validate()).valid) return;
  if (props.customerEmail === email.value) {
    emit('continue');
    return;
  }
  try {
    isLoading.value = true;
    const isAgency = authStore.user?.role === UserRole.AGENT;
    const customer = !isAgency ? await usersService.getOneByEmailIfExists(email.value) : null;
    const customerId = isAgency
      ? ((await usersService.getOneIdByEmailIfExists(email.value))?.id ?? null)
      : (customer?.id ?? null);
    emit('update:customer', customer);
    emit('update:customerId', customerId);
    emit('update:customerEmail', email.value);
    emit('continue');
  } finally {
    isLoading.value = false;
  }
};

const continueWithoutEmail = () => {
  emit('update:customer', null);
  emit('update:customerId', null);
  emit('update:customerEmail', null);
  emit('continue');
};
</script>

<i18n lang="json">
{
  "en": {
    "customerEmail": "Customer Email",
    "agency": "Partner/Agency",
    "continueWithoutEmail": "Continue without email"
  },
  "de": {
    "customerEmail": "Kunden Email",
    "agency": "Partner/Vermittler",
    "continueWithoutEmail": "Ohne Email fortfahren"
  }
}
</i18n>
