<template>
  <form class="space-y-4" @submit="onSubmit">
    <div class="flex justify-between">
      <h2>{{ t('basicInfo') }}</h2>
      <DropdownDotted :buttons="dottedMenuItems" />
    </div>
    <div class="grid grid-cols-3 items-start gap-4">
      <div class="grid grid-cols-1 gap-2">
        <SelectField name="vehicleTypeId" :label="t('vehicleType')" :options="vehicleTypeOptions" />
        <InputField
          name="licencePlate"
          :label="t('licencePlate')"
          :placeholder="t('licencePlatePlaceholder')"
        />
        <InputField v-if="!initialCar.isGhostCar" name="fin" :label="t('fin')" />
      </div>

      <div class="col-span-2 grid grid-cols-6 gap-4">
        <img
          v-if="imageUrl"
          class="col-span-4 mb-3 self-center"
          :src="imageUrl"
          :width="600"
          :height="300"
        />
        <div
          v-else
          class="h-50 col-span-4 flex w-full items-center justify-center border border-primary/40 text-primary"
        >
          {{ t('noImage') }}
        </div>
        <div class="col-span-2 space-y-2 self-center">
          <CVButton outline class="w-full" @click.prevent="showUploadImageModal = true">
            {{ t('changeImage') }}
          </CVButton>
          <CVButton
            v-if="formValues.selectedImageFile"
            outline
            variant="error"
            class="w-full"
            @click.prevent="cancelImageChange"
          >
            {{ t('resetImage') }}
          </CVButton>
          <CVButton
            outline
            class="w-full"
            :disabled="!carImageUrl"
            @click.prevent="removeImageClick"
          >
            {{ t('removeImage') }}
          </CVButton>
        </div>
      </div>
    </div>

    <div v-if="!initialCar.isGhostCar" class="space-y-4">
      <Divider />

      <div class="flex items-center justify-between">
        <h2>{{ t('carStatus') }}</h2>

        <CVButton @click.prevent="gotoEditCarStatus">
          {{ t('editCarStatusAndDamages') }}
        </CVButton>
      </div>

      <div class="grid grid-cols-3 gap-4">
        <SelectField name="tyres" :label="t('tyres')" :options="tyresOptions" />

        <LabeledText :label="t('status')">{{ t(`carStatus.${initialCar?.status}`) }}</LabeledText>

        <LabeledText :label="t('lastTuvDate')">
          <div class="flex items-start gap-2">
            <span>{{ formattedLastTuvDate }}</span>
            <button class="-mt-1 text-2xl text-primary" @click.prevent="showTodosModal = true">
              &#9998;
            </button>
          </div>
        </LabeledText>

        <InspectionDatesList :inspections="initialCar.inspections" class="col-span-2" />
      </div>
    </div>

    <div v-if="!initialCar.isGhostCar" class="space-y-4">
      <Divider />

      <h2>{{ t('availability') }}</h2>

      <div class="grid grid-cols-3 gap-4">
        <DatepickerField
          :disabled="initialCar.status !== CarStatus.ORDERED"
          :label="t('availableFrom')"
          :name="`availableFrom`"
          :tooltip="t('availableFromTooltip')"
        />

        <DatepickerField
          :disabled="initialCar.status === CarStatus.FLOAT_OUT"
          :label="t('availableUntil')"
          :name="`availableUntil`"
        />

        <BooleanSelectField name="hiddenFromPublic" :label="t('hiddenFromPublic')" />

        <BooleanSelectField name="notAtTheHub" :label="t('externalLocation')" />
      </div>
    </div>

    <Divider />

    <CarStations :stations="stations" :error="errors.stations" />

    <Divider />

    <div v-if="initialCar.isGhostCar">
      <PossibleUpgradeCars
        :station-id="currentStation.id"
        :title="t('possibleUpgradeCars')"
        :errors="upgradeCarErrors"
      />

      <Divider />
    </div>

    <h2>{{ t('specificCarDetails') }}</h2>

    <div class="grid grid-cols-3 gap-4">
      <SelectField
        v-if="!initialCar.isGhostCar"
        name="acquisitionType"
        :label="t('acquisitionType')"
        :options="acquisitionTypeOptions"
      />

      <InputField
        v-if="
          (formValues.acquisitionType === CarAcquisitionType.PURCHASED_NEW ||
            formValues.acquisitionType === CarAcquisitionType.PURCHASED_USED) &&
          !initialCar.isGhostCar
        "
        name="purchasePrice"
        :label="t('purchasePrice')"
        type="number"
        :max-fraction-digits="2"
        :min="0"
        adornment="€"
        :tooltip="t('purchasePriceInfo')"
      />

      <InputField
        v-if="formValues.acquisitionType === CarAcquisitionType.LEASED && !initialCar.isGhostCar"
        name="leasingRate"
        :label="t('leasingRate')"
        type="number"
        :max-fraction-digits="2"
        :min="0"
        adornment="€"
        :tooltip="t('leasingRateInfo')"
      />

      <InputField
        v-if="!initialCar.isGhostCar"
        name="listPrice"
        :label="t('listPrice')"
        type="number"
        :max-fraction-digits="2"
        :min="0"
        adornment="€"
        :tooltip="t('listPriceInfo')"
      />

      <SelectField name="color" :label="t('color')" :options="colorOptions" empty-value-to="null" />

      <InputField
        v-if="user?.role === UserRole.ADMIN && !initialCar.isGhostCar"
        name="insuranceCompany"
        :label="t('insurance')"
        :placeholder="t('insurance')"
      />

      <DatepickerField
        v-if="!initialCar.isGhostCar"
        :label="t('firstRegistration')"
        :name="`firstRegistration`"
      />

      <BooleanSelectField
        v-if="initialCar.isGhostCar"
        name="isMysteryCar"
        :label="t('mysteryCar')"
        :tooltip="t('isMysteryCarInfo')"
      />
    </div>

    <Divider />

    <h2>{{ t('technicalData') }}</h2>

    <TechnicalDataFields :vehicle-type="selectedVehicleType" :fuel-type="formValues.fuelType" />

    <Divider />

    <h2>{{ t('features') }}</h2>

    <FeaturesFields :vehicle-type="selectedVehicleType" />

    <div v-if="!initialCar.isGhostCar">
      <Divider />

      <h2>{{ t('documents') }}</h2>

      <CarDocuments v-if="initialCar" :car="initialCar" />
    </div>

    <Divider />

    <div class="flex justify-between">
      <div>
        <CVButton
          v-if="initialCar.isGhostCar && initialCar.status !== CarStatus.FLOAT_OUT"
          :is-loading="isSaving"
          variant="error"
          outline
          @click="fleetOut"
          >{{ t('fleetOut') }}</CVButton
        >
      </div>
      <CVButton :is-loading="isSaving" type="submit" :disabled="isSaving">
        {{ t('save') }}
      </CVButton>
    </div>

    <SelectImageForUploadModal
      v-model:show-modal="showUploadImageModal"
      :image-file="formValues.selectedImageFile"
      @select="onSelectImage"
    ></SelectImageForUploadModal>

    <TodosModal
      v-model="showTodosModal"
      :car="initialCar"
      :stations="stations"
      :active-tab="VehiclePoolTab.TUV"
    />
  </form>
</template>

<script lang="ts" setup>
import BooleanSelectField from '@/components/BooleanSelectField.vue';
import DatepickerField from '@/components/DatepickerField.vue';
import Divider from '@/components/Divider.vue';
import InputField from '@/components/InputField.vue';
import SelectField from '@/components/SelectField.vue';
import { CarColor } from '@/entities/cars/car-color.enum';
import type { Car, UpdateCarDto } from '@/entities/cars/car.entity';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import { useAuthStore } from '@/stores/auth.store';
import { editCarSchema, editGhostCarSchema } from '@/validation/car.schema';
import { useForm } from 'vee-validate';
import { computed, ref, watch } from 'vue';
import CarDocuments from './components/CarDocuments.vue';
import { UserRole } from '@/entities/auth/user-role.enum';
import type { ManyVehicleTypesVehicleType } from '@/entities/many-vehicle-types-vehicle-type.entity';
import type { Station } from '@/entities/station/station.entity';
import { formHasChanged } from '@/hooks/use-form-has-changed';
import SelectImageForUploadModal from '@/components/SelectImageForUploadModal.vue';
import { cloneDeep } from 'lodash';
import CarStations from './components/CarStations.vue';
import TechnicalDataFields from '@/views/vehicle-pool/add-new-car/TechnicalDataFields.vue';
import type { VehicleType } from '@/entities/vehicle-type.entity';
import FeaturesFields from '@/views/vehicle-pool/add-new-car/FeaturesFields.vue';
import { CarAcquisitionType } from '@/entities/cars/car-acquisition-type.enum';
import { CarTyres } from '@/entities/cars/car-tyres.enum';
import LabeledText from '@/components/LabeledText.vue';
import { useFormattedDate } from '@/hooks/use-formatted-date';
import { getDateTimeFormat } from '@/hooks/use-date-format';
import InspectionDatesList from './components/InspectionDatesList.vue';
import { CarStatus } from '@/entities/cars/car-status.enum';
import DropdownDotted from '@/components/DropdownDotted.vue';
import TodosModal from '../vehicle-pool-list/components/TodosModal.vue';
import { VehiclePoolTab } from '@/views/vehicle-pool/vehicle-pool-list/vehicle-pool-tab.enum';
import PossibleUpgradeCars from './components/PossibleUpgradeCars.vue';
import { useCurrentStationOfCar } from '@/hooks/use-current-station-of-car';
import { getInitialValues } from './components/get-initial-values';
import { Alert } from '@/utils/alert';

export interface CarFormValues extends UpdateCarDto {
  selectedImageFile?: File | null;
  possibleUpgradeCars?: (Car | undefined)[];
}

const props = defineProps<{
  initialCar: Car;
  isSaving: boolean;
  vehicleTypes: ManyVehicleTypesVehicleType[];
  selectedVehicleType: VehicleType;
  stations: Station[];
}>();
const emit = defineEmits<{
  (e: 'onSubmit', value: CarFormValues): void;
  (e: 'vehicleTypeChange', value: string): void;
}>();

const { t } = useI18n();
const { user } = useAuthStore();

const showUploadImageModal = ref(false);
const selectedImageUrl = ref(props.initialCar?.imageUrl);
const showTodosModal = ref(false);

const currentStation = useCurrentStationOfCar(props.initialCar);

const formattedLastTuvDate = useFormattedDate(
  computed(() => props.initialCar?.lastTuvDate),
  getDateTimeFormat(),
  '-',
);

const tyresOptions = computed(() => {
  return Object.values(CarTyres).map((tyres) => ({
    label: t(`carTyres.${tyres}`),
    value: tyres,
  }));
});

const acquisitionTypeOptions = computed(() => {
  return Object.values(CarAcquisitionType).map((acquisitionType) => ({
    label: t(`carAcquisitionType.${acquisitionType}`),
    value: acquisitionType,
  }));
});

const colorOptions = computed(() => {
  return Object.values({
    EMPTY: null,
    ...CarColor,
  }).map((color) => ({
    label: color ? t(`carColors.${color}`) : '',
    value: color,
  }));
});

const vehicleTypeOptions = computed(() => {
  return props.vehicleTypes.map((vehicleType) => ({
    label: `${vehicleType.make.name} ${vehicleType.model}`,
    value: vehicleType.id,
  }));
});

const initialValues = ref<CarFormValues>(getInitialValues(props.initialCar));

const vehicleTypeImageUrl = computed(
  () =>
    props.initialCar?.vehicleType?.imageUrl ??
    props.vehicleTypes.find((type) => type.id === formValues.vehicleTypeId)?.imageUrl,
);
const carImageUrl = ref<string | null | undefined>(props.initialCar?.imageUrl);
const imageUrl = computed(
  () => selectedImageUrl.value || carImageUrl.value || vehicleTypeImageUrl.value,
);

watch(
  () => props.initialCar?.imageUrl,
  () => {
    carImageUrl.value = props.initialCar?.imageUrl;
  },
);

const removeImageClick = () => {
  selectedImageUrl.value = null;
  carImageUrl.value = null;
  setFieldValue('selectedImageFile', null);
};

const gotoEditCarStatus = () => {
  router.push({
    name: 'editCarStatus',
    params: { id: props.initialCar?.id },
  });
};

const {
  handleSubmit,
  values: formValues,
  setFieldValue,
  errors,
} = useForm({
  initialValues: initialValues.value,
  validationSchema: !props.initialCar.isGhostCar
    ? editCarSchema(props.initialCar.status)
    : editGhostCarSchema,
});

const onSelectImage = (newImageFile: File, newImageUrl: string) => {
  setFieldValue('selectedImageFile', newImageFile);
  selectedImageUrl.value = newImageUrl;
};

const cancelImageChange = () => {
  setFieldValue('selectedImageFile', undefined);
  selectedImageUrl.value = props.initialCar?.imageUrl;
};

const upgradeCarErrors = computed(() => {
  const upgradeCarErrors = Object.entries(errors.value).filter(
    ([key, error]) => key.startsWith('possibleUpgradeCars') && error,
  ) as unknown as [string, string[]];
  return upgradeCarErrors.length > 0 ? upgradeCarErrors.map(([, value]) => value) : undefined;
});

watch(
  () => formValues.vehicleTypeId,
  (vehicleTypeId) => {
    if (!vehicleTypeId) return;
    emit('vehicleTypeChange', vehicleTypeId);
  },
);

const fleetOut = async () => {
  const { isConfirmed } = await Alert.fire({
    text: t('reallyFleetOutText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('fleetOut'),
    denyButtonText: t('cancel'),
    focusDeny: true,
  });
  if (isConfirmed) {
    emit('onSubmit', { status: CarStatus.FLOAT_OUT });
  }
};

const onSubmit = handleSubmit((values) => {
  emit('onSubmit', values);
  setFieldValue('selectedImageFile', undefined);
  initialValues.value = cloneDeep(values);
});

usePreventLeavingUnsavedForm(() => formHasChanged(initialValues.value, formValues));

const dottedMenuItems = computed(() => [
  {
    title: t('addMaintenanceBlock'),
    onClick: () =>
      router.push({
        name: 'maintenanceEdit',
        params: { carId: props.initialCar.id, blockId: 'new' },
      }),
  },
  {
    title: t('addPartnerRent'),
    onClick: () =>
      router.push({
        name: 'partnerRentEdit',
        params: { carId: props.initialCar.id, bookingId: 'new' },
      }),
  },
]);
</script>

<i18n lang="json">
{
  "en": {
    "basicInfo": "Basic Info",
    "vehicleType": "Vehicle Type",
    "fin": "FIN",
    "changeImage": "Change Image",
    "removeImage": "× Remove Image",
    "carStatus": "Car Status",
    "tyres": "Tyres",
    "status": "Status",
    "lastTuvDate": "Last TÜV Date",
    "editCarStatusAndDamages": "View/Edit Car Status & Damages",
    "availability": "Availability",
    "specificCarDetails": "Specific Car Details",
    "color": "Color",
    "acquisitionType": "Acquisition Type",
    "purchasePrice": "Purchase Price",
    "purchasePriceInfo": "The actual gross list price of the car minus discount (incl. transfer costs)",
    "leasingRate": "Leasing Rate",
    "leasingRateInfo": "The gross leasing rate incl. discount",
    "listPrice": "List Price",
    "listPriceInfo": "The gross list price",
    "insurance": "Insurance",
    "availableFrom": "Available from",
    "availableUntil": "Available until",
    "technicalData": "Technical Data (overwrites Vehicle Type)",
    "fuelType": "Fuel Type",
    "transmission": "Transmission",
    "seats": "Seats",
    "power": "Power",
    "accelaration": "Acceleration (0 - 100)",
    "drive": "Drive",
    "tankVolume": "Tank Volume",
    "batteryCapacity": "Battery Capacity",
    "range": "Range",
    "features": "Features (overwrites Vehicle Type)",
    "gpsNavigation": "GPS/Navigation",
    "distanceControl": "Distance Control ...",
    "rearViewCamera": "... incl. Rear View Camera",
    "fromVehicleType": "From Vehicle Type",
    "documents": "Documents",
    "noImage": "No Image",
    "fuelLevel": "Fuel Level",
    "milage": "Milage",
    "licencePlatePlaceholder": "i.e. M-CV 1234",
    "availableFromTooltip": "Car is bookable on the website from this date",
    "firstRegistration": "First Registration",
    "hiddenFromPublic": "Hide from public",
    "externalLocation": "External location",
    "resetImage": "Reset image",
    "addMaintenanceBlock": "+ Add Maintenance Block",
    "addPartnerRent": "+ Add Partner Rent",
    "mysteryCar": "Mystery car",
    "isMysteryCarInfo": "The customer will not know which car they will get until they receive the bookings confirmation mail.",
    "possibleUpgradeCars": "Possible Upgrade Cars",
    "fleetOut": "Fleet Out",
    "reallyFleetOutText": "The car will be removed from the fleet. Really fleet out?"
  },
  "de": {
    "basicInfo": "Grundlegende Daten",
    "vehicleType": "Vehicle Type",
    "fin": "FIN",
    "changeImage": "Bild ändern",
    "removeImage": "× Bild löschen",
    "carStatus": "Fahrzeugstatus",
    "tyres": "Bereifung",
    "status": "Status",
    "lastTuvDate": "Letzter TÜV Termin",
    "editCarStatusAndDamages": "Fahrzeugstatus & Schäden ansehen/bearbeiten",
    "availability": "Verfügbarkeit",
    "specificCarDetails": "Spezifische Fahrzeugdaten",
    "color": "Farbe",
    "acquisitionType": "Beschaffungsart",
    "purchasePrice": "Kaufpreis",
    "purchasePriceInfo": "Der tatsächliche Brutto-Listenpreis des Autos abzüglich Rabatt (inkl. Überführungskosten)",
    "leasingRate": "Leasingrate",
    "leasingRateInfo": "Die Brutto Leasingrate inkl. Nachlass",
    "listPrice": "Listenpreis",
    "listPriceInfo": "Der Brutto Listenpreis",
    "insurance": "Versicherung",
    "availableFrom": "Verfügbar ab",
    "availableUntil": "Verfügbar bis",
    "technicalData": "Technische Daten (überschreibt Vehicle Type)",
    "fuelType": "Treibstofftyp",
    "transmission": "Getriebe",
    "seats": "Sitze",
    "power": "Leistung",
    "accelaration": "Beschleunigung (0 - 100)",
    "drive": "Antrieb",
    "tankVolume": "Tankgröße",
    "batteryCapacity": "Batteriekapazität",
    "range": "Reichweite",
    "features": "Ausstattung (Überschreibt Vehicle Type)",
    "gpsNavigation": "GPS/Navigation",
    "distanceControl": "Parkabstandswarner ...",
    "rearViewCamera": "... inkl. Rückfahrkamera",
    "fromVehicleType": "Von Vehicle Type",
    "documents": "Dokumente",
    "noImage": "Kein Bild",
    "fuelLevel": "Tankfüllung",
    "milage": "Kilometerstand",
    "licencePlatePlaceholder": "zB.: M-CV 1234",
    "availableFromTooltip": "Auto ist ab diesem Datum auf der Webseite buchbar",
    "firstRegistration": "Erstzulassung",
    "hiddenFromPublic": "Auf Webseite ausblenden",
    "externalLocation": "Auswärtiger Standort",
    "resetImage": "Bild zurücksetzen",
    "addMaintenanceBlock": "+ Wartungsblock hinzufügen",
    "addPartnerRent": "+ Partnermiete hinzufügen",
    "mysteryCar": "Mystery Car",
    "isMysteryCarInfo": "Der Kunde weiß nicht, welches Fahrzeug er bekommt, bis er die Buchungsbestätigungsmail erhält.",
    "possibleUpgradeCars": "Mögliche Upgrade Autos",
    "fleetOut": "Ausflotten",
    "reallyFleetOutText": "Das Fahrzeug wird aus der Flotte entfernt. Wirklich ausflotten?"
  }
}
</i18n>
