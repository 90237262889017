<template>
  <Input
    ref="inputField"
    type="search"
    @keypress="onKeyPress"
    @keydown="onKeyPress"
    @input="onInput"
  />
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue';
import Input from './Input.vue';
import type { GeocodedLocation } from '@/entities/bookings/booking.entity';
import { Loader } from '@googlemaps/js-api-loader';
import { GOOGLE_MAPS_API_KEY } from '@/constants/google-maps-api-key.constant';

export type ExtendedGeocodedLocation = GeocodedLocation & {
  /* eslint-disable no-undef */
  address_components?: google.maps.GeocoderAddressComponent[];
};

const emit = defineEmits<{
  (e: 'select-address', value: ExtendedGeocodedLocation | null): void;
}>();

const inputField = ref<typeof Input>();
onMounted(() => {
  const eventListener = (e: any, data: any) => {
    emit('select-address', e.detail.geocodedLocation);
    inputField.value!.input!.value = e.detail.geocodedLocation?.address?.displayName ?? '';
  };
  inputField.value?.input?.addEventListener('setAddress', eventListener);
  onUnmounted(() => {
    inputField.value?.input?.removeEventListener('setAddress', eventListener);
  });
});

const onKeyPress = (e: KeyboardEvent) => {
  switch (e.key) {
    case 'Enter':
      e.preventDefault();
  }
};

const onInput = () => {
  emit('select-address', null);
};

onMounted(async () => {
  const loader = new Loader({
    apiKey: GOOGLE_MAPS_API_KEY,
    version: 'weekly',
    libraries: ['places'],
  });

  const core = await loader.importLibrary('core');
  const places = await loader.importLibrary('places');
  const autocomplete = new places.Autocomplete(inputField.value?.input, {
    fields: ['formatted_address', 'address_components', 'geometry', 'types', 'name'],
  });

  core.event.addListener(autocomplete, 'place_changed', () => {
    const place = autocomplete.getPlace();
    if (!place.geometry) return;
    emit('select-address', {
      coordinates: {
        latitude: place.geometry?.location?.lat() ?? 0,
        longitude: place.geometry?.location?.lng() ?? 0,
      },
      address: {
        displayName:
          (place.types?.includes('establishment') ? place.name + ', ' : '') +
          place.formatted_address,
      },
      address_components: place.address_components,
    });
  });
});
</script>
