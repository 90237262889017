import Swal from 'sweetalert2';

export const Alert = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success btn-outline h-9 min-h-min mx-2 hover:text-success',
    cancelButton: 'btn btn-error btn-outline h-9 min-h-min mx-2 hover:text-white',
    denyButton: 'btn btn-warning btn-outline h-9 min-h-min mx-2 hover:text-white',
    icon: '!h-14 !w-14 !text-xs',
    title: 'text-lg font-medium',
    container: 'text-sm lg:text-base',
  },
  buttonsStyling: false,
  allowOutsideClick: false,
  allowEscapeKey: false,
});
