<template>
  <HeaderBar />

  <ButtonBackContainer v-if="route.name !== 'handoverDone'">
    <ButtonBack>{{ backButtonTitle }}</ButtonBack>
    <CVButton outline variant="error" @click="onCancelClicked">
      {{ t('cancelHandover') }}
      <XMarkIcon class="h-4 w-4" />
    </CVButton>
  </ButtonBackContainer>

  <section class="carvia-container flex flex-col justify-center">
    <div class="flex justify-center space-y-3">
      <router-view />
    </div>
  </section>
</template>

<script setup lang="ts">
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useI18n } from 'vue-i18n';
import { useBooking } from '@/queries/use-bookings';
import { useUiStore } from '@/stores/ui.store';
import { computed, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { Alert } from '@/utils/alert';
import { Local } from '@/entities/auth/local.enum';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';

const uiStore = useUiStore();
const { locale } = useI18n({ useScope: 'global' });
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { data: booking, isFetching } = useBooking(route.params.id as string);

watchEffect(() => {
  if (booking.value) {
    const { customerData, car } = booking.value;
    uiStore.setHeaderTitle(
      t('handover'),
      `${customerData?.firstName ?? 'n/a'} ${customerData?.lastName ?? 'n/a'}`,
      `${car.vehicleType?.make.name} ${car.vehicleType?.model}`,
    );
  } else {
    uiStore.setHeaderTitle(t('handover'));
  }
});

const unwatch = watch(isFetching, () => {
  if (booking.value) {
    locale.value = booking.value.customerData?.preferredLocal === Local.DE_DE ? 'de' : 'en';
    unwatch();
  }
});

const onCancelClicked = async () => {
  const result = await Alert.fire({
    title: t('cancelHandover'),
    text: t('cancelHandoverConfirmation'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('yes'),
    cancelButtonText: t('no'),
  });
  if (result.isConfirmed) {
    router.push({ name: 'upcomingHandovers' });
  }
};

const backButtonTitle = computed(() => {
  switch (route.name) {
    case 'handoverDetail':
      return t('backToUpcomingHandovers');
    case 'handoverClientData':
      return t('backToHandoverOverview');
    case 'handoverPayment':
      return t('backToHandoverClientData');
    case 'handoverCarStatus':
      return t('backToHandoverPayment');
    case 'handoverRentalContract':
      return t('backToHandoverCarStatus');
    default:
      return t('back');
  }
});
</script>

<i18n lang="json">
{
  "en": {
    "handover": "Handover",
    "backToUpcomingHandovers": "Back to Upcoming Handovers",
    "backToHandoverOverview": "Back to Booking Overview",
    "backToHandoverClientData": "Back to Customer & Driver",
    "backToHandoverPayment": "Back to Payment",
    "backToHandoverCarStatus": "Back to Car Status",
    "cancelHandover": "Cancel Handover",
    "cancelHandoverConfirmation": "Are you sure you want to cancel the handover?"
  },
  "de": {
    "handover": "Übergabe",
    "backToUpcomingHandovers": "Zurück zur Übergabeliste",
    "backToHandoverOverview": "Zurück zur Buchungsübersicht",
    "backToHandoverClientData": "Zurück zu Kunde & Fahrer",
    "backToHandoverPayment": "Zurück zu Kreditkarte hinterlegen",
    "backToHandoverCarStatus": "Zurück zum Fahrzeugstatus",
    "cancelHandover": "Übergabe abbrechen",
    "cancelHandoverConfirmation": "Möchten Sie die Übergabe wirklich abbrechen?"
  }
}
</i18n>
