import { onMounted, onUnmounted, ref } from 'vue';

export const useScrollPosition = () => {
  const scrollPosition = ref(window.scrollY);
  const pageHeight = ref(getPageHeight());
  const windowHeight = ref(window.innerHeight);
  const scrollPositionBottom = ref(pageHeight.value - (scrollPosition.value + windowHeight.value));

  const onChange = () => {
    windowHeight.value = window.innerHeight;
    scrollPosition.value = window.scrollY;
    pageHeight.value = getPageHeight();
    scrollPositionBottom.value = pageHeight.value - (scrollPosition.value + windowHeight.value);
  };

  onMounted(() => {
    window.addEventListener('scroll', onChange);
    window.addEventListener('resize', onChange);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', onChange);
    window.removeEventListener('resize', onChange);
  });

  return { scrollPosition, scrollPositionBottom, pageHeight, windowHeight };
};

const getPageHeight = () => {
  const body = document.body;
  const html = document.documentElement;

  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );
};
