<template>
  <form @submit.prevent="submit">
    <div class="grid grid-cols-3 gap-4">
      <InputField :label="t('comment')" name="internalComment" />
      <BookingRangePicker
        v-model:start-date="formValues.startDate"
        v-model:end-date="formValues.endDate"
        class="col-span-2"
        :car-id="props.car.id"
        :other-price-changing-module-is-active="false"
        :start-date-title="t('maintenanceStart')"
        :end-date-title="t('maintenanceEnd')"
      />
    </div>

    <Divider />

    <VehicleImmutable :car="car" />

    <Divider />

    <div class="flex justify-end"></div>

    <div
      class="flex items-center"
      :class="{ 'justify-end': blockNew, 'justify-between': !blockNew }"
    >
      <CVButton
        v-if="!blockNew"
        outline
        variant="error"
        :disabled="isSaving"
        :is-loading="isDeleting"
        @click.prevent="$emit('onDelete')"
      >
        {{ t('deleteBlock') }}
      </CVButton>

      <CVButton
        variant="primary"
        type="submit"
        class="justify-self-end"
        :disabled="isDeleting"
        :is-loading="isSaving"
      >
        {{ t('save') }}
      </CVButton>
    </div>
  </form>
</template>

<script setup lang="ts">
import BookingRangePicker from '@/views/handover/components/overview/BookingRangePicker.vue';
import InputField from '@/components/InputField.vue';
import { useI18n } from 'vue-i18n';
import type { Car } from '@/entities/cars/car.entity';
import { reactive } from 'vue';
import Divider from '@/components/Divider.vue';
import { useForm } from 'vee-validate';
import type { UpdateInternalBookingDto } from '@/entities/bookings/internal-booking.entity';
import { maintenanceSchema } from '@/validation/maintenance.schema';
import { DateTime } from 'luxon';
import VehicleImmutable from '@/components/booking-forms/VehicleImmutable.vue';
import type { Booking } from '@/entities/bookings/booking.entity';

const props = defineProps<{
  car: Car;
  block?: Booking;
  blockNew: boolean;
  startDate?: DateTime;
  isSaving: boolean;
  isDeleting: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSubmit', value: UpdateInternalBookingDto): void;
  (e: 'onDelete'): void;
}>();

const { t } = useI18n();

interface FormValues {
  startDate: string;
  endDate: string;
}

const newStartDate = props.startDate?.isValid
  ? (props.startDate as DateTime<true>)
  : DateTime.now();

const formValues: FormValues = reactive({
  startDate:
    props.block?.startDate ??
    newStartDate.set({ hour: 11, minute: 0, second: 0, millisecond: 0 }).toISO(),
  endDate:
    props.block?.endDate ??
    newStartDate.plus({ day: 1 }).set({ hour: 11, minute: 0, second: 0, millisecond: 0 }).toISO(),
});

const { handleSubmit } = useForm({
  initialValues: {
    internalComment: props.block?.internalComment,
  },
  validationSchema: maintenanceSchema,
});

const submit = handleSubmit((values) => {
  emit('onSubmit', {
    ...formValues,
    ...values,
  });
});
</script>

<i18n lang="json">
{
  "en": {
    "comment": "Comment",
    "save": "Save",
    "maintenanceStart": "Start",
    "maintenanceEnd": "End",
    "deleteBlock": "Delete Block"
  },
  "de": {
    "comment": "Kommentar",
    "save": "Speichern",
    "maintenanceStart": "Beginn",
    "maintenanceEnd": "Ende",
    "deleteBlock": "Block löschen"
  }
}
</i18n>
