<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center justify-start gap-4">
      <SearchCustomerInput @on-update-user="selectedCustomerForSwap = $event" />
      <CVButton
        variant="success"
        :disabled="!selectedCustomerForSwap"
        :is-loading="isSaving"
        @click.prevent="swapToSelectedCustomer"
        >{{ swapButtonText ?? t('swap') }}</CVButton
      >
      <span>/</span>
      <CVButton variant="success" :is-loading="isSaving" @click.prevent="createNewCustomer">{{
        t('createNewCustomer')
      }}</CVButton>
    </div>
    <CVButton variant="warning" @click.prevent="$emit('cancel')">{{ t('cancel') }}</CVButton>
  </div>

  <CreateCustomerModal
    v-model:show-modal="showCreateCustomerModal"
    :schema="schema"
    :initial-customer-data="initialCustomerData"
    :is-saving="isSaving"
    @new-customer-created="$emit('swapCustomer', $event)"
    @on-finished="$emit('cancel')"
    @on-cancel="showCreateCustomerModal = false"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import CreateCustomerModal from './CreateCustomerModal.vue';
import SearchCustomerInput from './SearchCustomerInput.vue';
import type { CreateUserDto, User } from '@/entities/auth/user.entity';
import type { ObjectSchema } from 'yup';
import { useI18n } from 'vue-i18n';

defineProps<{
  schema: ObjectSchema<any>;
  initialCustomerData?: Partial<CreateUserDto>;
  swapButtonText?: string;
  isSaving?: boolean;
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'swapCustomer', customer: User): void;
}>();

const { t } = useI18n();

const selectedCustomerForSwap = ref<User>();
const showCreateCustomerModal = ref(false);

const swapToSelectedCustomer = () => {
  if (!selectedCustomerForSwap.value) return;
  emit('swapCustomer', selectedCustomerForSwap.value);
};

const createNewCustomer = () => {
  showCreateCustomerModal.value = true;
};
</script>

<i18n lang="json">
{
  "en": {
    "swap": "Swap",
    "createNewCustomer": "+ Create new customer"
  },
  "de": {
    "swap": "Austauschen",
    "createNewCustomer": "+ Neuen Kunden anlegen"
  }
}
</i18n>
