<template>
  <div class="flex flex-col gap-4">
    <div class="flex justify-between">
      <h2>{{ t('extraDeductible') }}</h2>
    </div>

    <div class="flex justify-between">
      <CheckboxField name="overrideDeductibleExtra">{{
        t('overrideDeductibleExtra')
      }}</CheckboxField>
      <CVButton v-if="overrideDeductibleExtra" @click.prevent="pushEmptyOption">{{
        t('addDeductibleOption')
      }}</CVButton>
    </div>

    <template v-if="overrideDeductibleExtra">
      <ExtraDeductibleOption
        v-for="(field, i) in fields"
        :key="i"
        :idx="i"
        :option="field.value"
        @add-option="pushEmptyOption"
        @remove-option="remove(i)"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useField, useFieldArray } from 'vee-validate';
import ExtraDeductibleOption from './ExtraDeductibleOption.vue';
import type { VehicleExtraOptionFormValues } from '@/entities/vehicle-extra.entity';
import { VehicleExtraOptionValueType } from '@/entities/vehicle-extra-option-value-type.enum';
import { VehicleExtraOptionPricingType } from '@/entities/vehicle-extra-option.entity';
import CVButton from '../buttons/CVButton.vue';
import CheckboxField from '../CheckboxField.vue';
import { watch } from 'vue';

const { t } = useI18n();

const { remove, push, replace, fields } =
  useFieldArray<VehicleExtraOptionFormValues>('extraDeductible.options');

const { value: overrideDeductibleExtra } = useField<boolean>('overrideDeductibleExtra');

watch(
  overrideDeductibleExtra,
  () => {
    if (overrideDeductibleExtra.value && fields.value.length === 0) {
      pushEmptyOption();
    } else if (!overrideDeductibleExtra.value) {
      replace([]);
    }
  },
  { immediate: true },
);

function pushEmptyOption() {
  push({
    name: '',
    value: null,
    valueType: VehicleExtraOptionValueType.NUMBER,
    pricePerDay: [],
    pricingType: VehicleExtraOptionPricingType.FIXED_PER_DAY,
  });
}
</script>

<i18n lang="json">
{
  "en": {
    "extraDeductible": "Deductible Extra",
    "overrideDeductibleExtra": "Override automatic Deductible Extra",
    "addDeductibleOption": "+ Add Deductible Option"
  },
  "de": {
    "extraDeductible": "Selbstbeteiligungs-Extra",
    "overrideDeductibleExtra": "Automatisches Selbstbeteiligungs-Extra überschreiben",
    "addDeductibleOption": "+ Selbstbeteiligungs-Option hinzufügen"
  }
}
</i18n>
