<template>
  <template v-if="summary">
    <span class="text-sm text-gray-500"> {{ startDate }} - {{ endDate }} </span>
    <div class="flex flex-row gap-8">
      <div class="flex-grow-1 flex flex-col gap-2" style="flex-grow: 1">
        <div class="flex flex-row justify-between gap-2">
          <div>
            <div class="text-md font-medium">
              {{ t('handovers') }}
            </div>
            <div class="text-xs text-gray-500">
              {{ t('count') }}
            </div>
          </div>
          <span class="text-3xl font-medium">
            {{ summary.handovers }}
          </span>
        </div>
        <div class="flex flex-row justify-between gap-2">
          <div>
            <div class="text-md font-medium">
              {{ t('returns') }}
            </div>
            <div class="text-xs text-gray-500">
              {{ t('count') }}
            </div>
          </div>
          <span class="text-3xl font-medium">
            {{ summary.returns }}
          </span>
        </div>
        <div class="flex flex-row justify-between gap-2">
          <div>
            <div class="text-md font-medium">
              {{ t('detailings') }}
            </div>
            <div class="text-xs text-gray-500">
              {{ t('count') }}
            </div>
          </div>
          <span class="text-3xl font-medium">
            {{ summary.detailings }}
          </span>
        </div>
      </div>
      <div class="flex flex-col gap-2" style="flex-grow: 1">
        <div class="flex flex-row justify-between gap-2">
          <div>
            <div class="text-md font-medium">
              {{ t('extrasProvisions') }}
            </div>
            <div class="text-xs text-gray-500">
              {{ t('sum') }}
            </div>
          </div>
          <DashboardAgentProvisionCurrency :number="summary.extrasProvisions" />
        </div>
        <div class="flex flex-row justify-between gap-2">
          <div>
            <div class="text-md font-medium">
              {{ t('ratingProvisions') }}
            </div>
            <div class="text-xs text-gray-500">
              {{ t('sum') }}
            </div>
          </div>
          <DashboardAgentProvisionCurrency :number="summary.ratingProvisions" />
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <Spinner />
  </template>
</template>
<script lang="ts" setup>
import { useAgentProvisionSummary } from '@/queries/use-agent-provision-summary';
import { useI18n } from 'vue-i18n';
import DashboardAgentProvisionCurrency from '@/views/dashboard/components/DashboardAgentProvisionCurrency.vue';
import { useFormattedDate } from '@/hooks/use-formatted-date';
import { getDateFormat } from '@/hooks/use-date-format';

const { t } = useI18n();

const { data: summary } = useAgentProvisionSummary();

const startDate = useFormattedDate(summary.value?.startDate, getDateFormat());
const endDate = useFormattedDate(summary.value?.endDate, getDateFormat());
</script>
<i18n lang="json">
{
  "en": {
    "handovers": "Handovers",
    "returns": "Returns",
    "count": "count",
    "sum": "sum",
    "extrasProvisions": "Extras provisions",
    "ratingProvisions": "5★ provisions",
    "detailings": "Detailings"
  },
  "de": {
    "handovers": "Übergaben",
    "returns": "Rücknahmen",
    "count": "Anzahl",
    "sum": "Summe",
    "extrasProvisions": "Extras-Provisionen",
    "ratingProvisions": "5★-Provisionen",
    "detailings": "Aufbereitungen"
  }
}
</i18n>
