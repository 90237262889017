<template>
  <div>
    <p class="text-right text-base font-medium">
      {{ t('totalPrice') }}
      <span v-currency="totalPrice" class="block text-xl" />
    </p>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

defineProps<{
  totalPrice: number;
}>();

const { t } = useI18n();
</script>

<i18n lang="json">
{
  "en": {
    "totalPrice": "Total Price"
  },
  "de": {
    "totalPrice": "Gesamtpreis"
  }
}
</i18n>
