import { carDocumentsService } from '@/api/car-documents.service';
import type {
  CarDocument,
  CreateCarDocumentDto,
  UpdateCarDocumentDto,
} from '@/entities/cars/car-document.entity';
import type { FindAllCarDocumentsQuery } from '@/entities/cars/find-all-car-documents-query';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import type { MutationDefaultsContext } from './query-client';
import {
  addItemToQueriesData,
  removeItemFromQueriesData,
  updateItemOnQueriesData,
} from '@/utils/update-query-data';

const QUERY_KEY = 'car-documents';

export const useCarDocument = (carId: string, documentId: string, enabled?: boolean) => {
  return useQuery({
    queryKey: [QUERY_KEY, carId, documentId],
    queryFn: () => carDocumentsService.getOne(carId, documentId),
    enabled,
  });
};

export const useCarDocuments = (carId: string) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY],
    queryFn: ({ pageParam = 1 }) => carDocumentsService.getAllPaginated(carId, { page: pageParam }),
    getNextPageParam: (lastPage) => lastPage.meta.nextPage,
    initialPageParam: 1,
  });
};

export const useCarDocumentsWithQuery = (carId: string, query: FindAllCarDocumentsQuery) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY],
    queryFn: ({ pageParam = 1 }) =>
      carDocumentsService.getAllPaginated(carId, { ...query, page: pageParam }),
    getNextPageParam: (lastPage) => lastPage.meta.nextPage,
    initialPageParam: 1,
  });
};

export const useUpdateCarDocument = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({
      carId,
      documentId,
      document,
    }: {
      carId: string;
      documentId: string;
      document: UpdateCarDocumentDto;
    }) => carDocumentsService.update(carId, documentId, document),
    onMutate: () => ({
      successMessage: 'Car Document updated',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(null, null, context);
      updateItemOnQueriesData<CarDocument>([QUERY_KEY], result, queryClient);
    },
  });
};

export const useRemoveCarDocument = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ carId, documentId }: { carId: string; documentId: string }) =>
      carDocumentsService.remove(carId, documentId),
    onMutate: (ids) => ({ ...ids, successMessage: 'Car Document deleted' }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      removeItemFromQueriesData<CarDocument>([QUERY_KEY], context?.documentId, queryClient);
    },
  });
};

export const useCreateCarDocument = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ carId, document }: { carId: string; document: CreateCarDocumentDto }) =>
      carDocumentsService.create(carId, document),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'Car Document created',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(null, null, context);
      addItemToQueriesData<CarDocument>([QUERY_KEY], result, queryClient);
    },
  });
};
