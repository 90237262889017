<template>
  <div class="mt-4 grid grid-cols-3 grid-rows-1 gap-4">
    <LabeledText :label="t('rentalPartner')">{{
      t(`rentalPartners.${booking.rentalPartner}`)
    }}</LabeledText>

    <LabeledText :label="t('referenceNumber')">{{ booking.internalComment }}</LabeledText>
  </div>
</template>

<script setup lang="ts">
import type { Booking } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';
import LabeledText from '@/components/LabeledText.vue';

const { t } = useI18n();
defineProps<{
  booking: Booking;
}>();
</script>

<i18n lang="json">
{
  "en": {
    "rentalPartner": "Rental Partner",
    "referenceNumber": "Reference Number"
  },
  "de": {
    "rentalPartner": "Mietpartner",
    "referenceNumber": "Referenznummer"
  }
}
</i18n>
