import { emailConfirmationService } from '@/api/email-confirmation.service';
import { useMutation } from '@tanstack/vue-query';
import type { MutationDefaultsContext } from '../query-client';

const CONFIRM_QUERY_KEY = 'confirmEmail';
const RESEND_QUERY_KEY = 'resendEmail';

export const useConfirmEmail = () => {
  return useMutation<unknown, unknown, string, MutationDefaultsContext>({
    mutationKey: [CONFIRM_QUERY_KEY],
    mutationFn: (token) => emailConfirmationService.confirmEmail(token),
    onMutate: () => ({
      successMessage: 'Email confirmed',
      invalidateQueryKeys: [CONFIRM_QUERY_KEY],
    }),
  });
};

export const useResendConfirmEmail = () => {
  return useMutation<unknown, unknown, void, MutationDefaultsContext>({
    mutationKey: [RESEND_QUERY_KEY],
    mutationFn: () => emailConfirmationService.resendConfirmationLink(),
    onMutate: () => ({
      successMessage: 'Conrfimation link sent',
      invalidateQueryKeys: [RESEND_QUERY_KEY],
    }),
  });
};
