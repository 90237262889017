<template>
  <SearchApiEntityItemInput
    :search-result-options="searchResultOptions"
    :is-fetching-results="isFetchingResults"
    :placeholder="searchInputPlaceholder"
    @fetch="fetchResults"
    @on-update-selected-item="updateSelectedItem"
  />
</template>

<script lang="ts" setup>
import { useUsersWithQuery } from '@/queries/use-users';
import SearchApiEntityItemInput from '@/components/SearchApiEntityItemInput.vue';
import { computed, ref } from 'vue';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { useI18n } from 'vue-i18n';
import type { User } from '@/entities/auth/user.entity';

const emit = defineEmits<{
  (e: 'on-update-user', value: User): void;
}>();

const { t } = useI18n();

const searchString = ref('');
const searchInputPlaceholder = computed(() => t('typeCustomerName'));

const query = computed(() => ({
  query: searchString.value,
  limit: 20,
}));

const { data: searchResultsData, isFetching: isFetchingResults } = useUsersWithQuery(query);

const fetchResults = (search: string) => {
  searchString.value = search;
};

const searchResults = useFlattenPaginatedData(searchResultsData);

const searchResultOptions = computed(() => {
  return searchResults.value.map((user) => {
    const content = [user.firstName + ' ' + user.lastName];
    user.street && content.push(user.street);
    (user.zip || user.city) && content.push((user.zip || '-') + ' ' + (user.city ?? '-'));
    content.push(t('customerNr') + ' ' + user.customerNumber);
    return {
      item: user,
      content,
    };
  });
});

const updateSelectedItem = (item: any) => {
  emit('on-update-user', item as User);
};
</script>

<i18n lang="json">
{
  "en": {
    "customerNr": "Cnr.",
    "typeCustomerName": "Type customer name"
  },
  "de": {
    "customerNr": "Knr.",
    "typeCustomerName": "Kundenname eingeben"
  }
}
</i18n>
