import * as yup from 'yup';

export const paymentSchema = {
  invoiceId: yup.string().nullable(),
  direction: yup.string(),
  amount: yup.number().typeError('specify amount'),
  paymentType: yup.string().required(),
  referenceId: yup.string().optional().nullable(),
};

export const paymentCreateSchema = yup.object(paymentSchema);
