<template>
  <Textarea v-model:input-text="value" :hint="error" :is-error="!!error" class="w-full" />
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { ref, watch } from 'vue';
import Textarea from './Textarea.vue';

const props = defineProps<{
  name: string;
  initialValue?: string | null;
  emptyValueToNull?: boolean;
  preventErrorOnMount?: boolean;
}>();

const { errorMessage, value } = useField(props.name, undefined, {
  initialValue: props.initialValue,
});

// Prevent error being displayed on mount in some cases
const error = ref<string | undefined>(props.preventErrorOnMount ? undefined : errorMessage.value);
watch(errorMessage, (newError) => (error.value = newError));

if (props.emptyValueToNull) {
  watch(
    value,
    (newValue) => (value.value = newValue === '' || newValue === undefined ? null : newValue),
  );
}
</script>
