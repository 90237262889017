import { onBeforeRouteLeave } from 'vue-router';
import { Alert } from '@/utils/alert';
import { i18n } from '@/i18n';
import { onBeforeUnmount } from 'vue';

export const usePreventLeavingUnsavedForm = (hasChanged: () => boolean) => {
  const { t } = i18n.global;

  onBeforeRouteLeave(async () => {
    if (hasChanged()) {
      const alertResult = await Alert.fire({
        titleText: t('unsavedChangesTitle'),
        text: t('unsavedChangesText'),
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: t('leave'),
        denyButtonText: t('stay'),
      });
      if (alertResult.isDenied) {
        return false;
      }
    }
  });

  window.onbeforeunload = () => {
    if (hasChanged()) {
      return false;
    }
  };

  onBeforeUnmount(() => {
    window.onbeforeunload = null;
  });
};
