export enum CarColor {
  WHITE = 'WHITE',
  BLACK = 'BLACK',
  GREY = 'GREY',
  SILVER = 'SILVER',
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
  ORANGE = 'ORANGE',
  TURQUOISE = 'TURQUOISE',
  CHALK = 'CHALK',
  BEIGE = 'BEIGE',
  NATO_OLIVE = 'NATO_OLIVE',
  GOLD = 'GOLD',
  BROWN = 'BROWN',
}
