<template>
  <div class="grid grid-cols-3 gap-4">
    <FilterDropdowns
      :category-filter="categoryFilter"
      :make-filter="makeFilter"
      class="col-span-2"
      @change="handleFilterChange"
    />
  </div>

  <VehiclesList
    :create-booking-props="createBookingProps"
    class="mt-8"
    only-closest-to-delivery-location
    @select-offer="handleSelectOffer"
  />

  <CreateBookingModal
    v-if="pickupLocation && dropoffLocation && selectedOffer"
    v-model:show-modal="showModal"
    :create-booking-props="createBookingProps"
  />
</template>

<script lang="ts" setup>
import VehiclesList from '../booking-create/components/VehiclesList.vue';
import { ref } from 'vue';
import CreateBookingModal from '../booking-create/components/CreateBookingModal.vue';
import type { VehicleCategory } from '@/entities/vehicle-category.enum';
import type { InsuranceCase, Offer } from '@carvia/ros-client-types';
import FilterDropdowns from '@/views/bookings/booking-create/components/FilterDropdowns.vue';
import { useCreateBookingProps } from '@/views/bookings/booking-create/components/use-create-booking-props';
import { locationGeocodedAddressToBookingLocation } from '@/utils/location-geocoded-address-to-booking-location';
import type { Pricing } from '@/entities/pricing.entity';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import { getStationOfCarByDate } from '@/hooks/use-current-station-of-car';
import type { Car } from '@/entities/cars/car.entity';

const props = defineProps<{
  insuranceCase: InsuranceCase;
}>();

const createBookingProps = useCreateBookingProps({
  startDate: new Date(props.insuranceCase.startDate),
  endDate: new Date(props.insuranceCase.endDate),
  pickupLocation:
    locationGeocodedAddressToBookingLocation(props.insuranceCase.locationGeocodedAddress) ??
    undefined,
  insuranceCase: props.insuranceCase,
});
const {
  startDate,
  pickupLocation,
  dropoffLocation,
  categoryFilter,
  makeFilter,
  selectedOffer,
  pricing,
} = createBookingProps;

const showModal = ref(false);

const handleFilterChange = (category: VehicleCategory | null, make: string | null) => {
  categoryFilter.value = category;
  makeFilter.value = make;
};

const handleSelectOffer = (offer: Offer) => {
  selectedOffer.value = offer;
  pricing.value = offer.pricing as unknown as Pricing;
  dropoffLocation.value = {
    locationType: BookingLocationType.STATION,
    station: getStationOfCarByDate(offer.car as unknown as Car, startDate.value),
    location: null,
  };
  showModal.value = true;
};
</script>
