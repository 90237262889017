<template>
  <CVButton class="group">
    <slot></slot>
    <i
      class="arrow-long-right group-hover:text-primary group-hover:after:border-primary group-disabled:opacity-30"
    ></i>
  </CVButton>
</template>

<style scoped>
.arrow-long-right {
  @apply relative  block h-[6px] w-[14px] scale-90 border-b-[2px]
      border-t-[2px] border-b-transparent
      border-t-transparent text-white
      shadow-[inset_0_0_0_2px]
      after:absolute after:-bottom-[2px] after:right-0 after:block after:h-[6px]
      after:w-[6px] after:rotate-[225deg] after:border-b-[2px]
      after:border-l-[2px] after:border-white after:content-[''];
}
</style>
