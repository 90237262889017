import { internalBookingsService } from '@/api/internal-bookings.service';
import type {
  CreateInternalBookingDto,
  UpdateInternalBookingDto,
} from '@/entities/bookings/internal-booking.entity';
import { useMutation } from '@tanstack/vue-query';

const QUERY_KEY = 'internal-bookings';

export const useUpdateInternalBooking = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({
      id,
      internalBooking,
    }: {
      id: string;
      internalBooking: UpdateInternalBookingDto;
    }) => internalBookingsService.update(id, internalBooking),
    onMutate: () => ({ successMessage: 'Internal Booking updated' }),
  });
};

export const useCreateInternalBooking = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (internalBooking: CreateInternalBookingDto) =>
      internalBookingsService.create(internalBooking),
    onMutate: () => ({ successMessage: 'Internal Booking created' }),
  });
};
