import type { Interval } from 'luxon';

export const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export function* daysFromInterval(interval: Interval) {
  if (!interval.start || !interval.end) {
    throw new Error('Infinite interval given');
  }
  let cursor = interval.start.startOf('day');
  while (cursor < interval.end) {
    yield cursor;
    cursor = cursor.plus({ days: 1 });
  }
}
