import { VehicleExtraType } from '@/entities/vehicle-extra-type.enum';
import * as yup from 'yup';

export const customExtrasSchema = yup.object({
  customBookedExtras: yup
    .array(
      yup.object({
        description: yup.string().required(),
        price: yup.number().required(),
        customVehicleExtraType: yup.string().oneOf(Object.keys(VehicleExtraType)).nullable(),
        value: yup.number().when('customVehicleExtraType', {
          is: (val: VehicleExtraType) =>
            val === VehicleExtraType.ADDITIONAL_DRIVER ||
            val === VehicleExtraType.DEDUCTIBLE ||
            val === VehicleExtraType.EXTRA_KM,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired(),
        }),
      }),
    )
    .notRequired(),
});

export const customTotalBasePriceSchema = yup.object({
  customTotalBasePrice: yup.number().nullable(),
});
