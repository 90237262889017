<template>
  <HeaderBar />
  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
  </ButtonBackContainer>
  <section class="carvia-container">
    <MaintenanceForm
      v-if="car && (block || blockNew)"
      :car="car"
      :block="block"
      :block-new="blockNew"
      :start-date="startDate"
      :is-saving="isCreating || isUpdating"
      :is-deleting="isDeleting"
      @on-submit="handleSubmit"
      @on-delete="onDelete"
    />
    <div v-else class="flex justify-center">
      <Spinner />
    </div>
  </section>
</template>

<script setup lang="ts">
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import MaintenanceForm from './MaintenanceForm.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import { useCar } from '@/queries/use-cars';
import { useUiStore } from '@/stores/ui.store';
import { ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  useCreateInternalBooking,
  useUpdateInternalBooking,
} from '@/queries/use-internal-bookings';
import type {
  CreateInternalBookingDto,
  UpdateInternalBookingDto,
} from '@/entities/bookings/internal-booking.entity';
import { useI18n } from 'vue-i18n';
import { useBooking, useDeleteBooking } from '@/queries/use-bookings';
import { BookingType } from '@/entities/bookings/booking-type.enum';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import { Alert } from '@/utils/alert';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import { computed } from 'vue';
import { DateTime } from 'luxon';

const { t } = useI18n();
const uiStore = useUiStore();
const route = useRoute();
const router = useRouter();
const carId = route.params.carId as string;
const blockId = ref(route.params.blockId as string);
const blockNew = ref(blockId.value === 'new');
const startDate = route.query.startDate
  ? DateTime.fromISO(route.query.startDate as string)
  : undefined;
const blockFetchingEnabled = computed(() => !blockNew.value);
const { data: car } = useCar(carId);
const { data: block } = useBooking(blockId, blockFetchingEnabled);

watchEffect(() => {
  uiStore.setHeaderTitle('Block', car.value?.vehicleType.make.name, car.value?.vehicleType.model);
});

const { isPending: isCreating, mutateAsync: createMaintenanceBooking } = useCreateInternalBooking();
const { isPending: isUpdating, mutateAsync: updateMaintenanceBooking } = useUpdateInternalBooking();
const { isPending: isDeleting, mutateAsync: deleteMaintenanceBooking } = useDeleteBooking();

const handleSubmit = async (internalBooking: UpdateInternalBookingDto) => {
  if (blockNew.value) {
    const createMaintenanceBookingValues = {
      ...internalBooking,
      carId: carId,
      type: BookingType.MAINTENANCE_BOOKING,
      pickupLocationType: BookingLocationType.STATION,
      dropoffLocationType: BookingLocationType.STATION,
    };
    const { id } = await createMaintenanceBooking(
      createMaintenanceBookingValues as CreateInternalBookingDto,
    );
    router.replace({
      name: 'maintenanceEdit',
      params: { carId: car.value?.id, blockId: id },
    });
    blockId.value = id;
    blockNew.value = false;
  } else {
    updateMaintenanceBooking({ id: blockId.value, internalBooking });
  }
};

const onDelete = async () => {
  if (!block.value?.id) return;
  const alertResult = await Alert.fire({
    titleText: t('reallyDeleteBlockTitle'),
    text: t('reallyDeleteBlockText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('delete'),
    denyButtonText: t('keep'),
  });
  if (alertResult.isConfirmed) {
    await deleteMaintenanceBooking(block.value.id);
    router.push({ name: 'bookingPlan' });
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "comment": "Comment",
    "back": "Back",
    "reallyDeleteBlockTitle": "Really remove Block?",
    "reallyDeleteBlockText": "The Block will be permanently deleted.",
    "delete": "Delete",
    "keep": "Keep"
  },
  "de": {
    "comment": "Kommentar",
    "back": "Zurück",
    "reallyDeleteBlockTitle": "Block wirklich entfernen?",
    "reallyDeleteBlockText": "Der Block wird unwiderruflich gelöscht.",
    "delete": "Löschen",
    "keep": "Behalten"
  }
}
</i18n>
