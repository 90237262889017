import { httpClient } from './http-client';
import type { GetDamageMediaUploadUrlDto } from '@/entities/damage.entity';
import type { GetPresignedUploadUrlResponse } from '@/api/interfaces/get-presigned-upload-url-response.interface';
import type {
  GetCarDocumentUploadUrlDto,
  GetCarImageUploadUrlDto,
} from '@/entities/cars/car.entity';
import type { GetVehicleTypeImageUploadUrlDto } from '@/entities/vehicle-type.entity';
import type { GetContractSignatureUploadUrlDto } from '@/entities/bookings/booking.entity';

export class UploadService {
  httpClient = httpClient;
  endpoint = '/uploads';

  async uploadDamageMedia(params: GetDamageMediaUploadUrlDto, file: string | Blob) {
    return this.uploadToS3('damage-media', params, file);
  }

  async uploadCarImage(params: GetCarImageUploadUrlDto, file: string | Blob) {
    return this.uploadToS3('car-image', params, file);
  }

  async uploadVehicleTypeImage(params: GetVehicleTypeImageUploadUrlDto, file: string | Blob) {
    return this.uploadToS3('vehicle-type-image', params, file);
  }

  async uploadCarDocument(params: GetCarDocumentUploadUrlDto, file: string | Blob) {
    return this.uploadToS3('car-document', params, file);
  }

  async uploadContractSignature(params: GetContractSignatureUploadUrlDto, file: string | Blob) {
    return this.uploadToS3('contract-signature', params, file);
  }

  async uploadToS3(
    endpoint: string,
    params:
      | GetDamageMediaUploadUrlDto
      | GetCarImageUploadUrlDto
      | GetVehicleTypeImageUploadUrlDto
      | GetCarDocumentUploadUrlDto
      | GetContractSignatureUploadUrlDto,
    file: string | Blob,
  ) {
    const uploadUrl = await this.httpClient.get<GetPresignedUploadUrlResponse>(
      this.endpoint + '/upload-url/' + endpoint,
      {
        params,
      },
    );
    const { url, fileKey } = uploadUrl.data;

    await this.httpClient.put(url, file, {
      headers: {
        Authorization: null,
        'Content-Type': 'multipart/form-data',
      },
    });

    return { fileKey };
  }
}

export const uploadService = new UploadService();
