<template>
  <HeaderBar />
  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
    <AuditLogButton entity-type="InsuranceCase" :entity-id="insuranceCaseId" />
  </ButtonBackContainer>

  <section class="carvia-container space-y-8">
    <div v-if="insuranceCase?.status === InsuranceCaseStatus.RETURNED && insuranceCase">
      <InsuranceCaseCloseForm :insurance-case="insuranceCase" @close="$emit('close')" />
    </div>
    <h2>{{ t('editInsuranceCase') }}</h2>
    <CreateInsuranceCaseForm
      v-if="isNewInsuranceCase || insuranceCase"
      :initial-insurance-case="insuranceCase"
      :is-saving="isCreating || isUpdating"
      :is-deleting="isDeleting"
      @submit="handleSubmit"
      @delete="handleDelete"
    />
  </section>
</template>
<script setup lang="ts">
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useUiStore } from '@/stores/ui.store';
import { useI18n } from 'vue-i18n';
import { computed, ref, watchEffect } from 'vue';
import {
  useCreateInsuranceCase,
  useDeleteInsuranceCase,
  useInsuranceCase,
  useUpdateInsuranceCase,
} from '@/queries/use-insurance-cases';
import CreateInsuranceCaseForm from './InsuranceCaseForm.vue';
import {
  type CreateInsuranceCaseDto,
  InsuranceCaseStatus,
  type UpdateInsuranceCaseDto,
} from '@carvia/ros-client-types';
import { useRoute, useRouter } from 'vue-router';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import AuditLogButton from '@/components/audit-log/AuditLogButton.vue';
import InsuranceCaseCloseForm from '@/views/insurance-cases/insurance-case/InsuranceCaseCloseForm.vue';
import { Alert } from '@/utils/alert';

const uiStore = useUiStore();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const insuranceCaseId = ref(route.params.id as string);
const isNewInsuranceCase = computed(() => insuranceCaseId.value === 'new');
const insuranceCaseEnabled = computed(() => !isNewInsuranceCase.value);
const { data: insuranceCase } = useInsuranceCase(insuranceCaseId, insuranceCaseEnabled);

watchEffect(() => {
  if (insuranceCase.value) {
    const { insuranceProvider, customerData } = insuranceCase.value;
    uiStore.setHeaderTitle(
      t('insuranceCase'),
      `${insuranceProvider}`,
      `${customerData.firstName} ${customerData.lastName}`,
    );
  } else {
    uiStore.setHeaderTitle(t('insuranceCase'));
  }
});

const { mutateAsync: create, isPending: isCreating } = useCreateInsuranceCase();
const { mutateAsync: update, isPending: isUpdating } = useUpdateInsuranceCase();
const { mutateAsync: remove, isPending: isDeleting } = useDeleteInsuranceCase();

const handleSubmit = async (values: CreateInsuranceCaseDto | UpdateInsuranceCaseDto) => {
  if (isNewInsuranceCase.value) {
    const { id } = await create(values as CreateInsuranceCaseDto);
    await router.replace({ name: 'insuranceCase', params: { id } });
    insuranceCaseId.value = id;
  } else {
    await update({ id: insuranceCaseId.value, insuranceCase: values as UpdateInsuranceCaseDto });
  }
};

const handleDelete = async () => {
  const { isConfirmed } = await Alert.fire({
    text: t('reallyDeleteInsuranceCaseText'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('delete'),
    cancelButtonText: t('cancel'),
  });
  if (!isConfirmed) return;
  await remove(insuranceCaseId.value);
  await router.push({ name: 'insuranceCasesList' });
};
</script>

<i18n lang="json">
{
  "en": {
    "insuranceCase": "Insurance case",
    "editInsuranceCase": "Edit insurance case",
    "reallyDeleteInsuranceCaseText": "Do you really want to delete the insurance case?",
    "delete": "Delete"
  },
  "de": {
    "insuranceCase": "Versicherungsfall",
    "editInsuranceCase": "Versicherungsfall bearbeiten",
    "reallyDeleteInsuranceCaseText": "Möchtest du den Versicherungsfall wirklich löschen?",
    "delete": "Löschen"
  }
}
</i18n>
