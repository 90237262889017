<template>
  <div class="relative col-span-2">
    <BookingRangePicker
      v-if="!hideBookingRangePicker"
      v-model:start-date="startDate"
      v-model:end-date="endDate"
      class="col-span-2"
      :car-id="props.car.id"
      :booking-id="props.booking.id"
      :disabled="disabled || chargingAmount"
      :start-date-title="t('rentalStart')"
      :end-date-title="t('rentalEnd')"
      @change="handleTimeRangeChange"
    />

    <div v-else class="col-span-2 grid w-full grid-cols-2 space-x-2">
      <LabeledText class="px-1" :label="t('rentalStart')">
        {{ formatDate(startDate) }}
      </LabeledText>
      <LabeledText class="px-1" :label="t('rentalEnd')"> {{ formatDate(endDate) }}</LabeledText>
    </div>

    <DisplayLocation v-if="showLocations" :booking="booking" hide-station-addresses />

    <div v-if="showAlternativeCars">
      <div class="mb-1 mt-3 border border-primary p-2 pb-1 pt-1">
        {{ t('vehicleNotAvailableSelectAlternative') }}
      </div>
      <div v-if="!alternativeCars.length" class="mt-3 bg-primary/20 p-2 pb-1">
        {{ t('noAlternativeVehicleAvailable') }}
      </div>
      <div v-if="alternativeCars.length && vehicleType">
        <div v-for="(alternativeCar, i) in alternativeCars" :key="i">
          <div class="grid grid-cols-12 items-center gap-4 border-b border-primary pb-3 pt-3">
            <div class="col-span-5">{{ t('lp') }}: {{ alternativeCar.licencePlate }}</div>
            <div class="col-span-3">{{ t('color') }}: {{ alternativeCar.color ?? 'n/a' }}</div>
            <div class="col-span-4 flex justify-end">
              <CVButton outline @click.prevent="changeCar(alternativeCar as Car)">
                {{ t('choose') }}
              </CVButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="car.id !== booking.car.id" class="mt-3 border border-primary p-2 pb-1 pt-1">
        {{ t('alternativeCarSelected') }}: {{ car.licencePlate }}
      </div>
    </div>

    <div v-if="hasChanged && !chargingAmount" class="mt-2">
      <div class="mt-3">
        <CVButton
          v-if="daysHaveBeenExtended"
          :disabled="isSaving || recalculatingPrice"
          :is-loading="recalculatingPrice"
          class="mr-2"
          @click.prevent="extendTimePeriodOfPricing"
        >
          {{ t('recalculatePrice') }}
        </CVButton>
        <CVButton
          :disabled="recalculatingPrice || showAlternativeCars"
          variant="success"
          :is-loading="isSaving"
          class="btn btn-success mr-2"
          @click.prevent="saveTimeRangeChange"
        >
          {{ t('save') }}
        </CVButton>
        <CVButton
          variant="warning"
          :disabled="isSaving || recalculatingPrice"
          class="mr-2"
          @click.prevent="cancelTimeRangeChange"
        >
          {{ t('cancel') }}
        </CVButton>
      </div>
    </div>
    <ChargeAmountForm
      v-if="chargingAmount"
      :booking="booking"
      :invoices="invoices"
      :show-online-payment-card="showOnlinePaymentCard"
      :show-blocked-card="showBlockedCard"
      :confirm-button-text="t('chargeAndSave')"
      :initial-amount="surchargeAmount"
      :is-charging="isChargingAmount"
      @on-submit="chargeSurchargeAndSave"
      @on-cancel="cancelTimeRangeChange"
    />
    <div
      v-if="isFetchingVehicleType"
      class="absolute -inset-3 flex items-center justify-center bg-black/20"
    >
      <Spinner />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import type { Car } from '@/entities/cars/car.entity';
import type { Pricing } from '@/entities/pricing.entity';
import { useI18n } from 'vue-i18n';
import BookingRangePicker from '@/views/handover/components/overview/BookingRangePicker.vue';
import { DateTime } from 'luxon';
import { computed, ref, toRef, watch } from 'vue';
import LabeledText from '../LabeledText.vue';
import DisplayLocation from './DisplayLocation.vue';
import { useVehicleTypeWithQuery } from '@/queries/use-vehicle-types';
import { formatDate } from '@/hooks/use-formatted-date';
import type { ExtendTimePeriodOfPricingParams } from '@/hooks/use-extend-time-period-of-pricing-with-alert';
import { getStationOfCarByDate, useStationOfCarByDate } from '@/hooks/use-current-station-of-car';
import ChargeAmountForm from './ChargeAmountForm.vue';
import { useInvoicesOfBooking } from '@/hooks/use-invoices-of-booking';
import { useAvailableCreditCards } from '@/hooks/use-available-credit-cards';
import { useChargeAmount } from '@/queries/use-payment-api';
import type { ChargeAmountDto } from '@/entities/payment-terminals/charge-amount.dto';
import { Alert } from '@/utils/alert';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';

const props = defineProps<{
  startDate: string;
  endDate: string;
  car: Car;
  priceCalculation: Pricing;
  isSaving: boolean;
  recalculatingPrice: boolean;
  booking: Booking;
  showLocations?: boolean;
  hideBookingRangePicker?: boolean;
  disabled?: boolean;
}>();
const emit = defineEmits<{
  (e: 'update:startDate', value: string): void;
  (e: 'update:endDate', value: string): void;
  (e: 'update:priceCalculation', value: Pricing): void;
  (e: 'update:car', value: Car): void;
  (e: 'hasChanged', value: boolean): void;
  (e: 'extendTimePeriodOfPricing', value: ExtendTimePeriodOfPricingParams): void;
  (
    e: 'updateBooking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished: () => void,
  ): void;
}>();

const { t } = useI18n();

const startDate = ref(props.startDate);
const endDate = ref(props.endDate);
const hasChanged = ref(false);
const daysHaveBeenExtended = ref(false);
const showAlternativeCars = ref(false);
const chargingAmount = ref(false);
const surchargeAmount = ref(0);

const propsCarStation = useStationOfCarByDate(
  toRef(props, 'car'),
  computed(() => props.booking.startDate),
);

const vehicleTypeQuery = computed(() => ({
  startDate: startDate.value,
  endDate: endDate.value,
  stationId: propsCarStation.value.id,
  ignoredBookingIds: [props.booking.id],
  ignoreOpeningHoursAndHolidays: true,
}));

const vehicleTypeId = computed(() => props.car.vehicleType.id);

const {
  data: vehicleType,
  refetch: fetchVehicleType,
  isFetching: isFetchingVehicleType,
} = useVehicleTypeWithQuery(vehicleTypeId, vehicleTypeQuery, false);

const { mutateAsync: charge, isPending: isChargingAmount } = useChargeAmount();
const invoices = useInvoicesOfBooking(computed(() => props.booking.id));

const { showBlockedCard, showOnlinePaymentCard } = useAvailableCreditCards(
  computed(() => props.booking),
);

const alternativeCars = computed(
  () =>
    vehicleType.value?.cars?.filter(
      (car) =>
        getStationOfCarByDate(car as Car, props.booking.startDate).id ===
          propsCarStation.value.id && !car.isBlocked,
    ) ?? [],
);

const calculatedTotalRentalDays = computed<number>(() => {
  const startDateTime = DateTime.fromJSDate(new Date(startDate.value));
  const endDateTime = DateTime.fromJSDate(new Date(endDate.value));
  return Math.ceil(endDateTime.diff(startDateTime, 'days').as('days'));
});

const handleTimeRangeChange = async () => {
  hasChanged.value =
    startDate.value !== props.booking.startDate || endDate.value !== props.booking.endDate;
  daysHaveBeenExtended.value = calculatedTotalRentalDays.value > props.priceCalculation.days;
  if (!hasChanged.value) {
    showAlternativeCars.value = false;
    return;
  }
  emitChangedValues();
  await fetchVehicleType();
  showAlternativeCars.value = !vehicleType.value?.cars.some(
    (car) => car.id === props.car.id && !car.isBlocked,
  );
};

const changeCar = (alternativeCar: Car) => {
  showAlternativeCars.value = false;
  const car: Car = {
    ...alternativeCar,
    vehicleType: { ...alternativeCar.vehicleType, ...vehicleType },
  };
  emit('update:car', car);
};

const extendTimePeriodOfPricing = () => {
  emit('extendTimePeriodOfPricing', {
    isConfirmedFunction: (amount) => {
      if (
        props.booking.status === BookingStatus.HANDED_OVER &&
        !props.booking.insuranceCase &&
        amount > 0
      ) {
        surchargeAmount.value = amount;
        chargingAmount.value = true;
      }
    },
  });
};

const cancelTimeRangeChange = () => {
  startDate.value = props.booking.startDate;
  endDate.value = props.booking.endDate;
  hasChanged.value = false;
  showAlternativeCars.value = false;
  chargingAmount.value = false;
  emit('update:priceCalculation', props.booking.priceCalculation);
  emit('update:car', props.booking.car);
  emitChangedValues();
};

const chargeSurchargeAndSave = async (chargeAmountDto: ChargeAmountDto) => {
  const result = await charge(chargeAmountDto);
  if (!result.charged) {
    return await Alert.fire({
      titleText: t('paymentFailedTitle'),
      text: t('paymentFailedText'),
      icon: 'error',
      confirmButtonText: t('ok'),
    });
  }
  // refetchPayments();
  chargingAmount.value = false;
  saveTimeRangeChange();
};

const saveTimeRangeChange = async () => {
  const updateBookingValues: UpdateBookingDto = {
    startDate: startDate.value,
    endDate: endDate.value,
    priceCalculationId: props.priceCalculation.id,
    carId: props.car.id,
  };
  emit(
    'updateBooking',
    {
      id: props.booking.id,
      booking: updateBookingValues,
    },
    () => {
      hasChanged.value = false;
    },
  );
};

function emitChangedValues() {
  emit('update:startDate', startDate.value);
  emit('update:endDate', endDate.value);
}

watch(hasChanged, (newValue) => emit('hasChanged', newValue));

watch(startDate, (newValue) => emit('update:startDate', newValue));
watch(endDate, (newValue) => emit('update:endDate', newValue));
</script>

<i18n lang="json">
{
  "en": {
    "rentalStart": "Rental Start",
    "rentalEnd": "Rental End",
    "recalculatePrice": "Recalculate Price",
    "recalculatingPrice": "Recalculating Price...",
    "chargeAndSave": "Charge and Save",
    "paymentFailedTitle": "Payment failed",
    "paymentFailedText": "The payment could not be processed. The rental cannot be extended.",
    "newPrice": "New Price",
    "lp": "LP",
    "color": "Color",
    "choose": "Choose",
    "alternativeCarSelected": "Alternative Car selected",
    "vehicleNotAvailableSelectAlternative": "The vehicle is not available in this time period. Please choose an alternative:",
    "noAlternativeVehicleAvailable": "No alternative vehicle available. Please try another time period."
  },
  "de": {
    "rentalStart": "Mietbeginn",
    "rentalEnd": "Mietende",
    "recalculatePrice": "Preis neu berechnen",
    "recalculatingPrice": "Preis wird neu berechnet...",
    "chargeAndSave": "Abbuchen und speichern",
    "paymentFailedTitle": "Zahlung fehlgeschlagen",
    "paymentFailedText": "Die Zahlung konnte nicht durchgeführt werden. Die Miete kann nicht verlängert werden.",
    "newPrice": "Neuer Preis",
    "lp": "Knz",
    "color": "Farbe",
    "choose": "Auswählen",
    "alternativeCarSelected": "Alternativfahrzeug ausgewählt",
    "vehicleNotAvailableSelectAlternative": "Das Fahrzeug ist in dem Zeitraum nicht verfügbar. Bitte wählen Sie eine Alternative:",
    "noAlternativeVehicleAvailable": "Kein Alternativfahrzeug verfügbar. Bitte versuchen Sie einen anderen Zeitraum."
  }
}
</i18n>
