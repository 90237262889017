import type { MiddlewareContext } from './middleware-context.interface';
import type { Middleware } from './middleware.type';

function middlewarePipeline(context: MiddlewareContext, middleware: Middleware[], index: number) {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    return context.next;
  }

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1);
    return nextMiddleware({ ...context, next: nextPipeline });
  };
}

export default middlewarePipeline;
