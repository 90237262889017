<template>
  <div class="flex flex-row align-middle">
    <span class="text-3xl font-medium"> {{ Math.floor(number) }},</span>
    <span class="pt-1 text-xs font-medium">
      {{ cents }}
    </span>
    <span class="ml-1 text-3xl font-medium"> € </span>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  number: number;
}>();

let cents = computed(() => {
  const v = Math.floor(props.number * 100) % 100;
  return v < 10 ? '0' + v : v;
});
</script>
