<template>
  <form class="space-y-4" @submit="handleSaveInsuranceCase">
    <div v-if="booking.insuranceCase?.insuranceProvider === 'axa'" class="space-y-4">
      <h2>{{ t('axaSettings') }}</h2>
      <AxaInsuranceDataForm v-if="formValues.insuranceProvider === 'axa'" handover-view />
    </div>
    <div v-if="formMeta.dirty" class="mt-4 flex justify-end gap-4">
      <CVButton variant="success" :is-loading="isSaving">{{ t('save') }}</CVButton>
      <CVButton variant="warning" :disabled="isSaving" @click.prevent="resetForm()">{{
        t('cancel')
      }}</CVButton>
    </div>
  </form>
</template>
<script lang="ts" setup>
import type { Booking } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';
import { useUpdateInsuranceCase } from '@/queries/use-insurance-cases';
import { computed, ref, watchEffect } from 'vue';
import {
  type AxaInsuranceData,
  type CreateInsuranceCaseDto,
  type UpdateInsuranceCaseDto,
} from '@carvia/ros-client-types';
import { useForm } from 'vee-validate';
import { insuranceCaseHandoverSchema } from '@/validation/insurance-case.schema';
import AxaInsuranceDataForm from '@/views/insurance-cases/insurance-case/AxaInsuranceDataForm.vue';

const props = defineProps<{
  booking: Booking;
}>();

const emit = defineEmits<{
  (e: 'valid', value: boolean): void;
}>();

const { t } = useI18n();

const { isPending: isSaving, mutateAsync: updateInsuranceCase } = useUpdateInsuranceCase();

const initialValues = ref<UpdateInsuranceCaseDto>(props.booking.insuranceCase as any);

const savedValue = ref<UpdateInsuranceCaseDto>(props.booking.insuranceCase as any);

const {
  handleSubmit,
  values: formValues,
  meta: formMeta,
  resetForm,
} = useForm<UpdateInsuranceCaseDto>({
  initialValues: initialValues.value,
  validationSchema: insuranceCaseHandoverSchema,
});

const isValid = computed(() => {
  if (savedValue.value.insuranceProvider === 'axa') {
    const insuranceData = savedValue.value.insuranceData as AxaInsuranceData;
    return !!insuranceData.licencePlate && !!insuranceData.porscheCenter;
  }
  return true;
});

watchEffect(() => {
  if (formMeta.value.dirty) {
    emit('valid', false);
    return;
  }
  emit('valid', isValid.value);
});

const handleSaveInsuranceCase = handleSubmit(
  async (values: CreateInsuranceCaseDto | UpdateInsuranceCaseDto) => {
    const res = await updateInsuranceCase({
      id: props.booking.insuranceCase!.id,
      insuranceCase: values as UpdateInsuranceCaseDto,
    });
    resetForm({ values: res as any });
    savedValue.value = values as UpdateInsuranceCaseDto;
  },
);
</script>
<i18n lang="json">
{
  "en": {
    "axaSettings": "AXA Settings"
  },
  "de": {
    "axaSettings": "AXA-Einstellungen"
  }
}
</i18n>
