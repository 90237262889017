<template>
  <HeaderBar />

  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
  </ButtonBackContainer>

  <section class="carvia-container flex flex-col justify-center">
    <div v-if="!isLoading" class="grow">
      <Damages
        v-if="booking"
        :car-id="booking.car.id"
        :booking-id="booking.id"
        :add-damage-button-text="t('addDamageToBooking')"
      />
    </div>
    <div v-else class="flex justify-center">
      <Spinner />
    </div>
  </section>
</template>

<script setup lang="ts">
import Damages from '@/components/booking-forms/damages/Damages.vue';
import { useRoute } from 'vue-router';
import { useBooking } from '@/queries/use-bookings';
import { useI18n } from 'vue-i18n';
import { useUiStore } from '@/stores/ui.store';
import { watchEffect } from 'vue';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';

const { t } = useI18n();
const route = useRoute();
const uiStore = useUiStore();
const { data: booking, isLoading } = useBooking(route.params.id as string);

watchEffect(() => {
  if (booking.value) {
    const { customerData, car } = booking.value;
    uiStore.setHeaderTitle(
      t('booking'),
      `${customerData?.firstName ?? 'n/a'} ${customerData?.lastName ?? 'n/a'}`,
      `${car.vehicleType?.make.name} ${car.vehicleType?.model}`,
    );
  } else {
    uiStore.setHeaderTitle(t('booking'));
  }
});
</script>
<i18n lang="json">
{
  "en": {
    "back": "back to booking edit",
    "booking": "Booking",
    "addDamageToBooking": "+ Add damage to booking"
  },
  "de": {
    "back": "Zurück zur Buchungsbearbeitung",
    "booking": "Buchung",
    "addDamageToBooking": "+ Schaden zur Buchung hinzufügen"
  }
}
</i18n>
