<template>
  <div class="mt-4 grid grid-cols-3 gap-4">
    <LabeledText :label="t('renter')"
      >{{ booking.customerData?.firstName ?? 'n/a' }}
      {{ booking.customerData?.lastName ?? 'n/a' }}</LabeledText
    >
    <LabeledText :label="t('bookingNumber')">{{ booking.bookingNumber }}</LabeledText>
    <TextareaField name="internalComment" :label="t('comment')" />
    <SelectField
      name="userId"
      :label="t('driver')"
      :options="driverOptions"
      empty-value-to="null"
    />
  </div>
</template>

<script lang="ts" setup>
import LabeledText from '@/components/LabeledText.vue';
import SelectField from '@/components/SelectField.vue';
import TextareaField from '@/components/TextareaField.vue';
import type { Booking } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';
import { useUsersWithQuery } from '@/queries/use-users';
import { computed } from 'vue';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { UserRole } from '@/entities/auth/user-role.enum';

const props = defineProps<{
  booking: Booking;
  transfer?: Booking;
}>();

const { t } = useI18n();

const { data: usersData } = useUsersWithQuery({
  role: [UserRole.ADMIN, UserRole.HUB_MANAGER, UserRole.HUB_AGENT, UserRole.OPERATOR],
  stationIds: [props.booking.car.stations[0].station.id],
  limit: 500,
});
const users = useFlattenPaginatedData(usersData);

const driverOptions = computed(() => [
  {
    label: t('noDriverSelected'),
    value: null,
  },
  ...(users.value
    ? users.value.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      }))
    : []),
]);
</script>

<i18n lang="json">
{
  "en": {
    "renter": "Renter",
    "bookingNumber": "Booking Number",
    "comment": "Comment",
    "driver": "Driver",
    "noDriverSelected": "No driver selected"
  },
  "de": {
    "renter": "Mieter",
    "bookingNumber": "Buchungsnummer",
    "comment": "Kommentar",
    "driver": "Fahrer",
    "noDriverSelected": "Kein Fahrer ausgewählt"
  }
}
</i18n>
