<template>
  <p
    class="mb-1 font-medium"
    :class="{
      'text-booking-open-content':
        isHandover &&
        !isLateHandover &&
        booking.car.readyToHandover &&
        booking.car.currentLocationType === CarLocationType.STATION,
      'text-booking-warning-content':
        isHandover &&
        !isLateHandover &&
        !booking.car.readyToHandover &&
        booking.car.currentLocationType === CarLocationType.STATION,
      'text-booking-danger-content':
        isLateHandover ||
        (isHandover &&
          (booking.car.currentLocationType !== CarLocationType.STATION ||
            booking.car.status === CarStatus.ORDERED)),
      'text-booking-return-content': isReturn,
      'text-booking-transfer-content': isTransfer,
      'text-booking-partner-content': isPartnerRent,
    }"
  >
    <slot></slot>
  </p>
</template>
<script setup lang="ts">
import type { ManyBookingsBooking } from '@/entities/bookings/many-bookings-booking.entity';
import { CarLocationType } from '@/entities/cars/car-location-type.enum';
import { CarStatus } from '@/entities/cars/car-status.enum';
import { useBookingCharacteristics } from '@/hooks/use-booking-characteristics';

const props = defineProps<{
  booking: ManyBookingsBooking;
}>();

const { isReturn, isHandover, isLateHandover, isTransfer, isPartnerRent } =
  useBookingCharacteristics(props.booking);
</script>
