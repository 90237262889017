<template>
  <div>
    <div class="grid grid-cols-2 gap-4">
      <div v-if="transferDeliveryPrice">
        <p class="mb-2 text-xl font-medium">{{ t('delivery') }}</p>
        <div>{{ t('from') }}: {{ deliveryFrom }}</div>
        <div>{{ t('to') }}: {{ deliveryTo }}</div>
        <div class="font-medium">{{ t('price') }}: <span v-currency="transferDeliveryPrice" /></div>
      </div>
      <div v-if="transferReturnPrice">
        <p class="mb-2 text-xl font-medium">{{ t('return') }}</p>
        <div>{{ t('from') }}: {{ returnFrom }}</div>
        <div>{{ t('to') }}: {{ returnTo }}</div>
        <div class="font-medium">{{ t('price') }}: <span v-currency="transferReturnPrice" /></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useLocationInfo } from '@/hooks/use-location-info';
import { useI18n } from 'vue-i18n';
import { computed, toRef } from 'vue';
import { useLocalizedFields } from '@/hooks/use-localized-fields';
import type { Car } from '@/entities/cars/car.entity';
import { useStationOfCarByDate } from '@/hooks/use-current-station-of-car';
import type { CreateBookingProps } from '@/views/bookings/booking-create/components/use-create-booking-props';

const props = defineProps<{
  car: Car;
  createBookingProps: CreateBookingProps;
}>();

const { startDate, pickupLocation, dropoffLocation, transferDeliveryPrice, transferReturnPrice } =
  props.createBookingProps;
const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();
const carStation = useStationOfCarByDate(toRef(props, 'car'), startDate);

const deliveryFrom = computed(() => getLocalizedField(carStation.value.info));
const deliveryTo = useLocationInfo(pickupLocation);
const returnFrom = useLocationInfo(dropoffLocation);
const returnTo = computed(() => getLocalizedField(carStation.value.info));
</script>

<i18n lang="json">
{
  "en": {
    "transfers": "Transfers",
    "delivery": "Transfer Delivery",
    "return": "Transfer Return",
    "from": "From",
    "to": "To",
    "price": "Price"
  },
  "de": {
    "transfers": "Transfers",
    "delivery": "Transfer Anlieferung",
    "return": "Transfer Rückführung",
    "from": "Von",
    "to": "Nach",
    "price": "Preis"
  }
}
</i18n>
