<template>
  <Modal v-model="modelValue">
    <template #title>{{ t('creditCardDetails') }}</template>
    <div class="mt-4">
      <p>
        <span class="font-medium">brand:</span>
        {{ creditCardDetails.brand }}
      </p>
      <p v-if="creditCardDetails.number">
        <span class="font-medium">number:</span>
        {{ creditCardDetails.number }}
      </p>
      <p v-if="creditCardDetails.cardholderName">
        <span class="font-medium">cardholderName:</span>
        {{ creditCardDetails.cardholderName }}
      </p>
      <p>
        <span class="font-medium">accountBIN:</span>
        {{ creditCardDetails.bin.accountBIN }}
      </p>
      <p v-if="creditCardDetails.bin.accountRangeHigh">
        <span class="font-medium">accountRangeHigh:</span>
        {{ creditCardDetails.bin.accountRangeHigh }}
      </p>
      <p v-if="creditCardDetails.bin.accountRangeLow">
        <span class="font-medium">accountRangeHigh:</span>
        {{ creditCardDetails.bin.accountRangeLow }}
      </p>
      <p v-if="creditCardDetails.country.countryName">
        <span class="font-medium">countryName:</span>
        {{ creditCardDetails.country.countryName }}
      </p>
      <p v-if="creditCardDetails.country.countryA2">
        <span class="font-medium">countryA2:</span>
        {{ creditCardDetails.country.countryA2 }}
      </p>
      <p>
        <span class="font-medium">countryA3:</span>
        {{ creditCardDetails.country.countryA3 }}
      </p>
      <p>
        <span class="font-medium">countryNumber:</span>
        {{ creditCardDetails.country.countryNumber }}
      </p>
      <p>
        <span class="font-medium">expiryDate:</span>
        {{ creditCardDetails.expiryDate }}
      </p>
      <p v-if="creditCardDetails.issuer">
        <span class="font-medium">issuer:</span>
        {{ creditCardDetails.issuer }}
      </p>
      <p v-if="creditCardDetails.product">
        <span class="font-medium">product:</span>
        {{ creditCardDetails.product }}
      </p>
      <p v-if="creditCardDetails.source">
        <span class="font-medium">source:</span>
        {{ creditCardDetails.source }}
      </p>
      <p v-if="creditCardDetails.type">
        <span class="font-medium">type:</span>
        {{ creditCardDetails.type }}
      </p>
    </div>
    <div class="mt-4 flex justify-end">
      <CVButton @click.prevent="modelValue = false">{{ t('close') }}</CVButton>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import type { CreditCardDetails } from '@/entities/bookings/credit-card-details';
import Modal from '../Modal.vue';
import { useI18n } from 'vue-i18n';
import { ref, watch } from 'vue';

const props = defineProps<{
  modelValue: boolean;
  creditCardDetails: CreditCardDetails;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const { t } = useI18n();

const modelValue = ref(props.modelValue);
watch(
  () => props.modelValue,
  () => {
    modelValue.value = props.modelValue;
  },
);

watch(modelValue, () => {
  emit('update:modelValue', modelValue.value);
});
</script>

<i18n lang="json">
{
  "en": {
    "creditCardDetails": "Credit Card Details",
    "close": "Close"
  },
  "de": {
    "creditCardDetails": "Kreditkarten Details",
    "close": "Schließen"
  }
}
</i18n>
