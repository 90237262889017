<template>
  <div>
    <h2 v-if="!hideHeader" class="mb-4">{{ t('customer') }}</h2>
    <div class="grid grid-cols-3 gap-4">
      <InputField :name="`${namePrefix}company`" :label="t('company')" />
      <SelectField
        :name="`${namePrefix}gender`"
        :label="t('gender')"
        :options="genderOptions"
        class="col-start-1"
      />
      <InputField :name="`${namePrefix}firstName`" :label="t('firstName')" />
      <InputField :name="`${namePrefix}lastName`" :label="t('lastName')" />
      <InputField :name="`${namePrefix}phone`" :label="t('phone')" />
      <DatepickerField :name="`${namePrefix}dateOfBirth`" :label="t('dateOfBirth')" />
      <div class="flex gap-x-2">
        <InputField class="grow-0 basis-1/2" :name="`${namePrefix}country`" :label="t('country')" />
        <SelectField
          class="basis-1/2"
          :name="`${namePrefix}preferredLocal`"
          :label="t('preferredLocal')"
          :options="preferredLocalOptions"
        />
      </div>
      <InputField class="w-full grow" :name="`${namePrefix}street`" :label="t('street')" />
      <div class="flex gap-2">
        <InputField class="basis-2/5" :name="`${namePrefix}zip`" :label="t('zip')" />
        <InputField :name="`${namePrefix}city`" :label="t('city')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import InputField from '@/components/InputField.vue';
import DatepickerField from '@/components/DatepickerField.vue';
import SelectField from '@/components/SelectField.vue';
import { Local } from '@/entities/auth/local.enum';
import { Gender } from '@/entities/auth/gender.enum';

const props = defineProps<{
  name?: string;
  hideEmail?: boolean;
  hideHeader?: boolean;
}>();

const { t } = useI18n();

const namePrefix = computed(() => (props.name ? `${props.name}.` : ''));

const genderOptions = computed(() =>
  Object.values(Gender).map((value) => ({
    label: t(`gender.${value}`),
    value,
  })),
);

const preferredLocalOptions = computed(() =>
  Object.values(Local).map((value) => ({
    label: t(`locales.${value}`),
    value,
  })),
);
</script>

<i18n lang="json">
{
  "en": {
    "customer": "Customer",
    "gender": "Gender",
    "firstName": "First Name",
    "lastName": "Last Name",
    "company": "Company",
    "city": "City",
    "zip": "Zip",
    "street": "Street",
    "phone": "Phone",
    "country": "Country",
    "dateOfBirth": "Date Of Birth",
    "preferredLocal": "Pref. Language"
  },
  "de": {
    "customer": "Kunde",
    "gender": "Geschlecht",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "company": "Firma",
    "city": "Stadt",
    "zip": "PLZ",
    "street": "Straße",
    "phone": "Telefon",
    "country": "Land",
    "dateOfBirth": "Geburtsdatum",
    "preferredLocal": "Bev. Sprache"
  }
}
</i18n>
