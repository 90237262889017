import { i18n } from '@/i18n';
import { computed, unref } from 'vue';

export const useDateTimeFormat = () => {
  return computed(() => getDateTimeFormat());
};

export const getDateTimeFormat = () => {
  const locale = i18n.global.locale;
  if (unref(locale) === 'de') {
    return 'dd.MM.yyyy, HH:mm';
  }
  return 'MM/dd/yyyy, HH:mm';
};

export const useDateFormat = () => {
  return computed(() => getDateFormat());
};

export const getDateFormat = () => {
  const locale = i18n.global.locale;
  if (unref(locale) === 'de') {
    return 'dd.MM.yyyy';
  }
  return 'MM/dd/yyyy';
};
