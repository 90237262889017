<template>
  <HeaderBar :full-width="true" />

  <section class="mt-4 pl-9 pr-9">
    <div class="mb-4 flex items-center justify-start gap-4">
      <Input
        :placeholder="t('filterCarLicencePlate')"
        type="text"
        class="w-80"
        @input="filterByLicensePlate"
      />
      <Select
        v-for="(lineType, i) in lineTypes"
        :key="i"
        :options="lineTypeOptions[i]"
        :value="lineTypeOptions[i][0].value"
        @change="filterByLineType"
      />
      <Select
        v-model="decommissionedFilter"
        :options="decommissionedOptions"
        empty-value-to="null"
      />
      <CVButton @click.prevent="jumpToToday">{{ t('jumpToToday') }}</CVButton>
    </div>
    <GanttChart
      id="bookings-gantt-chart"
      ref="ganttChart"
      class="w-full"
      :categories="categories"
      :line-types="lineTypes"
      :lines="lines"
      :tags="tags"
      :blocks="blocks"
      @on-change-date="(newValue: DateTime) => (currentDate = newValue)"
      @on-calendar-click="onCalendarClick"
    />
    <GanttDropDown :options="carDropDownOptions" />
  </section>
</template>

<script lang="ts" setup>
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import Input from '@/components/Input.vue';
import Select from '@/components/Select.vue';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import { BookingType } from '@/entities/bookings/booking-type.enum';
import { flattenPaginatedData, useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { useBookingsWithQuery } from '@/queries/use-bookings';
import { useCarsWithQuery } from '@/queries/use-cars';
import { useStations } from '@/queries/use-stations';
import { useMe } from '@/queries/use-users';
import router from '@/router';
import { useUiStore } from '@/stores/ui.store';
import { DateTime } from 'luxon';
import { computed, ref, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import GanttChart from './components/GanttChart.vue';
import type {
  Block,
  Category,
  Line,
  LineInternal,
  LineType,
  LineTypeType,
  Tag,
} from './components/interfaces';
import { formatDate } from '@/hooks/use-formatted-date';
import { getDateFormat, useDateTimeFormat } from '@/hooks/use-date-format';
import { FindAllBookingsSort } from '@/entities/bookings/find-all-bookings-sort.enum';
import { Order } from '@/entities/pagination/order.enum';
import { FindAllCarsSort } from '@/entities/cars/find-all-cars-sort.enum';
import type {
  ManyBookingsBooking,
  ManyBookingsRelatedBooking,
} from '@/entities/bookings/many-bookings-booking.entity';
import { DecommissionedFilter } from '@/entities/cars/decommissioned-filter.enum';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import { getStationOfCarByDate } from '@/hooks/use-current-station-of-car';
import type { CarStations } from '@/entities/cars/car.entity';
import { useLocalizedFields } from '@/hooks/use-localized-fields';
import type { GanttDropDownOptions } from './components/GanttDropDown.vue';
import GanttDropDown from './components/GanttDropDown.vue';

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();
const uiStore = useUiStore();
const dateTimeFormat = useDateTimeFormat();

watchEffect(() => {
  uiStore.setHeaderTitle(t('bookingPlan'));
});

const ganttChart = ref();
const currentDate = ref<DateTime>();
const initialDate = ref<DateTime>();
const carDropDownOptions = ref<GanttDropDownOptions | null>(null);

watch(currentDate, (newDate, oldDate) => {
  if (!oldDate) {
    initialDate.value = newDate;
  }
});

const {
  data: bookingsDataBefore,
  fetchNextPage: fetchPreviousBookings,
  hasNextPage: hasEarlierBookings,
  isFetching: isFetchingPreviousBookings,
} = useBookingsWithQuery(
  computed(() => ({
    filter: [
      {
        status: [
          BookingStatus.FLOATING,
          BookingStatus.OPEN,
          BookingStatus.CONFIRMED,
          BookingStatus.HANDED_OVER,
          BookingStatus.CAR_RETURNED,
        ],
        startDateBefore: initialDate.value?.toISO() ?? undefined,
      },
    ],
    sort: FindAllBookingsSort.START_DATE,
    limit: 300,
    order: Order.DESC,
  })),
  computed(() => !!initialDate.value),
);
const {
  data: bookingsDataAfter,
  fetchNextPage: fetchNextBookings,
  hasNextPage: hasLaterBookings,
  isFetching: isFetchingNextBookings,
} = useBookingsWithQuery(
  computed(() => ({
    filter: [
      {
        status: [
          BookingStatus.FLOATING,
          BookingStatus.OPEN,
          BookingStatus.CONFIRMED,
          BookingStatus.HANDED_OVER,
          BookingStatus.CAR_RETURNED,
        ],
        startDateAfter: initialDate.value?.toISO() ?? undefined,
      },
    ],
    sort: FindAllBookingsSort.START_DATE,
    limit: 300,
    order: Order.ASC,
  })),
  computed(() => !!initialDate.value),
);
const bookings = computed(() =>
  [...flattenPaginatedData(bookingsDataBefore), ...flattenPaginatedData(bookingsDataAfter)].sort(
    (a, b) => (DateTime.fromISO(a.startDate) > DateTime.fromISO(b.startDate) ? 1 : -1),
  ),
);

watch(
  [currentDate, isFetchingPreviousBookings, isFetchingNextBookings],
  () => {
    if (!currentDate.value || !bookings.value.length) return;
    const earliestDate = currentDate.value.minus({ days: 100 });
    const latestDate = currentDate.value.plus({ days: 100 });
    if (
      !isFetchingPreviousBookings.value &&
      hasEarlierBookings?.value &&
      DateTime.fromISO(bookings.value[0].startDate) > earliestDate
    ) {
      fetchPreviousBookings();
    }
    if (
      !isFetchingNextBookings.value &&
      hasLaterBookings?.value &&
      DateTime.fromISO(bookings.value[bookings.value.length - 1].startDate) < latestDate
    ) {
      fetchNextBookings();
    }
  },
  { immediate: true },
);

const decommissionedFilter = ref<DecommissionedFilter | null>(DecommissionedFilter.ACTIVE);
const { data: stationsData } = useStations(500);
const stations = useFlattenPaginatedData(stationsData);
const { data: carsData } = useCarsWithQuery(
  computed(() => ({
    limit: 500,
    sort: [FindAllCarsSort.MAKE, FindAllCarsSort.MODEL],
    decommissioned: decommissionedFilter.value ?? undefined,
    isGhostCar: false,
  })),
);

const { data: user } = useMe();

const categories = computed<Category[]>(
  () =>
    stations.value?.map((station) => ({
      id: station.id,
      title: getLocalizedField(station.info),
      hidden: !user.value?.stations.some((userStation) => userStation.id === station.id),
    })) ?? [],
);

const cars = useFlattenPaginatedData(carsData);

const lineTypes = computed<LineType[]>(() => [
  {
    typeName: 'make',
    types: cars.value.reduce((acc, cur) => {
      if (!acc.find((type) => type.tag === cur.vehicleType?.make.id)) {
        acc.push({
          tag: cur.vehicleType?.make.id ?? '',
          title: cur.vehicleType?.make.name ?? '',
        });
      }
      return acc;
    }, [] as LineTypeType[]),
  },
  {
    typeName: 'category',
    types: cars.value.reduce((acc, cur) => {
      if (!acc.find((type) => type.tag === cur.vehicleType?.mainCategory)) {
        acc.push({
          tag: cur.vehicleType?.mainCategory ?? '',
          title: t(`categories.${cur.vehicleType?.mainCategory}`),
        });
      }
      return acc;
    }, [] as LineTypeType[]),
  },
]);

const lineTypeOptions = computed(() =>
  lineTypes.value.map((lineType) =>
    [{ title: t(lineType.typeName), tag: '*' }, ...lineType.types].map((type) => ({
      label: type.title,
      value: `${lineType.typeName};${type.tag}`,
    })),
  ),
);

const decommissionedOptions = computed(() => [
  {
    label: t('showAllCars'),
    value: null,
  },
  {
    label: t('hideDecommissionedCars'),
    value: DecommissionedFilter.ACTIVE,
  },
  {
    label: t('hideActiveCars'),
    value: DecommissionedFilter.DECOMMISSIONED,
  },
]);

const lines = computed<Line[]>(
  () =>
    cars.value.flatMap((car) => {
      return car.stations
        .reduce((prev, curr) => {
          const stationInList = prev.find((station) => station.station.id === curr.station.id);
          const stationInListDate = stationInList?.fromDate ? new Date(stationInList?.fromDate) : 0;
          const currDate = curr?.fromDate ? new Date(curr?.fromDate) : 0;
          if (!stationInList) {
            return [...prev, curr];
          } else if (stationInListDate < currDate) {
            return prev.map((station) => (station.station.id === curr.station.id ? curr : station));
          }
          return prev;
        }, [] as CarStations[])
        .map((carStation) => {
          return {
            id: car.id + '#' + carStation.station.id,
            title: `${car.vehicleType?.make?.name ?? ''} ${
              car.vehicleType?.model ?? ''
            } (${car.licencePlate})`,
            category: carStation.station.id,
            tags: [car.licencePlate, car.vehicleType.make.name + ' ' + car.vehicleType.model],
            lineTypes: [
              {
                typeName: 'make',
                tag: car.vehicleType?.make.id ?? '',
              },
              {
                typeName: 'category',
                tag: car.vehicleType?.mainCategory ?? '',
              },
            ],
            ...(car.notAtTheHub ? { color: '#fff0aa' } : {}),
          };
        });
    }) ?? [],
);

const enum TagSlug {
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  INSURANCE_CASE = 'INSURANCE_CASE',
}

const tags = ref<Tag[]>([
  {
    slug: BookingType.CUSTOMER_BOOKING,
    title: t('booking'),
    color: '#67a0e0',
  },
  {
    slug: BookingStatus.FLOATING,
    title: t('floating'),
    color: '#33cc33',
    alpha: 0.5,
    zIndex: 2,
  },
  {
    slug: BookingType.MAINTENANCE_BOOKING,
    title: t('maintenance'),
    color: '#888',
  },
  {
    slug: BookingType.PARTNER_BOOKING,
    title: t('partnerRent'),
    color: '#f90',
  },
  {
    slug: TagSlug.INSURANCE_CASE,
    title: t('insuranceCase'),
    color: '#ffb38c',
  },
  {
    slug: BookingType.TRANSFER_DELIVERY_BOOKING,
    title: t('transferDelivery'),
    color: '#f9d300',
  },
  {
    slug: BookingType.TRANSFER_RETURN_BOOKING,
    title: t('transferReturn'),
    color: '#f9d300',
  },
  {
    slug: TagSlug.NOT_AVAILABLE,
    title: t('notAvailable'),
    color: '#d5d5d5',
  },
]);

const carAvailabilityBlocks = computed(() =>
  lines.value.reduce((prev, cur) => {
    const car = cars.value.find((car) => car.id === cur.id.split('#')[0]);
    const block = {
      lineId: cur.id,
      tag: TagSlug.NOT_AVAILABLE,
    };
    car?.availableFrom &&
      prev.push({
        ...block,
        startDate: null,
        endDate: DateTime.fromISO(car.availableFrom).startOf('day'),
        title: `${t('availableFrom')} ${formatDate(car.availableFrom, getDateFormat())}`,
      });
    car?.availableUntil &&
      prev.push({
        ...block,
        startDate: DateTime.fromISO(car.availableUntil).endOf('day'),
        endDate: null,
        title: `${t('availableUntil')} ${formatDate(car.availableUntil, getDateFormat())}`,
      });
    return prev;
  }, [] as Block[]),
);

const blocks = computed<Block[]>(() => [
  ...bookings.value.map((booking) => {
    let hover: string[] | undefined = undefined;
    switch (booking.type) {
      case BookingType.CUSTOMER_BOOKING: {
        const transferDelivery = booking.relatedBookings.find(
          (relatedBooking) => relatedBooking.type === BookingType.TRANSFER_DELIVERY_BOOKING,
        );
        const transferReturn = booking.relatedBookings.find(
          (relatedBooking) => relatedBooking.type === BookingType.TRANSFER_RETURN_BOOKING,
        );
        const deliveryLocationInfo = getLocationInfos(transferDelivery);
        const returnLocationInfo = getLocationInfos(transferReturn);

        hover = [
          `${booking.car.vehicleType?.make?.name} ${booking.car.vehicleType?.model}`,
          `${t('startTime')}: ${DateTime.fromISO(booking.startDate).toFormat(
            dateTimeFormat.value,
          )}`,
          `${t('endTime')}: ${DateTime.fromISO(booking.endDate).toFormat(dateTimeFormat.value)}`,
          `${t('customer')}: ${booking.customerData?.firstName ?? 'n/a'} ${
            booking.customerData?.lastName ?? 'n/a'
          }`,
          ...(deliveryLocationInfo ? [`${t('deliveryTransfer')}: ${deliveryLocationInfo}`] : []),
          ...(returnLocationInfo ? [`${t('returnTransfer')}: ${returnLocationInfo}`] : []),
          `${t('bookingNumber')}: ${booking.bookingNumber.toString()}`,
        ];
        break;
      }
      case BookingType.TRANSFER_DELIVERY_BOOKING:
      case BookingType.TRANSFER_RETURN_BOOKING: {
        const locationInfo = getLocationInfos(booking);
        hover = [
          `${booking.car.vehicleType?.make?.name} ${booking.car.vehicleType?.model}`,
          `${t('startTime')}: ${DateTime.fromISO(booking.startDate).toFormat(
            dateTimeFormat.value,
          )}`,
          `${t('endTime')}: ${DateTime.fromISO(booking.endDate).toFormat(dateTimeFormat.value)}`,
          `${t('driver')}: ${booking.customer?.firstName ?? 'n/a'} ${
            booking.customer?.lastName ?? 'n/a'
          }`,
          `${t('location')}: ${locationInfo}`,
        ];
        break;
      }
      case BookingType.PARTNER_BOOKING: {
        hover = [
          `${booking.car.vehicleType?.make?.name} ${booking.car.vehicleType?.model}`,
          ...(booking.internalComment ? [`${t('reservationNr')}: ${booking.internalComment}`] : []),
          `${t('startTime')}: ${DateTime.fromISO(booking.startDate).toFormat(
            dateTimeFormat.value,
          )}`,
          `${t('endTime')}: ${DateTime.fromISO(booking.endDate).toFormat(dateTimeFormat.value)}`,
        ];
        break;
      }
      case BookingType.MAINTENANCE_BOOKING: {
        hover = [
          `${booking.car.vehicleType?.make?.name} ${booking.car.vehicleType?.model}`,
          ...(booking.internalComment ? [booking.internalComment] : []),
          `${t('startTime')}: ${DateTime.fromISO(booking.startDate).toFormat(
            dateTimeFormat.value,
          )}`,
          `${t('endTime')}: ${DateTime.fromISO(booking.endDate).toFormat(dateTimeFormat.value)}`,
        ];
        break;
      }
    }
    const tag =
      booking.status === BookingStatus.FLOATING && booking.type == BookingType.CUSTOMER_BOOKING
        ? booking.status
        : booking.insuranceCase
          ? TagSlug.INSURANCE_CASE
          : booking.type;
    return {
      lineId: booking.car.id + '#' + getStationOfCarByDate(booking.car, booking.startDate).id,
      tag,
      title: getBlockTitle(booking),
      onClick: () => router.push(getPushTarget(booking)),
      startDate: DateTime.fromISO(booking.startDate),
      endDate: DateTime.fromISO(booking.endDate),
      hover,
    };
  }),
  ...carAvailabilityBlocks.value,
]);

const getLocationInfos = (
  booking: ManyBookingsBooking | ManyBookingsRelatedBooking | undefined,
) => {
  const maxInfoLength = 30;
  if (booking?.type === BookingType.TRANSFER_DELIVERY_BOOKING) {
    const deliveryLocationDots =
      (booking.dropoffLocationGeocodedAddress?.displayName.length ?? 0) > maxInfoLength
        ? '...'
        : '';
    const deliveryLocationInfo =
      booking.dropoffLocationType === BookingLocationType.STATION
        ? getLocalizedField(booking.dropoffStation?.info)
        : booking.dropoffLocationGeocodedAddress?.displayName.slice(0, maxInfoLength) +
          deliveryLocationDots;
    return deliveryLocationInfo;
  }
  if (booking?.type === BookingType.TRANSFER_RETURN_BOOKING) {
    const returnLocationDots =
      (booking.pickupLocationGeocodedAddress?.displayName.length ?? 0) > maxInfoLength ? '...' : '';
    const returnLocationInfo =
      booking.pickupLocationType === BookingLocationType.STATION
        ? getLocalizedField(booking.pickupStation?.info)
        : booking.pickupLocationGeocodedAddress?.displayName.slice(0, maxInfoLength) +
          returnLocationDots;
    return returnLocationInfo;
  }
  return null;
};

const getPushTarget = (booking: ManyBookingsBooking) => {
  switch (booking.type) {
    case BookingType.CUSTOMER_BOOKING:
      return { name: 'bookingPlanEdit', params: { id: booking.id } };
    case BookingType.MAINTENANCE_BOOKING:
      return {
        name: 'maintenanceEdit',
        params: { carId: booking.car.id, blockId: booking.id },
      };
    case BookingType.PARTNER_BOOKING:
      return {
        name: 'partnerRentEdit',
        params: { carId: booking.car.id, bookingId: booking.id },
      };
    case BookingType.TRANSFER_DELIVERY_BOOKING:
    case BookingType.TRANSFER_RETURN_BOOKING:
      return {
        name: 'transferEdit',
        params: {
          transferType: booking.type,
          bookingId: booking.relatedBookings?.[0].id,
          transferId: booking.id,
        },
      };
    default:
      return {};
  }
};

const getBlockTitle = (booking: ManyBookingsBooking) => {
  switch (booking.type) {
    case BookingType.CUSTOMER_BOOKING:
    case BookingType.TRANSFER_DELIVERY_BOOKING:
    case BookingType.TRANSFER_RETURN_BOOKING:
      return `${booking.customerData?.firstName ?? 'n/a'} ${
        booking.customerData?.lastName ?? 'n/a'
      }`;
    case BookingType.MAINTENANCE_BOOKING:
      return booking.internalComment ?? t('maintenance');
    case BookingType.PARTNER_BOOKING:
      return t(`rentalPartners.${booking.rentalPartner}`) + ` (${booking.internalComment})`;
    default:
      return booking.bookingNumber.toString();
  }
};

const filterByLineType = (e: any) => {
  const value = e.target.value.split(';');
  ganttChart.value.filterLinesByType(value[0], value[1]);
};

const filterByLicensePlate = (e: any) => {
  ganttChart.value.filterLinesByTags(e.target.value);
};

const jumpToToday = () => {
  ganttChart.value.jumpToToday();
};

const onCalendarClick = (x: number, y: number, line: LineInternal | undefined, date: DateTime) => {
  carDropDownOptions.value = {
    show: !carDropDownOptions.value?.show,
    position: { x: Math.round(x), y: Math.round(y) },
    items: [
      ...(line
        ? [
            {
              label: t('createBlock'),
              onClick: () =>
                router.push({
                  name: 'maintenanceEdit',
                  params: { carId: line.id.split('#')[0], blockId: 'new' },
                  query: { startDate: date.toISO() },
                }),
            },
          ]
        : []),
    ],
  };
  if (!carDropDownOptions.value?.items?.length) carDropDownOptions.value.show = false;
};
</script>

<style scoped>
#bookings-gantt-chart {
  height: calc(100vh - 180px);
}
</style>

<i18n lang="json">
{
  "en": {
    "bookingPlan": "Booking Plan",
    "filterCarLicencePlate": "Filter car/license plate",
    "booking": "Booking",
    "maintenance": "Maintenance/Cleaning",
    "partnerRent": "Partner Rent",
    "make": "Make (All)",
    "category": "Category (All)",
    "jumpToToday": "Jump to today",
    "availableFrom": "Available from",
    "availableUntil": "Available until",
    "notAvailable": "Not available",
    "showAllCars": "Show all cars",
    "hideDecommissionedCars": "Hide decommissioned cars",
    "hideActiveCars": "Hide active cars",
    "startTime": "Start time",
    "endTime": "End time",
    "bookingNumber": "Bnr.",
    "deliveryTransfer": "Delivery Transfer",
    "returnTransfer": "Return Transfer",
    "location": "Location",
    "driver": "Driver",
    "customer": "Customer",
    "reservationNr": "Reservation nr.",
    "createBlock": "+ Create blocking",
    "insuranceCase": "Insurance case"
  },
  "de": {
    "bookingPlan": "Belegungsplan",
    "filterCarLicencePlate": "Filter nach Auto/Kennzeichen",
    "booking": "Buchung",
    "maintenance": "Wartung/Reinigung",
    "partnerRent": "Partnermiete",
    "make": "Marke (Alle)",
    "category": "Kategorie (Alle)",
    "jumpToToday": "Zu Heute springen",
    "availableFrom": "Verfügbar ab",
    "availableUntil": "Verfügbar bis",
    "notAvailable": "Nicht verfügbar",
    "showAllCars": "Alle Autos anzeigen",
    "hideDecommissionedCars": "Ausrangierte Autos ausblenden",
    "hideActiveCars": "Aktive Autos ausblenden",
    "startTime": "Startzeit",
    "endTime": "Endzeit",
    "bookingNumber": "Bnr.",
    "deliveryTransfer": "Anlieferung",
    "returnTransfer": "Rückführung",
    "location": "Ort",
    "driver": "Fahrer",
    "customer": "Kunde",
    "reservationNr": "Reservierungsnr.",
    "createBlock": "+ Blockierung erstellen",
    "insuranceCase": "Versicherungsfall"
  }
}
</i18n>
