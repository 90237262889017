<template>
  <router-link :to="{ name: 'bookingEdit', params: { id: booking.id } }">
    <div class="relative w-full border border-primary transition-shadow hover:shadow-md">
      <div class="flex items-center gap-2 py-2 pl-3 pr-2 text-sm">
        <div class="basis-[28%]">
          <p class="mb-1 font-medium text-primary">{{ t('vehicle') }}</p>
          <p>{{ car?.vehicleType?.make.name }} {{ car?.vehicleType?.model }}</p>
        </div>

        <div class="basis-[22%]">
          <p class="mb-1 font-medium text-primary">{{ t('customer') }}</p>
          <p>
            {{ booking.customerData?.firstName ?? 'n/a' }}
            {{ booking.customerData?.lastName ?? 'n/a' }}
          </p>
        </div>

        <div class="basis-[30%]">
          <div class="mb-1">
            <p class="mr-2 inline font-medium text-primary">
              {{ t('start') }}
            </p>
            <span>{{ startDate }} Uhr</span>
          </div>

          <div>
            <p class="mr-2 inline font-medium text-primary">
              {{ t('end') }}
            </p>
            <span>{{ endDate }} Uhr</span>
          </div>
        </div>

        <div class="basis-[15%]">
          <div class="mb-1">
            <p class="mr-2 inline font-medium text-primary">
              {{ t('bookingNumber') }}
            </p>
            <span>{{ booking.bookingNumber }}</span>
          </div>

          <div>
            <p class="mr-2 inline font-medium text-primary">
              {{ t('licencePlate') }}
            </p>
            <span>{{ car?.licencePlate }}</span>
          </div>
        </div>

        <DropdownDotted :buttons="dropdownItems" :is-loading="isRefreshingBookingConfirmation" />
      </div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import type { DropdownItem } from '@/components/DropdownDotted.vue';
import DropdownDotted from '@/components/DropdownDotted.vue';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import type { ManyBookingsBooking } from '@/entities/bookings/many-bookings-booking.entity';
import { useFormattedDate } from '@/hooks/use-formatted-date';
import { useResendBookingMails } from '@/hooks/use-resend-booking-mails';
import { useI18n } from 'vue-i18n';
import { useRefreshBookingConfirmation } from '@/queries/use-bookings';
import { computed, toRefs } from 'vue';

const props = defineProps<{
  booking: ManyBookingsBooking;
}>();
const { t } = useI18n();
const { booking } = toRefs(props);
const dateFormat = 'dd.MM.yyyy - HH:mm';

const startDate = useFormattedDate(booking.value.startDate, dateFormat);
const endDate = useFormattedDate(booking.value.endDate, dateFormat);

const car = computed(() => booking.value.car);

const { resendConfirmationEmail, resendContractEmail } = useResendBookingMails(props.booking.id);
const { mutateAsync: refreshBookingConfirmation, isPending: isRefreshingBookingConfirmation } =
  useRefreshBookingConfirmation();

const downloadContract = () => {
  if (!props.booking.contractFileUrl) return;
  window.location.href = props.booking.contractFileUrl;
};

const downloadBookingConfirmation = async () => {
  if (!props.booking.confirmationFileUrl) return;
  const { fileUrl } = await refreshBookingConfirmation(props.booking.id);
  window.location.href = fileUrl;
};

const dropdownItems = computed<DropdownItem[]>(() => {
  const menuItems: DropdownItem[] = [];
  if (
    props.booking.status === BookingStatus.CONFIRMED ||
    props.booking.status === BookingStatus.CAR_RETURNED ||
    props.booking.status === BookingStatus.HANDED_OVER
  ) {
    menuItems.push({
      title: t('resendConfirmation'),
      onClick: resendConfirmationEmail,
    });
  }
  if (
    (props.booking.status === BookingStatus.CONFIRMED ||
      props.booking.status === BookingStatus.HANDED_OVER ||
      props.booking.status === BookingStatus.CAR_RETURNED) &&
    props.booking.confirmationFileUrl
  ) {
    menuItems.push({
      title: t('downloadConfirmation'),
      onClick: downloadBookingConfirmation,
    });
  }
  if (
    props.booking.status === BookingStatus.CAR_RETURNED ||
    props.booking.status === BookingStatus.HANDED_OVER
  ) {
    menuItems.push({
      title: t('resendContract'),
      onClick: () => resendContractEmail(),
    });
  }
  if (
    (props.booking.status === BookingStatus.CAR_RETURNED ||
      props.booking.status === BookingStatus.HANDED_OVER) &&
    props.booking.contractFileUrl
  ) {
    menuItems.push({
      title: t('downloadContract'),
      onClick: downloadContract,
    });
  }
  return menuItems;
});
</script>

<i18n lang="json">
{
  "en": {
    "vehicle": "Vehicle",
    "customer": "Customer",
    "start": "Start:",
    "end": "End:",
    "bookingNumber": "Bnr.",
    "licencePlate": "Lp.",
    "resendConfirmation": "Resend Confirmation",
    "resendContract": "Resend Contract",
    "downloadContract": "Download Contract",
    "downloadConfirmation": "Download Confirmation"
  },
  "de": {
    "vehicle": "Fahrzeug",
    "customer": "Kunde",
    "start": "Start:",
    "end": "Ende:",
    "bookingNumber": "Bnr.",
    "licencePlate": "Knz.",
    "resendConfirmation": "Bestätigung erneut senden",
    "resendContract": "Vertrag erneut senden",
    "downloadContract": "Vertrag runterladen",
    "downloadConfirmation": "Bestätigung runterladen"
  }
}
</i18n>
