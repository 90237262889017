import type { FindAllCarsQueryDto } from '@/entities/cars/car.entity';
import { computed, type Ref } from 'vue';
import { VehiclePoolTab as Tab } from './vehicle-pool-tab.enum';
import { CarStatus } from '@/entities/cars/car-status.enum';
import { DateTime } from 'luxon';
import { CarTyres } from '@/entities/cars/car-tyres.enum';
import { getTuvDueDate } from '@/hooks/use-tuv-due-date';
import { getCurrentCarTyresSeason } from '@/utils/get-current-car-tyres-season';
import { FindAllCarsSort } from '@/entities/cars/find-all-cars-sort.enum';
import { Order } from '@/entities/pagination/order.enum';

export const useCarsQuery = (
  activeTab: Ref<Tab>,
  licencePlateFinQuery: Ref<string | null | undefined>,
  stationId: Ref<string | null>,
) => {
  return computed<FindAllCarsQueryDto>(() => {
    const query: FindAllCarsQueryDto = {};
    if (licencePlateFinQuery.value) {
      query.licencePlateFinQuery = licencePlateFinQuery.value ?? undefined;
    } else {
      const currentDateTime = DateTime.now().startOf('day');
      const currentTyresSeason = getCurrentCarTyresSeason();
      switch (activeTab.value) {
        case Tab.ORDERED:
          query.isGhostCar = false;
          query.status = [CarStatus.ORDERED];
          query.sort = FindAllCarsSort.AVAILABLE_FROM;
          query.order = Order.ASC;
          break;
        case Tab.FLOAT_IN:
          query.isGhostCar = false;
          query.status = [CarStatus.FLOAT_IN];
          query.sort = [FindAllCarsSort.MAKE, FindAllCarsSort.MODEL];
          query.order = Order.ASC;
          break;
        case Tab.FLOAT_OUT:
          query.isGhostCar = false;
          query.status = [CarStatus.FLOAT_OUT];
          query.sort = [FindAllCarsSort.MAKE, FindAllCarsSort.MODEL];
          query.order = Order.ASC;
          break;
        case Tab.GHOST_CARS:
          query.isGhostCar = true;
          query.status = [CarStatus.FLOAT_IN];
          query.sort = [FindAllCarsSort.MAKE, FindAllCarsSort.MODEL];
          query.order = Order.ASC;
          break;
        case Tab.MERCHANT_CONSULTATION:
          query.isGhostCar = false;
          query.status = [CarStatus.ORDERED];
          query.isSetmerchantConsultedDate = false;
          query.availableFromDateBefore = currentDateTime.plus({ weeks: 6 }).toISO();
          query.sort = FindAllCarsSort.AVAILABLE_FROM;
          query.order = Order.ASC;
          break;
        case Tab.CAR_INCOMING:
          query.isGhostCar = false;
          query.status = [CarStatus.ORDERED];
          query.isSetregistrationAndOrganizationDoneDate = false;
          query.availableFromDateBefore = currentDateTime.plus({ days: 10 }).toISO();
          query.sort = FindAllCarsSort.AVAILABLE_FROM;
          query.order = Order.ASC;
          break;
        case Tab.INSPECTION:
          query.isGhostCar = false;
          query.status = [CarStatus.FLOAT_IN];
          query.needInspection = true;
          query.sort = [FindAllCarsSort.NEXT_INSPECTION_DUE_IN_KM];
          query.order = Order.ASC;
          break;
        case Tab.TUV:
          query.isGhostCar = false;
          query.status = [CarStatus.FLOAT_IN];
          query.lastTuvDateBefore = getTuvDueDate(currentDateTime.toJSDate()).toISOString();
          query.sort = [FindAllCarsSort.LAST_TUV_DATE];
          query.order = Order.ASC;
          break;
        case Tab.TYRES:
          query.isGhostCar = false;
          query.status = [CarStatus.FLOAT_IN];
          query.tyres = currentTyresSeason === CarTyres.SUMMER ? CarTyres.WINTER : CarTyres.SUMMER;
          query.isAlwaysOnWinterTyres = currentTyresSeason === CarTyres.SUMMER ? false : undefined;
          query.sort = [FindAllCarsSort.MAKE, FindAllCarsSort.MODEL];
          query.order = Order.ASC;
          break;
        case Tab.SELL:
          query.isGhostCar = false;
          query.status = [CarStatus.FLOAT_IN];
          query.availableFromDateBefore = currentDateTime.minus({ months: 23 }).toISO();
          query.sort = FindAllCarsSort.AVAILABLE_FROM;
          query.order = Order.ASC;
          break;
      }
    }
    return {
      ...query,
      stationIds: stationId.value ? [stationId.value] : undefined,
      limit: 20,
    };
  });
};
