import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  fill: "currentColor",
  viewBox: "0 0 57 57"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M51.981 24.481q-11.576-.005-23.152 0c.001 3.2-.004 6.401.002 9.6h13.407c-.518 3.068-2.34 5.873-4.926 7.6-1.625 1.093-3.492 1.802-5.416 2.139-1.938.33-3.94.373-5.872-.018a14.45 14.45 0 0 1-5.477-2.365 15.3 15.3 0 0 1-5.639-7.555c-1.048-3.079-1.056-6.505.005-9.58a15.3 15.3 0 0 1 3.57-5.8c1.986-2.033 4.567-3.486 7.348-4.082a14.6 14.6 0 0 1 7.223.294 13.3 13.3 0 0 1 5.305 3.116c1.512-1.503 3.017-3.016 4.527-4.523.792-.81 1.624-1.586 2.39-2.42-2.292-2.121-4.98-3.827-7.917-4.905-5.287-1.946-11.25-1.987-16.572-.145C14.79 7.891 9.682 12.377 6.85 18.046a24.5 24.5 0 0 0-2.138 6.184c-1.088 5.343-.33 11.04 2.135 15.908a24.8 24.8 0 0 0 6.684 8.215 24.5 24.5 0 0 0 8.94 4.508c4.098 1.099 8.46 1.074 12.586.135 3.728-.858 7.256-2.64 10.073-5.24 2.977-2.736 5.1-6.34 6.224-10.214 1.227-4.225 1.396-8.736.627-13.06z" }, null, -1)
  ])))
}
export default { render: render }