<template>
  <UserSettingsForm
    v-if="user && stations"
    :user="user"
    :stations="stations"
    :is-updating="isUpdating"
    @on-submit="onSubmit"
  />
  <div v-else class="flex w-full justify-center"><Spinner /></div>
</template>

<script setup lang="ts">
import { useMe, useUpdateUser } from '@/queries/use-users';
import UserSettingsForm from './UserSettingsForm.vue';
import { useStations } from '@/queries/use-stations';
import type { UpdateUserDto } from '@/entities/auth/user.entity';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';

const { data: user } = useMe();
const { data: stationsData } = useStations(500);
const stations = useFlattenPaginatedData(stationsData);

const { mutateAsync: updateUser, isPending: isUpdating } = useUpdateUser();

const onSubmit = (updateUserDto: UpdateUserDto) => {
  if (!user.value) return;
  updateUser({
    id: user.value.id,
    user: updateUserDto,
  });
};
</script>
