<template>
  <HeaderBar />
  <section class="carvia-container flex flex-col justify-center">
    <div class="flex-auto">
      <div class="mb-7">
        <div class="col-span-5 flex flex-row items-center space-x-4 space-y-0">
          <h2 class="w-full text-2xl text-primary">
            {{ t('upcomingHandovers') }}
          </h2>
          <StationSwitcher
            class="max-w-[200px] basis-2/5 text-primary"
            use-station-store
            has-null-option
            :null-option-title="t('myStations')"
          />
        </div>

        <div class="flex flex-wrap-reverse items-end lg:flex-nowrap lg:justify-between">
          <Tabs
            v-model="activeTab"
            :tabs="tabs"
            :default="Tab.ALL"
            class="w-full md:col-span-3 lg:w-auto"
          />

          <div class="lg-mt-0 mt-3 flex w-full items-end justify-end lg:w-auto">
            <Datepicker
              v-model="filterDate"
              :format="dateFormat"
              week-numbers
              :month-change-on-scroll="false"
              :clearable="false"
              input-class-name="!text-primary"
              class="w-full max-w-[150px]"
              data-testid="handover-datepicker"
            />
            <CVButton class="ml-4" @click="switchTomorrowToday()">
              {{ isToday ? t('tomorrow') : t('today') }}
            </CVButton>
          </div>
        </div>
      </div>

      <BookingList
        v-if="activeTab !== Tab.DETAILING"
        :filter-booking-status="filterBookingStatus"
        :filter-date="filterDate"
      />

      <DetailingList v-else />
    </div>
  </section>
</template>

<script lang="ts" setup>
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import { useI18n } from 'vue-i18n';
import { useUiStore } from '@/stores/ui.store';
import BookingList from '@/views/handover/components/booking-list/BookingList.vue';
import { DateTime } from 'luxon';
import { computed, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import StationSwitcher from '../settings/components/StationSwitcher.vue';
import DetailingList from '@/views/handover/components/detailing-list/DetailingList.vue';
import Tabs, { type TabItem } from '@/components/Tabs.vue';
import { useDateFormat } from '@/hooks/use-date-format';
import { useRouteQueryStore } from '@/stores/route-query.store';

enum Tab {
  PICKUPS = 'PICKUPS',
  RETURNS = 'RETURNS',
  ALL = 'ALL',
  DETAILING = 'DETAILING',
}

const { t } = useI18n();
const route = useRoute();
const uiStore = useUiStore();
const routeQueryStore = useRouteQueryStore();

watchEffect(() => {
  uiStore.setHeaderTitle(t('handovers'));
});

const isValidDateQueryParam = DateTime.fromISO(route.query.date as string).isValid;
const presetDate = isValidDateQueryParam ? new Date(route.query.date as string) : new Date();

const dateFormat = useDateFormat();
const filterDate = ref(presetDate);

const mapTabToStatus = (tab?: Tab | null) => {
  switch (tab) {
    case Tab.PICKUPS:
      return [BookingStatus.CONFIRMED];
    case Tab.RETURNS:
      return [BookingStatus.HANDED_OVER];
    default:
      return [BookingStatus.CONFIRMED, BookingStatus.HANDED_OVER];
  }
};

const activeTab = ref<Tab>(Tab.ALL);
const filterBookingStatus = ref(mapTabToStatus(activeTab.value));

const switchTomorrowToday = () => {
  if (isToday.value) {
    const tomorrow = DateTime.now().plus({ days: 1 }).toJSDate();
    filterDate.value = tomorrow;
  } else {
    filterDate.value = new Date();
  }
};

const isToday = computed(() => {
  const today = DateTime.now().toJSDate();
  return filterDate.value.toDateString() === today.toDateString();
});

watchEffect(() => {
  if (activeTab.value === Tab.DETAILING) return;
  filterBookingStatus.value = mapTabToStatus(activeTab.value);
});

watchEffect(() => {
  routeQueryStore.push({
    date: filterDate.value.toISOString(),
  });
});

const tabs = computed<TabItem[]>(() => [
  {
    title: t('all'),
    id: Tab.ALL,
  },
  {
    title: t('pickups'),
    id: Tab.PICKUPS,
  },
  {
    title: t('returns'),
    id: Tab.RETURNS,
  },
  {
    title: t('detailing'),
    id: Tab.DETAILING,
  },
]);
</script>

<i18n lang="json">
{
  "en": {
    "handovers": "Handovers",
    "upcomingHandovers": "Upcoming handovers",
    "all": "All",
    "pickups": "Pickups",
    "returns": "Returns",
    "detailing": "Detailing",
    "tomorrow": "Tomorrow",
    "today": "Today",
    "myStations": "My Stations"
  },

  "de": {
    "handovers": "Übergaben / Rückgaben",
    "upcomingHandovers": "Anstehende Übergaben & Rückgaben",
    "all": "Alles",
    "pickups": "Übergaben",
    "returns": "Rückgaben",
    "detailing": "Aufbereiten",
    "tomorrow": "Morgen",
    "today": "Heute",
    "myStations": "Meine Stationen"
  }
}
</i18n>
