import type {
  CreateStationDto,
  Station,
  UpdateStationDto,
} from '@/entities/station/station.entity';
import { Service } from './http-service';

export const stationsService = new Service<Station, CreateStationDto, UpdateStationDto>(
  '/stations',
);
