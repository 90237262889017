<template>
  <HeaderBar />

  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
  </ButtonBackContainer>

  <section class="carvia-container">
    <div
      v-if="authStore.user?.role === UserRole.ADMIN"
      class="mb-4 flex justify-start border-b border-primary/50 pb-4"
    >
      <CVButton @click.prevent="router.push({ name: 'editNews', params: { id: 'new' } })">{{
        t('writeNews')
      }}</CVButton>
    </div>

    <div v-if="!isLoading" class="space-y-4">
      <NewsListItem v-for="article in news" :key="article.id" :news="article" />
    </div>

    <EntityListExceptions
      :status="status"
      :error="error"
      :items-count="news.length"
      :no-items-text="t('noNewsFound')"
    />

    <div v-if="hasNextPage" class="mt-4 flex justify-center border-t border-primary/30 pt-6">
      <CVButton outline :is-loading="isFetchingNextPage" @click="fetchNextPage">
        {{ t('loadMore') }}
      </CVButton>
    </div>
  </section>
</template>

<script lang="ts" setup>
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import { useI18n } from 'vue-i18n';
import { useUiStore } from '@/stores/ui.store';
import { computed, watchEffect } from 'vue';
import { useAllNewsWithQuery } from '@/queries/use-news';
import { useAuthStore } from '@/stores/auth.store';
import { UserRole } from '@/entities/auth/user-role.enum';
import { NewsStatus } from '@/entities/news/news-status.enum';
import { Order } from '@/entities/pagination/order.enum';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import EntityListExceptions from '@/components/EntityListExceptions.vue';
import NewsListItem from './NewsListItem.vue';
import { useRouter } from 'vue-router';
import { FindAllNewsSort } from '@/entities/news/find-all-news-sort.enum';

const { t } = useI18n();
const uiStore = useUiStore();
const authStore = useAuthStore();
const router = useRouter();

const {
  data: newsData,
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  error,
  status,
} = useAllNewsWithQuery(
  computed(() =>
    authStore.user?.role === UserRole.ADMIN
      ? { sort: FindAllNewsSort.CREATED_DATE, order: Order.DESC }
      : {
          status: NewsStatus.PUBLISHED,
          sort: FindAllNewsSort.PUBLISHED_DATE,
          order: Order.DESC,
        },
  ),
);

const news = useFlattenPaginatedData(newsData);

watchEffect(() => {
  uiStore.setHeaderTitle(t('allNews'));
});
</script>

<i18n lang="json">
{
  "en": {
    "allNews": "All News",
    "noNewsFound": "No news found",
    "writeNews": "+ Write news"
  },
  "de": {
    "allNews": "Alle Neuigkeiten",
    "noNewsFound": "Keine Neuigkeiten gefunden",
    "writeNews": "+ Neuigkeit schreiben"
  }
}
</i18n>
