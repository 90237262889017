import type { PaginatedResponse } from '@/entities/pagination/paginated-response';
import type {
  CreatePaymentDto,
  FindAllPaymentsQueryDto,
  Payment,
  UpdatePaymentDto,
} from '@/entities/payments/payment.entity';
import { Service } from './http-service';

class PaymentsService extends Service<Payment, CreatePaymentDto, UpdatePaymentDto> {
  async getAllWithQuery(params: FindAllPaymentsQueryDto) {
    const res = await this.httpClient.get<PaginatedResponse<Payment>>(`${this.endpoint}`, {
      params,
    });
    return res.data;
  }
}

export const paymentsService = new PaymentsService('/payments');
