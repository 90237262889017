<template>
  <div class="grow">
    <div v-if="booking">
      <h2>
        {{ t('partnerRentOverview') }}
      </h2>

      <PartnerRentInfo :booking="booking" />

      <Divider />

      <VehicleAndTimes :booking="booking" hide-locations />

      <Divider />

      <div class="flex justify-end">
        <ButtonNext
          v-if="booking"
          :is-loading="isPending"
          variant="success"
          @click.prevent="handleNext"
          >{{ nextButtonText }}</ButtonNext
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import VehicleAndTimes from '@/components/booking-forms/VehicleAndTimes.vue';
import ButtonNext from '@/components/buttons/ButtonNext.vue';
import Divider from '@/components/Divider.vue';
import { useI18n } from 'vue-i18n';
import { useBookingOfType } from '@/queries/use-bookings';
import { computed, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import PartnerRentInfo from './PartnerRentInfo.vue';
import { useUiStore } from '@/stores/ui.store';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import { useUpdateInternalBooking } from '@/queries/use-internal-bookings';
import { BookingType } from '@/entities/bookings/booking-type.enum';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const uiStore = useUiStore();

const { data: booking } = useBookingOfType(
  route.params.id as string,
  true,
  BookingType.PARTNER_BOOKING,
  'upcomingHandovers',
);
const { mutateAsync: updateBooking, isPending } = useUpdateInternalBooking();

watchEffect(() => {
  if (booking.value) {
    const { rentalPartner, status, car } = booking.value;
    uiStore.setHeaderTitle(
      status === BookingStatus.CONFIRMED ? t('partnerRentHandover') : t('partnerRentReturn'),
      t(`rentalPartners.${rentalPartner}`),
      `${car.vehicleType?.make.name} ${car.vehicleType?.model}`,
    );
  } else {
    uiStore.setHeaderTitle(t('partnerRent'));
  }
});

const nextButtonText = computed(() =>
  booking.value?.status === BookingStatus.CONFIRMED ? t('confirmHandover') : t('confirmReturn'),
);

const handleNext = async () => {
  if (!booking.value) return;
  const isConfirmed = booking.value.status === BookingStatus.CONFIRMED;
  await updateBooking({
    id: booking.value.id,
    internalBooking: {
      status: isConfirmed ? BookingStatus.HANDED_OVER : BookingStatus.CAR_RETURNED,
    },
  });
  router.push({
    name: 'partnerRentHandoverDone',
    params: { handedOverOrReturned: isConfirmed ? 'handed-over' : 'returned' },
  });
};
</script>

<i18n lang="json">
{
  "en": {
    "partnerRentHandover": "Partner Rent Handover",
    "partnerRentReturn": "Partner Rent Return",
    "partnerRentOverview": "Partner Rent Overview",
    "confirmHandover": "Confirm car has been handed over",
    "confirmReturn": "Confirm car has been returned"
  },
  "de": {
    "partnerRentHandover": "Partnermiete Übergabe",
    "partnerRentReturn": "Partnermiete Rückgabe",
    "partnerRentOverview": "Partnermiete Übersicht",
    "confirmHandover": "Erfolgte Übergabe bestätigen",
    "confirmReturn": "Erfolgte Rückgabe bestätigen"
  }
}
</i18n>
