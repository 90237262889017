<template>
  <h2>{{ t('summary') }}</h2>
  <div v-if="isLoading" class="flex h-[172px] items-center justify-center">
    <Spinner></Spinner>
  </div>
  <div v-if="!isLoading" class="mt-4 grid grid-cols-3 gap-4">
    <LabeledText :label="t('milesDriven')">
      {{ enabled ? booking.totalMilage + ' km' : '-' }}
    </LabeledText>

    <LabeledText :label="t('milesIncluded')">
      {{ totalInclusiveKmString }}
    </LabeledText>

    <LabeledText
      :label="`${t('extraKm')} (${formatCurrency(booking.priceCalculation.pricePerExtraKm ?? 0)})`"
    >
      {{ extraKmString }}
      -
      <span v-currency="additionalCosts?.costsOfExtraKm ?? 0" />
    </LabeledText>

    <LabeledText :label="`${t('fuelLevelStart')}`">
      {{ fuelStart }}
    </LabeledText>

    <LabeledText :label="t('fuelLevelEnd')">
      {{ fuelEnd }}
    </LabeledText>

    <LabeledText :label="`${t('fuelCosts')}`">
      {{ missingFuel }} - <span v-currency="costsOfRefueling" />
    </LabeledText>

    <LabeledText :label="t('totalExtraCosts')" class="col-start-3 justify-self-end">
      <p v-currency="totalExtraCosts" class="text-lg font-medium lg:text-xl" />
    </LabeledText>
  </div>
</template>

<script setup lang="ts">
import LabeledText from '@/components/LabeledText.vue';
import type { Booking } from '@/entities/bookings/booking.entity';
import { FuelType } from '@/entities/cars/fuel-type.enum';
import { useTrueCarAttributes } from '@/hooks/use-true-car-attributes';
import { useI18n } from 'vue-i18n';
import { useRequestAdditionalCosts } from '@/queries/use-pricing';
import { formatCurrency, formatFloat } from '@/utils/format-numbers';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const { t } = useI18n();
const route = useRoute();
const props = defineProps<{
  booking: Booking;
}>();

const enabled = computed(
  () =>
    typeof props.booking.milageEnd === 'number' && typeof props.booking.fuelLevelEnd === 'number',
);
const { data: additionalCosts, isLoading } = useRequestAdditionalCosts(
  {
    bookingId: route.params.id as string,
  },
  enabled,
);

const { fuelType, batteryCapacityInKwh } = useTrueCarAttributes(computed(() => props.booking.car));

const costsOfRefueling = computed(() => {
  const costOfRecharging = additionalCosts.value?.costsOfRecharging;
  const costOfRefueling = additionalCosts.value?.costsOfRefueling;
  return (fuelType.value === FuelType.ELECTRIC ? costOfRecharging : costOfRefueling) ?? 0;
});

const missingFuel = computed(() =>
  fuelType.value === FuelType.ELECTRIC
    ? `${formatFloat(additionalCosts.value?.missingBattery)} kWh`
    : `${formatFloat(additionalCosts.value?.missingFuel)} l`,
);

const totalExtraCosts = computed(() => {
  return costsOfRefueling.value && costsOfRefueling.value < 0
    ? additionalCosts.value?.costsOfExtraKm
    : (additionalCosts.value?.costsOfExtraKm ?? 0) + (costsOfRefueling.value ?? 0);
});

const totalInclusiveKmString = computed(() => {
  return enabled.value
    ? `${additionalCosts.value?.includedKms ?? 0} km + ${
        additionalCosts.value?.additionalBookedKmExtraValue ?? 0
      } ${t('additionalBookedKm')}`
    : '-';
});
const extraKmString = computed(() => {
  return enabled.value ? (additionalCosts.value?.extraKm ?? 0) + ' km' : 'n.a.';
});

const fuelLevelStart = computed(() => props.booking.fuelLevelStart ?? 0);
const fuelLevelEnd = computed(() => props.booking.fuelLevelEnd ?? 0);

const fuelStart = computed(() => {
  if (!props.booking.fuelLevelStart) return '-';
  return fuelType.value === FuelType.ELECTRIC
    ? `${Math.round(fuelLevelStart.value * 100)}% (${Math.round(
        batteryCapacityInKwh.value * fuelLevelStart.value,
      )} Kwh)`
    : `${fuelLevelStart.value * 8} / 8`;
});

const fuelEnd = computed(() => {
  if (!props.booking.fuelLevelEnd) return '-';
  return fuelType.value === FuelType.ELECTRIC && props.booking.fuelLevelEnd
    ? `${Math.round(props.booking.fuelLevelEnd * 100)}% (${Math.round(
        batteryCapacityInKwh.value * fuelLevelEnd.value,
      )} Kwh)`
    : `${fuelLevelEnd.value * 8} / 8`;
});
</script>

<i18n lang="json">
{
  "en": {
    "milesDriven": "Miles Driven",
    "enterMilage": "Please enter the meter milage",
    "summary": "Summary",
    "milesIncluded": "Miles Included",
    "extraKm": "Kilometers Extra",
    "fuelLevelStart": "Fuel Level Start",
    "fuelLevelEnd": "Fuel Level End",
    "fuelCosts": "Fuel Costs",
    "totalExtraCosts": "Total Extra Costs",
    "additionalBookedKm": "Extra Kilometers"
  },
  "de": {
    "milesDriven": "Gefahrene Kilometer",
    "enterMilage": "Bitte geben Sie den Kilometerstand an",
    "summary": "Zusammenfassung",
    "milesIncluded": "Inklusive Kilometer",
    "extraKm": "Zusatzkilometer",
    "fuelLevelStart": "Tank- / Ladestand Start",
    "fuelLevelEnd": "Tank- / Ladestand Ende",
    "fuelCosts": "Tank- / Ladekosten",
    "totalExtraCosts": "Zusatzkosten",
    "additionalBookedKm": "Extrakilometer"
  }
}
</i18n>
