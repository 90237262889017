<template>
  <GlobalSettingsForm
    v-if="globalSettings && surveys"
    :global-settings="globalSettings"
    :surveys="surveys"
    :is-updating="isUpdating"
    @on-submit="onSubmit"
  />
  <div v-else class="flex w-full justify-center"><Spinner /></div>
</template>

<script setup lang="ts">
import { useGlobalSettings, useUpdateGlobalSettings } from '@/queries/use-global-settings';
import type { UpdateGlobalSettingsDto } from '@/entities/global-settings.entity';
import GlobalSettingsForm from './GlobalSettingsForm.vue';
import { useSurveys } from '@/queries/use-surveys';

const { data: surveys } = useSurveys();
const { data: globalSettings } = useGlobalSettings();
const { mutateAsync: updateGlobalSettings, isPending: isUpdating } = useUpdateGlobalSettings();

const onSubmit = (updateGlobalSettingsDto: UpdateGlobalSettingsDto) => {
  updateGlobalSettings(updateGlobalSettingsDto);
};
</script>
