<!-- eslint-disable vue/no-v-html -->
<template>
  <form @submit.prevent="onSubmit">
    <div class="mb-6 grid w-full grid-cols-6 space-x-6">
      <VehicleStatus
        v-model:milage="milage"
        v-model:fuel-level="fuelLevel"
        class="col-span-4"
        :fuel-type="fueltype"
        :show-milage-not-updated="!car.readyToHandover && !milageChanged"
        :show-fuel-level-not-updated="!car.readyToHandover && !fuelLevelChanged"
        :show-milage-warning="
          !!formValues.milage && milageChanged && formValues.milage < car.milage
        "
        @on-fuel-level-touched="fuelLevelChanged = true"
      />

      <LabeledText v-if="car.milage" class="col-span-2" :label="t('carMilage')">
        <p class="flex h-8 items-center">
          {{ `${car.milage} km` }}
        </p>
      </LabeledText>
    </div>
    <Checkbox v-model="carWashedChecked" :label="t('carWashed')" />
    <Checkbox v-model="carVacuumedChecked" :label="t('carVacuumed')" />
    <Checkbox v-model="fuelLevelAndMilageUpdatedChecked" :label="t('fuelLevelAndMilageUpdated')" />
    <div class="mt-5 flex justify-end">
      <CVButton :disabled="!readyToHandover" :is-loading="isSaving" type="submit">
        {{ t('saveStatus') }}
      </CVButton>
    </div>
  </form>

  <Divider class="col-span-3" />

  <Damages :car-id="car.id" />
</template>

<script lang="ts" setup>
import Damages from '@/components/booking-forms/damages/Damages.vue';
import VehicleStatus from '@/components/booking-forms/VehicleStatus.vue';
import Checkbox from '@/components/Checkbox.vue';
import Divider from '@/components/Divider.vue';
import type { Car, CarStatusFormValues } from '@/entities/cars/car.entity';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { computed, reactive, ref, watchEffect } from 'vue';
import LabeledText from '@/components/LabeledText.vue';

const { t } = useI18n();
const props = defineProps<{
  car: Car;
  isSaving?: boolean;
  autoEnterMilage?: boolean;
}>();
const emit = defineEmits<{
  (e: 'onSubmit', value: CarStatusFormValues): void;
}>();

const initialValues = reactive({
  milage: props.autoEnterMilage ? props.car.milage : null,
  fuelLevel: props.car.fuelLevel,
});

const {
  handleSubmit,
  values: formValues,
  setValues,
} = useForm({
  initialValues,
});

const milage = ref(formValues.milage);
const fuelLevel = ref(formValues.fuelLevel);

watchEffect(() => {
  setValues({
    milage: milage.value,
    fuelLevel: fuelLevel.value,
  });
});

const onSubmit = handleSubmit((values) => {
  emit('onSubmit', { ...values, milage: values.milage ?? props.car.milage });
});

const fueltype = computed(() => props.car.fuelType ?? props.car.vehicleType.fuelType);
const fuelLevelChanged = ref(false);
const milageChanged = computed(() => {
  return formValues.milage !== null;
});
const carWashedChecked = ref(props.car.readyToHandover);
const carVacuumedChecked = ref(props.car.readyToHandover);
const fuelLevelAndMilageUpdatedChecked = ref(props.car.readyToHandover);

const readyToHandover = computed(
  () =>
    carWashedChecked.value && carVacuumedChecked.value && fuelLevelAndMilageUpdatedChecked.value,
);
</script>

<i18n lang="json">
{
  "en": {
    "saveStatus": "Save Status",
    "readyToHandover": "<span class='font-medium'>Milage</span> and <span class='font-medium'>fuel</span> are up to date and the car is <span class='font-medium'>vacuumed</span> and <span class='font-medium'>washed</span>",
    "carMilage": "Car Milage",
    "carWashed": "The Car is washed",
    "carVacuumed": "The Car Interior is vacuumed and cleaned",
    "fuelLevelAndMilageUpdated": "Milage and Fuellevel are updated"
  },
  "de": {
    "saveStatus": "Status aktualisieren",
    "readyToHandover": "<span class='font-medium'>Kilometerstand</span> und <span class='font-medium'>Tank</span> sind aktuell und das Auto ist <span class='font-medium'>gesaugt</span> und <span class='font-medium'>gewaschen</span>",
    "carMilage": "Bisheriger Kilometerstand",
    "carWashed": "Das Auto wurde gewaschen",
    "carVacuumed": "Das Interieur wurde gesaugt und gereinigt",
    "fuelLevelAndMilageUpdated": "Kilometer- und Füllstand wurden aktualisiert"
  }
}
</i18n>
