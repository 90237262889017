import { useMutation, useQuery } from '@tanstack/vue-query';
import { reviewService } from '@/api/reviews.service';
import type { CompleteReviewDto } from '@/entities/reviews/review.entity';

export const useReview = (id: string, key: string) =>
  useQuery({
    queryKey: ['reviews'],
    queryFn: async () => {
      return reviewService.getReview(id, key);
    },
  });

interface CompleteReviewVars {
  id: string;
  key: string;
  review: CompleteReviewDto;
}

export const useCompleteReview = (
  onSuccess: (variables: CompleteReviewVars) => void,
  onError: (variables: CompleteReviewVars) => void,
) =>
  useMutation({
    onSuccess(data, variables, context) {
      onSuccess?.(variables);
    },
    onError(error, variables, context) {
      onError?.(variables);
    },

    mutationKey: ['reviews'],
    mutationFn: (vars: { id: string; key: string; review: CompleteReviewDto }) =>
      reviewService.complete(vars.id, vars.key, vars.review),
  });
