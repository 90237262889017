import { Service } from '@/api/http-service';
import type {
  CompleteReviewDto,
  ReviewCompletionScreenDto,
} from '@/entities/reviews/review.entity';

class ReviewsService extends Service {
  constructor() {
    super('/reviews');
  }

  async getReview(id: string, secretCompletionToken: string): Promise<ReviewCompletionScreenDto> {
    const res = await this.httpClient.get<ReviewCompletionScreenDto>(
      `${this.endpoint}/${id}/completion-screen`,
      {
        params: { secretCompletionToken },
      },
    );
    return res.data;
  }

  async complete(
    id: string,
    secretCompletionToken: string,
    review: CompleteReviewDto,
  ): Promise<void> {
    await this.httpClient.post(`${this.endpoint}/${id}/complete`, review, {
      params: { secretCompletionToken },
    });
  }
}

export const reviewService = new ReviewsService();
