<template>
  <Select
    v-if="stationOptions"
    v-model="currentStationId"
    :placeholder="t('station')"
    :options="stationOptions"
  />
</template>

<script setup lang="ts">
import Select from '@/components/Select.vue';
import { useI18n } from 'vue-i18n';
import { useStations } from '@/queries/use-stations';
import { useStationStore as useStationStoreHook } from '@/stores/station.store';
import { computed, ref, watch } from 'vue';
import { useLocalizedFields } from '@/hooks/use-localized-fields';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';

const props = defineProps<{
  useStationStore?: boolean;
  hasNullOption?: boolean;
  nullOptionTitle?: string;
  modelValue?: string | null;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null | undefined): void;
}>();

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();
const stationStore = useStationStoreHook();
const { data: stationsData } = useStations(500);
const stations = useFlattenPaginatedData(stationsData);

const nullOption = computed(() =>
  props.hasNullOption ? [{ label: props.nullOptionTitle ?? t('noStation'), value: null }] : [],
);

const stationOptions = computed(() => [
  ...nullOption.value,
  ...(stations.value?.map((station) => ({
    label: getLocalizedField(station.info),
    value: station.id,
  })) ?? []),
]);

const currentStationId = ref(
  props.useStationStore ? stationStore.currentStationId : props.modelValue,
);

watch(currentStationId, () => {
  if (!stationOptions.value) return;
  if (props.useStationStore) {
    stationStore.setCurrentStation(currentStationId.value);
  } else {
    emit('update:modelValue', currentStationId.value);
  }
});
</script>

<i18n lang="json">
{
  "en": {
    "station": "Station",
    "noStation": "No Station"
  },
  "de": {
    "station": "Station",
    "noStation": "Keine Station"
  }
}
</i18n>
