<template>
  <div class="mt-4 grid grid-cols-12 gap-8">
    <div class="col-span-5">
      <Input v-model="requiredTravelTime" type="time" :label="t('requiredTravelTime')" />
      <Input v-model="bufferOnSite" type="time" :label="t('bufferOnSite')" />
    </div>
    <HorizontalArrow class="col-span-2" left right />
    <div class="col-span-5">
      <DatepickerField
        v-model="startDate"
        :label="t('transferStartTime')"
        name="startDate"
        :enable-time-picker="true"
      />
      <DatepickerField
        v-model="endDate"
        :label="t('arrivalTime')"
        name="endDate"
        :enable-time-picker="true"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import DatepickerField from '@/components/DatepickerField.vue';
import HorizontalArrow from '@/components/HorizontalArrow.vue';
import Input from '@/components/Input.vue';
import { BookingType } from '@/entities/bookings/booking-type.enum';
import type { Booking } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';
import { DateTime, Duration } from 'luxon';
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  transferType: BookingType;
  startDate?: string;
  endDate?: string;
  booking: Booking;
}>();

const { t } = useI18n();

const startDate = ref(props.startDate);
const endDate = ref(props.endDate);
const requiredTravelTime = ref('00:00');
const bufferOnSite = ref('00:00');

const startDateTime = computed(() =>
  DateTime.fromISO(new Date(startDate.value as string).toISOString() ?? new Date().toISOString()),
);
const endDateTime = computed(() =>
  DateTime.fromISO(new Date(endDate.value as string).toISOString() ?? new Date().toISOString()),
);
const bookingStartDateTime = computed(() => DateTime.fromISO(props.booking.startDate ?? ''));
const bookingEndDateTime = computed(() => DateTime.fromISO(props.booking.endDate ?? ''));
const requiredTravelTimeDuration = computed(() =>
  getDurationFromTimeInput(requiredTravelTime.value),
);
const bufferOnSiteDuration = computed(() => getDurationFromTimeInput(bufferOnSite.value));
const completeDuration = computed(() =>
  requiredTravelTimeDuration.value.plus(bufferOnSiteDuration.value),
);

const getDurationFromTimeInput = (time: string) => {
  const hoursMinutes = time.split(':');
  return Duration.fromDurationLike({
    hours: +hoursMinutes[0],
    minutes: +hoursMinutes[1],
  });
};

const convertDurationsToDates = () => {
  if (props.transferType === BookingType.TRANSFER_DELIVERY_BOOKING) {
    startDate.value =
      DateTime.fromISO(props.booking.startDate).minus(completeDuration.value).toISO() ?? undefined;
    endDate.value =
      DateTime.fromISO(props.booking.startDate).minus(bufferOnSiteDuration.value).toISO() ??
      undefined;
  } else {
    startDate.value =
      DateTime.fromISO(props.booking.endDate).plus(bufferOnSiteDuration.value).toISO() ?? undefined;
    endDate.value =
      DateTime.fromISO(props.booking.endDate).plus(completeDuration.value).toISO() ?? undefined;
  }
};

const convertDatesToDurations = () => {
  requiredTravelTime.value = endDateTime.value.diff(startDateTime.value).toFormat('hh:mm');
  if (props.transferType === BookingType.TRANSFER_DELIVERY_BOOKING) {
    bufferOnSite.value = bookingStartDateTime.value.diff(endDateTime.value).toFormat('hh:mm');
  } else {
    bufferOnSite.value = startDateTime.value.diff(bookingEndDateTime.value).toFormat('hh:mm');
  }
};

watch([requiredTravelTime, bufferOnSite], () => {
  convertDurationsToDates();
});

watch([startDate, endDate], () => {
  convertDatesToDurations();
});

if (startDate.value && endDate.value) {
  convertDatesToDurations();
} else {
  convertDurationsToDates();
}
</script>

<i18n lang="json">
{
  "en": {
    "requiredTravelTime": "Required Travel Time",
    "bufferOnSite": "Buffer on Site",
    "transferStartTime": "Transfer Start Time",
    "arrivalTime": "Arrival Time"
  },
  "de": {
    "requiredTravelTime": "Benötigte Fahrtdauer",
    "bufferOnSite": "Puffer vor Ort",
    "transferStartTime": "Transferstartzeit",
    "arrivalTime": "Ankunftszeit"
  }
}
</i18n>
