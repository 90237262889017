import type { PaginatedResponse } from '@/entities/pagination/paginated-response';
import { Service } from './http-service';
import type {
  CreateInsuranceCaseDto,
  InsuranceCase,
  InsuranceCasesControllerFindAllData,
  UpdateInsuranceCaseDto,
} from '@carvia/ros-client-types';

class InsuranceCasesService extends Service<
  InsuranceCase,
  CreateInsuranceCaseDto,
  UpdateInsuranceCaseDto
> {
  constructor() {
    super('/insurance-cases');
  }

  async getAllWithQuery(params: InsuranceCasesControllerFindAllData['query']) {
    const res = await this.httpClient.get<PaginatedResponse<InsuranceCase>>(`${this.endpoint}`, {
      params,
    });
    return res.data;
  }
}

export const insuranceCasesService = new InsuranceCasesService();
