<template>
  <HeaderBar />

  <section class="carvia-container flex flex-col justify-center">
    <div class="flex justify-between">
      <Input v-model="search" :placeholder="t('search')" />
      <CVButton @click.prevent="router.push('create-invoice')">{{
        t('createNewInvoice')
      }}</CVButton>
    </div>

    <Tabs v-model="activeTab" :tabs="tabs" class="mb-4 mt-6" />

    <div>
      <InvoiceList v-if="activeTab !== Tab.UNHANDLED_DAMAGES" :query-params="queryParams" />

      <BookingList v-else />

      <Divider />

      <DownloadInvoiceCsv />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { FindAllInvoicesSort } from '@/entities/invoices/find-all-invoices-sort.enum';
import { Order } from '@/entities/pagination/order.enum';
import { useI18n } from 'vue-i18n';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useUiStore } from '@/stores/ui.store';
import Input from '@/components/Input.vue';
import Tabs, { type TabItem } from '@/components/Tabs.vue';
import { computed, ref, watch, watchEffect } from 'vue';
import { InvoiceStatus } from '@/entities/invoices/invoice-status.enum';
import { DateTime } from 'luxon';
import Divider from '@/components/Divider.vue';
import DownloadInvoiceCsv from './DownloadInvoiceCsv.vue';
import { InvoiceType } from '@/entities/invoices/invoice-type.enum';
import { debounce } from 'lodash';
import router from '@/router';
import InvoiceList from './InvoiceList.vue';
import BookingList from './BookingList.vue';

enum Tab {
  ALL = 'ALL',
  NOT_PAID = 'NOT_PAID',
  OVERDUE = 'OVERDUE',
  CANCELLED = 'CANCELLED',
  UNHANDLED_DAMAGES = 'UNHANDLED_DAMAGES',
}

const { t } = useI18n();
const uiStore = useUiStore();
watchEffect(() => {
  uiStore.setHeaderTitle(t('invoices'));
});

const activeTab = ref<Tab>(Tab.NOT_PAID);

const search = ref('');
const searchQuery = ref('');

const refreshSearchQuery = debounce(() => {
  searchQuery.value = search.value;
}, 500);
watch(search, (newSearch) => {
  refreshSearchQuery();
  if (newSearch) {
    activeTab.value = Tab.ALL;
  }
});

watch(activeTab, (newActiveTab) => {
  if (newActiveTab !== Tab.ALL) {
    search.value = '';
  }
});

const queryStatus = computed(() => {
  switch (activeTab.value) {
    case Tab.NOT_PAID:
      return [
        {
          status: [InvoiceStatus.OPEN, InvoiceStatus.SENT],
          types: [
            InvoiceType.BOOKING,
            InvoiceType.DAMAGE,
            InvoiceType.OTHER,
            InvoiceType.GENERIC,
            InvoiceType.ADMINISTRATIVE_OFFENCE,
            InvoiceType.CAR_SALE,
          ],
        },
      ];
    case Tab.OVERDUE:
      return [
        {
          status: [InvoiceStatus.OPEN, InvoiceStatus.SENT],
          types: [
            InvoiceType.BOOKING,
            InvoiceType.DAMAGE,
            InvoiceType.OTHER,
            InvoiceType.GENERIC,
            InvoiceType.ADMINISTRATIVE_OFFENCE,
            InvoiceType.CAR_SALE,
          ],
          invoiceDateBefore: DateTime.now().minus({ days: 14 }).startOf('day').toISO(),
        },
      ];
    case Tab.CANCELLED:
      return [
        {
          status: [InvoiceStatus.CANCELED],
        },
        {
          types: [InvoiceType.REVERSAL],
        },
      ];
    default:
      return [
        {
          status: [
            InvoiceStatus.OPEN,
            InvoiceStatus.SENT,
            InvoiceStatus.PAID,
            InvoiceStatus.CANCELED,
          ],
          query: searchQuery.value ? searchQuery.value : undefined,
        },
      ];
  }
});

const queryParams = computed(() => ({
  filter: queryStatus.value,
  sort: FindAllInvoicesSort.INVOICE_DATE,
  order: Order.DESC,
}));

const tabs = computed<TabItem[]>(() => [
  {
    title: t('notPaid'),
    id: Tab.NOT_PAID,
  },
  {
    title: t('overdue'),
    id: Tab.OVERDUE,
  },
  {
    title: t('all'),
    id: Tab.ALL,
  },
  {
    title: t('cancelled'),
    id: Tab.CANCELLED,
  },
  {
    title: t('unhandledDamages'),
    id: Tab.UNHANDLED_DAMAGES,
  },
]);
</script>

<i18n lang="json">
{
  "en": {
    "invoices": "Invoices",
    "search": "Search",
    "notPaid": "Not Paid",
    "overdue": "Overdue",
    "all": "All",
    "cancelled": "Cancelled",
    "createNewInvoice": "+ Create new Invoice",
    "unhandledDamages": "Unhandled Damages"
  },
  "de": {
    "invoices": "Rechnungen",
    "search": "Suche",
    "notPaid": "Nicht bezahlt",
    "overdue": "Überfällig",
    "all": "Alle",
    "cancelled": "Storno",
    "createNewInvoice": "+ Neue Rechnung erstellen",
    "unhandledDamages": "Unbehandelte Schäden"
  }
}
</i18n>
