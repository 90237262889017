import type {
  CarDocument,
  CreateCarDocumentDto,
  UpdateCarDocumentDto,
} from '@/entities/cars/car-document.entity';
import type { FindAllCarDocumentsQuery } from '@/entities/cars/find-all-car-documents-query';
import type { DeleteResponse } from '@/entities/delete-reponse';
import type { PaginatedResponse } from '@/entities/pagination/paginated-response';
import { httpClient } from './http-client';

class CarDocumentsService {
  httpClient = httpClient;
  endpoint = '/cars/';
  subEndpoint = '/documents';

  async getAllPaginated(carId: string, params: FindAllCarDocumentsQuery) {
    const res = await this.httpClient.get<PaginatedResponse<CarDocument>>(
      `${this.endpoint}${carId}${this.subEndpoint}`,
      {
        params,
      },
    );
    return res.data;
  }
  async getOne(carId: string, documentId: string) {
    const res = await this.httpClient.get<CarDocument>(
      `${this.endpoint}${carId}${this.subEndpoint}/${documentId}`,
    );
    return res.data;
  }
  async update(carId: string, documentId: string, data: UpdateCarDocumentDto) {
    const res = await this.httpClient.patch<CarDocument>(
      `${this.endpoint}${carId}${this.subEndpoint}/${documentId}`,
      data,
    );
    return res.data;
  }
  async create(carId: string, data: CreateCarDocumentDto) {
    const res = await this.httpClient.post<CarDocument>(
      `${this.endpoint}${carId}${this.subEndpoint}`,
      data,
    );
    return res.data;
  }
  async remove(carId: string, documentId: string) {
    const res = await this.httpClient.delete<DeleteResponse>(
      `${this.endpoint}${carId}${this.subEndpoint}/${documentId}`,
    );
    return res.data;
  }
}

export const carDocumentsService = new CarDocumentsService();
