<template>
  <div>
    <p class="mb-4 text-2xl font-medium">
      {{ t('linkAgency') }}
    </p>
    <Select
      v-if="agencies"
      v-model="agencyId"
      :options="agencyOptions"
      :placeholder="t('agency')"
      class="max-w-fit"
    />
    <Divider />
    <div class="flex items-end justify-between">
      <CVButton outline @click="$emit('back')">{{ t('back') }}</CVButton>
      <div class="flex items-end gap-4">
        <TotalPrice :total-price="totalPrice" />
        <CVButton :disabled="!agencyId" @click="linkAgent">{{ t('linkAgency') }}</CVButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Divider from '@/components/Divider.vue';
import Select from '@/components/Select.vue';
import { UserRole } from '@/entities/auth/user-role.enum';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { useI18n } from 'vue-i18n';
import { useUsersWithQuery } from '@/queries/use-users';
import { computed, ref, watchEffect } from 'vue';
import TotalPrice from '../../TotalPrice.vue';
import type { User } from '@/entities/auth/user.entity';

const props = defineProps<{
  agency: User | null;
  totalPrice: number;
}>();

const emit = defineEmits<{
  (e: 'update:agency', value: User): void;
  (e: 'continue'): void;
  (e: 'back'): void;
}>();

const { t } = useI18n();

const agencyId = ref<string | null>(props.agency?.id ?? null);
watchEffect(() => {
  agencyId.value = props.agency?.id ?? null;
});

const { data: agenciesData } = useUsersWithQuery({
  role: [UserRole.AGENT],
  limit: 500,
});
const agencies = useFlattenPaginatedData(agenciesData);

const agencyOptions = computed(() =>
  agencies.value.map((agency) => {
    return {
      label: `${agency.firstName} ${agency.lastName}${agency.company ? ', ' + agency.company : ''}`,
      value: agency.id,
    };
  }),
);

const linkAgent = () => {
  const agency = agencies.value.find((agency) => agency.id === agencyId.value);
  if (agency) {
    emit('update:agency', agency);
    emit('continue');
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "linkAgency": "Link Partner/Agency",
    "agency": "Partner/Agency"
  },
  "de": {
    "linkAgency": "Partner/Vermittler verknüpfen",
    "agency": "Partner/Vermittler"
  }
}
</i18n>
