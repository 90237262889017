<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <vue-final-modal
    v-slot="{ params, close }"
    v-bind="$attrs"
    class="flex items-center justify-center"
    content-class="modal-box w-11/12 max-w-5xl"
    content-transition="vfm-fade"
    overlay-transition="vfm-fade"
    :click-to-close="closeOnClickOutside"
  >
    <span v-if="$slots.title" class="mr-8 text-2xl font-bold">
      <slot name="title"></slot>
    </span>
    <div class="flex-grow overflow-y-auto">
      <slot :params="params"></slot>
    </div>
    <div
      v-if="showConfirm || showClose"
      class="flex flex-shrink-0 items-center justify-center pt-4"
    >
      <CVButton v-if="showConfirm" @click="$emit('confirm', close)">
        {{ t('confirm') }}
      </CVButton>
      <CVButton v-if="showCancel" @click="$emit('cancel', close)">
        {{ t('cancel') }}
      </CVButton>
    </div>
    <button v-if="showClose" class="right-x0 absolute top-0 mr-2 mt-2" @click="close">close</button>
  </vue-final-modal>
</template>

<script lang="ts">
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

withDefaults(
  defineProps<{
    showConfirm?: boolean;
    showClose?: boolean;
    showCancel?: boolean;
    closeOnClickOutside?: boolean;
  }>(),
  {
    closeOnClickOutside: true,
  },
);

defineEmits<{
  (e: 'confirm', close: () => void): void;
  (e: 'cancel', close: () => void): void;
}>();
</script>
