import type { LocationGeocodedAddress } from '@carvia/ros-client-types';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';

export const locationGeocodedAddressToBookingLocation = (
  location: LocationGeocodedAddress | null | undefined,
) => {
  if (!location) return location;
  return {
    locationType: BookingLocationType.STREET,
    station: null,
    location: {
      coordinates: location.location,
      address: {
        displayName: location.displayName,
      },
    },
  };
};
