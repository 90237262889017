<template>
  <SearchApiEntityItemInput
    :search-result-options="searchResultOptions"
    :is-fetching-results="isFetchingResults"
    :placeholder="t('searchCar')"
    @fetch="fetchResults"
    @on-update-selected-item="updateSelectedItem"
  />
</template>

<script lang="ts" setup>
import SearchApiEntityItemInput from '@/components/SearchApiEntityItemInput.vue';
import { computed, ref } from 'vue';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { useI18n } from 'vue-i18n';
import { useCarsWithQuery } from '@/queries/use-cars';
import { CarStatus } from '@/entities/cars/car-status.enum';
import type { Car, FindAllCarsQueryDto } from '@/entities/cars/car.entity';

const props = defineProps<{
  stationId: string | undefined;
}>();

const emit = defineEmits<{
  (e: 'update-car', value: Car): void;
}>();

const { t } = useI18n();

const searchString = ref('');

const query = computed<FindAllCarsQueryDto>(() => ({
  query: searchString.value,
  isGhostCar: false,
  status: [CarStatus.ORDERED, CarStatus.FLOAT_IN],
  stationIds: props.stationId ? [props.stationId] : undefined,
  limit: 10,
}));

const { data: searchResultsData, isFetching: isFetchingResults } = useCarsWithQuery(query);

const fetchResults = (search: string) => {
  searchString.value = search;
};

const searchResults = useFlattenPaginatedData(searchResultsData);

const searchResultOptions = computed(() => {
  return searchResults.value.map((car) => {
    const content = [car.vehicleType.make.name + ' ' + car.vehicleType.model];
    content.push(car.licencePlate);
    content.push(car.fin);
    return {
      item: car,
      content,
    };
  });
});

const updateSelectedItem = (item: unknown) => {
  emit('update-car', item as Car);
};
</script>

<i18n lang="json">
{
  "en": {
    "searchCar": "Search car"
  },
  "de": {
    "searchCar": "Auto suchen"
  }
}
</i18n>
