<template>
  <Modal v-model="showModal">
    <div class="flex justify-between">
      <div class="mb-8">
        <h2 class="mb-8">{{ t('uploadImage') }}</h2>
        <FileInput v-model="imageFile" :disabled="imageFile" accept="image/*" />
      </div>
      <img
        v-if="imageUrl"
        :src="imageUrl"
        class="mb-4 max-h-[340px] max-w-[460px] border border-black/20"
      />
    </div>
    <div class="flex justify-end gap-4">
      <CVButton variant="warning" @click.prevent="showModal = false">{{ t('cancel') }}</CVButton>
      <CVButton variant="success" :disabled="!imageFile" @click.prevent="onSelect">{{
        t('select')
      }}</CVButton>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import Modal from './Modal.vue';
import { useI18n } from 'vue-i18n';
import FileInput from './FileInput.vue';

const props = defineProps<{
  showModal: boolean;
  imageFile: File | undefined | null;
}>();

const emit = defineEmits<{
  (e: 'update:showModal', value: boolean): void;
  (e: 'select', imageFile: File, imageUrl: string): void;
}>();

const { t } = useI18n();

const showModal = ref(props.showModal);
const imageFile = ref(props.imageFile);

watch(
  () => props.imageFile,
  () => {
    imageFile.value = props.imageFile;
  },
);

const imageUrl = computed(() => {
  if (imageFile.value) {
    return URL.createObjectURL(imageFile.value);
  }
  return undefined;
});

const onSelect = () => {
  if (!imageFile.value || !imageUrl.value) {
    return;
  }
  emit('select', imageFile.value, imageUrl.value);
  showModal.value = false;
};

watch(
  () => props.showModal,
  () => {
    showModal.value = props.showModal;
  },
);

watch(showModal, () => {
  emit('update:showModal', showModal.value);
});
</script>

<i18n lang="json">
{
  "en": {
    "uploadImage": "Upload image"
  },
  "de": {
    "uploadImage": "Bild hochladen"
  }
}
</i18n>
