<template>
  <div class="grow">
    <div v-if="booking">
      <h2>
        {{ t('returnOverview') }}
      </h2>

      <BasicReturnOverview :booking="booking" />

      <Divider />

      <VehicleAndTimes
        :booking="booking"
        use-return-date
        @on-return-date-change="(date) => (returnDate = date)"
      />

      <Divider />

      <div class="flex justify-end">
        <ButtonNext :is-loading="isPending" @click.prevent="handleNext">{{ t('next') }}</ButtonNext>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import VehicleAndTimes from '@/components/booking-forms/VehicleAndTimes.vue';
import ButtonNext from '@/components/buttons/ButtonNext.vue';
import Divider from '@/components/Divider.vue';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form';
import { useI18n } from 'vue-i18n';
import { useBookingOfType, useUpdateBooking } from '@/queries/use-bookings';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import BasicReturnOverview from './components/BasicReturnOverview.vue';
import { formHasChanged } from '@/hooks/use-form-has-changed';
import { BookingType } from '@/entities/bookings/booking-type.enum';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const returnDate = ref<string>();

const { data: booking } = useBookingOfType(
  route.params.id as string,
  true,
  BookingType.CUSTOMER_BOOKING,
  'upcomingHandovers',
);
const { mutateAsync: updateBooking, isPending } = useUpdateBooking();

const unchangedValue = ref<string>();
watch(returnDate, (newDate) => {
  if (unchangedValue.value === undefined) {
    unchangedValue.value = newDate;
  }
});

const handleNext = async () => {
  if (!booking.value) return;
  unchangedValue.value = returnDate.value;
  await updateBooking({
    id: booking.value.id,
    booking: {
      returnDate: returnDate.value,
    },
  });
  router.push({
    name: 'returnDamages',
    params: { id: booking.value.id },
  });
};

usePreventLeavingUnsavedForm(() => formHasChanged(unchangedValue.value, returnDate.value));
</script>

<i18n lang="json">
{
  "en": {
    "returnOverview": "Return overview",
    "back": "return to upcoming handovers",
    "next": "next"
  },
  "de": {
    "back": "Zurück zur Übergabeliste",
    "next": "Speichern und weiter",
    "returnOverview": "Rückgabeübersicht"
  }
}
</i18n>
