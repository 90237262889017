import { onMounted, onUnmounted, ref } from 'vue';

export const useMousePosition = () => {
  const mousePosition = ref({ x: 0, y: 0 });

  onMounted(() => {
    document.body.addEventListener('touchstart', handleMoveMouse);
    document.body.addEventListener('touchmove', handleMoveMouse);
    document.body.addEventListener('mousemove', handleMoveMouse);
  });

  onUnmounted(() => {
    document.body.removeEventListener('touchstart', handleMoveMouse);
    document.body.removeEventListener('touchmove', handleMoveMouse);
    document.body.removeEventListener('mousemove', handleMoveMouse);
  });

  const handleMoveMouse = (e: MouseEvent | TouchEvent) => {
    mousePosition.value = {
      x: (e as MouseEvent)?.clientX ?? (e as TouchEvent).touches?.[0].clientX,
      y: (e as MouseEvent)?.clientY ?? (e as TouchEvent).touches?.[0].clientY,
    };
  };

  return mousePosition;
};
