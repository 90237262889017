<template>
  <label class="label cursor-pointer justify-start">
    <input v-model="modelValue" type="checkbox" class="checkbox mr-2 bg-white" />
    <span v-if="label">{{ label }}</span>
    <slot v-else />
  </label>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';

const props = defineProps<{
  modelValue?: boolean;
  label?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'change', value: boolean): void;
}>();

const modelValue = ref(props.modelValue);
watch(
  () => props.modelValue,
  (newValue) => (modelValue.value = newValue),
);

watch(modelValue, (newValue) => {
  emit('update:modelValue', newValue);
  emit('change', newValue);
});
</script>
