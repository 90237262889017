<template>
  <HeaderBar />

  <section class="carvia-container flex flex-col justify-center">
    <div class="mb-4 flex justify-between gap-4">
      <div class="grid grid-cols-2 gap-4">
        <Input v-model="search" :placeholder="t('search')" class="w-full max-w-sm" />
        <StationSwitcher
          v-model="currentStationId"
          has-null-option
          :null-option-title="t('allStations')"
        />
      </div>
      <CVButton :to="{ name: 'paymentTerminal', params: { id: 'new' } }">{{
        t('newPaymentTerminal')
      }}</CVButton>
    </div>

    <div>
      <div v-if="paymentTerminals" class="flex flex-col space-y-2">
        <div v-for="(paymentTerminal, i) in paymentTerminals" :key="i">
          <PaymentTerminalListItem :payment-terminal="paymentTerminal" />
        </div>
      </div>

      <div
        v-if="paymentTerminalsStatus === 'success' && paymentTerminals.length === 0"
        class="flex justify-center py-28"
      >
        <p>{{ t('noPaymentTerminalsFound') }}</p>
      </div>

      <div v-if="paymentTerminalsStatus === 'pending'" class="flex justify-center py-28">
        <Spinner />
      </div>

      <div v-if="paymentTerminalsStatus === 'error'" class="flex justify-center py-28">
        <p>{{ paymentTerminalsError }}</p>
      </div>

      <div v-if="hasNextPage" class="flex justify-center pt-8">
        <CVButton outline :is-loading="isFetchingNextPage" @click="() => fetchNextPage()">
          {{ t('loadMore') }}
        </CVButton>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { FindAllPaymentTerminalsSort } from '@/entities/payment-terminals/find-all-payment-terminals-sort.enum';
import { Order } from '@/entities/pagination/order.enum';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import PaymentTerminalListItem from './PaymentTerminalListItem.vue';
import { useI18n } from 'vue-i18n';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useUiStore } from '@/stores/ui.store';
import Input from '@/components/Input.vue';
import { computed, ref, watch, watchEffect } from 'vue';
import { usePaymentTerminalsWithQuery } from '@/queries/use-payment-terminals';
import StationSwitcher from '@/views/settings/components/StationSwitcher.vue';
import { debounce } from 'lodash';

const { t } = useI18n();
const currentStationId = ref<string>();
const uiStore = useUiStore();
watchEffect(() => {
  uiStore.setHeaderTitle(t('paymentTerminals'));
});

const search = ref('');
const searchQuery = ref('');

const refreshSearchQuery = debounce(() => {
  searchQuery.value = search.value;
}, 500);
watch(search, () => {
  refreshSearchQuery();
});

const queryParams = computed(() => ({
  stationIds: currentStationId.value ? [currentStationId.value] : undefined,
  query: searchQuery.value,
  sort: FindAllPaymentTerminalsSort.DESCRIPTION,
  order: Order.ASC,
}));

const {
  data: paymentTerminalsData,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  status: paymentTerminalsStatus,
  error: paymentTerminalsError,
} = usePaymentTerminalsWithQuery(queryParams);

const paymentTerminals = useFlattenPaginatedData(paymentTerminalsData);
</script>

<i18n lang="json">
{
  "en": {
    "paymentTerminals": "Payment Terminals",
    "newPaymentTerminal": "+ New Payment Terminal",
    "search": "Search",
    "noPaymentTerminalsFound": "No Payment Terminals found",
    "allStations": "All Stations"
  },
  "de": {
    "paymentTerminals": "Zahlungsterminale",
    "newPaymentTerminal": "+ Neues Zahlungsterminal",
    "search": "Suche",
    "noPaymentTerminalsFound": "Keine Zahlungsterminale gefunden",
    "allStations": "Alle Stationen"
  }
}
</i18n>
