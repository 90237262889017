<template>
  <section class="grid min-h-screen place-items-center bg-accent">
    <div>
      <h1 class="mb-4 text-center text-4xl font-[600] xl:text-6xl">
        {{ t('resetPassword') }}
      </h1>

      <div
        class="card mx-auto w-[27rem] max-w-full space-y-2 overflow-hidden bg-white p-8 shadow-lg"
      >
        <form class="space-y-4" @submit="onPasswordReset">
          <InputField
            name="password"
            autocomplete="new-password"
            :label="t('password')"
            type="password"
          />

          <InputField
            autocomplete="new-password"
            name="passwordConfirm"
            :label="t('passwordConfirm')"
            type="password"
          />
          <CVButton type="submit" :is-loading="isPending">
            {{ t('save') }}
          </CVButton>
        </form>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useResetPassword } from '@/queries/auth/use-password-reset';
import { resetPasswordSchema } from '@/validation/reset-password.schema';
import { useForm } from 'vee-validate';
import { useRoute } from 'vue-router';
import InputField from '@/components/InputField.vue';
import router from '@/router';
import { useAuthStore } from '@/stores/auth.store';

interface FormValues {
  passwordConfirm: string;
  password: string;
}
const route = useRoute();
const { token } = route.query;
const { t } = useI18n();
const authStore = useAuthStore();
const { mutateAsync: resetPassword, isPending } = useResetPassword();

const { handleSubmit, resetForm } = useForm<FormValues>({
  validationSchema: resetPasswordSchema,
});

const onPasswordReset = handleSubmit(async (values) => {
  const res = await resetPassword({
    password: values.password,
    token: token as string,
  });
  resetForm();
  authStore.setAuthUser(res.user);
  router.push({ name: 'home' });
});
</script>

<i18n lang="json">
{
  "en": {
    "resetPassword": "Reset Password",
    "password": "Password",
    "save": "Save",
    "passwordConfirm": "Confirm Password"
  },
  "de": {
    "resetPassword": "Passwort zurücksetzen",
    "password": "Passwort",
    "save": "Speichern",
    "passwordConfirm": "Passwort bestätigen"
  }
}
</i18n>
