<template>
  <Modal :model-value="showModal" @update:model-value="$emit('update:showModal', $event)">
    <template #title>{{ t('createNewCustomer') }}</template>

    <CreateCustomerModalForm
      v-if="showModal"
      :schema="schema"
      :initial-customer-data="initialCustomerData"
      :is-saving="isSaving"
      @on-save="onSave"
      @on-cancel="$emit('onCancel')"
    />
  </Modal>
</template>

<script lang="ts" setup>
import Modal from '@/components/Modal.vue';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import type { ObjectSchema } from 'yup';
import CreateCustomerModalForm from './CreateCustomerModalForm.vue';
import type { CreateUserDto, User } from '@/entities/auth/user.entity';
import { useCreateUser } from '@/queries/use-users';

const props = defineProps<{
  showModal: boolean;
  schema: ObjectSchema<any>;
  initialCustomerData?: Partial<CreateUserDto>;
  isSaving?: boolean;
}>();

const emit = defineEmits<{
  (e: 'newCustomerCreated', newCustomer: User): void;
  (e: 'onFinished'): void;
  (e: 'onCancel'): void;
  (e: 'update:showModal', value: boolean): void;
}>();

const { t } = useI18n();

const isSaving = computed(() => props.isSaving || isCreatingUser.value);

const { mutateAsync: createUser, isPending: isCreatingUser } = useCreateUser();

const onSave = async (user: CreateUserDto) => {
  const newCustomer = await createUser(user);
  emit('newCustomerCreated', newCustomer);
};
</script>

<i18n lang="json">
{
  "en": {
    "createNewCustomer": "Create and link new Customer"
  },
  "de": {
    "createNewCustomer": "Neuen Kunden anlegen und verknüpfen"
  }
}
</i18n>
