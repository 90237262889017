<template>
  <div class="mt-4 lg:text-lg">
    <div class="font-medium">{{ t('positions') }}</div>
    <div class="border border-primary p-4">
      <div v-for="(position, i) in positionFields" :key="i" class="relative">
        <div class="mb-4 grid grid-cols-3 gap-4 border-b border-primary pb-4">
          <InputField :name="`positions[${i}].title`" :label="t('title')" class="col-span-3" />
          <InputField
            :name="`positions[${i}].amount`"
            :label="t('amount')"
            type="number"
            :max-fraction-digits="0"
          />
          <InputField
            :name="`positions[${i}].unitPrice`"
            :label="t('unitPrice')"
            type="number"
            :max-fraction-digits="2"
            adornment="€"
          />
          <InputField
            :disabled="invoiceType === InvoiceType.DAMAGE"
            :name="`positions[${i}].tax`"
            :label="t('tax')"
            type="number"
            :max-fraction-digits="0"
            adornment="%"
          />
        </div>
        <div v-if="i !== 0" class="absolute right-0 top-0 flex items-center">
          <button
            class="cursor-pointer p-2 text-lg leading-3 text-red-600"
            @click="removePosition(i)"
          >
            ×
          </button>
        </div>
      </div>
      <div class="mt-4 flex justify-end">
        <CVButton @click.prevent="pushPosition(getEmptyPosition())">{{
          t('addPosition')
        }}</CVButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import InputField from '@/components/InputField.vue';
import { InvoiceType } from '@/entities/invoices/invoice-type.enum';
import { useI18n } from 'vue-i18n';
import { useFieldArray } from 'vee-validate';

export interface PositionFormValues {
  title: string;
  amount: number | undefined;
  unitPrice: number | undefined;
  tax: number | undefined;
}

const props = defineProps<{
  invoiceType: InvoiceType;
}>();

const { t } = useI18n();

const {
  push: pushPosition,
  remove: removePosition,
  fields: positionFields,
} = useFieldArray<PositionFormValues>('positions');

pushPosition(getEmptyPosition());

function getEmptyPosition(type?: InvoiceType): PositionFormValues {
  return {
    title: '',
    amount: 1,
    unitPrice: undefined,
    tax: (type ?? props.invoiceType) === InvoiceType.DAMAGE ? 0 : 19,
  };
}
</script>

<i18n lang="json">
{
  "en": {
    "title": "Title",
    "amount": "Amount",
    "unitPrice": "Unit Price (Gross)",
    "tax": "Tax",
    "addPosition": "+ Add Position",
    "positions": "Positions"
  },
  "de": {
    "title": "Titel",
    "amount": "Anzahl",
    "unitPrice": "Preis (Brutto)",
    "tax": "Steuer",
    "addPosition": "+ Position hinzufügen",
    "positions": "Positionen"
  }
}
</i18n>
