<template>
  <Input
    v-model="value"
    :hint="error"
    :is-error="!!error"
    :type="type"
    :max-fraction-digits="maxFractionDigits"
    :min="min"
  />
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { toRef, watch } from 'vue';
import Input from './Input.vue';

const props = defineProps<{
  name: string;
  initialValue?: string | number | null;
  type?: string;
  saveNumberAsString?: boolean;
  emptyValueTo?: 'null' | 'undefined';
  maxFractionDigits?: number;
  min?: number;
  isError?: boolean;
}>();

const name = toRef(props, 'name');

const { errorMessage: error, value } = useField<string | number | null | undefined>(
  name,
  undefined,
  {
    initialValue: props.initialValue,
  },
);

if (props.emptyValueTo) {
  watch(value, (newValue) => {
    const emptyValue = props.emptyValueTo === 'null' ? null : undefined;
    value.value =
      newValue === '' || newValue === undefined || newValue === null ? emptyValue : newValue;
  });
}
if (props.saveNumberAsString) {
  watch(value, (newValue) => {
    value.value = props.saveNumberAsString ? `${newValue}` : newValue;
  });
}
</script>
