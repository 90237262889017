import { type MaybeRefOrGetter, toRef, toValue } from '@vueuse/core';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import type {
  CreateInsuranceCaseDto,
  InsuranceCase,
  InsuranceCasesControllerFindAllData,
  UpdateInsuranceCaseDto,
} from '@carvia/ros-client-types';
import { insuranceCasesService } from '@/api/insurance-cases.service';
import {
  addItemToQueriesData,
  removeItemFromQueriesData,
  updateItemOnQueriesData,
} from '@/utils/update-query-data';
import type { MutationDefaultsContext } from '@/queries/query-client';

const QUERY_KEY = 'insuranceCases';

export const useInsuranceCase = (
  id: MaybeRefOrGetter<string>,
  enabled?: MaybeRefOrGetter<boolean>,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(id)],
    queryFn: () => insuranceCasesService.getOne(toValue(id)),
    enabled: toRef(enabled),
  });
};

export const useInsuranceCases = (
  query: MaybeRefOrGetter<InsuranceCasesControllerFindAllData['query']>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY, toRef(query)],
    queryFn: ({ pageParam = 1 }) =>
      insuranceCasesService.getAllWithQuery({ ...toValue(query), page: pageParam }),
    enabled: toRef(enabled),
    getNextPageParam: (lastPage) => lastPage.meta.nextPage,
    initialPageParam: 1,
  });
};

export const useUpdateInsuranceCase = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ id, insuranceCase }: { id: string; insuranceCase: UpdateInsuranceCaseDto }) =>
      insuranceCasesService.update(id, insuranceCase),
    onMutate: () => ({
      successMessage: 'Insurance case updated',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(null, null, context);
      updateItemOnQueriesData<InsuranceCase>([QUERY_KEY], result, queryClient);
    },
  });
};

export const useCreateInsuranceCase = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (insuranceCase: CreateInsuranceCaseDto) =>
      insuranceCasesService.create(insuranceCase),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'Insurance case created',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(null, null, context);
      addItemToQueriesData<InsuranceCase>([QUERY_KEY], result, queryClient);
    },
  });
};

export const useDeleteInsuranceCase = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (id: string) => insuranceCasesService.remove(id),
    onMutate: (id) => {
      return { id };
    },
    onSuccess: (result, variables, context) => {
      removeItemFromQueriesData([QUERY_KEY], context?.id, queryClient);
    },
  });
};
