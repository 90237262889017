<template>
  <Checkbox v-model="inputValue"><slot /></Checkbox>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import Checkbox from './Checkbox.vue';

const props = withDefaults(
  defineProps<{
    name: string;
    initialValue?: boolean | null;
  }>(),
  {
    initialValue: undefined,
  },
);

const { value: inputValue } = useField(props.name, undefined, {
  initialValue: props.initialValue === null ? false : props.initialValue,
});
</script>
