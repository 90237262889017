import { authService } from '@/api/auth.service';
import { useAuthStore } from '@/stores/auth.store';
import { createToast } from 'mosha-vue-toastify';
import { useMutation } from '@tanstack/vue-query';

export const useLogout = () => {
  return useMutation({
    mutationFn: () => authService.logout(),
    onSuccess: () => {
      const auth = useAuthStore();
      auth.setAuthUser(null);
      auth.setUser(null);
      document.location.href = '/login';
    },
    onError: (error) => {
      if (Array.isArray((error as any).response.data.error)) {
        (error as any).response.data.error.forEach((el: any) =>
          createToast(el.message, {
            position: 'top-right',
            type: 'warning',
          }),
        );
      } else {
        createToast((error as any).response.data.message, {
          position: 'top-right',
          type: 'danger',
        });
      }
    },
  });
};
