<template>
  <HeaderBar />

  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
  </ButtonBackContainer>

  <section class="carvia-container flex flex-col justify-center">
    <StationForm
      v-if="station || stationNew"
      :station="station"
      :is-loading="isLoading"
      :is-fetching="isFetching"
      @on-submit="onSubmit"
      @refetch="refetch"
    />
    <div v-else class="flex justify-center"><Spinner /></div>
  </section>
</template>

<script lang="ts" setup>
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useI18n } from 'vue-i18n';
import { useUiStore } from '@/stores/ui.store';
import { computed, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import StationForm from './StationForm.vue';
import { useCreateStation, useStation, useUpdateStation } from '@/queries/use-stations';
import type { CreateStationDto, UpdateStationDto } from '@/entities/station/station.entity';
import { useLocalizedFields } from '@/hooks/use-localized-fields';
import { ref } from 'vue';

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();
const uiStore = useUiStore();
const route = useRoute();
const router = useRouter();
const stationId = ref(route.params.id as string);
const stationNew = computed(() => stationId.value === 'new');
const stationFetchingEnabled = computed(() => !stationNew.value);

const { data: station, refetch, isFetching } = useStation(stationId, stationFetchingEnabled);
const { mutateAsync: createStation, isPending: isSaving } = useCreateStation();
const { mutateAsync: updateStation, isPending: isUpdating } = useUpdateStation();

const isLoading = computed(() => isSaving.value || isUpdating.value);

watchEffect(() => {
  if (station.value) {
    uiStore.setHeaderTitle(
      t('station'),
      getLocalizedField(station.value.info),
      `${station.value.street}, ${station.value.zip} ${station.value.city}`,
    );
  } else {
    uiStore.setHeaderTitle(t('station'));
  }
});

const onSubmit = async (formValues: UpdateStationDto) => {
  if (!stationNew.value) {
    updateStation({
      id: stationId.value,
      station: formValues,
    });
  } else {
    const { id } = await createStation(formValues as CreateStationDto);
    router.replace({ name: 'station', params: { id: id } });
    stationId.value = id;
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "station": "Station"
  },
  "de": {
    "station": "Station"
  }
}
</i18n>
