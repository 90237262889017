<template>
  <div class="mt-4 grid grid-cols-3 gap-x-10">
    <LabeledText :label="t('driver')"
      >{{ transfer.customerData?.firstName ?? 'n/a' }}
      {{ transfer.customerData?.lastName ?? 'n/a' }}</LabeledText
    >

    <LabeledText :label="t('bookingNumber')">{{ bookingNumber }}</LabeledText>

    <LabeledText :label="t('internalComment')">
      {{ transfer.internalComment ?? '-' }}
    </LabeledText>
  </div>
</template>

<script setup lang="ts">
import type { Booking } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';
import LabeledText from '@/components/LabeledText.vue';

const { t } = useI18n();
defineProps<{
  transfer: Booking;
  bookingNumber: number;
}>();
</script>

<i18n lang="json">
{
  "en": {
    "internalComment": "Transfer Comment",
    "returnOverview": "Return overview",
    "driver": "Driver",
    "bookingNumber": "Booking Nr."
  },
  "de": {
    "internalComment": "Transfer Kommentar",
    "returnOverview": "Buchungsübersicht",
    "driver": "Fahrer",
    "bookingNumber": "Buchungs Nr."
  }
}
</i18n>
