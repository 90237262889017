import type { CarDocumentType } from '@/entities/cars/car-document-type.enum';
import { useCreateCarDocument } from '@/queries/use-car-documents';
import { useUploadCarDocument } from '@/queries/use-upload';
import { extractFileNameFromFile } from '@/utils/extract-file-name';
import { DateTime } from 'luxon';
import { computed } from 'vue';

export const useUploadAndCreateCarDocument = () => {
  const { mutateAsync: uploadCarDocument, isPending: isUploadingCarDocument } =
    useUploadCarDocument();
  const { mutateAsync: createCarDocument, isPending: isCreatingCarDocument } =
    useCreateCarDocument();

  const isPending = computed(() => isCreatingCarDocument.value || isUploadingCarDocument.value);

  const uploadAndCreateCarDocument = async (
    carId: string,
    file: File,
    type: CarDocumentType,
    documentName: string,
    comment?: string,
  ) => {
    const { suffix, fileName } = extractFileNameFromFile(file);
    const timestamp = DateTime.now().toISO().replace(/:/g, '-');
    const finalFileName = `${fileName}-${timestamp}.${suffix}`;
    const { fileKey } = await uploadCarDocument({
      file: file,
      params: { carId, fileName: finalFileName },
    });
    await createCarDocument({
      carId,
      document: {
        name: documentName,
        type: type,
        documentFileKey: fileKey,
        carId,
        comment,
      },
    });
  };

  return { uploadAndCreateCarDocument, isPending };
};
