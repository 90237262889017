<template>
  <button
    class="flex h-8 w-8 select-none items-center justify-center transition-all duration-200 ease-in-out"
    data-testid="main-menu-button"
    @click="
      isActive = !isActive;
      $emit('update:isActive', isActive);
    "
  >
    <div class="w-8 flex-col space-y-[7px]">
      <div
        v-for="i in 3"
        :key="i"
        class="h-[5px] bg-primary transition-all duration-200 ease-in-out"
        :class="{
          'ml-[5px] w-9 origin-top-left rotate-45 rounded-md': isActive && i === 1,
          'scale-0 duration-200': isActive && i === 2,
          'ml-[5px] w-9 origin-bottom-left -rotate-45 rounded-md': isActive && i === 3,
          'scale-95': !isActive,
        }"
      ></div>
    </div>
  </button>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps<{
  isActive?: boolean;
}>();

defineEmits<{
  (e: 'update:isActive', value: boolean): void;
}>();

const isActive = ref(props.isActive);

document.addEventListener('click', (event) => {
  const target = event.target as HTMLButtonElement;
  if (!target.closest('button') && isActive.value) {
    isActive.value = false;
  }
});
</script>
