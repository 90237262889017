import type { ChargeAmountDto } from '@/entities/payment-terminals/charge-amount.dto';
import type { ChargeAmountResponseDto } from '@/entities/payment-terminals/charge-amount-response.dto';
import type { ChargeRemainingBookingAmountDto } from '@/entities/payment-terminals/charge-remaining-booking-amount.dto';
import { httpClient } from './http-client';
import type { BlockAmountDto } from '@/entities/payment-terminals/block-amount.dto';

class PaymentApiService {
  httpClient = httpClient;
  endpoint = '/payment-api';

  async chargeAmount(data: ChargeAmountDto) {
    const res = await this.httpClient.post<ChargeAmountResponseDto>(
      `${this.endpoint}/charge-amount`,
      data,
    );
    return res.data;
  }

  async blockAmount(data: BlockAmountDto) {
    const res = await this.httpClient.post<ChargeAmountResponseDto>(
      `${this.endpoint}/block-amount`,
      data,
    );
    return res.data;
  }

  async chargeRemainingBookingAmount(data: ChargeRemainingBookingAmountDto) {
    const res = await this.httpClient.post<ChargeAmountResponseDto>(
      `${this.endpoint}/charge-remaining-booking-amount`,
      data,
    );
    return res.data;
  }
}

export const paymentApiService = new PaymentApiService();
