import messages from '@intlify/unplugin-vue-i18n/messages';
import { Settings } from 'luxon';
import { createI18n } from 'vue-i18n';
import type en from './locales/en.json';

// Type-define 'en' as the master schema for the resource
export type MessageSchema = typeof en;

export const i18n = createI18n<[MessageSchema], 'en' | 'de'>({
  legacy: false,
  locale: 'de',
  fallbackLocale: 'en',
  globalInjection: true,
  fallbackWarn: false,
  missingWarn: false,
  messages: messages as any,
});

Settings.defaultLocale = 'de';

export type TranslationFunction = typeof i18n.global.t;
