<template>
  <Modal v-model="showModal">
    <AdjustTransfers
      v-if="showModal"
      :booking="booking"
      :show-modal="showModal"
      :pickup-location-type="pickupLocationType"
      :dropoff-location-type="dropoffLocationType"
      :pickup-station-id="pickupStationId"
      :dropoff-station-id="dropoffStationId"
      :is-saving="isSaving"
      :just-auto-adjust="justAutoAdjust"
      @save="(saveEvent: SaveBookingLocationsEvent) => $emit('save', saveEvent)"
      @cancel="showModal = false"
    />
  </Modal>
</template>

<script lang="ts" setup>
import type { Booking } from '@/entities/bookings/booking.entity';
import Modal from '../Modal.vue';
import { ref, watch, watchEffect } from 'vue';
import type { SaveBookingLocationsEvent } from './AdjustTransfers.vue';
import AdjustTransfers from './AdjustTransfers.vue';
import type { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';

const props = defineProps<{
  booking: Booking;
  showModal: boolean;
  pickupLocationType: BookingLocationType;
  dropoffLocationType: BookingLocationType;
  pickupStationId: string | null | undefined;
  dropoffStationId: string | null | undefined;
  justAutoAdjust?: boolean;
  isSaving: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:showModal', value: boolean): void;
  (e: 'save', value: SaveBookingLocationsEvent): void;
}>();

const showModal = ref(props.showModal);
watchEffect(() => (showModal.value = props.showModal));
watch(showModal, () => {
  emit('update:showModal', showModal.value);
});
</script>
