<template>
  <div class="grow">
    <HandoverCarStatusForm
      v-if="booking"
      :booking="booking"
      :is-saving="isSaving"
      @on-submit="handleSubmit"
    />
  </div>
</template>

<script lang="ts" setup>
import type { CarStatusFormValues } from '@/entities/cars/car.entity';
import { useBookingOfType, useUpdateBooking } from '@/queries/use-bookings';
import { useRoute, useRouter } from 'vue-router';
import HandoverCarStatusForm from './HandoverCarStatusForm.vue';
import { useUpdateCar } from '@/queries/use-cars';
import { computed } from 'vue';
import { BookingType } from '@/entities/bookings/booking-type.enum';

const route = useRoute();
const router = useRouter();
const { data: booking } = useBookingOfType(
  route.params.id as string,
  true,
  BookingType.CUSTOMER_BOOKING,
  'upcomingHandovers',
);

const { isPending: isSavingBooking, mutateAsync: updateBooking } = useUpdateBooking();
const { isPending: isSavingCar, mutateAsync: updateCar } = useUpdateCar();

const isSaving = computed(() => isSavingBooking.value || isSavingCar.value);

const handleSubmit = async (formValues: CarStatusFormValues) => {
  if (!booking.value) return;
  await updateBooking({
    id: route.params.id as string,
    booking: {
      milageStart: formValues.milage,
      fuelLevelStart: formValues.fuelLevel,
    },
  });
  await updateCar({
    id: booking.value?.car.id,
    car: {
      milage: formValues.milage,
      fuelLevel: formValues.fuelLevel,
    },
  });
  router.push({
    name: 'handoverRentalContract',
    params: { id: route.params.id },
  });
};
</script>
