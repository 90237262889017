<template>
  <div class="flex flex-col items-end space-y-2">
    <div class="text-right font-medium">
      {{ t('totalBasePrice') }}
      <span
        v-if="!showOverwriteTotalBasePrice"
        v-currency="priceCalculation.totalBasePrice"
        class="block text-xl"
      />
    </div>
    <InputField
      v-if="showOverwriteTotalBasePrice"
      name="customTotalBasePrice"
      type="number"
      :min="0"
      :max-fraction-digits="2"
      adornment="€"
      empty-value-to="null"
      :min-fraction-digits="0"
      :disabled="disabled"
      @input="customTotalBasePriceHasChanged = true"
    />

    <p v-if="!customTotalBasePriceHasChanged && !disabled" class="text-right">
      <a class="text-link" href="#" @click.prevent="toggleOverwriteTotalBasePrice">{{
        showOverwriteTotalBasePrice ? t('useTotalBasePriceOfCar') : t('overwriteTotalBasePrice')
      }}</a>
    </p>

    <div v-if="customTotalBasePriceHasChanged && !disabled" class="flex justify-end gap-2">
      <CVButton
        variant="success"
        :is-loading="isSaving || recalculatingPrice"
        @click.prevent="saveCustomTotalBasePriceChange"
      >
        {{ t('save') }}
      </CVButton>
      <CVButton
        variant="warning"
        :disabled="isSaving || recalculatingPrice"
        @click.prevent="cancelCustomTotalBasePriceChange"
      >
        {{ t('cancel') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CreatePricingDto, Pricing } from '@/entities/pricing.entity';
import { useI18n } from 'vue-i18n';
import { useField, useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import InputField from '../InputField.vue';
import { customTotalBasePriceSchema } from '@/validation/price-calculation.schema';
import { useCreatePricing } from '@/queries/use-pricing';
import type { Car } from '@/entities/cars/car.entity';
import type { CarWithPricing } from '@/views/handover/components/overview/HandoverOverviewForm.vue';
import type { UpdateBookingDto } from '@/entities/bookings/booking.entity';

const props = defineProps<{
  priceCalculation: Pricing;
  bookingId: string;
  car: CarWithPricing | Car;
  startDate: string;
  endDate: string;
  isSaving: boolean;
  disabled?: boolean;
  insuranceCaseId: string | null | undefined;
}>();
const emit = defineEmits<{
  (e: 'update:priceCalculation', value: Pricing): void;
  (
    e: 'update-booking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished: () => void,
  ): void;
}>();

const { t } = useI18n();

const priceCalculation = ref(props.priceCalculation);
const customTotalBasePriceHasChanged = ref(false);
const showOverwriteTotalBasePrice = ref(priceCalculation.value.customTotalBasePrice !== null);

const toggleOverwriteTotalBasePrice = () => {
  customTotalBasePriceHasChanged.value = true;
  showOverwriteTotalBasePrice.value = priceCalculation.value.customTotalBasePrice === null;
  if (showOverwriteTotalBasePrice.value) {
    customTotalBasePrice.value = priceCalculation.value.totalBasePrice;
  } else {
    customTotalBasePrice.value = null;
  }
};

const { mutateAsync: requestPricing, isPending: recalculatingPrice } = useCreatePricing();

const { handleSubmit } = useForm({
  initialValues: {
    customTotalBasePrice: priceCalculation.value.customTotalBasePrice,
  },
  validationSchema: customTotalBasePriceSchema,
});

const { value: customTotalBasePrice } = useField<number | null>('customTotalBasePrice');

watch(
  () => props.priceCalculation,
  (newPricing) => {
    priceCalculation.value = newPricing;
    customTotalBasePrice.value = newPricing.customTotalBasePrice;
  },
);

const saveCustomTotalBasePriceChange = handleSubmit(async (values) => {
  const priceRequest: CreatePricingDto = {
    startDate: props.startDate,
    endDate: props.endDate,
    carId: props.car.id,
    oldPricingId: props.priceCalculation.id,
    bookedExtras: [],
    customTotalBasePrice: values.customTotalBasePrice ?? null,
    insuranceCaseId: props.insuranceCaseId,
  };
  priceCalculation.value = await requestPricing(priceRequest);
  const updateBookingValues: UpdateBookingDto = {
    priceCalculationId: priceCalculation.value.id,
  };
  emit('update-booking', { id: props.bookingId, booking: updateBookingValues }, () => {
    customTotalBasePriceHasChanged.value = false;
  });
  emit('update:priceCalculation', priceCalculation.value);
});

const cancelCustomTotalBasePriceChange = () => {
  customTotalBasePrice.value = priceCalculation.value.customTotalBasePrice;
  showOverwriteTotalBasePrice.value = !!priceCalculation.value.customTotalBasePrice;
  customTotalBasePriceHasChanged.value = false;
};
</script>

<i18n lang="json">
{
  "en": {
    "totalBasePrice": "Car Price (without Extras)",
    "overwriteTotalBasePrice": "Overwrite Total Car Price",
    "useTotalBasePriceOfCar": "Use Price of Car"
  },
  "de": {
    "totalBasePrice": "Fahrzeugpreis (ohne Extras)",
    "overwriteTotalBasePrice": "Fahrzeugpreis überschreiben",
    "useTotalBasePriceOfCar": "Preis des Autos nutzen"
  }
}
</i18n>
