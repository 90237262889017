<template>
  <HeaderBar />
  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
    <AuditLogButton entity-type="Car" :entity-id="carId" />
  </ButtonBackContainer>

  <section class="carvia-container">
    <ViewCarForm v-if="car" :is-saving="isUpdating" :initial-car="car" @on-submit="handleSubmit" />
    <div v-else class="flex justify-center">
      <Spinner />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useCar, useUpdateCar } from '@/queries/use-cars';
import { useRoute } from 'vue-router';
import { useUiStore } from '@/stores/ui.store';
import ViewCarForm from './ViewCarForm.vue';
import { ref, watchEffect } from 'vue';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import type { UpdateCarDto } from '@/entities/cars/car.entity';
import AuditLogButton from '@/components/audit-log/AuditLogButton.vue';

const uiStore = useUiStore();
const { t } = useI18n();
const route = useRoute();
const carId = ref(route.params.id as string);
const { data: car } = useCar(carId);

watchEffect(() => {
  if (car.value?.vehicleType) {
    const { make, model } = car.value.vehicleType;
    uiStore.setHeaderTitle(t('vehicle'), `${make.name} ${model}`, `${car.value.licencePlate}`);
  } else {
    uiStore.setHeaderTitle(t('vehicle'));
  }
});

const { mutate: updateCar, isPending: isUpdating } = useUpdateCar();

const handleSubmit = async (formValues: UpdateCarDto) => {
  updateCar({
    id: carId.value,
    car: formValues,
  });
};
</script>

<i18n lang="json">
{
  "en": {
    "vehicle": "Vehicle"
  },
  "de": {
    "vehicle": "Fahrzeug"
  }
}
</i18n>
