import type { PaginatedResponse } from '@/entities/pagination/paginated-response';
import type {
  CreateInvoiceDto,
  ExportInvoicesCsvDto,
  FindAllInvoicesQueryDto,
  Invoice,
  UpdateInvoiceDto,
} from '@/entities/invoices/invoice.entity';
import { Service } from './http-service';

class InvoicesService extends Service<Invoice, CreateInvoiceDto, UpdateInvoiceDto> {
  async getAllWithQuery(params: FindAllInvoicesQueryDto) {
    const res = await this.httpClient.get<PaginatedResponse<Invoice>>(this.endpoint, {
      params,
    });
    return res.data;
  }

  async sendInvoiceToCustomer(invoiceId: string) {
    const res = await this.httpClient.post(`${this.endpoint}/${invoiceId}/mails/invoice`);
    return res.data;
  }

  async exportInvoicesCsv(data: ExportInvoicesCsvDto) {
    const res = await this.httpClient.post<Blob>(`${this.endpoint}/export`, data);
    return res.data;
  }

  async sendInvoiceToAgent(bookingId: string) {
    const res = await this.httpClient.post(`${this.endpoint}/agent-invoice/${bookingId}`);
    return res.data;
  }
}

export const invoicesService = new InvoicesService('/invoices');
