import * as yup from 'yup';
import { DriveType } from '@/entities/cars/drive-type.enum';
import { TransmissionType } from '@/entities/cars/transmission-type.enum';
import { FuelType } from '@/entities/cars/fuel-type.enum';
import { AxaVehicleCategory } from '@carvia/ros-client-types';

export const vehicleTypeSchema = {
  makeId: yup.string().required(),
  model: yup.string().required(),
  resellPrice: yup.number().required(),
  basePrice: yup.number().required(),
  minAge: yup.number().required(),
  pricePerExtraKm: yup.number().required(),
  seats: yup.string().required(),
  fuelType: yup.string().required().oneOf(Object.keys(FuelType)),
  transmission: yup.string().required().oneOf(Object.keys(TransmissionType)),
  power: yup.number().required(),
  accelaration: yup.number().required(),
  drive: yup.string().required().oneOf(Object.keys(DriveType)),
  tankVolumeInLiter: yup.number().when('fuelType', {
    is: (val: FuelType) => val !== FuelType.ELECTRIC,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  batteryCapacityInKwh: yup.number().when('fuelType', {
    is: (val: FuelType) => val === FuelType.ELECTRIC,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  rangeInKm: yup.number().when('fuelType', {
    is: (val: FuelType) => val === FuelType.ELECTRIC,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  hasNavigation: yup.boolean().required(),
  hasDistanceControl: yup.boolean().required(),
  hasRearViewCamera: yup.boolean().required(),
  customContractCheckboxes: yup.array(
    yup.object({
      label: yup.object({
        de: yup.string().required(),
        en: yup.string().required(),
      }),
    }),
  ),
  inclusiveKm: yup.array(
    yup.object({
      fromDay: yup.number(),
      kmInclusivePerDay: yup.number(),
    }),
  ),
  extraKm: yup
    .object({
      options: yup.array(
        yup.object({
          value: yup.number().min(0),
          price: yup.number().min(0),
        }),
      ),
    })
    .required(),
  extraDeductible: yup
    .object({
      options: yup.array(
        yup.object({
          value: yup.number().min(0),
          pricePerDay: yup.array(
            yup.object({
              fromDay: yup.number().min(1),
              pricePerDay: yup.number().min(0),
            }),
          ),
        }),
      ),
    })
    .required(),
  deposit: yup.number().required(),
  mainCategory: yup.string().required(),
  categories: yup.array(yup.string()).min(1).required(),
  inspectionIntervalInKm: yup.number().required(),
  isAlwaysOnWinterTyres: yup.boolean().required(),
  axaCategory: yup.string().oneOf(Object.keys(AxaVehicleCategory)).notRequired(),
};

export const vehicleTypeValidator = yup.object(vehicleTypeSchema);
