import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type NavigationGuardReturn,
  type RouteLocationNormalized,
} from 'vue-router';
import DashboardView from '@/views/dashboard/DashboardView.vue';
import HandoverView from '@/views/handover/HandoverView.vue';
import LoginView from '@/views/login/LoginView.vue';
import VerifyEmailView from '@/views/login/VerifyEmailView.vue';
import { useAuthStore } from '@/stores/auth.store';
import requireAuth from '@/router/middleware/require-auth';
import middlewarePipeline from '@/router/middleware-pipeline';
import SettingsViewVue from '@/views/settings/SettingsView.vue';
import UpcomingHandoversView from '@/views/handover/UpcomingHandoversView.vue';
import EditVehicleType from '@/views/vehicle-pool/edit-vehicle-type/EditVehicleType.vue';
import VehicleTypesList from '@/views/vehicle-types/vehicle-types-list/VehicleTypesList.vue';
import EditCar from '@/views/vehicle-pool/edit-car/EditCar.vue';
import ViewCar from '@/views/vehicle-types/view-car/ViewCar.vue';
import EditCarStatus from '@/views/vehicle-types/edit-car-status/EditCarStatus.vue';
import HandoverOverview from '@/views/handover/components/overview/HandoverOverview.vue';
import HandoverClientData from '@/views/handover/components/client-data/HandoverClientData.vue';
import HandoverPayment from '@/views/handover/components/payment/HandoverPayment.vue';
import HandoverCarStatus from '@/views/handover/components/car-status/HandoverCarStatus.vue';
import HandoverContract from '@/views/handover/components/contract/HandoverContract.vue';
import BookingList from '@/views/bookings/bookings-list/BookingsListView.vue';
import BookingsGantt from '@/views/bookings/bookings-gantt-view/BookingsGanttView.vue';
import BookingEdit from '@/views/bookings/booking-edit/BookingEditView.vue';
import BookingCreate from '@/views/bookings/booking-create/BookingCreateView.vue';
import TransferEdit from '@/views/bookings/transfer-edit/TransferEditView.vue';
import ReturnView from '@/views/handover/ReturnView.vue';
import ReturnOverview from '@/views/handover/components/return/return-overview/ReturnOverview.vue';
import HandoverDone from '@/views/handover/components/handover-done/HandoverDone.vue';
import VehicleTypeCarsList from '@/views/vehicle-types/vehicle-type-cars-list/VehicleTypeCarsList.vue';
import CheckConfirmEmailView from '@/views/login/CheckConfirmEmailView.vue';
import ResetPasswordView from '@/views/login/ResetPasswordView.vue';
import ReturnDamages from '@/views/handover/components/return/ReturnDamages.vue';
import ReturnDone from '@/views/handover/components/return/ReturnDone.vue';
import ReturnCarStatus from '@/views/handover/components/return/return-car-status/ReturnCarStatus.vue';
import TransferDetail from '@/views/handover/components/transfer/TransferDetail.vue';
import MaintainanceEdit from '@/views/maintenance/MaintenanceEdit.vue';
import InvoiceListView from '@/views/invoices/invoice-list/InvoiceListView.vue';
import InvoiceView from '@/views/invoices/invoice/InvoiceView.vue';
import CreateInvoiceView from '@/views/invoices/create-invoice/CreateInvoiceView.vue';
import PaymentTerminalListView from '@/views/payment-terminals/payment-terminal-list/PaymentTerminalListView.vue';
import PaymentTerminalView from '@/views/payment-terminals/payment-terminal/PaymentTerminalView.vue';
import BookingEditDamages from '@/views/bookings/booking-edit-damages/BookingEditDamages.vue';
import PartnerRentEdit from '@/views/partner-rent/PartnerRentEdit.vue';
import PartnerRentOverview from '@/views/handover/components/partner-rent/PartnerRentOverview.vue';
import PartnerRentView from '@/views/handover/components/partner-rent/PartnerRentView.vue';
import PartnerRentDone from '@/views/handover/components/partner-rent/PartnerRentDone.vue';
import UserListView from '@/views/users/user-list/UserListView.vue';
import UserView from '@/views/users/user/UserView.vue';
import ShowNewsView from '@/views/news/show-news/ShowNewsView.vue';
import NewsListView from '@/views/news/news-list/NewsListView.vue';
import EditNewsView from '@/views/news/edit-news/EditNewsView.vue';
import VehiclePoolList from '@/views/vehicle-pool/vehicle-pool-list/VehiclePoolList.vue';
import AddNewCar from '@/views/vehicle-pool/add-new-car/AddNewCar.vue';
import carStatusRedirectToCompleteFleetIn from './middleware/car-status-redirect-to-complete-fleet-in';
import type { Middleware } from './middleware.type';
import CompleteFleetIn from '@/views/vehicle-pool/complete-fleet-in/CompleteFleetIn.vue';
import HandoverSurvey from '@/views/handover/components/survey/HandoverSurvey.vue';
import StationListView from '@/views/stations/station-list/StationListView.vue';
import StationView from '@/views/stations/station/StationView.vue';
import ReviewView from '@/views/reviews/ReviewView.vue';
import InsuranceCasesListView from '@/views/insurance-cases/insurance-cases-list/InsuranceCasesListView.vue';
import CreateInsuranceCaseView from '@/views/insurance-cases/insurance-case/InsuranceCaseView.vue';
import CreateBookingFromInsuranceCase from '@/views/bookings/create-booking-from-insurance-case/CreateBookingFromInsuranceCaseView.vue';

const routes = [
  {
    name: 'home',
    path: '/',
    component: DashboardView,
    meta: {
      middleware: [requireAuth([['ros', 'Dashboard']])],
    },
  },
  {
    name: 'login',
    path: '/login',
    component: LoginView,
  },
  {
    name: 'resetPassword',
    path: '/reset-password',
    component: ResetPasswordView,
  },

  {
    name: 'checkConfirmEmail',
    path: '/check-confirm-email',
    component: CheckConfirmEmailView,
  },
  {
    name: 'verifyemail',
    path: '/verify-email',
    component: VerifyEmailView,
    children: [
      {
        name: 'verifyEmail',
        path: ':verificationCode',
        component: VerifyEmailView,
      },
    ],
  },

  {
    name: 'upcomingHandovers',
    path: '/upcoming-handovers',
    component: UpcomingHandoversView,
    meta: {
      middleware: [requireAuth([['handle', 'Booking']])],
    },
  },
  {
    name: 'handover',
    path: '/handover',
    component: HandoverView,
    meta: {
      middleware: [requireAuth([['handle', 'Booking']])],
    },
    children: [
      {
        name: 'handoverDetail',
        path: ':id',
        component: HandoverOverview,
      },
      {
        name: 'handoverSurvey',
        path: ':id/:surveyId',
        component: HandoverSurvey,
      },
      {
        name: 'handoverClientData',
        path: ':id/client-data',
        component: HandoverClientData,
      },
      {
        name: 'handoverPayment',
        path: ':id/payment',
        component: HandoverPayment,
      },
      {
        name: 'handoverCarStatus',
        path: 'vehicle-status/:id',
        component: HandoverCarStatus,
      },
      {
        name: 'handoverRentalContract',
        path: 'contract/:id',
        component: HandoverContract,
      },
      {
        name: 'handoverDone',
        path: 'finished',
        component: HandoverDone,
      },
    ],
  },
  {
    name: 'return',
    path: '/return',
    component: ReturnView,
    meta: {
      middleware: [requireAuth([['handle', 'Booking']])],
    },
    children: [
      {
        name: 'returnOverview',
        path: ':id',
        component: ReturnOverview,
      },
      {
        name: 'returnDamages',
        path: ':id/damages',
        component: ReturnDamages,
      },
      {
        name: 'returnCarStatus',
        path: ':id/car-status',
        component: ReturnCarStatus,
      },
      {
        name: 'returnDone',
        path: ':id/done',
        component: ReturnDone,
      },
    ],
  },
  {
    name: 'transferDetail',
    path: '/transfer/:id',
    meta: {
      middleware: [requireAuth([['handle', 'Booking']])],
    },
    component: TransferDetail,
  },

  {
    name: 'bookingList',
    path: '/booking-list',
    component: BookingList,
    meta: {
      middleware: [requireAuth([['ros', 'Bookings']])],
    },
  },
  {
    name: 'bookingEdit',
    path: '/booking-list/:id',
    component: BookingEdit,
    meta: {
      middleware: [requireAuth([['ros', 'Bookings']])],
    },
  },
  {
    name: 'bookingCreate',
    path: '/booking-create',
    component: BookingCreate,
    meta: {
      middleware: [requireAuth([['ros', 'CreateBooking']])],
    },
  },
  {
    name: 'createBookingFromInsuranceCase',
    path: '/create-booking-from-insurance-case/:id',
    component: CreateBookingFromInsuranceCase,
    meta: {
      middleware: [requireAuth([['ros', 'CreateBooking']])],
    },
  },
  {
    name: 'bookingEditDamages',
    path: '/booking-list/:id/damages',
    component: BookingEditDamages,
    meta: {
      middleware: [requireAuth([['ros', 'Bookings']])],
    },
  },
  {
    name: 'bookingPlan',
    path: '/booking-plan',
    component: BookingsGantt,
    meta: {
      middleware: [requireAuth([['ros', 'Bookings']])],
    },
  },
  {
    name: 'bookingPlanEdit',
    path: '/booking-plan/:id',
    component: BookingEdit,
    meta: {
      middleware: [requireAuth([['ros', 'Bookings']])],
    },
  },
  {
    name: 'transferEdit',
    path: '/transfer-edit/:transferType/:bookingId/:transferId',
    component: TransferEdit,
    meta: {
      middleware: [requireAuth([['ros', 'Bookings']])],
    },
  },
  {
    name: 'insuranceCasesList',
    path: '/insurance-cases-list',
    component: InsuranceCasesListView,
    meta: {
      middleware: [requireAuth([['manage', 'InsuranceCase']])],
    },
  },
  {
    name: 'insuranceCase',
    path: '/insurance-case/:id',
    component: CreateInsuranceCaseView,
    meta: {
      middleware: [requireAuth([['manage', 'InsuranceCase']])],
    },
  },
  {
    name: 'vehicleTypes',
    path: '/vehicle-types',
    component: VehicleTypesList,
    meta: {
      middleware: [requireAuth([['read', 'VehicleType']])],
    },
  },
  {
    name: 'vehicleTypeCarsList',
    path: '/vehicle-type-cars-list/:id',
    component: VehicleTypeCarsList,
    meta: {
      middleware: [
        requireAuth([
          ['read', 'VehicleType'],
          ['manage', 'Car'],
        ]),
      ],
    },
  },
  {
    name: 'viewCar',
    path: '/view-car/:id',
    component: ViewCar,
    meta: {
      middleware: [requireAuth([['get', 'Car']])],
    },
  },
  {
    name: 'editCar',
    path: '/edit-car/:id',
    component: EditCar,
    meta: {
      middleware: [requireAuth([['manage', 'Car']])],
    },
  },
  {
    name: 'editVehicleType',
    path: '/edit-vehicle-type/:id',
    component: EditVehicleType,
    meta: {
      middleware: [requireAuth([['manage', 'VehicleType']])],
    },
  },
  {
    name: 'editCarStatus',
    path: '/car-status/:id/:autoEnterMilage?',
    component: EditCarStatus,
    meta: {
      middleware: [requireAuth([['manage', 'Car']]), carStatusRedirectToCompleteFleetIn],
    },
  },
  {
    name: 'vehiclePool',
    path: '/vehicle-pool',
    component: VehiclePoolList,
    meta: {
      middleware: [requireAuth([['ros', 'VehiclePool']])],
    },
  },
  {
    name: 'addNewCar',
    path: '/add-new-car/:vehicleTypeId?',
    component: AddNewCar,
    meta: {
      middleware: [requireAuth([['create', 'Car']])],
    },
  },
  {
    name: 'completeCarFleetIn',
    path: '/complete-car-fleet-in/:id',
    component: CompleteFleetIn,
    meta: {
      middleware: [requireAuth([['manage', 'Car']])],
    },
  },
  {
    name: 'settings',
    path: '/settings',
    component: SettingsViewVue,
    meta: {
      middleware: [requireAuth()],
    },
  },
  {
    name: 'maintenanceEdit',
    path: '/maintenance-edit/:carId/:blockId',
    component: MaintainanceEdit,
    meta: {
      middleware: [requireAuth([['ros', 'Bookings']])],
    },
  },
  {
    name: 'partnerRentEdit',
    path: '/partner-rent-edit/:carId/:bookingId',
    component: PartnerRentEdit,
    meta: {
      middleware: [requireAuth([['ros', 'Bookings']])],
    },
  },
  {
    name: 'partnerRentHandoverView',
    path: '/partner-rent-handover-view',
    component: PartnerRentView,
    meta: {
      middleware: [requireAuth([['handle', 'Booking']])],
    },
    children: [
      {
        name: 'partnerRentHandover',
        path: '/partner-rent-handover/:id',
        component: PartnerRentOverview,
        meta: {
          middleware: [requireAuth([['handle', 'Booking']])],
        },
      },
      {
        name: 'partnerRentHandoverDone',
        path: '/partner-rent-handover-done/:handedOverOrReturned',
        component: PartnerRentDone,
        meta: {
          middleware: [requireAuth([['handle', 'Booking']])],
        },
      },
    ],
  },
  {
    name: 'invoiceList',
    path: '/invoice-list',
    component: InvoiceListView,
    meta: {
      middleware: [requireAuth([['manage', 'Invoice']])],
    },
  },
  {
    name: 'invoice',
    path: '/invoice/:id',
    component: InvoiceView,
    meta: {
      middleware: [requireAuth([['manage', 'Invoice']])],
    },
  },
  {
    name: 'createInvoice',
    path: '/create-invoice',
    component: CreateInvoiceView,
    meta: {
      middleware: [requireAuth([['create', 'Invoice']])],
    },
  },
  {
    name: 'paymentTerminalList',
    path: '/payment-terminal-list',
    component: PaymentTerminalListView,
    meta: {
      middleware: [requireAuth([['manage', 'PaymentTerminal']])],
    },
  },
  {
    name: 'paymentTerminal',
    path: '/payment-terminal/:id',
    component: PaymentTerminalView,
    meta: {
      middleware: [requireAuth([['manage', 'PaymentTerminal']])],
    },
  },
  {
    name: 'userList',
    path: '/user-list',
    component: UserListView,
    meta: {
      middleware: [requireAuth([['manage', 'User']])],
    },
  },
  {
    name: 'user',
    path: '/user/:id',
    component: UserView,
    meta: {
      middleware: [requireAuth([['manage', 'User']])],
    },
  },
  {
    name: 'showNews',
    path: '/show-news/:id',
    component: ShowNewsView,
    meta: {
      middleware: [requireAuth([['read', 'News']])],
    },
  },
  {
    name: 'newsList',
    path: '/news-list',
    component: NewsListView,
    meta: {
      middleware: [requireAuth([['read', 'News']])],
    },
  },
  {
    name: 'editNews',
    path: '/edit-news/:id',
    component: EditNewsView,
    meta: {
      middleware: [requireAuth([['manage', 'News']])],
    },
  },
  {
    name: 'stationList',
    path: '/station-list',
    component: StationListView,
    meta: {
      middleware: [requireAuth([['manage', 'Station']])],
    },
  },
  {
    name: 'station',
    path: '/station/:id',
    component: StationView,
    meta: {
      middleware: [requireAuth([['manage', 'Station']])],
    },
  },
  {
    name: 'review',
    path: '/review/:id',
    component: ReviewView,
    meta: {},
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.query.tab && from.query.tab) {
      return false;
    } else {
      return { top: 0 };
    }
  },
});

let initialRoute = true;

router.beforeResolve(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<NavigationGuardReturn> => {
    if (to.path === from.path && !initialRoute) return next();
    initialRoute = false;

    const authStore = useAuthStore();

    if (!to.meta.middleware) {
      return next();
    }
    const middleware = to.meta.middleware as Middleware[];

    const context = {
      to,
      from,
      next,
      authStore,
    };

    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1),
    });
  },
);

export default router;
