<template>
  <HeaderBar class="mx-auto" />
  <section>
    <div class="carvia-container flex items-stretch gap-8">
      <div
        v-if="$can('read', 'News') || $can('readSummary', 'AgentProvision')"
        class="flex flex-1 flex-col justify-items-start gap-8"
      >
        <div
          v-if="$can('readSummary', 'AgentProvision') && isAgentProvisionEnabled"
          class="flex flex-col gap-4"
        >
          <h2>
            {{ t('achievementsThisMonth') }}
          </h2>
          <DashboardAgentProvision />
        </div>
        <div v-if="$can('read', 'News')">
          <div class="flex items-center justify-between">
            <h2>
              {{ t('news') }}
            </h2>
            <router-link :to="{ name: 'newsList' }" class="text-link">
              {{ t('showAll') }}
            </router-link>
          </div>
          <div class="mt-5 flex flex-col space-y-4">
            <DashboardNewsList />
          </div>
        </div>
        <DashboardCarviaWorldLink />
      </div>
      <div v-if="$can('read', 'News')" class="border-r border-primary"></div>
      <div class="flex-1">
        <h2>
          {{ t('start') }}
        </h2>
        <div class="mt-6 flex flex-col space-y-5">
          <DashboardButton
            v-for="button in buttons.filter((button) => button.available)"
            :key="button.text"
            @click="$router.push({ name: button.route })"
          >
            {{ t(button.text) }}
          </DashboardButton>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUiStore } from '@/stores/ui.store';
import DashboardButton from './components/DashboardButton.vue';
import DashboardCarviaWorldLink from './components/DashboardCarviaWorldLink.vue';
import DashboardNewsList from './components/DashboardNewsList.vue';
import { useAuthStore } from '@/stores/auth.store';
import { useAbility } from '@casl/vue';
import DashboardAgentProvision from '@/views/dashboard/components/DashboardAgentProvision.vue';
import { useFeatureFlag } from '@/hooks/use-feature-flag';

const { t } = useI18n();
const uiStore = useUiStore();
const authStore = useAuthStore();
const { can } = useAbility();

const isAgentProvisionEnabled = useFeatureFlag('agent_provision');

uiStore.setHeaderTitle('Servus ' + authStore.user?.firstName);

const buttons = ref<{ text: string; route: string; available: boolean }[]>([
  {
    text: 'handover',
    route: 'upcomingHandovers',
    available: can('handle', 'Booking'),
  },
  {
    text: 'occupancy',
    route: 'bookingPlan',
    available: can('ros', 'Bookings'),
  },
  {
    text: 'bookingList',
    route: 'bookingList',
    available: can('ros', 'Bookings'),
  },
  {
    text: 'vehicleTypes',
    route: 'vehicleTypes',
    available: can('read', 'VehicleType') && !can('ros', 'VehiclePool'),
  },
  {
    text: 'vehiclePoolManagement',
    route: 'vehiclePool',
    available: can('ros', 'VehiclePool'),
  },
  {
    text: 'createBooking',
    route: 'bookingCreate',
    available: can('ros', 'CreateBooking'),
  },
]);
</script>

<i18n lang="json">
{
  "en": {
    "news": "News",
    "start": "Let's go",
    "vehicleTypes": "Vehicles",
    "vehiclePoolManagement": "Vehicle Pool Management",
    "handover": "Handovers",
    "bookingList": "Booking list",
    "createBooking": "Create Booking",
    "occupancy": "Occupancy",
    "showAll": "Show all",
    "achievementsThisMonth": "Your achievements this month"
  },
  "de": {
    "news": "Neuigkeiten",
    "start": "Loslegen",
    "vehicleTypes": "Fahrzeugliste",
    "vehiclePoolManagement": "Fuhrparkmanagement",
    "handover": "Übergaben & Rückgaben",
    "bookingList": "Buchungsliste",
    "createBooking": "Buchung erstellen",
    "occupancy": "Belegungsplan",
    "showAll": "Alle anzeigen",
    "achievementsThisMonth": "Deine Erfolge diesen Monat"
  }
}
</i18n>
