<template>
  <HeaderBar />
  <section class="carvia-container">
    <UserSettings />

    <Divider />

    <GlobalSettings v-if="user?.role === UserRole.ADMIN" />
  </section>
</template>

<script setup lang="ts">
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { watchEffect } from 'vue';
import { useUiStore } from '@/stores/ui.store';
import { useI18n } from 'vue-i18n';
import UserSettings from './UserSettings.vue';
import { useAuthStore } from '@/stores/auth.store';
import GlobalSettings from './GlobalSettings.vue';
import { UserRole } from '@/entities/auth/user-role.enum';
import Divider from '@/components/Divider.vue';

const { t } = useI18n();

const { user } = useAuthStore();

watchEffect(() => {
  useUiStore().setHeaderTitle(t('settings'));
});
</script>

<i18n lang="json">
{
  "en": {
    "settings": "Settings"
  },
  "de": {
    "settings": "Einstellungen"
  }
}
</i18n>
