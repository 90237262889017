<template>
  <section class="grid min-h-screen place-items-center py-8">
    <div>
      <div v-if="isPending">
        <h1 class="text-2xl">{{ t('verifyingEmail') }}</h1>
        <Spinner />
      </div>

      <h2 v-if="isError" class="text-2xl">{{ t('somethingWentWrong') }}</h2>

      <div v-if="isSuccess">
        <h2 class="text-2xl">{{ t('emailConfirmed') }}</h2>
        <router-link :to="{ name: 'home' }">{{ t('home') }}</router-link>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useConfirmEmail } from '@/queries/auth/use-confirm-email';
import { onMounted } from 'vue';
import { RouterLink, useRoute } from 'vue-router';

const { t } = useI18n();

const route = useRoute();
const { token } = route.query;
const { mutateAsync: confirmEmail, isPending, isError, isSuccess } = useConfirmEmail();
onMounted(async () => {
  await confirmEmail(token as string);
});
</script>

<i18n lang="json">
{
  "en": {
    "verifyingEmail": "Verifying Email Address",
    "somethingWentWrong": "Something went wrong",
    "emailConfirmed": "Email confirmed",
    "home": "Home"
  },
  "de": {
    "verifyingEmail": "E-Mail-Adresse wird überprüft",
    "somethingWentWrong": "Etwas ist schief gelaufen",
    "emailConfirmed": "E-Mail bestätigt",
    "home": "Startseite"
  }
}
</i18n>
