<template>
  <div class="grow">
    <div v-if="booking && survey">
      <h2 class="mb-4">
        {{ t('customerSurvey') }}
      </h2>

      <HandoverSurveyForm :booking="booking" :survey="survey" @submit="handleSubmit" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useBookingOfType } from '@/queries/use-bookings';
import { useRoute, useRouter } from 'vue-router';
import { useSurvey } from '@/queries/use-surveys';
import HandoverSurveyForm from './HandoverSurveyForm.vue';
import { BookingType } from '@/entities/bookings/booking-type.enum';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const { data: booking } = useBookingOfType(
  route.params.id as string,
  true,
  BookingType.CUSTOMER_BOOKING,
  'upcomingHandovers',
);
const { data: survey } = useSurvey(route.params.surveyId as string);

const handleSubmit = async () => {
  router.replace({
    name: 'handoverClientData',
    params: { id: route.params.id },
  });
};
</script>

<i18n lang="json">
{
  "en": {
    "customerSurvey": "Customer Survey"
  },
  "de": {
    "customerSurvey": "Kundenumfrage"
  }
}
</i18n>
