<template>
  <div class="grid grid-cols-3 items-start gap-4">
    <div>
      <LabeledText :label="t('renter')">
        <span v-if="customerData.company">{{ customerData.company }}<br /></span>
        {{ customerData.firstName }} {{ customerData.lastName }}<br />
        {{ customerData.street }}<br />
        {{ customerData.zip }} {{ customerData.city }}
      </LabeledText>
    </div>

    <div class="grid grid-cols-1 gap-4">
      <LabeledText :label="t('contactData')">
        <span v-if="customerData.phone">{{ customerData.phone }}</span>
      </LabeledText>
      <LabeledText :label="t('dateOfBirth')">
        {{ formattedDateOfBirth }}
      </LabeledText>
    </div>

    <div class="grid grid-cols-1 gap-4">
      <LabeledText :label="t('country')">{{ customerData.country }}</LabeledText>
      <LabeledText :label="t('preferredLanguage')">
        {{ t(`locales.${customerData.preferredLocal}`) }}
      </LabeledText>
    </div>
  </div>
</template>

<script lang="ts" setup>
import LabeledText from '@/components/LabeledText.vue';
import type { CustomerData } from '@/entities/bookings/customer-data';
import { getDateFormat } from '@/hooks/use-date-format';
import { useFormattedDate } from '@/hooks/use-formatted-date';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const props = defineProps<{
  customerData: CustomerData;
}>();

const { t } = useI18n();

const dateOfBirthIso = computed(() =>
  props.customerData.dateOfBirth
    ? new Date(props.customerData.dateOfBirth).toISOString()
    : undefined,
);

const formattedDateOfBirth = useFormattedDate(dateOfBirthIso, getDateFormat());
</script>

<i18n lang="json">
{
  "en": {
    "renter": "Renter",
    "contactData": "Contact Data",
    "dateOfBirth": "Date of birth",
    "country": "Country",
    "preferredLanguage": "Preferred Language"
  },
  "de": {
    "renter": "Mieter",
    "contactData": "Kontaktdaten",
    "dateOfBirth": "Geburtsdatum",
    "country": "Land",
    "preferredLanguage": "Bevorzugte Sprache"
  }
}
</i18n>
