<template>
  <div v-if="carDocuments" class="relative">
    <div class="mb-4 border-primary" :class="{ 'border px-4': immutable }">
      <div
        v-for="(document, i) in carDocuments"
        :key="i"
        class="relative border-primary"
        :class="{
          'border-b': i !== carDocuments.length - 1 || !immutable,
        }"
      >
        <div class="relative flex items-center gap-4 pb-3 pt-3">
          <div class="basis-[25%]">{{ document.name }}</div>
          <div class="basis-[20%]">
            {{ t(`carDocumentTypes.${document.type as string}`) }}
          </div>
          <div class="basis-[42%]">
            {{ document.comment ? shortenText(document.comment, 70) : '-' }}
          </div>
          <div class="flex basis-[20%] justify-end">
            <a
              class="block border border-primary pl-1 pr-1 text-primary"
              :href="document.documentFileUrl"
              target="_blank"
              >{{ t('download') }}</a
            >
          </div>
          <DropdownDotted :buttons="dropdownButtons(document)" />
        </div>
      </div>
      <div v-if="carDocuments.length === 0" class="pb-3 pt-5">
        {{ t('noDocuments') }}
      </div>
    </div>

    <div v-if="!immutable">
      <CarDocumentsForm
        v-if="addingDocument"
        :car="car"
        :car-documents="carDocuments"
        @complete="addingDocument = false"
      />
      <div v-else class="flex justify-end">
        <CVButton @click.prevent="addDocument">
          {{ t('addDocument') }}
        </CVButton>
      </div>
      <div
        v-if="isRemovingCarDocument"
        class="absolute inset-[-10px] flex items-center justify-center bg-black bg-opacity-30"
      >
        <Spinner />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CarDocumentType } from '@/entities/cars/car-document-type.enum';
import type { CarDocument } from '@/entities/cars/car-document.entity';
import type { Car } from '@/entities/cars/car.entity';
import { useI18n } from 'vue-i18n';
import { useCarDocumentsWithQuery, useRemoveCarDocument } from '@/queries/use-car-documents';
import { Alert } from '@/utils/alert';
import { flatMap } from 'lodash';
import { computed, ref } from 'vue';
import DropdownDotted from '@/components/DropdownDotted.vue';
import { shortenText } from '@/hooks/use-shorten-text';
import CarDocumentsForm from './CarDocumentsForm.vue';

const props = defineProps<{
  car: Car;
  immutable?: boolean;
  documentTypeFilter?: CarDocumentType[];
}>();

const { t } = useI18n();

const addingDocument = ref(false);

const { data: carDocumentsData } = useCarDocumentsWithQuery(props.car.id, {
  type: props.documentTypeFilter,
});
const { mutateAsync: removeCarDocument, isPending: isRemovingCarDocument } = useRemoveCarDocument();

const addDocument = () => {
  if (addingDocument.value) return;
  addingDocument.value = true;
};

const removeDocument = async (documentId: string) => {
  const alertResult = await Alert.fire({
    titleText: t('reallyDeleteDocumentTitle'),
    text: t('reallyDeleteDocumentText'),
    icon: 'question',
    showDenyButton: true,
    confirmButtonText: t('delete'),
    denyButtonText: t('keep'),
  });

  if (alertResult.isConfirmed) {
    removeCarDocument({ carId: props.car.id, documentId });
  }
};

const carDocuments = computed(() =>
  flatMap(
    carDocumentsData.value?.pages,
    (page) =>
      page.items.map((car) => ({
        ...car,
      })) ?? [],
  ),
);

const dropdownButtons = (document: CarDocument) => [
  ...(!props.immutable
    ? [
        {
          title: t('delete'),
          onClick: () => removeDocument(document.id),
        },
      ]
    : []),
  ...(document.comment
    ? [
        {
          title: t('viewComment'),
          onClick: () => {
            Alert.fire({
              text: document.comment ?? '',
              icon: 'info',
              confirmButtonText: t('close'),
            });
          },
        },
      ]
    : []),
];
</script>

<i18n lang="json">
{
  "en": {
    "download": "Download",
    "addDocument": "+ Add Document",
    "reallyDeleteDocumentTitle": "Really remove Document?",
    "reallyDeleteDocumentText": "The File will be permanently deleted.",
    "delete": "Delete",
    "keep": "Keep",
    "noDocuments": "This car has no documents",
    "viewComment": "View Comment",
    "close": "Close"
  },
  "de": {
    "download": "Download",
    "addDocument": "+ Dokument hinzufügen",
    "reallyDeleteDocumentTitle": "Dokument wirklich entfernen?",
    "reallyDeleteDocumentText": "Die Datei wird unwiderruflich gelöscht.",
    "delete": "Löschen",
    "keep": "Behalten",
    "noDocuments": "Dieses Fahrzeug hat noch keine Dokumente",
    "viewComment": "Kommentar anzeigen",
    "close": "Schließen"
  }
}
</i18n>
