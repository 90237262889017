import { authService } from '@/api/auth.service';
import type { JwtTokenResponse } from '@/entities/auth/jwt-token-response.entity';
import type { ResetPasswordDto } from '@/entities/auth/reset-password.dto';
import type { SendPasswordResetMailDto } from '@/entities/auth/send-password-reset-mail.dto';
import { useMutation } from '@tanstack/vue-query';
import type { MutationDefaultsContext } from '../query-client';

const SEND_PASSWORD_RESET_MAIL_QUERY_KEY = 'sendPasswordResetMail';
const RESET_PASSWORD_QUERY_KEY = 'resetPassword';

export const useSendPasswordResetMail = () => {
  return useMutation<unknown, unknown, SendPasswordResetMailDto, MutationDefaultsContext>({
    mutationKey: [SEND_PASSWORD_RESET_MAIL_QUERY_KEY],
    mutationFn: ({ email }) => authService.sendPasswordResetEmail(email),
    onMutate: () => ({
      successMessage: 'Password reset email sent',
      invalidateQueryKeys: [SEND_PASSWORD_RESET_MAIL_QUERY_KEY],
    }),
  });
};

export const useResetPassword = () => {
  return useMutation<JwtTokenResponse, unknown, ResetPasswordDto, MutationDefaultsContext>({
    mutationKey: [RESET_PASSWORD_QUERY_KEY],
    mutationFn: ({ token, password }) => authService.resetPassword(token, password),
    onMutate: () => ({
      successMessage: 'Password successfully reset',
      invalidateQueryKeys: [RESET_PASSWORD_QUERY_KEY],
    }),
  });
};
