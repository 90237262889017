import { mapApiService } from '@/api/map-api-http-service';
import type { BookingCoordinates } from '@/entities/bookings/booking.entity';
import { useQuery } from '@tanstack/vue-query';
import { type MaybeRefOrGetter, toRef, toValue } from '@vueuse/core';

const QUERY_KEY = 'map-api';

export const useMapApiWithCoordinates = (
  location: MaybeRefOrGetter<BookingCoordinates>,
  customQueryKey: string,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, customQueryKey],
    queryFn: () => mapApiService.getPositionByCoordinates(toValue(location)),
    enabled: toRef(enabled),
  });
};
