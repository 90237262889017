<template>
  <div>
    <div class="space-y-6">
      <div v-for="question of survey.questions" :key="question.id" class="space-y-4">
        <p class="text-xl font-medium">
          {{ locale === 'de' ? question.textDe : question.textEn }}
        </p>
        <div class="flex flex-col items-start justify-start gap-2">
          <label
            v-for="answer of question.answers"
            :key="answer.id"
            class="flex cursor-pointer items-center gap-2"
          >
            <input
              type="radio"
              :name="question.id"
              class="radio"
              @change="onAnswerChange(question.id, answer.id)"
            />
            {{ locale === 'de' ? answer.textDe : answer.textEn }}
          </label>
        </div>
      </div>
    </div>

    <Divider />

    <div class="flex justify-end">
      <CVButton :disabled="!isValid" :is-loading="isCreating" @click.prevent="submit">{{
        t('continue')
      }}</CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Booking } from '@/entities/bookings/booking.entity';
import type { Survey } from '@/entities/surveys/survey.entity';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useCreateSurveyConducted } from '@/queries/use-surveys-conducted';
import * as yup from 'yup';
import { toTypedSchema } from '@vee-validate/yup';
import Divider from '@/components/Divider.vue';
import { computed } from 'vue';

const props = defineProps<{
  booking: Booking;
  survey: Survey;
}>();

const emit = defineEmits<{
  (e: 'submit'): void;
}>();

const { t, locale } = useI18n();

const { mutateAsync: createSurveyConducted, isPending: isCreating } = useCreateSurveyConducted();

const {
  handleSubmit,
  values: formValues,
  isFieldValid,
  setFieldValue,
} = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      answers: yup
        .array(
          yup.object({
            questionId: yup.string().required(),
            answerId: yup.string().required(),
          }),
        )
        .min(props.survey.questions.length)
        .required(),
    }),
  ),
});

const onAnswerChange = (questionId: string, answerId: string) => {
  if (formValues.answers && formValues.answers.some((answer) => answer.questionId === questionId)) {
    setFieldValue(
      'answers',
      formValues.answers.map((answer) =>
        answer.questionId === questionId ? { questionId, answerId } : answer,
      ),
    );
  } else {
    setFieldValue('answers', [
      ...(formValues.answers ? formValues.answers : []),
      { questionId, answerId },
    ]);
  }
};

const isValid = computed(() => {
  return isFieldValid('answers');
});

const submit = handleSubmit(async (values) => {
  await createSurveyConducted({
    surveyId: props.survey.id,
    bookingId: props.booking.id,
    answerIds: values.answers.map(({ answerId }) => answerId),
  });
  emit('submit');
});
</script>

<i18n lang="json">
{
  "en": {
    "customerSurvey": "Customer Survey",
    "continue": "Continue"
  },
  "de": {
    "customerSurvey": "Kundenumfrage",
    "continue": "Weiter"
  }
}
</i18n>
