import { damagesService } from '@/api/damages.service';
import type {
  CreateDamageDto,
  Damage,
  FindAllDamagesQueryDto,
  UpdateDamageDto,
} from '@/entities/damage.entity';
import { type MaybeRefOrGetter, toRef, toValue } from '@vueuse/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import type { MutationDefaultsContext } from './query-client';
import {
  addItemToQueriesData,
  removeItemFromQueriesData,
  updateItemOnQueriesData,
} from '@/utils/update-query-data';

const QUERY_KEY = 'damages';

export const useDamage = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: () => damagesService.getOne(id),
  });
};

export const useDamages = (
  query: MaybeRefOrGetter<FindAllDamagesQueryDto>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(query)],
    queryFn: () => damagesService.getAllWithQuery(toValue(query)),
    enabled: toValue(enabled),
  });
};

export const useUpdateDamage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({ id, damage }: { id: string; damage: UpdateDamageDto }) =>
      damagesService.update(id, damage),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'Damage updated',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(null, null, context);
      updateItemOnQueriesData<Damage>([QUERY_KEY], result, queryClient);
    },
  });
};

export const useCreateDamage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (damage: CreateDamageDto) => damagesService.create(damage),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'Damage created',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(null, null, context);
      addItemToQueriesData<Damage>([QUERY_KEY], result, queryClient);
    },
  });
};

export const useDeleteDamage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (id: string) => damagesService.remove(id),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'Damage removed',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(null, null, context);
      removeItemFromQueriesData<Damage>([QUERY_KEY], variables, queryClient);
    },
  });
};
