import type { Booking } from '@/entities/bookings/booking.entity';
import type {
  CreateInternalBookingDto,
  UpdateInternalBookingDto,
} from '@/entities/bookings/internal-booking.entity';
import { Service } from './http-service';

export const internalBookingsService = new Service<
  Booking,
  CreateInternalBookingDto,
  UpdateInternalBookingDto
>('/bookings/internal');
