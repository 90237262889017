import { GOOGLE_MAPS_API_KEY } from '@/constants/google-maps-api-key.constant';
import type { BookingCoordinates, GeocodedLocation } from '@/entities/bookings/booking.entity';
import type { GoogleMapsApiPositions } from '@/entities/map-api.entity';
import axios from 'axios';

export class MapApiService {
  async getPositionByCoordinates(location: BookingCoordinates): Promise<GeocodedLocation> {
    const res = await axios.get<GoogleMapsApiPositions>(
      'https://maps.googleapis.com/maps/api/geocode/json',
      {
        params: {
          latlng: location.latitude + ',' + location.longitude,
          key: GOOGLE_MAPS_API_KEY,
        },
      },
    );
    return {
      address: {
        displayName: res.data.results[0].formatted_address,
      },
      coordinates: {
        latitude: res.data.results[0].geometry.location.lat,
        longitude: res.data.results[0].geometry.location.lng,
      },
    };
  }
}

export const mapApiService = new MapApiService();
